<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-title">{{ (alert?.type?.value || '') + ' Alert: ' + (alert?.rule_name || 'N/A') }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button *permission="{'action':'Modify', 'key':'invoices'}" mat-icon-button matTooltip="Reload Invoice" (click)="reloadInvoice()" [disabled] = "isReloadDisabled">
    <ng-container *ngIf="alert && alert.type.value == 'Invoice'">
      <ca-icon [key]="'AUTO_RENEW'"></ca-icon>
    </ng-container>
  </button>
  <button *permission="{'action':'Create', 'key':'gl'}" 
    [disabled]="!(alert && alert.invoice && alert.invoice.invoice_id)" 
    (click)="goToInvoiceDetails()" mat-icon-button matTooltip="View Invoice Details">
    <ca-icon [key]="'VIEW_INVOICE_DETAILS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Refresh" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="!tabGroup.isActive(2) || alert?.rule_name === 'CHARGE_TOTAL'" 
    mat-icon-button matTooltip="Download CSV" 
    (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button [disabled]="!tabGroup.isActive(2) || isGridSettingsDisabled || alert?.rule_name === 'CHARGE_TOTAL'" 
    mat-icon-button matTooltip="Settings"
    (click)="gridSettings()">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="!tabGroup.isActive(2) || selection || alert?.rule_name === 'CHARGE_TOTAL'" 
    [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2) && alert?.rule_name !== 'CHARGE_TOTAL'}" 
    (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" 
    mat-icon-button matTooltip="Filter">
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button [disabled]="!tabGroup.isActive(2) || !selection" #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" 
          mat-icon-button matTooltip="Info">
    <ca-icon [key]="'INFO'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content tabbed-content">


    <mat-tab-group (selectedIndexChange)="onTabChange($event)">
      <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs" [disabled]="tab.disabled">
      </mat-tab>
    </mat-tab-group>

    <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
      <div class="flex-container ca-card custom-mat-card-container" fxLayout="row">
        <div fxFlex>
          <mat-card fxFlex fxFlexFill caCard>

            <mat-card-header>
              Alert Info
            </mat-card-header>

            <mat-card-content>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Category</label>
                  <p>
                    {{ alert?.category?.value || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Created At</label>
                  <p>
                    {{ (alert?.created_at | caDateTime) || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Created By</label>
                  <p>
                    {{ alert?.creator?.full_name || 'System'}}
                  </p>
                </div>
              </div>

              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Owner</label>
                  <p>
                    {{ alert?.owner?.full_name || PLACEHOLDERS.OWNER_UNASSIGNED }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Priority</label>
                  <p>
                    {{ alert?.priority.value || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Status</label>
                  <p>
                    {{ alert?.status.value || 'N/A' }}
                  </p>
                </div>
              </div>

              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Last Modified At</label>
                  <p>
                    {{ (alert?.last_modified_at | caDate) || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Closed At</label>
                  <p>
                    {{ (alert?.closed_at | caDate) || 'N/A' }}
                  </p>
                </div>

            <div fxFlex class="entry-list-item">
              <label>Message</label>
              <p>
                {{ alert?.message || 'N/A' }}
              </p>
            </div>
          </div>

        </mat-card-content>

      </mat-card>
        </div>
        <div fxFlex>
          <mat-card fxFlex fxFlexFill caCard>

            <mat-card-header>
              Invoice Info
            </mat-card-header>

            <mat-card-content>

              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>BAN</label>
                  <p>
                    {{ alert?.account_no || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Vendor</label>
                  <p>
                    {{ alert?.vendor?.name || 'N/A' }}
                  </p>
                </div>
              </div>

              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Invoice Number</label>
                  <p>
                    {{ alert?.invoice?.sp_inv_num || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Invoice ID</label>
                  <p>
                    {{ alert?.invoice?.invoice_id || 'N/A' }}
                  </p>
                </div>
              </div>

              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Invoice Date</label>
                  <p>
                    {{ (alert?.invoice?.inv_date | caDate) || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                </div>
              </div>

            </mat-card-content>

          </mat-card>
      </div>
  </div>
</div>

    <mat-sidenav-container [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}" class="tabs-body app-content app-content-list dx-nodata-absolute">

          <dx-data-grid #dataGrid
                        *ngIf="alert?.rule_name !== 'CHARGE_TOTAL'"
                        (onSelectionChanged)="onSelectionChanged($event)"
                        [columns]="columns"
                        [dataSource]="inventories"
                        (onCellClick)="onCellClick($event)"
                        [width]="'100%'"
                        [height]="'100%'">

            <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
              <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.currency }}</div>
            </div>

            <div *dxTemplate="let d of 'chargeRateTemplate'">
              <div caGridTooltip class="right">{{  d.value | caNumber }}</div>
            </div>
          </dx-data-grid>

          <ca-pager *ngIf="alert?.rule_name !== 'CHARGE_TOTAL'" [gridPager]="defaultGridPager" [pageContext]="alertInventoryGridService" [query]="query" class="app-pager ca-default-border-top" (onChange)="onPageChange(query)"></ca-pager>

          <dx-data-grid
            *ngIf="alert?.rule_name === 'CHARGE_TOTAL'"
            #chargeGrid class="app-grid-with-pager app-content-list data-table-view"
            [columns]="chargeTotalColumns"
            [dataSource]="chargeTotalData"
            [height]="'100%'"
            [showColumnLines]="false"
          >

          <dxo-paging [enabled]="false" visible="false"></dxo-paging>
          </dx-data-grid>

    </mat-sidenav-container>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="inventoryFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter($event)">
    </new-app-filter>
    <app-alert-inventory-sneak-peek [inventory]="selection"
                                 (closeRequested)="sider?.close()"
                                 [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></app-alert-inventory-sneak-peek>
  </mat-sidenav>

</mat-sidenav-container>
