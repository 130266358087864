import {UpdateQueryOptions} from "../../common/state/query.actions";

export class UpdateInvoicesQuery {
  static readonly type = '[INVOICES] Update Query';

  constructor(public options?: UpdateQueryOptions) {
  }
}


export class LoadInvoiceStatuses {
  static readonly type = '[INVOICES] Load Invoice statuses';
  constructor() {}
}

export class PatchInvoiceStatuses {
  static readonly type = '[INVOICES] Patch Invoice statuses';
  constructor(public payload: { statuses: any[] }) {}
}
