import { Injectable, Optional } from "@angular/core";
import { BaseService } from "../../core/base.service";
import { UserSetting } from "./user-setting";
import { ApiService } from "../../core/api";
import {
  map,
  publishReplay,
  refCount,
  mergeMap,
  first,
  switchMap,
  tap,
} from "rxjs";
import { ConnectableObservable, from, Observable, of } from "rxjs";
import { Store } from "@ngxs/store";
import { SetUserSettings } from "../state/user.actions";

@Injectable()
export class UserSettingsService extends BaseService<UserSetting> {
  constructor(private apiService: ApiService, private store: Store) {
    super("setting");
  }

  cache() {
    return this.apiService.cache(this.name);
  }

  findByKey(key) {
    return this.me().pipe(
      map((settings) => {
        return settings[key];
      })
    );
  }

  // this will work only if settings data is already cached, otherwise it will not wait for settings to load,
  // this need to be refactored to return observable, but there are many place in code that are calling this funciton
  // so such refactoring would have big regression on app, so i will just leave comment for now (detected while working on GPX-5967)
  //
  // there are also many place i the code that have commented "columns() {" that needs to be cleaned up
  entrySettings(name) {
    let settings;
    this.settings().subscribe((setting) => {
      if (!setting[name]) {
        setting[name] = {};
      }
      settings = Object.assign({}, setting[name]);
    });

    return settings || {};
  }

  /**
   * This function has business logic like entrySettings, but this logic here is implemented in a different way. We had a problem with downloading the CSV. EntrySettings function isn't waiting for settings from the backend
   * @param name string
   */
  entrySettingsNew(name): Observable<any> {
    return this.settings().pipe(
      map((setting) => {
        return setting[name] || {};
      })
    );
  }

  loadSettings(name) {
    return this.settings().pipe(map((s) => s[name]));
  }

  me() {
    return this.store
      .select((state) => state.user)
      .pipe(
        first(),
        switchMap((state) => {
          if (Object.keys(state?.settings || {}).length) {
            // Permissions exist in the store, use them directly
            return of(JSON.parse(JSON.stringify(state.settings)));
          } else {
            // No permissions in store, fetch from backend
            const cache = this.cache();
            return this.apiService
              .get("user/me/settings", null, { cache })
              .pipe(
                tap((settings) => {
                  // Update user settings in store / commented out part in user.state.ts
                  /// TODO: check if this is good place to do it
                  this.store.dispatch(new SetUserSettings({ settings }));
                })
              );
          }
        })
      );
  }

  settings() {
    return this.me();
  }

  save(name: string, data: any) {
    return this.settings().subscribe((settings) => {
      const copyOfSettings = this.parseFetchedSettingsAndSpreadWithNewData(
        name,
        settings,
        data
      );

      if (JSON.stringify(settings) == JSON.stringify(copyOfSettings))
        return of(copyOfSettings);

      const cache = this.cache();
      cache.put("user/me/settings", copyOfSettings);

      const observable = this.apiService
        .put("user/me/settings", {
          settings: copyOfSettings,
          page: name,
        })
        .pipe(
          // Update user settings in store / commented out part in user.state.ts
          /// TODO: check if this is good place to do it
          switchMap(() =>
            this.store.dispatch(
              new SetUserSettings({ settings: copyOfSettings })
            )
          ),
          publishReplay()
        ) as ConnectableObservable<any>;

      observable.connect();
      return observable;
    });
  }

  // We had a problem when hiding attributes on a grid and restart filters. We had a situation where the app get settings from the database before our changes save in the database. We have to rename this function later
  /// TODO: remove completely, code is the same in saveUserSettings()
  saveGridFilterSettings(name: string, data: any) {}

  saveUserSettings(name: string, data: any) {
    return this.settings().pipe(
      mergeMap((settings) => {
        const copyOfSettings = this.parseFetchedSettingsAndSpreadWithNewData(
          name,
          settings,
          data
        );

        if (JSON.stringify(settings) == JSON.stringify(copyOfSettings))
          return of(copyOfSettings);

        const cache = this.cache();
        cache.put("user/me/settings", copyOfSettings);

        const observable = this.apiService
          .put("user/me/settings", {
            settings: copyOfSettings,
            page: name,
          })
          .pipe(
            map((x) => x[name]),
            // Update user settings in store / commented out part in user.state.ts
            /// TODO: check if this is good place to do it
            switchMap(() =>
              this.store.dispatch(
                new SetUserSettings({ settings: copyOfSettings })
              )
            ),
            publishReplay()
          ) as ConnectableObservable<any>;

        observable.connect();
        return observable;
      })
    );
  }

  // to avoid some code repetition
  parseFetchedSettingsAndSpreadWithNewData(
    name: string,
    settings: any,
    data: any
  ) {
    const copyOfSettings = JSON.parse(JSON.stringify(settings));

    if (!copyOfSettings[name]) {
      copyOfSettings[name] = {};
    }
    copyOfSettings[name] = { ...copyOfSettings[name], ...data };

    return copyOfSettings;
  }
}
