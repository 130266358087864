import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@ngxs/store';
import {LoadPermissions} from "../permission.acctions";
import {map} from "rxjs";
import {PermissionsState} from "../permissions.state";

@Injectable({
  providedIn: 'root'
})
export class PermissionsResolver  {

  constructor(private store: Store) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.store.dispatch(new LoadPermissions())
      .pipe(
        map(() => this.store.selectSnapshot(PermissionsState).invoice)
      )
      .toPromise();
  }
}
