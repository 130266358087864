import {EventEmitter, Injectable, Output} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Contract} from './contract';
import {environment} from '../../../environments/environment';
import Auth from "../../shared/user-session/auth";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import Query from 'app/core/query/query';
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs";

@Injectable()
export class ContractService extends BaseService<Contract> {
  section: string = 'section';
  schedule: string = 'schedule';
  document: string = 'document';

  @Output() toolbarAction: EventEmitter<any> = new EventEmitter<{
    action: any,
    params: any
  }>();

  constructor(public http: HttpClient, private entityLock: EntityLockService) {
    super('contract', entityLock);
  }

  findOnlyContracts(query?: Query): any {
    return this.httpService().get([this.name, 'findOnlyContracts'], query);
  }

  public findAllSections(contractId, query) {
    return this.httpService().get([this.name, contractId, 'sections'], query);
  }

  public findOneContractByVendorId(contractId) {
    return this.httpService().get([this.name, contractId, 'findOneContractByVendorId']);
  }

  public findAllSectionsByInstance(contractId, instanceId, query?) {
    return this.httpService().get([this.name, contractId, 'sections', instanceId], query);
  }

  public findScheduleSeriesById(id) {
    return this.httpService().get([this.name, 'schedule_series', id]);
  }

  public findScheduleCommitmentTypeById(id) {
    return this.httpService().get([this.name, 'schedule_commitment_type', id]);
  }

  public findAllSchedulesByShell(contractId, query?) {
    return this.httpService().get([this.name, contractId, 'schedules'], query);
  }

  public findAllScheduleTermCondition(contractId, query?) {
    return this.httpService().get([this.name, contractId, 'schedules-term-condition'], query);
  }

  public findAllScheduleCommitment(contractId, query?) {
    return this.httpService().get([this.name, contractId, 'schedules-commitment'], query);
  }

  findSectionForEdit(id: number, contractSectionId: number) {
    return this.httpService().get([this.name, id, this.section, contractSectionId, 'edit']);
  }

  findAllByVendor(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'findAllByVendor'], transformedQuery);
  }

  public createSection(contractId, item) {
    return this.httpService().post([this.name, contractId, this.section], item);
  }

  public updateSection(contractId, contractSectionId, item) {
    return this.httpService().put([this.name, contractId, this.section, contractSectionId], item);
  }

  deleteSection(contractId, contractSectionId) {
    return this.httpService().delete([this.name, contractId, this.section, contractSectionId]);
  }

  uploadDocument(id: number, files) {
    return this.httpService().post([this.name, id, 'document'], files)
  }

  downloadDocument(id: number, contractID, folderName) {
    let cookie = Auth.getSession();
    let params: HttpParams = new HttpParams();
    params.set('authorization', cookie.token);

    let endPoint: string = [environment.API_URL, this.name, '/', id.toString(), '/document'].join('');

    try {
      return this.http.get(endPoint, {
          params: {
            contractID, folderName
          },
          responseType: 'blob',
          headers: {
            authorization: `Bearer ${cookie.token}`
          }
        }
      )
      // .pipe(map(this.extractPdfBlob))
    } catch (error) {
      console.log(error, 'ERROR')
      // this.handleError(error)
    }
  }

  deleteDocument(id: number, documentId: number) {
    return this.httpService().delete([this.name, id, 'document']);
  }

  public createSchedule(contractId, item) {
    return this.httpService().post([this.name, contractId, this.schedule], item)
  }

  public updateSchedule(contractId, item) {
    return this.httpService().put([this.name, contractId, this.schedule], item);
  }

  deleteSchedule(id) {
    return this.httpService().delete([this.name, 'deleteSchedule', id])
  }

  findSchedules(query?: Query): any {
    return this.httpService().get([this.name, 'schedules'], query);
  }

  findSchedule(id): any {
    return this.httpService().get([this.name, 'schedule', id]);
  }

  findScheduleById(id, query?: Query): any {
    return this.httpService().get([this.name, 'schedule', id]);
  }

  findRateById(id, query?: Query): any {
    return this.httpService().get([this.name, 'rates', id]);
  }

  public createDocument(contractId, document) {
    return this.httpService().post([this.name, contractId, this.document], document)
  }

  public findDocuments(contractId, query?) {
    return this.httpService().get([this.name, contractId, 'documents'], this.toFilter(query));
  }

  deleteContractDocument(id: number) {
    return this.httpService().delete([this.name, id, 'documents']);
  }

  public findDuplicateDocument(query?) {
    return this.httpService().get([this.name, 'duplicateDocuments'], this.toFilter(query));
  }

  findAllRates(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'rates'], transformedQuery);
  }

  findAllAuditsRates(query?: Query): any {
    query.limit = 10000;
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get(['audit', 'ratesAudits'], this.toFilter(transformedQuery));
  }

  saveRates(contractId, dataToSave): any {
    return this.httpService().post([this.name, contractId, 'saveRates'], dataToSave)
  }

  deleteRates(rateIds) {
    return this.httpService().delete([this.name, 'deleteRates'], {rateIds})
  }

  createTermsAndConditions(query?: Query): any {
    return this.httpService().post([this.name, 'termAndCondition'], query);
  }

  findAllTermsAndConditions(query?: Query): any {
    return this.httpService().get([this.name, 'termsAndConditions'], query);
  }

  findTCForEdit(id: number): any {
    return this.httpService().get([this.name, id, 'termAndCondition'])
  }

  updateTermsAndConditions(id, value): any {
    return this.httpService().put([this.name, 'updateTC', id], value)
  }

  deleteTC(id): any {
    return this.httpService().delete([this.name, 'deleteTC', id])
  }

  createSvcOrderTermsAndConditions(query?: Query): any {
    return this.httpService().post([this.name, 'createSvcOrderTC'], query);
  }

  findAllSvcOrderTermsAndConditions(query?: Query): any {
    return this.httpService().get([this.name, 'findAllSvcOrderTC'], query);
  }

  findSvcOrderTCForEdit(id: number): any {
    return this.httpService().get([this.name, id, 'svcOrderTCForEdit'])
  }

  updateSvcOrderTermsAndConditions(id, value) {
    return this.httpService().put([this.name, 'updateSvcOrderTC', id], value)
  }

  deleteSvcOrderTC(id): any {
    return this.httpService().delete([this.name, 'deleteSvcOrderTC', id])
  }

  isScheduleContainsAudit(scheduleId) {
    return this.httpService().get([this.name, scheduleId, 'isScheduleContainsAudit'])
  }

}
