import { Injectable } from "@angular/core";
import { AccountLookupProvider } from "app/account/core/account-lookup.provider";
import { AccountService } from "app/account/core/account.service";
import { ChargeTypeLookupProvider } from "app/charge/core/charge-type-lookup.provider";
import { DictionaryService } from "app/dictionary/core/dictionary.service";
import { VendorLookupProvider } from "app/vendor/core/vendor-lookup.provider";
import { VendorService } from "app/vendor/core/vendor.service";
import { DefaultDateRangesLookupProvider } from "../../core/filter/default-date-ranges-lookup.provider";
import { FilterService } from "../../core/filter/filter.service";
import { CurrencyLookupProvider } from "../../location/core/currency-lookup.provider";
import { LocationService } from "../../location/core/location.service";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { invoiceChargeProfileBrowserFilterContext } from "./invoice-charge-profile-browser-filter.context";

@Injectable()
export class InvoiceChargeProfileBrowserFilterService extends FilterService {
  customRanges: any = [];
  excludeRanges: any = [];

  constructor(
    public userSettingsService: UserSettingsService,
    public locationService: LocationService,
    public dictionaryService: DictionaryService,
    public vendorService: VendorService,
    public accountService: AccountService
  ) {
    super(
      "app.invoice.charge-profile-browser-filter",
      invoiceChargeProfileBrowserFilterContext,
      userSettingsService
    );

    this.addLookupProvider(
      "currency",
      new CurrencyLookupProvider(locationService)
    );
    this.addLookupProvider(
      "chargeType",
      new ChargeTypeLookupProvider(dictionaryService)
    );
    this.addLookupProvider(
      "vendor",
      new VendorLookupProvider(vendorService, true)
    );
    this.addLookupProvider(
      "accountLP",
      new AccountLookupProvider(accountService)
    );
    this.addLookupProvider(
      "defaultDateRanges",
      new DefaultDateRangesLookupProvider(
        this.customRanges,
        this.excludeRanges,
        true
      )
    );
  }
}
