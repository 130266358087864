import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { forkJoin, of as observableOf } from 'rxjs';

import { DialogService } from '../../../shared/dialog/dialog.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { AlertService } from '../../../shared/alert/alert.service';
import { DictionaryService } from '../../core/dictionary.service';
import { LookupModel } from '../../core/lookup.model';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { LOOKUP_MODELS_ENUM } from '../../core/lookup-models.enum';
import {PermissionService} from '../../../permissions/core/permission.service';
import {debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap} from "rxjs";
import Query from "../../../core/query/query";
import { ConfigService } from "../../../core/config/config.service";
import { Config } from 'app/core/config/config';
import {LocationService} from "../../../location/core/location.service";

@Component({
  selector: 'app-dictionary-manage-dialog',
  templateUrl: './dictionary-manage-dialog.component.html',
})
export class DictionaryManageDialogComponent extends PageManageDialogComponent implements OnInit {
  dictionary: LookupModel;
  isSystem: boolean;

  isSys: boolean;
  isEdit: boolean;

  readonly SITE_TYPE_LOOKUP = LOOKUP_MODELS_ENUM.SITE_TYPE.modelName;
  readonly CONTRACT_SCHEDULE_CATEGORY = LOOKUP_MODELS_ENUM.CONTRACT_SCHEDULE_CATEGORY.modelName;
  readonly CONTRACT_SCHEDULE_SERIES = LOOKUP_MODELS_ENUM.CONTRACT_SCHEDULE_SERIES.modelName;
  readonly SUBCLIENT = LOOKUP_MODELS_ENUM.SUBCLIENT.modelName;

  messages: IMessagesResourceService;

  public selectedSiteType = { value: '' };

  readonly MESSAGES_MODULE: string = 'dictionary';

  deletePermission = false;
  subClientUnique = true;

  subclientValueRegex: any;

  usingSubclientEntity = false;
  usingSubclientCurrency = false;
  availableCurrencies = [];

  constructor(public formBuilder: FormBuilder,
    public alertService: AlertService,
    public dictionaryService: DictionaryService,
    public dialogService: DialogService,
    private permissionService: PermissionService,
    public dialogRef: MatDialogRef<DictionaryManageDialogComponent>,
    public configService: ConfigService,
    public locationService: LocationService
  ) {
    super(dialogService, dialogRef);
    this.dictionary = this.dictionary || new LookupModel();

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.loadPermissions().subscribe(() => {});

    this.locationService.findAllActiveCountriesCurrencies(new Query({
      where: { 'country.is_enabled': true }
    }))
      .subscribe(res => {
        if (!res?.items) return;

        this.availableCurrencies = Array.from(new Set(res.items.map(item => item.currency_id)))
          .sort((a, b) => +a - +b);
      });

    this.isUpdate = this.isEdit;
    this.configService.findAll().subscribe((config: Config) => {
      this.usingSubclientEntity = config?.subclient?.using_entity_id;
      this.usingSubclientCurrency = config?.subclient?.using_base_currency;

      this.subclientValueRegex = new RegExp(config.subclient.validation_regex ? config.subclient.validation_regex : /.*/);
      const isSubClientLookupModel = this.dictionary?.lookup_model === LOOKUP_MODELS_ENUM.SUBCLIENT.modelName
      this.form = this.formBuilder.group({
        lookupModel: [this.dictionary?.lookup_model, Validators.required],
        value: !isSubClientLookupModel ? [this.dictionary?.value, Validators.required] :
          [this.dictionary?.value, [Validators.required, Validators.pattern(this.subclientValueRegex)]],
        site_type_id: this.dictionary?.lookup_model === 'SiteOwnerCategory' ?
          [this.dictionary?.site_type_id, Validators.required] : [this.dictionary?.site_type_id],
        category_id: this.dictionary?.lookup_model === this.CONTRACT_SCHEDULE_SERIES ?
          [this.dictionary?.category_id, Validators.required] : [this.dictionary?.category_id],
        entity_id: this.dictionary?.lookup_model === this.SUBCLIENT && this.usingSubclientEntity ?
          [this.dictionary?.entity_id, Validators.required] : [this.dictionary?.entity_id],
        currency_id: this.dictionary?.lookup_model === this.SUBCLIENT && this.usingSubclientCurrency ?
          [this.dictionary?.currency_id] : [this.dictionary?.currency_id]
      });

      this.isSys = !!this.dictionary.system;
      this.formTitle = this.isUpdate ? 'Edit Dictionary Item' : 'Create Dictionary Item';

      if (this.dictionary.lookup_model) {
        this.isSystem = !!this.dictionaryService.getLookupModelInfo(this.dictionary.lookup_model).system ||
        this.dictionary.lookup_model === 'DnpReasonCode' || this.dictionary.is_system_row;
      }

      if (this.dictionary.lookup_model === LOOKUP_MODELS_ENUM.SUBCLIENT.modelName) {
        this.hasValue('value').subscribe(result => {
          this.subClientUnique = result;
        });
      }

      this.afterInit();
    });
  }


  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      if (this.isUpdate) {
        this.update(Object.assign({}, this.dictionary, value));
      } else {
        this.create(value);
      }
    } else {
      this.alertService.error('', this.messages.get('FORM_INVALID'));
    }
  }

  create(value) {
    this.toggleDialogButtons()
    if (value.lookupModel) {
      let lookupModelInfo = this.dictionaryService.getLookupModelInfo(value.lookupModel);

      // Only non system lookup can be created
      if (lookupModelInfo && !lookupModelInfo.system) {
        this.dictionaryService.createLookup(lookupModelInfo.modelName, value)
          .subscribe((result) => {
            result.lookup_model = lookupModelInfo.modelName;
            result.display_name = lookupModelInfo.displayName;
            result.system = false;
            result.key = '';

            this.closeDialog(result, true);
          }, (err) => {
            this.toggleDialogButtons(false)
            this.alertService.error('', this.messages.get('CREATE_ERROR'));
          });
      }
    }
  }

  onPickerChanged(event) {
    if (event.value !== 'SiteOwnerCategory') {
      this.form.value.site_type_id = null;
      if (event && event.value) {
        this.dictionary.lookup_model = event.value;

        this.form.patchValue({
          value: null
         })

        this.form.controls["value"].setValidators([Validators.required]);
        this.form.controls['value'].updateValueAndValidity();

        if (this.dictionary.lookup_model === LOOKUP_MODELS_ENUM.SUBCLIENT.modelName) {
          this.form.controls["value"].setValidators([Validators.required, Validators.pattern(this.subclientValueRegex)]);
          this.form.controls['value'].updateValueAndValidity();

          this.hasValue('value').subscribe(result => {
            this.subClientUnique = result;
          });
        }
      }
    }
  }

  update(value) {
    this.toggleDialogButtons()

    const model = value.lookup_model;
    let lookupModelInfo = this.dictionaryService.getLookupModelInfo(model);

    if (lookupModelInfo) {
      this.dictionaryService.updateLookup(lookupModelInfo.modelName, value.id, value)
        .subscribe((result) => {
          this.closeDialog(result, true);
        }, (err) => {
          this.toggleDialogButtons(false)
          this.alertService.error('', this.messages.get('UPDATE_ERROR'));
        });
    }
  }

  delete(event) {
    this.toggleDialogButtons()
    if (this.isUpdate && event) {
      let lookupModelInfo = this.dictionaryService.getLookupModelInfo(this.dictionary.lookup_model);

      // Only non system lookup can be deleted
      if (lookupModelInfo && !lookupModelInfo.system) {
        this.dictionaryService.removeLookup(lookupModelInfo.modelName, this.dictionary.id)
          .subscribe((deleteResult) => {
            if (deleteResult) {
              this.dialogRef.close({ deleted: true });
            }
          }, (err) => {
            this.toggleDialogButtons(false);
            let message = this.messages.get('DELETE_CONFLICT_ERROR');
            setTimeout(()=> {
              this.alertService.error('', message);
            }, 500);
          })
      }
    }
  }

  public parseErrorMessage(res) {
    let body = res.json();
    return body.message || {};
  }

  cancel() {
    this.dictionaryService.cancelEdit();
    this.closeDialog();
  }

  loadPermissions() {
    const deleteDictionary$ = this.permissionService.isAllowed("dictionary", "DELETE");
    return forkJoin(
      deleteDictionary$
    ).pipe(map(([deleteDictionary]) => {
      this.deletePermission = deleteDictionary;
    }));
  }

  hasValue(field) {
    return this.form.controls[field].valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((value) => {
        if (value && !this.form.controls[field].hasError(field)) {
          const query = new Query({ limit: 1 });
          query.set(field, {'$ilike': value});
          query.set('id', {'$ne': (this.dictionary && this.dictionary.id) ? this.dictionary.id : 0});
          return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.SUBCLIENT.modelName, query);
        }
        return observableOf({total: 0});
      }),

      map((result: any) => !!!result.total),);
  }
}
