import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class CommonAlertGridService extends GridService {
	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.common.alerts-grid', userSettings);
	}

	defaultColumns = [
		{
			caption: '',
			headerCellTemplate: 'selectionBadgeHeaderTemplate',
			cellTemplate: 'selectionBadgeCellTemplate',
			fixed: true,
			width: 40,
			allowSorting: false
		},
		{
			caption: 'Alert ID',
			dataField: 'id',
			width: 120,
			sortOrder: 'desc',
			alignment: 'left'
		},
		{
			caption: 'Type',
			dataField: 'type.value',
			width: 120
		},
		{
			caption: 'Vendor',
			dataField: 'vendor.name',
			width: 120
		},
		{
			caption: 'Account',
			dataField: 'account_no',
			width: 150
		},
		{
			caption: 'Invoice Number',
			dataField: 'invoice.sp_inv_num',
			width: 150
		},
		{
			caption: 'Status',
			dataField: 'status.value',
			width: 120
		},
		{
			caption: 'Message',
			dataField: 'message',
			width: 220
		},
		{
			caption: 'Priority',
			dataField: 'priority.value',
			width: 120
		},
		{
			caption: 'Owner',
			dataField: 'owner.full_name',
			width: 120
		},
		{
			caption: 'Create Time',
			dataField: 'created_at',
			dataType: 'datetime',
			cellTemplate: 'createdAtTemplate',
			alignment: 'left',
			width: 160
		},
		{
			caption: 'Created by',
			dataField: 'creator.full_name',
			width: 130
		}
	];

	/*columns() {
    let settings = this.userSettings.entrySettings(this.name);
    if (settings && settings.columns) {
      return settings.columns;
    }

    return this.defaultColumns;
  }*/
}
