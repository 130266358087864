export const alertDetailsFilterContext = {
	filters: [
		{
			field: 'charge_id',
			label: 'Charge ID',
			type: 'number'
		},
		{
			field: 'sp_serv_id',
			label: 'SPID',
			type: 'string'
		},
		{
			field: 'chg_amt',
			label: 'Charge Amount',
			type: 'negativeNumber',
			index: 16
		},
		{
			field: 'chg_class',
			label: 'Charge Type',
			editor: 'checkboxList',
			lookupProviderName: 'chargeType'
		},
		{
			field: 'message',
			label: 'Message',
			type: 'string'
		},
		{
			field: 'detail',
			label: 'Detail',
			type: 'string'
		}
	]
};
