import { Injectable } from '@angular/core';
import Query from 'app/core/query/query';
import { Observable } from 'rxjs';
import { BaseService } from '../../core/base.service';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';
import { OrderService } from './order-service';

@Injectable()
export class OrderServiceService extends BaseService<OrderService> {
  constructor( private entityLock: EntityLockService) {
    super('order-service', entityLock);
  }

  createOnlyService(item: OrderService): any {
    return this.httpService().post([this.name, 'service'], item);
  }

  public findAllList(query: Query): Observable<any> {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'list'], this.toFilter(transformedQuery));
  }

  public setOrderServicesToStatus(services: Array<OrderService>, status: number): Promise<any> {
    const promises: Array<Promise<any>> = services.map(service => {
      service.state = status;
      return this.update(service.id, service).toPromise();
    });

    return Promise.all(promises);
  }
}
