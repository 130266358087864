import { LookupModel } from '../../dictionary/core/lookup.model';
export class DisputeStatusLookup {
	NEW: LookupModel;
	FILED: LookupModel;
	CLOSED_LOST: LookupModel;
	IN_PROGRESS: LookupModel;
	CLOSED_WON: LookupModel;
	CANCELLED: LookupModel;

	constructor(data?: any) {
		if (data) {
			Object.assign(this, data);
		}
	}
}
