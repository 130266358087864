<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-title">{{ (alert?.type?.value || '') + ' Alert: ' + (alert?.rule_name || 'N/A') }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

  <button *permission="{'action':'View', 'key':'gl'}" [disabled]="!(alert && alert.invoice && alert.invoice.invoice_id)" (click)="goToRuleExecution()" mat-icon-button matTooltip="Rule Execution">
    <ca-icon [key]="'GL_PROCESS_RULES'" ></ca-icon>
  </button>

  <button *permission="{'action':'View', 'key':'invoice'}"  [disabled]="!(alert && alert.invoice && alert.invoice.invoice_id)" (click)="goToInvoiceDetails()" mat-icon-button matTooltip="View Invoice Details">
    <ca-icon [key]="'VIEW_INVOICE_DETAILS'"></ca-icon>
  </button>

  <button mat-icon-button matTooltip="Refresh" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <button *permission="{'action':'Modify', 'key':'gl'}" [disabled]="!selectedCharges.length || !isSelectionValid" [ngClass]="{'hidden': !tabGroup.isActive(2)}" mat-icon-button (click)="excludeItems()" matTooltip="Exclude">
    <ca-icon [key]="'EXCLUDE'" [tooltip]="'Exclude'"></ca-icon>
  </button>
    <div class="vertical-divider"></div>
    <button [disabled]="!tabGroup.isActive(2)" mat-icon-button (click)="csv()" matTooltip="Download">
      <ca-icon [key]="'DOWNLOAD'" [tooltip]="'Download CSV'"></ca-icon>
    </button>
  <!--  <button [disabled]="!tabGroup.isActive(2) || isGridSettingsDisabled" mat-icon-button (click)="gridSettings()">-->
  <!--    <ca-icon [key]="'GRID_SETTINGS'" [tooltip]="'Settings'"></ca-icon>-->
  <!--  </button>-->
  <!--  <div class="vertical-divider"></div>-->
  <!--  <button [disabled]="chargesSelectionActive || !tabGroup.isActive(2)"-->
  <!--          [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2)}"-->
  <!--          (click)="toggleFilter()" mat-icon-button>-->
  <!--    <ca-icon [key]="'FILTER'" [tooltip]="'Filter'"></ca-icon>-->
  <!--  </button>-->
  <!--  <button #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"-->
  <!--          [disabled]="!selection || selectedCharges?.length > 1 || !tabGroup.isActive(2)"-->
  <!--          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" mat-icon-button>-->
  <!--    <ca-icon [key]="'INFO'" [tooltip]="'Info'"></ca-icon>-->
  <!--  </button>-->
</mat-toolbar>


<mat-sidenav-container class="app-content tabbed-content">

  <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="currentTabIndex">
    <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs">
    </mat-tab>
  </mat-tab-group>


  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
    <div class="flex-container ca-card custom-mat-card-container" fxLayout="row">
      <div fxFlex>
        <mat-card fxFlex fxFlexFill caCard>

          <mat-card-header>
            Alert Info
          </mat-card-header>

          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Category</label>
                <p>
                  {{ alert?.category?.value || 'N/A' }}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Created At</label>
                <p>
                  {{ (alert?.created_at | caDateTime) || 'N/A' }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Created By</label>
                <p>
                  {{ alert?.creator?.full_name || 'System' }}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Owner</label>
                <p>
                  {{ alert?.owner?.full_name || PLACEHOLDERS.OWNER_UNASSIGNED }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Priority</label>
                <p>
                  {{ alert?.priority.value || 'N/A' }}
                </p>
              </div>

              <div fxFlex class="entry-list-item">
                <label>Status</label>
                <p>
                  {{ alert?.status.value || 'N/A' }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Vendor</label>
                <p>
                  {{ (alert?.vendor.name) || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>BAN</label>
                <p>
                  {{ (alert?.account_no) || 'N/A' }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Invoice Number</label>
                <p>
                  {{ (alert?.invoice?.sp_inv_num) || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Last Modified At</label>
                <p>
                  {{ (alert?.last_modified_at | caDate) || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Closed At</label>
                <p>
                  {{ (alert?.closed_at | caDate) || 'N/A' }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Message</label>
                <p>
                  {{ alert?.message || 'N/A' }}
                </p>
              </div>
            </div>
          </mat-card-content>

        </mat-card>
      </div>
    </div>
  </div>


  <mat-sidenav-container [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}" class="tabs-body grid-container grid-with-pager app-content-list dx-nodata-absolute" style="overflow-y: hidden">

    <dx-data-grid #dataGrid class="app-grid-with-pager grid-with-checkboxes"
                  [columns]="columns"
                  [dataSource]="validationAlerts"
                  (onSelectionChanged)="onSelectionChanged($event)"
                  (onRowClick)="onRowClick($event)"
                  (onCellClick)="onCellClick($event)"
                  >
      <dxo-selection
          mode="multiple"
          [allowSelectAll]="validationAlerts?.length">
      </dxo-selection>

      <div *dxTemplate="let d of 'selectionBadgeHeaderTemplate'" class="grid-selection-badge-container">
        <a class="selection-badge grid-badge"
          matTooltip="Selection"
          (click)="loadSelected()"
          [ngClass]="{'active':chargesSelectionActive}"
        >
          {{selectedCharges.length}}
        </a>
      </div>
      <div *dxTemplate="let d of 'selectionBadgeCellTemplate'">
      </div>
      <div *dxTemplate="let d of 'statusCellTemplate'">
        <div caGridTooltip>{{ d.value ? 'Excluded' : 'Included' }}</div>
      </div>
    </dx-data-grid>
    <ca-pager [gridPager]="defaultGridPager" [pageContext]="alertPreGlValidationGridService" [query]="query" class="app-pager ca-default-border-top"
              (onChange)="onPageChange(query)"></ca-pager>
  </mat-sidenav-container>


  <!--  <mat-sidenav #panelSide mode="side" align="end" opened="false">-->
  <!--    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"-->
  <!--                    [query]="query"-->
  <!--                    [filterService]="alertChargesFilterService"-->
  <!--                    (filterRequested)="filterData($event)"-->
  <!--                    (clearFilterRequested)="clearFilter($event)">-->
  <!--    </new-app-filter>-->
  <!--    <app-alert-charge-sneak-peek [charge]="selection"-->
  <!--                                 (closeRequested)="sider?.close()"-->
  <!--                                 [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></app-alert-charge-sneak-peek>-->
  <!--  </mat-sidenav>-->

</mat-sidenav-container>
