
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DialogService } from '../../shared/dialog/dialog.service';
import { ProjectService } from '../core/project.service';
import { ProjectManageDialogComponent } from '../shared/project-manage-dialog/project-manage-dialog.component';
import { Project } from '../core/project';
import { PageListComponent } from '../../core/page-list.component';
import { ProjectQuery } from '../core/project.query';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import { AlertService } from '../../shared/alert/alert.service';
import { ProjectGridService } from '../core/project-grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { PageContext } from '../../core/page.context';
import { LoaderService } from '../../shared/loader/loader.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { DataLockDialogService } from '../../core/data-lock/data-lock-dialog.service';
import { ProjectFilterService } from '../core/project-filter.service';
import { GridService } from '../../shared/grid/grid.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
})

export class ProjectListComponent extends PageListComponent implements AfterViewInit {

  public query: ProjectQuery = new ProjectQuery({
    orderBy: [['name', 'ASC'], ['project_id', 'ASC']]
  });
  public projects: Array<Project>;
  public totalProjects: number;
  public selection: Project;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public sorting: any[][];

  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'project';
  readonly CORE_MODULE: string = 'core';

  constructor(public projectService: ProjectService,
              public dialogService: DialogService,
              public alertService: AlertService,
              public settingsService: UserSettingsService,
              public projectGridService: ProjectGridService,
              public loaderService: LoaderService,
              public dataLockDialogService: DataLockDialogService,
              public projectFilterService: ProjectFilterService) {
    super(new PageContext({
      name: 'app.project.project-list',
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  loadData(query?: ProjectQuery) {

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.projectService.findAll(query)
      .subscribe((result) => {
          this.projects = result.items;
          this.query.total = this.totalProjects = result.total;

          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        },
        (error) => {
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        });
  }

  filterData(query: ProjectQuery) {
    this.query = query;
    this.loadData(query);
  }

  clearFilter() {
    this.query.where = null
    this.loadData(this.query);
  }

  refresh() {
    this.loadData(this.query);
  }

  newProject() {
    this.dialogService.open(ProjectManageDialogComponent, {
      project: {}
    })
      .afterClosed()
      .subscribe((result) => {
        this.refresh();
        if (result) {
          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  editProject(project: Project) {
      this.projectService.findByIdForEdit(project.id)
      .subscribe((project: Project) => {

        if (project) {
          this.dialogService.edit(
            ProjectManageDialogComponent,
            {
              project
            }).subscribe(result => {
              let obs = observableOf(result);
              obs.pipe(mergeMap(x => x.afterClosed()))
                .subscribe((result) => {
                  if (result) {
                    this.refresh()
                    if (result['cancelByTimer'] || result['status'] == 2) {
                      return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
                    } else if (result['deleted']) {
                      setTimeout(() => {
                        this.alertService.snackBar.dismiss();
                        this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
                      }, 255);
                    } else if (result['id']) {
                      this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
                    }
                  }
                })
            })
        }
    })
  }

  csv() {

    const { projectGridService, projectService, query } = this;
    projectGridService.csvMap().subscribe(fields => {
      projectService.exportToCSV(
        'projects',
        {
          fields,
          query
        }
      );
    });
  }

  onSelectionChanged(row) {
    this.selection = <Project>row.selectedRowsData[0];
  }

  ngOnInit() {
    this._init();
  }

  ngAfterViewInit(): void {
    this.projectGridService.create(this.dataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.projectGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.projectGridService;
  }

  getDefaultSort(): any {
    return [['name', 'ASC'], ['project_id', 'ASC']];
  }

  _init() {
    this.prepareList();
    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.refresh();
    }
    this.loadData(this.query);
  }
}
