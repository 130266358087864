
import {mergeMap} from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { DataLockService } from '../../core/data-lock/data-lock.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { LookupModel } from '../../dictionary/core/lookup.model';
import { ORDER_SERVICE_DISCONNECT_STATUS } from '../../order-service/core/order-service-disconnect-status.enum';
import { ORDER_SERVICE_STATUS } from '../../order-service/core/order-service-status.enum';
import { OrderServiceService } from '../../order-service/core/order-service.service';
import { AlertService } from '../../shared/alert/alert.service';
import { ConfirmDialogComponent } from '../../shared/dialog/confirm-dialog/confirm-dialog.component';
import { DialogService } from '../../shared/dialog/dialog.service';
import { Flow } from '../../shared/flow/flow';
import { FlowStep } from '../../shared/flow/flow-step';
import { FlowService } from '../../shared/flow/flow.service';
import { Notes } from '../../shared/notes/core/notes';
import { NotesService } from '../../shared/notes/core/notes.service';
import { UserService } from '../../user/core/user.service';
import { OrderApprovalManageComponent } from '../flow/order-approval-manage/order-approval-manage.component';
import { OrderVendorAckDateComponent } from '../flow/order-vendor-ack-date/order-vendor-ack-date.component';
import { OrderSentToVendor } from "../flow/order-sent-to-vendor/order-sent-to-vendor.component";
import { OrderVendorApprovalManageComponent } from '../flow/order-vendor-approval-manage/order-vendor-approval-manage.component';
import { Order } from './order';
import { ORDER_STATUS } from './order-status.enum';
import { ORDER_TYPE } from './order-type.enum';
import { OrderService } from './order.service';
import { LoaderService } from 'app/shared/loader/loader.service';
import { OrderResetToNewManageComponent } from '../shared/order-reset-to-new-manage/order-reset-to-new-manage.component';
import { LOOKUP_ENUM } from 'app/dictionary/core/lookup.enum';
import { InventoryService } from 'app/inventory/core/inventory.service';

@Injectable()
export class OrderFlowService extends FlowService {
  public me: any = {};
  public order: Order;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'order';
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  public onStatusChange: EventEmitter<any> = new EventEmitter();
  public onOrderChange: EventEmitter<Order> = new EventEmitter();

  readonly ORDER_STATUS_ENUM = ORDER_STATUS;
  readonly ORDER_SERVICE_STATUS_ENUM = ORDER_SERVICE_STATUS;
  readonly ORDER_SERVICE_DISCONNECT_STATUS_ENUM = ORDER_SERVICE_DISCONNECT_STATUS;
  readonly ORDER_TYPE = ORDER_TYPE;

  public orderStatuses: Array<LookupModel>;

  public statuses: any;

  constructor(public orderService: OrderService,
              public orderServiceService: OrderServiceService,
              public alertService: AlertService,
              public dialogService: DialogService,
              public notesService: NotesService,
              public userService: UserService,
              public dataLockService: DataLockService,
              public dictionaryService: DictionaryService,
              public loaderService: LoaderService,
              public inventoryService: InventoryService) {
    super();

    userService.me().subscribe((result) => {
      this.me = result;
    });
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.ORDER_STATUS.modelName).subscribe((res: any) => {
      this.orderStatuses = res.items;
    })
  }

  readonly DATA_LOCK_CLOSE_STATUS = {
    CANCEL_BY_USER: 0,
    TIME_EXTEND: 1,
    CANCEL_BY_TIMER: 2
  };

  elapsedTimeModal(status:any){
    return status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER && status !== this.DATA_LOCK_CLOSE_STATUS.TIME_EXTEND && status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER
  }

  // Gets step name, and returns value from dictionary
  generateName(uid: number) {
    return this.orderStatuses.find(i => i.id === uid).value;
  }

  public async refreshOrder() {
    this.loaderService.displayLoader();
    await this.orderService.findById(this.order.id).toPromise();
    this.loaderService.hideLoader();
  }

  createFlow(order: Order): Flow {
    this.order = order;
    this.flow = super.create({currentStep: 1});

    let status: number = order.status_id;
    if (!status) {
      status = this.ORDER_STATUS_ENUM.NEW;
    }

    this.statuses = {
      'NEW': this.generateName(this.ORDER_STATUS_ENUM.NEW) || 'New',
      'NEW_REJECTED': this.generateName(this.ORDER_STATUS_ENUM.NEW_REJECTED) || 'New (Rejected)',
      'NEW_RESET': this.generateName(this.ORDER_STATUS_ENUM.NEW_RESET) || 'New (Reset)',
      'RFA': this.generateName(this.ORDER_STATUS_ENUM.RFA) || 'Ready for Approval',
      'RFA_PRIOR_REJECT': this.generateName(this.ORDER_STATUS_ENUM.RFA_PRIOR_REJECT) || 'Ready for Approval (Prior Reject)',
      'AR': this.generateName(this.ORDER_STATUS_ENUM.APPROVED) || 'Approved',
      'SENDTOVENDOR': this.generateName(this.ORDER_STATUS_ENUM.SENT_TO_VENDOR) || 'Send to Vendor',
      'SENDTOVENDOR_REWORK': this.generateName(this.ORDER_STATUS_ENUM.SENT_TO_VENDOR_REWORK) || 'Send to Vendor (Rework)',
      'VENDORACK': this.generateName(this.ORDER_STATUS_ENUM.VENDOR_ACK_DATE) || 'Vendor Ack Date',
      'VENDOR_REJECT': this.generateName(this.ORDER_STATUS_ENUM.VENDOR_REJECT) || 'Vendor Reject',
      'VENDOR_ACCEPT_RECEIVED': this.generateName(this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED) || 'Vendor Accept Received',
      'VENDORACC': this.generateName(this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED) || 'Vendor Accept/Reject',
      'COMPLETED': this.generateName(this.ORDER_STATUS_ENUM.COMPLETED) || 'Complete',
      'CANCELLED': this.generateName(this.ORDER_STATUS_ENUM.CANCELLED) || 'Cancelled',
    }

    if(order.type_id === ORDER_TYPE.RECORD_ONLY){
      this.flow.steps.push(new FlowStep({
        name: this.statuses.NEW, code: 1,
        revertStatusAlert: `To reset to ${this.statuses.NEW}, item needs to be in ${this.statuses.RFA} state before.`
      }));
      this.flow.steps.push(new FlowStep({
        name: this.statuses.RFA, code: 2,
        progressStatusAlert: 'An order needs at least 1 service',
        revertStatusAlert: `To reset to ${this.statuses.RFA}, item needs to be in Approved state before.`
      }));
      this.flow.steps.push(new FlowStep({
        name: this.statuses.AR, code: 3,
        progressStatusAlert: `Item needs to be in ${this.statuses.RFA} state before.`,
        revertStatusAlert: `To reset to Approved, item needs to be in ${this.statuses.SENDTOVENDOR} state before.`
      }));
      this.flow.steps.push(new FlowStep({
        name: this.statuses.COMPLETED, code: 4,
        progressStatusAlert: `Item needs to be in ${this.statuses.AR} state before.`
      }));

    }else{
      this.flow.steps.push(new FlowStep({
        name: this.statuses.NEW, code: 1,
        revertStatusAlert: `To reset to ${this.statuses.NEW}, item needs to be in ${this.statuses.RFA} state before.`
      }));
      this.flow.steps.push(new FlowStep({
        name: this.statuses.RFA, code: 2,
        progressStatusAlert: 'An order needs at least 1 service',
        revertStatusAlert: `To reset to ${this.statuses.RFA}, item needs to be in Approved state before.`
      }));
      this.flow.steps.push(new FlowStep({
        name: this.statuses.AR, code: 3,
        progressStatusAlert: `Item needs to be in ${this.statuses.RFA} state before.`,
        revertStatusAlert: `To reset to Approved, item needs to be in ${this.statuses.SENDTOVENDOR} state before.`
      }));
      this.flow.steps.push(new FlowStep({
        name: this.statuses.SENDTOVENDOR, code: 4,
        progressStatusAlert: 'Item needs to be in Approved state before.',
        revertStatusAlert: `To reset to ${this.statuses.SENDTOVENDOR}, item needs to be in ${this.statuses.VENDORACK} state before.`
      }));
      this.flow.steps.push(new FlowStep({
        name: this.statuses.VENDORACK, code: 5,
        progressStatusAlert: 'Item needs to be in Send to Vendor state before.',
        revertStatusAlert: `To reset to ${this.statuses.VENDORACK}, item needs to be in ${this.statuses.VENDORACC} state before.`
      }));
      this.flow.steps.push(new FlowStep({
        name: this.statuses.VENDORACC, code: 6,
        progressStatusAlert: `Item needs to be in ${this.statuses.VENDORACK} state before.`,
        revertStatusAlert: `To reset to ${this.statuses.VENDORACC}, item needs to be in ${this.statuses.COMPLETED} state before.`
      }));
      this.flow.steps.push(new FlowStep({
        name: this.statuses.COMPLETED, code: 7,
        progressStatusAlert: `Item needs to be in ${this.statuses.VENDORACC} state before.`
      }));
    }

    this.setStepDates(order);
    this.updateSteps(status);
    return this.flow;
  }

  public setStepDates(order: Order) {
    this.flow.steps.forEach((item) => {
      switch (item.code) {
      case (1): {
        item.dateInfo = order.created_at || null;
        break;
      }
      case (2): {
        item.dateInfo = order.ready_for_approval || null;
        break;
      }
      case (3): {
        item.dateInfo = order.approve_date || null;
        break;
      }
      case (4): {
        if(order.type_id === ORDER_TYPE.RECORD_ONLY){
          item.dateInfo = order.complete_date || null;
        }else{
          item.dateInfo = order.send_date || null;
        }
        break;
      }
      case (5): {
        item.dateInfo = order.vendor_ack_date || null;
        break;
      }
      case (6): {
        let accRejDate = order.vendor_accept_date ? order.vendor_accept_date : order.vendor_reject_date;
        item.dateInfo = accRejDate || null;
        break;
      }
      case (7): {
        item.dateInfo = order.complete_date || null;
        break;
      }
      }
    });
  }
  checkRequestedields(orderSvc) {
    if(
      orderSvc.inventory
      && orderSvc.inventory.ban
      && orderSvc.inventory.term
      && orderSvc.inventory.topology_id
      && orderSvc.inventory.est_mrc
      && orderSvc.inventory.est_nrc
      && orderSvc.inventory.technology_id
      && orderSvc.inventory.cir_bw_id
      && orderSvc.des_due_date
      ){
        return true
      } else {
        return false
      }
  }

  async handleStepSelection(step, ord) {
    let code = step.code;
    let order = Object.assign({}, this.order);

    let checkOrderServiceRequiredFields = order.services.every((x)=>{
      return this.checkRequestedields(x) === true
    })

    if (code === 1) {
      this.dialogService.edit(
        OrderResetToNewManageComponent, {
          order: this.orderService.findByIdForEdit(this.order.id)
        }).pipe(
          mergeMap(x => x.afterClosed())
        ).subscribe(result => {
          if (result && result.reset) {
            if (this.flow.isCurrentOrGreaterStep(code)) {
              order.approve_date = order.send_date = order.vendor_ack_date = order.vendor_accept_date = order.vendor_reject_date = order.complete_date = null;
            }
            this.setStepDates(order);

            let status: number = this.ORDER_STATUS_ENUM.NEW_RESET;
            order.status_id = status;
            order.request_date = ord.request_date

            this.orderService.update(order.id, order).subscribe((resultOrder) => {
              if (result.note && result.note.length) {
                let note = new Notes({
                  entity_id: this.order.id,
                  entity_type: this.SYSTEM_MODULE.ORDER,
                  content: 'Reset to New: ' + result.note
                });
                this.notesService.create(note).subscribe(() => {})
              }

              this.updateSteps(status);

              let orderSvcStatus: number = this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET;
              this.order.services.forEach((item) => {
                item.state = orderSvcStatus
              });
              this.updateOrderServices(this.order.services)
            });
          }
        })
    }
    else if (code === 2) {
      let isAnyServiceInvalid = order.services.filter(service => service.state < this.ORDER_SERVICE_STATUS_ENUM.RFA).length > 0;
      let isAnyServiceInNewPandingState = order.services.filter(service => service.disconnect_state === this.ORDER_SERVICE_DISCONNECT_STATUS_ENUM.NEW_PENDING).length > 0;
      if (order.type_id == ORDER_TYPE.DISCONNECT && !isAnyServiceInNewPandingState) {
        isAnyServiceInvalid = false;
      }
      if (!isAnyServiceInvalid ) {
        this.dialogService.edit(
          ConfirmDialogComponent,
          {
            order: this.orderService.findByIdForEdit(this.order.id),
            bodyText: 'Order is Ready for Approval?'
          },
          {
            width: '350px'
          }).pipe(
          mergeMap(x => x.afterClosed()))
          .subscribe(result => {
            if (result && this.elapsedTimeModal(result.status)) {
              if (this.flow.isCurrentOrGreaterStep(code)) {
                order.approve_date = order.send_date = order.vendor_ack_date = order.vendor_accept_date = order.vendor_reject_date = order.complete_date = null;
              }
              let priorStatus: number = order.status_id ? order.status_id : this.ORDER_STATUS_ENUM.NEW;
              let status: number = this.ORDER_STATUS_ENUM.RFA;
              order.status_id = status;
              order.ready_for_approval = new Date();
              order.request_date = ord.request_date

              this.orderService.update(order.id, order).subscribe((result) => {
                Object.assign(this.order, result);
                this.order.services = this.order.services.filter(svc => svc.active);
                this.setStepDates(order);
                this.updateSteps(status, priorStatus);

                this.onOrderChange.emit(this.order)
              });
            }
          })
      } else {
        if (order.type_id == ORDER_TYPE.DISCONNECT ){
          this.alertService.error('',  this.messages.get('ORDER_SERVICE_REQUIRE_NEW_STATE'));
        }else{
          this.alertService.error('', this.messages.get('ORDER_SERVICE_REQUIRE_RFA_STATE'));
        }
      }
    } else if (code === 3) {
      let dbOrder = await this.orderService.findById(order.id).toPromise()
      let isAnyServiceInvalid = dbOrder.services.filter(service => service.state < this.ORDER_SERVICE_STATUS_ENUM.RFA).length > 0;
      if (order.type_id == ORDER_TYPE.DISCONNECT) {
        isAnyServiceInvalid = false;
      }
        if (!isAnyServiceInvalid) {
          this.dialogService.edit(
            OrderApprovalManageComponent,
            {
              order: this.orderService.findByIdForEdit(this.order.id)
            }).pipe(
            mergeMap(x => x.afterClosed()))
            .subscribe((result) => {
              if (result) {
                if (result.cancelByTimer) {
                  return this.dataLockService.cancelByTimerAlert();
                }

                if (result && result.approved) {
                  if (this.flow.isCurrentOrGreaterStep(code)) {
                    order.send_date = order.vendor_ack_date = order.vendor_accept_date = order.vendor_reject_date = order.complete_date = null;
                  }
                  let status: number = this.ORDER_STATUS_ENUM.APPROVED;
                  dbOrder.status_id = status;
                  dbOrder.approve_date = new Date();
                  this.orderService.update(order.id, dbOrder).subscribe((ord) => {
                    Object.assign(this.order, ord);
                    this.order.services = this.order.services.filter(svc => svc.active);
                    this.setStepDates(dbOrder);
                    this.updateSteps(status);

                    if (result.note && result.note.length > 0) {
                      let message = `Order Approved: ${result.note}`;
                      this.addNote(message);
                    }

                    this.onOrderChange.emit(this.order)
                  });
                }
                else if (result && result.rejected) {
                  if (this.flow.isCurrentOrGreaterStep(code)) {
                    order.send_date = order.vendor_ack_date = order.vendor_accept_date = order.vendor_reject_date = order.complete_date = null;
                  }
                  let status: number = this.ORDER_STATUS_ENUM.NEW_REJECTED;
                  order.status_id = status;

                  this.orderService.update(order.id, order).subscribe((ord) => {
                    Object.assign(this.order, ord);
                    this.order.services = this.order.services.filter(svc => svc.active);
                    this.setStepDates(order);
                    this.updateSteps(status);

                    if (result.note && result.note.length > 0) {
                      let message = `Order Rejected: ${result.reasonText} - ${result.note}`;
                      this.addNote(message);
                    }
                    // //set all services to New state
                    this.order.services.forEach((service) => {
                      if (this.order.type_id == this.ORDER_TYPE.DISCONNECT) {
                        service.disconnect_state = this.ORDER_SERVICE_DISCONNECT_STATUS_ENUM.NEW_REJECTED;
                      } else {
                        service.state = this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED;
                      }
                    });
                    this.updateOrderServices(this.order.services)
                  });
                }
              }
            });
        } else {
          this.alertService.error('', this.messages.get('ORDER_SERVICE_REQUIRE_RFA_STATE'));
        }
    } else if (code === 4) {
      let vendorName = this.order.vendor ? this.order.vendor.name : 'N/A';
      if(order.type_id !== ORDER_TYPE.RECORD_ONLY){
      this.dialogService.edit(
        OrderSentToVendor,
        {
          order: this.orderService.findByIdForEdit(this.order.id),
          bodyText: 'Order was sent to vendor?'
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {
            if (this.flow.isCurrentOrGreaterStep(code)) {
              order.vendor_ack_date = order.vendor_accept_date = order.vendor_reject_date = order.complete_date = null;
            }
            let status: number = this.ORDER_STATUS_ENUM.SENT_TO_VENDOR;
            order.status_id = status;
            order.send_date = result.send_date;
            order.request_date = ord.request_date

            this.orderService.update(order.id, order)
              .subscribe((result) => {
                Object.assign(this.order, result);
                this.order.services = this.order.services.filter(svc => svc.active);
                this.alertService.success('', this.messages.get('VENDOR_SEND_SUCCESS'));

                this.setStepDates(order);
                this.updateSteps(status);

                this.onOrderChange.emit(this.order)
              });
          }
        })
      }
    } else if (code === 5) {
      this.dialogService.edit(
        OrderVendorAckDateComponent,
        {
          order: this.orderService.findByIdForEdit(this.order.id),
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result) {
            if (result.cancelByTimer) {
              return this.dataLockService.cancelByTimerAlert();
            }

            const resetInventoryServiceDates = this.flow.isCurrentOrGreaterStep(code)

            if (result && result.approved) {
              if (this.flow.isCurrentOrGreaterStep(code)) {
                order.vendor_accept_date = order.vendor_reject_date = order.complete_date = null;
              }
              let status: number = this.ORDER_STATUS_ENUM.VENDOR_ACK_DATE;
              order.status_id = status;

              order.vendor_ack_date = result.vendor_ack_date;
              order.request_date = ord.request_date

              this.orderService.update(order.id, order).subscribe((result) => {
                Object.assign(this.order, result);
                this.order.services = this.order.services.filter(svc => svc.active);

                if (this.flow.isCurrentOrGreaterStep(code)) {
                  this.order.services.forEach((service) => {
                    service.disconnect_date = service.stop_billing_date = null
                    if (this.order.type_id == this.ORDER_TYPE.DISCONNECT) {
                      service.disconnect_state = this.ORDER_SERVICE_DISCONNECT_STATUS_ENUM.NEW_RESET;
                    } else {
                      service.state = this.ORDER_SERVICE_STATUS_ENUM.RFA;
                    }
                  });
                }

                // When we go from step 4 to step 5 we were updating the order service. It is an unnecessary entry in the database. This if statement fix this
                if (this.flow.isCurrentOrGreaterStep(code)) {
                  this.updateOrderServices(this.order.services, resetInventoryServiceDates)
                }

                let message = `Vendor Ack Date: ${order.vendor_ack_date}`;
                this.setStepDates(order);
                this.updateSteps(status);
                this.addNote(message);
              });
            }
          }
        })
    } else if (code === 6) {
      this.dialogService.edit(
        OrderVendorApprovalManageComponent,
        {
          order: this.orderService.findByIdForEdit(this.order.id)
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe((result) => {
          if (result) {
            if (result && result.approved) {
              if (this.flow.isCurrentOrGreaterStep(code)) {
                order.vendor_reject_date = order.complete_date = null;
              }
              let status: number = this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED;
              order.status_id = status;
              order.vendor_accept_date = result.date;
              order.request_date = ord.request_date

              this.orderService.update(order.id, order).subscribe((ord) => {
                Object.assign(this.order, ord);
                this.order.services = this.order.services.filter(svc => svc.active);
                this.setStepDates(order);
                this.updateSteps(status);

                if (result.note && result.note.length > 0) {
                  let message = `Vendor Accepted: ${result.note}`;
                  this.addNote(message);
                }

                //set all services to Vendor Accepted state
                this.order.services.forEach((service) => {
                  if (this.order.type_id == this.ORDER_TYPE.DISCONNECT) {
                    service.disconnect_state = this.ORDER_SERVICE_DISCONNECT_STATUS_ENUM.VENDOR_ACCEPTED;
                  } else {
                    service.state = this.ORDER_SERVICE_STATUS_ENUM.VENDOR_ACCEPTED;
                  }
                });
                this.updateOrderServices(this.order.services)
              });
            } else if (result && result.reject) {
              if (this.flow.isCurrentOrGreaterStep(code)) {
                order.vendor_accept_date = order.complete_date = null;
              }
              let status: number = this.ORDER_STATUS_ENUM.NEW_REJECTED;
              order.status_id = status;
              order.vendor_reject_date = result.date;
              order.request_date = ord.request_date

              this.orderService.update(order.id, order).subscribe((ord) => {
                Object.assign(this.order, ord);
                this.order.services = this.order.services.filter(svc => svc.active);
                this.setStepDates(order);
                this.updateSteps(status);

                if (result.note && result.note.length > 0) {
                  let message = `Vendor Rejected: ${result.note}`;
                  this.addNote(message);
                }

                //set all services to New state
                this.order.services.forEach((service) => {
                  if (this.order.type_id == this.ORDER_TYPE.DISCONNECT) {
                    service.disconnect_state = this.ORDER_SERVICE_DISCONNECT_STATUS_ENUM.NEW_REJECTED;
                  } else {
                    service.state = this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED;
                  }
                });
                this.updateOrderServices(this.order.services)
              });
            }
          }
        });
      }
  }

  public async updateOrderServices(services, resetInventoryServiceDates = false) {
    // reset inventories disconnect date
    if (resetInventoryServiceDates) {
      for (let i=0; i < services.length; i++) {
        services[i].inventory.disc_date = null;
      }
    }
    let promises = [];
    services.forEach(service => {
      if (resetInventoryServiceDates) { service.disconnect_date = service.stop_billing_date = null }
      promises.push(this.orderServiceService.update(service.id, service).toPromise())
    });
    Promise.all(promises).then((result) => {
      this.order.services = services;
      this.onOrderChange.emit(this.order)
    })
  }

  public updateSteps(status: number, priorStatus?: number) {
    if (status === this.ORDER_STATUS_ENUM.NEW) {
      this.flow.currentStep = 1;
    }
    else if (status === this.ORDER_STATUS_ENUM.NEW_REJECTED) {
      this.updateStep(1, this.statuses.NEW_REJECTED);
      this.updateStep(3, this.statuses.AR);
      this.flow.currentStep = 1;
    }
    else if (status === this.ORDER_STATUS_ENUM.NEW_RESET) {
      this.updateStep(1, this.statuses.NEW_RESET);
      this.updateStep(3, this.statuses.AR);
      this.flow.currentStep = 1;
    }
    else if (status === this.ORDER_STATUS_ENUM.RFA) {
      if (priorStatus && priorStatus === this.ORDER_STATUS_ENUM.NEW_REJECTED) {
        this.updateStep(1, this.statuses.NEW);
        this.updateStep(2, this.statuses.RFA_PRIOR_REJECT)
      }
      this.flow.currentStep = 2;
    }
    else if (status === this.ORDER_STATUS_ENUM.APPROVED) {
      this.updateStep(2, this.statuses.RFA);
      this.updateStep(3, this.statuses.AR);
      this.flow.currentStep = 3;
    }
    else if (status === this.ORDER_STATUS_ENUM.SENT_TO_VENDOR) {
      this.updateStep(3, this.statuses.AR);
      this.flow.currentStep = 4;
    }
    else if (status === this.ORDER_STATUS_ENUM.VENDOR_ACK_DATE) {
      this.updateStep(3, this.statuses.AR);
      this.flow.currentStep = 5;
    }
    else if (status === this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED) {
      this.updateStep(3, this.statuses.AR);
      this.updateStep(6, this.statuses.VENDORACC);
      this.flow.currentStep = 6;
    }
    else if (status === this.ORDER_STATUS_ENUM.VENDOR_REJECT) {
      this.updateStep(3, this.statuses.AR);
      this.updateStep(6, this.statuses.VENDOR_REJECT);
      this.flow.currentStep = 1;
    }
    else if (status === this.ORDER_STATUS_ENUM.COMPLETED) {
      this.updateStep(3, this.statuses.AR);
      this.updateStep(6, this.statuses.VENDOR_ACCEPT_RECEIVED);
      this.flow.currentStep = 7;
    }
    else if (status === this.ORDER_STATUS_ENUM.CANCELLED) {
      this.updateStep(1, this.statuses.CANCELLED);
      this.flow.currentStep = 1;
    }
    this.onStatusChange.emit({status: status, order: this.order});
  }

  public addNote(noteMessage) {
    let note = new Notes({
      entity_id: this.order.id,
      entity_type: 20, //TODO
      content: noteMessage
    });

    this.notesService.create(note)
      .subscribe(
        result => {
          this.onStatusChange.emit();
        }
      );
  }

  public async revertOrderStateToNew(orderId) {
    const order = await this.orderService.findById(orderId).toPromise();
    order.approve_date = order.send_date = order.vendor_ack_date = order.vendor_accept_date = order.vendor_reject_date = order.complete_date = order.ready_for_approval = null;
    this.setStepDates(order);
    let status: number = this.ORDER_STATUS_ENUM.NEW_RESET;
    order.status_id = status;
    order.services = []

    this.orderService.update(order.id, order).subscribe((resultOrder) => {
      Object.assign(this.order, resultOrder);
      let note = new Notes({
        entity_id: this.order.id,
        entity_type: this.SYSTEM_MODULE.ORDER,
        content: 'Reset to New: Services removed'
      });
      this.notesService.create(note).subscribe(() => {})
      this.updateSteps(status);

      this.onOrderChange.emit(this.order)
    })
  }
}
