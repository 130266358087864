import {
  Component,
  forwardRef,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  PICKER_COMPONENT_TEMPLATE,
  PickerComponentBase,
} from "../../../shared/picker/ca-picker-base.component";
import { Observable } from "rxjs";
import { InventoryService } from "../../core/inventory.service";

@Component({
  selector: "ca-inventory-relationship-type-picker",
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaInventoryRelationshipTypePickerComponent),
      multi: true,
    },
  ],
})
export class CaInventoryRelationshipTypePickerComponent
  extends PickerComponentBase
  implements OnInit, OnChanges
{
  readonly TEXT_FIELD = "value";
  @Input("formControlName") formControlName;
  @Input("initialItem") initialItem;
  @Input("pickerForm") pickerForm;
  @Input("disabled") disabled: boolean;
  @Input("required") required: boolean;
  @Input("placeholder") placeholder = "Select ...";
  @Input("searchEnabled") searchEnabled: boolean;
  @Input("clearEnabled") clearEnabled: boolean;
  @Input("query") query;

  public control;

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];
    this.loadData();
  }

  constructor(public inventoryService: InventoryService) {
    super();
  }

  loadEntries(): Observable<any> {
    this.query.orderBy = [["value", "ASC"]];

    return this.inventoryService.findOnlyInventoryRelationshipType(this.query);
  }

  search(value: string): Observable<any> {
    if (value) {
      this.query.where["value"] = { $ilike: `${value}%` };
    } else {
      delete this.query.where["value"];
    }
    return this.inventoryService.findOnlyInventoryRelationshipType(this.query);
  }
}
