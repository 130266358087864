<div class="mat-dialog-top-spacer"></div>
<div class="ca-dialog-title">
	<h1 mat-dialog-title>User</h1>
</div>
<div
	mat-dialog-content
	class="ca-dialog-content"
	fxLayout="column wrap"
	*ngIf="form"
>
	<div class="flex-item">
		<div fxFlex="55%" fxLayout="row" class="flex-item caGap caGapRight">
			<!-- Setup identity provider Cognito -->
			<form
				class="ca-form"
				[formGroup]="form"
				autocomplete="false"
				*ngIf="form && !form.controls.username"
			>
				<div class="flex-container" fxLayout="row wrap">
					<div fxFlex>
						<div class="flex-container" fxLayout="row">
							<ca-avatar
								fxLayout="row"
								fxLayoutAlign="center center"
								class="details-item caGap caGapRight"
								[uploadEnabled]="true"
								(onAvatarUpload)="handleAvatarUpload($event)"
								[disabled]="viewOnly"
								[text]="getUserInitials()"
								[size]="'m'"
								[source]="userCopy?.avatar"
							>
							</ca-avatar>
							<div class="flex-container" fxFlex fxLayout="column">
								<div class="flex-container" fxLayoutAlign="start center">
									<mat-slide-toggle
										id="user_modal_active"
										class="caGap"
										[disabled]="me?.id == user?.id || viewOnly"
										formControlName="is_active"
									>
										Active
									</mat-slide-toggle>
								</div>
								<div fxFlex class="caGap">
									<mat-form-field caInput>
										<mat-label>Email</mat-label>
										<input
											id="user_modal_email"
											matInput
											required
											formControlName="email"
											type="text"
										/>
										<mat-hint
											*ngIf="
												form.hasError('required', ['email']) &&
												form.controls.email.touched &&
												emailUnique
											"
											class="error-class"
										>
											{{ VALIDATION.REQUIRED_ERROR }}
										</mat-hint>
										<mat-hint
											*ngIf="
												form.hasError('email', ['email']) &&
												form.controls.email.touched &&
												emailUnique
											"
											class="error-class"
										>
											{{ VALIDATION.EMAIL_ERROR }}
										</mat-hint>
										<mat-hint *ngIf="!emailUnique">
											{{ VALIDATION.EMAIL_ALREADY_USED }}
										</mat-hint>
									</mat-form-field>
								</div>
							</div>
						</div>

						<div class="flex-container" fxLayout="row wrap">
							<div fxFlex class="caGap caGapRight">
								<mat-form-field caInput>
									<mat-label>First Name</mat-label>
									<input
										id="user_modal_first_name"
										matInput
										required
										formControlName="first_name"
										type="text"
									/>
									<mat-hint
										*ngIf="
											form.hasError('required', ['first_name']) &&
											form.controls.first_name.touched
										"
										class="error-class"
										>{{ VALIDATION.REQUIRED_ERROR }}
									</mat-hint>
								</mat-form-field>
							</div>
							<div fxFlex class="caGap">
								<mat-form-field caInput>
									<mat-label>Last Name</mat-label>
									<input
										id="user_modal_last_name"
										matInput
										required
										formControlName="last_name"
										type="text"
									/>
									<mat-hint
										*ngIf="
											form.hasError('required', ['last_name']) &&
											form.controls.last_name.touched
										"
										class="error-class"
										>{{ VALIDATION.REQUIRED_ERROR }}
									</mat-hint>
								</mat-form-field>
							</div>
						</div>
						<div class="flex-container" fxLayout="row wrap">
							<div fxFlex fxFlex="48%" class="caGap">
								<ca-country-picker
									id="user_modal_country"
									caInput
									formControlName="country_id"
									placeholder="Country"
									[pickerForm]="form"
									[showAllEntries]="true"
									[clearEnabled]="true"
									[required]="true"
									[searchEnabled]="true"
									[disabled]="viewOnly"
									(onSelectionChange)="onCountryChange($event)"
								>
								</ca-country-picker>
							</div>
						</div>
						<div class="flex-container" fxLayout="row wrap">
							<div
								class="flex-item caGap caGapRight entry-list-container"
								fxFlex="80px"
							>
								<div fxLayout="row" class="entry-list">
									<div fxFlex class="entry-list-item">
										<label>Country Code</label>
										<p style="text-align: end">{{ phoneCountryCode || '' }}</p>
									</div>
								</div>
							</div>
							<div fxFlex class="caGap caGapRight">
								<mat-form-field caInput>
									<mat-label>Phone Number</mat-label>
									<input
										id="user_modal_phone_number"
										matInput
										formControlName="phone_number"
										type="text"
										[mask]="phoneRegexMask"
										[validation]="true"
									/>
									<mat-hint
										*ngIf="
											(form.hasError('pattern', ['phone_number']) ||
												this.form.get('phone_number').invalid) &&
											form.controls.phone_number.touched
										"
										class="error-class"
										>{{ phoneRegexMask }}
									</mat-hint>
								</mat-form-field>
							</div>
							<div fxFlex class="caGap">
								<mat-form-field caInput>
									<mat-label>Mobile Number</mat-label>
									<input
										id="user_modal_mobile_number"
										matInput
										formControlName="mobile_number"
										type="text"
										[mask]="phoneRegexMask"
										[validation]="true"
									/>
									<mat-hint
										*ngIf="
											(form.hasError('pattern', ['mobile_number']) ||
												this.form.get('mobile_number').invalid) &&
											form.controls.mobile_number.touched
										"
										class="error-class"
										>{{ phoneRegexMask }}
									</mat-hint>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</form>
			<!-- Setup identity provider gpx_identity -->
			<form
				class="ca-form"
				[formGroup]="form"
				autocomplete="false"
				*ngIf="form && form.controls.username"
			>
				<div class="flex-container" fxLayout="row wrap">
					<div fxFlex>
						<div class="flex-container" fxLayout="row">
							<ca-avatar
								fxLayout="row"
								fxLayoutAlign="center center"
								class="details-item caGap caGapRight"
								[uploadEnabled]="true"
								(onAvatarUpload)="handleAvatarUpload($event)"
								[disabled]="viewOnly"
								[text]="getUserInitials()"
								[size]="'m'"
								[source]="userCopy?.avatar"
							>
							</ca-avatar>
							<div class="flex-container" fxFlex>
								<div class="flex-container" fxLayoutAlign="start center">
									<div fxFlex="55%" class="caGap caGapRight">
										<mat-form-field fxFlex caInput>
											<mat-label>Username</mat-label>
											<input
												id="user_modal_username"
												matInput
												required
												formControlName="username"
												type="text"
											/>
											<mat-hint
												*ngIf="
													form.hasError('required', ['username']) &&
													form.controls.username.touched
												"
												class="error-class"
												>{{ VALIDATION.REQUIRED_ERROR }}
											</mat-hint>
											<mat-hint *ngIf="!usernameUnique">
												{{ VALIDATION.USERNAME_ALREADY_USED }}
											</mat-hint>
										</mat-form-field>
									</div>
									<mat-slide-toggle
										id="user_modal_active"
										class="caGap"
										[disabled]="me?.id == user?.id || viewOnly"
										formControlName="is_active"
									>
										Active
									</mat-slide-toggle>
								</div>
								<div class="caGap">
									<mat-form-field caInput>
										<mat-label>Email</mat-label>
										<input
											id="user_modal_email"
											matInput
											required
											formControlName="email"
											type="text"
										/>
										<mat-hint
											*ngIf="
												form.hasError('required', ['email']) &&
												form.controls.email.touched &&
												emailUnique
											"
											class="error-class"
										>
											{{ VALIDATION.REQUIRED_ERROR }}
										</mat-hint>
										<mat-hint
											*ngIf="
												form.hasError('email', ['email']) &&
												form.controls.email.touched &&
												emailUnique
											"
											class="error-class"
										>
											{{ VALIDATION.EMAIL_ERROR }}
										</mat-hint>
										<mat-hint *ngIf="!emailUnique">
											{{ VALIDATION.EMAIL_ALREADY_USED }}
										</mat-hint>
									</mat-form-field>
								</div>
							</div>
						</div>
						<div class="flex-container" fxLayout="row wrap">
							<div fxFlex class="caGap caGapRight">
								<mat-form-field caInput>
									<mat-label>First Name</mat-label>
									<input
										id="user_modal_first_name"
										matInput
										required
										formControlName="first_name"
										type="text"
									/>
									<mat-hint
										*ngIf="
											form.hasError('required', ['first_name']) &&
											form.controls.first_name.touched
										"
										class="error-class"
										>{{ VALIDATION.REQUIRED_ERROR }}
									</mat-hint>
								</mat-form-field>
							</div>
							<div fxFlex class="caGap">
								<mat-form-field caInput>
									<mat-label>Last Name</mat-label>
									<input
										id="user_modal_last_name"
										matInput
										required
										formControlName="last_name"
										type="text"
									/>
									<mat-hint
										*ngIf="
											form.hasError('required', ['last_name']) &&
											form.controls.last_name.touched
										"
										class="error-class"
										>{{ VALIDATION.REQUIRED_ERROR }}
									</mat-hint>
								</mat-form-field>
							</div>
						</div>
						<div class="flex-container" fxLayout="row wrap" *ngIf="!user.id">
							<div fxFlex class="caGap caGapRight">
								<mat-form-field caInput>
									<mat-label>Password</mat-label>
									<input
										id="user_modal_password"
										matInput
										autocomplete="new-password"
										formControlName="password"
										type="password"
										required
									/>
								</mat-form-field>
							</div>
							<div fxFlex class="caGap">
								<mat-form-field caInput>
									<mat-label>Confirm Password</mat-label>
									<input
										id="user_modal_confirm_password"
										matInput
										formControlName="confirm_password"
										type="password"
										required
									/>
									<mat-hint
										*ngIf="
											form.hasError('required', ['password']) &&
											form.controls.password.touched
										"
										class="error-class"
										>{{ VALIDATION.REQUIRED_ERROR }}</mat-hint
									>
									<mat-hint
										*ngIf="
											form.hasError('pattern', ['password']) &&
											form.controls.password.touched
										"
										class="error-class"
										>{{ passwordMessage }}
									</mat-hint>
									<mat-hint
										*ngIf="
											form.hasError('required', ['confirm_password']) &&
											form.controls.confirm_password.touched
										"
										class="error-class"
										>{{ VALIDATION.REQUIRED_ERROR }}
									</mat-hint>
									<mat-hint
										*ngIf="
											form.controls.password.value &&
											form.controls.confirm_password.value &&
											form.controls.password.value !==
												form.controls.confirm_password.value
										"
										class="error-class"
										>Entered passwords don't match
									</mat-hint>
								</mat-form-field>
							</div>
						</div>
						<div class="flex-container">
							<div fxFlex="48%" class="caGap caGapRight">
								<ca-country-picker
									id="user_modal_country"
									caInput
									formControlName="country_id"
									placeholder="Country"
									[pickerForm]="form"
									[showAllEntries]="true"
									[clearEnabled]="true"
									[required]="true"
									[searchEnabled]="true"
									[disabled]="viewOnly"
									(onSelectionChange)="onCountryChange($event)"
								>
								</ca-country-picker>
							</div>
						</div>
						<div class="flex-container" fxLayout="row wrap">
							<div
								class="flex-item caGap caGapRight entry-list-container"
								fxFlex="80px"
							>
								<div fxLayout="row" class="entry-list">
									<div fxFlex class="entry-list-item">
										<label>Country Code</label>
										<p style="text-align: end">{{ phoneCountryCode || '' }}</p>
									</div>
								</div>
							</div>
							<div fxFlex class="caGap caGapRight">
								<mat-form-field caInput>
									<mat-label>Phone Number</mat-label>
									<input
										id="user_modal_phone_number"
										matInput
										formControlName="phone_number"
										type="text"
										[mask]="phoneRegexMask"
										[validation]="true"
									/>
									<mat-hint
										*ngIf="
											(form.hasError('pattern', ['phone_number']) ||
												this.form.get('phone_number').invalid) &&
											form.controls.phone_number.touched
										"
										class="error-class"
										>{{ phoneRegexMask }}
									</mat-hint>
								</mat-form-field>
							</div>
							<div fxFlex class="caGap">
								<mat-form-field caInput>
									<mat-label>Mobile Number</mat-label>
									<input
										id="user_modal_mobile_number"
										matInput
										formControlName="mobile_number"
										type="text"
										[mask]="phoneRegexMask"
										[validation]="true"
									/>
									<mat-hint
										*ngIf="
											(form.hasError('pattern', ['mobile_number']) ||
												this.form.get('mobile_number').invalid) &&
											form.controls.mobile_number.touched
										"
										class="error-class"
										>{{ phoneRegexMask }}
									</mat-hint>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div fxFlex="45%" class="flex-item caGap" fxLayout="column wrap">
			<div class="flex-item">
				<h3 class="no-margin-top">Roles</h3>
				<div
					style="overflow-x: hidden; align-content: flex-start"
					class="flex-container checkbox-container"
					[ngClass]="{
						'ca-user-cognito-modal': form && !form.controls.username,
						'ca-user-edit-cognito-modal':
							form && !form.controls.username && user.id,
						'ca-user-create-modal': !user.id,
						'ca-user-edit-modal': user.id
					}"
					(scroll)="scrollHandler($event)"
				>
					<div
						*ngFor="let role of roles; index as i"
						class="content-padding"
						[ngClass]="{ 'field-border': i === numberOfUserRoles - 1 }"
					>
						<mat-checkbox
							[(ngModel)]="role.selected"
							[disabled]="viewOnly || !isFormEnabled"
							(change)="changeRoleStatus(role.id, $event)"
						>
							{{ role.name }}
						</mat-checkbox>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		fxFlex
		fxLayout="row"
		class="flex-item"
		*permission="{ action: 'View', key: 'reports' }"
	>
		<div fxFlex="40%" class="reporting-role-container">
			<h3 class="no-margin-top">Reporting Role</h3>
			<mat-radio-group
				class="radio-button"
				[(ngModel)]="selectedReportingRole"
				(change)="reportRoleChange()"
				[disabled]="viewOnly || !isFormEnabled"
			>
				<div fxLayout="row" fxLayoutWrap>
					<div class="caGap caGapRight" *ngFor="let role of reportingRoles">
						<mat-radio-button
							[value]="role"
							[disabled]="isRadioButtonDisabled(role)"
						>
							{{ role.name }}
						</mat-radio-button>
					</div>
				</div>
			</mat-radio-group>
		</div>

		<div fxFlex="60%" class="reporting-transfere" *ngIf="transfereQSUser">
			<h3 class="no-margin-top">Existing Objects</h3>
			<mat-radio-group
				class="radio-button"
				[(ngModel)]="selectedTransfereQSMethod"
				(change)="changeTransfere()"
			>
				<div fxLayout="row" fxLayoutWrap>
					<div
						class="ca-radio-button-no-background caGap caGapRight reporting-transfere-choice"
						*ngFor="let transfere of transfereObject"
					>
						<mat-radio-button class="caGap caGapRight" [value]="transfere.id">
							{{ transfere.name }}
						</mat-radio-button>
					</div>
					<div fxFlex class="ca-form" *ngIf="selectedTransfereQSMethod">
						<ca-default-picker hiDensityInput>
							<mat-form-field>
								<mat-select
									placeholder="Transfer User"
									[disabled]="!selectedTransfereQSMethod"
									[(ngModel)]="selectedTransfereQSUser"
								>
									<mat-option
										*ngFor="let user of QSTransfereUser"
										[value]="user.email"
									>
										{{ user.email }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</ca-default-picker>
					</div>
				</div>
			</mat-radio-group>
		</div>
	</div>
</div>
<mat-dialog-actions class="ca-dialog-action" *ngIf="!viewOnly">
	<span class="example-fill-remaining-space"></span>
	<button
		id="user_modal_cancel_button"
		mat-button
		#cancelButton
		type="submit"
		(click)="cancel()"
	>
		Cancel
	</button>
	<button
		id="user_modal_save_button"
		mat-button
		#saveButton
		type="submit"
		(click)="onSubmit(form)"
		[disabled]="
			form?.invalid ||
			!emailUnique ||
			!usernameUnique ||
			!selectedRoleIds.length ||
			(selectedTransfereQSMethod
				? !(selectedTransfereQSMethod && selectedTransfereQSUser)
				: false)
		"
	>
		Save
	</button>
</mat-dialog-actions>
<mat-dialog-actions class="ca-dialog-action" *ngIf="viewOnly">
	<div class="example-fill-remaining-space"></div>
	<button
		id="user_modal_cancel_button_view"
		mat-button
		type="submit"
		(click)="closeDialog()"
	>
		Cancel
	</button>
	<button
		id="user_modal_edit_button"
		*permission="{ action: 'Modify', key: 'users' }"
		mat-button
		type="submit"
		(click)="editForm()"
		[disabled]="form?.invalid"
	>
		Edit
	</button>
</mat-dialog-actions>
