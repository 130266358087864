import {map, takeUntil, forkJoin, from, combineLatest} from 'rxjs';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

import {GLSystemRuleService} from '../core/gl-system-rule.service';
import {SPIDCodingGridService} from '../core/spid-coding-grid.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {PageListComponent} from '../../core/page-list.component';
import {AlertService} from '../../shared/alert/alert.service';
import Query from '../../core/query/query';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {SortingBuilder, SortingService} from '../../shared/sorting/sorting.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {SpidCodingFilterService} from '../core/spid-coding-filter.service';
import {SystemRuleStore} from '../core/system-rule.store';
import {SystemRuleSelectors} from '../core/system-rule.selectors';
import {PermissionService} from '../../permissions/core/permission.service';
import { IActions } from 'app/gl-system-rules/shared/actions.interface';


@Component({
  selector: 'app-spid-coding',
  templateUrl: './spid-coding.component.html',
  styleUrls: ['./spid-coding.component.css']
})
export class SPIDCodingComponent extends PageListComponent implements AfterViewInit, OnDestroy, OnChanges, OnInit {
  public query: Query;
  public spids: Array<any> = [];
  public selection: any;
  public selectedVendor: any = null;
  public selectedBAN: any = null;


  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public selectedCodings: Array<any>;
  public originalCodings: Array<any>;
  public validGLSelection = false;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'gl';

  permissionModify = false;

  public spidSorting: SortingBuilder;
  @Input() triggerReload = false;
  @Output() public onSelection = new EventEmitter();
  @Output() public onSaveSpid = new EventEmitter();
  @Output() public onActionsLoaded = new EventEmitter();
  @Output() public onClearSelection = new EventEmitter();

  filterVisible = false;

  form: FormGroup;
  form$;
  banDisabled$;


  actionSchema: IActions = {
    add: false,
    edit: false,
  };

  constructor(
    public glSystemRuleService: GLSystemRuleService,
    public dialog: DialogService,
    public router: Router,
    public alert: AlertService,
    public spidCodingGridService: SPIDCodingGridService,
    public settingsService: UserSettingsService,
    public sortingService: SortingService,
    public loaderService: LoaderService,
    public spidCodingFilterService: SpidCodingFilterService,
    public formBuilder: FormBuilder,
    private systemRuleStore: SystemRuleStore,
    private permissionService: PermissionService,
    private systemRuleSelectors: SystemRuleSelectors) {
    super(new PageContext({
      name: 'app.gl.spid-without-gl-codes-coding',
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.spidSorting = this.sortingService.builder();

    this.query = this.setInitialQuery();
  }

  saveSPIDConfig(params) {
    this.selectedCodings = params;

    let createCodings = this.selectedCodings.filter((o) => {
      o.ban = this.selectedBAN;
      o.spid = this.selection.spid;
      o.gl_coding_enabled = this.selection.gl_coding_enabled;
      o.vendor_id = this.selectedVendor;
      return o.id == null && o.gl_apportion_pct > 0;
    });

    let updateCodings = this.selectedCodings.filter((o) => {
      return o.id != null && o.gl_apportion_pct > 0;
    });

    let selectedIds = updateCodings.map((o) => {
      return o.id;
    });

    let deleteCodings = this.originalCodings.filter((o) => {
      return selectedIds.indexOf(o) === -1;
    });

    this.onSaveSpid.emit({
      pending: true
    });

    this.glSystemRuleService.saveSPIDCodings(this.selection, createCodings, updateCodings, deleteCodings)
      .then((result) => {
        this.selection = result;
        this.loadSPIDCodings();

        let obj = this.selection;
        obj.gl_coding_enabled = !this.selection.gl_coding_enabled;
        this.changeStatus(obj);

        this.onSaveSpid.emit({
          pending: false
        });
      }).catch(err => this.onSaveSpid.emit({
      pending: false
    }))
  }

  onSelectionChanged(row) {
    this.selection = row.selectedRowsData[0];
    if (this.selection) {
      this.loadSPIDCodings();
    }
  }

  ngOnChanges(changes) {
    if (changes && changes.triggerReload && changes.triggerReload.currentValue) {
      this.saveSPIDConfig(changes.triggerReload.currentValue);
    }
  }

  public loadSPIDCodings() {
    let selection = this.selection;
    let selectedBAN = this.selectedBAN;

    this.glSystemRuleService.loadSPIDCodings(this.selectedBAN, selection.spid)
      .subscribe((result) => {
        this.selectedCodings = result.items;
        this.originalCodings = result.items.map((o) => {
          return o.id;
        });
        this.onSelection.emit({
          coding: selection,
          strings: this.selectedCodings,
          show: selection == null,
          ruleInfo: {
            meta: {
              ban: selectedBAN,
              spid: selection.spid
            },
            type: 'spid'
          }
        });
      });
  }


  public onSpidCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.spidSorting.apply(event, this.query);
      this.loadSpids(this.query);
    }
  }

  loadSpids(query: Query) {
    if (this.selectedVendor && this.selectedBAN) {

      this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

      this.glSystemRuleService.loadSPIDs(this.selectedVendor, this.selectedBAN, query)
        .subscribe((result) => {
            this.spids = result;
            this.selectFirstRow();
            this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
          },
          () => {
            this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
          });
    }
  }

  handleVendorChange() {
    this.systemRuleStore.resetSpidBan();
  }

  onClearVendor() {
    this.systemRuleStore.resetSpidBan();
    this.onClearSelection.emit();
  }

  onClearAccount() {
    this.onClearSelection.emit();
  }

  ngOnInit() {
    this.loadPermissions()
            .subscribe(() => {
            })
    this.columns = this.spidCodingGridService.columns();
    this.form$ = this.systemRuleStore.select('spidForm');
    this.banDisabled$ = this.systemRuleSelectors.isSpidBanDisabled(this.systemRuleStore);
    combineLatest(
      [this.systemRuleSelectors.getSpidVendor(this.systemRuleStore),
      this.systemRuleSelectors.getSpidBan(this.systemRuleStore)]
    ).pipe(
      takeUntil(this.destroy$))
      .subscribe(([vendor_id, ban]: any) => {
        this.selectedVendor = vendor_id;
        this.selectedBAN = ban;
        if (this.selectedBAN != null && this.selectedVendor != null) {
          this.filterVisible = false;
          this.query = this.setInitialQuery();
          this.loadSpids(this.query);
        } else {
          this.spids = [];
        }
      });
  }

  ngAfterViewInit(): void {
    this.spidCodingGridService.create(this.dataGrid.instance, {
      noDataText: this.spidCodingGridService.noDataMessage
    });
    super.ngAfterViewInit();
  }

  validGLSelectionEvent(value) {
    this.validGLSelection = value;
  }

  changeStatus(obj) {
    if (!this.isUndefinedOrNull(obj.gl_coding_enabled)) {
      obj.ban = this.selectedBAN;
      obj.gl_coding_enabled = !obj.gl_coding_enabled;

      let changeSPID = this.permissionModify ? this.glSystemRuleService.changeSPIDStatus(obj) :  this.glSystemRuleService.codingChangeSPIDStatus(obj);

      changeSPID
        .subscribe(() => {
          this.alert.success('', this.messages.get('SPID_CODING_GL_STATUS_CHANGE_SUCCESS'));
        });
    }
  }
  loadPermissions() {
    const glModify$ = this.permissionService.isAllowed("gl", "MODIFY");
    const glCreate$ = this.permissionService.isAllowed("gl", "CREATE");

    return forkJoin([
      glModify$,
      glCreate$]
    ).pipe(map(([glModify, glCreate]) => {
      this.permissionModify = glModify;
      this.actionSchema = {
        add: glCreate,
        edit: glCreate && glModify,
      };
      this.onActionsLoaded.emit(this.actionSchema)
    }));
  }

  isUndefinedOrNull(val) {
    return val === undefined || val === null;
  }

  // redefine methods from base class to prevent detail panel hide
  public selectFirstRow() {
    setTimeout(() => {
      if (this.dataGrid) {
        let dataGridInstance = this.dataGrid.instance;
        if (dataGridInstance && dataGridInstance.totalCount()) {
          dataGridInstance.selectRowsByIndexes([0]);
          this['selection'] = dataGridInstance.getSelectedRowsData()[0];
        } else {
          this['selection'] = null;
        }
      }
    }, 500);
  }

  onSpdidsGridRowClick(row) {
    this.filterVisible = false;
  }


  reset() {
  }

  toggleFilter() {
    if (this.selectedVendor && this.selectedBAN) {
      this.filterVisible = !this.filterVisible;
    }
  }

  filterData(query: Query) {
    this.loadSpids(query);
  }

  clearFilter() {
    this.query = this.setInitialQuery();
    this.loadSpids(this.query);
  }

  setInitialQuery() {
    let query = Object.assign(new Query(), this.query);
    query.clear();
    return query;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
