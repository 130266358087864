import { Injectable } from "@angular/core";
import { DefaultDateRangesLookupProvider } from "../../core/filter/default-date-ranges-lookup.provider";
import { FilterService } from "../../core/filter/filter.service";
import { CurrencyLookupProvider } from "../../location/core/currency-lookup.provider";
import { LocationService } from "../../location/core/location.service";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { invoiceChargeProfileFilterContext } from "./invoice-charge-profile-filter.context";
import { ChargeTypeLookupProvider } from "app/charge/core/charge-type-lookup.provider";
import { DictionaryService } from "app/dictionary/core/dictionary.service";

@Injectable()
export class InvoiceChargeProfileFilterService extends FilterService {
  customRanges: any = [];
  excludeRanges: any = [];

  constructor(
    public userSettingsService: UserSettingsService,
    public locationService: LocationService,
    public dictionaryService: DictionaryService
  ) {
    super(
      "app.invoice.charge-profile-filter",
      invoiceChargeProfileFilterContext,
      userSettingsService
    );

    this.addLookupProvider(
      "currency",
      new CurrencyLookupProvider(locationService)
    );
    this.addLookupProvider('chargeType', new ChargeTypeLookupProvider(dictionaryService));
    this.addLookupProvider(
      "defaultDateRanges",
      new DefaultDateRangesLookupProvider(
        this.customRanges,
        this.excludeRanges,
        true
      )
    );
  }
}
