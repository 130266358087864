import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {InvoiceContactGridService} from "../../core/invoice-contact-grid.service";
import {InvoiceNoteGridService} from "../../core/invoice-note-grid.service";
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {Select, Store} from "@ngxs/store";
import {QueryBuilder} from "../../../common/query/query.builder";
import {ActivatedRoute, Router} from "@angular/router";
import {Config} from "../../../core/config/config";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {HistoryComponent} from "../../../shared/history/history.component";
import {Observable, Subject} from "rxjs";
import {INVOICE_STATUS_ENUM} from "../../core/invoice-status.enum";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {InvoiceFlowService} from "../../core/invoice-flow.service";
import {HistoryService} from "../../../shared/history/history.service";
import {
  UpdateInvoiceNoteContactSiderSection
} from "./state/invoice-note-contact.actions";
import {takeUntil} from "rxjs";

@Component({
  selector: 'app-invoice-notes-and-contacts',
  templateUrl: './invoice-notes-and-contacts.component.html',
  styleUrls: ['./invoice-notes-and-contacts.component.css']
}) // extends PageDetailsComponent
export class InvoiceNotesAndContactsComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly SECTIONS = {
    HISTORY_SECTION_NAME: "history",
    NOTES_SECTION_NAME: 'notes',
  };

  invoice: InvoiceFacepage
  config: Config;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'invoice';
  invoiceNotesColumns: any;
  haveInvoiceNotes = false;
  invoiceContactsColumns: any;
  haveInvoiceContacts = false;
  notes: any[] = []
  contacts: any[] = []
  sider: Sider;

  dnpInfo: any = null
  onHoldInfo: any = null
  notesCount = 0;
  invoiceStatusEnum = INVOICE_STATUS_ENUM;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('invoiceNotesGrid') invoiceNotesGrid: DxDataGridComponent;
  @ViewChild('invoiceContactsGrid') invoiceContactsGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide;
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('testNotes') testNotes;

  @Select(state => state.invoice_note_contact.activeSiderSection) $activeSiderSection: Observable<any>;

  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public invoiceService: InvoiceFacepageService,
    public alertService: AlertService,
    public settingsService: UserSettingsService,
    public invoiceContactsGridService: InvoiceContactGridService,
    public invoiceNotesGridService: InvoiceNoteGridService,
    public invoiceFlowService: InvoiceFlowService,
    public historyService: HistoryService,
  ) {
    this.invoice = this.route.parent.snapshot.data.invoice;
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.invoiceService.invoiceChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(invoice => {
      this.invoice = invoice;
      this.history.refreshList();
    })
  }

  ngOnInit(): void {
    this.invoiceNotesColumns = this.invoiceNotesGridService.columns();
    this.invoiceContactsColumns = this.invoiceContactsGridService.columns();
    this.loadNotesAndContacts(this.invoice);
    this.getOnHoldInfo()
    this.testNotes && this.testNotes.emitCount();
    //this.getDNPInfo(this.invoice.notes, false)
  }

  ngAfterViewInit() {
    this.sider = this.createSider();

    /** Invoice Contacts grid */
    this.invoiceContactsGridService.create(
      this.invoiceContactsGrid.instance,
      {
        noDataText: this.invoiceContactsGridService.noDataMessage
      }
    );
    /** Invoice Notes grid */
    this.invoiceNotesGridService.create(this.invoiceNotesGrid.instance, {
      noDataText: this.invoiceNotesGridService.noDataMessage
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  loadNotesAndContacts(invoice: InvoiceFacepage) {
    this.invoiceService
      .loadInvoiceNotesAndContacts(invoice.invoice_id)
      .subscribe(
        result => {
          this.notes = result.notes;
          this.haveInvoiceNotes = !!(
            this.notes && this.notes.length
          );
          this.contacts = result.contacts;
          this.haveInvoiceContacts = !!(
            this.contacts && this.contacts.length
          );
        },
        err => {
          this.alertService.error('', this.messages.get('NOTE_LOAD_ERROR'));
        }
      );
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInvoiceNoteContactSiderSection(activeSection.name)
    ]);
  }

  /** Toolbar actions */

  async getOnHoldInfo() {
    if (!this.invoice.account.on_hold) {
      this.onHoldInfo = null;
      return;
    }
    let accountHistory = await this.historyService.findAllForEntity('account', this.invoice.account.id).toPromise()
    let onHoldFound = accountHistory.filter(account => (account.meta_data.on_hold && account.meta_data.on_hold.value === true))
    this.onHoldInfo = (onHoldFound && onHoldFound.length > 0) ? onHoldFound[0] : null
  }

  getDNPInfo(notes: any, forceEmpty: boolean = false) {
    const dnpStatus = this.invoiceFlowService.LOCALS.DO_NOT_PROCESS

    if (forceEmpty || this.invoice.header.status_code !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
      this.dnpInfo = null
    } else {
      const dnpNotes = notes.filter(note => note.content.startsWith(dnpStatus));
      this.dnpInfo = (dnpNotes.length) ? dnpNotes[0] : {};
      this.dnpInfo.status = dnpStatus;
      this.dnpInfo.message = this.dnpInfo.content ? this.dnpInfo.content.substring(dnpStatus.length + 2) : this.messages.get('DNP_FALLBACK_MESSAGE');
    }
  }

  /* Notes count changed */
  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }


  onNoteCreated() {
    // this.refresh(this.query)
    // // TODO: was this.loadCharges(this.invoice); now refresh / check if valid
    // this.selectedCharges.length = 0;
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/invoice']);
    }
  }
}
