import { Injectable } from '@angular/core';

import { BaseService } from '../../core/base.service';
import { Audit } from './audit';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';
import Query from "../../core/query/query";

@Injectable()
export class AuditsService extends BaseService<Audit> {

  constructor(private entityLock: EntityLockService) {
    super('audit-entries', entityLock);
  }

  findAllList(query?: Query, includeAddressSearch?): any {
    if(JSON.stringify(query.orderBy[0]) == JSON.stringify(["description", "DESC"])){
      query.orderBy = [['description', 'DESC'], ['id', 'DESC']]
    }
    if(JSON.stringify(query.orderBy[0]) == JSON.stringify(["description", "ASC"])){
      query.orderBy = [['description', 'ASC'], ['id', 'ASC']]
    }
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().post([this.name, 'list'], {
      ...this.toFilter(transformedQuery),
      includeAddressSearch: includeAddressSearch || false
    });
  }

  findAllForType(typeId: number, query?: Query) {
    return this.httpService().get([this.name, 'list', typeId], this.toFilter(query));
  }

  getAuditSpecificHistory(query) {
    return this.httpService().get('audit-process/audit-specific-history/', this.toFilter(query));
  }
  getAuditHistory(query) {
    return this.httpService().get('audit-process/history/', this.toFilter(query));
  }

  updateBulk(audit_id, item){
    return this.httpService().put([this.name, audit_id], item);
  }
}
