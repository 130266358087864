import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { PickerComponentBase, PICKER_COMPONENT_TEMPLATE } from '../../../shared/picker/ca-picker-base.component';
import { NetworkHubQuery } from '../../core/network-hub.query';
import { NetworkHubService } from '../../core/network-hub.service';

@Component({
  selector: 'ca-network-hub-circuit-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaNetworkHubCircuitPickerComponent),
      multi: true
    },
    NetworkHubService
  ]
})
export class CaNetworkHubCircuitPickerComponent extends PickerComponentBase
  implements OnInit, OnChanges {
  readonly TEXT_FIELD = 'description';

  public readonly NETWORK_HUB_CAPACITY_NAMES = {
    OC12: 'OC12',
    DS3: 'DS3',
    DS1: 'DS1',
    GB1: '1GB',
    GB10: '10GB',
    UNASSIGNED: 'Unassigned'
  };

  query: NetworkHubQuery = new NetworkHubQuery({
    limit: 20
  });

  @Input('parentId') parentId;
  @Input('hubId') hubId;
  parentIdPrevious: number;
  hubIdPrevious: number;
  @Input('showInventory') showInventory = false;
  @Input('typeLabel') typeLabel;
  @Input('formControlName') formControlName;
  @Input('initialItem') initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('placeholder') placeholder = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('clearEnabled') clearEnabled: boolean;

  @Output() fullEntryListChanged: EventEmitter<any> = new EventEmitter();
  public control;

  constructor(public networkHubService: NetworkHubService) {
    super();
  }

  loadEntries(): Observable<any> {
    return this.networkHubService.fetchHubCircuits(this.hubId);
  }

  loadData(): void {
    if (this.hubId) {
      this.loadEntries().subscribe(result => {
        console.log(result, result.filter(item => !item.parent_id), this.hubId, this.parentId)
        this.fullEntryListChanged.emit(result);
        let identifier = this.parentId;
        if (this.hubId == this.parentId) {
          let root = result.filter(item => !item.parent_id)[0];
          identifier = root ? root.id : this.parentId
        }
        if (this.showInventory) {
          result = result.filter(
            item => item.parent_id == identifier || item.id == this.value
          );
        } else {
          result = result.filter(
            item =>
              item.parent_id == identifier &&
              (!item.inventory || item.id == this.value)
          );
        }
        this.prepareData(result);
      });
    } else {
      this.parentId = null;
    }
  }

  prepareData(data) {
    this.entries = data.items ? data.items : data;
    this.entries.sort((a, b) => {
      return a.order - b.order;
    });
    this.entries.forEach(slot => {
      if (slot.name === this.NETWORK_HUB_CAPACITY_NAMES.UNASSIGNED) {
        slot.description = this.NETWORK_HUB_CAPACITY_NAMES.UNASSIGNED;
        return;
      }
      slot.description = [
        this.typeLabel,
        (slot.inventory || {}).unique_id || 'Slot available',
        '-',
        slot.order
      ].join(' ');
    });
    this.query.total = data.total;
    this.query.page = 1;
  }

  // search(value: string): Observable<any> {
  // TODO: FIX THIS SO IT CAN BE SEARCHABLE
  // let query = new NetworkHubQuery();
  // if (value) {
  //   query.where['order'] = value;
  // }
  // query.where['network_hub_id'] = this.hubId;
  // return this.networkHubService.fetchHubCircuitsQueryFormater(query, this.hubId, this.parentId, this.typeLabel, this.showInventory, this.value)
  // return this.networkHubService.fetchHubCircuits(this.hubId);

  //   this.loadData();
  //   return null;
  // }

  search(value: string): Observable<any> {
    this.loadData();
    return null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.pickerForm && this.formControlName) {
      this.control = this.pickerForm.controls[this.formControlName];
    }

    if (this.parentId !== this.parentIdPrevious) {
      this.parentIdPrevious = this.parentId;
      this.loadData();
    } else if (this.hubId !== this.hubIdPrevious) {
      this.hubIdPrevious = this.hubId;
      this.loadData();
    }
  }
}
