<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
	<button mat-icon-button matTooltip="Back" (click)="back()">
		<ca-icon [key]="'BACK'"></ca-icon>
	</button>
	<span class="toolbar-title">{{ pageTitle }}</span>
	<span class="example-fill-remaining-space">&nbsp;</span>
	<button
		*permission="{ action: 'View', key: 'invoice' }"
		[disabled]="!(alert && alert.invoice && alert.invoice.invoice_id)"
		(click)="goToInvoiceDetails()"
		mat-icon-button
		matTooltip="View Invoice Details"
	>
		<ca-icon [key]="'VIEW_INVOICE_DETAILS'"></ca-icon>
	</button>
	<button
		*permission="{ action: 'Create', key: 'disputes' }"
		mat-icon-button
		[disabled]="isAddDisputeDisabled"
		(click)="addDispute()"
		[hidden]="!tabGroup.isActive(2)"
	>
		<ca-icon [key]="'ADD_DISPUTE'" [tooltip]="'Create Dispute'"></ca-icon>
	</button>
	<button
		*permission="{ action: 'CREATE', key: 'charges' }"
		mat-icon-button
		(click)="addInvoiceChargeProfile()"
		matTooltip="Add Invoice Charge Profile"
		[disabled]="isAddInvoiceChargeProfileDisabled"
		[hidden]="!tabGroup.isActive(2)"
	>
		<ca-icon [key]="'INVOICE_CHARGE_PROFILE'"></ca-icon>
	</button>
	<button
		[hidden]="!tabGroup.isActive(2)"
		mat-icon-button
		matTooltip="Refresh"
		(click)="refresh()"
	>
		<ca-icon [key]="'REFRESH'"></ca-icon>
	</button>
	<div class="vertical-divider"></div>
	<button
		[disabled]="!tabGroup.isActive(2) || isGridSettingsDisabled"
		mat-icon-button
		matTooltip="Settings"
		(click)="gridSettings()"
	>
		<ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
	</button>
	<div class="vertical-divider"></div>
	<button
		[disabled]="!tabGroup.isActive(2)"
		[disabled]="!selection"
		[ngClass]="{
			on: sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2)
		}"
		(click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)"
		mat-icon-button
		matTooltip="Filter"
	>
		<ca-icon [key]="'FILTER'"></ca-icon>
	</button>
</mat-toolbar>

<mat-sidenav-container class="app-content tabbed-content">
	<mat-tab-group (selectedIndexChange)="onTabChange($event)">
		<mat-tab
			[label]="tab.title"
			*ngFor="let tab of tabGroup.tabs"
			[disabled]="tab.disabled"
		>
		</mat-tab>
	</mat-tab-group>

	<div *ngIf="tabGroup.isActive(1)" class="tabs-body" fxLayout="column">
		<div
			class="flex-container ca-card custom-mat-card-container"
			fxLayout="row"
		>
			<mat-card fxFlex="60%" fxFlexFill caCard>
				<mat-card-content>
					<div fxLayout="row" class="entry-list">
						<div fxFlex="33%" class="entry-list-item">
							<label>Category</label>
							<p>{{ alertCategory }}</p>
						</div>

						<div fxFlex="33%" class="entry-list-item">
							<label>Created At</label>
							<p>{{ (alert?.created_at | caDateTime) || 'N/A' }}</p>
						</div>

						<div fxFlex="33%" class="entry-list-item">
							<label>Created By</label>
							<p>{{ alertCreatedBy }}</p>
						</div>
					</div>

					<div fxLayout="row" class="entry-list">
						<div fxFlex="33%" class="entry-list-item">
							<label>Owner</label>
							<p>{{ alertOwner }}</p>
						</div>

						<div fxFlex="33%" class="entry-list-item">
							<label>Priority</label>
							<p>{{ alertPriority }}</p>
						</div>

						<div fxFlex="33%" class="entry-list-item">
							<label>Status</label>
							<p>{{ alertStatus }}</p>
						</div>
					</div>

					<div fxLayout="row" class="entry-list">
						<div fxFlex="33%" class="entry-list-item">
							<label>Last Modified At</label>
							<p>
								{{ (alert?.last_modified_at | caDate) || 'N/A' }}
							</p>
						</div>

						<div fxFlex="33%" class="entry-list-item">
							<label>Closed At</label>
							<p>
								{{ (alert?.closed_at | caDate) || 'N/A' }}
							</p>
						</div>
					</div>
					<div fxLayout="row" class="entry-list">
						<div fxFlex class="entry-list-item">
							<label>Message</label>
							<p>
								{{ alert?.message || 'N/A' }}
							</p>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div *ngIf="tabGroup.isActive(2)" class="tabs-body master-detail-arrow-color">
		<dx-data-grid
			#dataGrid
			class="app-grid-with-pager app-content-list master-charge-table"
			(onSelectionChanged)="onSelectionChanged($event)"
			[columns]="columns"
			[dataSource]="audits"
			[showColumnLines]="false"
			[allowColumnResizing]="true"
			[columnAutoWidth]="true"
			[selection]="{ mode: 'multiple' }"
			(onCellClick)="onCellClick($event)"
		>
			<dxo-paging [pageSize]="10" [enabled]="false"></dxo-paging>
		</dx-data-grid>
	</div>
	<ca-pager
		*ngIf="tabGroup.isActive(2)"
		[gridPager]="defaultGridPager"
		[pageContext]="alertDetailsGridService"
		[query]="query"
		class="app-pager ca-default-border-top"
		(onChange)="onPageChange(query)"
	></ca-pager>

	<mat-sidenav
		#panelSide
		mode="side"
		position="end"
		opened="false"
		[ngClass]="{ 'visibility-hidden': !tabGroup.isActive(2) }"
	>
		<new-app-filter
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
			[query]="query"
			[filterService]="detailsFilterService"
			(filterRequested)="filterData($event)"
			(clearFilterRequested)="clearFilter($event)"
		>
		</new-app-filter>
		<app-alert-inventory-mrc-sneak-peek
			[entry]="selection"
			(closeRequested)="sider?.close()"
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME) }"
		></app-alert-inventory-mrc-sneak-peek>
	</mat-sidenav>
</mat-sidenav-container>
