import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";

import {Observable, of, Subject} from "rxjs";
import {Inventory} from "../../core/inventory";
import {InventoryService} from "../../core/inventory.service";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {GLRuleStringGridService} from "../../../gl-rule/core/gl-rule-string-grid.service";
import Query from "../../../core/query/query";
import {
  GlStringSelectGridDialogComponent
} from "../../../gl-system-rules/shared/gl-string-select-grid-dialog/gl-string-select-grid-dialog.component";
import {AlertService} from "../../../shared/alert/alert.service";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {Select, Store} from "@ngxs/store";
import {UpdateInventoryCodeActiveSiderSection} from "./state/inventory-code.actions";

@Component({
  selector: 'app-inventory-code',
  templateUrl: './inventory-code.component.html',
  styleUrls:['./inventory-code.component.scss']
})
export class InventoryCodeComponent extends PageDetailsComponent  implements OnInit {
  inventory: Inventory;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public previewGridColumns: Array<any> = [];
  public selectedCodings: Array<any> = [];
  public glQuery: Query = new Query();
  public validGLSelection = false;

  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  sider: Sider;
  notesCount: number;
  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
    HISTORY_SECTION_NAME: 'history'
  };

  @ViewChild('panelSide') panelSide;
  @ViewChild('glCodeGrid') glCodeGrid;
  @Select(state => state.inventory_code.activeSiderSection) $activeSiderSection: Observable<any>;

  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public router: Router,
    public settingsService: UserSettingsService,
    public glRuleStringGridService: GLRuleStringGridService,
    public alertService: AlertService,
    public dialogService: DialogService,
    private readonly store: Store,
  ) {
    super(new PageContext({
      name: 'app.inventory.inventory-code',
      settings: settingsService
    }))
  }

  loadPermissions() {
    return of({});
  }


  private getInventory() {
    return this.route.parent.snapshot.data.inventory;
  }

  ngOnInit(): void {

    this.inventory = this.getInventory();

    this.loadGlString();

    this.glRuleStringGridService.generateColumns('_obj')
      .then((columns: Array<any>) => {
        this.previewGridColumns = columns;
      });
  }

  loadGlString() {
    if (this.inventory) {
      this.glQuery.where = {
        inv_id: this.inventory.id
      };
      this.inventoryService.getGLStrings(this.glQuery).subscribe((result: any) => {
        this.selectedCodings = result.items;
        this.glQuery.total = result.total;
        this.glRuleStringGridService.generateColumns('_obj')
          .then((columns: Array<any>) => {
            this.previewGridColumns = columns;
          });
      })
    }
  }

  public changeGlPage() {
    this.loadGlString();
  }

  addString() {
    // reset query for a modal to be opened
    let query = new Query();
    query.where = {
      inv_id: this.inventory.id
    };
    query.limit = 1000000;
    delete query.offset;
    delete query.page;

    this.inventoryService.getGLStrings(query).subscribe((result: any) => {
      this.dialogService.open(GlStringSelectGridDialogComponent, {
        selectedStrings: result.items,
        getLoadData: () => ({
          ruleType: 'inventory',
          ruleMeta: {
            inv_id: this.inventory.id
          },
        }),
      }, {width: '80%'})
        .afterClosed()
        .subscribe((result) => {
          if (result.data) {
            this.inventoryService.saveGLStrings(this.inventory.id, result.data).subscribe((res: any) => {
              // TODO: Check the result and handle accordingly
              this.loadGlString()
              this.history.refreshList();
            });
          }
          this.validGLSelection = result.valid;
        });
    })
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
    this.glRuleStringGridService.create(this.glCodeGrid.instance, {
      noDataText: 'No Data'
    });
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInventoryCodeActiveSiderSection(activeSection.name)
    ]);
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/inventory']);
    }
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }

  onDownloadCSVRequested() {
    // TODO: check if middleware is needed and crete if it is

    this.glRuleStringGridService.generateColumns('_obj')
      .then((columns: Array<any>) => {
        let fields = columns.map((column) => {
          return {
            value: column.dataField,
            label: column.caption.replace(' #', ''),
            type: column.csvDataType ? column.csvDataType : column.dataType,
            transformValue: column.transformValue,
            isExchangeRate: column.isExchangeRate,
            csvOutput: column.csvOutput === false ? column.csvOutput : true
          };
        })
        this.inventoryService.exportToCSV('inventory-codes', {
          fields,
          query: this.glQuery,
          fetchDataHandler: (query) => {
            return this.inventoryService.getGLStrings(query)
          },
          middleware: []
        })
      });
  }
}
