import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';

import { PickerComponentBase, PICKER_COMPONENT_TEMPLATE } from '../../../shared/picker/ca-picker-base.component';
import { ContractService } from '../../core/contract.service';
import { SvcOrderService } from "../../core/svc-order.service";

@Component({
  selector: 'ca-contract-schedule-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractSchedulePickerComponent),
      multi: true
    }
  ]
})
export class ContractSchedulePickerComponent extends PickerComponentBase implements OnInit, OnChanges {
  TEXT_FIELD = 'schedule_name';

  @Input() formControlName;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() placeholder = 'Select ...';
  @Input() initialItem;
  @Input() pickerForm;
  @Input() searchEnabled: boolean;
  @Input() clearEnabled: boolean;
  @Input() query;
  @Input() onlyActive = false;
  @Input() selectContractIfOne = false;
  @Input() contractId: number;
  @Input() svcOrderId: number;
  @Input() scheduleSeriesId: number;
  @Input() scheduleId: number;
  @Input() svcOrderServices: boolean;
  @Input() dateToday: boolean;


  public control;

  constructor(
    public contractService: ContractService,
    public svcOrderService: SvcOrderService
  ) {
    super();
  }

  loadEntries(): Observable<any> {
    this.query.limit = 1000;

    if (this.svcOrderServices && this.svcOrderId) {
      this.TEXT_FIELD = 'description';
      if (this.svcOrderId) {
        this.query.where.svc_order_header_id = +this.svcOrderId;
      }
      return this.svcOrderService.findAllServices(this.svcOrderId, this.query)
    } else if (this.svcOrderServices === false) {
      if (this.contractId) {
        this.query.where.contract_shell_id = +this.contractId;
      }
      if (this.scheduleId) {
        this.query.where.id = { '$not': this.scheduleId };
      }
      if (this.scheduleSeriesId) {
        this.query.where.schedule_series_id = +this.scheduleSeriesId;
      }
      return this.contractService.findSchedules(this.query);
    }
    return observableOf([])
  }

  search(value: string): Observable<any> {
    this.query.limit = 1000;
    if (this.contractId) {
      this.query.orderBy = [['schedule_name', 'ASC']]
    }
    if (this.svcOrderId) {
      this.query.orderBy = [['description', 'ASC']]
    }

    if (this.svcOrderServices && this.svcOrderId) {
      this.TEXT_FIELD = 'description';

      if (this.svcOrderId) {
        this.query.where.svc_order_header_id = +this.svcOrderId;
      }
      return this.svcOrderService.findAllServices(this.svcOrderId, this.query)
    } else if (this.svcOrderServices === false) {
      if (this.contractId) {
        this.query.where.contract_shell_id = this.contractId;
      }
      if (this.scheduleSeriesId) {
        this.query.where.schedule_series_id = this.scheduleSeriesId;
      }
      this.query.where['schedule_name'] = { '$ilike': `${value}%` };
      return this.contractService.findSchedules(this.query);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];
  }
}
