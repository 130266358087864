import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import {debounceTime, takeUntil} from 'rxjs';
import Query from '../../query/query';
import { FilterEntry } from '../filter-entry';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'ca-country-filter',
  templateUrl: 'country-filter.component.html',
  styleUrls: ['./country-filter.component.scss']
})

export class CountryFilterComponent implements OnInit, OnChanges {
    @Output() queryChanged: EventEmitter<Query> = new EventEmitter();

    @Input('entry') entry: FilterEntry;
    @Input('query') query: Query;
    destroy$: Subject<boolean> = new Subject<boolean>();
    @ViewChild('picker') picker;
    selectFormControl = new FormControl(null);
    searchFormControl = new FormControl();

    constructor() {

     }

    ngOnInit(): void {
        this.searchFormControl.valueChanges.pipe(
            debounceTime(250),
            takeUntil(this.destroy$),)
            .subscribe(
              value => {
                this.searchCountry(value);
              }
            );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['query']) {
            this.clearCountry()
        }
    }

    onSelectionChange(event) {
        this.query.where[this.entry['field']] = event.value;
    }

    searchCountry(value) {
        let myQuery = new Query();
        if(this.entry?.editor === 'subdivision') {
            myQuery.where = {
                $or: [
                  {abbreviation: { '$ilike': `%${value}%` }},
                  {name: { '$ilike': `%${value}%` }}
                ]
              }
        } else {
            myQuery.where = {
              $or: [
                {alpha_2_code: { '$ilike': `%${value}%` }},
                {name: { '$ilike': `%${value}%` }}
              ]
            }
        }
        this.entry['lookupProvider'].findAll(myQuery)
            .subscribe((result) => {
              this.entry['lookupItems'] = result;
            });
      }
    

    openedChange(isOpened) {
        if (isOpened){
          this.searchCountry('');
          this.selectFormControl.markAsTouched();
        } else {
          this.searchFormControl.setValue(null, { emitEvent: false });
        }
    }

    clearCountry() {
        this.selectFormControl.reset();
        this.picker?.close();
        delete this.query?.where[this.entry['field']];
        this.queryChanged.emit(this.query)
    }
    
}
