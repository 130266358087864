<div class="mat-dialog-top-spacer"></div>
<div mat-dialog-content class="ca-dialog-content">
  <p>
    {{ message }}
  </p>
  <form class="ca-form" fxLayout="column" [formGroup]="form">
    <div class="flex-container" fxLayout.sm="column">
      <div class="flex-item caGap" fxFlex fxFlex="60%">
        <ca-lookup-model-picker
          caInput
          [pickerForm]="form"
          [lookupModel]="REPLACE_INVOICE_CHARGE_PROFILE"
          [clearEnabled]="true"
          formControlName="replacement"
          required="true"
          placeholder="Replacement">
        </ca-lookup-model-picker>
      </div>
    </div>

  </form>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space"></div>

  <button mat-button type="submit" (click)="submitValueAndClose(false)">Cancel</button>

  <button
    mat-button
    class="previous"
    type="button"
    [disabled]="form?.invalid"
    (click)="submitValueAndClose(true)"
  >
    Continue
  </button>
</mat-dialog-actions>
