

import {BandwidthService} from "./core/bandwidth.service";
import {NgModule} from "@angular/core";
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        BandwidthService
    ]
})
export class BandwidthModule { }
