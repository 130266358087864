import { Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from "rxjs";
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from '../../../shared/picker/ca-picker-base.component';
import { ContractService } from '../../core/contract.service';
import { SvcOrderService } from "../../core/svc-order.service";

@Component({
  selector: 'ca-contract-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractPickerComponent),
      multi: true
    }
  ]
})
export class ContractPickerComponent extends PickerComponentBase implements OnInit, OnChanges {
  readonly TEXT_FIELD = 'name';
  readonly SECOND_LINE_TEXT_FIELD = 'description';

  @Input() formControlName;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() placeholder = 'Select ...';
  @Input() initialItem;
  @Input() pickerForm;
  @Input() searchEnabled: boolean;
  @Input() clearEnabled: boolean;
  @Input() query;
  @Input() onlyActive = false;
  @Input() pickerType = 'contract';
  @Input() selectContractIfOne = false;
  @Input() showOrderSvc = false;
  @Input() showContract = false;
  @Input() vendorId = false;
  @Input() disabledContracts

  public control;

  constructor(
    public contractService: ContractService,
    public svcOrderService: SvcOrderService,
  ) {
    super();
  }

  loadEntries(): Observable<any> {
    this.query.limit = 1000;
    if (this.query.where && !this.query.where.vendor_id) {
      this.query.where.vendor_id = this.vendorId;
    }

    if (this.showOrderSvc) {
      return this.svcOrderService.findAllByVendor(this.query)
    } else if (this.showContract) {
      return this.loadContracts()
    }
  }

  search(value: string): Observable<any> {
    this.query.limit = 1000;

    if (this.showOrderSvc) {

      this.query.where['name'] = {'$ilike': `${value}%`};
      return this.svcOrderService.findAllByVendor(this.query);
    } else if (this.showContract){

      this.query.where['name'] = {'$ilike': `${value}%`};
     return this.loadContracts()
    }
  }


  loadContracts(){
    return this.contractService.findOnlyContracts(this.query).pipe(
      map(res => {
        let array = []
        res['items'].map(item => {
          if(this.disabledContracts){
            if(this.disabledContracts.includes(item.id)) {
              array.push({...item, disabled: true})
              return
            }
          }
          array.push({...item, disabled: false})
        })
        res['items'] = [...array]
        return res;
      })
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];
  }
}
