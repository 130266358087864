<mat-form-field>
  <mat-label>{{placeholder}}</mat-label>
  <mat-select
    #picker
    [required]="required"
    [disabled]="disabled"
    (selectionChange)="onSelectionChange($event)"
    (openedChange)="openedChange($event)"
    [ngClass]="{'ca-picker-searchable' : searchEnabled, 'ca-picker': true}"
    [panelClass]="{'ca-picker-searchable' : searchEnabled, 'ca-picker': true}"
    [multiple]="multiple"
    #select="matSelect"
    [formControl]="selectFormControl"
  >
    <mat-select-trigger *ngIf="pickerType === 'contract'">
      <span *ngFor="let entry of entries">
        <span *ngIf="selectFormControl.value === entry.id">
          {{entry.name}} {{entry.description ? '•' : ''}} {{ entry.description }}
        </span>
      </span>
    </mat-select-trigger>
    <mat-form-field *ngIf="searchEnabled"
                    class="ca-picker-search-container"
                    floatPlaceholder="never" color="accent"
    >
      <mat-label></mat-label>
      <input title="Search"
             #searchInput type="text"
             (keyup.enter)="onSearchInputEnter($event)"
             matInput [formControl]="searchFormControl"
      >
    </mat-form-field>

    <div class="ca-picker-entries"
         [ngClass]="{'two-line-entries': secondLineTextField}"
         #entriesContainer
         (scroll)="scrollHandler($event)"
    >

      <button *ngIf="clearEnabled && selectFormControl.value"
              (click)="clearHandler()"
              mat-button class="clear-option"
      >Clear
      </button>

      <mat-option *ngIf="!entries.length" disabled>No data found</mat-option>
      <ng-container *ngFor="let entry of entries">
        <mat-option
          [disabled]="entry.disabled"
          [value]="returnWholeEntry ? entry : entry[keyField]"
          [ngClass]="{'two-line': secondLineTextField, 'option-italic': italicStyleOptionPredicate(entry)}"
          [matTooltip]="!returnWholeEntry && entry[textField]?.length > 35 ? entry[textField] : null"
        >
          <ng-container
            *ngIf="(!customFormating || !customFormating.length) && !secondTextField">{{ entry[textField] }}</ng-container>
          <ng-container *ngIf="customFormating && customFormating.length">
            {{ entry[customFormating[1]] }}, {{ entry[customFormating[0]] }}
          </ng-container>
          <ng-container *ngIf="secondTextField">
            {{ entry[textField] }} &bull; {{ entry[secondTextField] }}
          </ng-container>
          <span
            *ngIf="secondLineTextField && picker.panelOpen"
            class="second-line"
          >
                {{(secondLineTextField === SECOND_LINE_TEXTS.NETWORK_HUB_CAPACITY || secondLineTextField === SECOND_LINE_TEXTS.SITE_ADDRESS)
            ? (entry?.hub_capacity?.value || entry?.building?.address?.search_address)
            : entry[secondLineTextField]}}
            {{secondLineTextField === SECOND_LINE_TEXTS.BUILDING_ADDRESS ? entry?.address?.search_address : entry[secondLineTextField]}}
          </span>
        </mat-option>
      </ng-container>

    </div>

  </mat-select>
  <mat-hint *ngIf="control && control.touched && control.errors?.required"
            class="error-class">{{ VALIDATION.REQUIRED_ERROR }}
  </mat-hint>
</mat-form-field>
