<div class="container">
  <div
    *ngFor="let option of options; let i = index"
    [ngClass]="{
      'left-column': i <= options.length / 2,
      'right-column': i > options.length / 2
    }"
  >
    <mat-checkbox
      [value]="option?.key"
      (change)="onSelectionChange($event)"
      [checked]="option?.checked"
    >
      {{ option?.value }}
    </mat-checkbox>
  </div>
</div>
