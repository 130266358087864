import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from 'app/material-barrel/material-barrel.module';

import { ContractService } from './core/contract.service';
import { ContractManageDialogComponent } from './shared/contract-manage-dialog/contract-manage-dialog.component';
import { ContractShellMenageDialogComponent } from './shared/contract-shell-menage-dialog/contract-shell-menage-dialog.component';
import { ContractSectionManageDialogComponent } from './shared/contract-section-manage-dialog/contract-section-manage-dialog.component';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractPickerComponent } from './shared/contract-picker/contract-picker.component';
import { ContractComponent } from './contract.component';
import { ContractRoutingModule } from './contract-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';

import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../shared/shared.module';
import { ContractGridService } from './core/contract-grid.service';
import { AuditRatesGridService} from "./core/audit-rates-grid.service";
import { ContractSneakPeekComponent } from './shared/contract-sneak-peek/contract-sneak-peek.component';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { ContractScheduleDetailsComponent } from './contract-schedule-details/contract-schedule-details.component';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { ContractSectionGridService } from './core/contract-section-grid.service';
import { VendorModule } from '../vendor/vendor.module';
import { DocumentService } from '../shared/document/core/document.service';
import { DocumentUploadDialogComponent } from './shared/document-upload-dialog/document-upload-dialog.component';
import {CoreModule} from "../core/core.module";
import {ContractFilterService} from "./core/contract-filter.service";
import { InventoryService } from '../inventory/core/inventory.service';
import { ContractVendorInfoComponent } from "./contract-vendor-info/contract-vendor-info.component";
import { ContractsDocumentManagerComponent } from './shared/contracts-document-manager/contracts-document-manager.component';
import {ContractsScheduleDetailDocumentManagerComponent } from './shared/contracts-schedule-detail-document-manager/contracts-schedule-detail-document-manager.component';
import { ContractDocumentFilterComponent } from "./shared/contract-document-filter/contract-document-filter.component";
import { ContractDocumentGridService } from "./core/contract-document-grid.service";
import { ContractScheduleDetailDocumentGridService } from "./core/contract-schedule-detail-document-grid.service";
import { ContractInventoryGridService } from "./core/contract-inventory-grid.service";
import { DictionaryModule } from '../dictionary/dictionary.module';
import { ContractRateGridService } from './core/contract-rate-grid.service';
import { ContractScheduleDetailRateGridService } from './core/contract-schedule-detail-rate-grid.service';
import { RateService } from './core/contract-rate.service';
import { ContractRateEditGridService } from './core/contract-rate-edit-grid.service';
import { ContractInstanceService } from './core/contract-instance.service';
import { ContractInstanceManageDialogComponent } from './shared/contract-instance-manage-dialog/contract-instance-manage-dialog.component';
import { ContractInstancePickerComponent } from './shared/contract-instance-picker/contract-instance-picker.component';
import { ContractInstanceRateService } from './core/contract-instance-rate.service';
import {ContractShellVendorGridService} from "./core/contract-shell-vendor-grid.service";
import {ContractScheduleManageDialogComponent} from "./shared/contract-schedule-manage-dialog/contract-schedule-manage-dialog.component";
import {ContractSchedulePickerComponent} from "./shared/contract-schedule-picker/contract-schedule-picker.component";
import { ContractRateFilterService } from './core/contract-rate-filter.service';
import { ContractScheduleDetailRateFilterService } from './core/contract-schedule-detail-rate-filter.service';
import {SvcOrderService} from "./core/svc-order.service";
import {SvcOrderHeaderGridService} from "./core/svc-order-header-grid.service";
import {SvcOrderDetailsComponent} from "./svc-order-details/svc-order-details.component";
import {SvcOrderHeaderManageDialogComponent} from "./shared/svc-order-header-manage-dialog/svc-order-header-manage-dialog.component";
import {SvcOrderServiceGridService} from "./core/svc-order-service-grid.service";
import {SvcOrderServiceManageDialogComponent} from "./shared/svc-order-service-manage-dialog/svc-order-service-manage-dialog.component";
import {SvcOrderServiceFilterService} from "./core/svc-order-service-filter.service";
import {SiteModule} from "../site/site.module";
import {SvcOrderHeaderFilterService} from "./core/svc-order-header-filter.service";
import {ContractScheduleTermConditionManageDialogComponent} from "./shared/contract-schedule-term-condition-manage-dialog/contract-schedule-term-condition-manage-dialog.component";
import {ContractTermConditionGridService} from "./core/contract-term-condition-grid.service";
import {ContractScheduleDetailTermConditionGridService} from "./core/contract-schedule-detail-term-condition-grid.service";
import {ContractScheduleTermConditionGridService} from "./core/contract-schedule-term-condition-grid.service";
import {ContractTermAndConditionFilterService} from "./core/contract-term-and-condition-filter.service";
import {ContractInventoryManageDialogComponent} from "./shared/contract-inventory-manage-dialog/contract-inventory-manage-dialog.component";
import {CaContractInventoryPickerComponent} from "./shared/contract-inventory-picker/ca-contract-inventory-picker.component";
import {AccountModule} from "../account/account.module";
import {ContractScheduleCommitmentGridService} from "./core/contract-schedule-commitment-grid.service";
import {
  SvcOrderContractTermAndConditionGridService
} from "./core/svc-order-contract-term-and-condition-grid.service";
import {
  SvcOrderContractTermAndConditionFilterService
} from "./core/svc-order-contract-term-and-condition-filter.service";
import {
  SvcOrderContractScheduleTermConditionManageDialogComponent
} from "./shared/svc-order-term-condition-manage-dialog/svc-order-term-condition-manage-dialog.component";
import { ContractScheduleDetailTermAndConditionFilterService } from './core/contract-schedule-detail-term-and-condition-filter.service';
import { InventoryFilterService } from './core/contract-inventory-filter.service';
import { ManageRatesDialogComponent } from './shared/rates-audit-manage-dialog/rates-audit-manage-dialog'
import { ContractDetailsTabsComponent } from './contract-details/contract-details-tabs/contract-details-tabs.component';
import { ContractDetailsToolbarComponent } from './contract-details/contract-details-toolbar/contract-details-toolbar.component';
import { ContractSummaryComponent } from './contract-details/contract-details-summary/contract-details-summary.component';
import { ContractSchedulesComponent } from './contract-details/contract-details-schedules/contract-details-schedules.component';
import { ContractTermsConditionsComponent } from './contract-details/contract-details-terms-conditions/contract-details-terms-conditions.component';
import { ContractInventoryComponent } from './contract-details/contract-details-inventory/contract-details-inventory.component';
import { ContractAttachmentsComponent } from './contract-details/contract-details-attachments/contract-details-attachments.component';
import { ContractRatesComponent } from './contract-details/contract-details-rates/contract-details-rates.component';
import { SvcOrderDetailsSummaryComponent } from './svc-order-details/svc-order-details-summary/svc-order-details-summary.component';
import { SvcOrderDetailsServicesComponent } from './svc-order-details/svc-order-details-services/svc-order-details-services.component';
import { SvcOrderDetailsTermsConditionsComponent } from './svc-order-details/svc-order-details-terms-conditions/svc-order-details-terms-conditions.component';
import { SvcOrderDetailsAttachmentsComponent } from './svc-order-details/svc-order-details-attachments/svc-order-details-attachments.component';
import { SvcOrderDetailsToolbarComponent } from './svc-order-details/svc-order-details-toolbar/svc-order-details-toolbar.component';
import { SvcOrderDetailsTabsComponent } from './svc-order-details/svc-order-details-tabs/svc-order-details-tabs.component'
import { ContractScheduleSummaryComponent } from './contract-schedule-details/contract-schedule-summary/contract-schedule-summary.component';
import { ContractScheduleTermsConditionsComponent } from './contract-schedule-details/contract-schedule-terms-conditions/contract-schedule-terms-conditions.component';
import { ContractScheduleAttachmentsComponent } from './contract-schedule-details/contract-schedule-attachments/contract-schedule-attachments.component';
import { ContractScheduleRatesComponent } from './contract-schedule-details/contract-schedule-rates/contract-schedule-rates.component';
import { ContractScheduleTabsComponent } from './contract-schedule-details/contract-schedule-tabs/contract-schedule-tabs.component';
import { ContractScheduleToolbarComponent } from './contract-schedule-details/contract-schedule-toolbar/contract-schedule-toolbar.component';
import { CaInventorySvcPickerComponent } from "./shared/inventory-svc-picker/ca-inventory-svc-picker.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ContractRoutingModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        DxButtonModule,
        DxDataGridModule,
        MaterialModule,
        SharedModule,
        DxDateBoxModule,
        VendorModule,
        CoreModule,
        DictionaryModule,
        SiteModule,
        AccountModule,
    ],
    exports: [
        ContractPickerComponent,
        ContractSchedulePickerComponent,
        CaContractInventoryPickerComponent,
        CaInventorySvcPickerComponent
    ],
    declarations: [
        ContractManageDialogComponent,
        ContractShellMenageDialogComponent,
        ContractSectionManageDialogComponent,
        ContractListComponent,
        ContractComponent,
        ContractSneakPeekComponent,
        ContractDetailsComponent,
        ContractScheduleDetailsComponent,
        ContractPickerComponent,
        ContractSchedulePickerComponent,
        CaContractInventoryPickerComponent,
        DocumentUploadDialogComponent,
        ContractVendorInfoComponent,
        ContractsDocumentManagerComponent,
        ContractsScheduleDetailDocumentManagerComponent,
        ContractDocumentFilterComponent,
        ContractInstanceManageDialogComponent,
        ContractScheduleManageDialogComponent,
        ContractInstancePickerComponent,
        SvcOrderDetailsComponent,
        SvcOrderHeaderManageDialogComponent,
        SvcOrderServiceManageDialogComponent,
        ContractScheduleTermConditionManageDialogComponent,
        ContractInventoryManageDialogComponent,
        SvcOrderContractScheduleTermConditionManageDialogComponent,
        ManageRatesDialogComponent,
        ContractDetailsTabsComponent,
        ContractDetailsToolbarComponent,
        ContractSummaryComponent,
        ContractSchedulesComponent,
        ContractTermsConditionsComponent,
        ContractInventoryComponent,
        ContractAttachmentsComponent,
        ContractRatesComponent,
        SvcOrderDetailsSummaryComponent,
        SvcOrderDetailsServicesComponent,
        SvcOrderDetailsTermsConditionsComponent,
        SvcOrderDetailsAttachmentsComponent,
        SvcOrderDetailsToolbarComponent,
        SvcOrderDetailsTabsComponent,
        ContractScheduleSummaryComponent,
        ContractScheduleTermsConditionsComponent,
        ContractScheduleAttachmentsComponent,
        ContractScheduleRatesComponent,
        ContractScheduleTabsComponent,
        ContractScheduleToolbarComponent,
        CaInventorySvcPickerComponent
    ],
    providers: [
        ContractService,
        SvcOrderService,
        ContractGridService,
        ContractSectionGridService,
        SvcOrderServiceGridService,
        DocumentService,
        ContractFilterService,
        SvcOrderHeaderFilterService,
        SvcOrderServiceFilterService,
        ContractRateFilterService,
        ContractScheduleDetailRateFilterService,
        ContractTermConditionGridService,
        ContractScheduleDetailTermConditionGridService,
        SvcOrderContractTermAndConditionGridService,
        ContractScheduleTermConditionGridService,
        ContractTermAndConditionFilterService,
        SvcOrderContractTermAndConditionFilterService,
        ContractScheduleDetailTermAndConditionFilterService,
        InventoryFilterService,
        InventoryService,
        ContractDocumentGridService,
        ContractScheduleDetailDocumentGridService,
        ContractInventoryGridService,
        ContractRateGridService,
        ContractScheduleDetailRateGridService,
        RateService,
        ContractRateEditGridService,
        ContractShellVendorGridService,
        SvcOrderHeaderGridService,
        ContractInstanceService,
        ContractInstanceRateService,
        ContractScheduleTermConditionGridService,
        ContractScheduleCommitmentGridService,
        AuditRatesGridService
    ]
})
export class ContractModule {
}
