import { Injectable } from '@angular/core';
import { DisputeCharge } from '../../charge/core/dispute-charge';
import { InvoiceCharge } from '../../charge/core/invoice-charge';
import { BaseService } from '../../core/base.service';
import Query from '../../core/query/query';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';
import { Dispute } from './dispute';
import { DisputeStatusLookup } from './dispute-status.lookup';
import { DisputeWithheldStatusLookup } from './dispute-witheld-status.lookup';
import { LookupModel } from '../../dictionary/core/lookup.model';
import { LOOKUP_ENUM } from '../../dictionary/core/lookup.enum';
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { DialogService } from 'app/shared/dialog/dialog.service';
@Injectable()
export class DisputeService extends BaseService<Dispute> {
	readonly DISPUTE_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_STATUS_ID;

	readonly DISPUTE_STATUS_LOOKUP_MODEL: string =
		LOOKUP_MODELS_ENUM.DISPUTE_STATUS.modelName;

	readonly DISPUTE_WITHHELD_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS;

	readonly DISPUTE_WITHHELD_STATUS_LOOKUP_MODEL: string =
		LOOKUP_MODELS_ENUM.DISPUTE_WITHHELD_STATUS.modelName;

	disputeStatusLookup: DisputeStatusLookup = new DisputeStatusLookup();
	disputeWithheldStatusLookup: DisputeWithheldStatusLookup =
		new DisputeWithheldStatusLookup();

	constructor(
		private entityLock: EntityLockService,
		private dialogService: DialogService
	) {
		super('dispute', entityLock);
	}

	findOnlyNecessaryById(id: number): any {
		return this.httpService().get(['dispute/findOnlyNecessaryById', id]);
	}

	findDisputeCharges(disputeId: number, query?: any) {
		return this.httpService().get(
			[this.name, disputeId, 'charges'],
			this.toFilter(query)
		);
	}
	findDisputeChargesCheck(disputeId: number, charges?: any) {
		return this.httpService().get(
			[this.name, disputeId, 'charges_check'],
			charges
		);
	}

	findAllDisputeChargesLargeRequest(disputeId: number, query?: any) {
		return this.httpService().post(
			[this.name, disputeId, 'find'],
			this.toFilter(query)
		);
	}

	findDisputes(query?: any) {
		let transformedQuery = query.transform();
		return this.httpService().get(
			[this.name, 'all'],
			this.toFilter(transformedQuery)
		);
	}

	findChargesDisputes(query?: any) {
		let transformedQuery = query.transform();
		return this.httpService().get(
			[this.name, 'allchargesdispute'],
			this.toFilter(transformedQuery)
		);
	}

	findDisputesWithCharges(query?: any) {
		let transformedQuery = query.transform();
		return this.httpService().get(
			[this.name, 'allwithcharges'],
			this.toFilter(transformedQuery)
		);
	}

	deleteSubclient(disputeId, SubclientId) {
		return this.httpService().delete(
			[this.name, disputeId, 'subclient'],
			SubclientId
		);
	}

	filters(query: Query): any {
		let transformedQuery = query.transform();
		return this.httpService().get(
			[this.name, 'filters'],
			this.toFilter(transformedQuery)
		);
	}

	setDisputeStatus(statusId: number, disputeId: number) {
		return this.httpService().get([this.name, disputeId, 'status', statusId]);
	}

	validateSelectionForDispute(charges: Array<InvoiceCharge>): boolean {
		charges = charges || [];
		let isValid: boolean = true;

		if (charges && charges.length) {
			charges.forEach((charge) => {
				if (
					charges[0].vendor_id !== charge.vendor_id ||
					charges[0].currency_id !== charge.currency_id ||
					charge['charge_dispute']
				) {
					isValid = false;
				}
			});
		} else {
			isValid = false;
		}
		return isValid;
	}

	//** Calculates totals and status on dispute charge level */
	calculateDisputeChargeTotals(
		disputeCharge: DisputeCharge,
		disputeStatusLookup: DisputeStatusLookup
	) {
		let disputeValueAwarded: number =
			disputeCharge.dispute_value_awarded || null;

		/* Disputed amount: ‘Charge amount(chg_amt)’ –(minus) ‘Calculated Charges(calculated_amount)’ */
		let disputedAmount: number =
			disputeCharge.charge.chg_amt - disputeCharge.calculated_amount;

		/*Payback Amount = ‘Disputed amount’ –(minus) ‘Dispute amount awarded’,
     but only if: ‘Dispute amount awarded’ greater than 0,
     and ‘Dispute amount awarded’ less than ‘Disputed Amount’*/
		let paybackAmount: number =
			disputeValueAwarded > 0 ? disputeValueAwarded - disputedAmount : 0;

		/* Dispute charge Status:
     •	Filed (no resolution date)
     •	Closed – Lost (has resolution date but Dispute Amount Awarded is 0 or not provided)
     •	Closed – Won (has resolution date, Dispute amount awarded is greater than 0 and equal to Disputed amount)*/

		let status: number, statusObj;
		if (!disputeCharge.resolution_date || !disputeValueAwarded) {
			status = disputeStatusLookup.FILED.id;
			statusObj = disputeStatusLookup.FILED;
		} else {
			if (disputeValueAwarded == 0 && disputeCharge.resolution_date) {
				status = disputeStatusLookup.CLOSED_LOST.id;
				statusObj = disputeStatusLookup.CLOSED_LOST;
			} else if (disputeValueAwarded !== 0 && disputeCharge.resolution_date) {
				status = disputeStatusLookup.CLOSED_WON.id;
				statusObj = disputeStatusLookup.CLOSED_WON;
			}
		}
		return {
			dispute_value_awarded: disputeValueAwarded,
			disputed_amount: disputedAmount,
			payback_amount: paybackAmount,
			status: status,
			status_obj: statusObj
		};
	}

	//** Calculates totals and statuses on dispute level */
	calculateDisputeTotals(
		disputeCharges: Array<DisputeCharge>,
		disputeStatusLookup: DisputeStatusLookup,
		disputeWithheldStatusLookup: DisputeWithheldStatusLookup
	) {
		let hasResolutionDate: boolean = true;
		let withheldCount: number = 0;
		let totalAmount: number = 0;
		let calculatedAmount: number = 0;
		let disputeValueAwarded: number = null;
		let paybackAmount: number = 0;
		let hasFiledCharges: boolean = false;

		let awardedDisputeValues = disputeCharges.map(
			(charge) => charge.dispute_value_awarded
		);

		let disputAmountsAwardedAllNull = awardedDisputeValues.some(
			(item) => item !== null
		);

		//Check for all disput awarded values are null in amountDsiputAwarded
		let allAmountsDsiputAwardedNull = disputeCharges.every(
			(item) => item.status === 10
		);

		//Check if disputs are closed Lost and Won and at least one disput value award is null
		let allDisputAmountsClosed = disputeCharges.every(
			(item) => item.status === 20 || item.status === 40
		);
		let atLestOneDisput = disputeCharges.every((item) => item.status === 10);

		//Check for if all disputs awarded values are Closed-Lost state
		let allDisputsClosedLost = disputeCharges.every(
			(item) => item.status === 20
		);

		//Check if is at least on disput awarded values is in Close - Won state
		let oneDisputClosedWon = disputeCharges.some((item) => item.status === 40);

		disputeCharges.forEach((disputeCharge) => {
			hasResolutionDate =
				hasResolutionDate && disputeCharge.resolution_date !== null;

			if (disputeCharge.dispute_withheld) {
				withheldCount++;
			}

			totalAmount += +disputeCharge.charge.chg_amt;
			calculatedAmount += +disputeCharge.calculated_amount || 0;
			if (disputAmountsAwardedAllNull) {
				disputeValueAwarded += +disputeCharge.dispute_value_awarded;
			} else {
				disputeValueAwarded = null;
			}
			paybackAmount += +disputeCharge.payback_amount || 0;

			if (disputeCharge.status == disputeStatusLookup.FILED.id) {
				hasFiledCharges = true;
			}
		});

		/* Resolution date: populated only if all charges have resolution date and highest date (from charges) is used */
		let resolutionDate = null;
		if (hasResolutionDate) {
			let resDate = disputeCharges
				.map(function (o) {
					return new Date(o.resolution_date).getTime();
				})
				.sort(function (a, b) {
					return b - a;
				})[0];

			if (resDate !== 0) {
				resolutionDate = new Date(resDate);
			}
		}

		/* Withheld status:
     •	Not withheld: no withheld charges:
     •	Partially withheld: has withheld charges but not all charges are withheld
     •	Fully withheld: all charges are withheld */
		let withheldStatus: number, withheldStatusObj;
		if (withheldCount === 0) {
			withheldStatus = disputeWithheldStatusLookup.NOT_WITHHELD.id;
			withheldStatusObj = disputeWithheldStatusLookup.NOT_WITHHELD;
		} else if (withheldCount < disputeCharges.length) {
			withheldStatus = disputeWithheldStatusLookup.PARTIALLY_WITHHELD.id;
			withheldStatusObj = disputeWithheldStatusLookup.PARTIALLY_WITHHELD;
		} else {
			withheldStatus = disputeWithheldStatusLookup.FULL_WITHHELD.id;
			withheldStatusObj = disputeWithheldStatusLookup.FULL_WITHHELD;
		}

		/* Disputed amount: (‘Billed charges’ –(minus) ‘Calculated charges’) */
		let disputedAmount = totalAmount - calculatedAmount;

		/* Dispute status:
     •	Filed (All dispute lines are in status "Filed")
     •	In Progress : (At least one, but not all dispute lines are in a closed state.)
     •  Closed- Lost: (All dispute lines are in state “Closed-Lost”)
     •	Closed- Won: (All dispute lines are Closed . At least one dispute line is in status “Closed-Won”.) */
		let status, statusObj;
		if (allAmountsDsiputAwardedNull) {
			status = disputeStatusLookup.FILED.id;
			statusObj = disputeStatusLookup.FILED;
		} else if (!atLestOneDisput && !allDisputAmountsClosed) {
			status = disputeStatusLookup.IN_PROGRESS.id;
			statusObj = disputeStatusLookup.IN_PROGRESS;
		} else if (allDisputsClosedLost) {
			status = disputeStatusLookup.CLOSED_LOST.id;
			statusObj = disputeStatusLookup.CLOSED_LOST;
		} else if (allDisputAmountsClosed && oneDisputClosedWon) {
			status = disputeStatusLookup.CLOSED_WON.id;
			statusObj = disputeStatusLookup.CLOSED_WON;
		}

		return {
			resolution_date: resolutionDate,
			withheld_status: withheldStatus,
			withheld_status_obj: withheldStatusObj,
			total_amount: totalAmount,
			calculated_amount: calculatedAmount,
			disputed_amount: disputedAmount,
			dispute_value_awarded: disputeValueAwarded,
			payback_amount: paybackAmount,
			status_id: status,
			status_obj: statusObj
		};
	}

	/** populate disputeStatusLookup */
	populateDisputeStatusLookup(disputeStatuses: Array<LookupModel>): void {
		disputeStatuses.forEach((item) => {
			if (item.id === this.DISPUTE_STATUS_ENUM.NEW) {
				this.disputeStatusLookup.NEW = item;
			}
			if (item.id === this.DISPUTE_STATUS_ENUM.FILED) {
				this.disputeStatusLookup.FILED = item;
			}
			if (item.id === this.DISPUTE_STATUS_ENUM.CLOSED_WON) {
				this.disputeStatusLookup.CLOSED_WON = item;
			}
			if (item.id === this.DISPUTE_STATUS_ENUM.IN_PROGRESS) {
				this.disputeStatusLookup.IN_PROGRESS = item;
			}
			if (item.id === this.DISPUTE_STATUS_ENUM.CLOSED_LOST) {
				this.disputeStatusLookup.CLOSED_LOST = item;
			}
			if (item.id === this.DISPUTE_STATUS_ENUM.CANCELLED) {
				this.disputeStatusLookup.CANCELLED = item;
			}
		});
	}

	/** populate disputeWithheldStatusLookup */
	populateDisputeWithheldStatusLookup(
		disputeWithheldStatuses: Array<LookupModel>
	): void {
		disputeWithheldStatuses.forEach((item) => {
			if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.NOT_WITHHELD) {
				this.disputeWithheldStatusLookup.NOT_WITHHELD = item;
			}
			if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.PARTIALLY_WITHHELD) {
				this.disputeWithheldStatusLookup.PARTIALLY_WITHHELD = item;
			}
			if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.FULL_WITHHELD) {
				this.disputeWithheldStatusLookup.FULL_WITHHELD = item;
			}
		});
	}

	/// not in use
	destroyDispute(id, note) {
		return this.httpService().delete([this.name], {
			id,
			note
		});
	}

	deleteDispute(id, note) {
		return this.httpService().delete([this.name, id], { note });
	}

	deleteCharges(disputeId: number, payload: Object) {
		return this.httpService().post([this.name, disputeId, 'charges'], {
			payload
		});
	}
}
