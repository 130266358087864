import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Inventory} from './inventory';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import Auth from '../../shared/user-session/auth';
import {ConfigService} from '../../core/config/config.service';
import {Observable} from 'rxjs';
import Query from '../../core/query/query';
import {EntityLockService} from '../../shared/entity-lock/entity-lock.service';
import {map} from "rxjs";

@Injectable()
export class InventoryService extends BaseService<Inventory> {
  public inventoryGLStringPath = 'inventory_gl_string';

  constructor(public configService: ConfigService,
              public http: HttpClient,
              private entityLock: EntityLockService) {
    super('inventory', entityLock);
  }

  checkAlias(query) {
    return this.httpService().get([this.name, 'alias/check'], this.toFilter(query))
  }

  findAllAssignable(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'assignable'], this.toFilter(transformedQuery));
  }

  fetchCustomerInventoryForNetworkInventory(inventoryId: number) {
    return this.httpService().get([this.name, inventoryId, 'customer-inventory']);
  }

  fetchNetworkCircuits(inventoryId: number) {
    return this.httpService().get([this.name, inventoryId, 'circuits']);
  }

  fetchNetworkCircuitsCsv(inventoryId: number, query?: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, inventoryId, 'circuitsCsv'], this.toFilter(transformedQuery))
  }

  addNetworkCircuits(inventoryId: number, networkCircuits: Array<any>) {
    return this.httpService().post([this.name, inventoryId, 'circuits'], networkCircuits);
  }

  /* Billing alias section */
  fetchInventoryAliases(inventoryId: number) {
    return this.httpService().get([this.name, inventoryId, 'alias']);
  }

  addInventoryAliases(inventoryId: number, linkedInventory: Array<any>) {
    return this.httpService().post([this.name, inventoryId, 'alias'], linkedInventory);
  }

  /* EO billing alias section */

  addSite(inventory_id: number, site_id: number, contact_id: number): any {
    let conId = contact_id ? contact_id : -1;
    return this.httpService().put([this.name, inventory_id, 'site', site_id, 'contact', conId])
  }

  deleteSite(inventory_id: number, site_id: number): any {
    return this.httpService().delete([this.name, inventory_id, 'site', site_id]);
  }

  addPricing(inventory_id: number, data) {
    return this.httpService().post([this.name, inventory_id, 'document'], data)
  }

  getPricing(inventory_id: number) {
    return this.httpService().get([this.name, inventory_id, 'documents']);
  }

  getPricings(query: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'pricings'], this.toFilter(transformedQuery));
  }

  deletePricing(inventory_id: number, document_id: number) {
    return this.httpService().delete([this.name, inventory_id, 'document', document_id])
  }



  downloadPricing(inventory_id: number, document_id: number) {
    let cookie = Auth.getSession();
    let params: URLSearchParams = new URLSearchParams();
    params.set('authorization', cookie.token);

    let endPoint = `${environment.API_URL + this.name}/${inventory_id}/document/${document_id}/download`;

    // @ts-ignore
    return this.http.get(endPoint, {responseType: 'blob', headers: {authorization: `Bearer ${cookie.token}`}});
  }

  initiateDownload(name, response: Blob) {
    let objectUrl = URL.createObjectURL(response);
    let save = document.createElement('a');
    save.href = objectUrl;
    save.target = '_blank';
    save.download = name;
    let event = document.createEvent('MouseEvents');
    event.initMouseEvent(
      "click", true, false, window, 0, 0, 0, 0, 0
      , false, false, false, false, 0, null
    );
    save.dispatchEvent(event);
  }

  isFileNameTaken(entityId: number, query: any): Observable<any> {
    return this.httpService().get([this.name, entityId, 'documents', 'file-name'], this.toFilter(query));
  }


  getGLStrings(query: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.inventoryGLStringPath], this.toFilter(transformedQuery));
  }

  getOrders(query: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'order-services'], this.toFilter(transformedQuery));
  }

  saveGLStrings(inventory_id: number, glStrings: Array<any>) {
    let saveData = glStrings.filter((o) => {
      o.inv_id = inventory_id;
      return o;
    });
    let data = {inv: inventory_id, values: saveData};
    return this.httpService().post([this.inventoryGLStringPath, 'save'], data);
  }

  canBeEdited(inventoryId: number) {
    return this.httpService().get([this.name, inventoryId, 'canedit']);
  }

  unlinkFromNetworkHubCircuisMap(inventoryId: number) {
    return this.httpService().delete([this.name, inventoryId, 'unlinknetworkhubcircuit']);
  }

  findOnlyInventory(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'filtered'], this.toFilter(transformedQuery));
  }

  findOnlySvcInventory(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'svc-filtered'], this.toFilter(transformedQuery));
  }

  addRecordAfterOSDelete(inventoryId: number, inventory, service_id) {
    let data = {inv: inventory, id: inventoryId, service_id: service_id}
    return this.httpService().post([this.name, inventoryId, 'order-servicedeleted'], data);
  }

  findAllContractTerms(id, query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, id, 'contract-term'], this.toFilter(transformedQuery));
  }

  createContractTerm(inventoryId, item) {
    return this.httpService().post([this.name, inventoryId, 'contract-term'], item);
  }

  updateContractTerm(inventoryId, id, item) {
    return this.httpService().put([this.name, inventoryId, 'contract-term'], item);
  }

  deleteContractTerm(inventory_id: number, id: number) {
    return this.httpService().delete([this.name, inventory_id, 'contract-term']);
  }

  updateContract(selectedInventoryIds,contractId) {
    let item = {
      contract_id: contractId,
      inventory_ids: selectedInventoryIds
    };

    return this.httpService().put([this.name, 'contract'], item);
  }

  findAllForContract(query?: Query, contractId?, inventoryIds?): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, contractId, 'contract'], this.toFilter(transformedQuery), inventoryIds)
  }

  // TODO: check if needed and remove / using old 'inventory_contracts_map' table
  findAllContracts(query?: Query, contractId?): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, contractId, 'inventory-contract'], this.toFilter(transformedQuery))
  }

  findAllInventoriesForContract(contractId?): any {
    return this.httpService().get([this.name, contractId, 'contractinventories']);
  }

  unlinkInventory(query?: Query, contractId?): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().delete([this.name, contractId, 'unlink-inventory'], this.toFilter(transformedQuery))
  }

  findContractById(id): any {
    return this.httpService().get([this.name, id, 'getcontractbyid']);
  }

  findContractInventories(query?: Query, contractId?): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, contractId, 'contract-inventories'], this.toFilter(transformedQuery))
  }

  findContractTypeById(id: number): any {
    return this.httpService().get([this.name, id, 'contracttype']);
  }

  findAllSvcOrderServices(query: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'inventory-svc-order-service'], this.toFilter(transformedQuery));
  }

  findOnlyInventoryRelationshipType(query: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'relationship-type'], this.toFilter(transformedQuery));
  }

  findAllScheduleRates(query: Query):any{
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get(['contract', 'rates'], this.toFilter(transformedQuery));
  }

  findInvoiceChargeProfileById(id: number) {
    return this.httpService().get([this.name, id, 'charge-profile']);
  }

  findAllInvoiceChargeProfiles(query?: Query):any {
    const concreteQuery = (query || new Query());
    // const transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name,'charge-profile'], this.toFilter(concreteQuery));
  }

  addInvoiceChargeProfiles(chargeIds: number[], auditRuleId: number, minRuleValue: number, maxRuleValue: number, replaceAll: number) {
    return this.httpService().post([this.name, 'charge-profile'], {
      chargeIds, auditRuleId, minRuleValue, maxRuleValue, replaceAll
    })
  }

  updateChargeProfile(chargeProfiles: any): any {
    return this.httpService().put([this.name, 'charge-profile'], chargeProfiles);
  }

  updateInvoiceChargeProfileStatus(query: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().patch([this.name, 'charge-profile-status'], this.toFilter(transformedQuery));
  }
}
