import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {Observable} from "rxjs";
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";
import {map} from "rxjs";
export class RateQtyUomLookupProvider extends IFilterLookupProvider{

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  readonly lookupModel: string = LOOKUP_MODELS_ENUM.CONTRACT_RATE_QTY_UOM.modelName;
  public entries : any;

  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel).pipe(
      map((result: any) => {
        return {
          ...result,
          items: result.items.map(entry => entry.value)
        }
      }));
  }

}
