import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges
} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {
  PICKER_COMPONENT_TEMPLATE,
  PickerComponentBase
} from "../../picker/ca-picker-base.component";
import {Observable} from "rxjs";
import {DictionaryService} from "../../../dictionary/core/dictionary.service";
import {LOOKUP_MODELS_ENUM} from "../../../dictionary/core/lookup-models.enum";
import Query from "../../../core/query/query";



@Component({
  selector: 'ca-bandwidth-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BandwidthPickerComponent),
      multi: true
    },
    DictionaryService
  ]
})
export class BandwidthPickerComponent extends PickerComponentBase implements OnInit, OnChanges {


  readonly TEXT_FIELD = 'value';
  readonly KEY_FIELD = 'id';

  @Input('formControlName') formControlName;
  @Input("initialItem") initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('queryLimit') queryLimit = 20;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('query') query = new Query({
    where: {
      is_active: true,
    },
  });
  public control;

  italicStyleOptionPredicate = (option) => !option.is_active;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }
  ngOnInit() {
    this.query.orderBy = [['order_number', 'ASC']];
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];

    if (changes['queryLimit']) {
      this.query.limit = this.queryLimit;
      super.ngOnInit();
    }
  }

  loadEntries(): Observable<any> {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.BANDWIDTH.modelName, this.query, true);
  }

  search(value: string): Observable<any> {
    this.query.set('value', {'$ilike': `${value}%`});
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.BANDWIDTH.modelName, this.query, true);
  }

}
