import { SiteModule } from '../site/site.module';
import { ContactModule } from '../contact/contact.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderRoutingModule } from './order-routing.module';
import { OrderComponent } from './order.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../shared/shared.module';
import { DictionaryModule } from "../dictionary/dictionary.module";
import { VendorModule } from "../vendor/vendor.module";
import { ProjectModule } from "../project/project.module";
import { CoreModule } from "../core/core.module";
import { DxDataGridModule} from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { OrderService } from './core/order.service';
import { OrderGridService } from './core/order-grid.service';
import { OrderFilterService } from './core/order-filter.service';
import { OrderFlowService } from './core/order-flow.service';
import { OrderServiceManageDialogComponent } from '../order-service/shared/order-service-manage-dialog/order-service-manage-dialog.component';
import { OrderManageDialogComponent } from './shared/order-manage-dialog/order-manage-dialog.component';
import { OrderServiceModule } from '../order-service/order-service.module';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderSneakPeekComponent } from './shared/order-sneak-peek/order-sneak-peek.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderApprovalManageComponent } from './flow/order-approval-manage/order-approval-manage.component';
import { OrderVendorAckDateComponent } from './flow/order-vendor-ack-date/order-vendor-ack-date.component';
import { OrderSentToVendor} from "./flow/order-sent-to-vendor/order-sent-to-vendor.component";
import { OrderVendorApprovalManageComponent } from './flow/order-vendor-approval-manage/order-vendor-approval-manage.component';
import { InventoryService } from '../inventory/core/inventory.service';
import { InventoryModule } from '../inventory/inventory.module';
import {CustomerModule} from "../customer/customer.module";
import {ReportService} from "../report/core/report.service";
import { DisconnectOrderManageDialogComponent } from './shared/disconnect-order-manage-dialog/disconnect-order-manage-dialog.component';
import {DisconnectOrderGridService} from "./core/disconnect-order.grid.service";
import {DemarcModule} from "../demarc/demarc.module";
import { SortPipe } from './core/sort-pipe.pipe';
import { OrderServiceLevelGridService } from './core/order-service-level/order-service-level.grid.service';
import { OrderServiceLevelFilterService } from './core/order-service-level/order-service-filter.service';
import { OrderServiceLevelSneakPeekComponent } from './shared/order-service-level-sneak-peek/order-service-level-sneak-peek.component';
import { OrderResetToNewManageComponent } from './shared/order-reset-to-new-manage/order-reset-to-new-manage.component';

import {ContactFormService} from "./core/contact-form.service";
import {LocationModule} from "../location/location.module";
import { OrderServiceGridService } from './core/order-service.grid.service';

@NgModule({
    imports: [
        CommonModule,
        OrderRoutingModule,
        OrderServiceModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        DxDataGridModule,
        DxDateBoxModule,
        DictionaryModule,
        VendorModule,
        ContactModule,
        ProjectModule,
        CoreModule,
        InventoryModule,
        SiteModule,
        CustomerModule,
        DemarcModule,
        LocationModule
    ],
    declarations: [
        OrderComponent,
        OrderManageDialogComponent,
        OrderListComponent,
        OrderSneakPeekComponent,
        OrderServiceLevelSneakPeekComponent,
        OrderDetailsComponent,
        OrderApprovalManageComponent,
        OrderVendorAckDateComponent,
        OrderSentToVendor,
        OrderVendorApprovalManageComponent,
        DisconnectOrderManageDialogComponent,
        SortPipe,
        SortPipe,
        OrderResetToNewManageComponent,
    ],
    providers: [
        OrderService,
        OrderGridService,
        OrderServiceLevelGridService,
        OrderServiceGridService,
        OrderFilterService,
        OrderServiceLevelFilterService,
        OrderFlowService,
        InventoryService,
        ReportService,
        DisconnectOrderGridService,
        ContactFormService,
    ]
})
export class OrderModule { }
