import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import Query from 'app/core/query/query';
import {SvcOrderHeader} from "./svc-order-header";
import {SvcOrderServices} from "./svc-order-service";

@Injectable()
export class SvcOrderService extends BaseService<SvcOrderHeader | SvcOrderServices> {
  section: string = 'service';

  constructor(private entityLock: EntityLockService) {
    super('svc-order', entityLock);
  }

  findAllByVendor(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'findAllByVendorID'], transformedQuery);
  }


  findAllServices(id, query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return id ?
      this.httpService().get([this.name, id, 'services'], transformedQuery) :
      this.httpService().get([this.name, 'services'], transformedQuery);
  }

  findServiceById(id): any {
    return this.httpService().get([this.name, 'service', id])
  }

  public createService(item) {
    return this.httpService().post([this.name, this.section], item);
  }

  public updateService(id, item) {
    return this.httpService().put([this.name, this.section, id], item);
  }

  public deleteService(headerId, serviceId) {
    return this.httpService().delete([this.name, this.section, serviceId]);
  }
}

