
import {debounceTime} from 'rxjs';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { FilterEntry } from '../filter-entry';
import Query from '../../query/query';
import { VendorService } from '../../../vendor/core/vendor.service';
import {AccountService} from '../../../account/core/account.service';

@Component({
  selector: 'ca-vendor-account-filter',
  templateUrl: './vendor-account-filter.component.html',
  providers: [
    VendorService,
    AccountService
  ],
})

export class VendorAccountFilterComponent implements OnInit, OnChanges {

  @Input('entry') entry: FilterEntry;
  @Input('query') query: Query;
  @Output() vendorFilterChanged = new EventEmitter();

  vendorFilterForm: FormGroup;
  internalQuery: any;

  readonly FIELDS = {
    VENDOR: 'vendor',
    ACCOUNT: 'account'
  };

  vendorList = [];
  vendorQuery: Query = new Query({ orderBy: [['name', 'ASC']] });
  accountList = [];
  accountQuery = new Query({ orderBy: [['account_no', 'ASC']]});
  selectedVendorId = null;

  constructor(
    public formBuilder: FormBuilder,
    public vendorService: VendorService,
    public accountService: AccountService
  ) {
  }

  ngOnInit() {
    this.vendorFilterForm.controls['vendor'].valueChanges.pipe(
      debounceTime(250))
      .subscribe((value) => {
        this.internalQuery[this.FIELDS.VENDOR] = value;
        this.setQuery();
        this.suggestVendors(value);
      });

    this.vendorFilterForm.controls['account'].valueChanges.pipe(
      debounceTime(250))
      .subscribe((value) => {
        this.internalQuery[this.FIELDS.ACCOUNT] = value;
        this.setQuery();
        this.suggestAccounts(value);
      });
  }

  findSelectedVendor() {
    let query = new Query();
    this.selectedVendorId = null;
    if (this.vendorQuery && this.vendorQuery.where && this.vendorQuery.where.name) {
      query.where.name = this.vendorQuery.where.name;
      this.vendorService.findVendorsWithBans(query)
        .subscribe((res) => {
          this.selectedVendorId = res.items[0].id ? res.items[0].id : null;
        })
    }
  }

  suggestVendors(q?) {
    if (q && q.length) {
      this.vendorQuery['where']['name'] = {'$ilike': `${q}%`};
    } else {
      this.vendorQuery.orderBy = [['name', 'ASC']];
    }
    this.vendorService.findVendorsWithBans(this.vendorQuery)
      .subscribe((response) => {
        this.vendorQuery.total = response.total;
        this.vendorList = response.items.map((entry) => {
          return {
            value: entry.id,
            text: entry.name
          }
        });
      });
  }

  suggestAccounts(q?) {
    let vendorId = this.selectedVendorId;
    if (vendorId) {
      this.accountQuery['vendor_ids'] = [vendorId];
    }
    if (q && q.length) {
      this.accountQuery['where']['account_no'] = {'$ilike': `${q}%`};
    } else {
      this.accountQuery.orderBy = [['account_no', 'ASC']];
    }

    this.accountService.findAccountsWithInvoices(this.accountQuery)
      .subscribe((response) => {
        this.accountQuery.total = response.total;

        this.accountList = response.items.map((entry) => {
          return {
            value: entry.account_no,
            text: entry.account_no
          }
        });
      });
  }

  public clearQueryOnFocusOut() {
    if (!this.vendorFilterForm.get('vendor').value) {
      this.vendorQuery = new Query();
      this.vendorFilterForm.get('account').setValue('');
      if (!this.vendorFilterForm.get('account').value) {
        this.accountQuery = new Query();
      }
    }
  }

  public clearAcctQueryOnFocusOut() {
    if (!this.vendorFilterForm.get('account').value || !this.vendorFilterForm.get('vendor').value) {
      this.accountQuery = new Query();
    }
  }

  public searchAllOnInitialFocus() {
    this.suggestVendors()
  }

  public searchAllAcctOnInitialFocus() {
    this.suggestAccounts()
  }

  setQuery() {
    let operator = this.entry.caseSensitive ? '$like' : '$ilike';
    let suffix = this.entry.exact ? '' : '%';
    let prefix = this.entry.exact ? '' : '%';

    for(let i in this.FIELDS) {
      let filterField = this.FIELDS[i];
      let queryField = this.entry.vendorFields[filterField]['field'];
      let value = this.internalQuery[filterField];

      if (value) {
        value = value.trim();
        this.query.where[queryField] = this.query.where[queryField] || {};
        delete this.query.where[queryField]['$ilike'];
        delete this.query.where[queryField]['$like'];
        this.query.where[queryField][operator] = prefix + value + suffix;
      } else {
        delete this.query.where[queryField];
      }
    }

    this.vendorFilterChanged.emit(this.query);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['query']){

      if (!this.vendorFilterForm || !this.vendorFilterForm.controls) {
        this.vendorFilterForm = this.formBuilder.group({
          vendor: [''],
          account: ['']
        });
      }

      this.setInitialInternalQuery();
    }
  }

  setInitialInternalQuery() {
    this.internalQuery = {
      vendor: '',
      account: ''
    };

    this.vendorFilterForm.controls['vendor'].reset('');
    this.vendorFilterForm.controls['account'].reset('');
  }

  scrollHandlerVendor($event) {
    let element = $event.srcElement;
    if (Math.ceil(element.scrollTop) >= (element.scrollHeight - element.clientHeight)) {
      this.onScrollEndVendor(element);
    }
  }

  scrollHandlerAccount($event) {
    let element = $event.srcElement;
    if (Math.ceil(element.scrollTop) >= (element.scrollHeight - element.clientHeight)) {
      this.onScrollEndAccount(element);
    }
  }

  onScrollEndVendor($event) {
    if (this.vendorQuery.canNext()) {
      this.vendorQuery.page++;
      this.vendorQuery.offset = this.vendorQuery.from() - 1;

      this.vendorService.findVendorsWithBans(this.vendorQuery)
        .subscribe((result) => {
          this.vendorQuery.total = result.total;
          this.vendorList = this.vendorList.concat(result.items.map((entry) => {
            return {
              value: entry.id,
              text: entry.name
            }
          }));
        });
    }
  }

  onScrollEndAccount($event) {
    if (this.accountQuery.canNext()) {
      this.accountQuery.page++;
      this.accountQuery.offset = this.accountQuery.from() - 1;

      this.accountService.findAccountsWithInvoices(this.accountQuery)
        .subscribe((result) => {
          this.accountQuery.total = result.total;
          this.accountList = this.accountList.concat(result.items.map((entry) => {
            return {
              value: entry.account_no,
              text: entry.account_no
            }
          }));
        });
    }
  }
}
