import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Query from '../../../core/query/query';
import { DictionaryService } from '../../../dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { LookupModel } from '../../../dictionary/core/lookup.model';
import { FilterEntry } from '../filter-entry';


@Component({
  selector: 'ca-network-hub-l1-l2-filter',
  templateUrl: 'network-hub-l1-l2-filter.component.html'
})
export class NetworkHubLevel1Level2Component implements OnInit {
  @Input() entry: FilterEntry;
  @Input() query: Query;

  form: FormGroup;

  selectedCapacity: LookupModel;
  selectedLevel1: any;
  selectedLevel1Order: any;

  selectedLevel2: any;
  selectedLevel2Order: any;
  networkHubID: number | string;

  level1FullEntryList: any[];

  networkHubTypes: Array<LookupModel>;

  readonly NETWORK_HUB_CAPACITY_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.NETWORK_HUB_CAPACITY.modelName;

  readonly NETWORK_HUB_CAPACITY = LOOKUP_ENUM.NETWORK_HUB_CAPACITY;

  constructor(
    private dictionaryService: DictionaryService,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    const formControls = {
      level_1: new FormControl(this.selectedLevel1),
      level_2: new FormControl(this.selectedLevel2)
    };

    this.form = this.formBuilder.group(formControls);

    this.loadNetworkHubCapacity();
    this.networkHubID = this.route.snapshot.params['id'];
  }

  /**
   * Loads network hub capacity(DS3, OC12, 600mbps..)
   *
   * @private
   * @memberof NetworkHubLevel1Level2Component
   */
  private async loadNetworkHubCapacity() {
    const { items } = await this.dictionaryService
      .getByLookup(this.NETWORK_HUB_CAPACITY_LOOKUP_MODEL)
      .toPromise();
    this.networkHubTypes = items;
  }

  /**
   * Hub Capacity has changed
   *
   * @param {LookupModel} event
   * @memberof NetworkHubLevel1Level2Component
   */
  public handleHubCapacityChange(event: LookupModel) {
    this.selectedCapacity = event;
    this.query.where['name'] = this.selectedCapacity.value;
    this.form.reset();
  }

  /**
   * Level 1 has changed
   *
   * @param {*} ev
   * @memberof NetworkHubLevel1Level2Component
   */
  public onLevel1Change(ev: any) {
    this.selectedLevel1 = ev;

    const { order } = this.level1FullEntryList.find(
      item => item.id == ev.value
    );

    if (this.selectedLevel2Order) {
      this.query.where['order'] = { $in: [order, this.selectedLevel2Order] };
    } else {
      this.query.where['order'] = order;
    }

    this.selectedLevel1Order = order;
  }

  /**
   * Level 2 has changed
   *
   * @param {*} ev
   * @memberof NetworkHubLevel1Level2Component
   */
  public onLevel2Change(ev: any) {
    this.selectedLevel2 = ev;

    const { order } = this.level1FullEntryList.find(
      item => item.id == ev.value
    );

    if (this.selectedLevel1Order) {
      this.query.where['order'] = { $in: [order, this.selectedLevel1Order] };
    } else {
      this.query.where['order'] = order;
    }

    this.selectedLevel2Order = order;
  }

  public level1FullEntryListChanged(event) {
    this.level1FullEntryList = event;
  }

  public clearFilter() {
    delete this.query.where['order'];
    delete this.query.where['name'];
    this.selectedLevel1Order = null;
    this.selectedLevel2Order = null;
    this.selectedLevel1 = null;
    this.selectedLevel2 = null;
    this.selectedCapacity = null;
    this.form.reset();
  }
}
