import { Injectable } from '@angular/core';
import { FilterService } from '../../core/filter/filter.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { alertDetailsFilterContext } from './alert-details-filter.context';
import { ChargeTypeLookupProvider } from 'app/charge/core/charge-type-lookup.provider';

@Injectable()
export class AlertDetailsFilterService extends FilterService {
	constructor(
		public userSettingsService: UserSettingsService,
		public dictionaryService: DictionaryService
	) {
		super(
			'app.cost.alert-details-filter',
			alertDetailsFilterContext,
			userSettingsService
		);

		this.addLookupProvider(
			'chargeType',
			new ChargeTypeLookupProvider(dictionaryService)
		);
	}
}
