import {
  Component, forwardRef, Input, OnInit,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable } from "rxjs";
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from "../../../shared/picker/ca-picker-base.component";
import { ProjectService } from "../../core/project.service";
import { ProjectQuery } from "../../core/project.query";
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';

@Component({
  selector: 'ca-project-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => CaProjectPickerComponent),
          multi: true
      },
      ProjectService
  ]
})
export class CaProjectPickerComponent extends PickerComponentBase implements OnInit {

  readonly TEXT_FIELD = 'name';

  query: ProjectQuery = new ProjectQuery({
      orderBy: [['name', 'ASC']],
      limit: 20
  });

  @Input('formControlName') formControlName;
  @Input('initialItem') initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('clearEnabled') clearEnabled: boolean;

  public control;
  readonly PROJECT_STATUS_ENUM: any = LOOKUP_ENUM.PROJECT_STATUS;

  constructor( public projectService: ProjectService) { super(); }


  loadEntries(): Observable<any> {
    this.control = this.pickerForm.controls[this.formControlName];
    this.query.where['status_id'] = this.PROJECT_STATUS_ENUM.ACTIVE;;
    return this.projectService.findAll(this.query);
  }

  search(value: string) {
    this.query.where['name'] = { '$ilike': `${value}%` };
    return this.projectService.findAll(this.query);
  }


}
