import { FormControl, Validators } from '@angular/forms'

export class SingleContactModel {
  id: number;
  first_name: string;
  last_name: string;
  country_id: string;
  email: string;
  contact_function: string;
  mobile_number: string;
  required: boolean;

  constructor(id?: number, first_name?: string, last_name?: string, country_id?: string, email?: string, contact_function?: string, mobile_number?: string, required?: boolean) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.country_id = country_id;
    this.email = email;
    this.contact_function = contact_function;
    this.mobile_number = mobile_number;
    this.required = required;
  }
}

export class SingleContactFormModel {
  id = new FormControl();
  first_name = new FormControl();
  last_name = new FormControl();
  country_id = new FormControl();
  email = new FormControl();
  contact_function = new FormControl();
  mobile_number = new FormControl();
  required: boolean;

  constructor(contact?: SingleContactModel, phone_regex?: string) {
    if (contact) {
      this.first_name.setValue(contact.first_name);
      this.last_name.setValue(contact.last_name);
      this.country_id.setValue(contact.country_id);
      this.email.setValue(contact.email);
      this.contact_function.setValue(contact.contact_function);
      this.mobile_number.setValue(contact.mobile_number);
      this.id.setValue(contact.id);
      this.required = contact.required;
    }

    this.first_name.setValidators([Validators.required]);
    this.last_name.setValidators([Validators.required]);
    this.email.setValidators([Validators.required, Validators.email]);
    this.contact_function.setValidators([Validators.required]);
    this.mobile_number.setValidators([Validators.pattern(phone_regex || '^[0-9 ()-]*$')]);
  }
}
