
import {of as observableOf, from as observableFrom, Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {FilterService} from '../../../core/filter/filter.service';
import {GLStringService} from '../../../gl-string/core/gl-string.service';
import {GLStringFilterContext} from './gl-string-filter.context';
import {UserSettingsService} from '../../../user/core/user-settings.service';
import {AppService} from '../../../app.service';
// @ts-ignore
import {pipe as ramdaPipe, map as ramdaMap, flatten} from 'ramda';
import {flatMap} from "rxjs";

@Injectable()
export class GLStringGridFilterService extends FilterService {

  constructor(public glStringService: GLStringService,
              public userSettingsService: UserSettingsService) {
    super('app.gl.gl-string-grid-filter', GLStringFilterContext, userSettingsService);
  }

  public loadContext(): any {

    return this.userSettingsService.entrySettingsNew('app.gl.gl-string-grid-filter')
      .pipe(flatMap(async context => {
      if (!(context && context.filters && context.filters.length > 2)) {
        let segments: any = await this.glStringService.findGLSegments();
            let index = GLStringFilterContext.filters.length;
            let cols = ramdaPipe(
              ramdaMap((o: any) => {
                const obj = {
                  field: [o.system_key, '_obj.segment_value'].join(''),
                  label: o.value,
                  type: 'string',
                  index: index++
                };

                const objDesc = {
                  field: [o.system_key, '_obj.segment_desc'].join(''),
                  label: [o.value, ' Description'].join(''),
                  type: 'string',
                  index: index++
                };

                return [obj, objDesc];
              }), flatten
            )(segments);
            return {
              ...GLStringFilterContext,
              filterSet: context && context.filterSet ? context.filterSet : [],
              filters: GLStringFilterContext.filters.concat(<Array<any>>cols),
            };
      }
      return context
    }));
  }

}
