import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {EntityLockService} from '../../shared/entity-lock/entity-lock.service';
import CustomerInventory from './customer-inventory';
import Query from "../../core/query/query";


@Injectable()
export class CustomerInventoryService extends BaseService<CustomerInventory> {
  constructor(private entityLock: EntityLockService
  ) {
    super('customer-inventory', entityLock);
  }

  fetchPricing(circuitId: number) {
    return this.httpService().get([this.name, circuitId, 'pricings']);
  }

  fetchSortPricing(circuitId: number, query?: any) {
    query.where = {...query.where, customer_circuit_id: circuitId};
    return this.httpService().get([this.name, circuitId, 'sort-pricings'], this.toFilter(query));
  }

  fetchSites(circuitId: number) {
    return this.httpService().get([this.name, circuitId, 'sites']);
  }

  fetchNetworkCircuits(circuitId: number, query?: any) {
    return this.httpService().get([this.name, circuitId, 'circuits'], this.toFilter(query));
  }

  fetchGridFilterNetworkCircuits(circuitId: number, query?: any) {
    query.where = {...query.where, customer_circuit_id: circuitId};
    return this.httpService().get([this.name, circuitId, 'filter-grid-circuits'], this.toFilter(query));
  }

  addPricing(circuitId: number, pricings: Array<any>) {
    return this.httpService().post([this.name, circuitId, 'pricings'], pricings);
  }

  addSites(circuitId: number, sites: Array<any>) {
    return this.httpService().post([this.name, circuitId, 'sites'], sites);
  }

  addNetworkCircuits(circuitId: number, networkCircuits: Array<any>) {

    return this.httpService().post([this.name, circuitId, 'circuits'], networkCircuits);
  }

  linkCustomerWithNetworkCircuit(circuitId: number, network_circuit_id: number) {
    return this.httpService().post([this.name, circuitId, 'link', network_circuit_id]);
  }

  findCustomerInventoryOnly(query?: Query): any {
    let concreteQuery = (query || new Query());
    return this.httpService().get([this.name, 'customer-inventory-only'], this.toFilter(concreteQuery));
  }
}
