import {of as observableOf} from 'rxjs';
import {takeUntil} from 'rxjs';

import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageListComponent} from '../../core/page-list.component';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {ChargeQuery} from '../../charge/core/charge.query';
import {CommonAlertService} from '../core/common-alert.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

import {AlertBatchInvoicesGridService} from '../core/alert-batch-invoices-grid.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {GridSettingsComponent} from '../../core/grid/grid-settings.component';
import {AlertService} from '../../shared/alert/alert.service';
import {DisputeService} from '../../dispute/core/dispute.service';
import {DisputeManageDialogComponent} from '../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component';
import {InvoiceFacepageService} from '../../invoice/core/invoice-facepage.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {TabGroup, TabService} from '../../shared/tabs/tab.service';
import {AlertBatchInvoicesFilterService} from '../core/alert-batch-invoices-filter.service';
import {GridService} from '../../shared/grid/grid.service';
import {flatMap} from "rxjs";

@Component({
  selector: 'app-alert-batch',
  templateUrl: './alert-batch.component.html',
  styleUrls: ['./alert-batch.component.css']
})

export class AlertBatchComponent extends PageListComponent implements OnInit, AfterViewInit, OnDestroy {
  alert;
  public summary: any;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab: number = 0;
  public query: ChargeQuery = new ChargeQuery({orderBy: [['id', 'ASC']]});
  public invoices: Array<any> = [];
  public columns: any;
  public sorting: any[][];
  public selection: any;
  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide;

  readonly sideSections = {
    none: null,
    info: 'details',
    filter: 'filter',
  };

  _isSidePanelOpen = false;
  activeTabIndex: number = 0;
  isGridSettingsDisabled = true;

  batchKey: number;
  public alertId: number;

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  }

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'common-alert';

  constructor(public settingsService: UserSettingsService,
              public route: ActivatedRoute,
              public alertService: CommonAlertService,
              public alertBatchInvoicesGridService: AlertBatchInvoicesGridService,
              public router: Router,
              public dialogService: DialogService,
              public toastAlertService: AlertService,
              public disputeService: DisputeService,
              public loaderService: LoaderService,
              public alertBatchInvoicesFilterService: AlertBatchInvoicesFilterService,
              public invoiceFacepageService: InvoiceFacepageService,
              public tabService: TabService) {
    super(new PageContext({
      name: 'app.alert.alert-batch',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    if (tab.key === 2 && this.sider.getActiveSection().name === this.sideSections.filter) {
      this._isSidePanelOpen = true;

      observableOf(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (tab.key !== 2 && this.sider.getActiveSection().name === this.sideSections.filter) {
      this._isSidePanelOpen = false;

      observableOf(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (tab.key !== 2 && this.sider.getActiveSection().name === this.sideSections.info) {
      this._isSidePanelOpen = false;

      observableOf(true)
        .subscribe(
          () => {
            this._isSidePanelOpen ? this.sider.open(this.SECTIONS.DETAILS_SECTION_NAME) : this.sider.closeComponent();

            this.tabGroup.forceRerender();
          }
        );
    }

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }

    /* Settings grid disabled */
    if (this.tabGroup.isActive(2) || this.tabGroup.isActive(3) || this.tabGroup.isActive(4)) {
      this.isGridSettingsDisabled = false;
    } else {
      this.isGridSettingsDisabled = true;
    }
  }

  public loadTabs() {

    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({key: 1, title: 'Summary'});
    this.tabGroup.addTab({key: 2, title: 'Details'});

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }


  loadData(alertId: number) {
    this.alertService.findById(this.route.params['_value'].id)
      .subscribe(
        (alert) => {
          this.alert = alert;
          this.loadInvoices(this.query);
        },
        () => {
          console.log('Error fetching alert info');
        }
      );
  }

  private getInvoices(alertId, query) {
    return this.alertService.findBatch(alertId, query)
  }

  // -----------------------------------------------------------
  loadInvoices(query: ChargeQuery) {
    this.invoices = [];
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.getInvoices(this.alertId, query)
      .subscribe((results) => {
          this.invoices = results.rows;
          this.query.total = results.count;
          if(this.invoices.length) {
              this.batchKey = this.invoices[0].batch?.batch_key;
          }

        setTimeout(() => {
          this.dataGrid.instance.repaint();
          this.dataGrid.instance.refresh();
        }, 600);

        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      }, () => {
        this.toastAlertService.error('', this.messages.get('ERROR_LOADING_INVOICES'));
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      });
  }

  refresh() {
    this.loadInvoices(this.query);
  }

  onPageChange(query: ChargeQuery) {
    this.loadInvoices(query);
  }

  filterData(query: ChargeQuery) {
    this.query = query;
    this.loadInvoices(query);
  }

  clearFilter(query: ChargeQuery) {
    this.query = query;
    this.loadInvoices(query);
  }

  ngOnInit() {
    this.loadTabs();
    this._init();
  }

  gridSettings() {
    this.dialogService.open(GridSettingsComponent, {
      service: this.alertBatchInvoicesGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.alertBatchInvoicesGridService;
  }

  getDefaultSort(): any {
    return [['id', 'ASC']];
  }

  _init() {
    super.prepareList();
    this.alert = this.route.data['alert'];
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
        this.alertId = params['id'];
        this.loadInvoices(this.query);
      });
  }

  ngAfterViewInit(): void {
    this.alertBatchInvoicesGridService.create(this.dataGrid.instance, {
      noDataText: this.alertBatchInvoicesGridService.noDataMessage
    });

    super.ngAfterViewInit();
  }

  sortColumn(sorting) {
    this.query['orderBy'] = sorting;

    this.loadInvoices(this.query);
  };

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    } else {
      this.router.navigate(['/alert']);
    }
  }

  csv() {
    const {alertBatchInvoicesGridService, alertService, query, alertId} = this;
    alertBatchInvoicesGridService.csvMap().subscribe(fields => {
      alertService.exportToCSV(
        `alert-batch-invoices`,
        {
          query: query,
          fields,
          fetchDataHandler: (csvQuery) => {
            return this.getInvoices(alertId, csvQuery);
          },
        }
      );
    });
  }

  toggleFilter() {
    this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
    this.tabGroup.forceRerender();
  }

  ngOnDestroy() {
    if (this.sider.getActiveSection().name === this.sideSections.filter) {
      this.toggleFilter()
    }
    this.tabGroup.onActivate.unsubscribe();
  }
  showDetails() {
    if (this.selection) {
      this.router.navigate(['/invoice', this.selection.invoice.invoice_id, 'show', 'overview']);
    }
  }
  onSelectionChanged(row) {
    if (row.selectedRowsData[0]){
      this.selection = row.selectedRowsData[0];
    }
  }

}
