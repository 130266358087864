import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FilterBaseComponent } from "../filter-base.component";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { debounceTime, map } from "rxjs";
import { Observable } from "rxjs";
import { ICONS_ENUM } from "../../../core/resources/icons.enum";
import { FilterEntry } from "../../../core/filter/filter-entry";
import { FilterService } from "../../../core/filter/filter.service";
import Query from "../../../core/query/query";

const OPERATORS = {
  IN: "$in",
};

@Component({
  selector: "cas-filter-autocomplete",
  templateUrl: "./filter-autocomplete.component.html",
  styleUrls: ["./filter-autocomplete.component.scss"],
})
export class FilterAutocompleteComponent
  implements OnInit, AfterContentInit, FilterBaseComponent
{
  readonly ICONS = ICONS_ENUM;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public value: any;
  private _queryOptions = { offset: 0, limit: 20 };
  private _query = new Query();

  public provider: any;
  public inputControl = new FormControl();
  public selection: any[] = [];
  public options: any[] = [];

  @Input() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() entry: FilterEntry;
  @Input() filterService: FilterService;

  @ViewChild("optionsInput") optionsInput: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    if (this.entry?.lookupProviderName) {
      this.provider = this.filterService.getLookupProvider(
        this.entry?.lookupProviderName,
      ).provider;
    }
  }

  ngAfterContentInit(): void {
    this.inputControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this._queryOptions = { offset: 0, limit: 20 };
        this.search(value, this._queryOptions).subscribe((options) => {
          this.options = this.filterOptions(options);
        });
      });

    this.search("", { offset: 0, limit: 20 }).subscribe((options) => {
      this.options = options;
    });
  }

  remove(option: any): void {
    const index = this.selection.findIndex((x) => x === option);

    if (index >= 0) {
      this.selection.splice(index, 1);
      this.update();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selection.push(event.option.viewValue);
    this.optionsInput.nativeElement.value = "";
    // this.optionsInput.nativeElement.blur();
    this.inputControl.setValue(null);
    this.update();
  }

  search(value?: string, options?: any): Observable<any> {
    if (value) {
      const searchField = this.entry.field.split(".").pop();
      this._query.where[searchField] = { $ilike: `${value}%` };
    } else {
      this._query = new Query();
    }

    if (options) {
      this._query.limit = options.limit;
      this._query.offset = options.offset;
    }

    return this.provider?.findAll(this._query).pipe(
      map((x: any) => {
        return x.items ?? x;
      }),
    );
  }

  loadData(value?: string, options?: any): Observable<any> {
    if (value) {
      const searchField = this.entry.field.split(".").pop();
      this._query.where[searchField] = { $ilike: `${value}%` };
    } else {
      this._query = new Query();
    }

    if (options) {
      options.limit = 20;
      this._query.limit = options.limit ?? this._query.limit;
      this._query.offset = options.offset ?? this._query.offset;
    }

    return this.provider?.findAll(this._query).pipe(
      map((x: any) => {
        return x.items ?? x;
      }),
    );
  }

  onScroll() {
    this._queryOptions = {
      limit: 20,
      offset: this._queryOptions.offset + 20,
    };

    this.loadData(this.inputControl.value, this._queryOptions).subscribe(
      (options) => {
        this.options = this.options.concat(this.filterOptions(options));
      },
    );
  }

  private update(): void {
    const result = this.selection.length
      ? { [OPERATORS.IN]: this.selection }
      : null;
    this.change.emit(result);
  }

  private getSimpleValue(value): any {
    if (typeof value === "object") {
      return Object.values(value)[0];
    }
    return value;
  }

  private preselect(input?: any): void {
    let value = this.getSimpleValue(input || this.value);
    if (value && !Array.isArray(value)) {
      value = [value];
    }

    this.selection = value ? value : [];
  }

  private filterOptions(options): any {
    return options.filter((option) => {
      return this.selection.indexOf(option) === -1;
    });
  }

  public clearQueryOnFocusOut() {
    this.inputControl.setValue(null);
    this.optionsInput.nativeElement.value = "";
    this.optionsInput.nativeElement.blur();
  }
}
