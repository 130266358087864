<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
    <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
<form class="ca-form" [formGroup]="form" *ngIf="form">

    <div class="flex-container" fxLayout>
      <div class="flex-item form-group no-bottom-padding caGap" fxFlex>

        <ca-lookup-group-picker required="true"
                                caInput
                                [disabled]="isUpdate"
                                (onSelectionChange)="onPickerChanged($event)"
                                placeholder="Group"
                                formControlName="lookupModel"
                                [loadSystemGroups]="isUpdate"
                                [pickerForm]="form"
                                fxFlex="100%">
        </ca-lookup-group-picker>
        <!-- <mat-hint *ngIf="form.hasError('required', ['lookupModel']) && form.controls.lookupModel.touched"
                 class="ca-select-hint">{{VALIDATION.REQUIRED_ERROR}}
        </mat-hint> -->
      </div>
    </div>
    <div *ngIf="form.get('lookupModel').value === 'SiteOwnerCategory'" class="flex-container" fxLayout fxLayoutGap="12px">
      <div class="flex-item form-group no-bottom-padding caGap" fxFlex="45%">
        <ca-lookup-model-picker
          caInput
          [pickerForm]="form"
          [lookupModel]="SITE_TYPE_LOOKUP"
          formControlName="site_type_id"
          required="true"
          placeholder="Site Type">
        </ca-lookup-model-picker>
        <mat-hint class="ca-select-hint" *ngIf="form.hasError('required', ['site_type_id']) && form.controls.site_type_id.touched">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </div>
    </div>

  <div *ngIf="form.get('lookupModel').value === 'ContractScheduleSeries'" class="flex-container" fxLayout fxLayoutGap="12px">
    <div class="flex-item form-group no-bottom-padding caGap" fxFlex="45%">
      <ca-lookup-model-picker
        caInput
        [pickerForm]="form"
        [lookupModel]="CONTRACT_SCHEDULE_CATEGORY"
        formControlName="category_id"
        required="true"
        placeholder="Schedule Category">
      </ca-lookup-model-picker>
      <mat-hint class="ca-select-hint" *ngIf="form.hasError('required', ['category_id']) && form.controls.category_id.touched">
        {{VALIDATION.REQUIRED_ERROR}}
      </mat-hint>
    </div>
  </div>

    <div class="flex-container" fxLayout="row">
      <div class="flex-item caGap" fxFlex>
        <mat-form-field caInput>
          <mat-label>Value</mat-label>
          <input matInput required formControlName="value" type="text">
          <mat-hint *ngIf="form.hasError('required', ['value']) && form.controls.value.touched" class="error-class">
            {{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
          <mat-hint *ngIf="form.hasError('pattern', ['value']) && form.controls.value.touched" class="error-class">
            {{VALIDATION.SUBCLIENT_INCORRECT_FORMAT}}
          </mat-hint>
          <mat-hint *ngIf="!subClientUnique">
            {{VALIDATION.SUBCLIENT_ALREADY_USED}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

  <div *ngIf="form.get('lookupModel').value === 'SubClient' && usingSubclientEntity" class="flex-container" fxLayout="row">
    <div class="flex-item caGap" fxFlex>
      <mat-form-field caInput>
        <mat-label>Entity ID</mat-label>
        <input matInput required formControlName="entity_id" type="number">
        <mat-hint *ngIf="form.hasError('required', ['entity_id']) && form.controls.entity_id.touched" class="error-class">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="form.get('lookupModel').value === 'SubClient' && usingSubclientCurrency" class="flex-container" fxLayout fxLayoutGap="12px">
    <div class="flex-item form-group no-bottom-padding caGap" fxFlex="45%">
      <ca-currency-picker
        clearEnabled="true"
        caInput
        formControlName="currency_id"
        [pickerForm]="form"
        [required]="true"
        [availableCurrencies]="availableCurrencies"
        placeholder="Currency"></ca-currency-picker>
    </div>
  </div>

    </form>

  </div>

  <mat-dialog-actions class="ca-align-baseline ca-dialog-action">
    <div class="example-fill-remaining-space">
        <ca-confirm-delete-button #genericButton *ngIf="isUpdate && !isSys && deletePermission" [confirmText]="'Delete?'"
          (deleteRequested)="delete($event)" [disabled]="isSystem"></ca-confirm-delete-button>
    </div>

    <button mat-button #cancelButton type="submit" (click)="cancel()">
      Cancel
    </button>

    <button mat-button #saveButton type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid || !subClientUnique">
      Save
    </button>
  </mat-dialog-actions>
