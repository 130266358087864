<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Accounts</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

  <button *permission="'Create'" matTooltip="New Account" mat-icon-button (click)="newAccount()">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button *permission="'Modify'" mat-icon-button (click)="editAccount(selection)" matTooltip="Edit Account">
    <ca-icon [key]="'EDIT'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter"
          [ngClass]="{'on' : sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button #toggleDetailsEnableButton
          [ngClass]="{'on' : sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          [disabled]="!selection"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" 
          mat-icon-button matTooltip="Info">
    <ca-icon [key]="'INFO'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">
  <dx-data-grid #grid
                (onSelectionChanged)="onSelectionChanged($event)"
                (onRowClick)="onRowClick($event)"
                (onCellClick)="onCellClick($event)"
                [columns]="columns"
                [dataSource]="accounts">

    <div *dxTemplate="let d of 'onHoldCellTemplate'">
      <div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
    </div>
    <div *dxTemplate="let d of 'taxExemptCellTemplate'">
      <div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
    </div>
</dx-data-grid>
  <ca-pager [doNotEmitQueryOnInitialLoad]="true"
  [gridPager]="defaultGridPager" [pageContext]="accountGridService"
  [query]="query"
   class="app-pager ca-default-border-top" (onChange)="onPageChanged()"></ca-pager>

  <mat-sidenav #panelSide mode="side"
               position="end"
               opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                    [query]="query"
                    [filterService]="accountFilterService"
                    (filterRequested)="filterData($event)"
                [isToRefresh]="isToRefreshFilter"
                (clearFilterRequested)="clearFilter()">
    </new-app-filter>
    <app-account-sneak-peek
      [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
      [account]="selection"
      [visible]="sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)"
      (closeRequested)="sider?.close()"
      (deletedRequested)="deleteAccount($event)"
      (editRequested)="editAccount($event)"></app-account-sneak-peek>
  </mat-sidenav>
</mat-sidenav-container>


