import { Injectable } from '@angular/core';
import { BaseService } from '../../../core/base.service';
import { EntityLockService } from '../../../shared/entity-lock/entity-lock.service';
import Query from 'app/core/query/query';

@Injectable()
export class RateAuditResultService extends BaseService<any> {
	constructor(private entityLock: EntityLockService) {
		super('audit-rate-result', entityLock);
	}

	grouping(query?: Query): any {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().get(
			[this.name, 'grouping'],
			this.toFilter(transformedQuery)
		);
	}
}
