
import {of as observableOf,  Observable } from 'rxjs';
import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from "../../../shared/picker/ca-picker-base.component";
import { DictionaryService } from "../../core/dictionary.service";
import { LOOKUP_MODELS_ENUM } from "../../core/lookup-models.enum";


@Component({
  selector: 'ca-lookup-group-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaLookupGroupPickerComponent),
      multi: true
    },
    DictionaryService
  ]
})
export class CaLookupGroupPickerComponent extends PickerComponentBase implements OnInit, OnChanges {


  readonly TEXT_FIELD = 'text';

  @Input('formControlName') formControlName;
  @Input("initialItem") initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('searchEnabled') searchEnabled: boolean = false;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input() loadSystemGroups: boolean = false;

  public control;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  loadEntries(): Observable<any> {
    let lookups = LOOKUP_MODELS_ENUM;
    let entries = [];
    for (let i in lookups) {
      if (!lookups[i].system || this.loadSystemGroups) {
        entries.push({ id: lookups[i].modelName, text: lookups[i].displayName });
      }
    }

    let entriesSortedASC = entries.sort((a, b) => {
      let textA = a.text.toUpperCase();
      let textB = b.text.toUpperCase();
      if (textA < textB) {
        return -1;
      } else if (textA > textB) {
        return 1;
      }
      return 0;
    }
    );
    return observableOf(entriesSortedASC);
  }

  search(value: string): Observable<any> {
    return observableOf(this.entries);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];
    this.searchEnabled = false;
  }

}
