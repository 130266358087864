import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import Query from "../../core/query/query";

@Injectable()
export class LocationService extends BaseService<any> {

  public name = 'country';

  constructor() {

    super('location');
  }

  findAllEnabled(query?: Query): any {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name], this.toFilter(transformedQuery));
  }

  findLookupCountries(query?: Query): any {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'countries'], this.toFilter(transformedQuery));
  }

  findCountryById(id: number): any {
    return this.httpService().get([this.name, id]);
  }

  findAllExchangeRates(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'exchangeRates'], this.toFilter(transformedQuery));
  }

  findAll(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'all'], this.toFilter(transformedQuery));
  }

  findAllSubdivision(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'subdivision'], this.toFilter(transformedQuery));
  }

  findByIdSubdivision(id: number) {
    return this.httpService().get([this.name, 'subdivision']);
  }

  findAllCountrySubdivisions(countryCode: string, query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, countryCode, 'states'], this.toFilter(transformedQuery));
  }

  findAllStateSubdivisions(countryCode: string, query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, countryCode, 'stateslist'], this.toFilter(transformedQuery));
  }

  findAllCurrencies(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'currencies'], this.toFilter(transformedQuery));
  }

  findAllActiveCountriesCurrencies(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'active-countries-currencies'], this.toFilter(transformedQuery));

  }

  findAllUniqueCurrencies(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'uniquecurrencies'], this.toFilter(transformedQuery));
  }

  findAllCurrenciesByCountry(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'currenciesCountry'], this.toFilter(transformedQuery));
  }

  findCurrencyById(id) {
    return this.httpService().get([this.name, 'currency', id]);
  }
}
