import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DictionaryService} from './core/dictionary.service';
import {MaterialModule} from "app/material-barrel/material-barrel.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DictionaryManageDialogComponent} from './shared/dictionary-manage-dialog/dictionary-manage-dialog.component';
import {DictionaryListComponent} from './dictionary-list/dictionary-list.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {DictionaryRoutingModule} from "./dictionary-routing.module";
import {DxButtonModule} from "devextreme-angular/ui/button";
import {DxDataGridModule} from "devextreme-angular/ui/data-grid";

import {DictionaryGridService} from "./core/dictionary-grid.service";
import {DictionarySneakPeekComponent} from './shared/dictionary-sneak-peek/dictionary-sneak-peek.component';
import {DictionaryReorderComponent} from './shared/dictionary-reorder/dictionary-reorder.component';

import {CoreModule} from "../core/core.module";
import { UserSettingsService } from '../user/core/user-settings.service';
import { SharedModule } from '../shared/shared.module';

import { CaLookupGroupPickerComponent } from './shared/lookup-group-picker/ca-lookup-group-picker.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        DictionaryRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        DxButtonModule,
        DxDataGridModule,
        CoreModule,
        SharedModule,
        MaterialModule,
        DragDropModule
    ],
    exports: [
        CaLookupGroupPickerComponent
    ],
    providers: [
        DictionaryService,
        DictionaryGridService,
        UserSettingsService
    ],
    declarations: [
        DictionaryManageDialogComponent,
        DictionaryListComponent,
        DictionarySneakPeekComponent,
        DictionaryReorderComponent,
        CaLookupGroupPickerComponent,
    ]
})
export class DictionaryModule {
}
