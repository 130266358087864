import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {DialogButtonsController} from "../../../core/dialog-buttons-controller.class";
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';


@Component({
  selector: 'app-create-invoice-charge-profile-duplicate-warning-dialog',
  templateUrl: './create-invoice-charge-profile-duplicate-warning-dialog.component.html'
})
export class CreateInvoiceChargeProfileDuplicateWarningDialogComponent
  extends DialogButtonsController
  implements OnInit
{
  message: string;
  readonly REPLACE_INVOICE_CHARGE_PROFILE: string = LOOKUP_MODELS_ENUM.REPLACE_INVOICE_CHARGE_PROFILE.modelName;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateInvoiceChargeProfileDuplicateWarningDialogComponent>,
    public formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      replacement: [""]
    });
  }

  submitValueAndClose(value) {
    this.dialogRef.close(value ? this.form.controls['replacement'].value : value);
  }
}

