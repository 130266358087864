import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { QuoteService } from '../../core/quote-service.model';
import { QuotingService } from '../../core/quoting.service';
import { Quote } from '../../core/quote';
import { FlowService } from '../../../shared/flow/flow.service';
import { FlowStep } from '../../../shared/flow/flow-step';
import { Flow } from '../../../shared/flow/flow';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { Vendor } from '../../../vendor/core/vendor';
import { VendorService } from '../../../vendor/core/vendor.service';
import { VendorQuery } from '../../../vendor/core/vendor.query';
import { Address } from '../../../address/core/address.model';
import { ConfigService } from '../../../core/config/config.service';
import { Config } from '../../../core/config/config';
import {
  IMessagesResourceService,
  ResourcesService
} from '../../../core/resources/resources.service';
import { AlertService } from '../../../shared/alert/alert.service';
import Query from '../../../core/query/query';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { DictionaryService } from '../../../dictionary/core/dictionary.service';
import { BandwidthService } from '../../../bandwidth/core/bandwidth.service';
import { DialogButtonsController } from '../../../core/dialog-buttons-controller.class';
import {flatMap} from "rxjs";

enum UILifeCycleStatus {
  Loading,
  Ready,
  NoData
}

class QuotingServiceDialogUI {
  status: UILifeCycleStatus = UILifeCycleStatus.Loading;
  canPrev = false;
  canNext = false;
  canConfirm = false;
  previousButtonVisible = false;
  confirmButtonVisible = false;
  nextButtonVisible = false;
  submitInProgress = false;
  public addressColumnHeight = '100%';
  public addressColumnWidth = '100%';
}

@Component({
  selector: 'app-quoting-service-manage-dialog',
  templateUrl: './quoting-service-manage-dialog.component.html',
  styleUrls: ['./quoting-service-manage-dialog.component.css'],
  providers: []
})
export class QuotingServiceDialogComponent extends DialogButtonsController
  implements OnInit {
  inventoryTechnologyQuery = new Query({
    orderBy: ['value', 'ASC']
  });

  readonly ORRERS_LOOKUP: string =
    LOOKUP_MODELS_ENUM.QUOTE_SERVICE_OFFER_METHOD.modelName;

  offerMethods: any[];

  titleValue: string;

  /**
   * Readonly Members
   */

  readonly STEPS = {
    FORM: 1,
    VENDOR_SELECTION: 2,
    FINAL: 3
  };

  readonly VALIDATION = {
    REQUIRED_ERROR: 'Field is required',
    EMAIL_ERROR: 'Email is invalid',
    EMAIL_ALREADY_USED: 'Email is already in use',
    PHONE_INVALID: '(###) ###-####',
    ZIP_INVALID: '##### or #####-####',
    DATE_INVALID: 'mm/dd/yyyy',
    INTEGER_INVALID: 'Invalid value'
  };

  readonly QUOTE_SERVICE_OFFER_METHOD = LOOKUP_ENUM.QUOTE_SERVICE_OFFER_METHOD;
  readonly QUOTE_SERVICE_OFFER_STATUS = LOOKUP_ENUM.QUOTE_SERVICE_OFFER_STATUS;
  readonly QUOTING_SERVICE_TYPE = LOOKUP_ENUM.QUOTING_SERVICE_TYPE;
  readonly MESSAGES_MODULE: string = 'quote';

  /**
   * Public Members
   */
  UI = new QuotingServiceDialogUI();
  service: QuoteService;
  quote: Quote;
  form: FormGroup;
  currentStep = 1;
  refreshAfterCancel = false;
  isUpdate = false;
  flow: Flow;
  quote_service_type_id: number;
  vendors: Array<Vendor> = [];
  nonExhaustedVendors: Array<Vendor> = [];
  tierVendors: Array<Vendor> = [];
  selectedOrExistingRequests = [];
  selectedRequests = [];
  terms = [];
  allVendorsSelected = false;
  allRequestsSelected = false;
  selectedEmailTabIndex = 0;
  selectedAddress: Address;
  messages: IMessagesResourceService;
  circuitBandwidthValue: string;
  portBandwidthValue: string;

  email = {
    cc: [],
    bcc: [],
    subject: '',
    header: ''
  };

  serviceTypeValue: any;
  clientServiceTypeValue: string;
  now: any;

  constructor(
    public quotingService: QuotingService,
    public dialogRef: MatDialogRef<QuotingServiceDialogComponent>,
    public formBuilder: FormBuilder,
    public flowService: FlowService,
    public alert: AlertService,
    public vendorService: VendorService,
    public configService: ConfigService,
    private sanitizer: DomSanitizer,
    private bandwidthService: BandwidthService,
    public dictionaryService: DictionaryService
  ) {
    super();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  next() {
    this.flow.next();
    this.onStepChanged();
  }

  previous() {
    this.flow.previous();
    this.onStepChanged();
    this.UI.canNext = true;
  }

  getTitle() {
    this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTING_SERVICE_TYPE.modelName).subscribe(res => {
      this.titleValue = res.items.filter(x => x.id === this.service.quote_service_type_id)[0].value;
    });

    return this.sanitizer.bypassSecurityTrustHtml(`${this.service.quote_service_id || ''} &#8226; ${this.titleValue}`);
  }

  selectAllVendors(event) {
    this.allVendorsSelected = event.checked;
    this.nonExhaustedVendors.forEach(vendor => {
      vendor.selected = this.allVendorsSelected;
    });
    this.refreshUI();
  }

  selectVendor(event, vendor: Vendor) {
    vendor.selected = event.checked;
    this.allVendorsSelected =
      this.nonExhaustedVendors.filter(vendorItem => {
        return vendorItem.selected;
      }).length === this.nonExhaustedVendors.length;
    this.refreshUI();
  }

  selectRequest(event, request: any) {
    request.selected = event.checked;
    this.allRequestsSelected =
      this.selectedOrExistingRequests.filter(requestItem => {
        return requestItem.selected;
      }).length === this.selectedOrExistingRequests.length;
    this.onRequestSelectionChange();
  }

  selectAllRequests(event) {
    this.allRequestsSelected = event.checked;
    this.selectedOrExistingRequests
      .filter(request => {
        return !request.used;
      })
      .forEach(request => {
        request.selected = this.allRequestsSelected;
      });

    this.onRequestSelectionChange();
  }

  anyEmailRequestSelected() {
    return !!this.selectedOrExistingRequests.filter(request => {
      return (
        request.method &&
        request.method.id === this.QUOTE_SERVICE_OFFER_METHOD.EMAIL &&
        request.selected &&
        !request.used
      );
    }).length;
  }

  emailVendorsSelected() {
    return this.selectedOrExistingRequests
      .filter(request => {
        return (
          request.method &&
          request.method.id === this.QUOTE_SERVICE_OFFER_METHOD.EMAIL &&
          request.selected &&
          !request.used
        );
      })
      .map(vendor => {
        return {
          id: vendor.id,
          name: vendor.name
        };
      });
  }

  /**
   * Handlers
   */

  ngOnInit(): void {
    this.dictionaryService
      .getByLookup(this.ORRERS_LOOKUP)
      .subscribe((res: any) => {
        this.offerMethods = res.items;
      });

    this.UI = this.buildUI();

    this.service =
      this.service ||
      new QuoteService({
        quote_service_type_id: this.quote_service_type_id
      });

    this.isUpdate = !!this.service.id;
    this.UI.previousButtonVisible = !this.isUpdate;

    // terms
    this.service.terms =
      this.service.terms && this.service.terms.length
        ? this.service.terms
        : this.terms.filter(term => {
          return term.selected;
        });

    // flow
    this.flow = this.flowService.create({
      currentStep: this.currentStep
    });
    this.flow.steps.push(
      new FlowStep({ name: 'Service Info', code: this.STEPS.FORM })
    );
    this.flow.steps.push(
      new FlowStep({
        name: 'Vendor and Hub Info',
        code: this.STEPS.VENDOR_SELECTION
      })
    );
    this.flow.steps.push(
      new FlowStep({ name: 'Confirm', code: this.STEPS.FINAL })
    );
    this.form = this.buildForm(this.service);
    if (!this.service.id) {
      this.quotingService
        .fetchQuoteServiceCount(this.quote.id)
        .subscribe(result => {
          const { count } = result;
          this.service.quote_service_id =
            count > 9
              ? `${this.quote.quote_id}-${count + 1}`
              : `${this.quote.quote_id}-0${count + 1}`;
          this.UI.status = UILifeCycleStatus.Ready;
          this.loadTerms();
          this.loadVendors();
          this.refreshUI();
        });
    }

    if (this.service.id) {
      this.UI.status = UILifeCycleStatus.Ready;
      this.loadTerms();
      this.loadVendors();
      this.refreshUI();

      this.clientServiceTypeChanged({value: this.service.client_service_type, text: ''});
    }

    if (
      this.service.circuit_bandwidth &&
      this.service.circuit_bandwidth.value
    ) {
      this.circuitBandwidthValue = this.service.circuit_bandwidth.value;
    }

    if (this.service.port_bandwidth && this.service.port_bandwidth.value) {
      this.portBandwidthValue = this.service.port_bandwidth.value;
    }

    if (this.service.quote_service_type && this.service.quote_service_type.value) {
      this.serviceTypeValue = this.service.quote_service_type.value;
    }
  }


  submit({ value }: { value: QuoteService; valid: boolean }) {
    let service = Object.assign({}, this.service, value, { requests: [] });
    service.secondary_address_id = service.quote_service_type_id === this.QUOTING_SERVICE_TYPE.P2P ? service.secondary_address.id : null;
    service.requests = this.selectedOrExistingRequests.filter(request => {
      request.bandwidth =
        service.circuit_bandwidth && service.circuit_bandwidth.value;
      request.port_bandwidth =
        service.port_bandwidth && service.port_bandwidth.value;
      return !request.used;
    });

    /*if (value && value.quote_service_type && value.quote_service_type_id !== LOOKUP_ENUM.QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT) {
      service.requests.forEach(request => {
        request.selected_hub = null;
      })
    }*/

    if (service.id) {
      this.updateService(service);
    } else {
      service.service_term_id = this.terms
        .filter(term => {
          return term.selected;
        })
        .map(term => {
          return term.id;
        });

      this.createService(service);
    }
  }

  onStepSelection(flowStep: FlowStep) {
    if (flowStep.code >= this.flow.currentStep) {
      if (this.UI.canNext) {
        this.next();
      }
    } else {
      this.previous();
      this.UI.canNext = true;
    }
  }

  onEmailsTabChange(index: number) {
    this.selectedEmailTabIndex = index;
  }

  onBandwidthChange(event, type?) {
    const query: Query = new Query();
    query.set('id', event.value);

    if (type === 'circuitBandwidth') {
      this.dictionaryService
        .getByLookup(LOOKUP_MODELS_ENUM.BANDWIDTH.modelName, query, true)
        .subscribe((res: any) => {
          this.circuitBandwidthValue = res.items[0].value;
        });
    } else if (type === 'portBandwidth') {
      this.dictionaryService
        .getByLookup(LOOKUP_MODELS_ENUM.BANDWIDTH.modelName, query, true)
        .subscribe((res: any) => {
          this.portBandwidthValue = res.items[0].value;
        });
    }
  }

  onAddressChanged(address: Address) {
    if (address && (address.internal || address.is_validated)) {
      this.selectedAddress = new Address(address);

      this.form.controls['address'].setValue(new Address(address));
      return;
    }

    this.form.controls['address'].setValue(null);
  }

  onSecondaryAddressChanged(address: Address) {
    if (address && (address.internal || address.is_validated)) {
      this.selectedAddress = new Address(address);

      this.form.controls['secondary_address'].setValue(new Address(address));
      return;
    }

    this.form.controls['secondary_address'].setValue(null);
  }

  /**
   * Private Members
   */

  public isRequestSelected(request) {
    return this.selectedRequests.includes(request)
  }

  private createService(service: QuoteService) {
    service.quote_id = this.quote.id;

    let UI = this.UI;

    UI.submitInProgress = true;

    this.toggleDialogButtons();
    this.selectedRequests.forEach((request) => {
      request.status = this.QUOTE_SERVICE_OFFER_STATUS.SENT;
      request.used = true;
    })


    this.quotingService.addService(this.quote.id, service).subscribe(
      (result) => {
        this.toggleDialogButtons(false);
        UI.submitInProgress = false;
        this.selectedOrExistingRequests.forEach((req) => {
          if (this.isRequestSelected(req)) {
            req.status = this.QUOTE_SERVICE_OFFER_STATUS.SENT;
            req.used = true;
           // this.quotingService.updateRequest(this.quote.id, service.id, result.offers.id, req);
          }
        })

        this.selectedRequests = [];
        UI.canConfirm = this.isConfirmEnabled();
        this.alert.success(
          '',
          this.messages.get('QUOTE_SERVICE_CREATE_SUCCESS')
        );
        this.refreshAfterCancel = true;
      },
      () => {
        this.toggleDialogButtons(false);
        UI.submitInProgress = false;
        this.alert.error('', this.messages.get('QUOTE_SERVICE_CREATE_ERROR'));
      }
    );
  }

  onEmailChange(event: any, field: string) {
    const EMAIL_FIELDS = {
      CC: 'cc',
      BCC: 'bcc'
    };

    if (field === EMAIL_FIELDS.CC) {
      this.email.cc = event;
    } else if (field === EMAIL_FIELDS.BCC) {
      this.email.bcc = event;
    }
  }

  private updateService(service: QuoteService) {
    let UI = this.UI;

    UI.submitInProgress = false;
    this.toggleDialogButtons();
    this.quotingService.updateService(service.id, service).subscribe(
      () => {
        this.toggleDialogButtons(false);
        UI.submitInProgress = false;
        this.selectedOrExistingRequests.forEach((req) => {
          if (this.isRequestSelected(req)) {
            req.status = this.QUOTE_SERVICE_OFFER_STATUS.SENT;
            req.used = true;
            // this.quotingService.updateRequest(this.quote.id, service.id, result.offers.id, req);
          }
        })
        this.selectedRequests = [];
        UI.canConfirm = this.isConfirmEnabled();
        this.alert.success(
          '',
          this.messages.get('QUOTE_SERVICE_UPDATE_SUCCESS')
        );
      },
      () => {
        this.toggleDialogButtons(false);
        UI.submitInProgress = false;
        this.alert.error('', this.messages.get('QUOTE_SERVICE_UPDATE_ERROR'));
      }
    );
  }

  private onStepChanged() {
    this.refreshUI();

    if (this.form) {
      this.service = Object.assign({}, this.service, this.form.value);
    }

    this.service.terms =
      this.service.terms && this.service.terms.length
        ? this.service.terms
        : this.terms.filter(term => {
          return term.selected;
        });

    // TODO: what is this?
    // this.service.quote_service_type = this.serviceTypes.filter(type => type.id == this.service.service_type)[0];
    //

    if (this.flow.isCurrentStep(this.STEPS.VENDOR_SELECTION)) {
      if (!this.vendors.length) {
        this.loadVendors();
      }
    } else if (this.flow.isCurrentStep(this.STEPS.FINAL)) {
      let requests = [],
        offers = this.service.offers || [],
        vendors = this.nonExhaustedVendors.filter(vendor => vendor.selected);

      requests = requests.concat(
        vendors.map(vendor => {
          return {
            method: vendor.methods.find(
              method => vendor.method_id === method.id
            ),
            rate_tier: vendor.rate_tier,
            vendor: vendor,
            hub_id: vendor.hub_id,
            selected_hub: this.service.quote_service_type_id === LOOKUP_ENUM.QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT ?
              vendor.hubs.find(hub => hub.id === vendor.hub_id) : null,
            status: 0,
            used: false
          };
        })
      );

      requests = requests.concat(
        offers.map(offer => {
          return {
            id: offer.id,
            method: offer.quote_type,
            vendor: offer.vendor,
            status: offer.status,
            failure_reason: offer.failure_reason,
            selected_hub: this.service.quote_service_type_id === LOOKUP_ENUM.QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT ?
              offer.network_hub : null,
            selected: true,
            used: true
          };
        })
      );

      this.selectedOrExistingRequests = requests;

      this.constructEmailAttributes();
    }
  }

  private onRequestSelectionChange() {
    let selectedRequests = this.selectedOrExistingRequests.filter(request => {
      return request.selected && !request.used;
    });

    this.selectedRequests = selectedRequests;

    this.refreshUI();
  }

  private constructEmailAttributes() {
    this.configService.findAll().subscribe((config: Config) => {
      this.email.subject = `Quote ${this.service.quote_service_id}`;
      this.email.header = config.quoting.default_email_header;
    });
  }

  private refreshUI() {
    let UI = this.UI,
      flow = this.flow,
      service = this.service;

    UI.canNext = flow.canNext() && this.form.valid;
    UI.canPrev = flow.canPrevious();
    UI.addressColumnHeight =
      service.quote_service_type_id === this.QUOTING_SERVICE_TYPE.P2P
        ? '50%'
        : '100%';
    UI.addressColumnWidth =
      service.quote_service_type_id === this.QUOTING_SERVICE_TYPE.P2P
        ? '50%'
        : '100%';
    UI.confirmButtonVisible = flow.isLastStep();
    UI.previousButtonVisible = !this.isUpdate;
    UI.canConfirm = this.isConfirmEnabled();
    UI.nextButtonVisible = flow.canNext();

    this.service.terms =  this.terms.filter(term => term.selected === true);
  }

  private loadTerms() {
    this.quotingService.findAllServiceTerms().subscribe(response => {
      let serviceTerms = this.service.service_term_id
        ? this.service.service_term_id
        : [];
      let items = response.items;

      let terms = items.map(entry => {
        let ind = serviceTerms.indexOf(entry.id);
        return {
          id: entry.id,
          text: entry.value,
          order_number: entry.order_number,
          selected:
            !this.service.service_term_id ||
            !this.service.service_term_id.length ||
            ind > -1
        };
      });

      terms.sort((a, b) => {
        return a.order_number - b.order_number;
      });

      this.terms = terms;
    });
  }

  private loadVendors() {
    const vendorQuery = new VendorQuery({
      limit: 1000,
      where: {
        is_quoting: true
      }
    });

    this.vendorService
      .findAll(vendorQuery)
      .pipe(flatMap((result: any) => {
        this.vendors = result.items;
        return this.quotingService.getAllHubs(null);
      }))
      .subscribe(hubs => {
        let vendors = this.mapVendorData(this.vendors, hubs, this.service);
        this.nonExhaustedVendors = this.getAllNonExhaustedVendors(
          this.service,
          vendors
        );
        this.tierVendors = this.nonExhaustedVendors.filter((vendor: Vendor) => {
          return vendor.is_tier_contract_quoting;
        });
      });
  }

  private mapMethodData(
    vendor: Vendor,
    methods: Array<any>,
    offers: Array<any>
  ): Array<any> {
    return methods
      .filter(method => {
        return method.id !== this.QUOTE_SERVICE_OFFER_METHOD.MANUAL;
      })
      .map(method => {
        return Object.assign({}, method, {
          used: !!this.findOfferByVendorAndMethod(vendor, method, offers || [])
        });
      });
  }

  private mapVendorData(
    vendors: Array<Vendor>,
    hubs: Array<any>,
    service: any
  ) {
    return vendors.map(vendor => {
      let vendorHubs;
      if (service.quote_service_type_id === LOOKUP_ENUM.QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT) {
        vendorHubs = this.getVendorHubs(vendor, hubs);
      }
      let methods = vendor.quote_methods ? vendor.quote_methods.items : [];
      methods = this.mapMethodData(vendor, methods, service.offers);
      return Object.assign({}, vendor, {
        hubs: vendorHubs,
        hub_id: service.quote_service_type_id === LOOKUP_ENUM.QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT ?
          this.getPreselectedHubForVendor(vendorHubs).id : null,
        methods: methods,
        method_id: (this.getPreselectedMethodForVendor(methods) || {}).id,
        // TODO: Take this from DB
        tiers: [1, 2, 3, 4],
        rate_tier: null
      });
    });
  }

  private getVendorHubs(vendor: Vendor, hubs: Array<any>) {
    let result = [];
    result.push({ id: -20, name: 'NO HUB SELECTED' });
    result.push({ id: -10, name: 'RESALE' });
    return result.concat(
      hubs.filter(hub => {
        return hub.vendor_id === vendor.id;
      })
    );
  }

  private getPreselectedHubForVendor(hubs: Array<any>) {
    let selection = hubs.filter(hub => {
      return hub.id >= 0;
    })[0];

    return selection ? selection : hubs[0];
  }

  private getPreselectedMethodForVendor(methods: Array<any>) {
    return methods.filter(method => {
      return !method.used;
    })[0];
  }

  private findOfferByVendorAndMethod(
    vendor: Vendor,
    method: any,
    offers: Array<any>
  ) {
    return offers.filter(offer => {
      return offer.vendor_id === vendor.id && offer.quote_type_id === method.id;
    })[0];
  }

  private getAllVendorUnusedMethods(
    vendor: Vendor,
    offers: Array<any>
  ): Array<any> {
    return vendor.methods.filter(method => {
      return !this.findOfferByVendorAndMethod(vendor, method, offers || []);
    });
  }

  private getAllNonExhaustedVendors(service, vendors) {
    let offers = service.offers;

    return vendors.filter(vendor => {
      return this.getAllVendorUnusedMethods(vendor, offers).length > 0;
    });
  }

  private buildForm(service: QuoteService) {
    let formConfig = {
      address: [this.isUpdate ? service.address : '', Validators.required],
      client_service_type: [this.isUpdate ? service.client_service_type : null],
      quote_service_type_id: [
        service.quote_service_type_id ||
          this.QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT,
        Validators.required
      ],

      diversity: [this.isUpdate ? service.diversity : null],
      service_term_id: [this.isUpdate ? service.service_term_id : []],
      current_vendor_id: [this.isUpdate ? service.current_vendor_id : null],
      service_details: [
        { value: service.service_details, disabled: this.isUpdate }
      ],
      vendors: [this.isUpdate ? service.vendors : '']
    };

    if (
      service.quote_service_type_id === this.QUOTING_SERVICE_TYPE.ACCESS_CIRCUIT
    ) {
      formConfig['npa'] = [
        { value: this.service.npa, disabled: this.isUpdate }
      ];
      formConfig['nxx'] = [
        { value: this.service.nxx, disabled: this.isUpdate }
      ];
      formConfig['circuit_bandwidth_id'] = [
        {
          value: service.circuit_bandwidth_id,
          disabled: this.isUpdate
        },
        Validators.required
      ];
      formConfig['port_bandwidth_id'] = [
        { value: service.port_bandwidth_id, disabled: this.isUpdate }
      ];
      formConfig['desired_install_date'] = [
        {
          value:
            service.id && service.desired_install_date
              ? new Date(service.desired_install_date)
              : null,
          disabled: this.isUpdate
        },
        Validators.required
      ];
      formConfig['service_type'] = [
        this.isUpdate ? service.service_type : '',
        Validators.required
      ];
    } else if (
      service.quote_service_type_id === this.QUOTING_SERVICE_TYPE.P2P
    ) {
      formConfig['secondary_address'] = [
        this.isUpdate ? service.secondary_address : '',
        Validators.required
      ];
      formConfig['circuit_bandwidth_id'] = [
        {
          value: service.circuit_bandwidth_id,
          disabled: this.isUpdate
        },
        Validators.required
      ];
      formConfig['port_bandwidth_id'] = [
        { value: service.port_bandwidth_id, disabled: this.isUpdate }
      ];
      formConfig['desired_install_date'] = [
        {
          value:
            service.id && service.desired_install_date
              ? new Date(service.desired_install_date)
              : null,
          disabled: this.isUpdate
        },
        Validators.required
      ];
      formConfig['service_type'] = [
        this.isUpdate ? service.service_type : '',
        Validators.required
      ];
    }

    let form = this.formBuilder.group(formConfig);

    //When I upload Angular on v14, I had issue with this part of code. I couldn't start app.
    //error TS2339: Property 'nxx' does not exist on type 'Partial<{ address: string | Address; client_service_type: number; quote_service_type_id: number; diversity: string; service_term_id: number[]; current_vendor_id: number; service_details: string; vendors: string; }>'.
    // form.valueChanges.subscribe(val => {
    //   // console.log(">>>>>>>val>>>", val);
    //   if (val.npa) {
    //     this.service.npa = val.npa;
    //   }
    //
    //   if (val.nxx) {
    //     this.service.nxx = val.nxx;
    //   }
    //
    //   this.refreshUI();
    // });

    return form;
  }

  buildUI() {
    return new QuotingServiceDialogUI();
  }

  isConfirmEnabled() {
    return this.selectedRequests.length > 0 && this.form.valid;
  }

  cancel() {
    this.quotingService.cancelEdit();
    const closeData = this.refreshAfterCancel ? { refresh: this.refreshAfterCancel } : null;
    this.dialogRef.close(closeData);
  }

  public serviceTypeChanged(type: { value: number; text: any }): void {
    const query = new Query();
    const { value } = type;
    query.set('id', value);

    this.bandwidthService.findAllTypes(query).subscribe((res: any) => {
      if (res && res.items && res.items.length) {
        this.serviceTypeValue = res.items[0].value;
      }
    });
  }

  public clientServiceTypeChanged(type: { value: number; text: any }): void {
    const { value } = type;

    if (value) {
      this.dictionaryService.findLookupById(LOOKUP_MODELS_ENUM.QUOTING_CLIENT_SERVICE_TYPE.modelName, value).subscribe((res: any) => {
        this.clientServiceTypeValue = res.value;
      });
    }
  }
}
