import {Injectable} from '@angular/core';
import {BaseService} from '../../../core/base.service';
import {EntityLockService} from '../../../shared/entity-lock/entity-lock.service';
import Query from 'app/core/query/query';


@Injectable()
export class RateAuditRuleService extends BaseService<any> {
  constructor(private entityLock: EntityLockService) {
    super('audit-rate-rule', entityLock);
  }

  getRules(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'rules'], this.toFilter(transformedQuery));
  }

  update(auditObject){
    return this.httpService().put([this.name, 'update'], auditObject);
  }

  updateBulk(auditId, rules:any){
    return this.httpService().put([this.name, 'updateBulk'], {auditId, rules});
  }

}
