import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Address} from "./address.model";
import Query from "../../core/query/query";

@Injectable()
export class AddressService extends BaseService<any> {

  constructor() {
    super('address');
  }

  suggest(q: string,country_id?:any): any {
    const query = new Query({
      where: {q,country_id }
    });
    return this.httpService().get([this.name, 'suggest'], query);
  }

  suggestInternal(q: string): any {
    const query = new Query({
      where: {q}
    });
    return this.httpService().get([this.name, 'suggestinternal'], this.toFilter(query));
  }

  verify(strAddress: Object): any {
    return this.httpService().get([this.name, 'verify'], this.toFilter(strAddress));
  }

  createOrUpdate(item: Address): any {
    return this.httpService().post([this.name, 'check'], item);
  }

}
