<app-invoice-details-toolbar
	[invoice]="invoice"
	[isRunAuditHidden]="false"
	[isGoToRuleExecutionPageHidden]="false"
	[isDeleteInvoiceHidden]="false"
	[isOnHoldHidden]="false"
	(toggleNotesRequested)="toggleSider(SECTIONS.NOTES_SECTION_NAME)"
	[isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
	(toggleHistoryRequested)="toggleSider(SECTIONS.HISTORY_SECTION_NAME)"
	[isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"
	(moveInvoiceToDoNotProcesseRequested)="onMoveInvoiceToDoNotProcessRequested()"
	(changeInvoiceDocumentTypeRequested)="
		onChangeInvoiceDocumentTypeRequested($event)
	"
	(putOnHoldRequested)="onPutOnHoldRequested()"
	[isOnHoldDisabled]="isOnHoldDisabled"
	[notesCount]="notesCount"
	[isDoNotProcessDisabled]="isDoNotProcessDisabled"
	[isCSVHidden]="true"
	[isCSVDisabled]="true"
	[isAddDisputeHidden]="true"
	[isGLChargeViewSwitchHidden]="true"
	[isAddDirectGLHidden]="true"
	[isEditDirectGLHidden]="true"
	[isGridSettingsHidden]="false"
	[isGridSettingsDisabled]="true"
	[isFilterHidden]="false"
	[isFilterDisabled]="true"
	[isDropDownFileHidden]="false"
	[isDropDownCsvHidden]="false"
	[isDropDownCsvDisabled]="true"
	[isDropDownUploadDocumentDisabled]="true"
	[isDropDownDownloadPDFHidden]="false"
	[isDownloadPDFHidden]="true"
	[isDownloadContainerHidden]="true"
	(backRequested)="back()"
	(runAuditRequested)="runAudit()"
></app-invoice-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
	<app-invoice-details-tabs
		[activeTabIndex]="0"
		[invoice]="invoice"
	></app-invoice-details-tabs>
	<div class="tabs-body">
		<div class="flex-container details">
			<ca-invoice-flow
				class="invoice-flow card-flow-wrapper-horizontal-8"
				(onStepSelection)="onStepSelection($event)"
				[flow]="flow"
				[step]="flow?.currentStep"
			></ca-invoice-flow>
		</div>

		<div
			class="dnp-onhold-banner ca-card custom-mat-card-container-large"
			*ngIf="dnpInfo"
		>
			<mat-card fxFlex caCard class="banner-danger mt-0 mb-0">
				<mat-card-content>
					<div fxLayout="row" class="entry-list">
						<div class="mdl-stepper-icon warning">
							<ca-icon
								[key]="'INVOICE_FLOW_ERROR'"
								class="icon-danger"
							></ca-icon>
						</div>

						<div>
							<div [ngClass]="{ 'mt-m4': !dnpInfo?.user?.full_name }">
								<span class="fw-500">{{ dnpInfo.status || 'N/A' }}</span>
								<span *ngIf="dnpInfo?.value"> &#8226;</span>
								{{ dnpInfo.value || '' }}
							</div>
							<div [ngClass]="{ 'mt-m4': !dnpInfo?.user?.full_name }">
								<!--                <span class="fw-500">{{ dnpInfo.status || 'N/A' }}:</span>-->
								{{ dnpInfo?.concatMessage || 'N/A' }}
							</div>
							<div class="muted small" *ngIf="dnpInfo?.user?.full_name">
								{{ dnpInfo?.user?.full_name }}
								{{ (dnpInfo?.created_at | caDateTime) || 'N/A' }}
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>

		<div
			class="dnp-onhold-banner ca-card custom-mat-card-container-large"
			*ngIf="onHoldInfo"
		>
			<mat-card fxFlex caCard class="banner-warning mt-0 mb-0">
				<mat-card-content>
					<div fxLayout="row" class="entry-list">
						<div class="mdl-stepper-icon warning">
							<ca-icon
								[key]="'INVOICE_FLOW_WARNING'"
								class="icon-warning"
							></ca-icon>
						</div>

						<div>
							<div>
								<span class="fw-500"
									>{{ onHoldInfo?.meta_data?.on_hold?.label || 'N/A' }} :</span
								>
								{{ onHoldInfo?.meta_data?.on_hold_desc?.value || 'N/A' }}
							</div>
							<div class="muted small">
								{{ onHoldInfo?.user?.full_name }}
								{{ (onHoldInfo?.created_at | caDateTime) || 'N/A' }}
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="ca-card custom-mat-card-container-large mt-0" fxLayout="row">
			<div fxFlex="40" fxLayout="column">
				<!-- Vendor -->
				<mat-card fxFlex caCard>
					<mat-card-content>
						<div fxLayout="row" class="entry-list">
							<div
								fxFlex
								fxLayoutAlign="start start"
								style="overflow: hidden; padding-right: 15px"
							>
								<div class="invoice-avatar">
									<div
										*ngIf="invoice?.account?.vendor?.logo"
										class="vendor-image ca-default-border"
										style="width: 100%; height: 100%"
									>
										<img
											style="width: 100%"
											[src]="invoice?.account?.vendor?.logo"
											alt="Vendor logo"
										/>
									</div>
									<div
										class="ca-empty-state-container vendor-image-empty"
										style="width: 100%; height: 100%"
										*ngIf="!invoice?.account?.vendor?.logo"
									>
										<p class="card-message">No image</p>
									</div>
								</div>
							</div>
							<div fxFlex="75%" class="entry-list-item">
								<p>
									{{ invoice?.account?.vendor?.name }}
									<br />
									{{ invoice?.sp_remit_addr_1 }} <br />
									{{ invoice?.sp_remit_city }}, {{ invoice?.sp_remit_state }},
									{{ invoice?.sp_remit_zip }}
									<span [hidden]="!invoice?.sp_inquiry_tel_num">
										<br />For inquiries call:
										{{ invoice?.sp_inquiry_tel_num | caPhone }}
									</span>
								</p>
								<p class="ca-invoice-address-section ca-default-border-color">
									{{ invoice?.billed_company_name }}
									<br />
									{{ invoice?.billed_company_addr_1 }}
									<span [hidden]="!invoice?.billed_company_addr_2">
										<br />{{ invoice?.billed_company_addr_2 }}
									</span>
									<br />
									{{ invoice?.billed_company_city }}
									<span *ngIf="invoice && invoice.billed_company_city">, </span
									>{{ invoice?.billed_company_state }}
									<span *ngIf="invoice && invoice.billed_company_state">, </span
									>{{ invoice?.billed_company_zip }}
								</p>
							</div>
						</div>
					</mat-card-content>
				</mat-card>

				<!-- Remittance Section -->
				<mat-card fxFlex caCard>
					<mat-card-header style="align-items: baseline">
						<p fxFlex="100%" style="margin: 0">Remittance Section</p>
						<div fxLayout="row" class="entry-list" fxFlex="30%">
							<div
								fxFlex="30%"
								fxFlex
								class="entry-list-item"
								class="account-badge"
							>
								<p
									class="switch-button status-badge"
									[ngClass]="{
										'btn-warning':
											invoice?.header?.document_type_id ===
											DOCUMENT_INVOICE_TYPE.CREDIT_MEMO,
										'btn-neutral':
											invoice?.header?.document_type_id ===
											DOCUMENT_INVOICE_TYPE.INVOICE
									}"
								>
									{{
										invoice?.header?.document_type_id ===
										DOCUMENT_INVOICE_TYPE.CREDIT_MEMO
											? 'CREDIT MEMO'
											: 'INVOICE'
									}}
								</p>
							</div>
						</div>
					</mat-card-header>
					<mat-card-content>
						<div fxLayout="row" class="entry-list">
							<div fxFlex class="entry-list-item">
								<label>Invoice Date</label>
								<p>
									{{ (invoice?.inv_date | caDate) || 'N/A' }}
								</p>
							</div>
							<div fxFlex class="entry-list-item">
								<label>Account</label>
								<p>
									{{ invoice?.acct_level_1 || 'N/A' }}
								</p>
							</div>
						</div>
						<div fxLayout="row" class="entry-list">
							<div fxFlex class="entry-list-item">
								<label>Due Date</label>
								<p>
									{{ (invoice?.due_date | caDate) || 'N/A' }}
								</p>
							</div>
							<div fxFlex class="entry-list-item">
								<label>Total Due</label>
								<p>
									{{
										(invoice?.tot_amt_due | caCurrency: invoice?.currency) ||
											'N/A'
									}}
								</p>
							</div>
						</div>
						<div fxLayout="row" class="entry-list">
							<div fxFlex class="entry-list-item">
								<label>AP Vendor Remit Address</label>
								<div>
									<p>
										{{
											invoice?.account?.vendor_remit_address
												?.vendor_location_id || 'N/A'
										}}
									</p>
									<p *ngIf="!invoice?.account?.vendor_remit_address">N/A</p>
									<p
										*ngIf="
											invoice?.account?.vendor_remit_address?.address?.address1
										"
									>
										{{
											invoice?.account?.vendor_remit_address?.address?.address1
										}}
									</p>
									<p>
										{{
											invoice?.account?.vendor_remit_address?.address?.city
										}}&nbsp;
										{{
											invoice?.account?.vendor_remit_address?.address
												?.subdivision?.abbreviation
										}}&nbsp;
										{{
											invoice?.account?.vendor_remit_address?.address
												?.postal_code
										}}
									</p>
									<p>
										{{
											invoice?.account?.vendor_remit_address?.address?.country
												?.name
										}}
									</p>
								</div>
							</div>
							<div fxFlex class="entry-list-item">
								<label>AP Invoice Number</label>
								<p>
									{{ invoice?.batch_item?.ap_invoice_number || 'N/A' }}
								</p>
							</div>
						</div>
						<div fxLayout="row" class="entry-list">
							<div fxFlex class="entry-list-item">
								<label>Bill Media Type</label>
								<p>
									{{ invoice?.account?.media_type?.value || 'N/A' }}
								</p>
							</div>
						</div>
					</mat-card-content>
				</mat-card>

				<!-- Invoice Information -->
				<mat-card fxFlex caCard>
					<mat-card-header> Invoice Information </mat-card-header>
					<mat-card-content>
						<div fxLayout="row" class="entry-list">
							<div fxFlex class="entry-list-item">
								<label>Vendor Entity</label>
								<p>
									{{ getVendorEntityName(invoice) }}
								</p>
							</div>
							<div fxFlex class="entry-list-item">
								<label>Status</label>
								<p>
									{{
										strInvoiceStatus
											? strInvoiceStatus
											: invoice?.header?.status?.value
									}}
								</p>
							</div>
						</div>
						<div fxLayout="row" class="entry-list">
							<div fxFlex class="entry-list-item">
								<label>Currency</label>
								<p>
									{{ invoice?.currency || 'N/A' }}
								</p>
							</div>
							<div fxFlex class="entry-list-item">
								<label>Region</label>
								<p>
									{{ invoice?.account?.region?.value || 'N/A' }}
								</p>
							</div>
						</div>
						<div fxLayout="row" class="entry-list">
							<div fxFlex class="entry-list-item">
								<label>Subclient</label>
								<p>
									{{ invoice?.account?.subclient?.value || 'N/A' }}
								</p>
							</div>
							<div fxFlex class="entry-list-item">
								<label>Invoice Owner</label>
								<p>
									{{ invoice?.owner?.first_name || 'System' }}
								</p>
							</div>
						</div>
						<div fxLayout="row" class="entry-list">
							<div fxFlex class="entry-list-item">
								<label>Approved By</label>
								<p>
									{{ invoice?.header?.approver?.full_name || 'N/A' }}
								</p>
								<p>{{ invoice?.header?.approved_at | caDateTime }}</p>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>

			<div fxLayout="column" fxFlex="60">
				<mat-card
					class="invoice-summary-card"
					caCard
					*ngIf="auditResultDetails.length"
				>
					<mat-card-content>
						<table mat-table [dataSource]="auditResultDetails">
							<ng-container matColumnDef="name">
								<th mat-header-cell *matHeaderCellDef>Audit Type</th>
								<td mat-cell *matCellDef="let element">
									<a
										href="#"
										(click)="navigateToAlert($event, element.alert_ids)"
										*ngIf="element.failed > 0; else label"
									>
										{{ element.name }}
									</a>
									<ng-template #label>
										{{ element.name }}
									</ng-template>
								</td>
							</ng-container>

							<ng-container matColumnDef="passed">
								<th mat-header-cell *matHeaderCellDef>Passed</th>
								<td
									mat-cell
									*matCellDef="let element"
									[ngClass]="{
										'badge-success': element.passed === element.total,
										'badge-warning':
											element.passed !== element.total && element.passed !== 0,
										'badge-danger': element.passed === 0
									}"
								>
									{{ element.passed }}/{{ element.total }}
								</td>
							</ng-container>

							<ng-container matColumnDef="failed">
								<th mat-header-cell *matHeaderCellDef>Failed</th>
								<td
									mat-cell
									*matCellDef="let element"
									[ngClass]="{
										'badge-success': element.failed === 0,
										'badge-danger': element.failed > 0
									}"
								>
									{{ element.failed }}/{{ element.total }}
								</td>
							</ng-container>

							<tr
								mat-header-row
								*matHeaderRowDef="['name', 'passed', 'failed']"
							></tr>
							<tr
								mat-row
								*matRowDef="let row; columns: ['name', 'passed', 'failed']"
							></tr>
						</table>
					</mat-card-content>
				</mat-card>

				<mat-card class="invoice-summary-card" caCard>
					<mat-card-content>
						<div fxLayout class="card-list-entry">
							<div fxFlex>
								<span>Balance Information</span>
							</div>
						</div>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Previous Balance</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.prev_bill_amt | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
							<div fxFlex="15%">&nbsp;</div>
						</div>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Payments Received</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.pmts_rcvd | caCurrency: invoice?.currency) || 'N/A'
								}}
							</div>
							<div fxFlex="15%">&nbsp;</div>
						</div>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Balance Forward Adjustments</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.bal_fwd_adj | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
							<div fxFlex="15%">&nbsp;</div>
						</div>
						<div fxLayout class="card-list-entry-bgr">
							<div fxFlex>
								<span>Balance Forward</span>
							</div>
							<div fxFlex="150px" class="right">
								{{ invoice?.currency }}
								{{
									(invoice?.bal_fwd | caCurrency: invoice?.currency) || 'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex>
								<span>New Charges</span>
							</div>
						</div>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Monthly Recurring Charges</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_mrc_chgs | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
							<div fxFlex="15%">&nbsp;</div>
						</div>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Other Charges & Credits</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_occ_chgs | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
							<div fxFlex="15%">&nbsp;</div>
						</div>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Usage Charges</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_usage_chgs | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
							<div fxFlex="15%">&nbsp;</div>
						</div>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Taxes / Surcharges</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_taxsur | caCurrency: invoice?.currency) || 'N/A'
								}}
							</div>
							<div fxFlex="15%">&nbsp;</div>
						</div>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Discounts</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_disc_amt | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
							<div fxFlex="15%">&nbsp;</div>
						</div>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Adjustments</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_new_chg_adj | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
							<div fxFlex="15%">&nbsp;</div>
						</div>

						<div fxLayout class="card-list-entry-bgr">
							<div fxFlex>
								<span>Total New Charges</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_new_chgs | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Adjustments (Amount Due)</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_amt_due_adj | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry-bgr">
							<div fxFlex>
								<span>Total Amount Due</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_amt_due | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex>
								<span>Payment Summary</span>
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Total New Charges</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_amt_due_with_adj
										| caCurrency: invoice?.currency) || 'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>User Adjustment</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.tot_adjman | caCurrency: invoice?.currency) || 0.0
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Balance Forward Adjustments</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.bal_fwd_adjbf | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Total Amount Due Adjustments</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(invoice?.total_amount_due_adjad
										| caCurrency: invoice?.currency) || 'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Withheld Dispute Amount</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(disputeWithheldAmount | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry-bgr">
							<div fxFlex>
								<span>Total Amount to be Paid</span>
							</div>
							<div fxFlex="150px" class="right">
								{{ invoice?.currency }}
								{{
									(this.totalAmountToBePaid | caCurrency: invoice?.currency) ||
										'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry-bgr">
							<div fxFlex>
								<span>Total Amount to be Booked to AP</span>
							</div>
							<div fxFlex="150px" class="right">
								{{ (invoice?.gl_amt | caCurrency: invoice?.currency) || 'N/A' }}
							</div>
						</div>
					</mat-card-content>
				</mat-card>

				<mat-card class="invoice-summary-card" caCard>
					<mat-card-header style="padding-left: 8px">
						Summary Dispute
					</mat-card-header>

					<mat-card-content>
						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Number of Disputes</span>
							</div>
							<div fxFlex="120px" class="right">
								{{ numOfDisputes || 0 }}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Billed Charges</span>
							</div>
							<div fxFlex="120px" class="right">
								{{ (billedCharges | caCurrency: invoice?.currency) || 'N/A' }}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Calculated Charges</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(calculatedCharges | caCurrency: invoice?.currency) || 'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Total Disputed Amount</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(totalDisputedAmount | caCurrency: invoice?.currency) || 'N/A'
								}}
							</div>
						</div>

						<div fxLayout class="card-list-entry">
							<div fxFlex="5%">&nbsp;</div>
							<div fxFlex>
								<span>Total Withheld Amount</span>
							</div>
							<div fxFlex="120px" class="right">
								{{
									(totalWithheldAmount | caCurrency: invoice?.currency) || 'N/A'
								}}
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
	</div>
	<mat-sidenav #panelSide [mode]="'side'" position="end" [opened]="false">
		<ca-notes
			#testNotes
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
			[entityId]="invoiceId"
			[entityType]="SYSTEM_MODULE.INVOICE"
			[charges]="selectedCharges"
			[chargesNote]="chargesNote"
			[selectedId]="selectedNoteId"
			(countChanged)="onNotesCountChanged($event)"
			(notesCountChanged)="getDNPInfo($event)"
			(noteCreated)="onNoteCreated()"
		>
			<ca-account-notes
				[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
				[entityId]="invoice?.account?.id"
				[entityType]="SYSTEM_MODULE.ACCOUNT"
				[entityName]="'Account'"
				[enableReply]="false"
			></ca-account-notes>
		</ca-notes>
		<ca-history
			#history
			[entityId]="invoiceId"
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }"
			[entityType]="'invoice'"
			[invoice]="invoice"
		></ca-history>
	</mat-sidenav>
</mat-sidenav-container>
