import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../core/core.module';
import { GLFiltersComponent } from './gl-filters.component';
import { GLStringFilterService } from '../gl-rule-execution/core/gl-string-filter.service';
import { GLRuleExecutionFilterService } from '../gl-rule-execution/core/gl-rule-execution-filter.service';
import { GLRuleExecutionFlatFilterService } from '../gl-rule-execution/core/gl-rule-execution-flat-filter.service';
import {FilterFeatureModule} from "../common/filter/filter-feature.module";

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        FilterFeatureModule
    ],
    exports: [
        GLFiltersComponent
    ],
    declarations: [
        GLFiltersComponent
    ],
    providers: [
        GLStringFilterService,
        GLRuleExecutionFilterService,
        GLRuleExecutionFlatFilterService
    ]
})
export class GLFiltersModule {
}
