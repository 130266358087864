import {Injectable} from '@angular/core';
import {BaseService} from "../core/base.service";

@Injectable()
export class SearchService extends BaseService<any> {

  constructor() {
    super('search');
  }

  search(q:string, offset:number) {
    return this.httpService().get('search', {
      q: q,
      offset: offset
    });
  }

}
