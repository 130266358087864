import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { GlDirectSelectGridDialogComponent } from 'app/gl-system-rules/shared/gl-direct-select-grid-dialog/gl-direct-select-grid-dialog.component';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Observable, Subject } from 'rxjs';
import { ChargeCsvInterceptor } from '../../../charge/core/charge-csv-interceptor';
import { ChargeQuery } from '../../../charge/core/charge.query';
import { InvoiceCharge } from '../../../charge/core/invoice-charge';
import { InvoiceChargeService } from '../../../charge/core/invoice-charge.service';
import { QueryBuilder } from '../../../common/query/query.builder';
import { GridSettingsComponent } from '../../../core/grid/grid-settings.component';
import Query from '../../../core/query/query';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { Sider, SiderSection, SiderSettings } from '../../../core/sider/sider';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { DisputeManageDialogComponent } from '../../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component';
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { HistoryComponent } from '../../../shared/history/history.component';
import { LoaderService } from '../../../shared/loader/loader.service';
import { SortingBuilder, SortingService } from '../../../shared/sorting/sorting.service';
import { UserService } from '../../../user/core/user.service';
import { InvoiceChargeFilterService } from '../../core/invoice-charge-filter.service';
import { InvoiceChargeGridService } from '../../core/invoice-charges-grid.service';
import { InvoiceFacepage } from '../../core/invoice-facepage';
import { InvoiceFacepageService } from '../../core/invoice-facepage.service';
import { InvoiceFlowService } from '../../core/invoice-flow.service';
import { INVOICE_STATUS_ENUM } from '../../core/invoice-status.enum';
import { LoadSingleInvoice } from '../state/invoice-details.actions';
import { UpdateActiveSiderSection, UpdateInvoiceChargesQuery } from './state/invoice-charges.actions';
import { switchMap, takeUntil } from 'rxjs/operators';
import { InvoiceFlowHandleService } from '../../core/invoice-flow-handle.service';
import { InvoiceCreateChargeForAdjustmentDialogComponent } from '../../shared/invoice-create-charge-for-ajustment-dialog/invoice-create-charge-for-adjustment-dialog.component';
import { InvoiceDeleteChargeDialogComponent } from '../../shared/invoice-delete-charge-dialog/invoice-delete-charge-dialog.component';
import { CreateInvoiceChargeProfileDialogComponent } from 'app/invoice/shared/create-invoice-charge-profile-dialog/create-invoice-charge-profile-dialog.component';
import { InventoryService } from 'app/inventory/core/inventory.service';
import { get } from 'lodash';

@Component({
	selector: 'app-invoice-charges',
	templateUrl: './invoice-charges.component.html',
	styleUrls: ['./invoice-charges.component.scss']
})
export class InvoiceChargesComponent implements OnInit, AfterViewInit {
	private chargesColumnsSorting: any[];
	private chargesSorting: SortingBuilder;
	private delayChargesSelectionChange = false;
	private gridSettings: any;

	readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
	readonly COMPONENT_NAME = 'invoice-charges';

	canChangeAdjustment = false;
	invoiceStatusEnum = INVOICE_STATUS_ENUM;

	readonly SECTIONS = {
		FILTER_SECTION_NAME: 'filter',
		DETAILS_SECTION_NAME: 'details',
		HISTORY_SECTION_NAME: 'history',
		NOTES_SECTION_NAME: 'notes'
	};
	invoice: InvoiceFacepage;
	charges: InvoiceCharge[] = [];
	selectedCharges: InvoiceCharge[] = [];
	chargesSelectionActive: boolean = false;
	chargesColumns: any;
	sider: Sider;
	query: ChargeQuery = new ChargeQuery({ orderBy: [['id', 'ASC']] });
	defaultGridPager: any;

	messages: IMessagesResourceService;
	readonly MESSAGES_MODULE: string = 'invoice';
	selectedNoteId: number;
	chargesNote: boolean;
	notesCount = 0;

	isAdjustment = true;

	/** Toolbar variables */
	isDoNotProcessDisabled = true;
	isAddDisputeDisabled = true;
	isDeleteInvoiceDisabled = true;
	isManualAdjustmentDisabled = true;
	isGLAddDisabled = true;
	isGLEditDisabled = true;
	isDeleteChargesDisabled = true;
	gridLimit: number = 20;

	destroy$: Subject<boolean> = new Subject<boolean>();

	@ViewChild('panelSide') panelSide;
	@ViewChild('history') history: HistoryComponent;
	@ViewChild('chargesGrid') chargesGrid: DxDataGridComponent;

	@Select((state) => state.invoice_charges.query) $query: Observable<Query>;
	@Select((state) => state.invoice_charges.activeSiderSection)
	$activeSiderSection: Observable<any>;
	@Select((state) => state.invoice_details.filter) $filter: Observable<any>;

	constructor(
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly store: Store,
		private readonly queryBuilder: QueryBuilder,
		private readonly chargeService: InvoiceChargeService,
		private readonly sortingService: SortingService,
		private readonly invoiceFacepageService: InvoiceFacepageService,
		public invoiceChargeFilterService: InvoiceChargeFilterService,
		public invoiceChargeGridService: InvoiceChargeGridService,
		public dialogService: DialogService,
		public alertService: AlertService,
		public loaderService: LoaderService,
		public invoiceFlowService: InvoiceFlowService,
		public userService: UserService,
		public inventoryService: InventoryService,
		public invoiceFlowHandleService: InvoiceFlowHandleService
	) {
		this.chargesSorting = this.sortingService.builder();
		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

		this.invoiceFacepageService.invoiceChange.pipe(takeUntil(this.destroy$)).subscribe((invoice) => {
			this.invoice = invoice;
			this.selectedCharges = [];
			this.history.refreshList();
		});
	}

	ngOnInit(): void {
		this.invoice = this.getInvoice();
		this.query.where.invoice_id = this.invoice.invoice_id;

		this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => this.onQueryParamsChange(params));
		this.$query.pipe(takeUntil(this.destroy$)).subscribe((query) => this.onQueryChange(query));

		this.isDoNotProcessDisabled = this.invoiceFacepageService.isDoNotProcessDisabled(this.invoice);
		this.isDeleteInvoiceDisabled = !this.invoiceFacepageService.isDeleteEligible(this.invoice);
		this.isManualAdjustmentDisabled = this.isAddManualAdjustmentDisabled();
	}

	ngAfterViewInit(): void {
		this.sider = this.createSider();

		setTimeout(() => {
			//** Charges grid */
			this.invoiceChargeGridService.create(this.chargesGrid.instance, {
				noDataText: this.invoiceChargeGridService.noDataMessage,
				selection: {
					mode: 'multiple'
				}
			});
		});
	}

	onPageChange(query) {
		this.refresh(query);
	}

	onChargesCellClick(event: any) {
		if (event.rowType === 'header' && event.column.allowSorting) {
			this.chargesSorting.apply(event, this.query);
			this.refresh(this.query);
		}
	}

	filterData(query: ChargeQuery) {
		// delete necessary to make filter and clear hash equal if no filter params are present
		delete query.where['invoice_id'];
		this.queryBuilder.buildQueryAndNavigate({
			component: this,
			query,
			route: this.activatedRoute,
			queryBuilder: ChargeQuery,
			queryToSet: undefined
		});
	}

	clearFilter() {
		const query = new ChargeQuery({ orderBy: [['id', 'ASC']] });
		this.queryBuilder.buildQueryAndNavigate({
			component: this,
			query,
			route: this.activatedRoute,
			queryBuilder: ChargeQuery,
			queryToSet: undefined
		});
	}

	loadSelectedCharges() {
		let query = new ChargeQuery(this.queryBuilder.getQuery());
		query.limit = this.query.limit;
		query.orderBy = this.query.orderBy;
		if (this.chargesSelectionActive) {
			this.query.remove('id');
			this.query = query;
		} else {
			this.query.where = {};
			this.query.where.invoice_id = this.invoice.invoice_id;
			this.query.set('id', {
				$in: this.selectedCharges.map((x) => {
					return x.id;
				})
			});
		}

		this.query.offset = 0;
		this.query.page = 1;
		this.chargesSelectionActive = !this.chargesSelectionActive;
		this.refresh(this.query);
	}

	goToDispute(dispute_id) {
		if (dispute_id) {
			this.router.navigate(['/dispute', dispute_id, 'show']);
		}
	}

	toggleNotes(chargesNote?: boolean, charge?: InvoiceCharge) {
		this.selectedCharges = [charge];
		this.chargesGrid.instance.selectRows(this.selectedCharges, false);

		if (!this.sider.isActive(this.SECTIONS.NOTES_SECTION_NAME)) {
			this.sider.toggle(this.SECTIONS.NOTES_SECTION_NAME);
			return true;
		} else {
			return false;
		}
	}

	onChargesSelectionChanged(event) {
		if (!this.delayChargesSelectionChange && this.charges.length) {
			// ** Deselected rows */
			if (event.currentDeselectedRowKeys.length) {
				event.currentDeselectedRowKeys.forEach((item) => {
					let index = this.findIndexInSelectedCharges(item.id);
					if (index > -1) {
						this.selectedCharges.splice(index, 1);
					}
				});
			}

			// ** Selected rows */
			if (event.currentSelectedRowKeys.length) {
				event.currentSelectedRowKeys.forEach((item) => {
					let index = this.findIndexInSelectedCharges(item.id);
					if (index === -1) {
						this.selectedCharges.push(item);
					}
				});
			}

			// * Dispute Add enabled/disabled */
			if (this.selectedCharges.length) {
				this.isAddDisputeDisabled = false;
				this.selectedCharges.forEach((charge) => {
					if (charge['charge_dispute']) {
						this.isAddDisputeDisabled = true;
					}
				});
			} else {
				this.isAddDisputeDisabled = true;
			}

			this.isGLAddDisabled = this.isAddGLDisabled();
			this.isGLEditDisabled = this.isEditGLDisabled();
			this.isDeleteChargesDisabled = this.isChargeTypeValid();
			if (this.chargesSelectionActive && !this.selectedCharges.length) {
				this.loadSelectedCharges();
			}
			this.checkSpecialChargeType(['adjbf', 'adjad']);
		}
	}

	private onQueryParamsChange(params: Params) {
		if (Object.keys(params).length === 0) {
			this.query = new ChargeQuery({
				where: {
					invoice_id: this.invoice.invoice_id
				}
			});
		}
		const query = this.queryBuilder.build(this.query, null, this.COMPONENT_NAME).getQuery();
		this.store.dispatch([new UpdateInvoiceChargesQuery({ query, key: params.key })]);
	}

	private onQueryChange(query: Query) {
		this.query = new ChargeQuery(query);

		if (this.activatedRoute.snapshot.routeConfig.path === 'charges-adj') {
			this.query.where = {
				...this.query.where,
				invoice_id: this.invoice.invoice_id,
				chg_class: { $in: ['ADJAD', 'ADJBF'] },
				$or: [
					{ include_in_amount_due_status: null },
					{
						include_in_amount_due_status: LOOKUP_ENUM.UNSET_ADJUSTMENTS.NONE
					}
				]
			};
		} else {
			this.query.where = {
				...this.query.where,
				invoice_id: this.invoice.invoice_id
			};
		}

		this.prepareList();
	}

	public prepareList() {
		this.invoiceChargeGridService.loadSettings().subscribe((settings) => {
			this.initializeGridColumns(settings);
		});
	}

	private getInvoice() {
		return this.activatedRoute.parent.snapshot.data.invoice;
	}

	/// MERGE // audit release
	private refresh(query: ChargeQuery) {
		this.query = query;
		this.loaderService.displayLoaderAndManageGrid([this.chargesGrid]);
		this.delayChargesSelectionChange = true;
		this.chargeService.largeRequestFindAll(this.query).subscribe((result) => {
			this.charges = result.items;
			this.query.total = result.total;
			this.loaderService.hideLoaderAndManageGrid([this.chargesGrid]);
			if (this.charges.length) {
				setTimeout(() => {
					const results = this.charges.filter(({ id: id1 }) => this.selectedCharges.some(({ id: id2 }) => id2 === id1));
					this.notesCount = this.charges.filter((charge) => charge.note_charges.length > 0).length;
					this.delayChargesSelectionChange = false;
					this.chargesGrid.instance.selectRows(results, false);
				}, 500);
			}
		});
	}

	private createSider() {
		const sider = new Sider(
			new SiderSettings(
				[
					new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
					new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
					new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
					new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME)
				],
				this.panelSide
			)
		);

		this.$activeSiderSection.subscribe((sectionName) => {
			if (sectionName && sectionName !== 'none') {
				setTimeout(() => {
					this.sider.open(sectionName);
				});
			}
		});

		return sider;
	}

	private initializeGridColumns(settings) {
		this.gridSettings = settings;
		this.chargesColumnsSorting = settings ? settings.sorting : [];
		this.chargesColumns = this.invoiceChargeGridService.getColumns(settings && settings.columns ? settings.columns : []);

		this.defaultGridPager = settings ? settings.gridPager : 20;
		this.query.limit = this.defaultGridPager;

		if (this.chargesColumnsSorting && this.chargesColumnsSorting.length) {
			this.query.orderBy = this.invoiceChargeGridService.getOrderBy(this.chargesColumnsSorting);
		}

		if (this.chargesGrid && this.chargesGrid.instance) {
			this.chargesGrid.instance.option('columns', this.chargesColumns);
		}

		this.refresh(this.query);
	}

	private checkSpecialChargeType(typeArray: string[]) {
		const foundChargeTypes = this.selectedCharges.filter((charge) => !typeArray.includes(charge.chg_class.trim().toLowerCase()));
		this.canChangeAdjustment = foundChargeTypes.length === 0;
	}

	private findIndexInSelectedCharges(chargeId): number {
		for (let i = 0, l = this.selectedCharges.length; i < l; i++) {
			if (this.selectedCharges[i].id === chargeId) {
				return i;
			}
		}
		return -1;
	}

	toggleSider(sectionName: string) {
		this.sider.toggle(sectionName);
		const activeSection = this.sider.getActiveSection();
		this.store.dispatch([new UpdateActiveSiderSection(activeSection.name)]);
	}

	/** Toolbar actions */
	onDownloadCSVRequested() {
		const chargeTransformMiddleware = (items) => {
			const chargeCSVInterceptor = new ChargeCsvInterceptor();
			return items.map((item) => chargeCSVInterceptor.transform(item));
		};

		this.invoiceChargeGridService.csvMap().subscribe((fieldsCharge) => {
			this.chargeService.exportToCSV(`${this.invoice?.sp_inv_num}_charges`, {
				fields: fieldsCharge,
				query: this.query,
				middleware: [chargeTransformMiddleware]
			});
		});
	}

	onOpenGridSettingsRequested() {
		this.dialogService
			.open(GridSettingsComponent, {
				service: this.invoiceChargeGridService,
				settings: this.gridSettings,
				sliderMinimum: 1
			})
			.afterClosed()
			.pipe(switchMap(() => this.invoiceChargeGridService.loadSettings()))
			.subscribe((settings) => {
				if (settings) {
					this.resetDataPager();
					this.initializeGridColumns(settings);
				}
			});
	}

	public resetDataPager() {
		this.defaultGridPager = 20;
	}

	/* Notes count changed */
	onNotesCountChanged(count: number) {
		this.notesCount = count;
	}

	/* View note charges */
	onViewNoteCharges(charges) {
		if (charges && charges.length) {
			let chargeIds: Array<number> = charges.map((item) => {
				return item.id;
			});
			this.query['where'] = {};
			this.query.set('id', { $in: chargeIds });
			this.query.offset = 0;
			this.query.page = 1;
			this.query.limit = 1000;

			/* additional load to populate this.selectedCharges since DX grid use whole row item as row key */
			this.chargeService.findAll(this.query).subscribe(
				(result) => {
					this.selectedCharges = result.items; // populate selected charges
					this.query.limit = 20; // set query.limit to default
					this.chargesSelectionActive = true; // activate selection mode
					this.refresh(this.query);
				},
				(err) => {
					this.alertService.error('', this.messages.get('CHARGE_LOAD_ERROR'));
				}
			);
		}
	}

	onNoteCreated() {
		this.chargesSelectionActive ? this.loadSelectedCharges() : this.refresh(this.query);

		this.selectedCharges.length = 0;
	}

	addDispute() {
		if (!this.selectedCharges.length || this.isAddDisputeDisabled) {
			return;
		}
		this.dialogService
			.open(
				DisputeManageDialogComponent,
				{
					charges: this.selectedCharges,
					invoice: this.invoice
				},
				{ width: '60%' }
			)
			.afterClosed()
			.subscribe(async (result) => {
				if (result) {
					// display disputes icon on included charges
					this.selectedCharges.forEach((charge) => {
						charge['charge_dispute'] = { dispute_id: result.id };
					});
					// clear selection
					this.selectedCharges = [];
					this.history.refreshList();
				}
			});
	}

	manageDirectGL(isUpdate = false) {
		return this.dialogService
			.open(
				GlDirectSelectGridDialogComponent,
				{
					charges: this.selectedCharges,
					invoice: this.invoice,
					isUpdate
				},
				{ width: '60%' }
			)
			.afterClosed()
			.subscribe((result) => {
				if (result && !result.cancel) {
					this.alertService.success('', this.messages.get(result.deleted ? 'MANUAL_GL_DELETED' : 'MANUAL_GL_ASSIGN_SUCCESS'));
					this.selectedCharges = [];
					this.isGLEditDisabled = this.isEditGLDisabled();
					this.refresh(this.query);
				}
			});
	}

	// Disabled adding dispute if info only charge is selected
	isInfoOnlyChargeSelected(rows: Array<InvoiceCharge>) {
		return rows.some((r) => r.info_only_ind === 'Y');
	}

	directGLAddEnabledForSelection(rows: Array<InvoiceCharge>) {
		if (!rows.length) {
			return false;
		}
		return !rows.some((r) => !!r.direct_gl_stamp || this.isUnsetAdjustment(r));
	}

	directGLEditEnabledForSelection(rows: Array<InvoiceCharge>) {
		if (!rows.length) {
			return false;
		}
		return rows.some((r) => !!r.direct_gl_stamp);
	}

	isUnsetAdjustment(row) {
		return (row.chg_class === 'adjad' || row.chg_class === 'adjbf') && row.include_in_amount_due_status !== LOOKUP_ENUM.UNSET_ADJUSTMENTS.INCLUDED;
	}

	isGLBatchStatus() {
		if (this.invoice && this.invoice.header) {
			return this.invoice.header.status_code >= LOOKUP_ENUM.INVOICE_STATUS.GL_BATCH_OUTPUT;
		}
	}

	isAddGLDisabled() {
		return (
			this.isInfoOnlyChargeSelected(this.selectedCharges) ||
			!this.directGLAddEnabledForSelection(this.selectedCharges) ||
			(this.selectedCharges && this.selectedCharges.length > 1) ||
			this.isGLBatchStatus()
		);
	}

	isEditGLDisabled() {
		return (
			this.isInfoOnlyChargeSelected(this.selectedCharges) ||
			!this.directGLEditEnabledForSelection(this.selectedCharges) ||
			(this.selectedCharges && this.selectedCharges.length > 1) ||
			this.isGLBatchStatus()
		);
	}

	changeAdjustment(id) {
		this.chargeService.changeAdjustment(this.selectedCharges, id).subscribe((result) => {
			if (result) {
				this.selectedCharges = [];
				this.store.dispatch(new LoadSingleInvoice(this.invoice.invoice_id));

				this.chargesGrid.instance.refresh();
				this.loadCharges(this.invoice, this.query);
				this.alertService.success('', this.messages.get('CHANGE_ADJUSTMENT_SUCCESS'));
				this.history.refreshList();
			}
		});
	}

	goToInventory(event: PointerEvent, data: any) {
		event.preventDefault();
		event.stopPropagation();

		const inventoryId = get(data, 'inventory.id');
		if (inventoryId !== undefined) {
			this.router.navigate(['inventory', inventoryId, 'show', 'summary']);
		}
	}

	public loadCharges(invoice: InvoiceFacepage, chargeQuery?: any) {
		// prevents auto-deselection of selected rows on data-set change
		// (dx grid calls to onSelectionChanged are not predictable and depends on client machine speed - how fast grid will be rendered)
		this.delayChargesSelectionChange = true;
		this.charges = [];
		this.query = chargeQuery || this.query;

		this.loaderService.displayLoaderAndManageGrid([this.chargesGrid]);
		let loadAdj = true;
		if (this.isAdjustment && loadAdj) {
			this.invoiceFacepageService.findChargesWithoutAdj(invoice.invoice_id).subscribe((res) => {
				if (!res.length) {
					this.chargesSelectionActive = false;
					const query = new ChargeQuery(this.queryBuilder.getQuery());
					query.limit = this.query.limit;
					this.refresh(query);
					this.loaderService.hideLoaderAndManageGrid([this.chargesGrid]);
					this.alertService.success('', this.messages.get('CHANGE_ADJUSTMENT_ALL_SUCCESS'));
					const status = this.invoiceStatusEnum.GL_PENDING;
					this.invoiceFacepageService
						.updateStatus(invoice.invoice_id, { status })
						.pipe(
							switchMap(() => {
								this.invoiceFlowHandleService.updateSteps(status);

								return this.invoiceFacepageService.processMultipleInvoices([this.invoice.invoice_id]);
							})
						)
						.subscribe();
				} else {
					this.chargesSelectionActive = false;
					const query = new ChargeQuery(this.queryBuilder.getQuery());
					query.limit = this.query.limit;
					this.refresh(query);
					this.loaderService.hideLoaderAndManageGrid([this.chargesGrid]);
				}
			});
		}
	}

	back() {
		if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
			history.back();
		} else {
			this.router.navigate(['/invoice']);
		}
	}

	createNewRateAudit() {
		const chargesIds = [];
		const vendorId = this.invoice.vendor_id;
		const invoiceIds = [this.invoice.invoice_id];
		this.selectedCharges.forEach((charge) => {
			chargesIds.push(charge.id);
		});

		const navigationExtras: NavigationExtras = {
			state: {
				chargeIds: chargesIds,
				invoiceIds: invoiceIds,
				vendorId,
				fromPlace: 'invoice',
				selectContractIfOne: true
			}
		};
		this.router.navigate(['audit', 'rate-audit'], navigationExtras);
	}

	loadInvoice(id: number): Observable<InvoiceFacepage> {
		return this.invoiceFacepageService.findById(id);
	}

	addNewCharge() {
		this.dialogService
			.open(
				InvoiceCreateChargeForAdjustmentDialogComponent,
				{
					title: 'Add Manual Adjustment',
					invoice: this.invoice
				},
				{ width: '920px', height: '540px' }
			)
			.afterClosed()
			.subscribe(async (data) => {
				if (data) {
					this.alertService.success('', this.messages.get('CHARGE_CREATE_SUCCESS'));
					this.refresh(this.query);
					this.history.refreshList();
					this.invoiceFlowHandleService.onStatusChange.emit(this.invoice.header.status_code);
				}
			});
	}

	addInvoiceChargeProfile() {
		this.dialogService
			.open(
				CreateInvoiceChargeProfileDialogComponent,
				{
					title: 'Add Charges to Charge Profile',
					charges: this.selectedCharges
				},
				{
					width: '920px',
					height: '540px'
				}
			)
			.afterClosed()
			.subscribe(async (response) => {
				if (!response) return;

				if (!response?.data) return this.alertService.error('', this.messages.get('CHARGE_PROFILE_CREATE_FAIL'));

				this.alertService.success('', this.messages.get('CHARGE_PROFILE_CREATE_SUCCESS'));
				this.refresh(this.query);
				this.history.refreshList();
				this.invoiceFlowHandleService.onStatusChange.emit(this.invoice.header.status_code);
			});
	}

	isAddManualAdjustmentDisabled() {
		if (this.invoice && this.invoice.header) {
			return this.invoice.header.status_code < LOOKUP_ENUM.INVOICE_STATUS.NEW || this.invoice.header.status_code > LOOKUP_ENUM.INVOICE_STATUS.GL_CODED_FULL;
		}
	}

	isAdjmanChargeSelected(rows: Array<InvoiceCharge>) {
		return rows.every((r) => r.chg_class.toLowerCase() === LOOKUP_ENUM.CHARGE_TYPE_KEY['ADJMAN'].toLowerCase());
	}
	isChargeTypeValid() {
		return this.selectedCharges.length && !this.isAdjmanChargeSelected(this.selectedCharges);
	}
	deleteCharges() {
		this.dialogService
			.open(InvoiceDeleteChargeDialogComponent, {
				title: 'Delete charges',
				charges: this.selectedCharges
			})
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					//Restart selected charges
					this.selectedCharges = [];
					//Disable delete icon
					this.isDeleteChargesDisabled = true;
					this.alertService.success('', this.messages.get('CHARGE_DELETE_SUCCESS'));
					this.refresh(this.query);
					this.history.refreshList();
					this.invoiceFlowHandleService.onStatusChange.emit(this.invoice.header.status_code);
				}
			});
	}
	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
