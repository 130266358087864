import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MasterRoutingModule } from "./master-routing.module";
import { MasterComponent } from "./master.component";
import { MaterialModule } from "app/material-barrel/material-barrel.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DxTemplateModule } from "devextreme-angular";
import { DxListModule } from "devextreme-angular/ui/list";
import { SharedModule } from "../shared/shared.module";
import { NavigationComponent } from "../shared/navigation/navigation.component";
import { UserSettingsResolve } from "../user/core/user-settings.resolve";
import { ThemeModule } from "../theme/theme.module";
import { UserModule } from "../user/user.module";
import { ConfigResolve } from "../core/config/config.resolve";
import { SearchModule } from "../search/search.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MasterGuardService } from "../core/security/master-guard.service";

@NgModule({
  imports: [
    CommonModule,
    UserModule,
    MasterRoutingModule,
    DxListModule,
    SharedModule,
    DxTemplateModule,
    MaterialModule,
    FlexLayoutModule,
    ThemeModule,
    SearchModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [MasterComponent, NavigationComponent],
  providers: [UserSettingsResolve, ConfigResolve, MasterGuardService], ///// GORAN
})
export class MasterModule {}
