<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-details-title">{{ account?.account_no }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

  <button *permission="'Modify'" mat-icon-button matTooltip="Edit" (click)="edit()">
    <ca-icon [key]="'EDIT'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()" [disabled]="!tabGroup.isActive(2)">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Settings" [disabled]="!tabGroup.isActive(2) || isGridSettingsDisabled" (click)="gridSettings()">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [ngClass]="{'on' : sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2)}" [disabled]="!tabGroup.isActive(2)"
    (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME); activeSiderChanged = sider.getActiveSection()" mat-icon-button
    matTooltip="Filter">
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>

  <button [ngClass]="{'on' : sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}" 
    (click)="sider?.toggle(SECTIONS.NOTES_SECTION_NAME); activeSiderChanged = sider.getActiveSection()"
    mat-icon-button matTooltip="Notes">
    <ca-icon [key]="notesCount > 0 ? 'NOTES' : 'NOTES_EMPTY'"></ca-icon>
  </button>

  <button [ngClass]="{'on' : sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}" (click)="sider?.toggle(SECTIONS.HISTORY_SECTION_NAME); activeSiderChanged = sider.getActiveSection()"
    mat-icon-button matTooltip="History">
    <ca-icon [key]="'HISTORY'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content tabbed-content">

  <mat-tab-group (selectedIndexChange)="onTabChange($event)">
    <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs">
    </mat-tab>
  </mat-tab-group>

  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
    <div class="flex-container ca-card custom-mat-card-container-large" fxLayout="row"><!--fxLayout.sm="column"-->
      <div fxFlex="45%" >
        <mat-card style="height: calc(100% - 16px);" caCard>
          <mat-card-header>
            Account Info
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" class="entry-list" *ngIf="account?.on_hold">
              <div fxFlex="70%" class="entry-list-item" *ngIf="account?.on_hold">
              </div>
              <div fxFlex class="entry-list-item" class="account-badge" *ngIf="account?.on_hold">
                <p class="switch-button status-badge" [ngClass]="{'btn-disabled' : account?.on_hold}" *ngIf="account?.on_hold">
                  {{ "ON HOLD" }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex="70%" class="entry-list-item">
                <label>Account No</label>
                <p>
                  {{ account?.account_no }}
                </p>
              </div>
              <div fxFlex class="entry-list-item" class="account-badge">
                <p class="switch-button status-badge" [ngClass]="{ 'btn-enabled' : account?.status_id == ACCOUNT_STATUS_ENUM.ACTIVE,
                              'btn-disabled' : account?.status_id == ACCOUNT_STATUS_ENUM.INACTIVE }">
                  {{ account?.status?.value }}
                </p>
              </div>

            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex="70%" class="entry-list-item">
                <label>Vendor</label>
                <p>
                  {{ account?.vendor?.name }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex="70%" class="entry-list-item">
                <label>Entity</label>
                <p>
                  {{ account?.entity?.name || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Payment method</label>
                <p>
                  {{ account?.payment_method?.value || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex="70%" class="entry-list-item">
                <label>Remit Address</label>
                <div>
                  <p *ngIf="!account?.vendor_remit_address">N/A</p>
                  <p> {{account?.vendor_remit_address?.vendor_location_id}} </p>
                  <p *ngIf="account?.vendor_remit_address?.address?.address1">
                    {{ account?.vendor_remit_address?.address?.address1}}
                  </p>
                  <p>
                    {{ account?.vendor_remit_address?.address?.city}}&nbsp; {{ account?.vendor_remit_address?.address?.subdivision?.abbreviation}}&nbsp;
                    {{ account?.vendor_remit_address?.address?.postal_code}}
                  </p>
                  <p>
                    {{ account?.vendor_remit_address?.address?.country?.name}}
                  </p>
                </div>
              </div>
              <div>
                <div fxFlex class="entry-list-item">
                  <label>Subclient</label>
                  <p *ngIf="!account?.subclient">N/A</p>
                  <p *ngIf="account?.subclient">
                    {{account?.subclient.value}}
                  </p>
                </div>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
                  <div fxFlex="70%" class="entry-list-item">
                    <label>Late Bill Lag Days</label>
                    <p>
                      {{ account?.late_bill_lag_days }}
                    </p>
                  </div>
                  <div fxFlex class="entry-list-item">
                    <label>Billing Cycle</label>
                    <p>
                      {{ account?.billing_cycle || 'N/A' }}
                    </p>
                  </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex="70%" class="entry-list-item">
                <label>Audit threshold</label>
                <p>
                  {{ account?.audit_threshold || '20' }}%
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Media Type</label>
                <p>
                  {{ account?.media_type?.value || 'N/A' }}
                </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex="70%" class="entry-list-item">
                <label>Billing Frequency</label>
                <p>
                  {{ account?.billing_frequency?.value || 'N/A' }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Country</label>
                <p>
                  {{ account?.country?.alpha_2_code || account?.country?.name || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex="70%" class="entry-list-item">
                  <label>Currency</label>
                  <p>
                    {{ account?.currency_obj?.currency || 'N/A' }}
                  </p>
                </div>
              <div fxFlex class="entry-list-item">
                <label>Region</label>
                <p>
                  {{ account?.region?.value || 'N/A' }}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex="99%" class="entry-list-item">
                <label>Service Period Range</label>
                  <p *ngIf="!(account?.svc_pd_month?.value && account?.svc_pd_range?.value)">N/A</p>
                  <p *ngIf="account?.svc_pd_month?.value && account?.svc_pd_range?.value">
                    {{ account.svc_pd_month.value }} {{account.svc_pd_range.value }}
                  </p>
              </div>
            </div>

            <div fxLayout="row" class="entry-list">
              <div fxFlex="99%" class="entry-list-item">
                <label>Description</label>
                <p class="entity-description" matTooltip="{{account?.acct_desc}}" matTooltipPosition="above" matTooltipHideDelay="1000">
                  {{ account?.acct_desc || 'N/A'}}
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        </div>

        <div fxFlex="55%">
            <mat-card style="height: calc(100% - 16px);" class="chart-container" caCard>
              <mat-card-header>Invoice Spend Trend</mat-card-header>
              <mat-card-content style="height: calc(100% - 40px);">
                <app-invoice-trend style="height: 100%;" *ngIf="ready" [siderActive]="activeSiderChanged" [showLabels]="true" [account]="account"></app-invoice-trend>
              </mat-card-content>
            </mat-card>
          </div>

      </div>
    </div>

      <mat-sidenav-container [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}" class="app-content app-content-list dx-nodata-absolute">
        <div class="tabs-body" fxLayout="column"></div>
          <dx-data-grid #grid
          [showBorders]="true"
          (onRowClick)="onRowClick($event)"
          [columns]="columns"
          [height]="'100%'"
          [width]="'100%'"
          class="app-grid-with-pager"
          (onCellClick)="onCellClick($event)"
          [dataSource]="invoices">

        <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
          <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.currency }}</div>
        </div>

          <div *dxTemplate="let d of 'dateTemplate'">
            <div caGridTooltip>{{ d.value | caDate }}</div>
          </div>

        <div *dxTemplate="let d of 'batchIdTemplate'">
          <div caGridTooltip>{{ d.value }}</div>
        </div>
      </dx-data-grid>

      <ca-pager [gridPager]="defaultGridPager" [pageContext]="invoiceAccountGridService" [query]="query" class="app-pager ca-default-border-top" (onChange)="account ? loadInvoices(query) : null"></ca-pager>

</mat-sidenav-container>

  <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false">

    <ca-notes [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}" [entityId]="account?.id" [entityType]="SYSTEM_MODULE.ACCOUNT"
      (countChanged)="onNotesCountChanged($event)"></ca-notes>

    <ca-history #history
                [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
                [entityId]="account?.id"
                [entityType]="'account'"
    ></ca-history>
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="accountDetailsFilterService"
                (filterRequested)="filterInvoices($event)"
                (clearFilterRequested)="clearInvoiceFilter($event)">
    </new-app-filter>
  </mat-sidenav>

</mat-sidenav-container>
