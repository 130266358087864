import { FlowStep } from './flow-step';

export class Flow {
	name: string;
	currentStep = 1;
	currentStatus;
	steps: Array<FlowStep> = [];
	linear = true;
	srm = false;

	constructor(data?: any) {
		Object.assign(this, data);
	}

	addStep(step: FlowStep) {
		this.steps.push(step);
	}

	removeStep(step, places = 1) {
		this.steps.splice(step, places);
	}

	canNext() {
		return !this.isLastStep();
	}

	canPrevious() {
		return !this.isFirstStep();
	}

	next() {
		if (this.canNext()) {
			this.currentStep++;
		}
	}

	previous() {
		if (this.canPrevious()) {
			this.currentStep--;
		}
	}

	isCurrentStep(stepNumber: number) {
		return this.currentStep === stepNumber;
	}

	isLastStep() {
		return this.steps.length === this.currentStep;
	}

	isFirstStep() {
		return this.currentStep === 1;
	}

	getLastStep() {
		return this.steps[this.steps.length];
	}

	isCurrentOrGreaterStep(stepCode: number) {
		const { currentStep, steps } = this;
		const doNotProcessStep = steps.find(
			(stepItem) => 'Do Not Process' === stepItem.name
		);
		if (doNotProcessStep && currentStep === doNotProcessStep.code) {
			return (
				this.currentStep >= stepCode &&
				(this.isStepDoNotProcess(stepCode) || stepCode === 1)
			);
		} else {
			return this.currentStep >= stepCode;
		}
	}

	isCompletedStep(stepCode: number) {
		if (!this.linear) {
			const retVal = this.steps.filter((step) => {
				return step.code === stepCode;
			})[0].completed;
			return retVal;
		} else {
			if (this.srm) {
				return this.currentStep > stepCode;
			} else {
				return this.currentStep >= stepCode;
			}
		}
	}

	handleSelection(step: FlowStep) {}

	setStep(step: number | FlowStep) {
		if (step instanceof FlowStep) {
			return (this.currentStep = step.code);
		}
		return (this.currentStep = step);
	}

	isStepAvailableAsNext(stepCode: number) {
		const step = this.steps.find((item) => item.code === stepCode);
		const isStepAvailable = step
			? !step.disabled && this.currentStep + 1 === stepCode
			: this.currentStep + 1 === stepCode;

		let currentStep = this.steps.find((item) => item.code === this.currentStep);
		if (currentStep) {
			return (
				isStepAvailable &&
				![
					'INVOICE_FLOW_ERROR',
					'INVOICE_FLOW_PENDING',
					'INVOICE_FLOW_WARNING'
				].includes(currentStep.icon)
			);
		}

		return isStepAvailable;
	}

	// Checks if current step is with label 'Cancelled'
	isStepNameCancelled(step: FlowStep) {
		let s = this.steps.filter((item) => item.code === this.currentStep);
		if (s.length && s[0].name == 'Cancelled') return true;
		return false;
	}

	// Checks if user can go to next step
	canGoNextStep(step: number) {
		return this.areStepsInBetweenOptional(step)
			? this.areStepsInBetweenOptional(step)
			: !this.canActivateStep(step);
	}

	// Checks if steps between current active step, and clicked on step, have optional steps in between
	private areStepsInBetweenOptional(step: number) {
		return (
			this.isNextStepOptionalOfCurrentActive() &&
			this.isPreviousStepOptionalOfSelectedStep(step)
		);
	}

	private canActivateStep(step: number) {
		return this.currentStep < step && !this.isStepAvailableAsNext(step);
	}

	// Is previous step of clicked on step optional
	private isPreviousStepOptionalOfSelectedStep(step: number) {
		const { steps } = this;
		const selectedStepIndex = steps.findIndex((s, i) => s.code === step);

		if (selectedStepIndex - 1 <= 0) {
			return steps.find((s, i) => i === selectedStepIndex).optional;
		} else {
			const previousStepIndex = steps.findIndex(
				(s, i) => i === selectedStepIndex - 1
			);
			return this.steps.find((s, i) => i === previousStepIndex).optional;
		}
	}

	// Is next step of current active step optional
	private isNextStepOptionalOfCurrentActive() {
		const { currentStep, steps } = this;

		const currentStepIndex = steps.findIndex(
			(step) => step.code === currentStep
		);

		if (currentStepIndex + 1 > steps.length - 1) {
			return steps.find((step, i) => i === currentStepIndex).optional;
		} else {
			const nextStepIndex = steps.findIndex(
				(step, i) => i === currentStepIndex + 1
			);
			return this.steps.find((step, i) => i === nextStepIndex).optional;
		}
	}

	isNextStepDoNotProcess(stepCode: number) {
		const { steps, currentStep } = this;
		const doNotProcessStep = steps.find(
			(stepItem) => stepItem.code === stepCode
		);
		return (
			doNotProcessStep &&
			'Do Not Process' === doNotProcessStep.name &&
			currentStep < 5
		);
	}

	canGoToRFA(stepCode: number) {
		const { steps, currentStep: currentStepCode } = this;
		const currentStep = steps.find(
			(stepItem) => stepItem.code === currentStepCode
		);
		const newStep = steps.find((stepItem) => stepItem.code === stepCode);

		return currentStep.key === 'AUDIT' && newStep.key === 'RFA';
	}

	canGoToDoNotProcess(stepCode: number) {
		const { currentStep, steps } = this;
		const doNotProcessStep = steps.find(
			(stepItem) => 'Do Not Process' === stepItem.name
		);
		const currentClickedStep = steps.find(
			(stepItem) => stepItem.code === currentStep
		);
		return (
			doNotProcessStep &&
			currentClickedStep &&
			currentClickedStep.name !== 'Create Output File' &&
			currentClickedStep.name !== 'GL Output Sent' &&
			currentClickedStep.name !== 'AP Feed Received' &&
			stepCode === doNotProcessStep.code
		);
	}

	isStepDoNotProcess(stepCode: number) {
		const { currentStep, steps } = this;
		const doNotProcessStep = steps.find(
			(stepItem) => 'Do Not Process' === stepItem.name
		);
		return (
			doNotProcessStep &&
			stepCode === doNotProcessStep.code &&
			currentStep === doNotProcessStep.code
		);
	}

	isCurrentStepDoNotProcess(stepCode: number) {
		const { currentStep, steps } = this;
		const doNotProcessStep = steps.find(
			(stepItem) => 'Do Not Process' === stepItem.name
		);
		return doNotProcessStep
			? doNotProcessStep.code === currentStep && stepCode === 1
			: true;
	}
}
