import { Injectable } from '@angular/core';
import { Notes } from './notes';
import { BaseService } from '../../../core/base.service';
import Query from "../../../core/query/query";

@Injectable()
export class NotesService extends BaseService<Notes> {

  constructor() {
    super('note');
  }

  findAllForMaster(query?: Query): any {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'master'], this.toFilter(transformedQuery));
  }
}
