import {timer as observableTimer, of as observableOf, Observable} from 'rxjs';
import {Injectable, Type} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {AlertService} from "../../shared/alert/alert.service";
import {DialogService} from "../../shared/dialog/dialog.service";
import DataLock from './data-lock';
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import {flatMap} from "rxjs";

@Injectable()
export class DataLockService extends BaseService<DataLock> {

  readonly DATA_LOCK_TYPES = {
    INVENTORY: 'inventory',
    USERS: 'users',
    ROLES: 'roles',
    ACCOUNTS: 'accounts',
    DICTIONARY: 'dictionary',
    ORDERS: 'orders',
    ORDER_SERVICE: 'order-service',
    PROJECTS: 'projects',
    DISPUTES: 'disputes',
    BUILDING: 'building',
    CONTACT: 'contact',
    CUSTOMER: 'customer',
    SITE: 'site',
    VENDOR: 'vendor',
    VENDOR_REMIT_ADDRESS: 'vendor-remit-address',
    GL_CODE: 'gl-code',
    GL_STRING: 'gl-string',
    RATE_AUDIT: 'rate-audit',
    QUOTES: 'quotes',
    QUOTE_SERVICE: 'quote-service',
    CONTRACTS: 'contracts',
    CONTRACT_SECTION: 'contract-section',
    TAGS: 'tag-administration',
    NETWORK_HUB: 'network-hub',
    CUSTOMER_EQUIPMENT: 'customer-equipment',
    TN_TFN: 'tn-tfn',
    TAX: 'tax'
  }

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "core";

  readonly DATA_LOCK_CLOSE_STATUS = {
    CANCEL_BY_USER: 0,
    TIME_EXTEND: 1,
    CANCEL_BY_TIMER: 2
  }

  public entityDataLock: DataLock;
  public warningTimer;

  constructor(public dialogService: DialogService,
              public alertService: AlertService) {
    super('data-lock');
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  static component: Type<any>;

  isEntityLocked(entityInfo) {
    return this.httpService().get(['data-lock', entityInfo]).pipe(
      flatMap(this.isEntityLockHandler));
  }

  createEntityDataLock(entityInfo, lockDuration?: number) {

    const createDataLockInfo = Object.assign({}, entityInfo, {lock_duration: lockDuration});

    return this.httpService().post('data-lock', createDataLockInfo).pipe(
      flatMap(this.createEntityDataLockHandler));
  }

  deleteEntityDataLock() {
    return this.httpService().delete(['data-lock', this.entityDataLock.id]);
  }

  isEntityLockHandler = (entityLock) => {
    return observableOf(entityLock.entityDataLock);
  }

  createEntityDataLockHandler = (entityLock) => {
    const entityDataLock = entityLock.entityDataLock;
    this.entityDataLock = entityDataLock;

    return observableOf(entityDataLock);
  }

  startWarningTimer = (lockDuration: number, dialogRef) => {
    this.warningTimer = observableTimer((lockDuration) * 3000).subscribe(result => {

      this.dialogService.open(DataLockService.component, {
        entityDataLock: this.entityDataLock,
        editDialogRef: dialogRef
      })
        .afterClosed()
        .subscribe((result) => {

          if (result.closeStatus === this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER) {
            dialogRef.componentInstance.closeDialog(null, true);
          }

          if (result.closeStatus === this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER) {
            dialogRef.componentInstance.closeDialog({cancelByTimer: true}, true);
          }

        });

    });
  }

  killWarningTimer = () => {
    this.warningTimer.unsubscribe();
  }

  lockedByUserAlert(userFullName) {
    this.alertService.error('', `${this.messages.get('DATA_LOCKED')}${userFullName}`);
  }

  cancelByTimerAlert() {
    this.alertService.error('', this.messages.get('TIMER_EXPIRED'));
  }

}
