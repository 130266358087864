<div
  fxLayout="column"
  fxLayoutGap="16px"
  class="address-filter"
>
  <div fxFlex class="form-group address-entry-container ca-form">
    <mat-form-field hiDensityInput>
      <mat-label>{{ entry?.addressFields?.address1?.label || 'Address' }}</mat-label>
      <input
        [(ngModel)]="internalAddressQuery[ADDRESS_FIELDS.ADDRESS1]"
        (ngModelChange)="ngOnAddressChange()"
        type="text"
        matInput
      />
    </mat-form-field>
  </div>

  <div fxFlex class="form-group address-entry-container ca-form">
    <mat-form-field hiDensityInput>
      <mat-label>{{ entry?.addressFields?.city?.label || 'City' }}</mat-label>
      <input
        [(ngModel)]="internalAddressQuery[ADDRESS_FIELDS.CITY]"
        (ngModelChange)="ngOnAddressChange()"
        type="text"
        matInput
      />
    </mat-form-field>
  </div>
  <div
    fxLayout="row"
    style="margin-bottom: -16px;"
    class="ca-form"
    fxLayoutGap="16px"
  >
    <div fxFlex class="form-group">
      <ca-default-picker
       hiDensityInput>
        <mat-form-field>
          <mat-label>{{ entry?.addressFields?.subdivision?.label || 'State' }}</mat-label>
          <mat-select
            #picker
            class="ca-picker-searchable ca-picker"
            [panelClass]="{'ca-picker-searchable' : true, 'ca-picker': true}"
            [(ngModel)]="internalAddressQuery[ADDRESS_FIELDS.SUBDIVISION]"
            (ngModelChange)="ngOnAddressChange()"
            (openedChange)="openedChange($event)"
            [formControl]="selectFormControl"
          >
            <mat-form-field
              class="ca-picker-search-container"
              floatPlaceholder="never" color="accent"
            >
              <input title="Search"
                #searchInput type="text"
                matInput [formControl]="searchFormControl"
              >
            </mat-form-field>
            <div class="ca-picker-entries"
                #entriesContainer
            >
              <button *ngIf="selectFormControl.value"
                (click)="clearSubdivision()"
                mat-button class="clear-option"
              >Clear
              </button>

              <mat-option
                *ngFor="let val of entry?.addressFields?.subdivision?.lookupItems"
                [value]="val.key"
                >{{ val.value }} &bull; {{ val.name }}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </ca-default-picker>
    </div>
    <div fxFlex class="form-group address-entry-container ca-form">
      <mat-form-field hiDensityInput>
        <mat-label>{{ entry?.addressFields?.postal_code?.label || 'Zip' }}</mat-label>
        <input
          [(ngModel)]="internalAddressQuery[ADDRESS_FIELDS.POSTAL_CODE]"
          (ngModelChange)="ngOnAddressChange()"
          type="text"
          matInput
        />
      </mat-form-field>
    </div>
  </div>
</div>
