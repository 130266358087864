import { MatDialogRef } from "@angular/material/dialog";
import {Injectable} from "@angular/core";
import {DataLockService} from "./data-lock.service";
import {DialogService} from "../../shared/dialog/dialog.service";
import {ComponentType} from "@angular/cdk/portal";
import {Observable} from "rxjs";
import {flatMap, tap} from "rxjs";

class DataLockDialogRef {

  constructor(private dataLockService: DataLockService, private dataLockInfo: any, private dataInfo: any, private dialog: DialogService, private dialogConfig?: any) {

  }

  afterClosed() {

    let dataLockInfo = this.dataLockInfo;
    let config = dataLockInfo;

    return new Observable((observer) => {

      this.dataLockService.isEntityLocked(dataLockInfo)
        .pipe(
        tap((entityDataLock: any) => {
          if (entityDataLock) {
            return this.dataLockService.lockedByUserAlert(entityDataLock.user.full_name);
          }

          return this.dataLockService.createEntityDataLock(dataLockInfo);
        }))
        .subscribe((newEntityLock) => {
          const lockDialogRef: MatDialogRef<any> = this.dialog.open(this.dataInfo.component, this.dataInfo.data, this.dialogConfig || {});

          this.dataLockService.startWarningTimer(newEntityLock.lock_duration, lockDialogRef);

          lockDialogRef.afterClosed()
            .subscribe((result) => {
              if (result && result.cancelByTimer) {
                this.dataLockService.cancelByTimerAlert();
              }
              this.dataLockService.deleteEntityDataLock();
              this.dataLockService.killWarningTimer();
              observer.next(result);
              observer.complete()
            });
        });
    });
  }
}

@Injectable()
export class DataLockDialogService {

  constructor(private dataLockService: DataLockService, private dialog: DialogService) {

  }

  get Types() {
    return this.dataLockService.DATA_LOCK_TYPES;
  }

  open(config: {
    entityType: any;
    entityId: any;
    data: any;
    component: ComponentType<any>
  }, dialogConfig?: any) {


    const dataLockInfo = {
      entityType: config.entityType,
      entityId: config.entityId,
    };

    const dataInfo = {
      data: config.data,
      component: config.component
    }

    return new DataLockDialogRef(this.dataLockService, dataLockInfo, dataInfo, this.dialog, dialogConfig);

  }
}
