<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Vendor Remit Address</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button *permission="'Create'" matTooltip="New Vendor Remit Address" mat-icon-button
          (click)="newVendorRemitAddress()">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <div matTooltip="Edit Vendor Remit Address">
    <button *permission="'Modify'" mat-icon-button (click)="editVendorRemitAddress(selection)" [disabled]="!selection">
      <ca-icon [key]="'EDIT'"></ca-icon>
    </button>
  </div>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
   <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"  (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button #toggleDetailsEnableButton  [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
         [disabled]="!selection" 
         (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" 
         mat-icon-button matTooltip="Info">
    <ca-icon [key]="'INFO'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">
  <dx-data-grid #grid
                (onRowClick)="onRowClick($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onCellClick)="onCellClick($event)"
                [columns]="columns"
                class="app-grid-with-pager"
                [width]="'100%'"
                [height]="'100%'"
                [dataSource]="vendorRemitAddresses">
  </dx-data-grid>
  <ca-pager  [gridPager]="defaultGridPager" [pageContext]="vendorRemitAddressGridService"  [query]="query" class="app-pager ca-default-border-top" (onChange)="loadData(query)"></ca-pager>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="vendorRemitAddressFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter()">
    </new-app-filter>
    <ca-vendor-remit-address-sneak-peek [vendorRemitAddress]="selection"
                                        (closeRequested)="sider?.close()"
                                        [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></ca-vendor-remit-address-sneak-peek>
  </mat-sidenav>

</mat-sidenav-container>
