import { of as observableOf } from 'rxjs';

import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { takeUntil } from 'rxjs';
import { InvoiceChargeService } from '../../charge/core/invoice-charge.service';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import Query from '../../core/query/query';
import {
	IMessagesResourceService,
	ResourcesService
} from '../../core/resources/resources.service';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { DisputeManageDialogComponent } from '../../dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component';
import { Inventory } from '../../inventory/core/inventory';
import { InventoryQuery } from '../../inventory/core/inventory.query';
import { InventoryService } from '../../inventory/core/inventory.service';
import { LocationService } from '../../location/core/location.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { GridService } from '../../shared/grid/grid.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { UserService } from '../../user/core/user.service';
import { AlertInventoryMrcChargesGridService } from '../core/alert-inventory-mrc-charges-grid.service';
import { AlertInventoryMrcFilterService } from '../core/alert-inventory-mrc-filter.service';
import { CommonAlertService } from '../core/common-alert.service';

//HELPER
import {
	calculateDisputesFromTotalAmount,
	getTotalChargeAmountFromInvoice,
	groupDisputesBySpid,
	sortCalculatedChargesByChargeId
} from '../helper/common-alert';

@Component({
	selector: 'app-alert-inventory-mrc',
	templateUrl: './alert-inventory-mrc.component.html',
	styleUrls: ['./alert-inventory-mrc.component.css']
})
export class AlertInventoryMrcComponent
	extends PageListComponent
	implements AfterViewInit, OnDestroy
{
	public alert;
	public tabGroup: TabGroup;
	public tabChanged: boolean;
	public activeTab = 0;
	public audits: Array<any> = [];
	public loadedAudits: Array<any> = [];
	public columns: any;
	public sorting: any[][];
	public chargeColumns: any;
	public selection: any;

	@ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;

	readonly sideSections = {
		none: null,
		info: 'info',
		filter: 'filter'
	};
	chargesSelectionActive = false;

	_isSidePanelOpen = false;
	activeTabIndex = 0;
	isGridSettingsDisabled = true;

	readonly PLACEHOLDERS = {
		OWNER_UNASSIGNED: 'Unassigned'
	};

	public query: InventoryQuery = new InventoryQuery({
		orderBy: [['id', 'ASC']]
	});

	public queryFilter: Query = new Query({
		orderBy: [['id', 'ASC']],
		limit: 20
	});

	addDisputeDisabled: boolean = true;
	public selectedCharges: Array<any> = [];
	charges: Array<any> = [];
	public DISPUTE_CATEGORY_LOOKUP_MODEL =
		LOOKUP_MODELS_ENUM.DISPUTE_CATEGORY.modelName;
	resultQuery: Query = new Query({ total: 0, orderBy: [['id', 'ASC']] });
	delayChargesSelectionChange: boolean = false;
	public selectionActive = false;

	messages: IMessagesResourceService;
	readonly MESSAGES_MODULE: string = 'common-alert';

	public alertId: number;

	public selectedAlertId = null;

	chargesRowsExpanded: any[] = [];
	inventoryMRCPagerLimit: number = 20;
	public currentTabIndex = 0;

	constructor(
		public settingsService: UserSettingsService,
		public route: ActivatedRoute,
		public alertService: CommonAlertService,
		public toastAlertService: AlertService,
		public alertInventoryMrcChargesGridService: AlertInventoryMrcChargesGridService,
		public router: Router,
		public dialogService: DialogService,
		public inventoryService: InventoryService,
		public chargeService: InvoiceChargeService,
		public dictionaryService: DictionaryService,
		public userService: UserService,
		public locationService: LocationService,
		public inventoryMrcFilterService: AlertInventoryMrcFilterService,
		public tabService: TabService,
		public loaderService: LoaderService
	) {
		super(
			new PageContext({
				name: 'app.alert.alert-inventory-mrc',
				settings: settingsService
			})
		);

		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
	}

	onTabChange(index) {
		let tab = this.tabGroup.tabs[index];
		this.currentTabIndex = index;

		if (!tab.disabled) {
			this.activeTabIndex = index;
			this.tabGroup.activate(tab.key);
		}
	}

	public loadTabs() {
		this.tabGroup = this.tabService.create();

		this.tabGroup.addTab({ key: 1, title: 'Summary' });
		this.tabGroup.addTab({ key: 2, title: 'Details' });

		this.tabGroup.activate(1);

		this.tabGroup.onActivate.subscribe((tab) => {
			setTimeout(() => {
				if (this.tabGroup.isActive(2)) {
					if (this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME)) {
						this.panelSide.open(this.SECTIONS.FILTER_SECTION_NAME);
						this.tabChanged = !this.tabChanged;
					}
				}
			});
		});
	}

	loadData(query) {
		this.alertService.findById(this.route.params['_value'].id).subscribe(
			(alert) => {
				this.alert = alert;
				this.loadInventoryMRCs(query);
			},
			(error) => {
				console.error('Error fetching alert info', error);
			}
		);
	}

	loadInventoryMRCs(query) {
		// query.where.invoice_id = this.alert.invoice_id;
		const queryn = new InventoryQuery();
		this.alertService
			.findInventoryMRCs(this.alertId, queryn)
			.subscribe((result) => {
				this.audits = result.rows;
				this.loadCharges();
			});
	}

	loadCharges() {
		const spidArray = this.audits.map((audit) => audit.sp_ckt);

		if (spidArray.length > 0) {
			this.query = new Query({
				where: {
					spid: spidArray,
					invoice_id: this.alert.invoice_id,
					alert_id: this.alert.id
				},
				orderBy: [['id', 'ASC']]
			});

			this.loaderService.displayLoader(true);
			this.alertService
				.findChargesByMRCandSPID(this.alert.id, this.query)
				.subscribe((result) => {
					this.charges = result.rows;
					this.loaderService.hideLoader(true);
					this.query.total = result.count;
					this.delayChargesSelectionChange = true;

					setTimeout(() => {
						if (this.dataGrid) {
							this.dataGrid.instance.refresh();
						}
					}, 200);
				});
		}
	}

	refresh() {
		this.loadData(this.query);
	}

	onPageChange() {
		this.loadData(this.query);
	}

	ngOnInit() {
		this.loadTabs();
		this.alert = this.route.snapshot.data.alert;
		this.tabGroup.tabs[1].disabled = this.alert.message === 'No issues found';
		this.alertId = this.alert.id;
		this.loadData(this.query);
		// this._init();
		this.columns = this.alertInventoryMrcChargesGridService.columns();
	}

	ngAfterViewInit(): void {
		this.alertInventoryMrcChargesGridService.create(this.dataGrid.instance, {
			noDataText: this.alertInventoryMrcChargesGridService.noDataMessage,
			selection: {
				mode: 'multiple',
				selectAllMode: 'page'
			}
		});

		super.ngAfterViewInit();
	}

	back(): void {
		this.router.navigate(['/audit/audit-results']);
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
		this.tabGroup.onActivate.unsubscribe();
	}

	clickFilterButton() {
		this.sider && this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME)
			? this.panelSide.close()
			: this.panelSide.open();
		this.sider && this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
	}

	filterData(query: InventoryQuery) {
		this.queryFilter.where.id = query['where']['charge_id'] || null;
		this.query = query;
		this.loadInventoryMRCs(this.query);
	}

	clearFilter() {
		this.queryFilter.where = {};
		this.query = new Query();
		this.chargesSelectionActive = false;
		this.loadData(this.query);
	}

	public goToInvoiceDetails() {
		if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
			this.router.navigate([
				'/invoice',
				this.alert.invoice.invoice_id,
				'show',
				'overview'
			]);
		}
	}

	showDispute(id: any) {
		this.router.navigate(['/dispute', id, 'show']);
	}

	findIndexInSelectedCharges(chargeId): number {
		for (let i = 0, l = this.selectedCharges.length; i < l; i++) {
			if (this.selectedCharges[i].id === chargeId) {
				return i;
			}
		}
		return -1;
	}

	onChargesSelectionChanged(event) {
		setTimeout(() => {
			if (!this.delayChargesSelectionChange && this.charges.length) {
				/** Selected rows */
				if (event.selectedRowKeys.length) {
					event.selectedRowsData.forEach((item) => {
						let index = this.findIndexInSelectedCharges(item.id);
						if (index === -1) {
							this.selectedCharges.push(item);
						}
					});
				}
				/** Deselected rows */
				if (event.currentDeselectedRowKeys.length) {
					event.currentDeselectedRowKeys.forEach((id) => {
						let index = this.findIndexInSelectedCharges(id);
						if (index > -1) {
							this.selectedCharges.splice(index, 1);
						}
					});
				}
			}
			/** Dispute Add enabled/disabled */
			if (this.selectedCharges.length) {
				this.addDisputeDisabled = false;
				this.selectedCharges.forEach((item) => {
					if (item.charge_dispute_id || +item.variance <= 0) {
						this.addDisputeDisabled = true;
					}
				});
			} else {
				this.addDisputeDisabled = true;
				this.selectedAlertId = null;
			}
			this.delayChargesSelectionChange = false;
		});
	}

	getDispDesc(value) {
		let disputeDescriptionArray = [];
		value.forEach((i) => {
			const descString = `Charges billed do not equal inventory MRC.`;
			disputeDescriptionArray.push(descString);
		});
		return disputeDescriptionArray;
	}

	getCalculatedCharges(value) {
		let newArray = [];
		value.sort((a, b) =>
			a.charge_id > b.charge_id ? 1 : b.charge_id > a.charge_id ? -1 : 0
		);
		value.forEach((i) => {
			newArray.push(i['calc_amt']);
		});
		return newArray;
	}

	loadSelectedAlerts() {
		this.query.where = {};
		if (this.chargesSelectionActive) {
			if (this.queryFilter.where)
				this.query.where = { ...this.queryFilter.where };
		} else {
			this.query.set('id', {
				$in: this.selectedCharges.map((x) => {
					return x.id;
				})
			});
		}

		this.query.offset = 0;
		this.query.page = 1;

		this.chargesSelectionActive = !this.chargesSelectionActive;
		this.loadData(this.query);
	}

	addDispute() {
		this.query['where'] = {};
		this.query.set('id', {
			$in: this.selectedCharges.map((x) => {
				return x.id;
			})
		});

		this.query.offset = 0;
		this.query.page = 1;
		this.query.limit = 10000;
		this.query.set('invoice_id', this.alert.invoice_id);
		this.chargeService.filters(this.query).subscribe(async (result) => {
			this.charges = result.items;
			let arrayOfCalculatedCharges = this.getCalculatedCharges(
				this.selectedCharges
			);
			let invoice = this.alert.invoice;
			let dispDesc = this.getDispDesc(this.selectedCharges);
			if (!this.selectedCharges.length || this.addDisputeDisabled) {
				return;
			}

			this.dialogService
				.open(
					DisputeManageDialogComponent,
					{
						charges: this.charges,
						invoice: invoice,
						calculatedCharges: arrayOfCalculatedCharges,
						dispDesc: dispDesc,
						alertId: this.alertId
					},
					{ width: '60%' }
				)
				.afterClosed()
				.subscribe(async (result) => {
					if (result) {
						this.selectedCharges.forEach((i) => {
							i.charge_dispute_id = { dispute_id: result.id };
						});
						this.selectedCharges = [];
						this.addDisputeDisabled = true;
						this.loadData(this.query);
					}
				});
		});
	}

	onChargesDataGridClick(event) {
		if (!event.target.classList.contains('selection-badge')) {
			this.delayChargesSelectionChange = false;
		}
	}

	selectRows() {
		this.dataGrid.instance.selectRows(
			this.selectedCharges.reduce((acc, item) => [...acc, item.id], []),
			false
		);
	}

	csv() {
		const {
			alertInventoryMrcChargesGridService,
			query,
			inventoryService,
			alertService,
			alertId,
			alert
		} = this;
		alertInventoryMrcChargesGridService.csvMap().subscribe((fields) => {
			const alertInventoryCSVTransform = (items) => {
				return items.map((item) => {
					const { id } = item;
					const details = alert.inventory_details.find(
						(detail) => id === detail.inventory_id
					);

					if (details) {
						item.message = details.message;
					}

					return item;
				});
			};

			inventoryService.exportToCSV('alert-inventories-mrc', {
				fields,
				query: query,
				fetchDataHandler: (csvQuery) => {
					return alertService.findInventoryMRCs(alertId, csvQuery);
				},
				middleware: [alertInventoryCSVTransform]
			});
		});
	}

	// getCalculatedCharges(value) {
	// 	const result = [];
	// 	const totalVarianceTypes = groupDisputesBySpid(value);

	// 	for (let key in totalVarianceTypes) {
	// 		totalVarianceTypes[key].forEach((chargeList) => {
	// 			const totalChargeAmt = getTotalChargeAmountFromInvoice([chargeList]);
	// 			result.push(
	// 				...calculateDisputesFromTotalAmount([chargeList], totalChargeAmt)
	// 			);
	// 		});
	// 	}
	// 	const sortedResult = sortCalculatedChargesByChargeId(result);
	// 	return sortedResult.map(
	// 		(calculatedChargeAmount) => calculatedChargeAmount.value
	// 	);
	// }

	// loadAuditResult(query) {
	// 	this.delayChargesSelectionChange = true;
	// 	this.loaderService.displayLoader(true);

	// 	this.alertService
	// 		.findChargesByMRCandSPID(this.alert.id, query)
	// 		.subscribe((result) => {
	// 			this.charges = result.rows;
	// 			query.total = result.count;
	// 			this.loaderService.hideLoader(true);
	// 		});
	// }

	// loadSelectedAuditResults(rule) {
	// 	if (rule.selectionActive) {
	// 		if (this.queryFilter.where.id)
	// 			rule.resultQuery.where.id = {
	// 				...this.queryFilter.where.id
	// 			};
	// 		else rule.resultQuery.remove('id');
	// 	} else {
	// 		rule.resultQuery.set('id', {
	// 			$in: rule.selectedRows
	// 		});
	// 	}
	// 	rule.resultQuery.offset = 0;
	// 	rule.resultQuery.page = 1;
	// 	rule.selectionActive = !rule.selectionActive;
	// 	this.loadAuditResult(rule.resultQuery);
	// }
}
