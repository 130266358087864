import {of as observableOf, Observable, from} from 'rxjs';

import {map, mergeMap} from 'rxjs';
import {Inject} from '@angular/core';

import {FilterEntry} from './filter-entry';
import {FilterContext} from './filter-context';
import {QueryParamType} from '../query/query-param-type';
import {IFilterLookupProvider} from './filter-lookup.provider';
import {UserSettingsService} from '../../user/core/user-settings.service';

export abstract class FilterService {
  public providers: Array<any> = [];
  private defaultContextOriginal = null;

  constructor(
    @Inject('name') public name: any,
    public defaultContext: any,
    @Inject('userSettingsService') public userSettingsService: UserSettingsService) {
    this.defaultContextOriginal = Object.assign({}, this.defaultContext);
  }

  addLookupProvider(name, provider: IFilterLookupProvider) {
    let existing = this.getLookupProvider(name);

    if (existing) {
      throw Error(`Lookup provider with name "${name}" is already registered. Lookup provider names must be unique`);
    }

    this.providers.push({
      name: name,
      provider: provider
    });
  }

  getLookupProvider(name) {
    return this.providers.filter((provider) => {
      return provider.name === name;
    })[0];
  }

  public loadContext(): Observable<any> {

    return this.userSettingsService.me().pipe(
      map((data) => {
        let context = data[this.name] || {};
        let filterSet = context.filterSet || null;
        if (context.filters && context.filters.length > 0) {
          let contextKeys = [];
          let contextFields = [];
          let defaultContextKeys = [];
          let defaultContextFields = [];

          context.filters.forEach((entry) => {
            contextKeys.push(entry.label);
            contextFields.push(entry.field);
          });
          contextKeys.sort();
          contextFields.sort();

          this.defaultContext.filters.forEach((entry) => {
            defaultContextKeys.push(entry.label);
            defaultContextFields.push(entry.field);
          });
          defaultContextKeys.sort();
          defaultContextFields.sort();

          if (contextKeys.length && defaultContextKeys.length) {
            if (JSON.stringify(contextKeys) !== JSON.stringify(defaultContextKeys)) {
              context = this.defaultContext;
            }
          }

          if (contextFields.length && defaultContextFields.length) {
            if (JSON.stringify(contextFields) !== JSON.stringify(defaultContextFields)) {
              context = this.defaultContext;
            }
          }
        }

        if (!context || this.isEmpty(context) || !context.filters || context.filters.length < 1) {
          context = this.defaultContext;
        }

        if (filterSet && filterSet.length > 0) {
          context['filterSet'] = filterSet;
        } else {
          context['filterSet'] = this.defaultContextOriginal.filterSet;
        }

        return context;
      }));


  }

  saveSettings(settings) {
    return this.userSettingsService.saveUserSettings(this.name, settings); /// replaced saveGridFilterSettings()
  }

  create(): Observable<any> {
    return from(this.loadContext()).pipe(
      mergeMap((settings) => {
        let context = new FilterContext();
        context.name = this.name;
        context.service = this;

        if (settings) {
          let filters = (settings.filters ? settings.filters : settings) || [];
          let filterSet = settings.filterSet ? settings.filterSet : [];

          filterSet.forEach((entry) => {
            let filter = new FilterEntry();
            filter.field = entry.field;
            filter.label = entry.label;
            filter.editor = entry.editor;
            filter.exact = entry.exact || false;
            filter.expanded = entry.expanded || false;
            filter.caseSensitive = entry.caseSensitive || false;
            filter.lookupProviderName = entry.lookupProviderName;
            filter.index = entry.index || 0;
            filter.visible = entry.visible != null ? entry.visible : true;
            filter.type = QueryParamType.find(entry.type);
            filter.trueLabel = entry.trueLabel;
            filter.falseLabel = entry.falseLabel;
            filter.exactMatch = entry.exactMatch;
            filter.matchCase = entry.matchCase;
            filter.fieldToFilterWith = entry.fieldToFilterWith;
            filter.secondRowField = entry.secondRowField;
            filter.max = entry.max;
            filter.defaultValue = entry.defaultValue;
            filter.topUsage = entry.topUsage;

            if (filter.type === QueryParamType.Lookup) {
              let prov = this.getLookupProvider(entry.lookupProviderName);
              if (prov && prov.provider) {
                filter.lookupProvider = prov.provider;
              }
            }


            /* addressFields and subdivision lookup provider */
            if (entry.addressFields) {
              filter.addressFields = JSON.parse(JSON.stringify(entry.addressFields));
              if (filter.addressFields && filter.addressFields.subdivision && filter.addressFields.subdivision.lookupProviderName) {
                let prov = this.getLookupProvider(entry.addressFields.subdivision.lookupProviderName);
                if (prov && prov.provider) {
                  filter.addressFields.subdivision.lookupProvider = prov.provider;
                }
              }
            }

            // TODO: Refactor to not repeat load for each specific case (above case for addressFields)
            /* sitesFields and subdivision lookup provider */
            if (entry.sitesFields) {
              filter.sitesFields = JSON.parse(JSON.stringify(entry.sitesFields));
              if (filter.sitesFields.subdivision && filter.sitesFields.subdivision.lookupProviderName) {
                let prov = this.getLookupProvider(entry.sitesFields.subdivision.lookupProviderName);
                if (prov && prov.provider) {
                  filter.sitesFields.subdivision.lookupProvider = prov.provider;
                }
              }
            }
            if (entry.vendorFields) {
              filter.vendorFields = JSON.parse(JSON.stringify(entry.vendorFields));
              if (filter.vendorFields.vendor && filter.vendorFields.vendor.lookupProviderName) {
                let prov = this.getLookupProvider(entry.vendorFields.vendor.lookupProviderName);
                if (prov && prov.provider) {
                  filter.vendorFields.vendor.lookupProvider = prov.provider;
                }
              }
              if (filter.vendorFields.account && filter.vendorFields.account.lookupProviderName) {
                let prov = this.getLookupProvider(entry.vendorFields.account.lookupProviderName);
                if (prov && prov.provider) {
                  filter.vendorFields.account.lookupProvider = prov.provider;
                }
              }
            }
            context.filterSet.push(filter);
          });
          if (filters && filters.length > 0) {
            filters.forEach((entry) => {
              let filter = new FilterEntry();
              filter.field = entry.field;
              filter.label = entry.label;
              filter.editor = entry.editor;
              filter.exact = entry.exact || false;
              filter.caseSensitive = entry.caseSensitive || false;
              filter.lookupProviderName = entry.lookupProviderName;
              filter.index = entry.index || 0;
              filter.visible = entry.visible != null ? entry.visible : true;
              filter.type = QueryParamType.find(entry.type);
              filter.trueLabel = entry.trueLabel;
              filter.falseLabel = entry.falseLabel;
              filter.exactMatch = entry.exactMatch;
              filter.matchCase = entry.matchCase;
              filter.fieldToFilterWith = entry.fieldToFilterWith;
              filter.secondRowField = entry.secondRowField;
              filter.max = entry.max;
              filter.defaultValue = entry.defaultValue;
              filter.topUsage = entry.topUsage;

              if (filter.type === QueryParamType.Lookup) {
                let prov = this.getLookupProvider(entry.lookupProviderName);
                if (prov && prov.provider) {
                  filter.lookupProvider = prov.provider;
                }
              }

              /* addressFields and subdivision lookup provider */
              if (entry.addressFields) {
                filter.addressFields = JSON.parse(JSON.stringify(entry.addressFields));
                if (filter.addressFields && filter.addressFields.subdivision && filter.addressFields.subdivision.lookupProviderName) {
                  let prov = this.getLookupProvider(entry.addressFields.subdivision.lookupProviderName);
                  if (prov && prov.provider) {
                    filter.addressFields.subdivision.lookupProvider = prov.provider;
                  }
                }
              }

              // TODO: Refactor to not repeat load for each specific case (above case for addressFields)
              /* sitesFields and subdivision lookup provider */
              if (entry.sitesFields) {
                filter.sitesFields = JSON.parse(JSON.stringify(entry.sitesFields));
                if (filter.sitesFields.subdivision && filter.sitesFields.subdivision.lookupProviderName) {
                  let prov = this.getLookupProvider(entry.sitesFields.subdivision.lookupProviderName);
                  if (prov && prov.provider) {
                    filter.sitesFields.subdivision.lookupProvider = prov.provider;
                  }
                }
              }

              if (entry.vendorFields) {
                filter.vendorFields = JSON.parse(JSON.stringify(entry.vendorFields));
                if (filter.vendorFields.vendor && filter.vendorFields.vendor.lookupProviderName) {
                  let prov = this.getLookupProvider(entry.vendorFields.vendor.lookupProviderName);
                  if (prov && prov.provider) {
                    filter.vendorFields.vendor.lookupProvider = prov.provider;
                  }
                }
                if (filter.vendorFields.account && filter.vendorFields.account.lookupProviderName) {
                  let prov = this.getLookupProvider(entry.vendorFields.account.lookupProviderName);
                  if (prov && prov.provider) {
                    filter.vendorFields.account.lookupProvider = prov.provider;
                  }
                }
              }

              context.filters.push(filter);
            });
          }
        }

        if (this.defaultContext && this.defaultContext.filters) {
          this.defaultContext.filters = this.defaultContext.filters.map((filter) => {
            let currentSetting = context.filters.filter(o => o.field == filter.field)[0];
            if (currentSetting) {
              let temp = Object.assign(new FilterEntry(), currentSetting);
              delete temp.index;
              delete temp.visible;
              filter = Object.assign(temp, filter);
            }
            return Object.assign(new FilterEntry(), filter);
          });
          context.defaultContext = this.defaultContext;
        }

        return observableOf(context);
      }));
  }


  public isEmpty(obj) {
    let hasOwnProperty = Object.prototype.hasOwnProperty;

    // null and undefined are "empty"
    if (obj == null) {
      return true;
    }

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) {
      return false;
    }
    if (obj.length === 0) {
      return true;
    }

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') {
      return true;
    }

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (let key in obj) {
      if (hasOwnProperty.call(obj, key)) {
        return false;
      }
    }

    return true;
  }
}
