import { Component, EventEmitter, Input, OnChanges, OnInit, AfterViewInit, Output, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { Account } from "../../core/account";
import { ReportService } from "../../../report/core/report.service";
import { ChartService } from "../../../shared/chart/chart.service";
import { ThemeService } from "../../../theme/core/theme.service";
import { ChartReloadService } from "../../../theme/core/chart-reload.service";
import { UserService } from 'app/user/core/user.service';
import { CaCurrencyPipe } from 'app/shared/format/ca-currency.pipe';
import { CasDatePipe } from 'app/shared/format/ca-date.pipe';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { CaNumberPipe } from 'app/shared/format/ca-number.pipe';
import {LocationService} from "../../../location/core/location.service";

@Component({
  selector: 'app-invoice-trend',
  templateUrl: './invoice-trend.component.html',
  styleUrls: ['./invoice-trend.component.css']
})
export class InvoiceTrendComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  readonly CHART_ID = 'INVOICE_TREND';

  @Input() account: Account;
  @Input() height: string = "200px";
  @Input() showLabels: boolean = false;
  @Input() siderActive: any;
  @Output() seriesClicked = new EventEmitter();

  @ViewChild('chartComponent') chartComponent;

  invoiceTrendData: Array<any> = [];
  public showChart: boolean = false;

  chartOptions: any = {};

  private currency;

  constructor(
    public reportService: ReportService,
    public chartService: ChartService,
    public chartReloadService: ChartReloadService,
    public themeService: ThemeService,
    private userService: UserService,
    private dateFormater: CasDatePipe,
    private locationService: LocationService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.showChart = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    let value = changes['account'];
    if (value) {
      this.loadChart();
    }

    if (changes['siderActive'] && this.chartComponent) {
      setTimeout(() => {
        this.chartComponent.instance.render();
    }, 500);
    }

  }

  ngOnDestroy() {
    this.chartReloadService.clearChart(this.CHART_ID);
  }

  loadChart() {
    if(this.account) {
      this.reportService.invoiceTrendReport(this.account.account_no, this.account.vendor_id)
        .subscribe((results) => {
          this.invoiceTrendData = results;
          if(this.invoiceTrendData.length>0) this.currency = this.invoiceTrendData[0].currency;
          this.chartOptions = this.getChartOptions();
          // this.chartOptions = {};

          this.chartReloadService.registerChart({id: this.CHART_ID, chart: this.chartComponent});

          setTimeout(() => {
            if (this.chartComponent) {
              this.chartComponent.instance.render()
            }
          }, 200);
        }, (err) => {
          console.log('reportService.invoiceTrendReport Error: ', err)
        });
    }
  }

  onSeriesClick() {
    this.seriesClicked.emit(new Date());
  }

  setLabel() {
    let label = "";
    let len = 0;

    if (this.invoiceTrendData && this.invoiceTrendData.length) {
      len = this.invoiceTrendData.length;
      if (len > 1)
        label = `${this.formatDateLabel(this.invoiceTrendData[0].inv_date)} to ${this.formatDateLabel(this.invoiceTrendData[len - 1].inv_date)}`;
      else
        label = this.formatDateLabel(this.invoiceTrendData[0].inv_date);
    }
    return label;
  }

  formatDateLabel(date: Date) {
    return this.dateFormater.transform(date, null);
  }


  getChartOptions() {
    const {currency} = this;
    const {userService} = this;
    const caCurrencyPipe = new CaCurrencyPipe(userService, this.locationService);
    return {
      commonSeriesSettings: {
        argumentField: "inv_date",
        type: "stackedBar"
      },
      series: [
        { valueField: "tot_mrc_chgs", name: "mrc" },
        { valueField: "tot_occ_chgs", name: "occ" },
        { valueField: "tot_usage_chgs", name: "usage" },
        { valueField: "tot_taxsur", name: "taxsur" },
        { valueField: "tot_disc_amt", name: "disc" },
        { valueField: "tot_new_chg_adj", name: "adjs" }
      ],
      palette: this.chartService.getChartColorsAsArray(ChartService.activeChartTheme),
      argumentAxis: {
        argumentType: 'datetime',
        label: {
          format: (value)=>{
            return this.formatDateLabel(value);
          },
          alignment: 'center',
          visible: this.showLabels
        },
        tick: {
          visible: true
        },
        title: {
          text: !this.showLabels ? this.setLabel() : "",
          font: { size: 12 }
        },
        type: 'discrete'
      },
      valueAxis: {
        valueType: 'numeric',
        label: {
          customizeText: function (axisValue) {
            return caCurrencyPipe.transform(axisValue.value,currency, null, null, null, true);
          }
        }
      },
      legend: {
        verticalAlignment: "top",
        horizontalAlignment: "center",
        itemTextPosition: 'right',
        visible: false
      },
      tooltip: {
        enabled: true,
        zIndex: 999,
        customizeTooltip: function (arg) {
          return {
            text: arg.seriesName + " " + caCurrencyPipe.transform(arg.originalValue, currency, null, null, null)
          };
        }
      },
      title: this.setLabel(),
      onInitialized: function (e) {
        this.chartInstance = e.component;
      }
    };
  }
}
