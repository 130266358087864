import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  PICKER_COMPONENT_TEMPLATE,
  PickerComponentBase
} from '../../../shared/picker/ca-picker-base.component';
import { VendorRemitAddressService } from '../../core/vendor-remit-address.service';
import { Observable } from 'rxjs';
import {path} from'ramda';
import {map} from "rxjs";

@Component({
  selector: 'ca-vendor-remit-address-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VendorRemitAddressPickerComponent),
      multi: true
    },
    VendorRemitAddressService
  ]
})
export class VendorRemitAddressPickerComponent extends PickerComponentBase implements OnInit, OnChanges {

  readonly TEXT_FIELD = 'vendor_location_id';
  @Input() formControlName;
  @Input() initialItem;
  @Input() initialItemId;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() placeholder = 'Select ...';
  @Input() pickerForm;
  @Input() searchEnabled: boolean;
  @Input() clearEnabled: boolean;
  @Input() customMethod: string;
  @Input() vendorId: string;


  public control;

  constructor(
    public vendorRemitAddressService: VendorRemitAddressService
  ) {
    super();
  }

  ngOnInit() { }

  loadEntries(): Observable<any> {
    this.control = path(['pickerForm', 'controls', this.formControlName])(this);
    let serviceMethod: string = this.customMethod || 'findOnlyVendorRemit';
    return this.vendorRemitAddressService[serviceMethod](this.query).pipe(map((data: any) => {
      if (!data.items) {
        data.items = data;
      }
      return data;
    }));
  }

  loadInitialItem(): Observable<any> {
    return this.vendorRemitAddressService.findById(this.initialItemId);
  }

  search(value: string): Observable<any> {
    if (value) {
      this.query.where['vendor_location_id'] = { '$ilike': `${value}%` };
    } else {
      delete this.query.where['vendor_location_id'];
    }

    let serviceMethod: string = this.customMethod || 'findOnlyVendorRemit';
    return this.vendorRemitAddressService[serviceMethod](this.query).pipe(map((data:any) => {
      if (!data.items) {
        data.items = data;
      }
      return data;
    }));
  }

  onSearchChange($event) {
    if (this.searchEnabled) {
      this.query.offset = 0;
      this.search($event.value)
        .subscribe((result) => {
          this.entries = result.items;
          this.query.total = result.total;
          this.query.page = 1;
          if (!this.entries.length) {
            this.value = null;
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = path(['pickerForm', 'controls', this.formControlName])(this);
    let {vendorId} = changes;
    if (vendorId && vendorId.currentValue) {
      this.query.where['vendor_id'] = vendorId.currentValue;
      this.initialItem = null;
      this.value = null;
      this.loadData();
    }
  }

}
