import {of as observableOf} from 'rxjs';
import {map, switchMap, distinctUntilChanged, debounceTime, takeUntil} from 'rxjs/operators';
import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {User} from '../../core/user';
import {MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../core/user.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {RolesService} from '../../../roles/core/roles.service';
import Query from '../../../core/query/query';
import {LoaderService} from '../../../shared/loader/loader.service';
import {Location} from '@angular/common';
import {IMessagesResourceService, ResourcesService} from '../../../core/resources/resources.service';
import {DataLockService} from '../../../core/data-lock/data-lock.service';
import {EntityEditContext} from '../../../shared/entity-lock/entity-edit-context';
import {Config} from "../../../core/config/config";
import {ConfigService} from "../../../core/config/config.service";
import {LocationService} from "../../../location/core/location.service";
import {LocationQuery} from "../../../location/core/location.query";
import {tap, flatMap} from "rxjs";
import {ROLE_REPORTS_ENUM} from '../../core/user.enum';
import {checkMfaStatusOrSetDefault} from "../../helper";

@Component({
  selector: 'app-user-manage-dialog',
  templateUrl: './user-manage-dialog.component.html',
  styleUrls: ['./user-manage-dialog.component.scss']
})
export class UserManageDialogComponent extends PageManageDialogComponent implements OnInit, OnDestroy {
  user: User;
  me: User;
  selectedRoles: Object = {};
  roles: Array<any> = [];
  userCopy: User;
  query = new Query();
  editMode: boolean;

  emailUnique = true;
  usernameUnique = true;

  public isFormEnabled = false;

  public passwordPattern: string;
  public passwordMessage: string;

  isUpdate: any;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'users';

  defaultCountryCode: any;
  defaultCountryId: any;
  phoneRegexMask = '(000) 000-0000';
  phoneRegex;
  phoneCountryCode: string = '';
  selectedCountryId: any;
  selectedCountry: any;
  selectedRoleIds: any = [];
  numberOfUserRoles = 0;

  reportingRoles:any = [
    {
      name: ROLE_REPORTS_ENUM.NONE,
      id:0
    }
  ];
  selectedReportingRole: any;
  selectedReportRolePrevValue: any;
  listAnalyses: string = '';
  listDashboards: string = '';
  QSTransfereQuestion: string = '';
  transfereQSUser: boolean = false;
  selectedTransfereQSUser: any;
  selectedTransfereQSMethod: number = 0;
  QSTransfereUser: Array<any> = [
    {
      email: 'none'
    }
  ]
  transfereObject: Array<any> = [
    {
      id: 0,
      name: 'Delete'
    },
    {
      id: 1,
      name: 'Transfer'
    }
  ];
  selectedQSTransfereUser: any;

  constructor(
    public formBuilder: FormBuilder,
    public alert: AlertService,
    public userService: UserService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<UserManageDialogComponent>,
    public roleService: RolesService,
    public location: Location,
    public locationService: LocationService,
    public loaderService: LoaderService,
    public configService: ConfigService,
    public dataLockService?: DataLockService
  ) {
    super(dialogService, dialogRef, dataLockService); // ,entityLockService

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    alert.onAlertClosed.subscribe((res) => {
      if (res) {
        this.closeDialog(UserManageDialogComponent, true)
      }
    })
  }

  loadRoles() {
    const cloned = this.query.clone();
    cloned.orderBy = [['type', 'DESC'],['name', 'ASC']];
    cloned.where = {
      user_id: this.user.id
    };
    cloned.limit = 20;

    return this.roleService.findAll(cloned)
      .pipe(tap((data: any) => {
        this.query.total = data.total;
        let userRoles = this.editMode ? this.user.roles : this.user.userRoles
        if (userRoles && userRoles.length) {
          userRoles.forEach((element, index) => {
            data.items.forEach((role) => {
              if (role.name === (this.editMode ? element.name : element.role.name)) {
                role.selected = true;
                this.numberOfUserRoles++
              }
            })
          });
        }
        let filteredData = data.items.filter(role => !role.is_system || role.id === 1).sort((a, b) => {
          if (a.selected && !b.selected) {
            return -1; // a comes before b
          } else if (!a.selected && b.selected) {
            return 1; // b comes before a
          } else {
            return 0; // leave them unchanged relative to each other
          }
        });
        const sortingOrderReportingRoles = Object.values(ROLE_REPORTS_ENUM);
        const concatReportingRoles = [...this.reportingRoles,...data.items.filter(role => role.is_system && role.id !== 1)];
        const customSort = (a,b) => {
          return sortingOrderReportingRoles.indexOf(a.name) - sortingOrderReportingRoles.indexOf(b.name);
        }
        const sortedReportingRoles = concatReportingRoles.sort(customSort);
        this.reportingRoles = sortedReportingRoles;

        this.roles = this.roles.concat(filteredData);
      }));
  }

  hasAnyUser(field) {
    return this.form.controls[field].valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((value) => {
        if (value && !this.form.controls[field].hasError(field)) {
          const query = new Query({limit: 1});
          query.set(field, {'$ilike': value});
          query.set('id', {'$ne': (this.user && this.user.id) ? this.user.id : 0});
          return this.userService.findAll(query);
        }
        return observableOf({total: 0});
      }),
      map((result: any) => !!!result.total
      ),);
  }

  ngOnInit() {
    this.loadRoles().subscribe((response)=>{
      this.isUpdate = this.user ? this.user.id : false;

      if(this.editMode) {
        if (this.user.roles) {
          const selectedReportingRole = this.reportingRoles.filter(obj1 => this.user.roles.some(obj2 => obj1.id === obj2.id));
          this.user.roles.forEach(role => {
            if(selectedReportingRole.length > 0){
              if(selectedReportingRole[0].id !== role.id){
                this.selectedRoleIds.push(role.id)
              }
            }else{
              this.selectedRoleIds.push(role.id)
            }
          })
          if(selectedReportingRole.length > 0){
            this.selectedReportingRole = selectedReportingRole[0];
            this.selectedReportRolePrevValue = selectedReportingRole[0]
          }else{
            this.selectedReportingRole = this.reportingRoles[0];
            this.selectedReportRolePrevValue = this.reportingRoles[0];
          }
          if (this.selectedReportRolePrevValue.name === ROLE_REPORTS_ENUM.REPORTS_AUTHOR) {
            this.getAuthorReportsUsers();
          }
        }
      } else {
        if (this.user.userRoles) {
          const selectedReportingRole = this.reportingRoles.filter(obj1 => this.user.userRoles.some(obj2 => obj1.id === obj2.role_id));
          this.user.userRoles.forEach(role => {
            if(selectedReportingRole.length > 0){
              if(selectedReportingRole[0].id !== role.role_id){
                this.selectedRoleIds.push(role.role_id)
              }
            }else{
              this.selectedRoleIds.push(role.role_id)
            }
          })
          if(selectedReportingRole.length > 0){
            this.selectedReportingRole = selectedReportingRole[0];
            this.selectedReportRolePrevValue = selectedReportingRole[0]
          }else{
            this.selectedReportingRole = this.reportingRoles[0];
            this.selectedReportRolePrevValue = this.reportingRoles[0];
          }
          if (this.selectedReportRolePrevValue.name === ROLE_REPORTS_ENUM.REPORTS_AUTHOR) {
            this.getAuthorReportsUsers();
          }
        }
      }
      if (!this.isUpdate) {
        this.selectedReportingRole = this.reportingRoles[0];
      }

      this.configService.findAll()
        .subscribe((config: Config) => {

          this.locationService.findAll(new LocationQuery({where:{alpha_2_code:config.location.country}}))
            .subscribe((locations)=>{
              const {items} = locations;
              if(items.length)
                this.defaultCountryId = items[0].id;
            });
          this.passwordPattern = config.validation?.regex;
          this.passwordMessage = config.validation?.error;
        });

      this.userService.me()
        .subscribe((result) => {
          this.me = result;
          this.isFormEnabled = result.roles.filter(role=>role.name === "Administrator").length === 1;

          let userCopy = this.userCopy = Object.assign({}, this.user);

          this.phoneCountryCode = this.user && this.user.country ? this.user.country.phone_country_code : '';
          let replacedCode;
          if (this.phoneCountryCode && this.phoneCountryCode.length) replacedCode = this.phoneCountryCode.replace(/\d/g, '0');
          if (this.user && this.user.country && this.user.country.phone_regex_mask) this.phoneRegexMask = this.user.country.phone_regex_mask.replace(replacedCode, '').trim();
          if (this.user.country && this.user.country.phone_regex) this.phoneRegex = this.user.country.phone_regex;

          if (this.viewOnly || this.isUpdate) {
            this.form = this.formBuilder.group({
              email: [{
                value: userCopy ? userCopy.email : '',
                disabled: this.isUpdate || !this.isFormEnabled
              }, Validators.compose([Validators.required, Validators.email])],
              first_name: [{
                value: userCopy ? userCopy.first_name : '',
                disabled: !this.isFormEnabled
              }, Validators.required],
              last_name: [{
                value: userCopy ? userCopy.last_name : '',
                disabled: !this.isFormEnabled
              }, Validators.required],
              phone_number: [{
                value: userCopy && userCopy.phone_number ? userCopy.phone_number.replace(this.phoneCountryCode, '') : '',
                disabled: !this.isFormEnabled
              }, Validators.pattern(this.phoneRegex)],
              mobile_number: [{
                value: userCopy && userCopy.mobile_number ? userCopy.mobile_number.replace(this.phoneCountryCode, '') : '',
                disabled: !this.isFormEnabled
              }, Validators.pattern(this.phoneRegex)],
              is_active: [{
                value: this.isUpdate ? userCopy.is_active : true,
                disabled: !this.isFormEnabled
              }, Validators.required],
              avatar: [userCopy ? userCopy.avatar : ''],
              country_id: [userCopy ? userCopy.country_id : '']
            });
            this.configService.findAll()
            .subscribe((config: Config) => {
              let isMfaEnabled: boolean = checkMfaStatusOrSetDefault(config);

              if (!isMfaEnabled) {

                this.form.addControl('username', new FormControl(userCopy ? userCopy.username : '', Validators.required))

                this.form.controls.email.enable();

                this.hasAnyUser('username').subscribe(result => {
                  this.usernameUnique = result;
                });
              }
            })
          } else {
            this.form = this.formBuilder.group({
              email: [{
                value: userCopy ? userCopy.email : '',
                disabled: this.isUpdate || !this.isFormEnabled
              }, Validators.compose([Validators.required, Validators.email])],
              first_name: [{
                value: userCopy ? userCopy.first_name : '',
                disabled: !this.isFormEnabled
              }, Validators.required],
              last_name: [{
                value: userCopy ? userCopy.last_name : '',
                disabled: !this.isFormEnabled
              }, Validators.required],
              phone_number: [{
                value: userCopy && userCopy.phone_number ? userCopy.phone_number.replace(this.phoneCountryCode, '') : '',
                disabled: !this.isFormEnabled
              }, Validators.pattern(this.phoneRegex)],
              mobile_number: [{
                value: userCopy && userCopy.mobile_number ? userCopy.mobile_number.replace(this.phoneCountryCode, '') : '',
                disabled: !this.isFormEnabled
              }, Validators.pattern(this.phoneRegex)],
              is_active: [{
                value: this.isUpdate ? userCopy.is_active : true,
                disabled: !this.isFormEnabled
              }, Validators.required],
              avatar: [userCopy ? userCopy.avatar : ''],
              country_id: [userCopy ? userCopy.country_id : '']
            });

            this.configService.findAll()
              .subscribe((config: Config) => {
                let isMfaEnabled: boolean = checkMfaStatusOrSetDefault(config);

                if (!isMfaEnabled) {
                  this.form.addControl('password', new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.passwordPattern)])))
                  this.form.addControl('confirm_password', new FormControl('', Validators.required))
                  this.form.addControl('username', new FormControl('', Validators.required))

                  this.hasAnyUser('username').subscribe(result => {
                    this.usernameUnique = result;
                  });
                }
              })
          }
          this.form.value.username = null;

          this.form.controls['is_active'].valueChanges
            .pipe(debounceTime(250))
            .subscribe((result) => {
              if(!result){
                this.selectedReportingRole = this.reportingRoles[0];
                this.transfereQSUser = this.selectedReportRolePrevValue.name === ROLE_REPORTS_ENUM.REPORTS_AUTHOR;
              }
            });

          if (this.user.id) {
            this.form.get('phone_number').markAsTouched();
            this.form.get('mobile_number').markAsTouched();
          }
          this.hasAnyUser('email').subscribe(result => {
            this.emailUnique = result;
          });
          this.initViewOnlyForm(this.user.id, 'View User', 'Edit User', 'users');
          this.afterInit();
        });
    });

  }

  isRadioButtonDisabled(role) {
    if(this.isUpdate){
      if(role.name === ROLE_REPORTS_ENUM.REPORTS_READER && this.selectedReportRolePrevValue.name  === ROLE_REPORTS_ENUM.REPORTS_AUTHOR) return true;
    }
    return !this.form.get('is_active').value
  }

  reportRoleChange() {
    this.transfereQSUser = this.selectedReportRolePrevValue && this.selectedReportRolePrevValue.name === ROLE_REPORTS_ENUM.REPORTS_AUTHOR && this.selectedReportingRole.name === ROLE_REPORTS_ENUM.NONE;
    if (this.transfereQSUser) {
      this.selectedTransfereQSUser = undefined;
      this.selectedTransfereQSMethod = 0;
    }
  }

  getAuthorReportsUsers() {
    const query = new Query({
      limit: 1000
    })
    this.userService.findAll(query).subscribe(res => {
      const filteredUsers = res.items.filter(user => user.userRoles.length && user.email !== this.user.email && user.userRoles.some(userRole => userRole.role.name === ROLE_REPORTS_ENUM.REPORTS_AUTHOR));
      this.QSTransfereUser = filteredUsers;
    })
  }

  init() {
    if (this.user && this.user.id) {
      return this.userService.findByIdForEdit(this.user.id, new EntityEditContext({
        dialogRef: this.dialogRef
      })).pipe(
        flatMap((user: any) => {
          this.user = user;
          return observableOf(user);
        }))
    }
    return observableOf(this.user);
  }

  userHasRoles() {
    let hasRoles = false;
    this.roles.forEach((item) => {
      if (item.selected) {
        hasRoles = true;
      }
    });
    return hasRoles;
  }

  concatAllTypeOfUserRoles(){
    let concatRoles: string[];
    if(this.selectedReportingRole.id !== 0){
      concatRoles = [...this.selectedRoleIds, this.selectedReportingRole.id].map(id => id.toString());
    }else{
      concatRoles = [...this.selectedRoleIds].map(id => id.toString());
    }
    return concatRoles;
  }

  onSubmit({ value, valid }: { value: User, valid: boolean }) {
    if (valid) {
      const roleValues = this.concatAllTypeOfUserRoles();
      this.user.roles = roleValues;

      value.avatar = this.userCopy.avatar;

      if (value.phone_number && !value.phone_number.includes(this.phoneCountryCode)) {
        value.phone_number = value.phone_number && value.phone_number.length ? this.phoneCountryCode + value.phone_number : null;
      }

      if (value.mobile_number && !value.mobile_number.includes(this.phoneCountryCode)) {value.mobile_number = value.mobile_number && value.mobile_number.length ? this.phoneCountryCode + value.mobile_number : null;
}
      
      if (this.user && this.user.id) {
        this.user['previous_report_role'] = this.selectedReportRolePrevValue;
        this.user['current_report_role'] = this.selectedReportingRole;
        this.user['selected_transfere_user'] = this.selectedTransfereQSUser;
        this.update(Object.assign({}, this.user, value));
      } else {
        value.roles = this.user.roles;
        // this.locationService.findCountryById(1)
        value.country_id = value.country_id ? value.country_id : this.defaultCountryId;
        value['current_report_role'] = this.selectedReportingRole;
        this.create(value);
      }
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  create(user: User) {
    this.loaderService.displayLoader();
    this.toggleDialogButtons();
    this.userService.create(user)
      .subscribe((result) => {
        this.loaderService.hideLoader();
        this.alert.success('', this.messages.get('CREATE_SUCCESS'));
        this.closeDialog(result, true);
      }, (err) => {
        this.loaderService.hideLoader();
        this.toggleDialogButtons(false)
        if (err.data && err.data.message) {
          this.alert.error('', err.data.message);
        } else {
          this.alert.error('', this.messages.get('CREATE_ERROR'));
        }
      });
  }

  update(user: User) {
    this.loaderService.displayLoader();
    this.toggleDialogButtons()
    this.userService.update(user.id, user)
      .subscribe((result) => {
        this.loaderService.hideLoader();
        this.userService.userChanged.emit(result);
        this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
        this.closeDialog(result, true);
      }, (err) => {
        this.loaderService.hideLoader();
        this.toggleDialogButtons(false)
        if (err.data && err.data.message) {
          this.alert.error('', err.data.message);
        } else {
          this.alert.error('', this.messages.get('UPDATE_ERROR'));
        }
      });
  }

  public editForm() {
    this.form.enable();
    this.viewOnly = false;
    this.formTitle = this.editTitle;

    super.editForm();

    let emailControl = this.form.get('email');
    emailControl.disable();
  }

  handleAvatarUpload(avatarDataUrl) {
    this.userCopy.avatar = avatarDataUrl;
  }

  getUserInitials(): string {
    if (this.user && this.user.first_name && this.user.last_name) {
      return this.user.first_name.toUpperCase()[0] + this.user.last_name.toUpperCase()[0];
    } else {
      return 'NN';
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  cancel() {
    this.userService.cancelEdit();
    this.closeDialog();
  }

  scrollHandler($event) {
    let element = $event.srcElement;
    if (Math.ceil(element.scrollTop) >= (element.scrollHeight - element.clientHeight)) {
      this.onScrollEnd(element);
    }
  }

  onScrollEnd($event) {
    if (this.query.canNext()) {
      this.query.page++;
      this.query.offset = this.query.from() - 1;
      this.loadRoles()
        .subscribe();
    }
  }

  onCountryChange(event) {
    this.selectedCountryId = event.value;
    this.locationService.findCountryById(this.selectedCountryId)
      .subscribe(result => {
        this.phoneCountryCode = result.phone_country_code || '';
        const replacedCode = this.phoneCountryCode.replace(/\d/g, '0');
        this.phoneRegexMask = result.phone_regex_mask.replace(replacedCode, '').trim();
        this.phoneRegex = result.phone_regex;
        this.selectedCountry = result;
        this.form.get('phone_number').markAsTouched();
        this.form.get('mobile_number').markAsTouched();
      })
  }

  public changeRoleStatus(roleId, event) {
    const found = this.selectedRoleIds.find((id) => id === roleId)
    if (found && !event.checked)
      this.selectedRoleIds = this.selectedRoleIds.filter(id => id != roleId)
    else if (!found)
      this.selectedRoleIds.push(roleId)
  }

  changeTransfere() {
    if (!this.selectedTransfereQSMethod) {
      this.selectedTransfereQSUser = undefined;
    } else if (this.QSTransfereUser.length) {
      this.selectedTransfereQSUser = this.QSTransfereUser[0].email;
    }
  }
}
