<mat-toolbar color="accent" mat-scroll-shrink>

  <a [routerLink]="['/dispute', id, 'show']" class="sneak-peek-title">{{ dispute?.dispute_id }}</a>

  <span class="example-fill-remaining-space"></span>

  <button mat-icon-button (click)="closeSide()">
    <ca-icon [key]="'CLOSE'"></ca-icon>
  </button>
</mat-toolbar>
<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard>
    <mat-card-content>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Vendor</label>
          <p>
            {{ dispute?.vendor?.name || 'N/A'}}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Filed By</label>
          <p>
            {{ dispute?.user?.username || 'N/A' }}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Filed Date</label>
          <p>
            {{ (dispute?.created_at | caDate) || 'N/A' }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Withheld Status</label>
          <p>
            {{ dispute?.withheld_status_obj?.value || 'N/A' }}
          </p>
        </div>
      </div>
      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Status</label>
          <p>
            {{ dispute?.status_obj?.value || 'N/A'}}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Resolution Date</label>
          <p>
            {{ (dispute?.resolution_date | caDate) || 'N/A' }}
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard>
    <mat-card-content>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Billed Charges</label>
          <p>
            {{ dispute?.total_amount | caCurrency:dispute?.currency?.currency }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Calculated Charges</label>
          <p>
            {{ dispute?.calculated_amount | caCurrency:dispute?.currency?.currency }}
          </p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Dispute Amount</label>
          <p>
            {{ dispute?.disputed_amount | caCurrency:dispute?.currency?.currency }}
          </p>
        </div>
        <div fxFlex class="entry-list-item">
          <label>Dispute Awarded</label>
          <p>
            {{ (dispute?.dispute_value_awarded | caCurrency:dispute?.currency?.currency) || 'N/A' }}
          </p>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex class="entry-list-item">
          <label>Payback Amount</label>
          <p>
            {{ dispute?.payback_amount | caCurrency:dispute?.currency?.currency }}
          </p>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>
