import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import Query from "../../query/query";

@Component({
  selector: "ca-boolean-filter",
  templateUrl: "boolean-filter.component.html",
})
export class BooleanFilterComponent implements OnInit, OnChanges {
  public internalBooleanQuery = {};
  @Input("query") query: Query;
  @Input("entry") entry: any;

  constructor() {}

  ngOnInit() {
    if (this.entry && this.entry.defaultValue) {
      this.entry.defaultValue.forEach((value) => {
        this.internalBooleanQuery[value] = true;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["query"]) {
      let query = changes["query"].currentValue;
      if (query) {
        query["where"] = query.where ?? {};
      }

      this.internalBooleanQuery = {
        true: false,
        false: false,
      };

      if (this.entry && this.entry.defaultValue) {
        this.entry.defaultValue.forEach((value) => {
          this.internalBooleanQuery[value] = true;
        });
      }
    }
  }

  ngOnBooleanChange(entry) {
    setTimeout(() => {
      let value = this.internalBooleanQuery;
      let keys = Object.keys(value);

      if (keys.length) {
        let intVal = [];
        keys.forEach((key) => {
          if (value[key]) {
            intVal.push(key);
          }
        });
        if (intVal.length) {
          this.query.where[entry["field"]] = { $in: intVal };
        } else {
          if (
            entry.field === "info_only_ind" &&
            this.internalBooleanQuery["false"] === false &&
            this.internalBooleanQuery["true"] === false
          ) {
            this.query.where[entry["field"]] = { $in: ["true", "false"] };
          } else {
            delete this.query.where[entry["field"]];
          }
        }
      } else {
        delete this.query.where[entry["field"]];
      }
    }, 50);
  }
}
