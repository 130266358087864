import { DictionaryService } from "./dictionary.service";
import { IFilterLookupProvider } from "../../core/filter/filter-lookup.provider";
import { Observable } from "rxjs";
import Query from "../../core/query/query";
import {map} from "rxjs";

export class LookupModelLookupProvider extends IFilterLookupProvider {
  constructor(
    public dictionaryService: DictionaryService,
    public lookupModel: string,
    public disableCache = false,
    public noKeyValuePairs?: boolean,
    public query?: Query
  ) {
    super();
  }

  public entries : any;

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery ? searchQuery : new Query();
    query.orderBy = [["value", "ASC"]];

    if (!this.noKeyValuePairs) {
      return this.dictionaryService
        .getByLookup(this.lookupModel,  this.query? this.query : query, this.disableCache).pipe(
        map((result: any) => {
            this.entries = result.items;
            this.entries.map(entry => {
              return {
                key: entry.id,
                value: entry.value
              };
            });
            this.entries.total = result.total;
            return this.entries;
        }));
    } else {
      return this.dictionaryService
        .getByLookup(this.lookupModel, query || this.query, this.disableCache).pipe(
        map((result: any) => {
          return {
            items: result.items.map(entry => {
              return entry.value;
            }),
            total: result.total
          };
        }));
    }

  }
}
