export const invoiceChargeProfileBrowserFilterContext = {
	filters: [
		{
			label: 'SPID',
			field: 'spid',
			type: 'string',
			index: 1,
			visible: true
		},
		{
			field: 'vendor.name',
			label: 'Vendor',
			type: 'lookup',
			lookupProviderName: 'vendor',
			index: 1
		},
		{
			label: 'Account',
			field: 'ban',
			type: 'lookup',
			lookupProviderName: 'accountLP',
			fieldToFilterWith: 'ban',
			index: 3
		},
		{
			label: 'Charge Amount',
			field: 'charge_amount',
			type: 'negativeNumber',
			index: 4,
			visible: true
		},
		{
			label: 'Charge Description',
			field: 'charge_description',
			type: 'string',
			index: 5,
			visible: true
		},
		{
			label: 'Charge Type',
			field: 'charge_type',
			type: 'lookup',
			editor: 'checkboxList',
			lookupProviderName: 'chargeType',
			index: 6,
			visible: true
		},
		{
			label: 'Active Profile Charges',
			field: 'is_active',
			type: 'boolean',
			trueLabel: 'Yes',
			falseLabel: 'No',
			index: 7,
			visible: true
		},
		{
			label: 'Expected Min Value',
			field: 'audit_rule_expected_min_value',
			type: 'negativeNumber',
			index: 8,
			visible: true
		},
		{
			label: 'Expected Max Value',
			field: 'audit_rule_expected_max_value',
			type: 'negativeNumber',
			index: 9,
			visible: true
		}
	]
};
