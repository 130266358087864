import {Action, State, StateContext, Store} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {ConfigService} from "./config.service";
import {LoadConfig, SetConfig} from "./config.actions";

@State<any>({
  name: 'config',
  defaults: {
    config: {}
  },
})
@Injectable()
export class ConfigState {

  constructor(private store: Store, private configService: ConfigService) {
  }

  @Action(LoadConfig)
  loadConfig(ctx: StateContext<any>): any {

    const state = ctx.getState();
    if (Object.keys(state?.config || {}).length)
      return Promise.resolve(state.config)

    return this.configService.findAll().toPromise()
      // .then((config) => {
      //   ctx.setState({
      //     config
      //   })
      // });
  }

  @Action(SetConfig)
  setConfig(ctx: StateContext<any>, action: SetConfig): void {
    const { config } = action.payload;

    ctx.setState({
      config
    });
  }
}
