<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>

  <button
    mat-icon-button
    matTooltip="Back"
    (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>

  <span class="toolbar-details-title">{{ inventory?.unique_id }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

  <button
    mat-icon-button
    *permission="{'action':'Create', 'key':'contracts'}"
    [disabled]="isAddContractTermsDisabled"
    [hidden]="isContractTermsHidden"
    (click)="addContractTerm()"
    matTooltip="New Contract Term">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>

  <button
    mat-icon-button
    *permission="{'action':'Modify', 'key':'contracts'}"
    [disabled]="isEditContractTermsDisabled"
    [hidden]="isContractTermsHidden"
    (click)="editContractTerm()"
    matTooltip="Edit Contract Term">
    <ca-icon [key]="'EDIT'"></ca-icon>
  </button>

  <button
    mat-icon-button
    *permission="{'action':'Delete', 'key':'contracts'}"
    [disabled]="isDeleteContractTermsDisabled"
    [hidden]="isContractTermsHidden"
    (click)="deleteContractTerm()"
    matTooltip="Delete Contract Term">
    <ca-icon [key]="'DELETE'"></ca-icon>
  </button>

  <button
    *permission="{'action':'Create', 'key':'gl'}"
    mat-icon-button
    [disabled]="isAddGLDisabled"
    [hidden]="isAddGLHidden"
    (click)="addGL()"
    matTooltip="New">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>

  <button
    *permission="{'action':'Create', 'key':'inventory'}"
    mat-icon-button
    [disabled]="isAddPricingDisabled"
    [hidden]="isAddPricingHidden"
    (click)="addPricing()"
    matTooltip="New">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>

  <button
    *permission="{'action':'Modify', 'key':'inventory'}"
    mat-icon-button
    (click)="edit()"
    [hidden]="isEditInventoryHidden"
    [disabled]="isEditInventoryDisabled"
    matTooltip="Edit">
    <ca-icon [key]="'EDIT'"></ca-icon>
  </button>

  <button
    mat-icon-button
    (click)="editChargeProfileStatus()"
    [hidden]="isEditChargeProfileHidden"
    [disabled]="isEditChargeProfileDisabled"
    matTooltip="Edit Charge Profile Status">
    <ca-icon [key]="'EDIT'"></ca-icon>
  </button>

  <button
    mat-icon-button
    *permission="{ action: 'Create', key: 'orders' }"
    [hidden]="isCreateOrderHidden"
    [matMenuTriggerFor]="menu"
    [disabled]="!isCreateOrderEnabled()"
    matTooltip="Create Order">
    <ca-icon [key]="'ORDER_TYPE'"></ca-icon>
  </button>

  <button mat-icon-button matTooltip="Save Charge Profile"
      [disabled]="isSaveDisabled"
      (click)="saveChargeProfile()"
      *ngIf="isChargeProfileTab">
    <ca-icon [key]="'APPLY'"></ca-icon>
  </button>

  <div class="vertical-divider" *ngIf="isPricingDocumentAction"></div>

  <app-document-manager-commands
    *ngIf="isPricingDocumentAction"

    [deleteDocumentActionHandler]="PRICING_MANAGER_SETTINGS.DELETE_PRICING_ACTION"
    [deleteDocumentDisablePredicate]="!pricingSelection"

    [uploadDocumentActionHandler]="PRICING_MANAGER_SETTINGS.UPLOAD_PRICING_ACTION"
    [uploadDocumentDisablePredicate]="false"

    [downloadDocumentActionHandler]="PRICING_MANAGER_SETTINGS.DOWNLOAD_PRICING_ACTION"
    [downloadDocumentDisablePredicate]="!pricingSelection"
  >
  </app-document-manager-commands>

  <button
    mat-icon-button
    (click)="csv()"
    [disabled]="isCSVDisabled"
    [hidden]="isCSVHidden"
    matTooltip="Download CSV"
  >
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>

  <button  
    mat-icon-button 
    (click)="gridSettings()" 
    [disabled]="isSettingsDisabled"
    [hidden]="isSettingsHidden"
    matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>

  <div class="vertical-divider" *ngIf="isDocumentAction"></div>

  <app-document-manager-commands
    *ngIf="isDocumentAction"

    [deleteDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DELETE_DOCUMENT_ACTION"
    [deleteDocumentDisablePredicate]="!documentSelection"

    [uploadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.UPLOAD_DOCUMENT_ACTION"
    [uploadDocumentDisablePredicate]="false"

    [downloadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DOWNLOAD_DOCUMENT_ACTION"
    [downloadDocumentDisablePredicate]="!documentSelection">

  </app-document-manager-commands>

  <div class="vertical-divider"></div>

  <button
    [disabled]="isFilterDisabled"
    [ngClass]="{'on' : isFilterOn}"
    (click)="toggleFilter()"
    mat-icon-button
    matTooltip="Filter">
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>

  <button
    [disabled]="isNotesDisabled"
    [ngClass]="{'on' : isNotesOn}"
    (click)="toggleNotes()"
    mat-icon-button
    matTooltip="Notes">
    <ca-icon [key]="notesCount > 0 ? 'NOTES' : 'NOTES_EMPTY'"></ca-icon>
  </button>

  <button
    [disabled]="isHistoryDisabled"
    [ngClass]="{'on' : isHistoryOn}"
    (click)="toggleHistory()"
    mat-icon-button
    matTooltip="History">
    <ca-icon [key]="'HISTORY'"></ca-icon>
  </button>

  <mat-menu class="ca-user-menu" #menu="matMenu">
    <ng-container *ngFor="let item of adjustmentsKeys">
      <button mat-menu-item  class="ca-capitalize-button" (click)="createOrder(item)">
        {{ item.split('_').join(' ').toLowerCase() }}
      </button>
    </ng-container>
  </mat-menu>
</mat-toolbar>
