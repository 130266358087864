import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs';
import { stringify } from 'qs';
import { CacheFactory } from 'cachefactory';
import { Cache } from '../cache.servce';
import { Router } from '@angular/router';
import { UserSessionService } from '../../shared/user-session/services/user-session.service';

@Injectable()
export class ApiService {
	private cacheFactory: CacheFactory = Cache.factory;

	constructor(
		private http: HttpClient,
		private router: Router,
		private userSessionService: UserSessionService
	) {}

	private formatErrors(response: any) {
		if (response && response.error && response.error.status === 403) {
			return this.router.navigate(['/auth/sign-in']);
		}
		if (response.status === 401) {
			this.userSessionService.removeSession();
		}
		return throwError(response);
	}

	getQueryString(query?: any) {
		const params = Object.assign({}, query || {});
		// TODO: Should we flatten where param in the query?
		// if (params.where) {
		//   Object.assign(params, params.where);
		//   delete params.where;
		// }
		return stringify(params, {
			encoder: function (str, defaultEncoder, charset, type) {
				if (type === 'value') {
					return encodeURIComponent(str);
				} else if (type === 'key') {
					return str;
				}
			}
		});
	}

	getPath(path) {
		if (Array.isArray(path)) {
			path = path.join('/');
		}

		return path;
	}

	getFullUrl(path, query?) {
		path = this.getPath(path);
		query = this.transformQuery(query);
		const args = [`${environment.API_URL}${path}`];
		const queryString = this.getQueryString(query);

		if (queryString) {
			args.push(queryString);
		}
		return args.join('?');
	}

	transformQuery(query) {
		return query && query.transform ? query.transform(query) : query;
	}

	clearAllCache() {
		this.cacheFactory.clearAll();
	}

	cache(
		name,
		options = {
			maxAge: 60 * 60 * 1000,
			deleteOnExpire: 'aggressive'
		}
	) {
		let cache;
		if (!this.cacheFactory) {
			this.cacheFactory = Cache.factory;
		}

		const { maxAge, deleteOnExpire } = options;
		if (!this.cacheFactory?.exists(name)) {
			cache = this.cacheFactory?.createCache(name, {
				maxAge,
				// @ts-ignore
				deleteOnExpire
			});
		} else {
			cache = this.cacheFactory?.get(name);
		}
		return cache;
	}

	get(path: string | any[], query = {}, params: any = {}): Observable<any> {
		const url = this.getFullUrl(path, query);
		return this.http.get(url).pipe(catchError(this.formatErrors.bind(this)));
	}

	getCustom(
		path: string | any[],
		query = {},
		params: any = {}
	): Observable<any> {
		const url = this.getFullUrl(path, query);
		return this.http
			.get(url, { responseType: 'blob', observe: 'response' })
			.pipe(catchError(this.formatErrors.bind(this)));
	}

	postCustom(path: string | any[], body = {}): Observable<any> {
		const url = this.getFullUrl(path);
		return this.http
			.post(url, body, { responseType: 'blob', observe: 'response' })
			.pipe(catchError(this.formatErrors.bind(this)));
	}

	put(path: string | any[], body = {}): Observable<any> {
		const url = this.getFullUrl(path);

		return this.http
			.put(url, body)
			.pipe(catchError(this.formatErrors.bind(this)));
	}

	post(path: string | any[], body = {}): Observable<any> {
		const url = this.getFullUrl(path);
		return this.http
			.post(url, body)
			.pipe(catchError(this.formatErrors.bind(this)));
	}

	patch(path: string | any[], body = {}): Observable<any> {
		const url = this.getFullUrl(path);
		return this.http
			.patch(url, body)
			.pipe(catchError(this.formatErrors.bind(this)));
	}

	delete(path: string | any[], body = {}): Observable<any> {
		const url = this.getFullUrl(path);
		return this.http
			.delete(url, { body })
			.pipe(catchError(this.formatErrors.bind(this)));
	}
}
