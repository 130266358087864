import { Injectable } from "@angular/core";
import { Permission } from "./permission";
import { BaseService } from "../../core/base.service";
import { Observable, of, zip, map, tap, first, flatMap, switchMap } from "rxjs";
import { ActivatedRoute } from "@angular/router";
// import {first, map, switchMap, tap} from "rxjs/operators";
// import {flatMap} from "rxjs/internal/operators";
import { Store } from "@ngxs/store";
import { SetPermissions } from "../permission.acctions";

@Injectable()
export class PermissionService extends BaseService<Permission> {
  constructor(private store: Store) {
    super("permission", null);
  }

  cache() {
    return this.httpService().cache(this.name);
  }

  me() {
    const cache = this.cache();
    return this.httpService().get('permission/me', null, {cache});
  }

  app() {
    const cache = this.cache();
    return this.httpService().get('permission/app', null, {cache});
  }

  package() {
    const cache = this.cache();
    return this.httpService().get('package', null, {cache});
  }

  isUnrestricted(permissions) {
    return permissions[0] === "*";
  }

  loadPermissions() {
    return this.store
      .select((state) => state.permissions)
      .pipe(
        first(),
        switchMap((state) => {
          if (state?.permissions?.length && state?.resources?.length) {
            // Permissions exist in the store, use them directly
            return of([state.permissions, { resources: state.resources }]);
          } else {
            // No permissions in store, fetch from backend
            return zip(this.me(), this.package()).pipe(
              tap(([permissions, res]) => {
                // Update permissions/resources in store / commented out part in permissions.state.ts
                this.store.dispatch(
                  new SetPermissions({ permissions, resources: res.resources })
                );
              })
            );
          }
        })
      );
  }

  isAllowed(resource, action): Observable<any> {
    return this.loadPermissions().pipe(
      map((result) => {
        const permissions = result[0];
        const { resources } = result[1];

        return this.isAllowedTo(permissions, resources, resource, action);
      })
    );
  }

  isAllowedTo(permissions, resources, resource, action) {
    const resourcesFromPackage = resources.find((res) => res.key === resource);
    const isAllowed =
      resourcesFromPackage &&
      Object.keys(resourcesFromPackage).includes("allowed")
        ? resourcesFromPackage.allowed
        : true;
    const isVisible =
      resourcesFromPackage &&
      Object.keys(resourcesFromPackage).includes("visible")
        ? resourcesFromPackage.visible
        : true;
    if (resourcesFromPackage && (!isVisible || !isAllowed)) {
      return false;
    }
    return this.canPerformAction({ key: resource }, action, permissions);
  }

  isRouteAllowed(route: ActivatedRoute, action) {
    return route.data.pipe(
      flatMap((result = {}) => {
        const { key } = result;
        return this.isAllowed(key, action);
      })
    );
  }

  isAdmin(): Observable<boolean> {
    return this.store
      .select((state) => state.permissions)
      .pipe(
        first(),
        switchMap((state) => {
          if (state?.permissions?.length && state.permissions[0]) {
            return of(this.isUnrestricted(state.permissions[0]));
          } else {
            return this.me().pipe(
              map((permissions) => {
                return !!this.isUnrestricted(permissions[0]);
              })
            );
          }
        })
      );
  }

  canPerformAction(resource, action, permissions) {
    if (this.isUnrestricted(permissions)) {
      return true;
    }

    let permissionForResources = this.findPermissionForResource(
      resource,
      permissions
    );
    const any = permissionForResources.filter((perm) => {
      return perm.action.toLowerCase() === action.toLowerCase();
    });

    return !!any.length;
  }

  reportPermission() {
    return this.loadPermissions().pipe(
      map((result) => {
        const permissions = result[0];
        const { resources } = result[1];

        // is allowed
        const resourcesFromPackage = resources.find(
          (res) => res.key === "reports"
        );
        const all =
          resourcesFromPackage &&
          Object.keys(resourcesFromPackage).includes("allowed")
            ? resourcesFromPackage.allowed
            : true;
        const vis =
          resourcesFromPackage &&
          Object.keys(resourcesFromPackage).includes("visible")
            ? resourcesFromPackage.visible
            : true;
        if (resourcesFromPackage && (!vis || !all)) {
          return false;
        }
        // have permission
        const filteredAdmin =
          permissions[0] === "*" && permissions.length > 1
            ? permissions.slice(1, permissions.length + 1)
            : permissions[0] === "*"
            ? []
            : permissions;
        let permissionForResources = filteredAdmin.filter((permission) => {
          return permission.resource_key === "reports";
        });
        const reportPerm = permissionForResources.some(
          (perm) => perm.action.toLowerCase() === "view"
        );

        return reportPerm;
      })
    );
  }

  public findPermissionForResource(resource, permissions) {
    return permissions.filter((permission) => {
      return permission.resource_key === resource.key;
    });
  }

  public create(item) {
    const { role_id, permissions } = item;
    return this.httpService().post([this.name], { role_id, permissions });
  }

  public update(id: number, permission: Permission) {
    return this.httpService().put([this.name, id], permission);
  }

  delete(id: number) {
    return this.httpService().delete([this.name, id]);
  }

  bulkDelete(ids: number[]) {
    return this.httpService().delete([this.name, 'bulk', ids]);
  }
}
