import { Injectable } from '@angular/core';
import { GridService } from '../../../../shared/grid/grid.service';
import { FormatService } from '../../../../shared/format/format.service';
import { UserSettingsService } from '../../../../user/core/user-settings.service';

@Injectable()
export class InvoiceAlertGridService extends GridService {
	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.common.invoice-alerts', userSettings);
	}

	defaultColumns = [
		{
			caption: '',
			headerCellTemplate: 'selectionBadgeHeaderTemplate',
			cellTemplate: 'selectionBadgeCellTemplate',
			fixed: true,
			width: 40,
			allowSorting: false
		},
		{
			caption: 'Type',
			dataField: 'type_value',
			visible: true,
			width: 200
		},
		{
			caption: 'Rule Name',
			dataField: 'rule_name',
			visible: true,
			width: 300
		},
		{
			caption: 'Status',
			dataField: 'status_value',
			visible: true,
			width: 100
		},
		{
			caption: 'Invoice Alert Message',
			dataField: 'message',
			visible: true,
			width: 400
		},
		{
			caption: 'Create Time',
			dataField: 'created_at',
			dataType: 'datetime',
			cellTemplate: 'dateTimeTemplate',
			alignment: 'left',
			visible: true,
			width: 180
		},
		{
			caption: 'Created by',
			dataField: 'creator_full_name',
			visible: true,
			width: 130
		},
		{
			caption: 'Closed Time',
			dataField: 'closed_at',
			dataType: 'datetime',
			cellTemplate: 'dateTimeTemplate',
			alignment: 'left',
			visible: true,
			width: 180
		},
		{
			caption: 'Run ID',
			dataField: 'run_id',
			visible: true,
			width: 120
		}
	];
}
