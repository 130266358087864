import { Injectable } from "@angular/core";
import { BaseService } from "../../core/base.service";

@Injectable()
export class BillService extends BaseService<any> {
  constructor() {
    super("bill");
  }

  checkBillStatus(id: number): any {
    return this.httpService().get([this.name, 'bill-status', id]);
  }

}
