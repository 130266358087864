import { Injectable } from '@angular/core';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';

@Injectable()
export class AlertDetailsGridService extends GridService {
	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.alert-inventory.details-grid', userSettings);
	}

	defaultColumns = [
		{ type: 'selection', width: 60 },
		{
			caption: 'Charge ID',
			dataField: 'charge_id',
			width: 80
		},
		{
			caption: 'SPID',
			dataField: 'sp_serv_id',
			width: 200
		},
		{
			caption: 'Begin Date',
			dataField: 'beg_chg_date',
			dataType: 'date',
			width: 90,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'End Date',
			dataField: 'end_chg_date',
			dataType: 'date',
			width: 90,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'Rate',
			dataField: 'rate_value',
			dataType: 'number',
			alignment: 'right',
			width: 70,
			format: {
				precision: 2,
				type: 'currency'
			}
		},
		{
			caption: 'Charge Amt',
			dataField: 'chg_amt',
			alignment: 'right',
			dataType: 'number',
			format: {
				precision: 2,
				type: 'currency'
			}
		},
		{
			caption: 'Calc Charge',
			dataField: 'calc_chg_amt',
			dataType: 'number',
			alignment: 'right',
			format: {
				precision: 2,
				type: 'currency'
			}
		},
		{
			caption: 'Message',
			dataField: 'audit_message'
		},
		{
			caption: 'Detail',
			calculateCellValue: (rowData) => {
				return `Rule ${rowData.meta_data.rule_id}. Charge Class ${rowData.chg_class}. Charge Description ${rowData.chg_desc_1}`;
			}
		}
	];
}
