
import {of as observableOf, Observable} from 'rxjs';
import {LOOKUP_ENUM} from '../../../dictionary/core/lookup.enum';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {Project} from "../../core/project";
import {AlertService} from "../../../shared/alert/alert.service";
import {ProjectService} from "../../core/project.service";
import { MatDialogRef } from "@angular/material/dialog";
import {LOOKUP_MODELS_ENUM} from "../../../dictionary/core/lookup-models.enum";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {EntityEditContext} from "../../../shared/entity-lock/entity-edit-context";
import {flatMap} from "rxjs";

@Component({
  selector: 'app-project-manage-dialog',
  templateUrl: './project-manage-dialog.component.html',
  styleUrls: ['./project-manage-dialog.component.css']
})
export class ProjectManageDialogComponent extends PageManageDialogComponent implements OnInit {

  project: any;
  labels: Array<any> = [];

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "project";

  readonly PROJECT_STATUS_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.PROJECT_STATUS.modelName;
  readonly CONTACT_TYPE_ENUM: any = LOOKUP_ENUM.CONTACT_TYPE;

  constructor(public formBuilder: FormBuilder,
              public alertService: AlertService,
              public projectService: ProjectService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<ProjectManageDialogComponent>) {

    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }


  ngOnInit() {

    this.isUpdate = !!(this.project && this.project.id);

    this.form = this.formBuilder.group({
      project_id: [this.project ? this.project.project_id : '', Validators.required],
      name: [this.project ? this.project.name : '', Validators.required],
      owner_id: [this.project ? this.project.owner_id : '', Validators.required],
      status_id: [this.isUpdate ? this.project.status_id : ''],
      start_date: [(this.project && this.project.start_date) ? new Date(this.project.start_date) : null],
      end_date: [(this.project && this.project.end_date) ? new Date(this.project.end_date) : null],
      description: [this.project ? this.project.description : '']
    });

    this.formTitle = this.isUpdate ? 'Edit Project' : 'Create Project';

    this.initializeLabels();
    this.afterInit();
  }

  init() {
    if (this.project && this.project.id) {
      return this.projectService.findByIdForEdit(this.project.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .pipe(flatMap((project) => {
          this.project = project;
          return observableOf(project);
        }))
    }
    return observableOf(this.project);
  }

  onSubmit({value, valid}: { value: Project, valid: boolean }) {

    if (valid) {
      if (this.project && this.project.id) {
        this.update(Object.assign({}, this.project, value));
      } else {
        this.create(value);
      }
    } else {
      this.alertService.error('', this.messages.get('FORM_INVALID'));

    }
  }

  create(project: Project) {
    this.toggleDialogButtons()
    this.projectService.create(project)
      .subscribe((result) => {

        this.closeDialog(result, true);

      }, (err) => {
        this.toggleDialogButtons(false)
        this.alertService.error('', this.messages.get('CREATE_ERROR'));

      });
  }

  update(project: Project) {
    this.toggleDialogButtons()
    this.projectService.update(project.id, project)
      .subscribe((result) => {

        this.closeDialog(result, true);

      }, (err) => {
        this.toggleDialogButtons(false)
        this.alertService.error('', this.messages.get('UPDATE_ERROR'));

      });
  }

  deleteProject(event) {
    if (this.project && this.project.id && event) {
      this.toggleDialogButtons()
      this.projectService.delete(this.project.id)
        .subscribe((deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({deleted: true});
          }
        }, (err) => {
          this.toggleDialogButtons(false)
          if (err.data.name == 'SequelizeForeignKeyConstraintError') {
            this.alertService.error('', this.messages.get('CONSTRAIN_DELETE_ERROR'));
          } else {
            this.alertService.error("", this.messages.get('DELETE_ERROR'));
          }
        });
    }
  }

  showLabel(date: string) {
    if (this.labels.indexOf(date) > -1)
      return true;
  }

  // TODO optimize this
  public toggleLabel(date: string) {
    if (this.form.controls[date].value) {
      if (this.labels.indexOf(date) == -1) {
        this.labels.push(date);
      }
    }
    else {
      if (this.labels.indexOf(date) == -1) {
        this.labels.push(date);
      }
      else {
        var index = this.labels.indexOf(date);
        this.labels.splice(index, 1);
      }
    }
  }

  public initializeLabels() {
    let dates = ['start_date', 'end_date'];
    dates.forEach((item) => {
      if (this.form.controls[item].value)
        this.labels.push(item);
    });
  }

  cancel() {
    this.projectService.cancelEdit();
    this.closeDialog();
  }

}
