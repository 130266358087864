import { Injectable } from "@angular/core";

import { FormatService } from "../../shared/format/format.service";
import { GridService } from "../../shared/grid/grid.service";
import { UserSettingsService } from "../../user/core/user-settings.service";

@Injectable()
export class ShortInvoiceChargeProfileGridService extends GridService {
  defaultColumns = [
    {
      caption: "SPID",
      dataField: "sp_serv_id",
      visible: true,
      width: 200,
    },
    {
      caption: "BAN",
      dataField: "acct_level_1",
      visible: true,
      width: 200,
    },
    {
      caption: "Vendor",
      dataField: "vendor.name",
      visible: true,
      width: 200,
    },
    {
      caption: "Charge Description",
      dataField: "chg_desc_1",
      visible: true,
      width: 240,
    },
    {
      caption: "Charge Amount",
      dataField: "chg_amt",
      dataType: "currency",
      alignment: "right",
      cellTemplate: "currencyTemplate",
      visible: true,
      width: 120,
    },
  ];

  constructor(
    public userSettings: UserSettingsService,
    public formatService: FormatService
  ) {
    super("app.cost.invoice-short-charges-profile-grid", userSettings);
  }
}
