import {Observable, of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Theme} from './theme';
import {UserSettingsService} from '../../user/core/user-settings.service';
import Query from '../../core/query/query';
import {ApiService} from "../../core/api";
import {mergeMap} from "rxjs";

@Injectable()
export class ThemeService extends BaseService<Theme> {

  static appThemeQuery = new Query({
    where: {
      is_app: true,
    },
    orderBy: [['id', 'ASC']],
  });

  static chartThemeQuery = new Query({
    where: {
      is_app: false,
    },
    orderBy: [['id', 'ASC']]
  });

  public static appThemes = [];

  public static activeTheme = {};

  readonly THEME_NAMES = {
    DEFAULT: 'Default',
    CUSTOM_THEME: 'Custom Theme',
  };

  readonly key = 'app.themes.scheme';
  readonly selectedKey = 'app.themes.selected';

  static setAppThemes(appThemes) {
    this.appThemes = appThemes;
  }

  static setActiveTheme(theme) {
    this.activeTheme = theme;
  }

  constructor(
    public apiService: ApiService,
    public userSettingsService: UserSettingsService
  ) {
    super('theme');
  }

  findOne(query) {
    return this.apiService.get(`${this.name}/one`, query);
  }

  saveSettings(schema) {
    schema.settings['selected'] = schema.name;
    return this.userSettingsService.saveUserSettings(this.selectedKey, {name: schema.name})
        .pipe(mergeMap(() => {
          if (schema.name === 'Custom Theme') {
            return this.userSettingsService.saveUserSettings(this.key, schema);
          } else {
            return observableOf(true);
          }
        }))
  }

  selectedTheme(): Observable<any> {
    return this.userSettingsService.entrySettingsNew(this.selectedKey);
  }

  customTheme(): Observable<any> {
    return this.userSettingsService.entrySettingsNew(this.key);
  }

  getActiveTheme(selTheme, userSettings) {
    let selectedTheme;

    if (!selTheme) {
      selectedTheme = ThemeService.appThemes.filter(
        item => item.name === this.THEME_NAMES.DEFAULT
      )[0];
    } else {
      if (selTheme.name !== this.THEME_NAMES.CUSTOM_THEME) {
        selectedTheme = ThemeService.appThemes.filter(
          item => item.name === selTheme.name
        )[0];
      } else {
        selectedTheme = userSettings[this.key];
      }
    }

    return selectedTheme;
  }

}
