import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  PICKER_COMPONENT_TEMPLATE,
  PickerComponentBase
} from '../../../shared/picker/ca-picker-base.component';

import { LocationService } from "../../core/location.service";
import { LocationQuery } from "../../core/location.query";

@Component({
  selector: 'ca-subdivision-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaSubdivisionPickerComponent),
      multi: true
    }
  ]
})
export class CaSubdivisionPickerComponent extends PickerComponentBase implements OnChanges, OnInit {

  readonly TEXT_FIELD = 'abbreviation';
  readonly SECOND_TEXT_FIELD = 'name';
  @Input() formControlName: string | number;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() placeholder = 'Select ...';
  @Input() contact_function: number;
  @Input() countryId: number;
  @Input() abbreviation: boolean;
  @Input() searchEnabled: boolean;
  @Input() clearEnabled: boolean;

  @Input() initialItem;
  @Input() pickerForm;
  @Input() countryCode;

  public control;

  query = new LocationQuery();
  subdivisions;

  constructor( public locationService: LocationService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    const code: SimpleChange = changes.countryCode
    this.countryCode = code.currentValue
    this.loadData()
  }

  loadEntries() {
    this.control = this.pickerForm.controls[this.formControlName];
    this.query.where['country_code'] = { '$eq': this.countryCode };
    this.query.orderBy = [['abbreviation', 'ASC']];
    return this.locationService.findAllSubdivision(this.query)
  }

  search(value: string){
    this.query.where['$or'] = [
      {abbreviation: { '$ilike': `%${value}%` }},
      {name: { '$ilike': `%${value}%` }}
    ];
    this.query.where['country_code'] = { '$eq': this.countryCode };
    this.query.orderBy = [['abbreviation', 'ASC']];
    return  this.locationService.findAllSubdivision(this.query);
  }

}
