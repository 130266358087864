<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content app-building-manage-dialog" *ngIf="form">
  <form class="ca-form" [formGroup]="form">
    <div class="flex-container" fxLayout>
      <div class="flex-item"
           [ngClass]="(!fields || fields && fields.length) ? 'caGapRight' : ''"
           fxFlex="{{ (!fields || fields && fields.length) ? '70%' : '100%'  }}">

        <div class="flex-container" fxLayout="row" fxLayout.sm="column">
          <div class="flex-item caGap" fxFlex="0 0 100%">
            <mat-form-field caInput>
              <mat-label>Name</mat-label>
              <input matInput required formControlName="name" type="text">
              <mat-hint *ngIf="form.hasError('required', ['name']) && form.controls.name.touched" class="error-class">
                {{VALIDATION.REQUIRED_ERROR}}
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="mb-24">
          <ca-address-selection
            [required]="!formModeEnabled"
            [address]="building?.address"
            [disabled]="viewOnly"
            (addressChanged)="onAddressChanged($event)"
            (isFormMode)="onFormModeChanged($event)"
          ></ca-address-selection>
        </div>

      </div>

      <div *ngIf="fields && fields.length"
           fxFlex="300px" fxLayout="column" style="position: relative;"
           class="custom-fields-wrapper"
      >
        <ca-custom-fields
          [form] = "this.form"
          [customFieldService]="this.buildingService"
          [customField]="this.building"
          [fields]="fields"
          [categories]="categories"
          [showCategory]="showCategory"
          [clearEnabled]="true"
          [onlyRequiredCustomFields] = "false"
        ></ca-custom-fields>
      </div>
    </div>
  </form>
</div>

<mat-dialog-actions class="ca-dialog-action" *ngIf="!viewOnly">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button #genericButton *ngIf="isUpdate && deletePermission" [confirmText]="'Delete?'"
      (deleteRequested)="deleteBuilding($event)"></ca-confirm-delete-button>
  </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
      Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit(form)"
          [disabled]="form?.invalid || !address">
    Save
  </button>
</mat-dialog-actions>

<mat-dialog-actions class="ca-dialog-action" *ngIf="viewOnly">
  <div class="example-fill-remaining-space"></div>

  <button mat-button type="submit" (click)="closeDialog()">
    Cancel
  </button>

  <button  *permission="{'action':'Modify', 'key':'buildings'}" mat-button type="submit" (click)="editForm()">
    Edit
  </button>
</mat-dialog-actions>
