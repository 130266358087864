import {throwError as observableThrowError, Observable, catchError, map} from 'rxjs';

import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  AfterViewInit, OnChanges
} from '@angular/core';
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';

import {Document} from '../../../shared/document/core/document';
import {DocumentService} from '../../../shared/document/core/document.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ContractScheduleDetailDocumentGridService} from '../../core/contract-schedule-detail-document-grid.service';
import {DocumentUploadDialogComponent} from '../document-upload-dialog/document-upload-dialog.component';
import {DocumentQuery} from '../../core/contract-document.query';
import {LOOKUP_ENUM} from '../../../dictionary/core/lookup.enum';
import {ContractDocument} from "../../core/contract-document";
import {ContractService} from "../../core/contract.service";
import Query from '../../../core/query/query'
import {PageListComponent} from "../../../core/page-list.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {LoaderService} from "../../../shared/loader/loader.service";

const SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;


@Component({
  selector: 'app-contracts-schedule-document-manager',
  templateUrl: './contracts-schedule-detail-document-manager.component.html',
  styleUrls: ['./contracts-schedule-detail-document-manager.component.css']
})
export class ContractsScheduleDetailDocumentManagerComponent extends PageListComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() folder: string;
  @Input() subfolder: string;
  @Input() parentType: string;
  @Input() entityType: string;
  @Input() entity: any;
  @Input() tabChanged: boolean;
  @Input() contractDocument: any;

  @Output() docAdd = new EventEmitter();
  @Output() docDelete = new EventEmitter();
  @Output() docSelected = new EventEmitter();

  public documents: Array<Document> = [];
  public selection: Document;
  public columns: Array<any>;
  public totalDocuments: number;
  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'contract';

  public query: Query = new Query({
    orderBy: [['contract_schedule.schedule_name', 'ASC']]
  });


  constructor(public documentService: DocumentService,
              public contractService: ContractService,
              public settingsService: UserSettingsService,
              public loaderService: LoaderService,
              public dialog: DialogService,
              public alert: AlertService,
              public documentScheduleDetailGridService: ContractScheduleDetailDocumentGridService) {
    super(new PageContext({
      name: 'app.contract_schedule_details_document.contract_schedule_details_document_list',
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.columns = this.documentScheduleDetailGridService.columns();
  }

  ngAfterViewInit(): void {
    this.documentScheduleDetailGridService.create(this.dataGrid.instance, {
      noDataText: 'No Data '
    });
  };

  onSelectionChanged(row) {
    this.selection = <Document>row.selectedRowsData[0];
    this.docSelected.emit(this.selection);
  }


  clearSelection() {
    this.dataGrid.instance.clearSelection();

    this.dataGrid.instance.refresh();
    this.dataGrid.instance.repaint();
  }

  loadData(query?: DocumentQuery) {

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);
    this.query.where['contract_schedule_id'] = this.entity.id
    if(query){
      this.query.orderBy = query.orderBy
    }
    this.contractService.findDocuments(this.entity.id,this.query).subscribe((result)=>{
      this.documents = result.items;
      this.query.total = this.totalDocuments = result.total;
      this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
    }, (err) => {
      this.alert.error('', this.messages.get('DOCUMENT_GET_ERROR'));
      this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
    })
  }

  public onRowClick(row) {

  }
  public onPageChange(query: Query) {
    this.loadData(query);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.entity && this.folder) {
      this.loadData();
    }

    if (changes['tabChanged']) {
      if (this.dataGrid && this.dataGrid.instance) {
        this.selection = null;
        this.docSelected.emit(null);

        this.dataGrid.instance.refresh();
        this.dataGrid.instance.repaint();
      }
    }
  }

  isMainDoc(docType) {
    return docType === LOOKUP_ENUM.DOCUMENT_TYPE.MSA || docType === LOOKUP_ENUM.DOCUMENT_TYPE.SERVICE_SCHEDULE;
  }

  addEntityDocument(contract): void {

    let entityId = this.entity ? this.entity.id : null;
    let entityType = this.entityType;
    let masterId = entityId;
    let masterType = this.entityType;

    this.dialog.open(DocumentUploadDialogComponent, {
      folder: this.folder,
      subfolder: this.subfolder,
      entity: Object.assign(this.entity, {parentType: this.parentType}),
      entity_type: entityType,
      entity_id: entityId,
      master_id: masterId,
      master_type: masterType,
      contractId: contract.id,
      contract_schedule_id: contract.contract_schedule_id,
      disableContractSchedulePicker: contract.disableContractSchedulePicker
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.alert.success('', this.messages.get('DOCUMENT_UPLOAD_SUCCESS'));
            this.docAdd.emit();

            this.loadData();
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('DOCUMENT_UPLOAD_ERROR'));
        }
      );
  }

  downloadDocument(doc: Document) {
    this.documentService.downloadDocument(this.selection.id, this.folder)
      .pipe(map(response => this.extractBlob(response)))
      // .catch(error => observableThrowError(error));
  };

  public extractBlob(data) {
    let blob = new Blob([data], {type: this.selection.type});
    return blob || {};
  }

  deleteEntityDocument(selection) {
    this.dialog.confirm({
      bodyText: `Delete document ${selection.document_name}?`
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.contractService.deleteContractDocument(selection.id)
              .subscribe(
                (deleteResult) => {
                  this.docDelete.emit();
                  this.alert.success('', this.messages.get('DOCUMENT_DELETE_SUCCESS'));

                  this.loadData();
                }, (err) => {
                  if (err.data.name === 'SequelizeForeignKeyConstraintError') {
                    this.alert.error('', this.messages.get('DELETE_CONFLICT_ERROR'));
                  }
                }
              );
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('DOCUMENT_DELETE_ERROR'));
        }
      );
  }
}
