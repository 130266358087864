<ng-container class="notification">
<button mat-icon-button class="notification-trigger" [matMenuTriggerFor]="notificationMenu"
        #menuTrigger="matMenuTrigger">
  <ca-icon [key]="'NOTIFICATION'"></ca-icon>
  <span *ngIf="newNotificationsCount > 9" class="badge badge-accent badge-number indented">9+</span>
  <span *ngIf="singleDigit" class="badge badge-accent badge-number">{{newNotificationsCount}}</span>
</button>
<mat-menu #notificationMenu="matMenu" [style.width]="'660px'" class="notification-menu">
  <mat-list class="notification-list" (scroll)="endScrollHandler($event)">
    <mat-list-item *ngIf="!notifications.length && !isLoading" class="notification-entry">
      <div class="ca-empty-state-container">
        <p class="small-div-message">No messages</p>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="!notifications.length && isLoading" class="notification-entry">
      <div class="ca-empty-state-container">
        <p class="small-div-message">Loading...</p>
      </div>
    </mat-list-item>
    <ng-container *ngFor="let entry of notifications" >
      <mat-list-item class="notification-entry" (click)="$event.stopPropagation();"
                     [ngClass]="{'unread' : !entry.is_read}"
      >
        <div fxFlex fxLayout="column" class="notification-entry-content">
          <div matLine class="notification-entry-date" fxFlex="25px" fxLayout="row">
            <div [fxFlex]="hasDownload(entry) || hasBatchLink(entry)  ? 'calc(100%-80px)' : 'calc(100%-54px)' ">
            {{entry?.created_at | date}}
            {{entry?.created_at | date:"HH:mm:ss"}}
            </div>
            <div class="notification-entry-commands" [fxFlex]="hasDownload(entry) || hasBatchLink(entry) || hasAlertLink(entry) ? '80px' : '54px'">
              <button mat-icon-button matTooltip="Gl batch" *ngIf="hasBatchLink(entry)" [disableRipple]="true">
                <ca-icon [key]="'VIEW_INVOICE_DETAILS'" (click)="redirectTo(entry, this.ROUTING_PLACES.GL_BATCH_DETAILS)" [tooltip]="'Redirect to gl batch'"></ca-icon>
              </button>
              <button mat-icon-button (click)="download(entry, $event)" matTooltip="Download CSV"
                      *ngIf="hasDownload(entry)" [disableRipple]="true">
                <ca-icon [key]="'DOWNLOAD'"></ca-icon>
              </button>
              <button mat-icon-button (click)="remove(entry, $event)" [disableRipple]="true" matTooltip="Remove">
                <ca-icon [key]="'DELETE'"></ca-icon>
              </button>
              <button mat-icon-button (click)="setRead(entry, $event)" [disableRipple]="true" [matTooltip]="entry.is_read ? 'Mark as unread' : 'Mark as read'">
                <ca-icon [key]="'IS_READ'" *ngIf="!entry.is_read"></ca-icon>
                <ca-icon [key]="'IS_UNREAD'" *ngIf="entry.is_read"></ca-icon>
              </button>
            </div>
          </div>
          <div class="notification-entry-title"  matLine> {{entry?.message}}
            <span>{{entry?.alert_id ? 'See Alert ' : ''}}</span>
            <span (click)="redirectToAlert(entry)" class="underlined-id">{{entry?.alert_id ? entry?.alert_id : ''}}</span>
            <span>{{entry?.alert_id ? ' for details.' : ''}}</span>
          </div>
        </div>
      </mat-list-item>
    </ng-container>
  </mat-list>
</mat-menu>
</ng-container>
