import { Injectable } from '@angular/core';
import { DisputeCharge } from './dispute-charge';
import { InvoiceCharge } from './invoice-charge';
import { DisputeStatusLookup } from '../../dispute/core/dispute-status.lookup';

@Injectable()
export class DisputeChargeService {
	constructor() {}

	mapFromCharges(
		charges: Array<InvoiceCharge>,
		disputeStatusLookup: DisputeStatusLookup,
		resDate?
	): Array<DisputeCharge> {
		let disputeCharges: Array<DisputeCharge> = [];

		if (charges && charges.length) {
			charges.map((charge) => {
				let disputeCharge = new DisputeCharge();
				disputeCharge.charge_id = charge.id;
				disputeCharge.charge = charge;
				disputeCharge.status = disputeStatusLookup.NEW.id;
				disputeCharge.status_obj = disputeStatusLookup.NEW;
				disputeCharge.currency_id = charge['currency_id'];
				disputeCharge.subclient = charge['account']?.subclient?.value;

				// disputeCharge.resolution_date = resDate;
				disputeCharges.push(disputeCharge);
			});
		}

		return disputeCharges;
	}
}
