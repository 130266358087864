import { OrderFlowService } from '../../order/core/order-flow.service';
import { InventoryService } from '../../inventory/core/inventory.service';
import { NotesService } from '../../shared/notes/core/notes.service';
import { Notes } from '../../shared/notes/core/notes';
import { OrderServiceService } from './order-service.service';
import { OrderService } from './order-service';
import { OrderService as OrderSvc } from '../../order/core/order.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { FlowStep } from '../../shared/flow/flow-step';
import { Flow } from '../../shared/flow/flow';
import { FlowService } from '../../shared/flow/flow.service';
import { Injectable, EventEmitter } from '@angular/core';
import { ORDER_SERVICE_DISCONNECT_STATUS } from "./order-service-disconnect-status.enum";
import { ORDER_SERVICE_STATUS } from './order-service-record-only-status.enum'
import { ORDER_STATUS } from "../../order/core/order-status.enum";
import { DataLockService } from "../../core/data-lock/data-lock.service";
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { AlertService } from '../../shared/alert/alert.service';
import {ConfirmDialogComponent} from "../../shared/dialog/confirm-dialog/confirm-dialog.component";
import {mergeMap} from "rxjs";
import {LookupModel} from "../../dictionary/core/lookup.model";

@Injectable()
export class OrderServicRecordOnlyFlowService extends FlowService {

  public orderService: OrderService;

  readonly ORDER_SERVICE_STATUS = ORDER_SERVICE_STATUS;
  readonly ORDER_STATUS_ENUM = ORDER_STATUS;
  readonly ORDER_SERVICE_STATUS_ENUM = ORDER_SERVICE_STATUS;

  readonly ICONS = {
    INVOICE_FLOW_WARNING: 'INVOICE_FLOW_WARNING',
    INVOICE_FLOW_ERROR: 'INVOICE_FLOW_ERROR',
    APPROVE_INVOICES:'APPROVE_INVOICES'
  };

  readonly DATA_LOCK_CLOSE_STATUS = {
    CANCEL_BY_USER: 0,
    TIME_EXTEND: 1,
    CANCEL_BY_TIMER: 2
  };

  public orderServiceFlow;
  public onStatusChange: EventEmitter<any> = new EventEmitter();
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "order";
  public orderServiceStatuses: Array<LookupModel>;

  constructor(public dialogService: DialogService,
    public orderServiceService: OrderServiceService,
    public orderFlowService: OrderFlowService,
    public orderSvc: OrderSvc,
    public notesService: NotesService,
    public alertService: AlertService,
    public inventoryService: InventoryService,
    public dataLockService: DataLockService) {
    super();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  createFlow(orderService: OrderService): Flow {
    this.orderService = orderService;
    this.orderServiceFlow = super.create({ currentStep: 1 });

    const statuses = {
      'NEW': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW) || 'New',
      'NEW_RESET': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET) || 'New (Reset)',
      'RFA': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA) || 'Ready for Approval',
      'COMPLETE': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.COMPLETED) || 'Complete',
      'CANCELLED': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.CANCELLED) || 'Cancelled',
      'NEW_PENDING': this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) || 'New (Pending)'
    };

    let status: number = orderService.state;
    const new_or_reset_name = status === this.ORDER_SERVICE_STATUS_ENUM.NEW ? statuses.NEW : statuses.NEW_RESET;
    if (!status && status !== this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) {
      status = this.ORDER_SERVICE_STATUS.NEW;
    }
    this.orderServiceFlow.steps.push(new FlowStep({
      name: new_or_reset_name ,
      code: 1,
      revertStatusAlert: 'To reset to New, item needs to be in Approved state before.'
    }));
    this.orderServiceFlow.steps.push(new FlowStep({
      name: statuses.RFA, code: 2,
    }));
    this.orderServiceFlow.steps.push(new FlowStep({
      name: statuses.COMPLETE, code: 3,
      progressStatusAlert: 'Item needs to be in Record Only Complete state before.'
    }));

    this.setStepDates(this.orderServiceFlow, orderService);
    this.updateSteps(this.orderServiceFlow, status);
    return this.orderServiceFlow;
  }

  // Gets step name, and returns value from dictionary
  generateName(uid: number) {
    if (this.orderServiceStatuses && this.orderServiceStatuses.length && this.orderServiceStatuses.find(i => i.id === uid)) {
      return this.orderServiceStatuses.find(i => i.id === uid).value;
    }

    return null;
  }
  elapsedTimeModal(status:any){
    return status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER && status !== this.DATA_LOCK_CLOSE_STATUS.TIME_EXTEND && status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER
  }

  handleStepSelection(flow: Flow, orderService: OrderService, step) {
    let code = step.code;
    this.orderService = orderService
    if (code === 1 && orderService.state === this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) {
      this.alertService.error('', this.messages.get('ORDER_SERVICE_REQUIRED'));
    }
    else if (code == 2 ) {
      if(flow.currentStep === 1){
      this.dialogService.edit(
        ConfirmDialogComponent,
        {
          order: this.orderServiceService.findByIdForEdit(this.orderService.id),
          bodyText: 'Order Service is ready for approval?'
        },
        {
          width: '350px'
        }).pipe(
        mergeMap(x => x.afterClosed()))
        .subscribe(result => {
          if (result && this.elapsedTimeModal(result.status)) {
            let status: number = this.ORDER_SERVICE_STATUS_ENUM.RFA;
            this.orderService.state = status;
            this.orderService.ready_for_approval = new Date();
            this.orderServiceService.update(this.orderService.id, this.orderService)
              .subscribe((result) => {
                this.orderService = result;
                //Object.assign(this.orderService, result);
                this.setStepDates(flow, orderService);
                this.updateSteps(flow, status);
                this.updateOrderFlow(this.orderService.order_id, this.ORDER_SERVICE_STATUS_ENUM.RFA,
                  this.ORDER_STATUS_ENUM.RFA, null);
              });
          }
        })
      }
    }
    else if (code == 3) {
      if (this.orderService.inventory.ban.indexOf('TEMP') === 0 || this.orderService.inventory.sp_ckt_id.indexOf('TEMP') === 0)
        this.alertService.error('', this.messages.get('ORDER_SERVICE_TEMP_BAN_CKT'));
      else if (ORDER_SERVICE_STATUS.COMPLETED !== this.orderService.state && ORDER_STATUS.APPROVED === this.orderFlowService.order.status.id) {
        this.dialogService.edit(
          ConfirmDialogComponent,
          {
            order: this.orderServiceService.findByIdForEdit(this.orderService.id),
            bodyText: 'Are you sure that you want to Complete this order service?'
          },
          {
            width: '350px'
          }).pipe(
          mergeMap(x => x.afterClosed()))
          .subscribe(result => {
            if (result && this.elapsedTimeModal(result.status)) {
              let status: number = this.ORDER_SERVICE_STATUS_ENUM.COMPLETED;
              this.orderService.state = status;
              this.orderService.record_only_complete_date = new Date();
              this.orderServiceService.update(this.orderService.id, this.orderService)
                .subscribe((result) => {
                  this.orderService = result;
                  //Object.assign(this.orderService, result);
                  this.setStepDates(flow, orderService);
                  this.updateSteps(flow, status);
                  this.updateOrderFlow(this.orderService.order_id, this.ORDER_SERVICE_STATUS_ENUM.COMPLETED,
                    this.ORDER_STATUS_ENUM.COMPLETED, null);
                });
            }
          })
        } else if (ORDER_SERVICE_STATUS.COMPLETED === this.orderService.state) {
          return;
        } else {
            this.alertService.error('', this.messages.get('ORDER_RECORD_ONLY_APPROVED'));
        }
    }
  }

  public setStepDates(flow: Flow, orderService: OrderService) {
    flow.steps.forEach((item) => {
      switch (item.code) {
        case(1): {
          item.dateInfo = orderService.created_at|| null;
          break;
        }
        case (2): {
          item.dateInfo =(orderService.ready_for_approval || this.orderFlowService.order.ready_for_approval) || null;
          break;
        }
        case (3): {
          item.dateInfo = orderService.record_only_complete_date || null;
          break;
        }
       }
    });
  }

  public updateOrderFlow(orderId, orderServiceState, orderNewState, orderRevertState) {
    // try this with emiters
    // update Order status to complete
    this.orderSvc.findById(orderId)
      .subscribe((order) => {
        let updateOrder = true;
        //if one service is not completed order is not completed
        order.services.forEach((service) => {
          if (!(service.state == orderServiceState))
            updateOrder = false;
        });

        if (updateOrder == true) {
          order.status_id = orderNewState;

          if (order.status_id === this.ORDER_STATUS_ENUM.COMPLETED && !order.complete_date) {
            order.complete_date = new Date().toUTCString();

            this.orderSvc.update(order.id, order)
              .subscribe((res) => {
                this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
                this.orderFlowService.updateSteps(order.status_id)
                this.orderFlowService.setStepDates(order)
              });
          }

          if (orderNewState === this.ORDER_STATUS_ENUM.RFA && order.status['id'] !== this.ORDER_STATUS_ENUM.RFA) {
            order.ready_for_approval = new Date();
            this.orderSvc.update(order.id, order)
              .subscribe((res) => {
                this.orderFlowService.updateSteps(order.status_id)
                this.orderFlowService.setStepDates(order)
              });
          }
        } else if (order.status_id == orderNewState && orderRevertState) {
          order.status_id = orderRevertState;
          this.orderSvc.update(order.id, order)
            .subscribe((res) => {
              this.orderFlowService.updateSteps(order.status_id)
            });
        }
      });
  }

  public updateSteps(flow, status: number) {
    if(status === this.ORDER_SERVICE_STATUS_ENUM.NEW){
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = 'New';
          s.icon = this.ICONS.APPROVE_INVOICES
          // s.revertStatusAlert =
          // `To reset to ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET)},
          // item needs to be in ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA)} state before.`
        }
      });
    }
    else if(status === this.ORDER_SERVICE_STATUS.NEW_REJECTED){
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = 'New (Reject)';
          //I have to research for what we use this code
          // s.revertStatusAlert =
          //   `To reject to ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_REJECTED)},
          // item needs to be in ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA)} state before.`
        }
      });
    }else if(status === this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET){
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = 'New (Reset)';
          // s.revertStatusAlert =
          // `To reset to ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET)},
          // item needs to be in ${this.generateName(this.ORDER_SERVICE_STATUS_ENUM.RFA)} state before.`
        }
      });
    }else if (status === this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) {
      this.orderServiceFlow.steps.forEach((s) => {
        if (s.code === 1) {
          s.name = 'New (Pending)';
          s.icon = this.ICONS.INVOICE_FLOW_ERROR
        }
      });
    }

    if (status === this.ORDER_SERVICE_STATUS.NEW) {
      this.updateStep(1, 'New');
      flow.currentStep = 1;
    }
    else if(status === this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING) {
      this.updateStep(1,'New (Pending)','mat-error', this.ICONS.INVOICE_FLOW_ERROR)
      flow.currentStep = 1;
    }
    else if(status === this.ORDER_SERVICE_STATUS.NEW_REJECTED){
      this.updateStep(1,'New (Rejected)');
      flow.currentStep = 1;
    }
    else if (status == this.ORDER_SERVICE_STATUS_ENUM.NEW_RESET) {
      this.updateStep(1,'New (Reset)');
      flow.currentStep = 1;
    }
    else if (status === this.ORDER_SERVICE_STATUS.RFA) {
      flow.currentStep = 2;
    }
    else if (status === this.ORDER_SERVICE_STATUS.COMPLETED) {
      flow.currentStep = 3;
    }
    this.onStatusChange.emit(status);
  }

  public addNote(noteMessage) {
    let note = new Notes({
      entity_id: this.orderService.order_id,
      entity_type: "order",
      content: noteMessage
    });

    this.notesService.create(note);
  }
}
