<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Approve/Reject</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">

  <form class="ca-form  dialog-content-no-margin" [formGroup]="form">
    <div>
      <mat-form-field caInput>
        <mat-label>Note</mat-label>
        <textarea formControlName="note" name="note" rows="3" matInput></textarea>
        <mat-hint *ngIf="!form.valid">{{this.messages.get('CHARACTER_LENGTH_ERROR')}}</mat-hint>
      </mat-form-field>
    </div>
  </form>
</div>

<mat-dialog-actions class="ca-dialog-action" *ngIf="form">
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button type="submit" (click)="cancel()">
    Cancel
  </button>
  <button mat-button
          type="button"
          [disabled]="form?.invalid || !form.controls['note'].value"
          (click)="reject(form)">
    Reject
  </button>
  <button mat-button
          class="previous"
          [disabled]="form?.invalid"
          type="button"
          (click)="approve()">
    Approve
  </button>
</mat-dialog-actions>
