import {of as observableOf} from 'rxjs';

import {takeUntil} from 'rxjs';
import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {PageListComponent} from '../../core/page-list.component';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {CommonAlertService} from '../core/common-alert.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

import Query from "../../core/query/query";
import {AlertAccountGridService} from '../core/alert-account-grid.service';
import {GridSettingsComponent} from '../../core/grid/grid-settings.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {TabGroup, TabService} from '../../shared/tabs/tab.service';
import {AlertAccountFilterService} from '../core/alert-account-filter.service';

import {GridService} from '../../shared/grid/grid.service';

import {IMessagesResourceService} from 'app/core/resources/resources.service';
import {AlertService} from 'app/shared/alert/alert.service';
import {ResourcesService} from '../../core/resources/resources.service';
import {LoaderService} from '../../shared/loader/loader.service';


@Component({
  selector: 'app-alert-account',
  templateUrl: './alert-account.component.html',
  styleUrls: ['./alert-account.component.css']
})
export class AlertAccountComponent extends PageListComponent
  implements AfterViewInit, OnDestroy {
  alert;
  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTab = 0;
  public accounts: Array<any> = [];
  public columns: any;
  public sorting: any[][];
  public selection: any;
  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  readonly MESSAGES_MODULE: string = 'common-alert';
  readonly sideSections = {
    none: null,
    info: 'info',
    filter: 'filter'
  };

  _isSidePanelOpen = false;
  activeTabIndex = 0;
  isGridSettingsDisabled = true;
  messages: IMessagesResourceService;

  readonly PLACEHOLDERS = {
    OWNER_UNASSIGNED: 'Unassigned'
  };

  public query: Query = new Query({
    orderBy: [['id', 'ASC']]
  });

  public alertId: number;

  constructor(
    public settingsService: UserSettingsService,
    public route: ActivatedRoute,
    public commonAlertService: CommonAlertService,
    public alertService: AlertService,
    public alertAccountGridService: AlertAccountGridService,
    public router: Router,
    public dialogService: DialogService,
    public loaderService: LoaderService,
    public alertAccountFilterService: AlertAccountFilterService,
    public tabService: TabService
  ) {
    super(
      new PageContext({
        name: 'app.alert.alert-account',
        settings: settingsService
      })
    );

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    if (
      tab.key === 2 &&
      this.sider.getActiveSection().name === this.sideSections.filter
    ) {
      this._isSidePanelOpen = true;

      observableOf(true).subscribe(() => {
        this._isSidePanelOpen
          ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME)
          : this.sider.closeComponent();

        this.tabGroup.forceRerender();
      });
    }

    if (
      tab.key !== 2 &&
      this.sider.getActiveSection().name === this.sideSections.filter
    ) {
      this._isSidePanelOpen = false;

      observableOf(true).subscribe(() => {
        this._isSidePanelOpen
          ? this.sider.open(this.SECTIONS.FILTER_SECTION_NAME)
          : this.sider.closeComponent();

        this.tabGroup.forceRerender();
      });
    }

    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }

    /* Settings grid disabled */
    if (
      this.tabGroup.isActive(2) ||
      this.tabGroup.isActive(3) ||
      this.tabGroup.isActive(4)
    ) {
      this.isGridSettingsDisabled = false;
    } else {
      this.isGridSettingsDisabled = true;
    }
  }

  public loadTabs() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({key: 1, title: 'Summary'});
    this.tabGroup.addTab({key: 2, title: 'Details'});

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe(tab => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }

  loadData(alertId: number) {
    this.commonAlertService.findById(this.route.params['_value'].id).subscribe(
      alert => {
        this.alert = alert;
        this.loadAccoutDetails(this.query)
      },
      error => {
        console.log('Error fetching alert info');
      }
    );
  }

  loadAccoutDetails(query: Query) {
    this.commonAlertService
      .findAccount(this.alertId, query)
      .subscribe(result => {
        this.accounts = result.rows;
        query.total = result.count;
        this.selectFirstRow();
        setTimeout(() => {
          this.dataGrid.instance.refresh();
          this.dataGrid.instance.repaint();
        }, 500);
      });
  }

  refresh() {
    this.loadAccoutDetails(this.query);
  }

  onSelectionChanged(row) {
    this.selection = <any>row.selectedRowsData[0];
  }

  onPageChange(query: Query) {
    this.loadAccoutDetails(query);
  }

  filterData(query: Query) {
    this.query = query;
    this.loadAccoutDetails(query);
  }

  clearFilter(query: Query) {
    this.query = query;
    this.loadAccoutDetails(query);
  }

  ngOnInit() {
    this.alert = this.route.snapshot.data.alert;
    this.loadTabs();
    this._init();
  }

  gridSettings() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.alertAccountGridService
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  getGridService(): GridService {
    return this.alertAccountGridService;
  }

  getDefaultSort(): any {
    return [['id', 'ASC']];
  }

  _init() {
    this.prepareList();
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.alertId = params['id'];
      this.loadAccoutDetails(this.query);
    });
  }

  ngAfterViewInit(): void {
    this.alertAccountGridService.create(this.dataGrid.instance, {
      noDataText: this.alertAccountGridService.noDataMessage
    });
    super.ngAfterViewInit();
  }

  sortColumn(sorting) {
    this.query['orderBy'] = sorting;

    this.loadAccoutDetails(this.query);
  }

  back(): void {
    if (document.referrer.indexOf(window.location.host) >= 0) {
      history.back();
    } else {
      this.router.navigate(['/alert']);
    }
  }

  csv() {
    const {
      alertAccountGridService,
      query,
      commonAlertService,
      alertId,
      alert
    } = this;
    alertAccountGridService.csvMap().subscribe(fields => {
      commonAlertService.exportToCSV('alert-account', {
        fields,
        query: query,
        fetchDataHandler: commonAlertService.findAccount.bind(
          commonAlertService,
          alertId,
          query
        ),
      });
    });
  }

  toggleFilter() {
    this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
    this.tabGroup.forceRerender();
  }

  ngOnDestroy() {
    if (this.sider.getActiveSection().name === this.sideSections.filter) {
      this.toggleFilter();
    }
    this.destroy$.next(true);
    this.destroy$.complete();
    this.tabGroup.onActivate.unsubscribe();
  }
}
