import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {of as observableOf} from 'rxjs';
import { mergeMap } from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";

import {
    SortingService,
    SortingBuilder
  } from '../../../shared/sorting/sorting.service';
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {HistoryService} from "../../../shared/history/history.service";
import Query from "../../../core/query/query";
import {AlertService} from '../../../shared/alert/alert.service';

import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {HistoryComponent} from "../../../shared/history/history.component";
import {ContractSchedule} from "../../core/contract-schedule";
import {DialogService} from '../../../shared/dialog/dialog.service';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {GridSettingsComponent} from "../../../core/grid/grid-settings.component";
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";

import {ContractService} from '../../core/contract.service';
import {Contract} from '../../core/contract';
import {ContractScheduleTermConditionManageDialogComponent} from "../../shared/contract-schedule-term-condition-manage-dialog/contract-schedule-term-condition-manage-dialog.component";
import {ContractTermConditionGridService} from "../../core/contract-term-condition-grid.service";
import {ContractSection} from '../../core/contract-section';
import {ContractTermAndConditionFilterService} from "../../core/contract-term-and-condition-filter.service";

@Component({
    selector: 'app-contract-details-terms-conditions',
    templateUrl: './contract-details-terms-conditions.component.html',
    styleUrls: ['./contract-details-terms-conditions.component.scss']
  })
  export class ContractTermsConditionsComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

    @ViewChild('panelSide') panelSide;
    @ViewChild('history') history: HistoryComponent;
    @ViewChild('testNotes') testNotes;

    @ViewChild('termsAndConditionGrid') termsAndConditionGrid: DxDataGridComponent;
  
    messages: IMessagesResourceService;
    coreMessages: IMessagesResourceService;
    readonly CORE_MODULE: string = 'core';
    readonly MESSAGES_MODULE: string = "contract";
    readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

    public contract: Contract;
    public termsConditions: Array<any>
    public termsConditionColumns: Array<any>;
    selection: any;
    public termsConditionQuery: Query = new Query({orderBy: [['id', 'ASC']]});

    public termsConditionFilterQuery:  Query = new Query({
      'order': [['id', 'ASC']]
    });

    public termsAndConditionsColumnsSorting: any[];
    public termsConditionSorting: SortingBuilder;
    public notesCount: number = 0;

    constructor(
        public contractService: ContractService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        public settingsService: UserSettingsService,
        public contractTermConditionGridService: ContractTermConditionGridService,
        public contractTermAndConditionFilterService: ContractTermAndConditionFilterService,
        public sortingService: SortingService,
        public dialog: DialogService,
        public alert: AlertService,
        public historyService: HistoryService
    ) {
    super(new PageContext({
        name: 'app.contract.contract-details-terms-conditions',
        settings: settingsService
    }));
    this.termsConditionSorting = this.sortingService.builder();

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);

  }

  ngOnInit(): void {
      this.contract = this.route.parent.snapshot.data.contract;
      this.termsConditionFilterQuery.params['contract_id'] = this.contract.id
      this.termsConditionQuery.where['contractSchedule.contract_shell_id'] = this.contract.id;

      this.contractTermConditionGridService.loadSettings()
                .subscribe((settings) => {
                  this.initTermsAndConditionsColumns(settings);
                });
  }

  loadTermsAndConditions(query){
      if(query){
        this.termsConditionQuery = new Query({...query});
      }
      this.contractService.findAllTermsAndConditions(query)
        .subscribe((result) =>{
          this.termsConditions = result.items;
          this.termsConditionQuery.total = result.total;
        })
    }

  initTermsAndConditionsColumns(settings?) {
      this.termsAndConditionsColumnsSorting = this.contractTermConditionGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
      this.termsConditionColumns = this.contractTermConditionGridService.getColumns(settings && settings.columns ? settings.columns : [])
  
      if (this.termsAndConditionsColumnsSorting.length) {
        this.termsConditionQuery.orderBy = this.termsAndConditionsColumnsSorting;
      }
  
      if (this.termsAndConditionGrid && this.termsAndConditionGrid.instance) {
        this.termsAndConditionGrid.instance.option('columns', this.termsConditionColumns);
      }
      this.loadTermsAndConditions(this.termsConditionQuery)
  }

  addNewTC(){
      this.dialog.open(ContractScheduleTermConditionManageDialogComponent, {
          contractSchedule: new ContractSchedule({}),
          contract: this.contract
      }, {width: '600px'})
        .afterClosed()
        .subscribe(result => {
            if (result) {
              this.loadTermsAndConditions(this.termsConditionQuery);
              this.history.refreshList();
              this.alert.success('', this.messages.get('TERMS_CONDITION_SUCCESS'));
            }
          },
          (error) => {
            this.alert.error('', this.messages.get('TERMS_CONDITION_ERROR'));
          });
  }

  editTC(){
      this.dialog.edit(
        ContractScheduleTermConditionManageDialogComponent,
        {
          termsAndCondition: this.contractService.findTCForEdit(this.selection.id),
          isUpdate: true,
          contract: this.contract
        }, {width: '600px'})
        .subscribe((items: any) => {
          if (items) {
            let obs = observableOf(items);
            obs.pipe(mergeMap(x => x.afterClosed()))
              .subscribe((result: any) => {
                if (result) {
                  this.alert.success('', this.messages.get('TERMS_CONDITION_UPDATE_SUCCESS'));
                  this.loadTermsAndConditions(this.termsConditionQuery);
                  this.history.refreshList();
                  if (result.cancelByTimer || result.status == 2) {
                    return this.alert.success('', this.coreMessages.get('TIMER_EXPIRED'));
                  }
                }
              })
          }
        })
  }
  
  deleteTC() {
      let termsConditionId = this.selection.id
  
      this.dialog.confirm({
        bodyText: `Are you sure sure you want to delete Terms And Conditions?`
      })
        .afterClosed()
        .subscribe(
          (result) => {
            if(result){
              this.alert.error('', this.messages.get('TERMS_CONDITION_DELETE_SUCCESS'));
              this.contractService.deleteTC(termsConditionId)
                .subscribe(() => {
                  this.loadTermsAndConditions(this.termsConditionQuery);
                  this.history.refreshList();
                })
            }
          },
          (error) => {
            this.alert.error('', this.messages.get('TERMS_CONDITION_DELETE_ERROR'));
          }
        );
  }

  termAndConditionCsv() {
    const { contractTermConditionGridService, contractService, termsConditionQuery: query } = this;
    contractTermConditionGridService.csvMap().subscribe(fields => {
      contractService.exportToCSV(
        'contract_t&c',
        {
          fields,
          query,
          settings: {currencyField: 'currency.currency'},
          fetchDataHandler: (query) => { return contractService.findAllTermsAndConditions(query)}
        })
    });
  }


  public onTermsAndConditionCellClick(event) {
      if (event.rowType === 'header' && event.column.allowSorting) {
      this.termsConditionSorting.apply(event, this.termsConditionQuery);
        this.loadTermsAndConditions(this.termsConditionQuery);
      }
  }

  onSelectionChanged(row) {
      this.selection = <ContractSection>row.selectedRowsData[0];
  }

  termsAndConditionsGridSettings() {
      this.dialog
        .open(GridSettingsComponent, {
          service: this.contractTermConditionGridService,
        })
        .afterClosed()
        .subscribe(settings => {
          if (settings) {
            this.resetDataPager();
            this.initTermsAndConditionsColumns(settings);
          }
      });
  }

  gridSettings() {
    this.termsAndConditionsGridSettings()
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  toggleFilter() {
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME);
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }

  clearTermsAndConditionFilter(query: any) {
    this.loadTermsAndConditions(query);
  }
}
