import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { path } from 'ramda';

import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from '../../../shared/picker/ca-picker-base.component';
import { VendorService } from '../../core/vendor.service';
import { VendorQuery } from '../../core/vendor.query';


@Component({
  selector: 'ca-vendor-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaVendorPickerComponent),
      multi: true
    },
    VendorService
  ]
})
export class CaVendorPickerComponent extends PickerComponentBase implements OnInit, OnChanges {
  readonly TEXT_FIELD = 'name';

  @Input() query: VendorQuery = new VendorQuery({
    orderBy: [['name', 'ASC']],
    limit: 20
  });

  @Input() formControlName;
  @Input() initialItem;
  @Input() initialItemId;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() placeholder = 'Select ...';
  @Input() pickerForm;
  @Input() searchEnabled: boolean;
  @Input() limit: number;
  @Input() clearEnabled: boolean;
  @Input() customMethod: string;
  @Output() onClear: EventEmitter<any> = new EventEmitter();


  public control;
  vendorsData;

  constructor(public vendorService: VendorService) {
    super();
  }

  loadEntries(): Observable<any> {
    if (this.limit) {
      this.query.limit = this.limit;
    }
    this.control = path(['pickerForm', 'controls', this.formControlName])(this);
    // let serviceMethod: string = this.customMethod || 'findOnlyVendors';
    let serviceMethod: string = this.customMethod || 'findVendorsForPicker';
    return this.vendorService[serviceMethod](this.query);
  }

  loadInitialItem(): Observable<any> {
    return this.vendorService.findById(this.initialItemId);
  }

  search(value: string) {
    if (value) {
      this.query.where['name'] = { '$ilike': `${value}%` };
    } else {
      delete this.query.where['name'];
    }

    let serviceMethod: string = this.customMethod || 'findOnlyVendors';
    return this.vendorService[serviceMethod](this.query);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = path(['pickerForm', 'controls', this.formControlName])(this);
    const {initialItemId} = changes;
    if (initialItemId && (!!initialItemId.currentValue || !!initialItemId.previousValue) && initialItemId.currentValue !== initialItemId.previousValue && !initialItemId.firstChange) {
      this.loadData();
    }
  }

  handleClear(){
    this.onClear.emit();
  }
}
