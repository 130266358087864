
import {filter} from 'rxjs';
import { Component, ViewChild } from '@angular/core';
import { AccountService } from '../core/account.service';
import { AccountManageDialogComponent } from '../shared/account-manage-dialog/account-manage-dialog.component';
import { Account } from '../core/account';
import { DialogService } from '../../shared/dialog/dialog.service';
import { PageListComponent } from '../../core/page-list.component';
import { AlertService } from '../../shared/alert/alert.service';
import { AccountQuery } from '../core/account.query';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountGridService } from '../core/account-grid.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {GridSettingsComponent} from '../../core/grid/grid-settings.component';
import {LoaderService} from '../../shared/loader/loader.service';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {DataLockDialogService} from '../../core/data-lock/data-lock-dialog.service';
import {AccountFilterService} from '../core/account-filter.service';
import {GridService} from '../../shared/grid/grid.service';
import {AccountDialogService} from '../core/account-dialog.service';
import { AccountCsvInterceptor } from '../core/account-csv-interceptor';
import {ConfigService} from "../../core/config/config.service";

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
})
export class AccountListComponent extends PageListComponent {

  public query: AccountQuery = new AccountQuery();
  public accounts: Array<Account>;
  public totalAccounts: number | string | any;
  public selection: Account;
  public columns: Array<any>;
  isToRefreshFilter: boolean;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'account';
  readonly custom_values_config_key = 'account_configuration';

  public sorting: any[][];

  constructor(public accountService: AccountService,
              public accountGridService: AccountGridService,
              public dialog: DialogService,
              public settingsService: UserSettingsService,
              public router: Router,
              public route: ActivatedRoute,
              public alert: AlertService,
              public loaderService: LoaderService,
              public dataLockDialogService: DataLockDialogService,
              public accountFilterService: AccountFilterService,
              public configService: ConfigService,
              private accountDialogService: AccountDialogService
              ) {

    super(new PageContext({
      resource: 'accounts',
      name: 'app.accounts.account-list',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }

  loadData(query?: AccountQuery) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.query = query ? query : this.query
    if (this.query.where)
      this.query.set('is_temporary', false)
    else
      this.query.where = { is_temporary: { $eq: false } };

    this.accountService.findAll(query)
      .subscribe((result: any) => {
          this.selectFirstRow();
          this.accounts = result.items;
          this.query.total = this.totalAccounts = result.total;

          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        },
        (error) => {
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        });
  }

  filterData(query: AccountQuery) {
    this.query = query;
    this.loadData(query);
  }

  clearFilter() {
    this.query.where = null;
    this.loadData(this.query);
  }

  refresh() {
    this.loadData(this.query);
  }

  onSelectionChanged(row) {
    this.selection = <Account>row.selectedRowsData[0];
  }

  async newAccount() {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

    this.dialog.open(AccountManageDialogComponent, {}, {
      width: hasCustomFields ? '1200px' : '900px'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refresh();
          this.alert.success('', this.messages.get('CREATE_SUCCESS'));

          this.isToRefreshFilter = true;
          setTimeout(() => {
            this.isToRefreshFilter = false;
          }, 2000);
        }
      });
  }

  async editAccount(account) {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

    this.accountDialogService.openEditAccountDialog(account, hasCustomFields).pipe(
    filter(result => !!result))
    .subscribe((items: any) => {
      const {changeDisputes = []} = items;
      let activateSetTimeOut = false;
      this.refresh();
      if((account.is_vat_gl_output !== items.is_vat_gl_output) && items.is_vat_gl_output){
        if (changeDisputes.length > 0) {
          let message = this.messages.get('IS_VAT_GL_OUTPUT_ENABLED').replace('<number>', changeDisputes.length)
          message += `. Dispute IDs: ${changeDisputes.join(', ')}.`
          this.alert.success('', message);
        }
        activateSetTimeOut=true;
      }
      if(activateSetTimeOut){
        setTimeout(()=> {
          this.getAccountMessage(account)
        },5000);
      }else {
        this.getAccountMessage(account)
      }
    });
  }
  getAccountMessage(account?){
    this.alert.success('', this.messages.get('UPDATE_SUCCESS'));
  }
  csv() {

    const { accountService, query, accountGridService } = this;

    accountGridService.csvMap().subscribe(fields => {
      const accountTransformMiddleware = (items) => {
        const accountCSVInterceptor = new AccountCsvInterceptor();
        return items.map(item => accountCSVInterceptor.transform(item));
      }

      accountService.exportToCSV(
        'accounts',
        {
          query,
          fields,
          middleware: [accountTransformMiddleware]
        }
      );
    });
  }

  deleteAccount(account) {

  }

  showDetails() {
    this.router.navigate(['/account', this.selection.id, 'show']);
  }

  gridSettings() {
    this.dialog.open(GridSettingsComponent, {
      service: this.accountGridService
    })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  ngAfterViewInit(): void {
    this.accountGridService.create(this.dataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  ngOnInit() {

    this._init();
  }

  getGridService(): GridService {
    return this.accountGridService;
  }

  getDefaultSort(): any {
    return [];
  }
}

