import {Component, OnInit, OnChanges, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';

import {Flow} from '../../shared/flow/flow';

@Component({
  selector: 'ca-invoice-flow',
  templateUrl: './invoice-flow.component.html',
})
export class InvoiceFlowComponent implements OnInit, OnChanges {
  @Input('flow') flow: Flow = new Flow();
  @Input('step') step = 0;
  @Input('readonly') readonly = false;
  @Output() onStepSelection = new EventEmitter();

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    let step = changes['step'];
    if (step && step.currentValue) {
      this.loadData();
    }
  }

  onSelect(step) {
    if (this.readonly) {
      return;
    }
    this.onStepSelection.emit(step);
  }

  loadData() {

  }

  calculateWidth() {
    let percent = (100 / this.flow.steps.length);
    return `${percent}%`;
  }
}
