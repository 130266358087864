import {Observable, throwError as observableThrowError, of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {GLBatch} from './gl-batch.model';
import {BaseService} from '../../core/base.service';
import {InvoiceFacepage} from '../../invoice/core/invoice-facepage';
import Query from '../../core/query/query';
import Auth from "../../shared/user-session/auth";
import {catchError, map} from "rxjs";

@Injectable()
export class GLBatchService extends BaseService<GLBatch> {
  constructor(public http: HttpClient) {
    super('batch_gl');
  }


  create(batch: GLBatch) {
    let temp: any = Object.assign({}, batch);
    let currencies = batch.invoices.map((invoice) => invoice.currency);
    let uniqueCurrencies = [...new Set(currencies)];

    temp.isMulticurrency = uniqueCurrencies.length > 1;
    temp.invoice_id = batch.invoices.map(function (x) {
      if (x && x.gl_invoice_amt && x.gl_invoice_amt.invoice_id != null) {
        return x.gl_invoice_amt.invoice_id
      }
      return x.invoice_id;
    });
    delete temp.invoices;

    return this.httpService().post(['batch_gl'], temp);
  };


  update(batchId) {
    return this.httpService().post(['batch_gl', batchId, 'update'], {});
  }

  partialDeactivation(batchId, invoicesIds, note, invoiceAlreadyInNewPendingState = false) {
    return this.httpService().post(['batch_gl', batchId, 'partialDeactivation'], {
      invoicesIds,
      note,
      invoiceAlreadyInNewPendingState
    })
  }

  getBatchInvoices(batchId: number) {
    return this.httpService().get(['batch_gl', batchId, 'invoices']);
  }

  findAllBatchInvoices(batchId: number, query = new Query()) {
    let transformedQuery = query.transform();
    return this.httpService().get([this.name, batchId, 'invoices/query'], this.toFilter(transformedQuery));
  };

  addInvoices(batchId: number, invoices: Array<InvoiceFacepage>) {
    let invoicesIds = invoices.map((x) => {
      return x.id;
    });
    return this.httpService().post(['batch_gl', batchId, 'invoices'], {invoice_id: invoicesIds});
  };


  removeInvoices(batchId: number, invoices: Array<InvoiceFacepage>) {
    let invoicesIds = invoices.map((x) => {
      return x.id;
    });
    return this.httpService().post(['batch_gl', batchId, 'invoices/delete'], {invoice_id: invoicesIds});
  };


  getBatchForInvoice(invoiceId: number) {
    return this.httpService().get(['batch_gl', invoiceId, 'batch']);
  };


  deactivateBatch(batchId: number, note?) {
    return this.httpService().post(['batch_gl', batchId, 'deactivateBatch'], {note});
  };


  batchFileDownload(batchId: number): Observable<any> {

    const endPoint: string = [this.name, '/', batchId.toString(), '/download'].join('');
    let cookie = Auth.getSession();
    return this.http.get(`${environment.API_URL}${endPoint}`, {
        responseType: 'blob', headers: {
          authorization: `Bearer ${cookie.token}`
        }
      }
    )
      .pipe(map(response => this.extractTXTBlob(response)),
        catchError(this.handleError))
  }

  batchOutputInvoiceZipFileDownload(batchId: number, findInvoice: number): Observable<any> {

    const endPoint: string = [this.name, '/', batchId.toString(), '/download', '/invoice', '/', findInvoice.toString()].join('');
    let cookie = Auth.getSession();
    return this.http.get(`${environment.API_URL}${endPoint}`, {
        responseType: 'blob', headers: {
          authorization: `Bearer ${cookie.token}`
        }
      }
    )
      .pipe(map(response => this.extractTXTBlob(response)),
        catchError(this.handleError))
  }

  public extractTXTBlob(data) {
    let blob = new Blob([data], {type: 'application/txt'});
    return blob || {};
  }


  public handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      const body = error || '';
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  batchCreatedDatesDistinct(): any {
    return this.httpService().get([this.name, 'datesCreatedDistinct']);
  }
}
