import { Injectable } from '@angular/core';

import { AuditConfig } from './audit-config';
import { BaseService } from '../../core/base.service';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';

@Injectable()
export class AuditConfigService extends BaseService<AuditConfig> {
	constructor(private entityLock: EntityLockService) {
		super('audit-config', entityLock);
	}

	getCurrentConfiguration(auditId: number) {
		return this.httpService().get(['audit-config', auditId]);
	}

	findForInvoice(invoiceId: number) {
		return this.httpService().get([this.name, 'invoice', invoiceId]);
	}

	runFromInvoices(auditIds: any, invoiceIds: any) {
		return this.httpService().post(['audit-process', 'invoices'], {
			auditIds,
			invoiceIds
		});
	}

	runFromAudits(auditIds: any, keys: any) {
		return this.httpService().post(['audit-process', 'accounts'], {
			auditIds,
			keys
		});
	}

	getAllAccountsForSelectedAudits(auditIds: any[]) {
		return this.httpService().post(['audit-config', 'accounts'], { auditIds });
	}

	getTreeForAudits(audits: any[], vendor_id: any) {
		return this.httpService().post(['audit-process', 'accountstree'], {
			audits,
			vendor_id
		});
	}

	getAuditConfig(audit_id: any) {
		return this.httpService().get(['audit-config', audit_id]);
	}

	getTreeList(audit_id) {
		return this.httpService().get(['tree-list', 'audit', audit_id]);
	}

	updateConfiguration(audit_id: any, selectedRows: any) {
		return this.httpService().post(['audit-config', audit_id], selectedRows);
	}

	saveAutoApprovalConfiguration(audit_id: any, accounts: any[]) {
		return this.httpService().post(
			['audit-config', 'auto-approval', audit_id],
			{ accounts }
		);
	}
}
