import {Injectable} from '@angular/core';

import {GridService} from '../../shared/grid/grid.service';
import {FormatService} from '../../shared/format/format.service';
import {UserSettingsService} from '../../user/core/user-settings.service';

@Injectable()
export class InventoryGridService extends GridService {
  constructor(public userSettings: UserSettingsService, public formatService: FormatService) {
    super('app.inventory.inventory-grid', userSettings);
  }

  defaultColumns = [
    {
      caption: '',
      headerCellTemplate: 'selectionBadgeHeaderTemplate',
      cellTemplate: 'selectionBadgeCellTemplate',
      fixed: true,
      width: 40,
      allowSorting: false
    },
    {
      caption: 'SP Service ID',
      dataField: 'sp_ckt_id',
      width: 200
    },
    {
      caption: 'System ID',
      dataField: 'id',
      width: 150,
      alignment: 'left',
      visible: false
    },
    {
      caption: 'Network Type',
      dataField: 'type.value',
      width: 130
    },
    {
      caption: 'Vendor',
      dataField: 'vendor.name',
      width: 130
    },
    {
      caption: 'Customer Service ID',
      dataField: 'customer_inventory.customer_circuit_id',
      width: 130
    },
    {
      caption: 'Install Date',
      dataField: 'install_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      width: 130
    },
    {
      caption: 'Disconnect Date',
      dataField: 'disc_date',
      dataType: 'date',
      cellTemplate: 'dateTemplate',
      alignment: 'left',
      width: 130
    },
    {
      dataField: 'siteA.building.address.search_address',
      caption: 'Site A (Carrier Location)',
      alignment: 'left',
      cellTemplate: 'siteAtemplate',
      width: 200
    },
    {
      dataField: 'siteZ.building.address.search_address',
      caption: 'Site Z (Customer Location)',
      alignment: 'left',
      cellTemplate: 'siteZtemplate',
      width: 200
    },
    {
      caption: 'Status',
      dataField: 'inventory_status.value',
      width: 130
    },
    {
      caption: 'Internal ID',
      dataField: 'internal_id',
      width: 200
    },
    {
      caption: 'Service Type',
      dataField: 'inventory_ckt_type.value',
      width: 150
    },
    {
      caption: 'Svc Tech',
      dataField: 'technology.value',
      width: 150
    },
    {
      caption: 'Service Bandwidth',
      dataField: 'circuit_bandwidth.value',
      width: 150
    },
    {
      caption: 'Port Bandwidth',
      dataField: 'bandwidth_max.value',
      width: 150
    },
    {
      caption: 'VLAN ID',
      dataField: 'vlan_id',
      width: 150
    },
    {
      caption: 'Customer Provided Access',
      dataField: 'is_customer_provided_access',
      cellTemplate: 'customerProvidedAccessTemplate',
      width: 120,
      transformValue: {
        true: 'Yes',
        false: 'No'
      }
    },
    {
      caption: 'Customer',
      dataField: 'customer.company',
      width: 150
    },
    {
      caption: 'Account',
      dataField: 'ban',
      width: 150
    },
    {
      caption: 'Subclient',
      dataField: 'ban_object.subclient.value',
      width: 150
    },
    {
      caption: 'Currency',
      dataField: 'currency_obj.currency',
      width: 120
    },
    {
      caption: 'MRC',
      dataField: 'est_mrc',
      dataType: 'currency',
      alignment: 'right',
      cellTemplate: 'inventoryCurrencyTemplate',
      width: 100
    },
    {
      caption: 'NRC',
      dataField: 'est_nrc',
      dataType: 'currency',
      alignment: 'right',
      cellTemplate: 'inventoryCurrencyTemplate',
      width: 100
    }
  ];
}
