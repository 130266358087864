import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  PICKER_COMPONENT_TEMPLATE,
  PickerComponentBase
} from '../../../shared/picker/ca-picker-base.component';
import {Observable} from 'rxjs';
import {InventoryService} from '../../core/inventory.service';

@Component({
  selector: 'ca-inventory-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaInventoryPickerComponent),
      multi: true
    }
  ]
})
export class CaInventoryPickerComponent extends PickerComponentBase implements OnInit, OnChanges {
  readonly TEXT_FIELD = 'sp_ckt_id';
  @Input('formControlName') formControlName;
  @Input("initialItem") initialItem;
  @Input('pickerForm') pickerForm;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('placeholder') placeholder = 'Select ...';
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('customerID') customerID: any;
  @Input('query') query;
  @Input('inventoryId') inventoryId: number;

  public control;

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];
    if (changes.customerID) {
      this.loadData()
    }
  }

  constructor(public inventoryService: InventoryService) {
    super();
  }

  loadEntries(): Observable<any> {
    this.query.orderBy =  [['sp_ckt_id', 'ASC']]
    if (this.customerID !== null) {
      this.query.where['customer_id'] = this.customerID
    }
    if (this.inventoryId) {
      this.query['where']['id'] = {
        $ne: this.inventoryId
      }

    }
    return this.inventoryService.findOnlyInventory(this.query);
  }

  search(value: string): Observable<any> {
    if (value) {
      this.query.where['sp_ckt_id'] = {'$ilike': `${value}%`};
    } else {
      delete this.query.where['sp_ckt_id'];
    }
    return this.inventoryService.findOnlyInventory(this.query);
  }
}
