export class InvoiceAuditResults {
	details: {
		[key: number]: InvoiceAuditResultsDetailItem;
	};
	summary: {
		[key: number]: InvoiceAuditResultsSummaryItem;
	};

	constructor(data?: any) {
		if (data) {
			this.details = data.details;
			this.summary = data.summary;
		}
	}

	getDetailItems(): InvoiceAuditResultsSummaryItemExtended[] {
		return Object.keys(
			this.summary ?? {}
		).map<InvoiceAuditResultsSummaryItemExtended>((key) => ({
			...this.summary[key],
			id: key
		}));
	}
}

export type InvoiceAuditResultsSummaryItemExtended =
	InvoiceAuditResultsSummaryItem & {
		id: number;
	};

export interface InvoiceAuditResultsSummaryItem {
	alert_ids: string[];
	failed: number;
	name: string;
	passed: number;
	total: number;
}

export interface InvoiceAuditResultsDetailItem {
	[key: number]: {
		name: string;
		issues: number;
	};
}
