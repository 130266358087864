<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Building</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button *permission="'Create'" matTooltip="New Building" mat-icon-button (click)="newBuilding()">
    <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button *permission="'Modify'" mat-icon-button (click)="editBuilding(selection)" matTooltip="Edit Building">
    <ca-icon [key]="'EDIT'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh List" (click)="refresh()">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
          <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          [disabled]="!selection" (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" 
          mat-icon-button matTooltip="Info">
          <ca-icon [key]="'INFO'"></ca-icon>
  </button>
</mat-toolbar>


<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">
  <dx-data-grid (onSelectionChanged)="onSelectionChanged($event)"
                (onRowClick)="onRowClick($event)"
                (onCellClick)="onCellClick($event)"
                [height]="'100%'"
                [width]="'100%'"
                [columns]="columns"
                class="app-grid-with-pager"
                [showColumnLines]="false"
                [showRowLines]="true"
                [showBorders]="true"
                [columnAutoWidth]="false"
                [dataSource]="buildings">
    <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
    <dxo-paging [pageSize]="10" [enabled]="false"></dxo-paging>
    <dxo-sorting mode="none"></dxo-sorting>

  </dx-data-grid>

  <ca-pager  [gridPager]="defaultGridPager" [pageContext]="buildingGridService"  [query]="query" class="app-pager ca-default-border-top" (onChange)="loadData(query)"></ca-pager>

  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="buildingFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter()">
    </new-app-filter>
    <app-building-sneak-peek [building]="selection"
                             (closeRequested)="sider?.close()"
                             [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></app-building-sneak-peek>
  </mat-sidenav>
</mat-sidenav-container>
