import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, } from '@angular/core';

import Query from '../../query/query';
import {from} from "rxjs";


@Component({
  selector: 'multi-select-filter-input',
  templateUrl: './multi-select-filter.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true
    },
  ]
})
export class MultiSelectComponent implements OnInit, OnChanges {
  @Input() query: Query;
  @Input() entry: any;
  @Input() entryInterceptor: any;
  @Output() numbersDidNotPassValidation: EventEmitter<boolean> = new EventEmitter();

  internalMultiselectInput = {
    All: true
  };

  constructor() {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.query?.currentValue) {
      let query = changes.query.currentValue;
      query.where = query?.where || {};

      if (this.entry && this.entry.editor === "checkboxList") {
        query.limit = 10000;
      }

      this.internalMultiselectInput = {
        All: true
      };
      if (Object.keys(query?.where).length === 0) {
        this.internalMultiselectInput = {
          All: true
        };
      }

      if (this.entryInterceptor) {
        this.entry = this.entryInterceptor(this.entry, this.query);
      }

      if (this.entry && this.entry?.defaultValue) {
        this.entry.defaultValue.forEach((value) => {
          this.internalMultiselectInput[value] = true;
        })
      }
      else {
        from(this.entry.lookupProvider.findAll()).subscribe(result => {
          this.entry.lookupItems.items = result;
        });
        this.internalMultiselectInput = {
          All: true
        };
      }
    }
  }

  ngOnInit() {
    if (this.entry && this.entry.defaultValue) {
      this.entry.defaultValue.forEach((value) => {
        this.internalMultiselectInput[value] = true;
      })
    }
  }

  ngOnMultiselectChange(entry, val, event, name) {
    setTimeout(() => {
      this.internalMultiselectInput[val.key != null ? val.key : val] = event.checked;

      if (name === 'All') {
        this.internalMultiselectInput = {
          All: true
        };
      } else if (event) {
        this.internalMultiselectInput['All'] = false;
      }
      let value = this.internalMultiselectInput;
      let keys = Object.keys(value);

      if (keys.length) {
        const intVal: Array<any> = keys.filter(key => value[key]);

        if (intVal && intVal.includes('All') || !intVal.length) {
          delete this.query.where[entry.field];
        } else {
          this.query.where[entry.field] = { '$in': intVal }
        }
      } else {
        delete this.query.where[entry.field];
      }
    }, 50)
  }

  toArray(val) {
    return Array.from(val);
  }
}






