import { Injectable } from '@angular/core';


import { ConfigService } from '../../core/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsResolver  {

  constructor(private configService: ConfigService) {
  }

  resolve(): any {
    return this.configService.loadCustomFields();
  }
}
