import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { DxDataGridComponent } from "devextreme-angular";
import { of } from "ramda";
import { Observable, Subject } from "rxjs";

import { GridSettingsComponent } from "app/core/grid/grid-settings.component";
import { PageContext } from "app/core/page.context";
import {
  IMessagesResourceService,
  ResourcesService,
} from "app/core/resources/resources.service";
import { Sider } from "app/core/sider/sider";
import { LOOKUP_ENUM } from "app/dictionary/core/lookup.enum";
import { Inventory } from "app/inventory/core/inventory";
import { InventoryService } from "app/inventory/core/inventory.service";
import { InvoiceChargeProfileFilterService } from "app/invoice/core/invoice-charge-profile-filter.service";
import { InvoiceChargeProfileGridService } from "app/invoice/core/invoice-charge-profile-grid.service";
import { AlertService } from "app/shared/alert/alert.service";
import { DialogService } from "app/shared/dialog/dialog.service";
import { LoaderService } from "app/shared/loader/loader.service";
import {
  SortingBuilder,
  SortingService,
} from "app/shared/sorting/sorting.service";
import { UserSettingsService } from "app/user/core/user-settings.service";
import Query from "../../../core/query/query";
import { InvoiceChargeProfile } from "../../core/invoice-charge-profile";
import { InvoiceChargeProfileManageDialogComponent } from "../../shared/invoice-charge-profile-manage-dialog/invoice-charge-profile-manage-dialog.component";
import { HistoryComponent } from "app/shared/history/history.component";
import { GridService } from "app/shared/grid/grid.service";
import { PageDetailsComponent } from "app/core/page-details.component";
import { AuditsService } from "app/audit/core/audits.service";
import { InventoryDetailsTabsComponent } from "../inventory-details-tabs/inventory-details-tabs.component";

@Component({
  selector: "app-invoice-charge-profile",
  templateUrl: "./invoice-charge-profile.component.html",
})
export class InvoiceChargeProfileComponent
  extends PageDetailsComponent
  implements AfterViewInit, OnInit
{
  @ViewChild("panelSide") panelSide;
  @ViewChild("history") history: HistoryComponent;
  @ViewChild("chargeProfileGrid", { static: false })
  chargeProfileGrid: DxDataGridComponent;
  @ViewChild("tabs") tabs: InventoryDetailsTabsComponent;

  @Select((state) => state.invoice_charge_profile.activeSiderSection)
  $activeSiderSection: Observable<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  readonly ACTIVE_TAB_INDEX = 9;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  readonly MESSAGES_MODULE: string = "inventory";
  readonly SECTIONS = {
    FILTER_SECTION_NAME: "filter",
    DETAILS_SECTION_NAME: "details",
    NOTES_SECTION_NAME: "notes",
    HISTORY_SECTION_NAME: "history",
  };
  readonly DISABLED_COLUMNS: string[] = [
    "created_date",
    "last_updated_date",
    "charge_description",
    "charge_type",
    "updated_by_user.username",
    "created_by_user.username",
  ];
  readonly DISABLED_AUDIT_TYPES = [
    "CHARGE_PROFILE_NONE",
    "CHARGE_PROFILE_MANUAL",
    "CHARGE_PROFILE_BASELINE",
  ];

  public messages: IMessagesResourceService;
  public chargeProfileSorting: SortingBuilder;
  public inventory: Inventory;
  public sider: Sider;
  public notesCount: number;
  public filter: any;
  public isBulkEditDisabled: boolean = true;
  public isSingleNoteSelected: boolean = false;
  public delaySelectionChange: any;
  query: Query = new Query({
    orderBy: [["id", "ASC"]],
  });

  public chargeProfiles: Array<any> = [];
  public chargeProfileColumns: any[] = [];
  public selectedSingleChargeProfile = null;
  public chargeProfilesSelected: any[] = [];
  public chargeProfilesSelectionActive: boolean = false;
  public defaultGridPager: number = 20;
  public isChargeProfileSavingOn: boolean = false;
  public isChargeProfileDataValid: boolean = false;
  public hasUnsavedChanges: boolean = false;

  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public router: Router,
    public settingsService: UserSettingsService,
    public invoiceChargeProfileGridService: InvoiceChargeProfileGridService,
    public alertService: AlertService,
    public loaderService: LoaderService,
    public dialogService: DialogService,
    public sortingService: SortingService,
    public invoiceChargeProfileFilterService: InvoiceChargeProfileFilterService,
    public auditService: AuditsService
  ) {
    super(
      new PageContext({
        name: "app.inventory.invoice-charge-profile",
        settings: settingsService,
      })
    );
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.chargeProfileSorting = this.sortingService.builder();
  }

  loadPermissions() {
    return of({});
  }

  initLookupFields() {
    this.auditService
      .findAllForType(30, new Query({ orderBy: [["name", "ASC"]] }))
      .subscribe((result) => {
        this.invoiceChargeProfileGridService.chargeProfileAuditRuleType =
          result.items;
        this.chargeProfileColumns =
          this.invoiceChargeProfileGridService.getChargeColumns();
      });
  }

  ngOnInit(): void {
    this.inventory = this.getInventory();

    this.loadData();
    this.loadInventory(this.inventory.id).subscribe((result) => {
      this.inventory = result;
    });
  }

  ngAfterViewInit(): void {
    this.invoiceChargeProfileGridService.create(
      this.chargeProfileGrid.instance,
      {
        selection: {
          mode: "multiple",
          selectAllMode: "page",
        },
      }
    );
    super.ngAfterViewInit();
  }

  public loadSelected() {
    if (this.chargeProfilesSelectionActive) {
      this.query["where"] = this.filter;
    } else {
      this.query["where"] = {};
      this.query.set("id", {
        $in: this.chargeProfilesSelected.map((x) => {
          return x.id;
        }),
      });
    }
    this.query.offset = 0;
    this.query.page = 1;
    this.chargeProfilesSelectionActive = !this.chargeProfilesSelectionActive;
    this.loadData(this.query);
  }

  private getInventory() {
    return this.route.parent.snapshot.data.inventory;
  }

  private loadInventory(id: number): any {
    return this.inventoryService.findById(id);
  }

  loadData(query?) {
    this.delaySelectionChange = true;
    this.loaderService.displayLoaderAndManageGrid([this.chargeProfileGrid]);

    if (!query) {
      query = this.query;
    }
    if (!query?.where?.is_active) {
      query.where = {
        is_active: true,
      };
    }
    query.where = { ...query.where, spid: this.inventory.sp_ckt_id };
    this.query = query;
    this.inventoryService.findAllInvoiceChargeProfiles(query).subscribe(
      (result) => {
        this.chargeProfiles = result.items;
        this.query.total = result.total;
        this.loaderService.hideLoaderAndManageGrid([this.chargeProfileGrid]);

        if (this.chargeProfiles.length) {
          setTimeout(() => {
            this.delaySelectionChange = false;
            this.chargeProfileGrid.instance.selectRows(
              this.chargeProfilesSelected,
              false
            );
          }, 500);
        }

        this.initLookupFields();
        this.history.refreshList();
      },
      (error) => {
        this.loaderService.displayLoaderAndManageGrid([this.chargeProfileGrid]);
        this.alertService.error(
          "",
          this.messages.get("FETCH_CHARGE_PROFILE_ERROR")
        );
      }
    );
  }

  editChargeProfileStatus() {
    this.dialogService
      .open(
        InvoiceChargeProfileManageDialogComponent,
        {
          inventoryId: this.inventory.id,
          vendorId: this.inventory.vendor_id,
          inventory: this.inventory,
          charges: this.chargeProfilesSelected,
        },
        { width: "800px" }
      )
      .afterClosed()
      .subscribe(async (response) => {
        this.loaderService.hideLoader();

        if (!response) {
          return;
        }

        this.chargeProfileGrid.instance.clearSelection();

        if (response?.error) {
          return this.alertService.error(
            "",
            this.messages.get("CHARGE_PROFILE_CREATE_FAIL")
          );
        }

        this.alertService.success(
          "",
          this.messages.get("CHARGE_PROFILE_UPDATE_SUCCESS")
        );
        this.loadData();
        this.history.refreshList();
      });
  }

  saveChargeProfile() {
    this.loaderService.displayLoader();
    if (this.isChargeProfileSavingOn) {
      return;
    }

    this.isChargeProfileSavingOn = true;
    this.hasUnsavedChanges = false;

    const chargeProfilesToUpdate = this.chargeProfiles.filter(
      (chargeProfile) => chargeProfile.record_updated
    );

    this.inventoryService.updateChargeProfile(chargeProfilesToUpdate).subscribe(
      (result) => {
        this.loaderService.hideLoader();
        this.chargeProfileGrid.instance.clearSelection();
        this.isChargeProfileSavingOn = false;
        this.isChargeProfileDataValid = false;
        this.loadData();
        this.history.refreshList();
        this.alertService.error(
          "",
          this.messages.get("CHARGE_PROFILE_UPDATE_SUCCESS")
        );
      },
      (err) => {
        console.error(err);
        this.loaderService.hideLoader();
        this.isChargeProfileSavingOn = false;
      }
    );
  }

  onSelectionChange(row: any) {
    this.chargeProfilesSelected = <InvoiceChargeProfile[]>row.selectedRowsData;
    this.isBulkEditDisabled = !this.chargeProfilesSelected?.length;
    this.selectedSingleChargeProfile =
      this.chargeProfilesSelected.length &&
      this.chargeProfilesSelected.length === 1
        ? this.chargeProfilesSelected[0]
        : null;
    this.isSingleNoteSelected = !!this.selectedSingleChargeProfile;
    this.sider.close();
  }

  public resetDataPager() {
    this.defaultGridPager = 20;
  }

  // Prevents page reload if there are unsaved changes on the page
  @HostListener("window:beforeunload")
  windowBeforeUnload(): Observable<boolean> | boolean {
    return !this.hasUnsavedChanges;
  }

  validateData(data: any) {
    const {
      charge_amount,
      audit_rule_type,
      audit_rule_expected_max_value,
      audit_rule_expected_min_value,
    } = data;

    const auditValues = [
      charge_amount,
      audit_rule_type,
      audit_rule_expected_max_value,
      audit_rule_expected_min_value,
    ];

    const anyInvalidNumber = auditValues.some(
      (value) => isNaN(value) && Number.isFinite(Number(value))
    );

    if (anyInvalidNumber) {
      return false;
    }
    return true;
  }

  onPageChange(query: Query) {
    this.loadData(query);
  }

  onRowUpdated(row: any) {
    const isDataValid = this.validateData(row.data);
    if (!isDataValid) {
      return;
    }

    this.hasUnsavedChanges = true;
    this.isChargeProfileDataValid = true;
    this.chargeProfiles = this.chargeProfiles.map((chargeProfile) => {
      if (chargeProfile.id === row.key.id) {
        chargeProfile = {
          ...chargeProfile,
          last_updated_date: new Date(),
          record_updated: true,
        };
      }
      return chargeProfile;
    });
  }

  onCellClick(event: any) {
    if (event.rowType === "header" && event.column.allowSorting) {
      this.chargeProfileSorting.apply(event, this.query);
      this.loadData(this.query);
    }
  }

  onCellPrepared(event: any) {
    if (event.rowType !== "header") {
      const column = event.column.dataField;
      const data = event.data;

      if (this.DISABLED_COLUMNS.includes(column)) {
        this.disableField(event);
      }

      if (
        (column === "audit_rule_expected_max_value" ||
          column === "audit_rule_expected_min_value") &&
        this.DISABLED_AUDIT_TYPES.includes(data.audit_type.key)
      ) {
        this.disableField(event);
      }
    }
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  displayCheckModal() {
    this.dialogService
      .save({
        bodyText: this.messages.get("INVOICE_CHARGE_PROFILE_DISCARD_CHANGES"),
      })
      .afterClosed()
      .subscribe((result) => {
        this.hasUnsavedChanges = !result;
        if (result) {
          this.tabs.onTabChange(null, this.ACTIVE_TAB_INDEX);
        }
      });
  }

  disableField(event) {
    event.cellElement.style.pointerEvents = "none";
    event.cellElement.style.opacity = "0.5";
    event.cellElement.style["background-color"] = "rgba(0,0,0,0.05)";
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME);
  }

  toggleFilter() {
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME);
  }

  filterData(query: Query) {
    this.query = query;
    this.loadData(query);
  }

  clearFilter() {
    this.query.where = null;
    this.loadData(this.query);
  }

  getGridService(): GridService {
    return this.invoiceChargeProfileGridService;
  }

  gridSettings() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.invoiceChargeProfileGridService,
      })
      .afterClosed()
      .subscribe((settings) => {
        if (settings) {
          this.resetDataPager();
          this._init();
        }
      });
  }

  back() {
    if (
      document.referrer.indexOf(window.location.host) >= 0 ||
      document.referrer === ""
    ) {
      history.back();
    } else {
      this.router.navigate(["/inventory"]);
    }
  }
}
