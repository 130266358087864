import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonAlert } from 'app/common-alert/core/common-alert';
import { UserService } from 'app/user/core/user.service';
import { pathEq } from 'ramda';
import pathOr from 'ramda/es/pathOr';
import { catchError, delay, filter, iif, lastValueFrom, map, mergeMap, of as observableOf, of, switchMap, tap, throwError } from 'rxjs';
import { AccountDialogService } from '../../account/core/account-dialog.service';
import { ACCOUNT_STATUS_ENUM } from '../../account/core/account-status.enum';
import { AccountService } from '../../account/core/account.service';
import { CommonAlertService } from '../../common-alert/core/common-alert.service';
import { Config } from '../../core/config/config';
import { ConfigService } from '../../core/config/config.service';
import Query from '../../core/query/query';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { LOOKUP_ENUM } from '../../dictionary/core/lookup.enum';
import { GLBatch } from '../../gl-batch/core/gl-batch.model';
import { GLBatchService } from '../../gl-batch/core/gl-batch.service';
import { PermissionService } from '../../permissions/core/permission.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { FlowStep } from '../../shared/flow/flow-step';
import { FlowService } from '../../shared/flow/flow.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { Notes } from '../../shared/notes/core/notes';
import { NotesService } from '../../shared/notes/core/notes.service';
import { InvoiceApprovalManageComponent } from '../shared/invoice-approval-manage/invoice-approval-manage.component';
import { InvoiceBalanceErrorDialogComponent } from '../shared/invoice-balance-error-dialog/invoice-balance-error-dialog.component';
import { InvoiceConfirmDialog } from '../shared/invoice-confirm-dialog/invoice-confirm-dialog.component';
import { InvoiceResetToNewManageComponent } from '../shared/invoice-reset-to-new-manage/invoice-reset-to-new-manage.component';
import { InvoiceStatusGlComponent } from '../shared/invoice-status-gl/invoice-status-gl.component';
import { InvoiceFacepage } from './invoice-facepage';
import { InvoiceFacepageService } from './invoice-facepage.service';
import { BATCH_OUTPUT_FORMAT_ENUM, INVOICE_STATUS_ENUM } from './invoice-status.enum';
import { PaymentService } from './payment.service';
import { ReverseAPFeedMethod } from './reverse_ap_feed_method.enum';
import { AuditsService } from 'app/audit/core/audits.service';
import { AuditConfigService } from 'app/audit/core/audit-config.service';
import { AUDIT_TYPE_ENUM } from 'app/audit/core/audit-type.enum';

@Injectable({
	providedIn: 'root'
})
export class InvoiceFlowHandleService extends FlowService {
	public onStatusChange: EventEmitter<any> = new EventEmitter();
	onHoldChange: EventEmitter<any> = new EventEmitter();

	public config: Config;
	public invoiceId: number;
	public invoice: InvoiceFacepage;
	public isGlEnabled: boolean;
	public reverseAPFeedMethod: string;
	public isGlButtonDisabled: boolean = true;
	public isAdminUser: boolean;
	public isGlBatchOutputButtonDisabled: boolean = false;
	public approve: boolean;
	public isApproveButtonDisabled: boolean = true;
	public hasModifyPermission: boolean;
	public isGlOutputAutoSend: boolean;
	public configMergeInToOneStep: boolean;
	public isPartailBatchDeactivationDisabled = false;
	public invoiceStatusMap = {};

	//TODO Check type of these two variables
	public status: any;
	public currentStatus: any;

	messages: IMessagesResourceService;

	readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
	readonly invoiceStatusEnum = INVOICE_STATUS_ENUM;
	readonly ICONS = {
		INVOICE_FLOW_WARNING: 'INVOICE_FLOW_WARNING',
		INVOICE_FLOW_ERROR: 'INVOICE_FLOW_ERROR',
		INVOICE_FLOW_PENDING: 'INVOICE_FLOW_PENDING'
	};

	readonly LOCALS = {
		INVOICE_STATUS_CHANGE_ALERT_TITLE: 'Invoice Status Change',
		READY_FOR_APPROVAL_TITLE: 'Ready For Approval',
		READY_FOR_APPROVAL_BODY: 'Invoice not fully GL coded',
		CREATE_OUTPUT_FILE_BODY: 'Create GL output file',
		INVOICE_IS_INACTIVE: "This account is in inactive state and can not be output while it's in this state.",
		REVERT_TO_GL_CODED_BODY: 'Revert Invoice status to GL Coded',
		REVERT_TO_BATCH_OUPUT: 'Revert to GL output',
		SET_TO_COMPLETED_BODY: 'Set Invoice state to Completed',
		GL_OUTPUT_SENT: 'Confirm GL output was sent',
		ACTIVE_ALERTS: 'There are still open alerts which need to be reviewed. Do want to proceed? By clicking this button, it will close all open alerts whether they have been worked or not.',
		REVERT_TO_RFA: 'Revert Invoice to Ready For Approval',
		REVERSE_AP_FEED_RECEIVED: 'Confirm AP Feed Received',
		DO_NOT_PROCESS: 'Do Not Process',
		REVERT_TO_GL_OUTPUT_SENT: 'Revert to GL output sent'
	};
	readonly MESSAGES_MODULE: string = 'gl';
	readonly DATA_LOCK_CLOSE_STATUS = {
		CANCEL_BY_USER: 0,
		TIME_EXTEND: 1,
		CANCEL_BY_TIMER: 2
	};

	constructor(
		public invoiceService: InvoiceFacepageService,
		public dialog: DialogService,
		public glBatchService: GLBatchService,
		public notesService: NotesService,
		public router: Router,
		public configService: ConfigService,
		public accountService: AccountService,
		public userService: UserService,
		private permissionService: PermissionService,
		private loaderService: LoaderService,
		private alert: AlertService,
		private auditsService: AuditsService,
		public auditConfigService: AuditConfigService,
		private commonAlertService: CommonAlertService,
		private accountDialogService: AccountDialogService,
		private paymentService: PaymentService
	) {
		super();
		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
	}

	public setInvoiceStatusMap() {
		if (!Object.keys(this.invoiceStatusMap).length) {
			this.invoiceService.fetchInvoiceStatuses().subscribe((data) => {
				data.forEach((element) => {
					this.invoiceStatusMap[element.key] = element.value;
				});
			});
		}
	}

	public setConfig(config?) {
		if (!this.config && config) {
			this.config = config;
		}
		this.isGlEnabled = this.config.invoice_flow_settings?.gl_coding_enabled;
		this.isGlButtonDisabled = true;
		this.isGlBatchOutputButtonDisabled = false;
		this.reverseAPFeedMethod = this.config.invoice_flow_settings?.reverse_ap_feed_method;
		this.isGlOutputAutoSend = config && config.invoice_flow_settings ? config.invoice_flow_settings.gl_output_auto_send : false;
		this.configMergeInToOneStep = config.invoice_flow_settings?.merge_batch_into_one_step;
		this.isPartailBatchDeactivationDisabled = config.invoice_flow_settings?.partial_batch_deactivation_disabled;
	}

	createGlBatch(glBatch, invoice, callFunc) {
		this.glBatchService.create(glBatch).subscribe(
			(result: GLBatch) => {
				if (this.config.gl_coding.format === 'bcm-one') {
					if (result && result['invoice_status'] && result['invoice_status'] === this.invoiceStatusEnum.GL_BATCH_ERROR) {
						this.alert.info('', this.messages['_MESSAGES_ENUM']['invoice']['GL_OUTPUT_PAYMENT_ERROR']);
					}
					if (result && result['invoice_status'] && result['invoice_status'] === this.invoiceStatusEnum.GL_BATCH_SENT) {
						this.alert.info('', this.messages['_MESSAGES_ENUM']['invoice']['GL_OUTPUT_SUCCESS_TAILWIND']);
					}
				}

				this.alert.success('', this.messages['_MESSAGES_ENUM']['invoice']['GL_OUTPUT_SUCCESS_TAILWIND']);
				this.loaderService.hideLoader();
				this.updateSteps(result.invoice_status);
				this.onStatusChange.emit(result.invoice_status);
			},
			(err) => {
				const errorMessages = err.error.messages;
				const { invoices } = err.data.messages;

				if (err.status === 409 && errorMessages[0] && errorMessages[0].message === 'Excel file not created successfully') {
					this.commonAlertService.create({
						message: `${this.messages['_MESSAGES_ENUM']['invoice']['GL_OUTPUT_ERROR']} Invoice ${invoice.invoice_id}`,
						priority_id: 30,
						status_id: 10,
						type_id: 30,
						invoice_id: invoice.invoice_id || null,
						category_id: 20,
						vendor_id: invoice.vendor_id || null,
						vendor_name: invoice.sp_name || null,
						account_no: invoice.account.account_no || null,
						account_id: invoice.account_id || null,
						sp_inv_num: invoice.sp_inv_num || null
					});
					this.alert.error('', this.messages['_MESSAGES_ENUM']['invoice']['GL_OUTPUT_ERROR']);
					this.updateSteps(this.invoiceStatusEnum.APPROVED);
					this.onStatusChange.emit(this.invoiceStatusEnum.APPROVED);
				} else if (err.status === 409 && typeof errorMessages == 'object' && errorMessages.message && errorMessages.message.indexOf('Invoice can be only part of one active batch') !== -1) {
					this.alert.error('', this.messages['_MESSAGES_ENUM']['invoice']['ACTIVE_BATCH_ERROR']);
					this.updateSteps(this.invoiceStatusEnum.APPROVED);
					this.onStatusChange.emit(this.invoiceStatusEnum.APPROVED);
				} else if (err.status === 409 && typeof errorMessages == 'object' && errorMessages.message && errorMessages.message.indexOf('Non existing region in the config file') !== -1) {
					this.alert.error('', this.messages['_MESSAGES_ENUM']['invoice']['CONFIG_REGION_ERROR']);
					this.updateSteps(this.invoiceStatusEnum.APPROVED);
					this.onStatusChange.emit(this.invoiceStatusEnum.APPROVED);
				} else if (err.status === 409 && typeof errorMessages == 'object' && errorMessages.message && errorMessages.message.indexOf('Non existing region on invoice account') !== -1) {
					this.alert.error('', this.messages['_MESSAGES_ENUM']['invoice']['ACCOUNT_REGION_ERROR']);
					this.updateSteps(this.invoiceStatusEnum.APPROVED);
					this.onStatusChange.emit(this.invoiceStatusEnum.APPROVED);
				} else if (err.status === 409 && typeof errorMessages == 'object' && errorMessages.message && errorMessages.message.indexOf('Source file does not exist') !== -1) {
					this.alert.error('', this.messages['_MESSAGES_ENUM']['invoice']['SOURCE_FILE_ERROR']);
					this.updateSteps(this.invoiceStatusEnum.APPROVED);
					this.onStatusChange.emit(this.invoiceStatusEnum.APPROVED);
				} else if (err.status === 409 && invoices && invoices.length) {
					this.handleErrorAlreadyUpdated(err);
				} else {
					const message = pathOr('Something went wrong', ['data', 'error', 'message'])(err);
					this.alert.error('', message);
				}
				this.loaderService.hideLoader();
				callFunc();
			}
		);
	}

	handleErrorAlreadyUpdated(error) {
		let invoice_status;
		if (error.data && error.data.messages && error.data.messages.invoices && error.data.messages.invoices.length) {
			invoice_status = error.data.messages.invoices[0].status_code;
		}
		if (error && error.status === 409 && ((error.data && error.data.invoice_status && error.data.invoice_status) || invoice_status)) {
			let realStatus = parseInt(error.data.invoice_status) || invoice_status;
			if (realStatus === this.invoiceStatusEnum.DO_NOT_PROCESS) {
				this.updateLastStep();
			}
			this.updateSteps(realStatus);
			this.onStatusChange.emit(realStatus);
			this.alert.info('', 'Invoice already updated');
			this.loaderService.hideLoader();
		}
	}

	getStepNumberForKey(key) {
		return (
			this.flow.steps.findIndex((step) => {
				return step.key === key;
			}) + 1
		);
	}

	isOnHold(status, invoice) {
		return this.isOnHoldEligible(status) && pathEq(['account', 'on_hold'], true, invoice);
	}

	isOnHoldEligible(status) {
		return status < this.invoiceStatusEnum.RFA && status !== this.invoiceStatusEnum.GL_CODED_FULL;
	}

	isAccountOnhold(invoice) {
		return this.accountService.findById(invoice.account_id);
	}

	handleErrorAlreadyUpdatedNewWay(error) {
		let invoice_status = error.invoices[0].status_code;
		this.updateSteps(invoice_status);
		this.onStatusChange.emit(invoice_status);
		this.alert.info('', 'Invoice already updated');
		this.loaderService.hideLoader();
	}

	elapsedTimeModal(status: any) {
		return status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_USER && status !== this.DATA_LOCK_CLOSE_STATUS.TIME_EXTEND && status !== this.DATA_LOCK_CLOSE_STATUS.CANCEL_BY_TIMER;
	}

	findCurrentStep(flow) {
		return flow.steps.filter((step) => {
			if (step.code === flow.currentStep) {
				return step;
			}
		})[0].key;
	}

	findNextStep(flow) {
		return flow.steps.filter((step) => {
			if (step.code === flow.currentStep + 1) {
				return step;
			}
		})[0].key;
	}

	public revertToNew(flow, invoice, mediator, callFunc, data) {
		this.invoice = invoice;
		const { currentStep } = flow;
		this.dialog
			.edit(InvoiceResetToNewManageComponent, {
				invoice: this.invoiceService.findByIdForEdit(invoice.invoice_id)
			})
			.subscribe((items: any) => {
				if (items) {
					let obs = observableOf(items);
					obs.pipe(mergeMap((x) => x.afterClosed())).subscribe((result: any) => {
						if (result && result.reset) {
							let statusReset = this.invoiceStatusEnum.NEW_RESET;
							this.invoiceService
								.updateStatus(invoice.invoice_id, {
									status: this.invoiceStatusEnum.NEW_PENDING,
									currentStatus: flow.currentStatus,
									statusReset
								})
								.subscribe(
									() => {
										this.loaderService.hideLoader();
										if (currentStep === flow.steps[flow.steps.length - 1].code) {
											this.updateLastStep(
												new FlowStep({
													name: 'AP Feed Received',
													key: 'REVERSE_AP_FEED_RECEIVED',
													progressStatusAlert: 'GL output not sent'
												})
											);
										}
										this.updateSteps(statusReset);
										this.invoice.header.status_code = statusReset;
										if (result.note && result.note.length) {
											let note = new Notes({
												entity_id: invoice.invoice_id,
												entity_type: this.SYSTEM_MODULE.INVOICE,
												content: 'Reset to New: ' + result.note
											});
											this.notesService.create(note).subscribe(() => {
												//
											});
										}
										this.onStatusChange.emit(statusReset);
									},
									(error) => {
										this.handleErrorAlreadyUpdated(error);
									}
								);
						}
					});
				}
			});
	}

	public onGLClick(flow, invoice, mediator, callFunc, data) {
		this.invoice = invoice;
		if (this.isGlEnabled) {
			if (this.status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
				if (this.invoice.header.status_code === this.invoiceStatusEnum.UNSET_ADJUSTMENTS) {
					mediator.emit(data);
					callFunc();
				} else if (this.invoice.header.status_code === this.invoiceStatusEnum.PRE_GL_WARNING) {
					this.commonAlertService.findAlertForInvoice(invoice.invoice_id).subscribe((result) => {
						const alert_id = result[0].alert_id;
						if (alert_id) {
							this.router.navigate([`alert/${alert_id}/gl-validation`, 2]);
						}
					});
				} else if (this.invoice.header.status_code === this.invoiceStatusEnum.VAT_GL_MISMATCH) {
					this.commonAlertService.findAlertForInvoice(invoice.invoice_id).subscribe((result) => {
						const message = result[0].message;
						this.dialog
							.vatWarning({
								title: '',
								bodyText: message,
								invoiceID: this.invoice.invoice_id,
								vendorID: this.invoice.vendor_id,
								accountID: this.invoice.account_id
							})
							.afterClosed()
							.subscribe((res) => {
								if (res) {
									this.currentStatus = this.invoiceStatusEnum.VAT_GL_MISMATCH;
									this.invoiceService
										.updateStatus(invoice.invoice_id, {
											status: this.invoiceStatusEnum.GL_PENDING,
											currentStatus: this.currentStatus
										})
										.subscribe(
											() => {
												this.onStatusChange.emit(this.invoiceStatusEnum.GL_PENDING);
												this.updateSteps(this.invoiceStatusEnum.GL_PENDING);
												let proceed_to_gl = true;
												this.invoiceService.processMultipleInvoices(this.invoice.invoice_id, proceed_to_gl);
											},
											(error) => {
												this.handleErrorAlreadyUpdated(error);
											}
										);
								}
							});
					});
				}
			}
		}
	}

	goToGL(flow, invoice) {
		this.router.navigate(['gl-rule-execution', invoice.invoice_id, invoice.vendor_id, invoice.account_id]);
	}

	revertToGL(flow, invoice, mediator, callFunc) {
		this.status = this.invoiceStatusEnum.GL_CODED_FULL;
		const currentStatusKey = Object.keys(this.invoiceStatusEnum).find((key) => this.invoiceStatusEnum[key] === invoice.header.status_code);

		this.currentStatus = currentStatusKey ? this.invoiceStatusEnum[currentStatusKey] : this.invoiceStatusEnum.RFA;

		this.invoiceService
			.updateStatus(invoice.invoice_id, {
				status: this.status,
				currentStatus: this.currentStatus
			})
			.subscribe(
				() => {
					this.updateSteps(this.status);
					this.onStatusChange.emit(this.status);
					callFunc();
				},
				(error) => {
					this.handleErrorAlreadyUpdated(error);
				}
			);
	}

	async goToAudit(flow, invoice, mediator, callFunc, data) {
		this.auditConfigService
			.findForInvoice(invoice.invoice_id)
			.pipe(
				switchMap((result: any) => {
					if (!result.items.length) {
						return of();
					}

					const auditIds = result.items.map((a) => a.id);
					this.alert.info('', 'Running Audits.');

					return this.auditConfigService.runFromInvoices(auditIds, [invoice.invoice_id]);
				})
			)
			.subscribe(() => {
				this.updateSteps(this.status);
				this.onStatusChange.emit(this.status);
				callFunc();
			});
	}

	async goToRfA(flow, invoice, mediator, callFunc, data) {
		const { on_hold } = await this.isAccountOnhold(invoice).toPromise();
		if (on_hold) {
			return;
		}

		this.onHoldChange.emit(false);

		const auditQuery = new Query();
		auditQuery['where']['invoice_id'] = invoice.invoice_id;
		auditQuery['where']['status_id'] = [LOOKUP_ENUM.ALERT_STATUS.IN_PROGRESS, LOOKUP_ENUM.ALERT_STATUS.NEW];

		const alerts: CommonAlert[] = await lastValueFrom(this.commonAlertService.getAlertsForInvoice(auditQuery).pipe(map((res: any) => res.items)));

		const confirmObs$ = Boolean(alerts.length)
			? this.dialog
					.confirm({
						title: '',
						bodyText: this.LOCALS.ACTIVE_ALERTS
					})
					.afterClosed()
			: of(true);
		const confirm = await lastValueFrom(confirmObs$);

		if (!confirm) {
			return;
		}

		let newQuery = new Query({
			where: { invoice_id: { $in: [invoice.invoice_id] } }
		});
		const [isInvoiceInvalid] = await lastValueFrom(this.invoiceService.getNonBalancedInvoices(newQuery));

		if (isInvoiceInvalid) {
			this.dialog.open(InvoiceBalanceErrorDialogComponent, {
				invoice: invoice
			});

			return;
		}

		const currentStatusKey = Object.keys(this.invoiceStatusEnum).find((key) => this.invoiceStatusEnum[key] === invoice.header.status_code);

		this.currentStatus = currentStatusKey ? this.invoiceStatusEnum[currentStatusKey] : this.invoiceStatusEnum.GL_CODED_FULL;

		if (!this.isGlEnabled) {
			this.status = this.invoiceStatusEnum.RFA;

			this.invoiceService
				.updateStatus(invoice.invoice_id, {
					status: this.status
				})
				.subscribe(
					() => {
						this.updateSteps(this.status);
						this.onStatusChange.emit(this.status);
						callFunc();
					},
					(error) => {
						this.handleErrorAlreadyUpdated(error);
					}
				);

			return;
		}

		if (invoice.header.status_code === this.invoiceStatusEnum.APPROVED) {
			// dont trigger a new confirm if the user already confirmed
			const confirmObs$ = Boolean(alerts.length)
				? of(true)
				: this.dialog
						.confirm({
							title: '',
							bodyText: this.LOCALS.REVERT_TO_RFA
						})
						.afterClosed();

			confirmObs$
				.pipe(
					filter((res) => res),
					switchMap(() => {
						this.status = this.invoiceStatusEnum.RFA;
						return this.invoiceService.updateStatus(invoice.invoice_id, {
							status: this.status,
							currentStatus: this.currentStatus
						});
					})
				)
				.subscribe({
					next: () => {
						this.updateSteps(this.status);
						this.onStatusChange.emit(this.status);
						callFunc();
					},
					error: (error) => {
						this.handleErrorAlreadyUpdated(error);
					}
				});

			return;
		}

		this.status = this.invoiceStatusEnum.RFA;
		this.invoiceService
			.updateStatus(invoice.invoice_id, {
				status: this.status,
				currentStatus: this.currentStatus
			})
			.pipe(
				catchError((error) => {
					this.handleErrorAlreadyUpdated(error);

					return throwError(() => new Error(error));
				}),
				switchMap(() => this.invoiceService.findAllUncodedCharges(invoice.invoice_id))
			)
			.subscribe((result) => {
				if (result && result.count === 0) {
					this.updateSteps(this.status);
					this.onStatusChange.emit(this.status);
					callFunc();
				} else {
					this.dialog.open(InvoiceStatusGlComponent, {
						state: result
					});
				}
			});
	}

	async revertToRfa(flow, invoice, mediator, callFunc, data) {
		this.currentStatus = this.invoiceStatusEnum.APPROVED;

		if (!this.isGlEnabled) {
			this.status = this.invoiceStatusEnum.RFA;

			this.invoiceService
				.updateStatus(invoice.invoice_id, {
					status: this.status,
					currentStatus: this.currentStatus
				})
				.subscribe(
					() => {
						this.updateSteps(this.status);
						this.onStatusChange.emit(this.status);
						callFunc();
					},
					(error) => {
						this.handleErrorAlreadyUpdated(error);
					}
				);

			return;
		}

		if (invoice.header.status_code === this.invoiceStatusEnum.APPROVED) {
			this.dialog
				.confirm({
					title: '',
					bodyText: this.LOCALS.REVERT_TO_RFA
				})
				.afterClosed()
				.pipe(
					filter((res) => res),
					switchMap(() => {
						this.status = this.invoiceStatusEnum.RFA;
						this.currentStatus = this.invoiceStatusEnum.APPROVED;
						return this.invoiceService.updateStatus(invoice.invoice_id, {
							status: this.status,
							currentStatus: this.currentStatus
						});
					})
				)
				.subscribe({
					next: () => {
						this.updateSteps(this.status);
						this.onStatusChange.emit(this.status);
						callFunc();
					},
					error: (error) => {
						this.handleErrorAlreadyUpdated(error);
					}
				});

			return;
		}

		this.status = this.invoiceStatusEnum.RFA;
		this.currentStatus = this.invoiceStatusEnum.APPROVED;
		this.invoiceService
			.updateStatus(invoice.invoice_id, {
				status: this.status,
				currentStatus: this.currentStatus
			})
			.pipe(
				catchError((error) => {
					this.handleErrorAlreadyUpdated(error);
					return throwError(() => new Error(error));
				}),
				switchMap(() => this.invoiceService.findAllUncodedCharges(invoice.invoice_id))
			)
			.subscribe((result) => {
				if (result && result.count === 0) {
					this.updateSteps(this.status);
					this.onStatusChange.emit(this.status);
					callFunc();
				} else {
					this.dialog.open(InvoiceStatusGlComponent, {
						state: result
					});
				}
			});
	}

	async goToApproved(flow, invoice, mediator, callFunc, data) {
		let newQuery = new Query({
			where: { invoice_id: { $in: [invoice.invoice_id] } }
		});
		const isInvoiceValid = await this.invoiceService.getNonBalancedInvoices(newQuery).toPromise();
		if (!isInvoiceValid[0]) {
			this.dialog
				.edit(InvoiceApprovalManageComponent, {
					invoice: this.invoiceService.findByIdForApprovedEdit(invoice.invoice_id)
				})
				.subscribe((items: any) => {
					if (items) {
						let obs = observableOf(items);
						obs.pipe(mergeMap((x) => x.afterClosed())).subscribe((result: any) => {
							if (result && result.approved) {
								this.status = this.invoiceStatusEnum.APPROVED;
								this.currentStatus = this.invoiceStatusEnum.RFA;
								this.invoiceService
									.updateStatusApproved(invoice.invoice_id, {
										status: this.status,
										currentStatus: this.currentStatus
									})
									.subscribe(
										() => {
											this.flow.steps[3].disabled = this.isApproveButtonDisabled;
											this.updateSteps(this.status);
											this.onStatusChange.emit(this.status);
										},
										(error) => {
											this.handleErrorAlreadyUpdated(error);
										}
									);
							} else if (result && result.rejected) {
								let statusReset = this.invoiceStatusEnum.NEW_REJECTED;
								this.status = this.invoiceStatusEnum.NEW_PENDING;
								this.currentStatus = this.invoiceStatusEnum.RFA;
								this.updateSteps(this.status);
								this.invoiceService
									.updateStatus(invoice.invoice_id, {
										status: this.status,
										currentStatus: this.currentStatus,
										statusReset: statusReset
									})
									.subscribe(
										() => {
											this.loaderService.hideLoader();
											this.updateSteps(statusReset);
											invoice.header.status_code = statusReset;
											if (result.note && result.note.length) {
												let note = new Notes({
													entity_id: invoice.invoice_id,
													entity_type: this.SYSTEM_MODULE.INVOICE,
													content: `Invoice Rejected: ${result.note}`
												});
												this.notesService.create(note).subscribe(() => {});
											}
											this.onStatusChange.emit(statusReset);
										},
										(error) => {
											this.handleErrorAlreadyUpdated(error);
										}
									);
							}
						});
					}
				});
		} else {
			this.dialog.open(InvoiceBalanceErrorDialogComponent, {
				invoice: invoice
			});
		}
	}

	revertToApproved(flow, invoice, mediator, callFunc, data) {
		if (this.configMergeInToOneStep) {
			if (invoice.header.status_code !== this.invoiceStatusEnum.GL_BATCH_ERROR) return;

			// added client_guid check; if there is client_guid we must not revert invoice to approved
			if (invoice.header.client_guid) {
				this.alert.info(null, 'Cannot revert invoice that has external Bill reverence');
				return;
			}

			// if (invoice.header.status_code === this.invoiceStatusEnum.GL_BATCH_ERROR) {
			this.invoice = invoice;
			this.dialog
				.edit(InvoiceResetToNewManageComponent, {
					invoice: this.invoiceService.findByIdForEdit(invoice.invoice_id)
				})
				.subscribe((items: any) => {
					if (items) {
						let obs = observableOf(items);
						obs.pipe(mergeMap((x) => x.afterClosed())).subscribe((result: any) => {
							if (result && result.reset) {
								let statusReset = this.invoiceStatusEnum.NEW_RESET;
								this.invoiceService
									.updateStatus(invoice.invoice_id, {
										status: this.invoiceStatusEnum.NEW_PENDING,
										currentStatus: this.invoiceStatusEnum.GL_BATCH_ERROR,
										statusReset
									})
									.subscribe(
										() => {
											this.loaderService.hideLoader();
											this.invoice.header.status_code = statusReset;
											if (result.note && result.note.length) {
												let note = new Notes({
													entity_id: invoice.invoice_id,
													entity_type: this.SYSTEM_MODULE.INVOICE,
													content: 'Reset to New: ' + result.note
												});
												this.notesService.create(note).subscribe();
											}
											this.onStatusChange.emit(statusReset);
										},
										(error) => {
											this.handleErrorAlreadyUpdated(error);
										}
									);
							}
						});
					}
				});
			// } else return;
		} else {
			if (this.isPartailBatchDeactivationDisabled) {
				this.alert.info(null, 'Single invoice deactivation is not allowed');
				return;
			}

			this.status = this.invoiceStatusEnum.NEW_PENDING;
			this.currentStatus = this.invoiceStatusEnum.APPROVED;
			this.glBatchService.getBatchForInvoice(invoice.invoice_id).subscribe((result) => {
				if (result.length > 0) {
					// Patch sometimes incorrectly returned results
					// Find batches with correct invoice id
					let batchInvoice = result.filter((bi) => bi.invoice_id === invoice.invoice_id);
					// Find last batch
					let lastBatch = batchInvoice.reduce((max, batch) => (max.batch_id > batch.batch_id ? max : batch));
					// Get last batch id
					let batchId = lastBatch.batch_id;
					this.currentStatus = this.invoiceStatusEnum.GL_BATCH_OUTPUT;
					this.invoiceService.openConfirmationDialog({
						invoices: invoice,
						actionText: 'deactivate',
						title: 'Deactivate invoice',
						confirmText: 'Are you sure you want to deactivate this invoice?',
						config: null,
						onDeleteConfirmed: (deleteResult) => {
							try {
								this.invoiceService
									.checkInvoiceStatus({
										invoiceIds: [invoice.invoice_id],
										currentStatus: this.currentStatus
									})
									.subscribe((invoiceStatus) => {
										if (invoiceStatus.error) {
											deleteResult.closeDialog();
											callFunc();
											this.handleErrorAlreadyUpdatedNewWay(invoiceStatus);
											return;
										}
										this.glBatchService
											.partialDeactivation(batchId, [invoice.invoice_id], deleteResult.note)
											.pipe(
												tap(() => {
													this.updateSteps(this.status);
													this.onStatusChange.emit(this.status);
													callFunc();
													deleteResult.closeDialog();
												})
											)
											.pipe(delay(200))
											.pipe(
												tap(() => {
													this.alert.success('', this.messages.get('PARTIAL_SINGLE_DEACTIVATE_SUCCESS'));
												})
											)
											.subscribe(
												() => {},
												(err) => {
													this.alert.success('glBatchService.deactivateBatch error: ', err);
												}
											);
									});
							} catch (e) {
								deleteResult.closeDialog();
								this.alert.info('', 'Batch Deactivation failed');
							}
						}
					});
				} else {
					this.invoiceService
						.updateStatus(invoice.invoice_id, {
							status: this.status,
							statusReset: this.invoiceStatusEnum.NEW_RESET,
							currentStatus: this.currentStatus
						})
						.subscribe(
							() => {
								this.updateSteps(this.status);
								this.onStatusChange.emit(this.status);
							},
							(error) => {
								this.handleErrorAlreadyUpdated(error);
							}
						);
				}
			});
		}
	}

	async goToGlBatchOutput(flow, invoice, mediator, callFunc, data) {
		if (this.configMergeInToOneStep) {
			this.loaderService.displayLoader();
			this.status = this.invoiceStatusEnum.GL_BOOKING;
			this.currentStatus = this.invoiceStatusEnum.APPROVED;
			this.invoiceService
				.updateStatus(invoice.invoice_id, {
					status: this.status,
					currentStatus: this.currentStatus
				})
				.subscribe(
					() => {
						this.loaderService.hideLoader();
						this.updateSteps(this.status);
						this.onStatusChange.emit(this.status);
					},
					(error) => {
						this.handleErrorAlreadyUpdated(error);
					}
				);
		} else {
			let newQuery = new Query({
				where: { invoice_id: { $in: [invoice.invoice_id] } }
			});
			const isInvoiceValid = await this.invoiceService.getNonBalancedInvoices(newQuery).toPromise();
			if (!isInvoiceValid[0]) {
				if (invoice.account.status_id === ACCOUNT_STATUS_ENUM.ACTIVE) {
					this.dialog
						.edit(InvoiceConfirmDialog, {
							invoice: this.invoiceService.findByIdForEdit(invoice.invoice_id)
						})
						.subscribe((items: any) => {
							if (items) {
								let obs = observableOf(items);
								obs.pipe(mergeMap((x) => x.afterClosed())).subscribe((result: any) => {
									if (this.config.gl_coding.format === BATCH_OUTPUT_FORMAT_ENUM.DXC_CSC) {
										this.status = this.invoiceStatusEnum.GL_BATCH_PROCESSING;
									}
									if (result && this.elapsedTimeModal(result.status)) {
										let glBatch = new GLBatch();
										glBatch.invoices = [
											new InvoiceFacepage({
												id: invoice.id,
												invoice_id: invoice.invoice_id
											})
										];
										this.loaderService.displayLoader();
										this.flow.steps[3].disabled = true;
										this.updateSteps(this.status);
										this.onStatusChange.emit(this.status);
										this.createGlBatch(glBatch, invoice, callFunc);
										this.flow.steps[3].disabled = false;
									}
								});
							}
						});
				} else {
					this.dialog
						.simpleAlert({
							bodyText: this.LOCALS.INVOICE_IS_INACTIVE
						})
						.afterClosed();
				}
			} else {
				this.dialog.open(InvoiceBalanceErrorDialogComponent, {
					invoice: invoice
				});
			}
		}
	}

	async revertToGlBatchOutput(flow, invoice, mediator, callFunc, data) {
		let newQuery = new Query({
			where: { invoice_id: { $in: [invoice.invoice_id] } }
		});
		const isInvoiceValid = await this.invoiceService.getNonBalancedInvoices(newQuery).toPromise();
		if (!isInvoiceValid[0]) {
			if (invoice.account.status_id === ACCOUNT_STATUS_ENUM.ACTIVE) {
				this.dialog
					.confirm({
						bodyText: this.LOCALS.REVERT_TO_BATCH_OUPUT
					})
					.afterClosed()
					.subscribe((result) => {
						this.status = this.invoiceStatusEnum.GL_BATCH_OUTPUT;
						this.currentStatus = this.invoiceStatusEnum.GL_BATCH_OUTPUT_SEND;
						if (result) {
							this.loaderService.displayLoader();
							this.invoiceService
								.updateStatus(invoice.invoice_id, {
									status: this.status,
									currentStatus: this.currentStatus
								})
								.subscribe(
									() => {
										this.loaderService.hideLoader();
										this.updateSteps(this.status);
										this.onStatusChange.emit(this.status);
									},
									(error) => {
										this.handleErrorAlreadyUpdated(error);
									}
								);
						}
					});
			} else {
				this.dialog
					.simpleAlert({
						bodyText: this.LOCALS.INVOICE_IS_INACTIVE
					})
					.afterClosed();
			}
		} else {
			this.dialog.open(InvoiceBalanceErrorDialogComponent, {
				invoice: invoice
			});
		}
	}

	goToGLOutputSent(flow, invoice, mediator, callFunc, data) {
		this.status = this.invoiceStatusEnum.GL_BATCH_OUTPUT_SEND;
		this.dialog
			.confirm({
				bodyText: this.LOCALS.GL_OUTPUT_SENT,
				width: '380px'
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.currentStatus = this.invoiceStatusEnum.GL_BATCH_OUTPUT;
					this.loaderService.displayLoader();
					this.invoiceService
						.updateStatus(invoice.invoice_id, {
							status: this.status,
							currentStatus: this.currentStatus
						})
						.subscribe(
							() => {
								this.loaderService.hideLoader();
								this.updateSteps(this.status);
								this.onStatusChange.emit(this.status);
							},
							(error) => {
								this.handleErrorAlreadyUpdated(error);
							}
						);
				}
			});
	}

	revertToGLOutputSent(flow, invoice, mediator, callFunc, data) {
		let currentStep = this.findCurrentStep(flow);
		this.status = this.invoiceStatusEnum.GL_BATCH_OUTPUT_SEND;
		this.dialog
			.confirm({
				bodyText: this.LOCALS.REVERT_TO_GL_OUTPUT_SENT
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.currentStatus = this.invoiceStatusEnum[currentStep];
					this.invoiceService
						.updateStatus(invoice.invoice_id, {
							status: this.status,
							currentStatus: this.currentStatus
						})
						.subscribe(
							() => {
								this.updateSteps(this.status);
								this.onStatusChange.emit(this.status);
							},
							(error) => {
								this.handleErrorAlreadyUpdated(error);
							}
						);
				}
			});
	}

	goToApFeedReceived(flow, invoice, mediator, callFunc, data) {
		if (this.isGlEnabled && this.reverseAPFeedMethod === ReverseAPFeedMethod.MANUAL) {
			this.dialog
				.confirm({
					bodyText: this.LOCALS.REVERSE_AP_FEED_RECEIVED
				})
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						this.loaderService.displayLoader();
						this.status = this.invoiceStatusEnum.REVERSE_AP_FEED_RECEIVED;
						this.currentStatus = this.invoiceStatusEnum.GL_BATCH_OUTPUT_SEND;
						this.invoiceService
							.updateStatus(invoice.invoice_id, {
								status: this.status,
								currentStatus: this.currentStatus
							})
							.subscribe(
								() => {
									this.loaderService.hideLoader();
									this.updateSteps(this.status);
									this.onStatusChange.emit(this.status);
								},
								(error) => {
									this.handleErrorAlreadyUpdated(error);
								}
							);
					}
				});
		}
	}

	// TODO: check usage
	goToInvoicePayment(flow, invoice, mediator, callFunc, data) {
		this.loaderService.displayLoader();
		// Get invoice status from DB
		this.status = this.invoiceStatusEnum.PAYMENT_SENT;
		let currentStep = this.findNextStep(flow);
		this.currentStatus = this.invoiceStatusEnum[currentStep];
		this.invoiceService
			.updateStatus(invoice.invoice_id, {
				status: this.status,
				currentStatus: this.currentStatus
			})
			.subscribe(
				() => {
					this.loaderService.hideLoader();
					this.updateSteps(this.status);
					this.onStatusChange.emit(this.status);
				},
				(error) => {
					this.handleErrorAlreadyUpdated(error);
				}
			);
	}

	async goToGLBooking(flow, invoice, mediator, callFunc, data) {
		this.dialog
			.confirm({
				title: '',
				bodyText: `Book ${invoice.sp_inv_num}?`
			})
			.afterClosed()
			.subscribe(async (res) => {
				if (res) {
					// check if invoice is in balance / if not display message and stop with purchase
					const isInvoiceNonBalanced = await this.invoiceService
						.getNonBalancedInvoices(
							new Query({
								where: { invoice_id: { $in: [invoice.invoice_id] } }
							})
						)
						.toPromise();
					if (isInvoiceNonBalanced[0]) {
						this.dialog.open(InvoiceBalanceErrorDialogComponent, {
							invoice: invoice
						});
						return;
					}

					let glBatch = new GLBatch();
					glBatch.invoices = [
						new InvoiceFacepage({
							id: invoice.id,
							invoice_id: invoice.invoice_id
						})
					];

					this.loaderService.displayLoader();
					this.currentStatus = this.invoiceStatusEnum.APPROVED;
					this.status = this.invoiceStatusEnum.GL_BATCH_IN_PROCESS;
					this.updateSteps(this.status);

					this.createGlBatch(glBatch, invoice, callFunc);
				}
			});
	}

	// TODO: check usage
	revertToGLBooking(flow, invoice, mediator, callFunc, data) {
		this.loaderService.displayLoader();
		let currentStep = this.findCurrentStep(flow);
		this.status = this.invoiceStatusEnum.GL_BOOKING;
		this.currentStatus = this.invoiceStatusEnum.PAYMENT_SENT;
		this.invoiceService
			.updateStatus(invoice.invoice_id, {
				status: this.status,
				currentStatus: this.currentStatus
			})
			.subscribe(
				() => {
					this.loaderService.hideLoader();
					this.updateSteps(this.status);
					this.onStatusChange.emit(this.status);
				},
				(error) => {
					this.handleErrorAlreadyUpdated(error);
				}
			);
	}

	public updateSteps(status: number, flow?, invoice?) {
		const step = this.flow.steps[3];
		if (flow) {
			this.flow = flow;
		}
		if (step) {
			step.disabled = this.isApproveButtonDisabled;
		}
		const { currentStep } = this.flow;
		if (this.isOnHold(status, invoice)) {
			this.updateStep(this.getStepNumberForKey('GL'), 'On Hold', 'mat-error', this.ICONS.INVOICE_FLOW_WARNING);
			this.flow.currentStep = this.getStepNumberForKey('GL');
			return;
		}
		const doNotProcessStepCode = this.flow.steps.find((s) => 'Do Not Process' === s.name);
		const feedRStepCode = this.flow.steps.find((s) => 'Reverse AP Feed Received' === s.name);
		this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['GL_CODED_FULL']);

		if (status === this.invoiceStatusEnum.NEW) {
			this.updateStep(this.getStepNumberForKey('NEW'), this.invoiceStatusMap['NEW']);
		} else if (status === this.invoiceStatusEnum.UNSET_ADJUSTMENTS && this.isGlEnabled) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['UNSET_ADJUSTMENTS'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		} else if (status === this.invoiceStatusEnum.GL_CODING_IN_PROCESS && this.isGlEnabled) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['GL_CODING_IN_PROCESS'], 'mat-error', this.ICONS.INVOICE_FLOW_PENDING);
		} else if (status === this.invoiceStatusEnum.GL_PENDING && this.isGlEnabled) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['GL_PENDING'], 'mat-error', this.ICONS.INVOICE_FLOW_PENDING);
		} else if (status === this.invoiceStatusEnum.GL_CODED_PARTIAL && this.isGlEnabled) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['GL_CODED_PARTIAL'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		} else if (status === this.invoiceStatusEnum.GL_CODED_FULL && this.isGlEnabled) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['GL_CODED_FULL']);
		} else if (status === this.invoiceStatusEnum.GL_CODING_MISMATCH && this.isGlEnabled) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['GL_CODING_MISMATCH'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		} else if (status === this.invoiceStatusEnum.GL_CODING_FAILED && this.isGlEnabled) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['GL_CHARGES_TOTAL_ERROR'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		} else if (status === this.invoiceStatusEnum.PRE_GL_WARNING && this.isGlEnabled) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['GL_WARNING'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		} else if (status === this.invoiceStatusEnum.NEW_REJECTED) {
			this.updateStep(this.getStepNumberForKey('NEW'), this.invoiceStatusMap['NEW_REJECTED']);
		} else if (status === this.invoiceStatusEnum.NEW_RESET) {
			this.updateStep(this.getStepNumberForKey('NEW'), this.invoiceStatusMap['NEW_RESET']);
		} else if (status === this.invoiceStatusEnum.NEW_PENDING) {
			this.updateStep(this.getStepNumberForKey('NEW'), this.invoiceStatusMap['NEW_PENDING']);
		} else if (status === this.invoiceStatusEnum.VAT_GL_WARNING) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['VAT_GL_WARNING'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		} else if (status === this.invoiceStatusEnum.VAT_GL_MISMATCH) {
			this.updateStep(this.getStepNumberForKey('GL'), this.invoiceStatusMap['VAT_GL_MISMATCH'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		}

		if (status === this.invoiceStatusEnum.AUDIT_WARNING) {
			this.updateStep(this.getStepNumberForKey('AUDIT'), this.invoiceStatusMap['AUDIT_WARNING'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		} else if (status === this.invoiceStatusEnum.AUDIT_PASSED) {
			this.updateStep(this.getStepNumberForKey('AUDIT'), this.invoiceStatusMap['AUDIT_PASSED']);
		} else if (status === this.invoiceStatusEnum.AUDIT_RUNNING) {
			this.updateStep(this.getStepNumberForKey('AUDIT'), this.invoiceStatusMap['AUDIT_RUNNING'], 'mat-error', this.ICONS.INVOICE_FLOW_PENDING);
		}

		if (status >= this.invoiceStatusEnum.RFA && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('NEW'), this.invoiceStatusMap['NEW']);
			/** in case invoice was in reset/reject status - clears alert icon and warning css class */
		}

		if (status >= this.invoiceStatusEnum.APPROVED && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('APPROVED'), this.invoiceStatusMap['APPROVED']);
		}

		if (status === this.invoiceStatusEnum.GL_BATCH_PROCESSING) {
			this.updateStep(this.getStepNumberForKey('GL_BATCH'), this.invoiceStatusMap['GL_PENDING'], 'mat-error', this.ICONS.INVOICE_FLOW_PENDING);
		}

		if (status >= this.invoiceStatusEnum.GL_BATCH_OUTPUT && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('GL_BATCH'), this.invoiceStatusMap['GL_BATCH_OUTPUT']);
		}

		if (status >= this.invoiceStatusEnum.GL_BATCH_OUTPUT_SEND && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('GL_OUTPUT'), this.invoiceStatusMap['GL_BATCH_OUTPUT_SEND']);
		}

		if (status >= this.invoiceStatusEnum.REVERSE_AP_FEED_RECEIVED && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('REVERSE_AP_FEED_RECEIVED'), this.invoiceStatusMap['REVERSE_AP_FEED_RECEIVED']);
		}
		if (status >= this.invoiceStatusEnum.GL_BATCH_SENT && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('GL_BOOKING'), this.invoiceStatusMap['GL_BATCH_SENT']);
		} else if (status === this.invoiceStatusEnum.GL_BATCH_ERROR) {
			this.updateStep(this.getStepNumberForKey('GL_BOOKING'), this.invoiceStatusMap['GL_BATCH_ERROR'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		} else if (status === this.invoiceStatusEnum.GL_BATCH_IN_PROCESS) {
			this.updateStep(this.getStepNumberForKey('GL_BOOKING'), this.invoiceStatusMap['GL_BATCH_IN_PROCESS'], 'mat-error', this.ICONS.INVOICE_FLOW_PENDING);
		}

		if (status === this.invoiceStatusEnum.PAYMENT_SENT && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('INVOICE_PAYMENT'), this.invoiceStatusMap['PAYMENT_SENT']);
		} else if (status === this.invoiceStatusEnum.GL_POSTED && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('INVOICE_PAYMENT'), this.invoiceStatusMap['GL_POSTED']);
		} else if (status === this.invoiceStatusEnum.PAYMENT_CONFIRMED && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('INVOICE_PAYMENT'), this.invoiceStatusMap['PAYMENT_CONFIRMED']);
		} else if (status === this.invoiceStatusEnum.BOOKING_CANCELED && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('INVOICE_PAYMENT'), this.invoiceStatusMap['BOOKING_CANCELED']);
		} else if (status === this.invoiceStatusEnum.INVOICE_DELETED && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('INVOICE_PAYMENT'), this.invoiceStatusMap['INVOICE_DELETED']);
		} else if (status === this.invoiceStatusEnum.INVOICE_REVERSED && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('INVOICE_PAYMENT'), this.invoiceStatusMap['INVOICE_REVERSED']);
		} else if (status === this.invoiceStatusEnum.PAYMENT_ERROR && status !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('INVOICE_PAYMENT'), this.invoiceStatusMap['PAYMENT_ERROR'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
		}

		if (status >= this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.updateStep(this.getStepNumberForKey('DO_NOT_PROCESS'), this.invoiceStatusMap['DO_NOT_PROCESS'], 'mat-error', this.ICONS.INVOICE_FLOW_ERROR);
			this.updateStep(this.getStepNumberForKey('NEW'), 'New');
		}
		if (status >= this.invoiceStatusEnum.COMPLETED) {
			if (this.reverseAPFeedMethod === ReverseAPFeedMethod.MANUAL && this.isGlEnabled) {
				this.updateStep(this.getStepNumberForKey('REVERSE_AP_FEED_RECEIVED'), this.invoiceStatusMap['REVERSE_AP_FEED_RECEIVED']);
			} else if (this.reverseAPFeedMethod === ReverseAPFeedMethod.NONE && this.isGlEnabled) {
				this.updateStep(this.getStepNumberForKey('GL_OUTPUT'), this.invoiceStatusMap['GL_BATCH_OUTPUT_SEND']);
			} else {
				this.updateStep(this.getStepNumberForKey('APPROVED'), this.invoiceStatusMap['APPROVED']);
			}
		}

		if (status === this.invoiceStatusEnum.NEW || status === this.invoiceStatusEnum.NEW_REJECTED || status === this.invoiceStatusEnum.NEW_RESET || status === this.invoiceStatusEnum.NEW_PENDING) {
			this.flow.currentStep = this.getStepNumberForKey('NEW');
		}
		if (
			status === this.invoiceStatusEnum.UNSET_ADJUSTMENTS ||
			status === this.invoiceStatusEnum.GL_CODED_FULL ||
			status === this.invoiceStatusEnum.GL_CODED_PARTIAL ||
			status === this.invoiceStatusEnum.GL_CODING_IN_PROCESS ||
			status === this.invoiceStatusEnum.GL_CODING_MISMATCH ||
			status === this.invoiceStatusEnum.GL_CODING_FAILED ||
			status === this.invoiceStatusEnum.PRE_GL_WARNING ||
			status === this.invoiceStatusEnum.VAT_GL_WARNING ||
			status === this.invoiceStatusEnum.VAT_GL_MISMATCH ||
			status === this.invoiceStatusEnum.GL_PENDING
		) {
			this.flow.currentStep = this.getStepNumberForKey('GL');
			this.flow.currentStatus = status;
		}
		if ([this.invoiceStatusEnum.AUDIT_WARNING, this.invoiceStatusEnum.AUDIT_PASSED, this.invoiceStatusEnum.AUDIT_RUNNING].includes(status)) {
			this.flow.currentStep = this.getStepNumberForKey('AUDIT');
		} else if (status === this.invoiceStatusEnum.RFA) {
			this.flow.currentStep = this.getStepNumberForKey('RFA');
		} else if (status === this.invoiceStatusEnum.APPROVED) {
			this.flow.currentStep = this.getStepNumberForKey('APPROVED');
		} else if (
			(status === this.invoiceStatusEnum.GL_BATCH_OUTPUT && currentStep === this.getStepNumberForKey('APPROVED') && !this.isGlOutputAutoSend) ||
			status === this.invoiceStatusEnum.GL_BATCH_PROCESSING
		) {
			this.flow.currentStep = this.getStepNumberForKey('GL_BATCH');
		} else if (
			(status === this.invoiceStatusEnum.GL_BATCH_OUTPUT && currentStep === this.getStepNumberForKey('APPROVED') && this.isGlOutputAutoSend) ||
			status === this.invoiceStatusEnum.GL_BATCH_PROCESSING
		) {
			this.flow.currentStep = this.getStepNumberForKey('GL_BATCH');
		} else if (status === this.invoiceStatusEnum.GL_BATCH_OUTPUT && currentStep === 6) {
			this.flow.currentStep = this.getStepNumberForKey('GL_BATCH');
		} else if (status === this.invoiceStatusEnum.GL_BATCH_OUTPUT) {
			this.flow.currentStep = this.getStepNumberForKey('GL_BATCH');
		} else if (status === this.invoiceStatusEnum.GL_BATCH_OUTPUT_SEND) {
			this.flow.currentStep = this.getStepNumberForKey('GL_OUTPUT');
		} else if (status === this.invoiceStatusEnum.REVERSE_AP_FEED_RECEIVED) {
			this.flow.currentStep = this.getStepNumberForKey('REVERSE_AP_FEED_RECEIVED');
		} else if (status === this.invoiceStatusEnum.DO_NOT_PROCESS) {
			this.flow.currentStep = this.getStepNumberForKey('DO_NOT_PROCESS');
			this.flow.currentStatus = status;
		}

		if (
			status === this.invoiceStatusEnum.GL_BOOKING ||
			status === this.invoiceStatusEnum.GL_BATCH_ERROR ||
			status === this.invoiceStatusEnum.GL_BATCH_IN_PROCESS ||
			status === this.invoiceStatusEnum.GL_BATCH_SENT
		) {
			this.flow.currentStep = this.getStepNumberForKey('GL_BOOKING');
		}

		if (
			status === this.invoiceStatusEnum.PAYMENT_SENT ||
			status === this.invoiceStatusEnum.GL_POSTED ||
			status === this.invoiceStatusEnum.PAYMENT_CONFIRMED ||
			status === this.invoiceStatusEnum.BOOKING_CANCELED ||
			status === this.invoiceStatusEnum.INVOICE_DELETED ||
			status === this.invoiceStatusEnum.INVOICE_REVERSED ||
			status === this.invoiceStatusEnum.PAYMENT_ERROR
		) {
			this.flow.currentStep = this.getStepNumberForKey('INVOICE_PAYMENT');
		}
	}

	updateLastStep(step?: FlowStep) {
		if (step) {
			this.replaceStep(step);
		} else {
			this.flow.linear = false;
			this.replaceStep(
				new FlowStep({
					name: 'Do Not Process',
					code: this.getStepNumberForKey('REVERSE_AP_FEED_RECEIVED'),
					progressStatusAlert: 'Do not process'
				})
			);
		}
	}
}
