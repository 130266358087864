import { Injectable } from '@angular/core';

import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class InvoiceChargeGridService extends GridService {
	defaultColumns = [
		{
			caption: '',
			headerCellTemplate: 'selectedChargesHeaderTemplate',
			cellTemplate: 'chargeDisputeNoteTemplate',
			width: 100,
			fixed: true,
			allowSorting: false
		},
		{
			caption: 'Charge Type',
			dataField: 'chg_class',
			visible: true,
			width: 120
		},
		{
			caption: 'Charge Category',
			dataField: 'charge_category',
			visible: true,
			width: 180
		},
		{
			caption: 'Unset Adjustment',
			dataField: 'unset_adjustment.value',
			visible: true,
			width: 180
		},
		{
			caption: 'Subaccount',
			dataField: 'acct_level_2',
			width: 120
		},
		{
			caption: 'Direct GL',
			dataField: 'direct_gl_stamp',
			width: 120
		},
		{
			caption: 'Service ID',
			dataField: 'sp_serv_id',
			cellTemplate: 'spServiceId',
			visible: true,
			width: 170
		},
		{
			caption: 'Related Service ID',
			dataField: 'rel_sp_fac_id',
			visible: true,
			width: 170
		},
		{
			caption: 'Beg Charge Date',
			dataField: 'beg_chg_date',
			dataType: 'date',
			cellTemplate: 'dateTemplate',
			alignment: 'left',
			visible: true,
			width: 150
		},
		{
			caption: 'Charge Amount',
			dataField: 'chg_amt',
			dataType: 'currency',
			alignment: 'right',
			cellTemplate: 'invoiceCurrencyTemplate',
			visible: true,
			width: 120
		},
		{
			caption: 'AP Withheld Total ',
			dataField: 'ap_withheld',
			dataType: 'currency',
			alignment: 'right',
			cellTemplate: 'invoiceCurrencyTemplate',
			visible: true,
			width: 120,
			allowSorting: false
		},
		{
			caption: 'Withheld Status',
			dataField: 'charge_dispute.dispute_withheld',
			cellTemplate: 'withheldCellTemplate',
			dataType: 'boolean',
			width: 160,
			allowEditing: false,
			transformValue: {
				true: 'Yes',
				false: 'No'
			}
		},
		{
			caption: 'Adjusted Total Charges  ',
			dataField: 'adj_total_charges',
			dataType: 'currency',
			alignment: 'right',
			cellTemplate: 'invoiceCurrencyTemplate',
			visible: true,
			width: 150,
			allowSorting: false
		},
		{
			caption: 'Charge Code1',
			dataField: 'chg_code_1',
			visible: true,
			width: 110
		},
		{
			caption: 'Charge Description 1',
			dataField: 'chg_desc_1',
			visible: true,
			width: 200
		},
		{
			caption: 'Charge Code2',
			dataField: 'chg_code_2',
			visible: true,
			width: 110
		},
		{
			caption: 'Charge Description 2',
			dataField: 'chg_desc_2',
			visible: true,
			width: 200
		},
		{
			alignment: 'right',
			caption: 'Chg Qty',
			dataField: 'chg_qty1_billed',
			dataType: 'number',
			csvDataType: 'integer',
			visible: true,
			width: 100
		},
		{
			caption: 'Charge Rate',
			dataField: 'chg_rate',
			cellTemplate: 'chargeRateTemplate',
			visible: true,
			csvDataType: 'float',
			width: 100
		},
		{
			caption: 'Info Only Ind',
			dataField: 'info_only_ind',
			visible: true,
			width: 100
		},
		{
			caption: 'Facility Bandwidth',
			dataField: 'fac_bw',
			visible: true,
			width: 140
		},
		{
			caption: 'Call Type',
			dataField: 'call_type',
			visible: false,
			width: 100
		},
		{
			caption: 'Prod Type',
			dataField: 'prod_type',
			visible: false,
			width: 100
		},
		{
			caption: 'Dir Ind',
			dataField: 'dir_ind',
			visible: false,
			width: 100
		},
		{
			caption: 'Share Ind',
			dataField: 'share_ind',
			visible: false,
			width: 100
		},
		{
			caption: 'Curr Prir Ind',
			dataField: 'curr_prir_ind',
			visible: false,
			width: 100
		},
		{
			caption: 'Jurisdiction',
			dataField: 'jur',
			width: 100
		},
		{
			caption: 'Chg Qty Type',
			dataField: 'chg_qty1_type',
			visible: true,
			width: 120
		},
		{
			caption: 'Chg Qty Type 2',
			dataField: 'chg_qty2_type',
			visible: false,
			width: 120
		},
		{
			alignment: 'right',
			caption: 'Chg Qty Used',
			dataField: 'chg_qty1_used',
			dataType: 'number',
			visible: true,
			width: 120
		},
		{
			alignment: 'right',
			caption: 'Chg Qty Used 2',
			dataField: 'chg_qty2_used',
			dataType: 'number',
			visible: false,
			width: 120
		},
		{
			caption: 'Chg Qty Allowed',
			dataField: 'chg_qty1_allowed',
			visible: true,
			width: 140
		},
		{
			caption: 'Segment Net Element',
			dataField: 'super_usoc_map.susoc_1',
			visible: false,
			width: 150
		},
		{
			caption: 'Segment Tech',
			dataField: 'super_usoc_map.susoc_2',
			visible: false,
			width: 150
		},
		{
			caption: 'Segment Qty',
			dataField: 'super_usoc_map.susoc_3',
			visible: false,
			width: 150
		},
		{
			caption: 'Segment Jur',
			dataField: 'super_usoc_map.susoc_4',
			visible: false,
			width: 150
		},
		{
			caption: 'Segment Primary/Feat',
			dataField: 'super_usoc_map.susoc_5',
			visible: false,
			width: 150
		},
		{
			caption: 'Super Usoc',
			dataField: 'superusoc',
			visible: true,
			width: 150
		},
		{
			caption: 'Activity Comp Date',
			dataField: 'activity_comp_date',
			dataType: 'date',
			cellTemplate: 'dateTemplate',
			visible: false,
			width: 150
		},
		{
			caption: 'Install Date',
			dataField: 'svc_establish_date',
			dataType: 'date',
			cellTemplate: 'dateTemplate',
			visible: true,
			width: 150
		},
		{
			caption: 'End Charge Date',
			dataField: 'end_chg_date',
			dataType: 'date',
			cellTemplate: 'dateTemplate',
			visible: true,
			width: 150
		},
		{
			caption: 'Currency',
			dataField: 'currency',
			visible: true,
			width: 120
		},
		{
			caption: 'Rate Period',
			dataField: 'rate_period',
			visible: false,
			width: 150
		},
		{
			caption: 'Roam Ind',
			dataField: 'roam_ind',
			visible: false,
			width: 150
		},
		{
			caption: 'Fac Bw Unit Type',
			dataField: 'fac_bw_unit_type',
			visible: true,
			width: 150
		},
		{
			caption: 'Original Sp Serv ID',
			dataField: 'original_sp_serv_id',
			visible: false,
			width: 150
		},
		{
			caption: 'Address',
			dataField: 'site_a_addr_1',
			visible: true,
			width: 200
		},
		{
			caption: 'City',
			dataField: 'site_a_addr_city',
			visible: true,
			width: 200
		},
		{
			caption: 'State',
			dataField: 'site_a_addr_st',
			visible: true,
			width: 200
		},
		{
			caption: 'Zip',
			dataField: 'site_a_addr_zip',
			visible: true,
			width: 200
		},
		{
			caption: 'BTN',
			dataField: 'btn',
			visible: true,
			width: 150
		},
		{
			caption: 'Dispute Calculated Amount',
			dataField: 'charge_dispute.calculated_amount',
			cellTemplate: 'invoiceCurrencyTemplate',
			dataType: 'currency',
			alignment: 'right',
			visible: true,
			width: 150
		},
		{
			caption: 'Disputed Amount',
			dataField: 'charge_dispute.disputed_amount',
			cellTemplate: 'invoiceCurrencyTemplate',
			dataType: 'currency',
			alignment: 'right',
			visible: true,
			width: 150
		},
		{
			caption: 'GL Coded',
			visible: false,
			dataField: 'charge_coded',
			cellTemplate: 'chargeCodedTemplate',
			width: 100
		}
	];

	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.cost.invoice-charges-grid', userSettings);
	}
}
