import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import Query from "../../core/query/query";

@Component({
  selector: 'ca-pager',
  templateUrl: './pager.component.html',
  styles: [`
   :host {
    z-index: 999;
   }

   .center {
    padding-right: 18px;
   }
  `]
})
export class PagerComponent implements OnInit, OnChanges {
  @Input() stackedPager = false;
  @Input() query: Query = new Query();
  @Input() sizes;
  @Input() pageContext: any;
  @Output() onChange = new EventEmitter();
  @Input() gridPager: number;
  // this is obsolete now and should not be used anymore
  @Input() doNotEmitQueryOnInitialLoad: boolean;

  constructor() {
  }

  async ngOnInit() {
    await this.loadGridPager();
  }

  async loadGridPager() {
    if (this.pageContext && this.pageContext.userSettings) {
      const settings = await this.pageContext.userSettings.settings().toPromise();
      if (settings && settings[this.pageContext.name] && settings[this.pageContext.name].gridPager) {
        this.query.limit = this.gridPager = settings[this.pageContext.name].gridPager;
      } else if (this.gridPager) {
        this.query.limit = this.gridPager;
      }else {
        this.query.limit = 20;
        await this.saveGridPager()
      }
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.gridPager) {
      this.query.limit = this.gridPager;

    }
    if (changes['gridPager']) {
      await this.loadGridPager()
    }
  }

  prev() {
    this.query.page--;
    this.query.offset = this.query.from() - 1;
    this.onChange.emit(this.query);
  }

  next() {
    this.query.page++;
    this.query.offset = this.query.from() - 1;
    this.onChange.emit(this.query);
  }

  onPageSizeChange(size) {
    this.query.limit = this.gridPager = size;
    this.query.offset = 0;
    this.query.page = 1;

    this.onChange.emit(this.query);

    if (this.pageContext) {
      this.saveGridPager();
    }
  }

  async saveGridPager() {
    if (!this.pageContext) return;
    if(this.gridPager){
      this.pageContext.userSettings.save(this.pageContext.name, { gridPager: this.gridPager });
    }else{
      this.pageContext.userSettings.save(this.pageContext.name, { gridPager: 20 });
    }
  }
}
