<div class="ca-form ca-date-form">
  <mat-form-field dateFormat class="ca-date-picker">
  <mat-label>{{placeholder}}</mat-label>
  <input
    #date
    class="ca-date-input"
    [matDatepickerFilter]="dateFilter"
    matInput
    [matDatepicker]="datePicker"
    (change)="onChange($event)"
    [(ngModel)]="value"
    (blur)="onTouched($event)"
    (paste)="onPaste($event)"
    (focusout)="onFocusOut($event)"
    [min]="minDate"
    [max]="maxDate"
    [required]="isRequired"
    [readonly]="isReadonly"
    [disabled]="isDisabled"
    (dateChange)="ngOnDateChange($event)"
  >
  <mat-datepicker-toggle matSuffix [for]="datePicker" (focusout)="onPickerChange($event)"></mat-datepicker-toggle>
  <mat-hint *ngIf="showRequireError()" class="error-class">
    {{VALIDATION.REQUIRED_ERROR}}
  </mat-hint>
  <mat-hint *ngIf="showValidationError()" class="error-class">
    {{VALIDATION.DATE_INVALID}}
  </mat-hint>
  <mat-datepicker #datePicker ></mat-datepicker>
</mat-form-field>
</div>
