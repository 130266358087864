import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {LocationService} from "./location.service";
import {Observable} from "rxjs";
import {ConfigService} from "../../core/config/config.service";
import {Config} from "../../core/config/config";
import Query from "../../core/query/query";
import {map} from "rxjs";
export class StateLookupProvider extends IFilterLookupProvider {
  defaultCountryCode: string;
  constructor(public locationService: LocationService,
              public configService?: ConfigService) {
    super();
  }

  findAll(searchQuery?: Query): Observable<any> {
    let query = searchQuery? searchQuery : new Query();

    query.orderBy = [['name', 'ASC']];
    query.limit = 10000;

    if (this.configService && !this.defaultCountryCode) {
      this.configService.findAll().subscribe((config: Config) => {
        this.defaultCountryCode = config.location.country;
      });
      query.where['country_code'] = this.defaultCountryCode;

      return this.locationService.findAllStateSubdivisions(this.defaultCountryCode, query)
        .pipe(map((result: any) => {
          return result.items.map((entry) => {
            return {
              key: entry.id,
              value: entry.abbreviation,
              name: entry.name
            };
          });
        }));
    }

    else {
      query.where['country_code'] = this.defaultCountryCode;
    }
    return this.locationService.findAllStateSubdivisions(this.defaultCountryCode, query)
      .pipe(map((result: any) => {
          return result.items.map((entry) => {
            return {
              key: entry.id,
              value: entry.abbreviation,
              name: entry.name
            };
          });
      }));
  }
}
