import {
  Component, forwardRef, Input, OnInit
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable } from "rxjs";
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from "../../../shared/picker/ca-picker-base.component";
import { QuotingService } from "../../core/quoting.service";
import Query from '../../../core/query/query';

@Component({
  selector: 'ca-npa-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => CaNpaPickerComponent),
          multi: true
      },
      QuotingService
  ]
})
export class CaNpaPickerComponent extends PickerComponentBase implements OnInit {

  readonly TEXT_FIELD = 'npa';
  readonly KEY_FIELD = 'npa';

  @Input('formControlName') formControlName;
  @Input('initialItem') initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('clearEnabled') clearEnabled: boolean;


  public control;

  query: Query = new Query({
    orderBy: [['npa', 'ASC']]
  });

  constructor( public quotingService: QuotingService) { super(); }

  loadEntries(): Observable<any> {
    this.control = this.pickerForm.controls[this.formControlName];
    return this.quotingService.getAllNpa(this.query)
  }

  search(value: string) {
    this.query.where['npa'] = {'$ilike': `${value}%`}
    return this.quotingService.getAllNpa(this.query)
  }

}
