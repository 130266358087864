import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { InvoiceChargeService } from '../../../charge/core/invoice-charge.service';
import { DialogButtonsController } from '../../../core/dialog-buttons-controller.class';
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { ValidationService } from '../../../shared/validation/validation.service';
import { InventoryService } from '../../core/inventory.service';
import { ShortInvoiceChargeProfileOnInventoryGridService } from './../../../invoice/core/short-invoice-charges-profile-on-inventory-grid.service';
import { LoaderService } from 'app/shared/loader/loader.service';
import {
	IMessagesResourceService,
	ResourcesService
} from 'app/core/resources/resources.service';
import Query from 'app/core/query/query';
import { lastValueFrom, of } from 'rxjs';
import { cloneDeep } from 'lodash';

@Component({
	selector: 'app-invoice-charge-profile-manage-dialog',
	templateUrl: './invoice-charge-profile-manage-dialog.component.html',
	styleUrls: ['./invoice-charge-profile-manage-dialog.component.css']
})
export class InvoiceChargeProfileManageDialogComponent
	extends DialogButtonsController
	implements OnInit
{
	readonly MESSAGES_MODULE: string = 'charge';
	readonly VALIDATION = ValidationService.MESSAGES;

	public title = 'Invoice Charge Profile Manage Dialog';
	public messages: IMessagesResourceService;
	public columns: Array<any> = [];
	public charges: Array<any> = [];
	public initialCharges: Array<any> = [];
	public updatedChargeProfiles: Array<any> = [];
	public selectedAuditSettings = null;
	public isChargeProfileActive: boolean = false;

	@ViewChild('chargeProfileGrid') chargeProfileGrid: DxDataGridComponent;

	constructor(
		public alertService: AlertService,
		public invoiceChargeService: InvoiceChargeService,
		public inventoryService: InventoryService,
		public shortInvoiceChargeProfileGridService: ShortInvoiceChargeProfileOnInventoryGridService,
		public dialogService: DialogService,
		public loaderService: LoaderService,
		public dialogRef: MatDialogRef<InvoiceChargeProfileManageDialogComponent>,
		public dialog: DialogService
	) {
		super();
		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
	}

	ngOnInit(): void {
		this.columns = this.shortInvoiceChargeProfileGridService.columns();
		this.isChargeProfileActive = this.charges.some((c) => c.is_active);
		this.initialCharges = cloneDeep(this.charges);
	}

	ngAfterViewInit() {
		if (this.chargeProfileGrid) {
			this.shortInvoiceChargeProfileGridService.create(
				this.chargeProfileGrid.instance,
				{
					noDataText: this.shortInvoiceChargeProfileGridService.noDataMessage,
					columnMinWidth: 50
				}
			);
		}
	}

	async onSubmit() {
		const deactivating =
			this.initialCharges.some((c) => c.is_active) &&
			!this.isChargeProfileActive;

		const activating =
			this.initialCharges.every((c) => !c.is_active) &&
			this.isChargeProfileActive;

		const obs$ =
			activating || deactivating
				? this.dialogService
						.confirm({
							bodyText: `You are ${activating ? 'activating' : 'deactivating'} all charge profiles. Do you want to proceed`
						})
						.afterClosed()
				: of(true);

		const confirm = await lastValueFrom(obs$);

		if (!confirm) {
			return;
		}

		this.loaderService.displayLoader();

		const invoiceChargeProfileIds = this.charges.map((obj) => obj.id);
		const query = new Query();
		query.where = {
			id: { $in: invoiceChargeProfileIds }
		};
		query['is_active'] = this.isChargeProfileActive;

		this.inventoryService.updateInvoiceChargeProfileStatus(query).subscribe(
			(result) => {
				this.loaderService.hideLoaderAndManageGrid([this.chargeProfileGrid]);
				this.updatedChargeProfiles = result.items;
				this.close({ error: false });
			},
			(error) => {
				this.loaderService.displayLoaderAndManageGrid([this.chargeProfileGrid]);
				console.error(error);
				this.alertService.error(
					'',
					this.messages.get('FETCH_CHARGE_PROFILE_ERROR')
				);
				this.close({ error: true });
			}
		);
	}

	updateChargeProfileActiveValue(event: any) {
		this.isChargeProfileActive = event?.checked;
	}

	close(result?: { error: boolean }) {
		this.dialogRef.close(result);
	}
}
