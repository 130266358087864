import {Injectable} from '@angular/core';
import {Action, State, StateContext, StateToken, Store} from '@ngxs/store';
import {LoadInvoiceStatuses, PatchInvoiceStatuses, UpdateInvoicesQuery,} from './invoice.actions';

import {QUERYABLE_STATE_MODEL_DEFAULTS, QueryableState, QueryableStateModel} from '../../common/state/queryable.state';
import {QueryBuilder} from "../../common/query/query.builder";
import {InvoiceFacepageService} from "../core/invoice-facepage.service";

const INVOICES_STATE_TOKEN = new StateToken<InvoicesStateModel>('invoices');

export interface InvoicesStateModel extends QueryableStateModel<any> {

}

@State<InvoicesStateModel>({
  name: INVOICES_STATE_TOKEN,
  defaults: {...QUERYABLE_STATE_MODEL_DEFAULTS},
})
@Injectable()
export class InvoicesState extends QueryableState {
  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
    public invoiceService: InvoiceFacepageService
  ) {
    super(store, queryBuilder);
  }

  @Action(UpdateInvoicesQuery)
  updateInvoicesQuery(ctx: StateContext<InvoicesStateModel>, {options}): void {
    return this.updateQuery(ctx, {options});
  }

  @Action(LoadInvoiceStatuses)
  loadInvoiceStatuses(ctx: StateContext<any>): any {

    const state = ctx.getState();
    if ((state?.statuses || []).length)
      return Promise.resolve(state.statuses)

    return this.invoiceService.fetchInvoiceStatuses().toPromise()
      // .then((result) => {
      //   ctx.patchState({
      //     statuses: [...result]
      //   })
      // });
  }

  @Action(PatchInvoiceStatuses)
  patchInvoiceStatuses(ctx: StateContext<any>, action: PatchInvoiceStatuses): void {
    const { statuses } = action.payload;
    ctx.patchState({
      statuses
    });
  }
}
