<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
<h1 mat-dialog-title>{{formTitle}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">

  <form class="ca-form dialog-content-no-margin" [formGroup]="form" *ngIf="form">
    <div style="height: 300px; overflow: hidden; " class="app-content-list app-content-list-no-pager">
      <dx-data-grid #chargesGrid
                    [showColumnLines]="false"
                    [height]="'100%'"
                    [showRowLines]="true"
                    [showBorders]="true"
                    [dataSource]="withheldCharges"
                    [columns]="columns">

        <div *dxTemplate="let d of 'dateTimeTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caDate}}
          </div>
        </div>

        <div *dxTemplate="let d of 'numberTemplate'">
          <div caGridTooltip class="right">
            {{ d.value | caNumber}}
          </div>
        </div>

        <div *dxTemplate="let d of 'disputeChargeIdCellTemplate'">
          <div caGridTooltip>{{ d.row.data.charge?.invoice?.sp_inv_num }}_{{ d.row.data.charge?.id }}</div>
        </div>

        <div *dxTemplate="let d of 'disputeInvoiceCellTemplate'">
          <div caGridTooltip>{{ d.row.data.charge?.invoice?.sp_inv_num }}</div>
        </div>

        <div *dxTemplate="let d of 'disputeChargesStatusCellTemplate'">
          <div caGridTooltip>{{ d.data.status_item?.value }}</div>
        </div>

        <i *dxTemplate="let d of 'disputeChargesHeaderTemplate'">
          {{ d.column.caption }}
        </i>

        <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
          <div caGridTooltip class="right">{{ d.value | caCurrency: d.data.charge.currency }}</div>
        </div>

        <div *dxTemplate="let d of 'statusTemplate'">
          <div caGridTooltip>{{ d.value ? d.value.value : 'Filed' }}</div>
        </div>

      </dx-data-grid>
    </div>
    <div class="flex-container ca-margin-top-15" fxLayout="row">
      <div class="flex-item" fxFlex>
        <mat-form-field caInput class="caGap">
          <mat-label>Reason</mat-label>
          <textarea matInput formControlName="reason" matInput rows="2" [required]="'true'"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>


<mat-dialog-actions class="ca-align-baseline ca-dialog-action" #dialogButtons>
    <div class="example-fill-remaining-space">
        <ca-confirm-delete-button
            #genericButton [requestInput]="true" (deleteRequested)="onSubmit(form)" [deleteText]="confirmButtonLabel"
            [snackBarDeleteInputMessage]="'Please type in '+ randomString + ' to ' +  confirmButtonLabel"
            [placeHolder]="confirmPlaceholder" [entityName]="randomString" [disabled]="form?.invalid">
        </ca-confirm-delete-button>
    </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>
</mat-dialog-actions>
