import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@ngxs/store';
import {map, tap} from "rxjs";
import {LoadSingleInventory} from "../inventory-details/state/inventory-details.actions";
import {InventoryDetailsState} from "../inventory-details/state/inventory-details.state";

@Injectable({
  providedIn: 'root'
})
export class InventoryDetailsResolver  {

  constructor(private store: Store) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const id = route.params.id;
    return this.store.dispatch(new LoadSingleInventory(id))
      .pipe(
        map(() => this.store.selectSnapshot(InventoryDetailsState).inventory)
      )
      .toPromise();
  }
}
