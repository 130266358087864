import {AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {takeUntil} from "rxjs";

import {Observable, of, Subject} from "rxjs";
import {Inventory} from "../../core/inventory";
import {InventoryService} from "../../core/inventory.service";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {SortingService} from "../../../shared/sorting/sorting.service";
import {InventoryPricingGridService} from "../../core/inventory-pricing-grid.service";
import {Pricing} from "../../core/pricing";
import { InventoryPricingDialogComponent } from 'app/inventory/shared/inventory-pricing-dialog/inventory-pricing-dialog.component';
import {AlertService} from "../../../shared/alert/alert.service";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {map} from "rxjs";
import {throwError as observableThrowError} from "rxjs";
import {HistoryComponent} from "../../../shared/history/history.component";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {Select, Store} from "@ngxs/store";
import {UpdateInventoryPricingActiveSiderSection} from "./state/inventory-pricing.actions";
import {InventoryPricingCsvInterceptor} from '../../core/inventory-pricing-csv-interceptor';
import Query from "../../../core/query/query";

@Component({
  selector: 'app-inventory-pricing',
  templateUrl: './inventory-pricing.component.html',
})
export class InventoryPricingComponent extends PageDetailsComponent  implements OnInit {
  inventory: Inventory;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public pricingQuery: Query = new Query();

  public pricingColumns: any;
  public pricing: Array<Pricing>;
  public pricingSelection: Pricing;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'inventory';

  public DOCUMENT = {
    entity: null,
    folder: 'inventories',
    subfolder: ''
  };

  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  sider: Sider;
  notesCount: number;
  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
    HISTORY_SECTION_NAME: 'history'
  };

  @ViewChild('history') history: HistoryComponent;
  @ViewChild('panelSide') panelSide;
  @ViewChild('pricingsGrid') pricingsGrid;
  @Select(state => state.inventory_pricing.activeSiderSection) $activeSiderSection: Observable<any>;

  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public pricingGridService: InventoryPricingGridService,
    public settingsService: UserSettingsService,
    public sortingService: SortingService,
    public router: Router,
    public alertService: AlertService,
    public dialogService: DialogService,
    private readonly store: Store,
  ) {
    super(new PageContext({
      name: 'app.inventory.inventory-pricing',
      settings: settingsService
    }))

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }

  loadPermissions() {
    return of({});
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/inventory']);
    }
  }

  private getInventory() {
    return this.route.parent.snapshot.data.inventory;
  }

  ngOnInit(): void {

    this.inventory = this.getInventory();

    this.pricingQuery.where = {
      inventory_id: this.inventory.id
    }
    this.loadPricings()

    // this.loadPricing(this.inventory.id);
    this.pricingColumns = this.pricingGridService.columns();
  }

  public loadPricings() {
    this.inventoryService.getPricings(this.pricingQuery)
      .subscribe(result => {
          this.pricingQuery.total = result.total
          this.pricing = result.items;
        },
        (error) => {
          console.log('error getting pricings', error);
        }
      );
  }

  public loadPricing(inventoryId: number) {
    this.inventoryService.getPricing(inventoryId)
      .subscribe(
        (result) => {
          this.pricing = result;
        },
        (error) => {
          console.log('error getting pricings', error);
        }
      );
  }

  onPricingSelectionChange(row) {
    this.pricingSelection = <Pricing>row.selectedRowsData[0];
  }

  addPricing() {
    this.dialogService.open(InventoryPricingDialogComponent, {
      inventory: this.inventory,
      folder: this.DOCUMENT.folder,
      subfolder: this.inventory.id
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.loadPricings();
            this.history.refreshList();
            this.alertService.success('', this.messages.get('ADD_PRICING_SUCCESS'));
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  invokeDeletePricing() {
    this.deletePricing(this.inventory.id, this.pricingSelection['inventory_document']);
  }

  invokeDownloadPricing() {
    this.downloadPricing(this.inventory.id, this.pricingSelection.inventory_document_id, this.pricingSelection.type, this.pricingSelection.inventory_document.path);
  }

  deletePricing(inventoryId: number, pricing: Pricing) {
    this.dialogService.confirm({
      bodyText: `Delete pricing document ${pricing.path}`
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.inventoryService.deletePricing(inventoryId, pricing.id)
              .subscribe(
                (deleteResult) => {
                  if (deleteResult) {
                    this.alertService.success('', this.messages.get('DELETE_PRICING_SUCCESS'));
                    this.loadPricings();
                    this.history.refreshList();
                  }
                },
                () => {
                  this.alertService.error('', this.messages.get('DELETE_PRICING_FAIL'));
                }
              );
          }
        },
        (error) => {
          console.log('error on confirm delete pricing modal', error);
        }
      );
  }

  downloadPricing(inventoryId: number, pricingId: number, pricingType: string, pricingName: string) {
      this.inventoryService.downloadPricing(inventoryId, pricingId)
        .pipe(map(response => this.extractBlob(response, pricingType)))
        .subscribe((response: Blob) => {
          const name = pricingName;
          this.inventoryService.initiateDownload(name, response);
        }, error => {
          this.alertService.error('', this.messages.get('GET_PRICING_FAIL'));
          observableThrowError(error)
        });
  }

  public extractBlob(data, type) {
    let blob = new Blob([data], {type: type});
    return blob || {};
  }


  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
    this.pricingGridService.create(this.pricingsGrid.instance, {
      noDataText: 'No Data'
    });
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    // TODO: delete this / used $activeSiderSection to reopen sider when back button is used
    // sider.onChange((section: SiderSection) => {
    //   // TODO: Save to state
    //   // this.saveSettings({siderSection: section.name});
    // });

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInventoryPricingActiveSiderSection(activeSection.name)
    ]);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }

  onDownloadCSVRequested() {
    const pricingTransformMiddleware = (items) => {
      const pricingCsvInterceptor = new InventoryPricingCsvInterceptor()
      return items.map((item) => pricingCsvInterceptor.transform(item));
    };

    this.pricingGridService.csvMap().subscribe(fields => {
      this.inventoryService.exportToCSV('inventory-pricing', {
          fields,
          query: this.pricingQuery,
          fetchDataHandler: (query) => {
            return this.inventoryService.getPricings(query)
          },
          middleware: [pricingTransformMiddleware]
      })
    })
  }
}
