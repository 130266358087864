import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { SitePickerComponent } from './shared/site-picker/site-picker.component';
import { SiteService } from './core/site.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SiteRoutingModule } from './site-routing.module';
import { SiteComponent } from './site.component';
import { SiteManageDialogComponent } from './shared/site-manage-dialog/site-manage-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {DxSelectBoxModule} from 'devextreme-angular/ui/select-box';
import {DxDataGridModule} from 'devextreme-angular/ui/data-grid';
import {DxButtonModule} from 'devextreme-angular/ui/button';

import {SiteGridService} from "./core/site-grid.service";
import {SiteFilterService} from "./core/site-filter.service";
import { SiteListComponent } from './site-list/site-list.component';
import { SiteSneakPeekComponent } from './shared/site-sneak-peek/site-sneak-peek.component';
import {VendorModule} from "../vendor/vendor.module";
import {CustomerModule} from "../customer/customer.module";
import {BuildingModule} from "../building/building.module";
import {DictionaryModule} from "../dictionary/dictionary.module";
import {SharedModule} from "../shared/shared.module";
import { CoreModule } from "../core/core.module";

@NgModule({
    imports: [
        SiteRoutingModule,
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        DxSelectBoxModule,
        DxButtonModule,
        DxDataGridModule,
        BuildingModule,
        VendorModule,
        CustomerModule,
        DictionaryModule,
        CoreModule
    ],
    exports: [SitePickerComponent],
    declarations: [
        SitePickerComponent,
        SiteComponent,
        SiteManageDialogComponent,
        SiteListComponent,
        SiteSneakPeekComponent
    ],
    providers: [
        SiteService,
        SiteGridService,
        SiteFilterService
    ]
})
export class SiteModule { }
