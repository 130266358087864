import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output
} from '@angular/core';
import { Store } from '@ngxs/store';
import { InvoiceFacepage } from '../../core/invoice-facepage';
import { CommonAlertQuery } from '../../../common-alert/core/common-alert.query';
import { CommonAlertService } from '../../../common-alert/core/common-alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs';
import { InvoiceFacepageService } from '../../core/invoice-facepage.service';
import { Subject } from 'rxjs';
import { LOOKUP_ENUM } from 'app/dictionary/core/lookup.enum';

@Component({
	selector: 'app-invoice-details-tabs',
	templateUrl: './invoice-details-tabs.component.html',
	styleUrls: ['./invoice-details-tabs.component.css']
})
export class InvoiceDetailsTabsComponent implements OnInit, OnDestroy {
	@Output() tabChangeRequested: EventEmitter<any> = new EventEmitter<any>();

	tabs = [
		{ label: 'Overview', key: 'overview' },
		{ label: 'Charges', key: 'charges' },
		{ label: 'Disputes', key: 'disputes' },
		{ label: 'Sub Accounts', key: 'subaccounts' },
		{ label: 'Alerts', key: 'alerts', showBadge: true },
		{ label: 'Notes & contacts', key: 'notes-and-contacts' },
		{ label: 'GL Charge Details', key: 'gl-charge-details' },
		{ label: 'GL Strings', key: 'gl-strings' },
		{ label: 'Documents', key: 'documents' }
	];

	auditQuery: CommonAlertQuery = new CommonAlertQuery();
	alertCount: number = 0;

	@Input() activeTabIndex: number = 0;
	@Input() badgeCount: number = 0;
	@Input() invoice: InvoiceFacepage;

	destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private readonly store: Store,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		public commonAlertService: CommonAlertService,
		public invoiceFacepageService: InvoiceFacepageService
	) {}

	ngOnInit(): void {
		if (this.invoice) {
			this.loadAudits(this.invoice);

			// refresh alerts count in case of batch error
			this.invoiceFacepageService.invoiceChange
				.pipe(takeUntil(this.destroy$))
				.subscribe(async (invoice) => {
					this.loadAudits(this.invoice);
				});
		}
	}

	onTabChange(index: number) {
		const key = this.activatedRoute.snapshot.queryParams['key'];
		const activeTab = this.tabs[index].key;
		this.router.navigate(
			['invoice', this.invoice.invoice_id, 'show', activeTab],
			{
				replaceUrl: !key
			}
		);
	}

	async loadAudits({ invoice_id: invoiceId }: InvoiceFacepage) {
		this.auditQuery['where']['invoice_id'] = invoiceId;
		this.auditQuery['where']['status_id'] = [
			LOOKUP_ENUM.ALERT_STATUS.IN_PROGRESS,
			LOOKUP_ENUM.ALERT_STATUS.NEW
		];
		const loadedAlerts = await this.commonAlertService
			.getAlertsForInvoice(this.auditQuery)
			.toPromise();
		this.alertCount = +loadedAlerts.total;
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
