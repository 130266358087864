import { CanDeactivateFn } from "@angular/router";
import { Observable } from "rxjs";

export const unsavedChangesGuard: CanDeactivateFn<any> = (
  component
): Observable<boolean> | boolean => {
  if (component.hasUnsavedChanges) {
    return window.confirm(
      "You have unsaved changes. Do you really want to leave?"
    );
  }
  return true;
};
