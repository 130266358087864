import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Building} from "./building";
import Query from "../../core/query/query";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class BuildingService extends BaseService<Building> {

  constructor(private entityLock: EntityLockService) {
    super('building', entityLock);
  }

  advancedSearch(query?: Query): any {

    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'advancedSearch'], this.toFilter(transformedQuery));
  }

}
