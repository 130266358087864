import {EventEmitter, Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {InvoiceCharge} from './invoice-charge';
import Query from '../../core/query/query';
import {Observable} from "rxjs";

@Injectable()
export class InvoiceChargeService extends BaseService<InvoiceCharge> {

  public onFilterChange: EventEmitter<any> = new EventEmitter();
  constructor() {
    super('charge');
  }

  filters(query: Query): any {
    let transformedQuery = query.transform();
    return this.httpService().post([this.name, 'find'], this.toFilter(transformedQuery));
  }

  removeSpecialParams(query) {
    const ignored = ['$gpx'];
    if (!query.where) query.where = {};
    let where = {};
    Object.keys(query.where)
      .forEach((key) => {
        const param = query.where[key];
        if (typeof param !== 'object' || param === null) {
          where[key] = param;
        } else {
          const reduced = {};
          Object.keys(param).forEach((k) => {
            if (ignored.indexOf(k) === -1) {
              reduced[k] = param[k]
            }
          })
          where[key] = reduced;
        }
      });

    return Object.assign({}, query, {where});
  }

  largeRequestFindAll(query: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    transformedQuery = this.removeSpecialParams(transformedQuery);
    if (transformedQuery.where.$or) {
      transformedQuery.where.$or = Object.values(transformedQuery.where.$or);
    }
    return this.httpService().post([this.name, 'find'], transformedQuery);
  }

  manyCharges(query: Query): any {
    let transformedQuery = query.transform();
    return this.httpService().get([this.name, 'many'], this.toFilter(transformedQuery));
  }

  findDistinctFacilityBandwidths(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'facbw', 'distinct'], this.toFilter(transformedQuery));
  }

  findChargeNotes(query?: Query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().post([this.name, 'notes'], transformedQuery);
  }

  findChargeNoteLargeRequest(query?: Query) {
    return this.httpService().post([this.name, 'find-notes'], this.toFilter(query));
  }

  findChargeInfoNotes(query?: Query) {
    return this.httpService().get([this.name, 'notes_info'], this.toFilter(query));
  }

  changeAdjustment(charges: InvoiceCharge[], adjustment_id: number) {
    const charge_ids = charges.map(charge => charge.id);
    return this.httpService().post([this.name, 'change-adjustment'], {charge_ids, adjustment_id});
  }

  findAllForRateAudit(query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'findAllForRateAudit'], this.toFilter(transformedQuery));
  }

  // This function is similar like findAll in base.service.ts.
  // We had problem query limitations like on task GPX-6669. But we resolved here on different way
  // Here we are parsing query params to string with function toFilter
  findCharges(query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name], this.toFilter(transformedQuery));
  }

  findAll(query?) {
    /**FindAll method is overridden here from Get to Post related to issue GPX-7672
     because Query String length was to long for Get method.
     This was noticed on production for Download of Small/Large CSV after
     enabling all columns on the grid.*/
    let transformedQuery = query.transform();
    return this.httpService().post(this.name, transformedQuery);
  }

  hasDispute(query?): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().post([this.name, 'invoicedispute'], this.toFilter(transformedQuery));
  }

  findAllCsv(query?: Query) {
    let transformedQuery = query.transform();
    return this.httpService().post([this.name, 'inventory-billing'], transformedQuery);
  }

  createCharge(payload: Object): Observable<any> {
    return this.httpService().post([this.name, 'create'], payload);

  }

  deleteCharges(payload: Object): Observable<any> {
    //TODO: This will not work
    return this.httpService().delete([this.name], payload);
  }

}
