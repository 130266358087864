import {Injectable, Type} from '@angular/core';
import {EntityLock} from './entity-lock';
import {EntityLockData} from './entity-lock-data';
import {EntityLockAlertComponent} from './entity-lock-alert.component';
import {Router} from "@angular/router";
import {ApiService} from "../../core/api";

@Injectable()
export class EntityLockService{

  locks: Array<EntityLock> = [];

  constructor(private apiService: ApiService, private router?: Router) {
  }

  cancelAll() {
    this.locks.forEach((lock) => {
      lock.killTimer();
      this.remove(lock)
    });
    this.locks = [];
  }

  completeAll() {
    this.locks.forEach((lock) => {
      lock.killTimer();
      this.remove(lock)
    });
    this.locks = [];
  }

  createLock(
    lockData: EntityLockData,
    dialogRef?: any,
    dialogService?: any,
    component: Type<any> = EntityLockAlertComponent) {
    const lock = new EntityLock(lockData, dialogRef, dialogService, component, this.router);
    this.locks.push(lock);
    return lock;
  }

  remove(lock) {
    return this.apiService.delete(['data-lock', lock.lockData['$lock'].id]);
  }
}
