export const invoiceChargeProfileFilterContext = {
    filters: [
      {
        label: "Charge Amount",
        field: "charge_amount",
        type: 'negativeNumber',
        index: 2,
        visible: true
      },
      {
        label: "Charge Description",
        field: "charge_description",
        type: 'string',
        index: 2,
        visible: true
      },
      {
        label: "Expected Min Value",
        field: "audit_rule_expected_min_value",
        type: 'negativeNumber',
        index: 3,
        visible: true
      },
      {
        label: "Expected Max Value",
        field: "audit_rule_expected_max_value",
        type: 'negativeNumber',
        index: 4,
        visible: true
      },
      {
        label: 'Charge Type',
        field: 'charge_type',
        type: 'lookup',
        editor: 'checkboxList',
        lookupProviderName: 'chargeType',
        index: 5,
        visible: true
      },
      {
        label: "Create Date",
        field: "created_date",
        type: 'date',
        editor: 'dateRanges',
        lookupProviderName: 'defaultDateRanges',
        index: 5,
        visible: true
      },
      {
        label: "Last Updated Date",
        field: "last_updated_date",
        type: 'date',
        editor: 'dateRanges',
        lookupProviderName: 'defaultDateRanges',
        index: 6,
        visible: true
      },
      {
        label: "Active Profile Charges",
        field: "is_active",
        type: 'boolean',
        trueLabel: 'Yes',
        falseLabel: 'No',
        index: 10,
        visible: true
      }
    ]
  };
  