import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import { LoaderService } from '../../shared/loader/loader.service';
import { TabGroup, TabService } from '../../shared/tabs/tab.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { UserService } from '../../user/core/user.service';

@Component({
	selector: 'app-alert-summary-only',
	templateUrl: './alert-summary-only.component.html',
	styleUrls: ['./alert-summary-only.component.css']
})
export class AlertSummaryOnlyComponent
	extends PageListComponent
	implements OnInit, OnDestroy
{
	public alert: any;
	public summary: any;
	public tabGroup: TabGroup;
	public currentTabIndex = 0;

	readonly PLACEHOLDERS = {
		OWNER_UNASSIGNED: 'Unassigned'
	};

	constructor(
		public settingsService: UserSettingsService,
		public route: ActivatedRoute,
		public router: Router,
		public loaderService: LoaderService,
		public tabService: TabService,
		public userService: UserService
	) {
		super(
			new PageContext({
				name: 'app.alert.alert-disconnect'
			})
		);
	}

	public loadTabs() {
		this.tabGroup = this.tabService.create();
		this.tabGroup.addTab({ key: 1, title: 'Summary' });
		this.tabGroup.activate(1);
	}

	ngOnInit() {
		this.loadTabs();
		this.alert = this.route.snapshot.data.alert;
		if (this.tabGroup.tabs[1]) {
			this.tabGroup.tabs[1].disabled = this.alert.message === 'No issues found';
		}
	}

	back(): void {
		this.router.navigate(['/audit/audit-results']);
	}

	ngOnDestroy() {
		this.tabGroup.onActivate.unsubscribe();
	}

	public goToInvoiceDetails() {
		if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
			this.router.navigate(['/invoice', this.alert.invoice.invoice_id, 'show']);
		}
	}
}
