import {
  Component, forwardRef, Input, OnInit
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  PICKER_COMPONENT_TEMPLATE,
  PickerComponentBase
} from '../../../shared/picker/ca-picker-base.component';
import { Observable } from 'rxjs';

import { LocationService } from "../../core/location.service";
import { LocationQuery } from "../../core/location.query";


@Component({
  selector: 'ca-country-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaCountryPickerComponent),
      multi: true
    }
  ]
})
export class CaCountryPickerComponent extends PickerComponentBase implements OnInit {

  readonly TEXT_FIELD = 'alpha_2_code';
  readonly SECOND_TEXT_FIELD = 'name';
  @Input('formControlName') formControlName;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('placeholder') placeholder = 'Select ...';
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('clearEnabled') clearEnabled: boolean;

  @Input('initialItem') initialItem;
  @Input('pickerForm') pickerForm;
  @Input('initialItemId') initialItemId;

  @Input('showAllEntries') showAllEntries;
  @Input('showEntriesByAttributeName') showEntriesByAttributeName: string;

  public control;

  query = new LocationQuery();

  constructor( public locationService: LocationService) { super(); }

  loadEntries(): Observable<any> {
    this.control = this.pickerForm.controls[this.formControlName];
    this.query.orderBy = [['alpha_2_code', 'ASC']];
    this.query.limit = 10000;
    if (this.showAllEntries) {
      return this.locationService.findAll(this.query);
    }
    if(this.showEntriesByAttributeName){
      this.query.where={[this.showEntriesByAttributeName]: true}
      return this.locationService.findLookupCountries(this.query);
    }
    return this.locationService.findLookupCountries(this.query);
  }

  loadInitialItem(): Observable<any> {
    return this.locationService.findCountryById(this.initialItemId);
  }

  search(value: string) {
    this.query.where['$or'] = [
      {alpha_2_code: { '$ilike': `%${value}%` }},
      {name: { '$ilike': `%${value}%` }}
    ]
    let countries = this.locationService.findLookupCountries(this.query);
    return countries;
  }

}
