<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Add Vendor Entity</h1>
</div>
<div mat-dialog-content class="ca-dialog-content"> 
  <form class="ca-form" [formGroup]="vendorEntityForm">
    <div class="flex-container" fxLayout="row">
      <div class="flex-item" fxFlex>
        <mat-form-field caInput>
          <mat-label>Name</mat-label>
          <input matInput required formControlName="name">
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button #cancelButton mat-button type="submit" (click)="close()">
      Cancel
  </button>
  <button #saveButton mat-button type="submit" (click)="onSubmit(vendorEntityForm)"
          [disabled]="vendorEntityForm.invalid">Save
  </button>
</mat-dialog-actions>
