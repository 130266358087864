import {zip,} from 'rxjs';
import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {History} from './history';
import * as moment from 'moment';
import {ApiService} from "../../core/api";
import {map} from "rxjs";

@Injectable()
export class HistoryService extends BaseService<History> {

  constructor(private apiService: ApiService) {
    super('history');
  }

  findHistoryForEntity(entityType: string, entityId: number) {
    let history: Array<History> = [];
    const query = {
      where:
        {
          entity_type: entityType, entity_id: entityId
        },
      limit: 10000,
      orderBy: [['created_at', 'DESC']]
    };

    return this.apiService.get('history', query)
  }

  findHistoryForEntityParent(entityType: string, entityId: number) {
    let history: Array<History> = [];
    const query = {
      where:
        {
          parent_entity_type: entityType, parent_id: entityId
        },
      limit: 10000,
      orderBy: [['created_at', 'DESC']]
    };

    return this.apiService.get('history', query)
  }

  findAllForEntity(entityType: string, entityId: number) {
    // This needs to be updated
    if (entityType === 'customerinventory') {
      return zip(
        this.findHistoryForEntity(entityType, entityId),
      )
        .pipe(
          map((result) => {
            const history = result[0] as any[];
            history.sort(historyDateCompare);
            return history;
          })
        );
    }

    return zip(
      this.findHistoryForEntity(entityType, entityId),
      this.findHistoryForEntityParent(entityType, entityId),
    )
      .pipe(
        map((result) => {
          const entityHistory = result[0] as any[];
          const parentHistory = result[1] as any[];
          const history = (entityHistory).concat(parentHistory);
          history.sort(historyDateCompare);
          return history;
        })
      );
  }
}

function historyDateCompare(a, b) {
  const prev = moment(a.created_at), next = moment(b.created_at);
  if (prev.isAfter(next)) {
    return -1;
  } else if (next.isAfter(prev)) {
    return 1;
  } else {
    return 0;
  }
}
