<div class="fadeMe" *ngIf="!onlineFlag">
	<ca-icon [key]="'OFFLINE'" [innerClass]="'offline-icon'"></ca-icon>
	No Internet
	<span class="notification-message"
		>Your network is unavailable. Check your data or Wi-Fi connection.</span
	>
</div>

<link
	rel="stylesheet"
	[href]="themeUrl"
	*ngIf="themeUrl && themeUrl !== EMPTY_THEME_NAME"
/>
<mat-toolbar
	color="primary"
	class="topbar"
	[ngClass]="{ alert: onlineFlag }"
	style="padding-left: 0"
>
	<div class="nav-toggle-wrapper">
		<button class="nav-toggle" mat-icon-button (click)="toggleNavigation()">
			<ca-icon [key]="'NAV_TOGGLE'"></ca-icon>
		</button>
	</div>
	<ca-brand key="logo.png" innerClass="logo"></ca-brand>

	<ca-loader></ca-loader>

	<span class="example-fill-remaining-space"></span>
	<ca-search></ca-search>

	<app-notification></app-notification>
	<ca-avatar
		class="avatar-contrast title-avatar avatar-link"
		[matMenuTriggerFor]="menu"
		[text]="getUserInitials()"
		[size]="'s'"
		[source]="me?.avatar"
		(click)="dashEnable()"
	></ca-avatar>

	<mat-menu class="ca-user-menu" #menu="matMenu">
		<button mat-menu-item (click)="profile()">
			<ca-icon [key]="'MENU_USER'"></ca-icon>
			<span> Profile </span>
		</button>
		<button mat-menu-item (click)="customize()">
			<ca-icon [key]="'MENU_THEME'"></ca-icon>
			<span> Theme </span>
		</button>
		<button mat-menu-item (click)="support()">
			<ca-icon [key]="'MENU_SUPPORT'"></ca-icon>
			<span> Knowledgebase </span>
		</button>
		<button mat-menu-item (click)="info()">
			<ca-icon [key]="'MENU_INFO'"></ca-icon>
			<span> Info </span>
		</button>
		<button mat-menu-item (click)="tickets()">
			<ca-icon [key]="'MENU_TICKETS'"></ca-icon>
			<span> Tickets </span>
		</button>

		<button
			[disabled]="!dashEnabled || isReportPermissionAvailable"
			mat-menu-item
			(click)="dashEdit()"
			*ngIf="!isDashEdit"
		>
			<ca-icon [key]="'MENU_DASHBOARD'"></ca-icon>
			<span> Unlock Dash </span>
		</button>
		<button
			[disabled]="!dashEnabled"
			mat-menu-item
			(click)="dashEdit()"
			*ngIf="isDashEdit"
		>
			<ca-icon [key]="'MENU_DASHBOARD'"></ca-icon>
			<span> Lock Dash </span>
		</button>
		<mat-divider></mat-divider>
		<button mat-menu-item (click)="signOut()">
			<ca-icon [key]="'MENU_SIGN_OUT'"></ca-icon>
			<span> Sign Out </span>
		</button>
	</mat-menu>
</mat-toolbar>
<mat-sidenav-container
	#container
	class="navigation-container-main"
	[class.mini]="navigationModeMini"
>
	<mat-sidenav
		#navigation
		disableClose="true"
		mode="side"
		[opened]="navigationOpened"
		class="site-navigation mat-primary"
		[class.mini-navigation-hover]="miniNavigationHovered"
		color="primary"
		on-mouseover="navigationMouseOver()"
		on-mouseleave="navigationMouseLeave()"
	>
		<div class="content" style="height: 100%">
			<app-navigation></app-navigation>
		</div>
	</mat-sidenav>
	<router-outlet></router-outlet>
</mat-sidenav-container>
