import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageListComponent } from 'app/core/page-list.component';
import { PageContext } from 'app/core/page.context';
import { CommonAlertService } from '../core/common-alert.service';
import { UserSettingsService } from 'app/user/core/user-settings.service';
import { TabGroup, TabService } from 'app/shared/tabs/tab.service';
import { Subject, switchMap, takeUntil, timeout } from 'rxjs';
import { GridSettingsComponent } from 'app/core/grid/grid-settings.component';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { AlertDetailsGridService } from '../core/alert-details-grid.service';
import { AlertDetailsFilterService } from '../core/alert-details-filter.service';
import Query from 'app/core/query/query';
import { GridService } from 'app/shared/grid/grid.service';
import { cloneDeep } from 'lodash';
import { CreateInvoiceChargeProfileDialogComponent } from 'app/invoice/shared/create-invoice-charge-profile-dialog/create-invoice-charge-profile-dialog.component';
import { AlertService } from 'app/shared/alert/alert.service';
import {
	IMessagesResourceService,
	ResourcesService
} from 'app/core/resources/resources.service';
import { ChargeQuery } from 'app/charge/core/charge.query';
import { InvoiceChargeService } from 'app/charge/core/invoice-charge.service';
import { DisputeManageDialogComponent } from 'app/dispute/shared/dispute-manage-dialog/dispute-manage-dialog.component';

@Component({
	selector: 'app-alert-details',
	templateUrl: './alert-details.component.html',
	styleUrls: ['./alert-details.component.scss']
})
export class AlertDetailsComponent extends PageListComponent implements OnInit {
	public alert: any;
	public tabChanged: boolean;
	public tabGroup: TabGroup;
	public selection: any;
	public isGridSettingsDisabled = true;
	public activeTabIndex = 0;
	public audits: Array<any> = [];
	public query: Query = new Query({ orderBy: [['charge_id', 'ASC']] });
	public selectedRows: Array<any> = [];
	public messages: IMessagesResourceService;
	readonly MESSAGES_MODULE: string = 'invoice';

	readonly sideSections = {
		none: null,
		info: 'info',
		filter: 'filter'
	};

	readonly PLACEHOLDERS = {
		OWNER_UNASSIGNED: 'Unassigned'
	};

	get pageTitle() {
		return `${this.alert?.type?.value || ''} Alert: ${this.alert?.rule_name || 'N/A'}`;
	}

	get alertCategory() {
		return this.alert?.category?.value || 'N/A';
	}

	get alertCreatedBy() {
		return this.alert?.creator?.full_name || 'System';
	}

	get alertOwner() {
		return this.alert?.owner?.full_name || this.PLACEHOLDERS.OWNER_UNASSIGNED;
	}

	get alertPriority() {
		return this.alert?.priority.value || 'N/A';
	}

	get alertStatus() {
		return this.alert?.status.value || 'N/A';
	}

	get isAddDisputeDisabled() {
		return (
			this.selectedRows.length === 0 ||
			this.selectedRows.some((r) => r.cost_dispute_charge_id !== null)
		);
	}

	get isAddInvoiceChargeProfileDisabled() {
		return this.selectedRows.length === 0;
	}

	constructor(
		public route: ActivatedRoute,
		public router: Router,
		public dialog: DialogService,
		public alertComponentService: AlertService,
		public alertService: CommonAlertService,
		public settingsService: UserSettingsService,
		public tabService: TabService,
		public alertDetailsGridService: AlertDetailsGridService,
		public detailsFilterService: AlertDetailsFilterService,
		public chargeService: InvoiceChargeService
	) {
		super(
			new PageContext({
				name: 'app.alert.details',
				settings: settingsService
			})
		);
	}

	ngOnInit() {
		this.loadTabs();
		this.alert = this.route.snapshot.data.alert;
		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
		this.tabGroup.tabs[1].disabled = this.alert.message === 'No issues found';
		this._init();
	}

	ngAfterViewInit(): void {
		// this.selectFirstRow();
		super.ngAfterViewInit();
		this.alertDetailsGridService.create(this.dataGrid.instance, {
			noDataText: 'No data'
		});
	}

	onTabChange(index) {
		let tab = this.tabGroup.tabs[index];
		if (!tab.disabled) {
			this.activeTabIndex = index;
			this.tabGroup.activate(tab.key);
		}

		if (tab.key === 2) {
			this.sider.open(this.SECTIONS.FILTER_SECTION_NAME);
		} else {
			this.sider.closeComponent();
		}

		/* Settings grid disabled */
		if (
			this.tabGroup.isActive(2) ||
			this.tabGroup.isActive(3) ||
			this.tabGroup.isActive(4)
		) {
			this.isGridSettingsDisabled = false;
		} else {
			this.isGridSettingsDisabled = true;
		}
	}

	loadData(alertId: number) {
		this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
			this.alert = data['alert'];
			this.loadDetails();
		});
	}

	public loadTabs() {
		this.tabGroup = this.tabService.create();
		this.tabGroup.addTab({ key: 1, title: 'Summary' });
		this.tabGroup.addTab({ key: 2, title: 'Details' });
		this.tabGroup.activate(1);

		this.tabGroup.onActivate.pipe(takeUntil(this.destroy$)).subscribe((tab) => {
			this.tabChanged = !this.tabChanged;
		});
	}

	gridSettings() {
		this.dialog
			.open(GridSettingsComponent, {
				service: this.alertDetailsGridService
			})
			.afterClosed()
			.subscribe((settings) => {
				if (settings) {
					this._init();
				}
			});
	}

	loadDetails(query: Query = new Query()) {
		const currentQuery = cloneDeep(query);

		currentQuery.where.invoice_id = this.alert.invoice_id;
		currentQuery.where.alert_id = this.alert.id;
		currentQuery.where.audit_type_id = this.alert?.audit.type_id;

		this.alertService
			.findDetails(this.alert.id, currentQuery)
			.subscribe((result) => {
				this.audits = result.items;
				this.query = currentQuery;
				this.query.total = result.total;
			});
	}

	back() {
		this.router.navigate(['/audit/audit-results']);
	}

	getGridService(): GridService {
		return this.alertDetailsGridService;
	}

	onSelectionChanged(event) {
		this.selectedRows = [...event.selectedRowsData];
	}

	addInvoiceChargeProfile() {
		this.dialog
			.open(
				CreateInvoiceChargeProfileDialogComponent,
				{
					title: 'Add Charges to Charge Profile',
					charges: this.selectedRows
				},
				{
					width: '920px',
					height: '540px'
				}
			)
			.afterClosed()
			.subscribe(async (response) => {
				if (!response) return;

				if (!response?.data)
					return this.alertComponentService.error(
						'',
						this.messages.get('CHARGE_PROFILE_CREATE_FAIL')
					);

				this.alertComponentService.success(
					'',
					this.messages.get('CHARGE_PROFILE_CREATE_SUCCESS')
				);
				this.refresh();
			});
	}

	addDispute() {
		const calculatedCharges = this.selectedRows
			.sort((a, b) => a.charge_id - b.charge_id)
			.map((r) => r.calc_chg_amt);

		const dispDesc = new Array(this.selectedRows.length).fill(
			'Charges billed do not equal calculated charges.'
		);

		this.dialog
			.open(
				DisputeManageDialogComponent,
				{
					charges: this.selectedRows.map((c) => ({
						...c,
						invoice: this.alert.invoice
					})),
					invoice: this.alert.invoice,
					calculatedCharges: calculatedCharges,
					dispDesc: dispDesc,
					alertId: this.alert.id
				},
				{ width: '60%' }
			)
			.afterClosed()
			.subscribe(async (result) => {
				if (result) {
					this.selectedRows = [];
					this.refresh();
				}
			});
	}

	public goToInvoiceDetails() {
		if (this.alert && this.alert.invoice && this.alert.invoice.invoice_id) {
			this.router.navigate(['/invoice', this.alert.invoice.invoice_id, 'show']);
		}
	}

	refresh() {
		this.loadDetails();
	}

	filterData(query: Query) {
		this.loadDetails(query);
	}

	clearFilter(query: Query) {
		this.loadDetails(query);
	}

	onPageChange(query: Query) {
		this.loadDetails(query);
	}
}
