export const invoiceFilterContext = {
  filters: [
    {
      field: 'invoice_id',
      label: 'Invoice ID',
      type: 'negativeNumber',
      index: 1
    },
    {
      field: 'sp_inv_num',
      label: 'Invoice Number',
      type: 'string',
      index: 1
    },
    {
      field: 'header.status.key',
      label: 'Status',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'invoiceStatus',
      index: 2
    },
    {
      field: 'vendor.name',
      label: 'Vendor',
      type: 'lookup',
      lookupProviderName: 'vendor',
      index: 3,
      visible: true
    },
    {
      field: 'acct_level_1',
      label: 'Account',
      type: 'lookup',
      lookupProviderName: 'account',
      index: 4
    },
    {
      field: 'due_date',
      label: 'Due Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 5,
    },
    {
      field: 'account.subclient.value',
      label: 'Subclient',
      type: 'lookup',
      lookupProviderName: 'subclient',
      index: 4
    },
    {
      field: 'bal_fwd',
      label: 'Balance Forward',
      type: 'negativeNumber',
      index: 7
    },
    {
      field: 'tot_new_chg_adj',
      label: 'Total New Charges Adj',
      type: 'negativeNumber',
      index: 8
    },
    {
      field: 'tot_amt_due',
      label: 'Total Due ',
      type: 'negativeNumber',
      index: 9
    },
    {
      field: 'tot_mrc_chgs',
      label: 'Total MRC',
      type: 'negativeNumber',
      index: 10
    },
    {
      field: 'tot_occ_chgs',
      label: 'Total OCC',
      type: 'negativeNumber',
      index: 11
    },
    {
      field: 'tot_usage_chgs',
      label: 'Total Usage',
      type: 'negativeNumber',
      index: 12
    },
    {
      field: 'tot_taxsur',
      label: 'Total Taxes / Surcharge',
      type: 'negativeNumber',
      index: 13
    },
    {
      field: 'tot_disc_amt',
      label: 'Total Discount',
      type: 'negativeNumber',
      index: 14
    },
    {
      field: 'tot_new_chgs',
      label: 'Total New Charges',
      type: 'negativeNumber',
      index: 15
    },
    {
      field: 'header.tot_new_chg_diff',
      label: 'New Charges Diff ',
      type: 'negativeNumber',
      index: 16
    },
    {
      field: 'header.tot_new_chg_diff_pct',
      label: 'New Charges Diff % ',
      type: 'negativeNumber',
      index: 17
    },
    {
      label: 'Batch ID',
      field: 'header.batch.batch_key',
      type: 'string',
      index: 18
    },
    {
      field: 'inv_date',
      label: 'Invoice Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 19,
    },
    {
      field: 'header.received_at',
      label: 'Received Date',
      type: 'lookup',
      editor: 'dateRanges',
      lookupProviderName: 'defaultDateRanges',
      index: 20,
    },
    {
      field: 'account.on_hold',
      label: 'On Hold',
      type: 'boolean',
      trueLabel: 'Yes',
      falseLabel: 'No',
      index: 21,
    },
    {
      label: 'AP Invoice Number',
      field: 'batch_item.ap_invoice_number',
      type: 'string',
      index: 22
    },
    {
      field: 'currency',
      label: 'Currency',
      type: 'lookup',
      lookupProviderName: 'currency',
      index: 23,
    },
    {
      field: 'header.type.key',
      label: 'Document Type',
      type: 'lookup',
      editor: 'checkboxList',
      lookupProviderName: 'documentInvoiceType',
      index: 24
    },
    {
      field: 'account.is_tax_exempt',
      label: 'Tax Exempt',
      type: 'boolean',
      trueLabel: 'Yes',
      falseLabel: 'No',
      index: 25,
    },
    {
      label: 'Vendor Entity',
      field: 'account.entity.name',
      type: 'string',
      index: 26
    },
    {
      field: 'header.dnp_reason.value',
      label: 'DNP Reason Code',
      type: 'lookup',
      lookupProviderName: 'dnpReasonCode',
      index: 27
    },
    {
      label: 'Vendor Location Id',
      field: 'account.vendor_remit_address.vendor_location_id',
      type: 'lookup',
      lookupProviderName: 'vendorRemitAddress',
      index: 28
    }
  ]
};
