<div class="history">
	<mat-toolbar color="accent">
		<span class="sneak-peek-title">History</span>
		<span class="example-fill-remaining-space"></span>
	</mat-toolbar>

	<div
		class="ca-empty-state-container history-container"
		*ngIf="!history?.length"
	>
		<p class="title">No history</p>
	</div>

	<div class="history-item history-item-overflow" *ngFor="let entry of history">
		<!-- Account History -->
		<ng-container *ngIf="entry.entity_type === 'account'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<!-- GPX-7266 -->
			<span *ngIf="entry.action_key === 'auto_approver_update'">
				<span class="history-entry-label">{{ entry?.custom_message }}</span>
			</span>

			<span *ngIf="entry.action_key !== 'auto_approver_update'">
				<!--GPX-8866 -->
				<span
					*ngIf="
						entry.action_key === 'bulk-upload';
						else account_not_bulk_upload
					"
				>
					<b>{{ entry.meta_data.meta_action }} </b>
					{{ entry.meta_data.meta_title.toLowerCase() }}
					<b
						>{{ entry.custom_message }} {{ entry.meta_data?.bulk_upload_id }}</b
					>
				</span>

				<ng-template #account_not_bulk_upload>
					<ng-container
						*ngIf="
							entry.custom_message && entry.action_key == 'custom';
							else account_not_bulk_upload_else
						"
					>
						<span>{{ entry.custom_message }}</span>
					</ng-container>
					<ng-template #account_not_bulk_upload_else>
						<!-- GPX-8866 -->
						<span
							><b>{{ entry?.custom_message?.split(' ')[0] }}</b>
							{{ entry?.custom_message?.split(' ').slice(1).join(' ') }}</span
						>
					</ng-template>
				</ng-template>
			</span>
		</ng-container>

		<!-- Contract History -->
		<ng-container
			*ngIf="
				entry.entity_type === 'contract' ||
				entry.parent_entity_type === 'contract'
			"
		>
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_contract_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_contract_else>
				<!-- GPX-7719 -->
				<span
					><b>{{ entry?.custom_message?.split(' ')[0] }}</b>
					{{ entry?.custom_message?.split(' ').slice(1).join(' ') }}</span
				>
			</ng-template>
		</ng-container>

		<!-- Contract Section History -->
		<ng-container *ngIf="entry.entity_type === 'contractsection'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_contract_section_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_contract_section_else>
				<!-- LCMA-1056 -->
				<span class="history-entry-label">contract section</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.name?.value }}
				</a>
			</ng-template>
		</ng-container>

		<!-- Order History -->
		<ng-container *ngIf="entry.entity_type === 'order'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_order_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_order_else>
				<!-- GPX-6928 -->
				<span
					class="history-entry-action"
					*ngIf="entry.action_key !== 'status_update'"
				>
					{{ entry.action_key | caHistoryAction }}
				</span>

				<!-- GPX-7240 -->
				<ng-container *ngIf="entry.action_key === 'delete'">
					<span class="history-entry-label">order service</span>
					<span class="history-entry-item">{{
						entry.meta_data?.full_service_id?.value
					}}</span>
					<span class="history-entry-label">on</span>
				</ng-container>

				<!-- GPX-6928 -->
				<span
					class="history-entry-action"
					*ngIf="entry.action_key === 'status_update'"
				>
					{{ getStatusUpdateAction(entry, 'order') }}
				</span>

				<span class="history-entry-label">order</span>
				<!-- GPX-6956 Sandra -->
				<a class="history-entry-item">
					{{ formatOrderId(entry.entity_id, entry) }}
				</a>

				<!-- GPX-7240 -->
				<span
					*ngIf="
						!isStatusEqual(entry, 'order') && entry.action_key !== 'delete'
					"
				>
					to {{ entry.meta_data?.status_value?.value }}</span
				>
			</ng-template>
		</ng-container>

		<!-- Order Service History -->
		<ng-container *ngIf="entry.entity_type === 'orderservice'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>

			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_order_service_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_order_service_else>
				<span
					class="history-entry-action"
					*ngIf="entry.action_key !== 'status_update'"
				>
					{{ entry.action_key | caHistoryAction }}
				</span>

				<span
					class="history-entry-action"
					*ngIf="entry.action_key === 'test_update'"
				>
					updated
				</span>

				<span
					class="history-entry-action"
					*ngIf="entry.action_key === 'status_update'"
				>
					{{ getStatusUpdateAction(entry, 'orderservice') }}
				</span>

				<span class="history-entry-label">order service</span>

				<a class="history-entry-item">
					{{ entry.meta_data?.full_service_id?.value }}
				</a>

				<span *ngIf="entry.action_key === 'test_update'">
					{{ entry.meta_data?.test_type?.value }}
					{{ entry.meta_data?.test_pass?.label }}
					{{ entry.meta_data?.test_pass?.value }}
				</span>

				<span *ngIf="entry.action_key === 'status_update'">on order</span>

				<a
					*ngIf="entry.action_key === 'status_update'"
					class="history-entry-item"
				>
					{{ formatOrderId(entry.parent_id) }}
				</a>

				<span *ngIf="entry.action_key === 'status_update'">
					to {{ entry.meta_data?.status_value?.value }}</span
				>
			</ng-template>
		</ng-container>

		<!-- Invoice History -->
		<!-- TODO: Check Usage -->
		<ng-container *ngIf="entry.entity_type === 'invoice'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_invoice_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_invoice_else>
				<span class="history-entry-label">invoice</span>
				<a class="history-entry-item">
					{{
						entry.meta_data?.sp_inv_num?.value || entry.meta_data?.sp_inv_num
					}}
				</a>
			</ng-template>
		</ng-container>

		<!-- Invoice Document History -->
		<!-- TODO: Check / See no point of having custom message here -->
		<ng-container *ngIf="entry.entity_type === 'invoice_document'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_invoice_document_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_invoice_document_else>
				<span class="history-entry-label history-entry-label-overflow">{{
					entry?.custom_message
				}}</span>
				<a class="history-entry-item">
					{{
						entry.meta_data?.sp_inv_num?.value || entry.meta_data?.sp_inv_num
					}}
				</a>
			</ng-template>
		</ng-container>

		<!-- Invoice Charge History -->
		<!-- TODO: Check / See no point of having custom message here -->
		<ng-container *ngIf="entry.entity_type === 'charge'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_invoice_charge_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_invoice_charge_else>
				<span class="history-entry-label history-entry-label-overflow">{{
					entry?.custom_message
				}}</span>
				<a class="history-entry-item">
					{{
						entry.meta_data?.sp_inv_num?.value || entry.meta_data?.sp_inv_num
					}}
				</a>
			</ng-template>
		</ng-container>

		<!-- Dispute History -->
		<ng-container *ngIf="entry.entity_type === 'dispute'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_dispute_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_dispute_else>
				<span
					class="history-entry-action"
					*ngIf="entry.action_key === 'update'"
				>
					updated
				</span>
				<span
					class="history-entry-action"
					*ngIf="entry.action_key === 'status_change'"
				>
					changed
				</span>

				<span *ngIf="entry.action_key === 'created'">
					<span class="history-entry-action"> created </span>
				</span>
				<span class="history-entry-label" *ngIf="entry.action_key === 'delete'">
					<span class="history-entry-action"> deleted </span>
				</span>

				<span
					*ngIf="
						entry.action_key !== 'delete' &&
						entry.action_key !== 'status_change'
					"
					class="history-entry-label"
				>
					dispute
				</span>

				<span
					*ngIf="entry.action_key === 'status_change' && entry.custom_message"
				>
					{{
						getDisputeStatusLabel(entry.meta_data?.status_change?.key) +
							' to ' +
							entry.meta_data?.status_change?.value
					}}
				</span>

				<ng-container *ngIf="entry.action_key === 'delete'">
					<ng-container
						*ngIf="
							entry.meta_data &&
								entry.meta_data.charges_id.value.indexOf(',') === -1;
							else charges
						"
					>
						<span class="history-entry-label">charge </span>
						<span class="history-entry-item">{{
							entry.meta_data.charges_id.value
						}}</span>
					</ng-container>
					<ng-template #charges>charges</ng-template>
				</ng-container>
			</ng-template>
		</ng-container>

		<!-- Dispute Charge History -->
		<!-- TODO: Check / See no point of having custom message here -->
		<ng-container
			*ngIf="
				entry.entity_type === 'dispute_charge' &&
				entry.parent_entity_type === 'dispute'
			"
		>
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_dispute_charge_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_dispute_charge_else>
				<span
					*ngIf="
						entry.action_key === 'status_change' &&
						entry.meta_data?.status_change?.charge_id
					"
				>
					<span class="history-entry-action"> changed </span
					>{{
						getDisputeStatusLabel(entry.meta_data?.status_change?.key || '')
					}}
					for charge
					<a class="history-entry-item">{{
						entry.meta_data?.status_change?.charge_id
					}}</a>
					to
					<span
						*ngIf="
							entry.meta_data?.status_change?.key === 'charge_status_change';
							else paymentWithheld
						"
					>
						{{ entry.meta_data?.status_change?.value }}
					</span>
					<ng-template #paymentWithheld>
						{{ entry.meta_data?.status_change?.value ? 'Yes' : 'No' }}
					</ng-template>
				</span>
			</ng-template>
		</ng-container>

		<!-- String History -->
		<ng-container *ngIf="entry.entity_type === 'string'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_string_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_string_else>
				<!-- GPX-8196 -->
				<span *ngIf="entry.action_key === 'create'">
					<span class="history-entry-action"> created </span>string
				</span>
				<span *ngIf="entry.action_key === 'status_update'">
					<span class="history-entry-action"> updated </span>status to
					{{ entry.meta_data?.action?.value }}
				</span>
				<span *ngIf="entry.action_key === 'replace_string'">
					<span class="history-entry-action"> replaced </span>string with
					{{
						entry.meta_data?.replacement?.value
							? entry.meta_data?.replacement?.value
							: 'N/A'
					}}
				</span>

				<!-- GPX-8866 -->
				<span *ngIf="entry.action_key === 'bulk-upload'">
					<b>{{ entry.meta_data.meta_action }} </b>
					{{ entry.meta_data.meta_title.toLowerCase() }}
					<b
						>{{ entry.custom_message }} {{ entry.meta_data?.bulk_upload_id }}</b
					>
				</span>
			</ng-template>
		</ng-container>

		<!-- Invoice Status History -->
		<ng-container
			*ngIf="
				entry.entity_type === 'invoicestatushistory' &&
				entry.meta_data?.status_code?.value != invoiceStatusEnum.GL_PENDING
			"
		>
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_invoice_status_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_invoice_status_else>
				<!-- GPX-5930-1 -->
				<ng-container
					*ngIf="
						entry.meta_data?.is_manual_proceed?.value;
						else is_manual_proceed_else
					"
				>
					<span>
						did manual proceed and override the rule
						{{ entry.meta_data?.vat_rule_failed?.value }}</span
					>
				</ng-container>

				<ng-template #is_manual_proceed_else>
					<!-- GPX-5930-1 -->
					<span
						class="history-entry-action"
						*ngIf="entry.action_key !== 'status_update'"
					>
						{{ entry.action_key | caHistoryAction }}
					</span>
					<!-- GPX-6928 -->
					<span
						class="history-entry-action"
						*ngIf="entry.action_key === 'status_update'"
					>
						{{ getStatusUpdateAction(entry, 'invoicestatushistory') }}
					</span>

					<!-- GPX-8040 -->
					<span
						class="history-entry-label"
						*ngIf="entry?.custom_message; else no_message"
					>
						{{ getInvoiceStatusCustomMessagePrefix(entry?.custom_message) }}
					</span>
					<ng-template #no_message>{{ 'invoice' }}</ng-template>

					<span class="history-entry-item"> {{ invoice.sp_inv_num }}</span>

					<!-- GPX-8040 -->
					<span
						*ngIf="entry?.custom_message; else only_status"
						[innerHtml]="getInvoiceStatusCustomMessage(entry)"
					>
					</span>
					<ng-template #only_status>
						to {{ entry.meta_data?.status_value?.value }}
					</ng-template>
				</ng-template>
			</ng-template>
		</ng-container>

		<!-- Inventory History -->
		<ng-container *ngIf="entry.entity_type === 'inventory'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>

			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_inventory_else
				"
			>
				<span *ngIf="entry.action_key === 'custom_bold'; else custom">
					<b>{{ entry?.custom_message?.split(' ')[0] }}</b>
					{{ entry?.custom_message?.split(' ').slice(1).join(' ') }}
				</span>
				<ng-template #custom
					><span>{{ entry.custom_message }}</span></ng-template
				>
			</ng-container>

			<ng-template #custom_inventory_else>
				<span
					*ngIf="
						entry?.action_key === 'created' || entry?.action_key === 'updated'
					"
				>
					<span class="history-entry-action"> {{ entry.action_key }} </span>
					inventory
				</span>

				<!-- GPX-7258 -->
				<span
					*ngIf="
						entry.action_key === 'status_updated' ||
						entry.action_key === 'status_changed'
					"
				>
					<span class="history-entry-action">
						{{ entry.action_key === 'status_updated' ? 'updated' : 'changed' }}
					</span>
					inventory to {{ getInventoryStatus(entry) }}
				</span>

				<span
					*ngIf="
						entry.action_key === 'so_created' ||
						entry.action_key === 'os_updated'
					"
				>
					<span class="history-entry-action">
						{{ entry.action_key === 'so_created' ? 'created ' : 'updated' }}
					</span>
					order service
					<a class="history-entry-item">
						{{ entry.meta_data?.service?.value }}</a
					>
				</span>

				<span *ngIf="entry.action_key === 'os_status_updated'">
					<span class="history-entry-action"> changed </span> order service
					<a class="history-entry-item">
						{{ entry.meta_data?.service?.value }}</a
					>
					to {{ entry?.custom_message }}
				</span>

				<span *ngIf="entry.action_key === 'completed_disconnected'">
					<span class="history-entry-action"> changed </span> order service
					<a class="history-entry-item">
						{{ entry.meta_data?.service?.value }}</a
					>
					to {{ entry?.custom_message }} and disconnected inventory
				</span>

				<span *ngIf="entry.action_key === 'deleted order service'">
					<span class="history-entry-action"> deleted </span> order service
					<a class="history-entry-item"> {{ entry.custom_message }}</a>
				</span>

				<!-- GPX-8866 -->
				<span *ngIf="entry.action_key === 'bulk-upload'">
					<b>{{ entry.meta_data.meta_action }} </b>
					{{ entry.meta_data.meta_title.toLowerCase() }}
					<b
						>{{ entry.custom_message }} {{ entry.meta_data?.bulk_upload_id }}</b
					>
				</span>
			</ng-template>
		</ng-container>

		<!-- Custom Rules History -->
		<ng-container *ngIf="entry.entity_type == 'rule'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_rule_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_rule_else>
				<span
					*ngIf="entry.action_key === 'create' || entry.action_key === 'update'"
				>
					<span class="history-entry-action">
						{{ entry.action_key === 'create' ? 'created' : 'updated' }}
					</span>
					rule
				</span>
			</ng-template>
		</ng-container>

		<!-- Inventory GL Codes History -->
		<ng-container *ngIf="entry.entity_type === 'inventory-gl-string'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_inventory_gl_string_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_inventory_gl_string_else>
				<span *ngIf="entry.action_key === 'create'">
					<span class="history-entry-action"> added </span>apportions
				</span>

				<span *ngIf="entry.action_key === 'update'">
					<span class="history-entry-action"> updated </span>apportions
				</span>

				<span *ngIf="entry.action_key === 'delete'">
					<span class="history-entry-action"> removed </span>apportions
				</span>

				<span *ngIf="entry.action_key === 'bulk-upload'">
					<b>{{ entry.meta_data.meta_action }} </b>
					{{ entry.meta_data.meta_title.toLowerCase() }}
					<b
						>{{ entry.custom_message }} {{ entry.meta_data?.bulk_upload_id }}</b
					>
				</span>
			</ng-template>
		</ng-container>

		<!-- Document History -->
		<!-- TODO: Check Usage -->
		<ng-container *ngIf="entry.entity_type === 'document'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_document_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_document_else>
				<span class="history-entry-label">document</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.path?.value }}
				</a>
			</ng-template>
		</ng-container>

		<!-- TODO: Check Usage -->
		<ng-container *ngIf="entry.entity_type === 'documententity'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_document_entity_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_document_entity_else>
				<span class="history-entry-label">document</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.document_name?.value }}
				</a>
			</ng-template>
		</ng-container>

		<!-- Quoting History-->
		<!-- TODO: Check Usage -->
		<ng-container *ngIf="entry.entity_type === 'quote'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_quote_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_quote_else>
				<span class="history-entry-label">quote</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.quote_id?.value }}
				</a>
			</ng-template>
		</ng-container>

		<ng-container *ngIf="entry.entity_type === 'quoteservice'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_quote_svc_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_quote_svc_else>
				<span class="history-entry-label">quote service</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.quote_service_id?.value }}
				</a>
			</ng-template>
		</ng-container>

		<ng-container *ngIf="entry.entity_type === 'quoteserviceoffer'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_quote_svc_offer_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_quote_svc_offer_else>
				<span class="history-entry-label">quote request</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.quote_service_offer_id?.value }}
				</a>
				<span>on quote service</span>
				<a class="history-entry-item">
					{{ entry.custom_message }}
				</a>
			</ng-template>
		</ng-container>

		<!-- Customer equipment -->
		<!-- TODO: Check Usage -->
		<ng-container *ngIf="entry.entity_type === 'customerequipment'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_customer_equipment_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_customer_equipment_else>
				<span class="history-entry-label">customer equipment</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.full_equipment_id?.value }}
				</a>
			</ng-template>
		</ng-container>

		<!-- Customer inventory -->
		<ng-container *ngIf="entry.entity_type === 'customerinventory'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_customer_inventory_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_customer_inventory_else>
				<span class="history-entry-label">customer inventory</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.customer_circuit_id?.value }}
				</a>

				<!-- GPX-8762 -->
				<span *ngIf="entry.action_key === 'created'">
					<span class="history-entry-action"> created </span>rule
				</span>

				<span *ngIf="entry.action_key === 'update'">
					<span class="history-entry-action"> updated </span>rule
				</span>
			</ng-template>
		</ng-container>

		<!-- Telephone number -->
		<!-- TODO: Check Usage -->
		<ng-container *ngIf="entry.entity_type === 'tntfn'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_tntfn_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_tntfn_else>
				<span class="history-entry-label">telephone number</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.tn?.value }}
				</a>
			</ng-template>
		</ng-container>

		<ng-container *ngIf="entry.entity_type === 'invoicechargeprofile'">
			<span class="history-entry-item" (click)="showHistoryCompare(entry)">{{
				entry.created_at | caDateTime
			}}</span>
			<span>&nbsp;&#8226;</span>
			<a (click)="showUserModal(entry.user)" class="history-entry-user">
				{{ entry?.user?.first_name }} {{ entry?.user?.last_name }}
			</a>

			<ng-container
				*ngIf="
					entry.custom_message && entry.action_key === 'custom';
					else custom_charge_profile_else
				"
			>
				<span>{{ entry.custom_message }}</span>
			</ng-container>

			<ng-template #custom_charge_profile_else>
				<span class="history-entry-label">Invoice Charge Profile</span>
				<a class="history-entry-item">
					{{ entry.meta_data?.id?.value }}
				</a>

				<span *ngIf="entry.action_key === 'created'">
					<span class="history-entry-action"> created </span>rule
				</span>

				<span *ngIf="entry.action_key === 'update'">
					<span class="history-entry-action"> updated </span>rule
				</span>
			</ng-template>
		</ng-container>
	</div>
</div>
