import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
// import { forceFillColumnWidths } from '@swimlane/ngx-datatable';

import { Observable } from 'rxjs';
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from '../picker/ca-picker-base.component';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import {map} from "rxjs";


@Component({
  selector: 'ca-lookup-model-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaLookupModelPickerComponent),
      multi: true
    },
    DictionaryService
  ]
})
export class CaLookupModelPickerComponent extends PickerComponentBase implements OnInit, OnChanges {

  readonly TEXT_FIELD = 'value';
  KEY_FIELD = 'id';

  @Input('formControlName') formControlName;
  @Input('initialItem') initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean = false;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('valueAsString') valueAsString: boolean;
  @Input('lookupModel') lookupModel: string;
  @Input() disableCache: boolean;
  @Input() customOption: object;
  @Input('excludeOptions') excludeOptions: Array<any>;
  @Input() account: any;

  @Input('query') query;

  public control;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  loadEntries(): Observable<any> {
    this.query.limit = 1000;
    //We add custom dropDown option, because we dont have this data in lookup tables
    if (this.customOption) {
      return this.dictionaryService.getByLookup(this.lookupModel, this.query, true).pipe(
        map((data: any) => {
        return [this.customOption, ...data.items];
      }));
    }
    //Exclude some options
    if (this.excludeOptions && this.excludeOptions.length) {
      this.query.where = {...this.query.where, id: {$notIn: this.excludeOptions}};
    }
    return this.dictionaryService.getByLookup(this.lookupModel, this.query, true)
  }

  search(value: string): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel, this.query);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];

    if (this.formControlName == 'subclient_id' && changes.account && changes.account.currentValue) {
      const found = this.entries.filter(item => item.id === changes.account.currentValue.subclient_id)
      if (found && found.length)
        this.control.patchValue(found[0].id)
      else
        this.control.patchValue(-1)
    }

    this.searchEnabled = false;

    if (changes['query'] && changes['query'].currentValue) {
      this.loadData();
    }
  }

  handleClear() {
    this.account = null
    this.onPickerClear.emit();
  }
}
