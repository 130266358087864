import {Injectable} from '@angular/core';
import {Site} from './site';
import {BaseService} from '../../core/base.service';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import Query from '../../core/query/query';

@Injectable()
export class SiteService extends BaseService<Site> {

  constructor(private entityLock: EntityLockService) {
    super('site', entityLock);
  }

  findSitesOnly(query?: Query): any {
    let concreteQuery = query || new Query();
    const transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'sites'], this.toFilter(transformedQuery));
  }

  findAllList(query?: Query): any {
    let concreteQuery = query || new Query();
    const transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'list'], this.toFilter(transformedQuery))
  }
}
