import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  ElementRef,
} from '@angular/core';
import { NotesService } from '../core/notes.service';
import { Notes } from '../core/notes';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import Query from '../../../core/query/query';
import { DialogService } from "../../dialog/dialog.service";
import { UserModalComponent } from "../../user-modal/user-modal.component";
import { DomSanitizer } from "@angular/platform-browser";
import {flatMap} from "rxjs";

@Component({
  selector: 'ca-account-notes',
  templateUrl: './account-notes-list.component.html',
  styleUrls: ['./account-notes-list.component.scss']
})
export class AccountNotesListComponent implements OnInit, OnChanges {

  @Input() entityType: any;
  @Input() entityId: number;
  @Input() entityName: string;
  @Input() enableReply: boolean;
  @Input('selectedId') selectedId: number;
  @Output() countChanged = new EventEmitter();
  @Output() noteCreated = new EventEmitter();

  public notes: Array<Notes> = [];
  public replyMode = false;
  noteForm: FormGroup;
  selectedNote: Notes;

  constructor(
    public notesService: NotesService,
    public formBuilder: FormBuilder,
    public dialog: DialogService,
    public sanitizer: DomSanitizer,
    public elRef: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['entityId'] && changes['entityId'].currentValue)
      ||
      ( changes['entityType'] && changes['entityType'].currentValue )
    ) {
      if (this.entityId && this.entityType) {
        this.loadNotes();
      }
    }
  }

  ngOnInit() {
    this.noteForm = this.formBuilder.group({
      content: new FormControl('', Validators.required)
    });
  }

  loadNotes() {
    let query = new Query({
      where: { entity_type: this.entityType, parent_id: null },
      orderBy: [['created_at', 'DESC']]
    });

    if (this.entityId) {
      query.where['entity_id'] = this.entityId;
    }

    this.notesService.findAll(query)
      .subscribe(
        (result) => {
          this.notes = result.items;
          this.emitCount();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  cancel(note: Notes) {
    note.reply = null;
    this.replyMode = false;
  }

  createNote(parentId?: number, content?: string): void {
    let note = new Notes();

    let query = new Query({
      where: { entity_type: this.entityType, parent_id: null },
      orderBy: [['created_at', 'DESC']]
    });

    if (this.entityId) {
      query.where['entity_id'] = this.entityId
    }

    note.content = content || this.noteForm.controls['content'].value;
    note.entity_id = this.entityId || null;
    note.entity_type = this.entityType;
    note.parent_id = parentId;

    this.noteForm.reset();

    this.notesService.create(note)
      .pipe(flatMap(result => this.notesService.findAll(query)))
      .subscribe(
        (result) => {
          this.notes = result.items;
          this.emitCount();
          const formControlContent = this.noteForm.controls['content'];
          formControlContent.setErrors({ 'required': false });
          this.replyMode = false;

          this.noteCreated.emit();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  reply(note: Notes) {
    if (this.replyMode) {
      this.createNote(note.id, note.reply);
    } else {
      this.replyMode = true;
      this.selectedNote = note;
    }
  }

  getUserInitials(note): string {
    return note.user
      ? note.user.first_name.toUpperCase()[0] + note.user.last_name.toUpperCase()[0]
      : 'NA';
  }

  public showUserModal(user) {
    this.dialog.open(UserModalComponent, { user: user }, { width: '400px' });
  }

  emitCount() {
    this.countChanged.emit(this.notes.length);
  }

  sanitizeNoteContent(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
