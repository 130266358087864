import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {forkJoin, from, Observable} from 'rxjs';
import {PICKER_COMPONENT_TEMPLATE, PickerComponentBase} from '../../../shared/picker/ca-picker-base.component';
import {AccountService} from '../../core/account.service';
import {AccountQuery} from '../../core/account.query';
import {ACCOUNT_STATUS_ENUM} from '../../core/account-status.enum';
import {path} from 'ramda';
import Query from 'app/core/query/query';
import {map, tap} from 'rxjs';

@Component({
  selector: 'ca-account-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaAccountPickerComponent),
      multi: true
    },
    AccountService
  ]
})
export class CaAccountPickerComponent extends PickerComponentBase implements OnInit, OnChanges {
  TEXT_FIELD = 'account_no';
  KEY_FIELD = 'account_no';
  @Input() query: AccountQuery = new AccountQuery({
    orderBy: [['account_no', 'ASC']],
    limit: 20
  });

  @Input('formControlName') formControlName;
  @Input('initialItem') initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('multiple') multiple: boolean;
  @Input('placeholder') placeholder = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('disableOnHold') disableOnHOld: boolean;
  @Input() vendorId: number;
  @Input() customMethod: string;
  @Input() initialItemId: string;
  @Input() approveTemp: boolean;
  @Input() tempBanName: string;
  @Input() subclient_id: number;
  @Input() account: any
  @Output() onClear: EventEmitter<any> = new EventEmitter();

  get keyField(): string {
    return this.KEY_FIELD;
  }

  @Input('keyField')
  set keyField(value: string) {
    this.KEY_FIELD = value;
  }

  public control;

  constructor(public accountService: AccountService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = path(['pickerForm', 'controls', this.formControlName])(this);
    const {vendorId, initialItemId, subclient_id} = changes;
    if (
      (vendorId && (!!vendorId.currentValue || !!vendorId.previousValue) && vendorId.currentValue !== vendorId.previousValue)
      || (initialItemId && (!!initialItemId.currentValue || !!initialItemId.previousValue) && initialItemId.currentValue !== initialItemId.previousValue)
      || (subclient_id && (!!subclient_id.currentValue || !!subclient_id.previousValue) && subclient_id.currentValue !== subclient_id.previousValue)
    ) {
      // Prevent account data reload when missing sub-client, on initial load
      if (subclient_id && !!subclient_id.previousValue === false && subclient_id.currentValue === -1 && !this.approveTemp) {
        return;
      }
      if(vendorId && !!vendorId.previousValue && vendorId.currentValue !== vendorId.previousValue)
        this.initialItem = null;

      this.loadData();
    }
  }

  // copied from Base Picker / added account check to prevent data reload
  loadData(): void {
    const data$ = this.initialItemId != null ? forkJoin([
        this.loadEntries(),
        this.loadInitialItem()
      ]
    ).pipe(
      tap(([_, initialItem]) => {
        this.initialItem = initialItem;
      }),
      map(([allEntries]) => {
        return allEntries;
      }),) : this.loadEntries();


     if (this.loadDataSusbscription) {
       this.loadDataSusbscription.unsubscribe();
     }

    this.loadDataSusbscription = from(data$).subscribe((result) => {
      if (this.account) return;
      this.entries = result.items ? result.items : result;
      this.query = this.query || new Query({
        limit: 20
      });
      this.query.total = result.total;
      this.query.page = 1;
    });
  }

  disableOnHoldAccounts(data) {
    data.items.forEach(item => {
      if (item.on_hold) {
        item.account_no += '    On hold';
        item.disabled = true;
      }
    })
    return data
  }

  loadEntries(): Observable<any> {
    this.query = this.query || new AccountQuery({
      where: {
        "status_id": ACCOUNT_STATUS_ENUM.ACTIVE
      }
    });

    this.vendorId ? this.query.set('vendor_id', this.vendorId) : this.query.remove('vendor_id');
    this.subclient_id && this.subclient_id !== -1 ?
      this.query.set('subclient_id', this.subclient_id) :
      this.query.remove('subclient_id')
    //Don't show temp accounts when create order service
    if(this.approveTemp) {
      this.query.set('is_temporary', false)
    }
    this.query.limit = 50;
    let serviceMethod: string = this.customMethod || 'findAccountsForPicker'; // 'findAll';
    let data = this.accountService[serviceMethod](this.query).pipe(tap((data: any) => {
      if (this.approveTemp && this.query.page < 2 && this.tempBanName) {
        if (data['items']) data['items'].unshift({account_no: this.tempBanName, id: null})
      }
    }))

    if (!data.items) {
      data.items = data;
    }

    return this.disableOnHOld ? this.disableOnHoldAccounts(data) : data;
  }

  loadInitialItem(): Observable<any> {
    if (this.keyField === 'id') {
      return this.accountService.findById(Number(this.initialItemId));
    } else if (this.vendorId) {
      return this.accountService.findOne(new Query({
        where: {
          account_no: this.initialItemId,
          vendor_id: this.vendorId
        }
      }));
    } else {
      return new Observable();
    }
  }

  search(value: string): Observable<any> {
    this.subclient_id && this.subclient_id !== -1 ?
      this.query.set('subclient_id', this.subclient_id) :
      this.query.remove('subclient_id')
    if (value) {
      this.query.where['account_no'] = {'$ilike': `${value}%`};
    } else {
      delete this.query.where['account_no'];
    }
    let serviceMethod: string = this.customMethod || 'findAll';
    let data = this.accountService[serviceMethod](this.query).pipe(tap((data: any) => {
      if (this.approveTemp && this.tempBanName) {
        if (data['items']) data['items'].unshift({account_no: this.tempBanName, id: null})
      }
    }))

    if (!data.items) {
      data.items = data;
    }

    return this.disableOnHOld ? this.disableOnHoldAccounts(data) : data;
  }

  triggerClearPicker() {
    this.writeValue(null);
    this.writeFiledStatus('markAsUntouched');
  }
  
  handleClear(){
    this.onClear.emit();
  }
}
