import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { VendorRemitAddress } from './vendor-remit-address';
import Query from '../../core/query/query';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class VendorRemitAddressService extends BaseService<VendorRemitAddress> {
  constructor(private entityLock: EntityLockService) {
    super('vendor-remit-address', entityLock);
   }

   findOnlyVendorRemit(query?: Query): any {
    let concreteQuery = query || new Query();
    const transformedQuery = concreteQuery.transform();
    return this.httpService().get(`${this.name}/vendor/remit`, this.toFilter(transformedQuery))
  }

}
