import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {UserService} from './user.service';
import {UserSetting} from "./user-setting";
import {AppService} from "../../app.service";

@Injectable()
export class UserSettingsResolve  {

  constructor(public userService: UserService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    return this.userService.settings();


  }
}
