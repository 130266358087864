import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { CoreModule } from '../core/core.module';
import { BuildingService } from './core/building.service';
import { BuildingManageDialogComponent } from './shared/building-manage-dialog/building-manage-dialog.component';
import { BuildingListComponent } from './building-list/building-list.component';
import { BuildingRoutingModule } from './building-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';

import { FlexLayoutModule } from '@angular/flex-layout';
import { BuildingSneakPeekComponent } from './shared/building-sneak-peek/building-sneak-peek.component';
import { LocationModule } from '../location/location.module';
import { SharedModule } from '../shared/shared.module';
import { BuildingGridService } from './core/building-grid.service';
import { BuildingFilterService } from './core/building-filter.service';
import { AddressModule } from '../address/address.module';
import { CaBuildingPickerComponent } from './shared/building-picker/ca-building-picker.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LocationModule,
        FormsModule,
        BuildingRoutingModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        DxButtonModule,
        DxDataGridModule,
        MaterialModule,
        CoreModule,
        AddressModule
    ],
    exports: [
        CaBuildingPickerComponent
    ],
    declarations: [
        CaBuildingPickerComponent,
        BuildingManageDialogComponent,
        BuildingListComponent,
        BuildingSneakPeekComponent,
    ],
    providers: [
        BuildingService,
        BuildingGridService,
        BuildingFilterService
    ]
})
export class BuildingModule {
}
