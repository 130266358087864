import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxChartComponent } from 'devextreme-angular/ui/chart';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getMarkup, exportFromMarkup } from 'devextreme/viz/export';
import { CurrencyPipe } from '@angular/common';

import { ChartReloadService } from '../../theme/core/chart-reload.service';
import Query from '../../core/query/query';
import { LOOKUP_ENUM } from '../../dictionary/core/lookup.enum';
import { QuotingReportService } from '../core/quoting-report.service';
import { Quote } from '../core/quote';
import { QuoteService } from '../core/quote-service.model';
import { ChartPdfExportService } from '../../report-gallery/core/chart-pdf-export.service';
import { ThemeService } from '../../theme/core/theme.service';
import { ChartService } from '../../shared/chart/chart.service';
import { QuotingReportFilterService } from '../core/quoting-report-filter.service';
import { LoaderService } from '../../shared/loader/loader.service';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";

// import * as moment from 'moment';


@Component({
  selector: 'app-quoting-report',
  templateUrl: './quoting-report.component.html',
  styleUrls: ['./quoting-report.component.css']
})
export class QuotingReportComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly CUSTOM_THEME_NAME = 'Custom Theme';
  readonly CHART_ID = 'QUOTING_REPORT_CHART';

  @ViewChild(DxChartComponent) chartComponent: DxChartComponent;
  chartData: any = [];
  currentQuoteResponses = [];
  currentQuoteResponsesRaw = [];

  query: Query = new Query({
    limit: 100000,
    orderBy: [['id', 'ASC']]
  });

  @ViewChild('panelSide') panelSide;
  isFilterOpen = false;

  filterQuery: Query = new Query({
    limit: 100000,
    orderBy: [['id', 'ASC']]
  });

  filteredQuoteResponses: any = [];

  currentQuoteHeader: Quote;
  currentQuoteService: QuoteService;
  title: string;

  constructor(public chartReloadService: ChartReloadService,
    public route: ActivatedRoute,
    public router: Router,
    public quotingReportService: QuotingReportService,
    public chartPdfExportService: ChartPdfExportService,
    public themeService: ThemeService,
    public chartService: ChartService,
    public quotingReportFilterService: QuotingReportFilterService,
    public currencyPipe: CurrencyPipe,
    public loadingService: LoaderService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        this.loadCurrentQuoteService(params['id'], params['serviceId']);
      });
  }

  loadCurrentQuoteService(quoteId: number, serviceId) {
    this.quotingReportService.findServiceById(quoteId, serviceId)
      .subscribe((service: QuoteService) => {
        this.currentQuoteService = service;
        this.currentQuoteHeader = service.quote;

        this.title = `Quote Analytics: ${this.currentQuoteService.quote_service_id} Responses`;

        this.loadCurrentQuoteResponses();
      })
  }

  loadCurrentQuoteResponses() {
    this.query.set('quote_service_id', this.currentQuoteService.id);
    this.query.set('responded', true);
    this.quotingReportService.fetchRequestsAnalyze(this.query)
      .subscribe((response) => {
        this.query.total = response.total;
        this.currentQuoteResponsesRaw = response.items; // to inject into csv download
        this.currentQuoteResponses = [];
        response.items.forEach((item) => {
          this.currentQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 1, 'current'));
          this.currentQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 2, 'current'));
          this.currentQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 3, 'current'));
          this.currentQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 5, 'current'));
          this.currentQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 7, 'current'));
          this.currentQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 0, 'current'));
        });

        this.chartData = this.currentQuoteResponses;

        setTimeout(() => {
          this.loadFilteredQuoteResponses(this.filterQuery);
        }, 250);
      }, (err) => {
        console.log('error fetching responses');
      });
  }

  loadFilteredQuoteResponses(query: Query) {
    this.loadingService.displayLoader();

    query.set('quote_service_id', { '$ne': this.currentQuoteService.id });
    query.set('responded', true);

    if (!query.where['quoteService.service_type'] && this.currentQuoteService.quote_service_type) {
      query.set('quoteService.service_type', this.currentQuoteService.quote_service_type.id)
    }

    let availableTerms = [
      LOOKUP_ENUM.QUOTE_SERVICE_TERM.ONE_YEAR,
      LOOKUP_ENUM.QUOTE_SERVICE_TERM.TWO_YEAR,
      LOOKUP_ENUM.QUOTE_SERVICE_TERM.THREE_YEAR,
      LOOKUP_ENUM.QUOTE_SERVICE_TERM.FIVE_YEAR,
      LOOKUP_ENUM.QUOTE_SERVICE_TERM.SEVEN_YEAR,
      LOOKUP_ENUM.QUOTE_SERVICE_TERM.MTM
    ];
    if (query.where.hasOwnProperty('tmp_service_term')) {
      availableTerms = query.where.tmp_service_term['$in'];
    }

    this.quotingReportService.fetchRequestsAnalyze(query)
      .subscribe((response) => {
        this.filterQuery.total = response.total;

        this.filteredQuoteResponses = [];
        response.items.forEach((item) => {
          this.filteredQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 1, 'previous'));
          this.filteredQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 2, 'previous'));
          this.filteredQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 3, 'previous'));
          this.currentQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 5, 'previous'));
          this.currentQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 7, 'previous'));
          this.currentQuoteResponses.push(this.quotingReportService.generateChartPoint(item, 0, 'previous'));
        });

        this.chartData = this.currentQuoteResponses.concat(this.filteredQuoteResponses);

        this.loadingService.hideLoader();
      }, (err) => {
        this.loadingService.hideLoader();
        console.log('Error filter quote responses');
      })
  }


  /* Filter */
  filterData(query: Query) {
    this.filterQuery = query;
    this.loadFilteredQuoteResponses(this.filterQuery);
  }

  clearFilter(query: Query) {
    this.filterQuery = query;
    this.loadFilteredQuoteResponses(this.filterQuery);
  }

  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
    if (this.isFilterOpen) {
      this.panelSide.open();
    } else {
      this.panelSide.close();
    }
    setTimeout(() => {
      this.chartComponent.instance.render();
    }, 500);
  }

  /* Csv / Pdf */
  csv() {
    const {
      quotingReportService,
      filterQuery,
      title,
      currentQuoteResponsesRaw,
    } = this;

    const fields = quotingReportService.csvMap();
    const csvName = title.toLowerCase().replace(':', '').replace(' ', '_');
    const current = currentQuoteResponsesRaw.map(item => {
      item.type = 'Current';
      return item;
    });

    const assignPreviousMiddleware = (items) => items.map(item => {
      item.type = 'Previous';
      return item;
    });

    const concatPreviousToCurrentMiddleware = (items) => current.concat(items);

    quotingReportService.exportToCSV(
      csvName,
      {
        fields,
        query: filterQuery,
        middleware: [assignPreviousMiddleware, concatPreviousToCurrentMiddleware],
        fetchDataHandler: quotingReportService.fetchRequestsAnalyze.bind(quotingReportService)
      }
    );
  }

  exportToPdf() {
    let _this = this;
    let reportTitle = this.title;

    let markup = getMarkup([this.chartComponent.instance]);
    let chartSize = this.chartComponent.instance.getSize();

    let fileSavingAction = (e) => {
      e.cancel = true;
      let reader = new window['FileReader']();
      reader.readAsDataURL(e.data);
      reader.onloadend = function () {
        let base64data = reader.result;
        _this.downloadPdf(base64data, reportTitle);
      }
    };

    exportFromMarkup(markup, {
      fileName: 'chart',
      format: 'PNG',
      width: chartSize.width,
      height: chartSize.height,
      onFileSaving: fileSavingAction
    });
  }

  downloadPdf(base64data, reportTitle) {
    this.chartPdfExportService.exportToPdf(base64data, reportTitle)
      .subscribe((response: Blob) => {
        let objectUrl = URL.createObjectURL(response);

        let save = document.createElement('a');
        save.href = objectUrl;
        save.target = '_blank';
        save.download = reportTitle.replace(/ /g, '-').toLowerCase();
        let event = document.createEvent('MouseEvents');
        event.initMouseEvent(
          'click', true, false, window, 0, 0, 0, 0, 0
          , false, false, false, false, 0, null
        );
        save.dispatchEvent(event);
      });
  }

  /* Misc */
  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/quotes']);
    }
  }

  ngAfterViewInit(): void {
    //I had issue with this part when upgrade devextreme on version 23.1.6
//     this.chartComponent.instance.option(this.quotingReportService.chartOptions(this.chartService.getChartColorsAsArray(ChartService.activeChartTheme), this.currencyPipe));
//     this.chartReloadService.registerChart({ id: this.CHART_ID, chart: this.chartComponent });
//
//     setTimeout(() => {
//       this.chartComponent.instance.render();
//     }, 1000);
  }

  ngOnDestroy() {
    this.chartReloadService.clearChart(this.CHART_ID);
  }
}

