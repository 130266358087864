import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxTemplateModule } from 'devextreme-angular';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DisputeService } from './core/dispute.service';
import { DisputeListComponent } from './dispute-list/dispute-list.component';
import { DisputeRoutingModule } from './dispute-routing.module';
import { DisputeComponent } from './dispute.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisputeChargeFilterService } from 'app/dispute/core/dispute-charge-filter.service';
import { DisputeChargesStaticGridService } from 'app/dispute/core/dispute-charges-static-grid.service';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { TagInputModule } from 'ngx-chips';
import { DisputeChargeService } from '../charge/core/dispute-charge.service';
import { InvoiceChargeService } from '../charge/core/invoice-charge.service';
import { CoreModule } from '../core/core.module';
import { DictionaryService } from '../dictionary/core/dictionary.service';
import { InvoiceFacepageService } from '../invoice/core/invoice-facepage.service';
import { DocumentService } from '../shared/document/core/document.service';
import { SharedModule } from '../shared/shared.module';
import { VendorService } from '../vendor/core/vendor.service';
import { DisputeChargesGridService } from './core/dispute-charges-grid.service';
import { DisputeFilterService } from './core/dispute-filter.service';
import { DisputeGridService } from './core/dispute-grid.service';
import { ShortDisputeGridService } from './core/short-dispute-grid.service';
import { DisputeDetailsComponent } from './dispute-details/dispute-details.component';
import { DeleteDisputeChargesManageDialog } from './shared/dispute-delete-charges-manage-dialog/dispute-delete-charges-manage-dialog.component';
import { DeleteDisputeManageDialog } from './shared/dispute-delete-manage-dialog/dispute-delete-manage-dialog.component';
import { DisputeDropdownComponent } from './shared/dispute-dropdown/dispute-dropdown.component';
import { DisputeManageDialogComponent } from './shared/dispute-manage-dialog/dispute-manage-dialog.component';
import { DisputeSneakPeekComponent } from './shared/dispute-sneak-peek/dispute-sneak-peek.component';
import { RevertWithheldManageDialogComponent } from './shared/revert-withheld-manage-dialog/revert-withheld-manage-dialog.component';

@NgModule({
	imports: [
		DisputeRoutingModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		FlexLayoutModule,
		DxButtonModule,
		DxDataGridModule,
		DxTemplateModule,
		SharedModule,
		TagInputModule,
		CoreModule
	],
	exports: [],
	declarations: [
		DisputeComponent,
		DisputeListComponent,
		DisputeManageDialogComponent,
		DisputeSneakPeekComponent,
		DisputeDetailsComponent,
		RevertWithheldManageDialogComponent,
		DeleteDisputeManageDialog,
		DeleteDisputeChargesManageDialog,
		DisputeDropdownComponent
	],
	providers: [
		DisputeService,
		DisputeGridService,
		DisputeChargesGridService,
		DisputeFilterService,
		InvoiceChargeService,
		DictionaryService,
		InvoiceFacepageService,
		VendorService,
		DisputeChargeService,
		DocumentService,
		DisputeChargesStaticGridService,
		DisputeChargeFilterService,
		ShortDisputeGridService
	]
})
export class DisputeModule {}
