<app-invoice-details-toolbar
	(toggleNotesRequested)="toggleSider(SECTIONS.NOTES_SECTION_NAME)"
	[isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
	(toggleHistoryRequested)="toggleSider(SECTIONS.HISTORY_SECTION_NAME)"
	[isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"
	[invoice]="invoice"
	[isCSVDisabled]="false"
	[isDoNotProcessDisabled]="true"
	[isAddDisputeHidden]="true"
	(downloadCSVRequested)="onDownloadCSVRequested()"
	(openGridSettingsRequested)="onOpenGridSettingsRequested()"
	[isDoNotProcessHidden]="true"
	[isDocumentTypeHidden]="true"
	[isOnHoldDisabled]="true"
	[isGLChargeViewSwitchHidden]="true"
	[isAddDirectGLHidden]="true"
	[isEditDirectGLHidden]="true"
	[isGridSettingsDisabled]="false"
	[isFilterDisabled]="true"
	[isFilterHidden]="false"
	[isDropDownFileHidden]="false"
	[isDropDownCsvHidden]="false"
	[isDropDownCsvDisabled]="false"
	[isDropDownUploadDocumentDisabled]="true"
	[isDownloadContainerHidden]="true"
	[isDownloadPDFHidden]="true"
	[isCSVHidden]="true"
	[notesCount]="notesCount"
	(backRequested)="back()"
></app-invoice-details-toolbar>

<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
	<app-invoice-details-tabs
		[activeTabIndex]="2"
		[invoice]="invoice"
	></app-invoice-details-tabs>
	<div
		class="app-content app-content-list tabbed-content tabbed-content-no-scroll-except-first-ch"
		style="top: 0"
	>
		<!-- Tabs Body -->
		<div class="tabs-body tabs-disable-scroll">
			<dx-data-grid
				class="app-grid-with-pager"
				#disputesGrid
				(onRowClick)="onDisputesGridRowClick($event)"
				(onCellClick)="onDisputesCellClick($event)"
				[columns]="disputesColumns"
				[dataSource]="disputes"
				[height]="'100%'"
				style="display: block"
			>
				<div *dxTemplate="let d of 'withheldCellTemplate'">
					<div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
				</div>
				<div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
					<div caGridTooltip class="right">
						{{ d.value | caCurrency: invoice?.currency }}
					</div>
				</div>
				<div *dxTemplate="let d of 'dateTimeTemplate'">
					<div caGridTooltip class="right">{{ d.value | caDateTime }}</div>
				</div>
				<div *dxTemplate="let d of 'subclientTemplate'">
					<div caGridTooltip class="left">
						{{
							d.value.length && d.value.length > 1
								? 'Multiple'
								: d.value[0]
									? d.value[0].value
									: ''
						}}
					</div>
				</div>
			</dx-data-grid>
			<ca-pager
				class="app-pager ca-default-border-top"
				[gridPager]="defaultGridPager"
				[pageContext]="disputeGridService"
				[query]="disputeQuery"
				(onChange)="onPageDisputeChange()"
			></ca-pager>
		</div>
	</div>
	<mat-sidenav #panelSide [mode]="'side'" position="end" [opened]="false">
		<ca-history
			#history
			[entityId]="invoice?.invoice_id"
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }"
			[entityType]="'invoice'"
			[invoice]="invoice"
		></ca-history>
		<ca-notes
			#testNotes
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
			[entityId]="invoice?.invoice_id"
			[entityType]="SYSTEM_MODULE.INVOICE"
			(countChanged)="onNotesCountChanged($event)"
			(notesCountChanged)="getDNPInfo($event)"
		>
			<ca-account-notes
				[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
				[entityId]="invoice?.account?.id"
				[entityType]="SYSTEM_MODULE.ACCOUNT"
				[entityName]="'Account'"
				[enableReply]="false"
			></ca-account-notes>
		</ca-notes>
	</mat-sidenav>
</mat-sidenav-container>
