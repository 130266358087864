<div class="mat-dialog-top-spacer"></div>
<div mat-dialog-content class="ca-dialog-content">
  <p>
    {{ message }}
    Are you sure you want to proceed?
  </p>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space"></div>

  <button mat-button type="submit" (click)="submitValueAndClose(false)">Cancel</button>

  <button
    mat-button
    class="previous"
    [disabled]="false"
    type="button"
    (click)="submitValueAndClose(true)"
  >
    Continue
  </button>
</mat-dialog-actions>
