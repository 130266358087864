import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {PermissionService} from "../../permissions/core/permission.service";
import {switchMap, tap} from "rxjs/operators";
import {LoadPermissions, SetPermissions} from "../../permissions/permission.acctions";
import {
  LoadUser, LoadUserSettings,
  SetUser, SetUserSettings
} from '../../user/state/user.actions';
import {Store} from "@ngxs/store";
import {LoadConfig, SetConfig} from "../config/config.actions";

import {LoadInvoiceStatuses, PatchInvoiceStatuses} from "../../invoice/state/invoice.actions"

@Injectable()
export class MasterGuardService implements CanActivate {
  constructor(public permissionService: PermissionService,
              public router: Router,
              private store: Store
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.store.dispatch([
      new SetPermissions({permissions: [], resources: []}),
      new SetUser({user: null}),
      new SetUserSettings({settings: null}),
      new SetConfig({config: null}),
      new PatchInvoiceStatuses({statuses: []}),
    ]).pipe(
      switchMap(() => this.store.dispatch(new LoadPermissions())),
      switchMap(() => this.store.dispatch(new LoadUser())),
      switchMap(() => this.store.dispatch(new LoadUserSettings())),
      switchMap(() => this.store.dispatch(new LoadConfig())),
      switchMap(() => this.store.dispatch(new LoadInvoiceStatuses())),
      // tap(() => console.log('MasterGuard completed'))
    ).toPromise().then(() => true);
  }
}
