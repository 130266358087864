// TODO put this filte to proper folder
import { QueryParamOperator } from '../query/query-param-operator';
// import { fields } from 'projects/web/src/app/common/rule-builder/constants';

const dxGroupOperators = ['!', 'and', 'or'];

// TODO test all operators
const dxFilterOperatorsToRegular = {
	'!': QueryParamOperator.Not,
	'=': QueryParamOperator.ILike,
	'<>': QueryParamOperator.NotEqual,
	'>': QueryParamOperator.GreaterThan,
	'>=': QueryParamOperator.GreaterThanOrEqual,
	'<': QueryParamOperator.LessThan,
	'<=': QueryParamOperator.LessThanOrEqual,
	'startswith': '$startswith',
	'endswith': '$endswith',
	'contains': '$contains',
	'notcontains': '$notcontains',
	'and': QueryParamOperator.And,
	'or': QueryParamOperator.Or,
	'in': QueryParamOperator.In,
	'notIn': QueryParamOperator.NotIn
};

export class FilterConverterHelper {
	static findOperation(value) {
		// if all members are strings, then this array does not contain any operators
		if (!Array.isArray(value) || value.filter((el) => Array.isArray(el)).length == 0) return null;

		// if all there is some member that is group operator, return its value
		const operation = dxGroupOperators.filter((op) => value.includes(op));
		return operation.length > 0 ? operation[0] : null;
	}

	static makeQueryField(value) {
		let operation = {},
			field = {};
		if ((typeof value[2] === 'number' || value[2] === null) && value[1] === '=') {
			operation[QueryParamOperator.Equal] = value[2];
			field[value[0]] = operation;
			return field;
		}

		if (typeof value[2] === 'boolean') {
			switch (value[1]) {
				case '=':
					operation[QueryParamOperator.Equal] = value[2];
					field[value[0]] = operation;
					return field;
				case '<>':
					operation[QueryParamOperator.NotEqual] = value[2];
					field[value[0]] = operation;
					return field;
				default:
					throw new Error('Invalid operator for boolean value');
					break;
			}
		}

		switch (value[1]) {
			case 'startswith':
				field[value[0]] = { $ilike: value[2] + '%' };
				break;
			case 'endswith':
				field[value[0]] = { $ilike: '%' + value[2] };
				break;
			case 'notcontains':
				field[value[0]] = { $notILike: '%' + value[2] + '%' };
				break;
			case 'contains':
				field[value[0]] = { $ilike: '%' + value[2] + '%' };
				break;
			default:
				operation[dxFilterOperatorsToRegular[value[1]]] = value[2];
				field[value[0]] = operation;
		}

		return field;
	}

	static dxFilterFormatToStandard = (value) => {
		const operation = FilterConverterHelper.findOperation(value);
		// if value array contains group operator "and", "or" etc. make array of conditions conected with that operator,

		if (operation) {
			let simpleValue = {};
			simpleValue[dxFilterOperatorsToRegular[operation]] = FilterConverterHelper.dxFilterFormatToStandard(value.filter((val) => val != operation));
			return simpleValue;
		} else {
			let complexValue = [];
			const first = value[0];
			if (typeof first === 'string') value = [value];
			value.forEach((element) => {
				const operation2 = FilterConverterHelper.findOperation(element);
				let field = {};
				if (operation2) {
					// this is set contains group operator, make subcondition recursively
					field[dxFilterOperatorsToRegular[operation2]] = FilterConverterHelper.dxFilterFormatToStandard(element.filter((val) => val != operation2));
				} else {
					// this is just condition, add it to array
					field = FilterConverterHelper.makeQueryField(element);
				}
				complexValue.push(field);
			});
			return Array.isArray(complexValue) && complexValue.length === 1 ? complexValue[0] : complexValue;
		}
	};

	static makeDxField(value) {
		const key = Object.keys(value)[0];
		let operation = Object.keys(value[key])[0];
		const val = value[key][Object.keys(value[key])[0]];

		switch (operation.toLocaleLowerCase()) {
			case QueryParamOperator.ILike.toLocaleLowerCase():
				if (val && val[0] == '%' && val[val.length - 1] == '%') return [key, 'contains', val.substr(1, val.length - 2)];
				if (val && val[0] != '%' && val[val.length - 1] == '%') return [key, 'startswith', val.substr(0, val.length - 1)];
				if (val && val[0] == '%' && val[val.length - 1] != '%') return [key, 'endswith', val.substr(1, val.length - 1)];
				if (val && val[0] != '%' && val[val.length - 1] != '%') return [key, '=', val];
				break;

			case QueryParamOperator.NotILike.toLocaleLowerCase():
				if (val && val[0] == '%' && val[val.length - 1] == '%') return [key, 'notcontains', val.substr(1, val.length - 2)];
				break;
			case QueryParamOperator.Equal.toLocaleLowerCase():
				return [key, '=', val];
				break;

			case QueryParamOperator.In.toLocaleLowerCase():
				return [key, 'in', val];
				break;

			case QueryParamOperator.NotIn.toLocaleLowerCase():
				return [key, 'notIn', val];
				break;
		}

		Object.keys(dxFilterOperatorsToRegular).forEach((k) => {
			if (dxFilterOperatorsToRegular[k].toLocaleLowerCase() == operation.toLocaleLowerCase()) {
				operation = k;
			}
		});

		return [key, operation, val];
	}

	static standardFilterFormatToDx = (value, groupOperator?) => {
		let dxValue = [];
		if (Array.isArray(value)) {
			if (groupOperator) {
				value.forEach((field) => {
					dxValue.push(FilterConverterHelper.standardFilterFormatToDx(field));
					dxValue.push(groupOperator);
				});

				dxValue.length = dxValue.length - 1;
			}
		} else {
			let key = Object.keys(value)[0];
			if (dxGroupOperators.indexOf(key.substr(1)) > -1) {
				// group operator
				return FilterConverterHelper.standardFilterFormatToDx(value[key], key.substr(1));
			} else {
				// field
				return FilterConverterHelper.makeDxField(value);
			}
		}

		return dxValue;
	};

	// this function will populate empty field in dx form format with values provided in fieldValues object
	static populateEmptyFields = (dxFilter, fieldValues) => {
		if (!Array.isArray(dxFilter)) return dxFilter;

		if (dxFilter.filter(Array.isArray).length == 0) {
			if (dxFilter.length == 3 && dxFilter[2] == '' && fieldValues[dxFilter[0]]) {
				dxFilter[2] = fieldValues[dxFilter[0]];
			}
			return dxFilter;
		}

		const ret = dxFilter.map((f) => {
			return FilterConverterHelper.populateEmptyFields(f, fieldValues);
		});
		return ret;
	};
}

/**
 // this is format that dx filter builder provides

 [["chg_code_1","=","c1"],"and",["chg_class","<>","disc"],"and",[["chg_code_1","startswith","33"],"or",["chg_class","<>","occ"]]]
 */

/**
 // this is format that API expect

 value: {
    $and: [
        {chg_class: {$iLike: "mrc"}},
        {chg_desc_1: {$iLike: "%LNP (Jan 2019 Usage)%"}},
        {
            $or: [
                {superusoc_segment_1: {$iLike: "ACCD"}},
                {charge_category: {$iLike: "ACCO"}}
            ]
        }
}

 // this are other values needed to save rule
 {
segment_id: 9841,
set_id: 170,
value: {$and: [{chg_class: {$iLike: "mrc"}}, {chg_desc_1: {$iLike: "%LNP (Jan 2019 Usage)%"}}]},
$and: [{chg_class: {$iLike: "mrc"}}, {chg_desc_1: {$iLike: "%LNP (Jan 2019 Usage)%"}}],
vendor_id: 2,
}



 value: {$and: [{chg_class: {$in: ["mrc", "occ"]}}, {$and: [{chg_desc_1: {$iLike: "admin%"}}]}]}
 $and: [{chg_class: {$in: ["mrc", "occ"]}}, {$and: [{chg_desc_1: {$iLike: "admin%"}}]}]

 */
