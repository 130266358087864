import {Observable} from 'rxjs';

import {SiteService} from './site.service';
import {IFilterLookupProvider} from '../../core/filter/filter-lookup.provider';
import Query from '../../core/query/query';
import {map} from "rxjs";

export class SiteLookupProvider extends IFilterLookupProvider {
  constructor(public siteService: SiteService,
            public secondRowField?: boolean) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery ? searchQuery : new Query();
    query.orderBy = [['site_id', 'ASC']];

    return this.siteService.findAllList(searchQuery).pipe(
      map((result: any) => {
        return {
          items: result.items.map((entry) => {
            return this.secondRowField ? {
              first_row: entry.site_id,
              second_row: entry.building.address.search_address
            } : entry.site_id;
          }), total: result.total
        };
      }));
  }
}
