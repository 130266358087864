import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {of as observableOf} from 'rxjs';
import { mergeMap } from 'rxjs';
import {ActivatedRoute} from "@angular/router";

import { SortingService, SortingBuilder } from '../../../shared/sorting/sorting.service';
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {AlertService} from '../../../shared/alert/alert.service';

import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {DialogService} from '../../../shared/dialog/dialog.service';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {GridSettingsComponent} from "../../../core/grid/grid-settings.component";
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";

import {ContractService} from '../../core/contract.service';
import {Contract} from '../../core/contract';
import {ContractScheduleTermConditionManageDialogComponent} from "../../shared/contract-schedule-term-condition-manage-dialog/contract-schedule-term-condition-manage-dialog.component";
import {ContractSection} from '../../core/contract-section';
import {ContractTermAndConditionQuery} from "../../core/contract-term-and-condition.query";
import {ContractTermsConditions} from "../../core/contract-terms-conditions";
import {ContractScheduleDetailTermConditionGridService} from "../../core/contract-schedule-detail-term-condition-grid.service";
import {ContractScheduleDetailTermAndConditionFilterService} from "../../core/contract-schedule-detail-term-and-condition-filter.service";

@Component({
    selector: 'app-contract-schedule-terms-conditions',
    templateUrl: './contract-schedule-terms-conditions.component.html',
    styleUrls: ['./contract-schedule-terms-conditions.component.scss']
  })
  export class ContractScheduleTermsConditionsComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

    @ViewChild('panelSide') panelSide;
    @ViewChild('termsAndConditionGrid') termsAndConditionGrid: DxDataGridComponent;

    messages: IMessagesResourceService;
    coreMessages: IMessagesResourceService;
    readonly MESSAGES_MODULE: string = 'schedule';
    readonly CORE_MODULE: string = 'core';
    readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

    public schedule: any;
    queryTermAndCondition: ContractTermAndConditionQuery = new ContractTermAndConditionQuery({});
    public termsConditions: Array<ContractTermsConditions>;
    public termConditionColumns: Array<any>;

    public termConditionColumnsSorting: any[];
    isGridSettingsDisabled = true;
    public termsConditionSorting: SortingBuilder;
    public selection: any;

    public notesCount: number = 0;

    constructor(
        public contractService: ContractService,
        private readonly route: ActivatedRoute,
        public settingsService: UserSettingsService,
        public contractScheduleDetailTermConditionGridService: ContractScheduleDetailTermConditionGridService,
        public contractScheduleDetailTermAndConditionFilterService: ContractScheduleDetailTermAndConditionFilterService,
        public sortingService: SortingService,
        public dialog: DialogService,
        public alert: AlertService,
    ) {
    super(new PageContext({
        name: 'app.contract.contract-schedule-terms-conditions',
        settings: settingsService
    }));
    this.termsConditionSorting = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);

  }

  ngOnInit(): void {
      this.schedule = this.route.parent.snapshot.data.schedule;

      this.contractScheduleDetailTermConditionGridService.loadSettings()
        .subscribe((settings) => {
          this.initTermsAndConditionsColumns(settings);
        });
  }

  initTermsAndConditionsColumns(settings?) {
    this.termConditionColumnsSorting = this.contractScheduleDetailTermConditionGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
    this.termConditionColumns = this.contractScheduleDetailTermConditionGridService.getColumns(settings && settings.columns ? settings.columns : [])

    if (this.termConditionColumnsSorting.length) {
      this.queryTermAndCondition.orderBy = this.termConditionColumnsSorting;
    }

    if (this.termsAndConditionGrid && this.termsAndConditionGrid.instance) {
      this.termsAndConditionGrid.instance.option('columns', this.termConditionColumns);
    }
    this.loadTermsAndConditions();
  }

  loadTermsAndConditions(event?: ContractTermAndConditionQuery){
    if(event){
      this.queryTermAndCondition = new ContractTermAndConditionQuery({...event});
    }
    this.queryTermAndCondition.where['contract_schedule_id'] = this.schedule.id;
    this.contractService.findAllTermsAndConditions(this.queryTermAndCondition)
      .subscribe((result) =>{
        this.termsConditions = result.items;
        this.queryTermAndCondition.total = result.total;
      })
  }

  addNewTC(){
    const {contract_shell:{name}} =this.schedule;
    this.dialog.open(ContractScheduleTermConditionManageDialogComponent, {
      contract: new Contract({id:this.schedule.contract_schedule_id, contract_schedule_id: this.schedule.id, name}),
      disableFields:{contractSchedule: true},
    }, {width: '600px'})
      .afterClosed()
      .subscribe(result => {
          if (result) {
            this.loadTermsAndConditions();
            this.alert.success('', this.messages.get('TERMS_CONDITION_SCHEDULE_SUCCESS'));
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('TERMS_CONDITION_SCHEDULE_ERROR'));
        });
  }

  editTC(){
    const {contract_shell:{name}} =this.schedule;
    this.dialog.edit(
      ContractScheduleTermConditionManageDialogComponent,
      {
        termsAndCondition: this.contractService.findTCForEdit(this.selection.id),
        isUpdate: true,
        contract: new Contract({id:this.schedule.contract_shell_id, name}),
        disableFields:{contractSchedule: true}

      }, {width: '600px'})
      .subscribe((items: any) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(mergeMap(x => x.afterClosed()))
            .subscribe((result: any) => {
              if (result) {
                this.alert.success('', this.messages.get('TERMS_CONDITION_SCHEDULE_UPDATE_SUCCESS'));

                this.loadTermsAndConditions();
                if (result.cancelByTimer || result.status == 2) {
                  return this.alert.success('', this.coreMessages.get('TIMER_EXPIRED'));
                }
              }
            })
        }
      })
  }

  deleteTC() {
    let termsConditionId = this.selection.id

    this.dialog.confirm({
      bodyText: `Are you sure sure you want to delete Terms And Conditions?`
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if(result){
            this.alert.error('', this.messages.get('TERMS_CONDITION_SCHEDULE_DELETE_SUCCESS'));
            this.contractService.deleteTC(termsConditionId)
              .subscribe(() => {
                this.loadTermsAndConditions();
              })
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('TERMS_CONDITION_SCHEDULE_DELETE_ERROR'));
        }
      );
  }


  termsAndConditionsGridSettings() {
    this.dialog
      .open(GridSettingsComponent, {
        service: this.contractScheduleDetailTermConditionGridService,
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initTermsAndConditionsColumns(settings);
        }
      });
  }

  keyAndTerminationCsv() {
    const { contractScheduleDetailTermConditionGridService, contractService, queryTermAndCondition: query } = this;
    contractScheduleDetailTermConditionGridService.csvMap().subscribe(fields => {
      contractService.exportToCSV(
        'schedule_t&c',
        {
          fields,
          query,
          fetchDataHandler: (query) => { return contractService.findAllTermsAndConditions(query) }
        })
    });
  }

  public onTermsAndConditionCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.termsConditionSorting.apply(event, this.queryTermAndCondition);
      this.loadTermsAndConditions(this.queryTermAndCondition);
    }
  }

  onSelectionChanged(row) {
    this.selection = <ContractSection>row.selectedRowsData[0];
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  toggleFilter() {
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME);
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }

  clearTermsAndConditionFilter(query: any) {
    this.loadTermsAndConditions(query);
  }
}
