import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import {
  IMessagesResourceService,
  ResourcesService,
} from "app/core/resources/resources.service";
import * as moment from "moment";
import { of as observableOf } from "rxjs";

import { PageManageDialogComponent } from "../../../core/page-manage-dialog.component";
import { LOOKUP_MODELS_ENUM } from "../../../dictionary/core/lookup-models.enum";
import { LOOKUP_ENUM } from "../../../dictionary/core/lookup.enum";
import { AlertService } from "../../../shared/alert/alert.service";
import { DialogService } from "../../../shared/dialog/dialog.service";
import { DocumentService } from "../../../shared/document/core/document.service";
import { LoaderService } from "../../../shared/loader/loader.service";
import { Contract } from "../../core/contract";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from "rxjs";
import Query from "../../../core/query/query";
import { LocationService } from "../../../location/core/location.service";
import { ContractInstance } from "../../core/contract-instance";
import { ContractInstanceRateService } from "../../core/contract-instance-rate.service";
import { ContractInstanceService } from "../../core/contract-instance.service";
import { RateService } from "../../core/contract-rate.service";
import { ContractSchedule } from "../../core/contract-schedule";
import { ContractService } from "../../core/contract.service";

const SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

@Component({
  selector: "contract-schedule-manage-dialog.component",
  templateUrl: "./contract-schedule-manage-dialog.component.html",
  styleUrls: ["./contract-schedule-manage-dialog.component.scss"],
})
export class ContractScheduleManageDialogComponent
  extends PageManageDialogComponent
  implements OnInit
{
  contract: Contract;
  contractInstance: ContractInstance;
  contractSchedule: ContractSchedule;
  minDate;
  maxDate;
  scheduleUnique = true;
  listOfSchedules;
  labels: Array<any> = [];
  now = Date.now();
  today = new Date();
  shown = false;

  contractScheduleQuery = new Query();
  isUpdate: boolean = false;
  showDynamicForm: object = {
    revenue_commitment: false,
  };
  selectedCurrency: any;
  exchangeRateValue: any;
  termDisabled = false;
  messages: IMessagesResourceService;

  readonly CONTRACT_TYPE_LOOKUP_MODEL: string =
    LOOKUP_MODELS_ENUM.CONTRACT_TYPE.modelName;
  readonly CONTRACT_SCHEDULE_SERIES: string =
    LOOKUP_MODELS_ENUM.CONTRACT_SCHEDULE_SERIES.modelName;
  readonly CONTRACT_SCHEDULE_COMMITMENT_TYPE: string =
    LOOKUP_MODELS_ENUM.CONTRACT_SCHEDULE_COMMITMENT_TYPE.modelName;
  readonly SYSTEM_SCHEDULE_SERIES: object = LOOKUP_ENUM.SYSTEM_SCHEDULE_SERIES;
  readonly COMMITMENT_TYPE = LOOKUP_ENUM.CONTRACT_SCHEDULE_COMMITMENT_TYPE;
  readonly MESSAGES_MODULE: string = "schedule";

  readonly AT_EXP_EVENT: string =
    LOOKUP_MODELS_ENUM.AT_EXP_EVENT.modelName;

  public vendorPickerDisabled: boolean;

  constructor(
    public dialog: DialogService,
    public formBuilder: FormBuilder,
    public alert: AlertService,
    public contractInstanceService: ContractInstanceService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<ContractScheduleManageDialogComponent>,
    public loaderService: LoaderService,
    public documentService: DocumentService,
    public rateService: RateService,
    public contractInstanceRateService: ContractInstanceRateService,
    public contractService: ContractService,
    public locationService: LocationService
  ) {
    super(dialogService, dialogRef);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    if (
      this.contractSchedule &&
      this.contractSchedule.id &&
      this.contractSchedule.contract_schedule_commitment
    ) {
      if (this.contractSchedule.contract_schedule_commitment.currency) {
        this.locationService
          .findCurrencyById(
            this.contractSchedule.contract_schedule_commitment.currency.id
          )
          .subscribe((res) => {
            this.selectedCurrency = res.currency;
          });
      }
    }

    if (this.contract && this.contract.id) {
      this.contractScheduleQuery.where["contract_shell_id"] = this.contract.id;
    }

    this.minEffectiveDate(this.contractSchedule.effective_date);
    this.maxTerminationDate(this.contractSchedule.termination_date);

    this.form = this.formBuilder.group({
      at_exp_event_id: new FormControl(
        this.contractSchedule.at_exp_event_id
      ),
      cancel_notice_pd: new FormControl(this.contractSchedule.cancel_notice_pd, [
        Validators.pattern("^[0-9]*$"),
      ]),
      cancel_notice_dt: new FormControl(this.contractSchedule.cancel_notice_dt),

      schedule_series_id: new FormControl(
        this.contractSchedule.schedule_series_id
      ),
      contains_rates: new FormControl(
        this.contractSchedule.contains_rates || false
      ),
      schedule_name: new FormControl(this.contractSchedule.schedule_name),
      prior_schedule_id: new FormControl(
        this.contractSchedule.prior_schedule_id
      ),
      customer_sign_date: new FormControl(
        this.contractSchedule.customer_sign_date
      ),
      vendor_sign_date: new FormControl(this.contractSchedule.vendor_sign_date),
      effective_date: new FormControl(this.contractSchedule.effective_date),
      termination_date: new FormControl(this.contractSchedule.termination_date),
      rate_term: new FormControl(this.contractSchedule.rate_term, [
        Validators.pattern("^[0-9]*$"),
      ]),
      schedule_description: new FormControl(
        this.contractSchedule.schedule_description
      ),
    });

    this.hasAnySchedule("schedule_name").subscribe((result) => {
      this.scheduleUnique = result;
    });

    this.form.controls.cancel_notice_pd.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((cancelNoticePd) => {
        if (cancelNoticePd && this.form.controls.termination_date.value) {
          const calculatedDate = moment(this.form.controls.termination_date.value).subtract(cancelNoticePd, 'days')
          this.form.controls.cancel_notice_dt.setValue(calculatedDate.format('YYYY-MM-DD'))
        } else {
          this.form.controls.cancel_notice_dt.reset()
        }
      });

    this.form.controls.schedule_series_id.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {
        if(!this.isUpdate) {
          this.form.controls.contains_rates.reset(false);
        }
        if (this.form.controls.prior_schedule_id.touched) {
          this.form.controls.prior_schedule_id.reset(null);
        }
      });
    this.form.controls.effective_date.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((effDate) => {
        this.minEffectiveDate(effDate);
        if (this.form.get("commitmentForm")) {
          this.form.get("commitmentForm").get("effective_date").value <
            effDate &&
            this.form
              .get("commitmentForm")
              .get("effective_date")
              .setValue(null);
          this.form.get("commitmentForm").get("termination_date").value <
            effDate &&
            this.form
              .get("commitmentForm")
              .get("termination_date")
              .setValue(null);
        }
      });
    this.form.controls.termination_date.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((termDate) => {
        this.maxTerminationDate(termDate);
        if (this.form.get("commitmentForm")) {
          this.form.get("commitmentForm").get("effective_date").value >
            termDate &&
            this.form
              .get("commitmentForm")
              .get("effective_date")
              .setValue(null);
          this.form.get("commitmentForm").get("termination_date").value >
            termDate &&
            this.form
              .get("commitmentForm")
              .get("termination_date")
              .setValue(null);
        }

        if (this.form.controls.cancel_notice_pd.value && this.form.controls.termination_date.value) {
          const calculatedDate = moment(this.form.controls.termination_date.value).subtract(this.form.controls.cancel_notice_pd.value, 'days')
          this.form.controls.cancel_notice_dt.setValue(calculatedDate.format('YYYY-MM-DD'));
        } else {
          this.form.controls.cancel_notice_dt.reset();
        }
      });

    this.showDynamicPartOfForm();
    this.afterInit();
  }

  hasAnySchedule(field) {
    return this.form.controls[field].valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((value) => {
        if (this.contractSchedule.schedule_name !== value) {
          if (value && !this.form.controls[field].hasError(field)) {
            const query = new Query({ limit: 1 });
            query.set("schedule_name", { $ilike: value });
            query.set("contract_shell_id", this.contract.id);
            return this.contractService.findSchedules(query);
          }
        }
        return observableOf({ total: 0 });
      }),
      map((result: any) => !!!result.total)
    );
  }

  //This part of code need improvement.
  getCommitPerTerm() {
    let termValue = this.form.get("commitmentForm").get("term").value || 0;
    let commit_per_term =
      this.form.get("commitmentForm").get("commit_per_term").value || 0;
    let commit_total = termValue * commit_per_term;
    this.form
      .get("commitmentForm")
      .get("commit_total")
      .setValue(commit_total.toString());
  }
  getTerm() {
    let termValue = this.form.get("commitmentForm").get("term").value || 0;
    let commit_per_term =
      this.form.get("commitmentForm").get("commit_per_term").value || 0;
    let commit_total = termValue * commit_per_term;
    this.form
      .get("commitmentForm")
      .get("commit_total")
      .setValue(commit_total.toString());
  }

  getTermType() {
    let termType = this.form
      .get("commitmentForm")
      .get("commit_term_type_id").value;
    if (termType === this.COMMITMENT_TYPE.TERM) {
      let term = 1;
      this.form.get("commitmentForm").get("term").setValue(term.toString());
      this.termDisabled = true;
      this.form.get("commitmentForm").get("term").disable();
    } else {
      this.form.get("commitmentForm").get("term").enable();
    }
  }

  init() {
    return observableOf(this.contractInstance);
  }

  onSubmit({ value, valid }: { value: ContractSchedule; valid: boolean }) {
    if (valid) {
      if (this.form.get("commitmentForm")) {
        value["commitmentForm"].term = this.form
          .get("commitmentForm")
          .get("term").value;
      }
      let spreadPayload = { ...value, id: this.contractSchedule.id };

      if (
        spreadPayload["commitmentForm"] &&
        this.contractSchedule["contract_schedule_commitment"] &&
        this.contractSchedule["contract_schedule_commitment"].id
      ) {
        spreadPayload["commitmentForm"].id =
          this.contractSchedule["contract_schedule_commitment"].id;
      }

      if (
        spreadPayload["commitmentForm"] &&
        spreadPayload["commitmentForm"].commit_per_term === ""
      ) {
        spreadPayload["commitmentForm"].commit_per_term = null;
      }

      this.isUpdate ? this.update(spreadPayload) : this.create(value);
    }
  }

  create(contractSchedule: ContractSchedule) {
    // protection of creation of double instances on fast Save button clicking
    contractSchedule.contract_shell_id = this.contract.id;
    if (!contractSchedule.prior_schedule_id) {
      contractSchedule.prior_schedule_id = 0;
    }
    this.contractService
      .createSchedule(this.contract.id, contractSchedule)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (err) => {
          this.loaderService.hideLoader();
          this.alert.success("", this.messages.get("CREATE_ERROR"));
        }
      );
  }

  update(contractSchedule: ContractSchedule) {
    this.contractService
      .updateSchedule(this.contract.id, contractSchedule)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (err) => {
          this.loaderService.hideLoader();
          this.alert.success("", this.messages.get("UPDATE_ERROR"));
        }
      );
  }

  showLabel(date: string) {
    if (this.labels.indexOf(date) > -1) {
      return true;
    }
  }

  // TODO optimize this
  public toggleLabel(date: string) {
    if (this.form.controls[date].value) {
      if (this.labels.indexOf(date) === -1) {
        this.labels.push(date);
      }
    } else {
      if (this.labels.indexOf(date) === -1) {
        this.labels.push(date);
      } else {
        let index = this.labels.indexOf(date);
        this.labels.splice(index, 1);
      }
    }
  }

  public initializeLabels() {
    let dates = [
      "company_sign_date",
      "vendor_sign_date",
      "effective_date",
      "termination_date",
    ];
    dates.forEach((item) => {
      if (this.form.controls[item].value) {
        this.labels.push(item);
      }
    });
  }

  cancel() {
    this.closeDialog();
  }

  showDynamicPartOfForm() {
    if (this.isUpdate) {
      this.form.get("schedule_series_id").disable();

      if (
        this.contractSchedule?.contract_schedule_commitment
          ?.schedule_commitment_type?.id == this.COMMITMENT_TYPE.TERM
      ) {
        this.termDisabled = true;
      } else {
        this.termDisabled = false;
      }
    }

    if (
      this.isUpdate &&
      this.SYSTEM_SCHEDULE_SERIES["REVENUE_COMMITMENT"] ===
        this.form.get("schedule_series_id").value
    ) {
      this.form.addControl(
        "commitmentForm",
        new FormGroup({
          commitment_name: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.commitment_name
          ),
          contribute_services: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.contribute_services
          ),
          effective_date: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.effective_date
          ),
          termination_date: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.termination_date
          ),
          commit_total: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.commit_total
          ),
          term: new FormControl(
            {
              value: this.contractSchedule?.contract_schedule_commitment?.term,
              disabled: this.termDisabled,
            },
            Validators.pattern("^[0-9]*$")
          ),
          commit_term_type_id: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.commit_term_type_id
          ),
          commit_per_term: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.commit_per_term
          ),
          currency_id: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.currency_id,
            Validators.required
          ),
          exchange_rate: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.exchange_rate
          ),
          commitment_description: new FormControl(
            this.contractSchedule?.contract_schedule_commitment?.commitment_description
          ),
        })
      );
      this.showDynamicForm["revenue_commitment"] = true;
      this.shown = true;
      return;
    }
    this.form.get("schedule_series_id").valueChanges.subscribe((item) => {
      if (this.SYSTEM_SCHEDULE_SERIES["REVENUE_COMMITMENT"] === item) {
        this.form.addControl(
          "commitmentForm",
          new FormGroup({
            commitment_name: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.commitment_name
            ),
            contribute_services: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.contribute_services
            ),
            effective_date: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.effective_date
            ),
            termination_date: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.termination_date
            ),
            commit_total: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.commit_total
            ),
            term: new FormControl(
              {
                value:
                  this.contractSchedule?.contract_schedule_commitment?.term,
                disabled: this.termDisabled,
              },
              Validators.pattern("^[0-9]*$")
            ),
            commit_term_type_id: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.commit_term_type_id
            ),
            commit_per_term: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.commit_per_term
            ),
            currency_id: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.currency_id,
              Validators.required
            ),
            exchange_rate: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.exchange_rate
            ),
            commitment_description: new FormControl(
              this.contractSchedule?.contract_schedule_commitment?.commitment_description
            ),
          })
        );

        this.showDynamicForm["revenue_commitment"] = true;
        this.shown = true;
        return;
      }
      this.showDynamicForm["revenue_commitment"] = false;
      this.form.removeControl("commitmentForm");
    });
  }

  onCurrencyChange(event) {
    this.selectedCurrency = event.selection.currency;
  }

  minEffectiveDate(date) {
    this.minDate = moment(date);
  }

  maxTerminationDate(date) {
    this.maxDate = moment(date);
  }

  isScheduleContainsRates(event) {
    if (this.isUpdate) {
      this.contractService
        .isScheduleContainsAudit(this.contractSchedule.id)
        .subscribe((result) => {
          if (event && !event.checked && parseInt(result.items.count) > 0) {
            this.alert.error("", "Schedule is used in Rate Audit");
          }
        });
    }
  }
}
