<mat-toolbar color="accent" mat-scroll-shrink>

  <span class="sneak-peek-title">{{ [user?.first_name, ' ', user?.last_name].join('') }}</span>

  <span class="example-fill-remaining-space"></span>
  <button mat-icon-button (click)="closeSide()">
    <ca-icon [key]="'CLOSE'"></ca-icon>
  </button>
</mat-toolbar>

<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard>
    <mat-card-content>

      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="50%" style="padding-right: 10px">
          <ca-avatar

                    class="details-item"
                    [uploadEnabled]="false"
                    [text]="getUserInitials()"
                    [size]="'m'"
                    [source]="user?.avatar"></ca-avatar>
        </div>
        <div fxFlex>

          <div fxLayout="column" class="entry-list">
            <div  fxFlex class="entry-list-item">
              <label>First Name</label>
              <p class="ca-text-ellipsis" style="width: 20ch">
                {{ user?.first_name }}
              </p>
            </div>
            <div  fxFlex class="entry-list-item">
              <label>Last Name</label>
              <p class="ca-text-ellipsis" style="width: 20ch">
                {{ user?.last_name }}
              </p>
            </div>
          </div>

        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div class="entry-list-item">
          <label>Email</label>
          <p>
            {{ user?.email }}
          </p>
        </div>
      </div>


      <div fxLayout="row" class="entry-list">
        <div  fxFlex class="entry-list-item">
          <label>Username</label>
          <p>
            {{ user?.username }}
          </p>
        </div>
        <div  fxFlex class="entry-list-item">
          <label>Phone Number</label>
          <p>
            {{ user?.phone_number || 'N/A' }}
          </p>
        </div>
      </div>


      <div fxLayout="row" class="entry-list">
        <div  fxFlex class="entry-list-item">
          <label>Mobile Number</label>
          <p>
            {{ user?.mobile_number || 'N/A' }}
          </p>
        </div>
        <div  fxFlex class="entry-list-item">
          <label>Status</label>
          <p>
            {{ user?.is_active ? 'Active' : 'Inactive' }}
          </p>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>

<div class="ca-card ca-card-sneak-peek">
  <mat-card caCard>
    <mat-card-content>
      <div fxLayout="column" class="details-item">
        <label fxFlex style="margin-bottom: 10px;">Roles</label>
        <div fxFlex>
          <mat-chip-set>
            <mat-chip-row style="margin-bottom: 5px;" *ngFor="let role of user?.userRoles">{{ role.role.name }}</mat-chip-row>
            <div *ngIf="!user?.userRoles?.length" class="ca-empty-state-container">
              <p class="card-message">No roles assigned for the user</p>
            </div>
          </mat-chip-set>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
