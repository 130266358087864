import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AlertInventoryMrcChargesGridService extends GridService {
	defaultColumns = [
		{
			caption: '',
			headerCellTemplate: 'selectedChargesHeaderTemplate',
			cellTemplate: 'chargeDisputeNoteTemplate',
			width: 40,
			fixed: true,
		},
		{
			caption: 'Charge ID',
			dataField: 'id',
			width: 140,
		},
		{
			caption: 'Charge Description 1',
			dataField: 'chg_desc_1',
			width: 200,
		},
		{
			caption: 'Charge Description 2',
			dataField: 'chg_desc_2',
			width: 200,
		},
		{
			alignment: 'right',
			caption: 'Chg Qty',
			dataField: 'chg_qty1_billed',
			dataType: 'number',
			width: 100,
		},
		{
			caption: 'Charge Rate',
			dataField: 'chg_rate',
			cellTemplate: 'chargeRateTemplate',
			width: 100,
		},
		{
			caption: 'Charge Amount',
			dataField: 'chg_amt',
			cellTemplate: 'invoiceCurrencyTemplate',
			width: 170,
		}
	];

	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.alert.inventory-mrc-charge-grid', userSettings);
	}
}
