import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { InvoiceListComponent } from "./invoice-list/invoice-list.component";
import { InvoiceDetailsComponent } from "./invoice-details/invoice-details.component";
import { SmartConfigResolve } from "../core/config/config.resolve";
import { InvoiceChargesComponent } from "./invoice-details/charges/invoice-charges.component";
import { InvoiceOverviewComponent } from "./invoice-details/overview/invoice-overview.component";
import { InvoiceDisputesComponent } from "./invoice-details/disputes/invoice-disputes.component";
import { InvoiceSubAccountsComponent } from "./invoice-details/sub-accounts/invoice-sub-accounts.component";
import { InvoiceNotesAndContactsComponent } from "./invoice-details/notes-and-contacts/invoice-notes-and-contacts.component";
import { InvoiceGlStringsComponent } from "./invoice-details/gl-strings/invoice-gl-strings.component";
import { DocumentsComponent } from "./invoice-details/documents/documents.component";
import { InvoiceAlertsComponent } from "./invoice-details/alerts/invoice-alerts.component";
import { InvoiceGlChargeDetailsComponent } from "./invoice-details/gl-charge-details/invoice-gl-charge-details.component";
import { InvoiceDetailsResolver } from "./core/invoice-details.resolver";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: ":id/show",
        component: InvoiceDetailsComponent,
        resolve: {
          config: SmartConfigResolve,
          invoice: InvoiceDetailsResolver,
        },
        children: [
          { path: "overview", component: InvoiceOverviewComponent },
          { path: "charges", component: InvoiceChargesComponent },
          { path: "charges-adj", component: InvoiceChargesComponent },
          { path: "disputes", component: InvoiceDisputesComponent },
          { path: "subaccounts", component: InvoiceSubAccountsComponent },
          { path: "alerts", component: InvoiceAlertsComponent },
          {
            path: "notes-and-contacts",
            component: InvoiceNotesAndContactsComponent,
          },
          {
            path: "gl-charge-details",
            component: InvoiceGlChargeDetailsComponent,
          },
          { path: "gl-strings", component: InvoiceGlStringsComponent },
          { path: "documents", component: DocumentsComponent },
          {
            path: "**",
            redirectTo: "overview",
          },
        ],
      },
      {
        path: "",
        component: InvoiceListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class InvoiceRoutingModule {}
