import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges
} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {
  PICKER_COMPONENT_TEMPLATE,
  PickerComponentBase
} from "../../picker/ca-picker-base.component";
import {Observable} from "rxjs";
import {CustomerInventoryService} from "../../../customer-inventory/core/customer-inventory.service";


@Component({
  selector: 'ca-customer-inventory-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerInventoryPickerComponent),
      multi: true
    },
    CustomerInventoryService
  ]
})
export class CustomerInventoryPickerComponent extends PickerComponentBase implements OnInit, OnChanges {


  readonly TEXT_FIELD = 'customer_circuit_id';

  @Input('formControlName') formControlName;
  @Input("initialItem") initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('clearEnabled') clearEnabled: boolean;

  public control;

  constructor(public customerInventoryService: CustomerInventoryService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];
  }

  loadEntries(): Observable<any> {
    this.query.orderBy = [['customer_circuit_id', 'ASC']];
    return this.customerInventoryService.findCustomerInventoryOnly(this.query);
  }

  search(value: string): Observable<any> {
    this.query.where['customer_circuit_id'] = {'$ilike': `%${value}%`};

    return this.customerInventoryService.findCustomerInventoryOnly(this.query);
  }

}
