import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogButtonsController } from '../../../core/dialog-buttons-controller.class';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { AlertService } from '../../../shared/alert/alert.service';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { InvoiceChargeService } from '../../../charge/core/invoice-charge.service';
import { ShortInvoiceChargeGridService } from '../../core/short-invoice-charges-grid.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
	selector: 'app-invoice-create-charge-for-adjustment-dialog',
	templateUrl: './invoice-create-charge-for-adjustment-dialog.component.html',
	styleUrls: ['./invoice-create-charge-for-adjustment-dialog.component.scss']
})
export class InvoiceCreateChargeForAdjustmentDialogComponent extends DialogButtonsController implements OnInit {
	readonly MESSAGES_MODULE: string = 'charge';
	private messages: IMessagesResourceService;
	public title;
	public invoice;
	public chargeForm: FormGroup;
	public MANUAL_ADJUSTMENT_REASON_CODE_MODEL = LOOKUP_MODELS_ENUM.MANUAL_ADJUSTMENT_REASON_CODE.modelName;

	//Create Type
	public selectedCharge: any;
	public columns: Array<any>;
	public chargeEntries: Array<any> = [];
	@ViewChild('chargeGrid') chargeGrid: DxDataGridComponent;
	@ViewChild('formCharge') formCharge: NgForm;
	readonly VALIDATION_CHARGE = {
		REQUIRED_ERROR: 'Field is required'
	};
	public reset = false;
	constructor(
		public dialogRef: MatDialogRef<InvoiceCreateChargeForAdjustmentDialogComponent>,
		public formBuilder: FormBuilder,
		public alert: AlertService,
		public invoiceChargeService: InvoiceChargeService,
		public shortInvoiceChargeGridService: ShortInvoiceChargeGridService
	) {
		super();
		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
	}

	ngOnInit(): void {
		this.columns = this.shortInvoiceChargeGridService.columns();

		this.chargeForm = this.formBuilder.group({
			chg_amt: new FormControl(null, Validators.required),
			beg_chg_date: new FormControl(null),
			end_chg_date: new FormControl(null),
			gl_rule_type_id: new FormControl(null),
			comment: new FormControl(''),
			chg_desc_1: new FormControl('')
		});
	}
	ngAfterViewInit() {
		if (this.chargeGrid) {
			this.shortInvoiceChargeGridService.create(this.chargeGrid.instance, {
				noDataText: this.shortInvoiceChargeGridService.noDataMessage,
				columnMinWidth: 50
			});
		}
	}
	close(result?: any) {
		this.dialogRef.close(result);
	}

	onSubmit(chargeForm: FormGroup) {
		const { account_id, currency_id, invoice_id, vendor_id, acct_level_1, currency } = this.invoice;
		let data = {
			charges: this.chargeEntries.map((charge) => {
				return { ...charge, account_id, currency, currency_id, vendor_id, acct_level_1, invoice_id };
			})
		};

		this.toggleDialogButtons();

		this.invoiceChargeService.createCharge(data).subscribe(
			(data) => {
				this.toggleDialogButtons(false);
				this.close(data);
			},
			({ error }) => {
				this.toggleDialogButtons(false);
				this.alert.error('', `Error on creating: ${error.message}`);
			}
		);
	}

	addCharge({ valid, value }: FormGroup) {
		if (valid) {
			this.chargeEntries = this.chargeEntries.concat([value]);
			this.resetCharge();
		}
	}

	updateCharge(selectedCharge, { value, valid }: FormGroup) {
		const selectedPricingArrayIndex = this.chargeGrid.instance.getRowIndexByKey(selectedCharge);
		this.chargeEntries[selectedPricingArrayIndex] = value;
		this.resetCharge();
	}

	deleteCharge(selectedCharge) {
		const selectedPricingArrayIndex = this.chargeGrid.instance.getRowIndexByKey(selectedCharge);

		this.chargeEntries = this.chargeEntries.filter((item, index) => {
			return selectedPricingArrayIndex !== index;
		});

		this.resetCharge();
	}

	resetCharge() {
		this.formCharge.reset();
		this.formCharge.resetForm();

		Object.keys(this.formCharge.form.controls).forEach((key) => {
			let control = this.formCharge.form.controls[key];
			control.markAsPristine();
			control.markAsUntouched();
			control.setErrors(null);
		});
		this.formCharge.form.controls['chg_amt'].patchValue(undefined);

		this.selectedCharge = null;
		this.refreshChargeGrid();
	}

	refreshChargeGrid() {
		if (this.chargeGrid.instance) {
			this.chargeGrid.instance.clearSelection();
			this.chargeGrid.instance.repaint();
		}
	}

	onSelectionChanged(row) {
		this.selectedCharge = <any>row.selectedRowsData[0];

		if (this.selectedCharge) {
			this.chargeForm.patchValue(this.selectedCharge);
		}
	}
}
