<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-title">{{ (alert?.type?.value || '') + ' Alert: ' + (alert?.rule_name || 'N/A') }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button [disabled]="!(alert && alert.invoice && alert.invoice.invoice_id)" (click)="goToInvoiceDetails()" 
    mat-icon-button matTooltip="View Invoice Details">
    <ca-icon [key]="'VIEW_INVOICE_DETAILS'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Refresh" (click)="refresh()" matTooltip="">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Add Dispute" [disabled]="addDisputeDisabled" 
    *permission="{'action':'Create', 'key':'disputes'}" (click)="addDispute()">
    <ca-icon [key]="'ADD_DISPUTE'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="!tabGroup.isActive(2)" mat-icon-button matTooltip="Download CSV" (click)="csv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button [disabled]="!tabGroup.isActive(2) || isGridSettingsDisabled" mat-icon-button matTooltip="Settings" (click)="gridSettings()">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button [disabled]="chargesSelectionActive || !tabGroup.isActive(2)"
          [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2)}"
          (click)="toggleFilter()" mat-icon-button matTooltip="Filter">
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"
          [disabled]="!selection || selectedCharges?.length > 1 || !tabGroup.isActive(2)"
          (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" mat-icon-button matTooltip="Info">
    <ca-icon [key]="'INFO'"></ca-icon>
  </button>
</mat-toolbar>


<mat-sidenav-container class="app-content tabbed-content">

    <mat-tab-group (selectedIndexChange)="onTabChange($event)">
      <mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs">
      </mat-tab>
    </mat-tab-group>


    <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(1)}" class="tabs-body" fxLayout="column">
      <div class="flex-container ca-card custom-mat-card-container-small" fxFlex="0 0 325px" fxLayout="row">
        <div fxFlex>
          <mat-card fxFlex fxFlexFill caCard>

            <mat-card-header>
              Alert Info
            </mat-card-header>

            <mat-card-content>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Category</label>
                  <p>
                    {{ alert?.category?.value || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Created At</label>
                  <p>
                    {{ (alert?.created_at | caDateTime) || 'N/A' }}
                  </p>
                </div>
              </div>

              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Created By</label>
                  <p>
                    {{ alert?.creator?.full_name || 'System' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Owner</label>
                  <p>
                    {{ alert?.owner?.full_name || PLACEHOLDERS.OWNER_UNASSIGNED }}
                  </p>
                </div>
              </div>

              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Priority</label>
                  <p>
                    {{ alert?.priority.value || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Status</label>
                  <p>
                    {{ alert?.status.value || 'N/A' }}
                  </p>
                </div>
              </div>

              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Last Modified At</label>
                  <p>
                    {{ (alert?.last_modified_at | caDate) || 'N/A' }}
                  </p>
                </div>

                <div fxFlex class="entry-list-item">
                  <label>Closed At</label>
                  <p>
                    {{ (alert?.closed_at | caDate) || 'N/A' }}
                  </p>
                </div>
              </div>


              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Message</label>
                  <p>
                    {{ alert?.message || 'N/A' }}
                  </p>
                </div>
              </div>
            </mat-card-content>

          </mat-card>
        </div>
    </div>
  </div>


  <mat-sidenav-container [ngClass]="{'visibility-hidden': !tabGroup.isActive(2)}" class="tabs-body grid-container grid-with-pager app-content-list dx-nodata-absolute" style="overflow-y: hidden">

        <dx-data-grid #dataGrid class="grid-with-selection-badge-inner"
        [columns]="columns"
        [dataSource]="charges"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onCellClick)="onCellClick($event)">

            <div *dxTemplate="let d of 'selectedChargesHeaderTemplate'" class="grid-selection-badge-container">
            <a class="selection-badge grid-badge"
             matTooltip="Selection"
             (click)="loadSelectedCharges()"
             [ngClass]="{'active':chargesSelectionActive}">
            {{selectedCharges.length}}
            </a>
            </div>

            <div *dxTemplate="let d of 'chargeDisputeTemplate'" style="position: relative;">
              <a [class]="d.data.charge_dispute ? 'icon-danger' : 'disabled'"
               (click)="showDispute(d.data.charge_dispute?.dispute_id)"
               matTooltip="Dispute" class="charge-grid-icons">
              <ca-icon [key]="'DISPUTE'" [innerClass]="'charge-grid-icon'"></ca-icon>
              </a>
            </div>

            <div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
            <div caGridTooltip class="right">{{ d.value | caCurrency:d.data.currency }}</div>
            </div>

            <div *dxTemplate="let d of 'chargeRateTemplate'">
            <div caGridTooltip class="right">{{ d.value | caNumber}}</div>
            </div>
        </dx-data-grid>
      <ca-pager [gridPager]="defaultGridPager" [pageContext]="alertChargeGridService" [query]="query" class="app-pager ca-default-border-top"
                (onChange)="onPageChange(query)"></ca-pager>
    </mat-sidenav-container>


  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter [ngClass]="{'hidden': !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"
                [query]="query"
                [filterService]="alertChargesFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter($event)">
    </new-app-filter>
    <app-alert-charge-sneak-peek [charge]="selection"
                                 (closeRequested)="sider?.close()"
                                 [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}"></app-alert-charge-sneak-peek>
  </mat-sidenav>

</mat-sidenav-container>
