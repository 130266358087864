<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <div fxLayout='row' style="align-items: center;">
    <h1 mat-dialog-title>Set Apportions</h1>
    <span class="example-fill-remaining-space"></span>
    <mat-slide-toggle *ngIf="enabledDynamicAllocationChange" [formControl]="dynamicIncluded" checked='dynamic_included'>
      Dynamic allocation
    </mat-slide-toggle>
    <button matTooltip="Refresh" tabindex="-1" (click)="refresh()" mat-icon-button>
      <ca-icon [key]="'REFRESH'"></ca-icon>
    </button>
    <button mat-icon-button tabindex="-1" (click)="clearApportions()" matTooltip="Clear All Apportions">
      <ca-icon [key]="'GL_STRING_CLEAR_ALL_APPORTIONS'"></ca-icon>
    </button>
    <button matTooltip="Filter" tabindex="-1"
            [ngClass]="{'on': isFilterOpen}"
            (click)="isFilterOpen = !isFilterOpen" mat-icon-button>
      <ca-icon [key]="'FILTER'"></ca-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="ca-dialog-content" style="height: 460px">
  <div fxLayout="row">
    <div class="gl-string-grid-panel app-content-list no-padding-app-content-list app-content-list-no-pager" [ngClass]="{'grid-filter': isFilterOpen}">
      <dx-data-grid #grid [height]="'400px'"
                    [columns]="columns"
                    [dataSource]="glStrings"
                    (onCellClick)="onCellClick($event)" (onCellPrepared)=handleCellPrepared($event)
                    (onRowUpdated)="onSelectedStringsRowEdit($event)" (onRowValidating)="onSelectedStringsRowValidating($event)">
        <dxo-editing mode="cell" [allowUpdating]="true">
        </dxo-editing>

        <i *dxTemplate="let d of 'apportionHeaderTemplate'">
          {{ d.column.caption }}
        </i>

      </dx-data-grid>
    </div>
    <div [ngClass]="{'visible': !isFilterOpen}"  class="filter-width">
      <mat-sidenav-container style="height: 100%" class="app-content-list">
        <mat-sidenav style="height: 100%; right: -300px; width: 300px; visibility: unset !important; display: flex">
          <new-app-filter [query]="query"
                          [filterService]="glStringGridFilterService"
                          (filterRequested)="filterData($event)"
                          (clearFilterRequested)="clearFilter()">
          </new-app-filter>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  </div>

  <div class="srm-load-status" *ngIf="isLoading">
    <p>Loading...</p>
  </div>
  <div class="srm-load-status" *ngIf="!glStrings.length && !isLoading">
    <p>No data</p>
  </div>
  <span class="total-pct-span" style="align-items: center; z-index: 9999;" [ngClass]="{'red-message':!valid}">Total Pct: {{totalApportion}} %</span>
  <ca-pager [pageContext]="gridService" [query]="query" class="pager" (onChange)="loadGLStrings(query)" class="app-pager ca-default-border-top" style="position: relative !important;"></ca-pager>

</mat-dialog-content>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button mat-button #cancelButton type="submit" (click)="close()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="confirm()" [disabled]="!valid || editCell" [ngClass]="{'visibility-confirm':!valid}">
    Confirm
  </button>
</mat-dialog-actions>
