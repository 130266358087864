<app-invoice-details-toolbar
	(toggleFilterRequested)="toggleSider(SECTIONS.FILTER_SECTION_NAME)"
	[isFilterOn]="($activeSiderSection | async) == SECTIONS.FILTER_SECTION_NAME"
	(toggleNotesRequested)="toggleSider(SECTIONS.NOTES_SECTION_NAME)"
	[isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
	(toggleHistoryRequested)="toggleSider(SECTIONS.HISTORY_SECTION_NAME)"
	[isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"
	[isCSVDisabled]="false"
	(downloadCSVRequested)="onDownloadCSVRequested()"
	[isDoNotProcessHidden]="true"
	[isDocumentTypeHidden]="true"
	[isAddDisputeHidden]="true"
	[isOnHoldDisabled]="true"
	[isGLChargeViewSwitchHidden]="true"
	[isAddDirectGLHidden]="true"
	[isEditDirectGLHidden]="true"
	[isGridSettingsDisabled]="false"
	(openGridSettingsRequested)="onOpenGridSettingsRequested()"
	[isFilterDisabled]="false"
	[isDropDownFileHidden]="false"
	[isDropDownCsvHidden]="false"
	[isDropDownCsvDisabled]="false"
	[isDropDownUploadDocumentDisabled]="true"
	[isDownloadContainerHidden]="true"
	[isDownloadPDFHidden]="true"
	[isCSVHidden]="true"
	[notesCount]="notesCount"
	(backRequested)="back()"
></app-invoice-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
	<app-invoice-details-tabs [activeTabIndex]="7" [invoice]="invoice"></app-invoice-details-tabs>
	<div class="app-content app-content-list tabbed-content tabbed-content-no-scroll-except-first-ch" style="top: 0">
		<!-- Tabs Body -->
		<div class="tabs-body tabs-disable-scroll">
			<dx-data-grid
				style="height: 100%"
				class="app-grid-with-pager"
				#glStringsGrid
				[columns]="GL_STRING_DETAIL_TAB_INFO.columns"
				[dataSource]="GL_STRING_DETAIL_TAB_INFO.dataSource"
				[allowColumnResizing]="true"
				[showRowLines]="true"
				[showColumnLines]="false"
				(onCellClick)="onGlStringsCellClick($event)"
			>
				<div *dxTemplate="let d of 'statusTemplate'">
					<div *ngIf="d && d.value" caGridTooltip>
						{{ d.value ? 'Active' : 'Inactive' }}
					</div>
				</div>

				<div *dxTemplate="let d of 'chgAmtCurrencyTemplate'">
					<div caGridTooltip class="left">
						{{ d.value | caCurrency: invoice?.currency }}
					</div>
				</div>

				<div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
					<div caGridTooltip class="right">
						{{ d.value | caCurrency: invoice?.currency }}
					</div>
				</div>
				<div *dxTemplate="let d of 'chargeRateTemplate'">
					<div caGridTooltip class="right">
						{{ d.value | caNumber }}
					</div>
				</div>
				<div *dxTemplate="let d of 'chargeCodedTemplate'">
					<div caGridTooltip>{{ d.value ? 'Coded' : 'Uncoded' }}</div>
				</div>
				<div *dxTemplate="let d of 'dateTemplate'">
					<div caGridTooltip>{{ d.value | caDate }}</div>
				</div>
				<div *dxTemplate="let d of 'dateTimeTemplate'">
					<div caGridTooltip class="right">{{ d.value | caDateTime }}</div>
				</div>
				<div *dxTemplate="let d of 'booleanTemplate'">
					<div caGridTooltip>
						{{ d.value ? 'Missing' : d.value === false ? 'Found' : '' }}
					</div>
				</div>
			</dx-data-grid>
			<div class="grid-pager">
				<ca-pager
					[pageContext]="glRuleExecutionGLStringsGridService"
					[gridPager]="defaultGridPagerGLString"
					[query]="query"
					class="app-pager ca-default-border-top"
					(onChange)="GL_STRING_DETAIL_TAB_INFO.handler(GL_STRING_DETAIL_TAB_INFO.query); changePagerString($event)"
				></ca-pager>
			</div>
		</div>
	</div>
	<mat-sidenav #panelSide [mode]="'side'" position="end" [opened]="false">
		<ca-history #history [entityId]="invoice?.invoice_id" [ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }" [entityType]="'invoice'" [invoice]="invoice"></ca-history>

		<ca-notes
			#testNotes
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
			[entityId]="invoice?.invoice_id"
			[entityType]="SYSTEM_MODULE.INVOICE"
			(countChanged)="onNotesCountChanged($event)"
			(notesCountChanged)="getDNPInfo($event)"
		>
			<ca-account-notes
				[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
				[entityId]="invoice?.account?.id"
				[entityType]="SYSTEM_MODULE.ACCOUNT"
				[entityName]="'Account'"
				[enableReply]="false"
			></ca-account-notes>
		</ca-notes>

		<!--    <gl-filters-->
		<!--      [activeFilter]="activeFilter"-->
		<!--      [stringQuery]="query"-->
		<!--      (stringCleared)="clearFilter()"-->
		<!--      (stringFiltered)="filterData($event)"-->
		<!--      [ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"-->
		<!--    ></gl-filters>-->

		<!--    <app-filter-container [ngClass]="{'hidden': activeFilter !== 'strings'}"-->
		<!--                          [query]="stringQuery"-->
		<!--                          [filterService]="glStringFilterService"-->
		<!--                          (filterRequested)="stringFiltered.emit($event)"-->
		<!--                          (clearFilterRequested)="stringCleared.emit($event)"></app-filter-container>-->

		<app-filter-container
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
			[query]="query"
			[filterService]="glStringFilterService"
			(filterRequested)="filterData($event)"
			(clearFilterRequested)="clearFilter()"
		></app-filter-container>
	</mat-sidenav>
</mat-sidenav-container>
