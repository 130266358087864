import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {takeUntil} from "rxjs";

import {Observable, of, Subject} from "rxjs";
import {Inventory} from "../../core/inventory";
import {InventoryService} from "../../core/inventory.service";
import {INVENTORY_TOPOLOGY_ENUM} from "../../core/inventory-topology.enum";
import {InventoryBillingGridService} from "../../core/inventory-billing-grid.service";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {ChargeQuery} from "../../../charge/core/charge.query";
import {InvoiceChargeService} from "../../../charge/core/invoice-charge.service";
import {SortingBuilder, SortingService} from "../../../shared/sorting/sorting.service";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {Select, Store} from "@ngxs/store";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {InventoryBillingFilterService} from "../../core/inventory-billing-filter.service";
import Query from "../../../core/query/query";
import {QueryBuilder} from "../../../common/query/query.builder";
import {UpdateInventoryBillingActiveSiderSection, UpdateInventoryBillingQuery} from "./state/inventory-billing.actions";
import {LoaderService} from "../../../shared/loader/loader.service";
import {UserService} from "../../../user/core/user.service";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";

@Component({
  selector: 'app-inventory-billing',
  templateUrl: './inventory-billing.component.html',
})
export class InventoryBillingComponent extends PageDetailsComponent  implements OnInit, OnDestroy {
  inventory: Inventory;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public chargesColumns: any;
  public charges: Array<any>;
  public chargeQuery: ChargeQuery = new ChargeQuery();

  private chargesColumnsSorting: any[];
  chargesSorting: SortingBuilder;

  public billingAliases: Array<any> = [];
  permissionDisputeView: boolean;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  readonly COMPONENT_NAME = 'inventory-billing';

  notesCount: number;
  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    NOTES_SECTION_NAME: 'notes',
    HISTORY_SECTION_NAME: 'history'
  };

  gridLimit: number = 20;
  private gridSettings: any;

  @ViewChild('panelSide') panelSide;
  @ViewChild('billingGrid') billingGrid: DxDataGridComponent;
  @Select(state => state.inventory_billing.activeSiderSection) $activeSiderSection: Observable<any>;
  @Select(state => state.inventory_billing.query) $query: Observable<Query>;

  constructor(
    public inventoryService: InventoryService,
    public route: ActivatedRoute,
    public router: Router,
    public billingGridService: InventoryBillingGridService,
    public settingsService: UserSettingsService,
    public chargeService: InvoiceChargeService,
    public sortingService: SortingService,
    public inventoryBillingFilterService: InventoryBillingFilterService,
    private readonly store: Store,
    private queryBuilder: QueryBuilder,
    public loaderService: LoaderService,
    public userService: UserService
  ) {
    super(new PageContext({
      name: 'app.inventory.inventory-billing',
      settings: settingsService
    }))

    this.chargesSorting = this.sortingService.builder();
  }

  loadPermissions() {
    return of({});
  }

  private getInventory() {
    return this.route.parent.snapshot.data.inventory;
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/inventory']);
    }
  }

  ngOnInit(): void {
    this.inventory = this.getInventory();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => this.onQueryParamsChange(params));
    this.$query.pipe(takeUntil(this.destroy$)).subscribe((query) => this.onQueryChange(query));

    this.chargesColumns = this.billingGridService.columns();

    this.billingAliases = [];
    this.inventory.billing_aliases.forEach((alias) => {
      this.billingAliases.push(alias.alias)
    });
    this.billingAliases.push(this.inventory.sp_ckt_id);
  }

  public loadCharges(inventory: Inventory) {
    this.loaderService.displayLoaderAndManageGrid([this.billingGrid]);

    if (!this.billingAliases || !inventory.sp_ckt_id) {
      return;
    }

    this.chargeService.filters(this.chargeQuery)
      .subscribe((result) => {
        this.charges = result.items;
        this.loaderService.hideLoaderAndManageGrid([this.billingGrid]);
        this.chargeQuery.total = result.total;
      }, (err) => {
        this.loaderService.displayLoaderAndManageGrid([this.billingGrid]);
        console.log('chargeService.filters error', err);
      });
  }

  public onChargesCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.chargesSorting.apply(event, this.chargeQuery);
      this.loadCharges(this.inventory);
    }
  }

  showDispute(id: any) {
    // TODO: Add permissions
    //if (id && this.permissionDisputeView) {
      this.router.navigate(['/dispute', id, 'show']);
    //}
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
    this.billingGridService.create(this.billingGrid.instance, {
      noDataText: 'No Data'
    });
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    // TODO: delete this / used $activeSiderSection to reopen sider when back button is used
    // sider.onChange((section: SiderSection) => {
    //   // TODO: Save to state
    //   // this.saveSettings({siderSection: section.name});
    // });

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInventoryBillingActiveSiderSection(activeSection.name)
    ]);
  }

  toggleNotes() {
    this.toggleSider(this.SECTIONS.NOTES_SECTION_NAME)
  }

  toggleHistory() {
    this.toggleSider(this.SECTIONS.HISTORY_SECTION_NAME)
  }

  toggleFilter() {
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME)
  }

  public filterData(query: ChargeQuery) {
    // delete necessary to make filter and clear hash equal if no filter params are present
    delete query.where['sp_serv_id']
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query, route: this.route, queryBuilder: ChargeQuery, queryToSet: 'chargeQuery'
    })
  }

  public clearFilter() {
    const query = new ChargeQuery()
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query, route: this.route, queryBuilder: ChargeQuery, queryToSet: 'chargeQuery'
    })
  }

  private onQueryParamsChange(params: Params) {
    if (Object.keys(params).length === 0) {
      this.chargeQuery = new ChargeQuery({
        where: {
          sp_serv_id: this.inventory.sp_ckt_id
        }
      })
    }
    const query = this.queryBuilder.build(this.chargeQuery, null, this.COMPONENT_NAME).getQuery()
    this.store.dispatch([
      new UpdateInventoryBillingQuery({query, key: params.key})
    ]);
  }

  private onQueryChange(query: Query) {
    this.chargeQuery = new ChargeQuery(query);
    this.chargeQuery.where = {
      ...this.chargeQuery.where,
      sp_serv_id: this.inventory.sp_ckt_id
    }
    this.prepareList()
  }

  public prepareList() {
    this.billingGridService.loadSettings()
      .subscribe(settings => {
        this.initGridColumns(settings)
      })
  }

  private initGridColumns(settings) {
    this.gridSettings = settings;
    this.chargesColumnsSorting = settings ? settings.sorting : [];
    this.chargesColumns = this.billingGridService.getColumns(settings && settings.columns ? settings.columns : [])

    this.defaultGridPager = settings ? settings.gridPager : 20;
    this.chargeQuery.limit = this.defaultGridPager;

    if (this.chargesColumnsSorting && this.chargesColumnsSorting.length) {
      this.chargeQuery.orderBy = this.billingGridService.getOrderBy(this.chargesColumnsSorting)
    }

    if (this.billingGrid && this.billingGrid.instance) {
      this.billingGrid.instance.option('columns', this.chargesColumns)
    }

    this.loadCharges(this.inventory)
  }

  /** Toolbar actions */
  onDownloadCSVRequested() {
    // TODO: check if middleware is needed and crete if it is
    this.billingGridService.csvMap().subscribe(fields => {
      this.chargeService.exportToCSV('inventory-billing', {
        fields,
        query: this.chargeQuery,
        fetchDataHandler: (query) => {
          return this.chargeService.findAllCsv(query)
        }
      })
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
