import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class VendorEntityService extends BaseService<any> {

  constructor(private entityLock: EntityLockService) {
    super('vendor-entity', entityLock);
  }
}
