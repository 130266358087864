import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Contact} from "./contact";
import Query from "../../core/query/query";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import { Observable } from 'rxjs';

@Injectable()
export class ContactService extends BaseService<Contact> {

  constructor(private entityLock: EntityLockService) {
    super('contacts', entityLock);
  }

  findAllList(query?: Query): any {
    return this.httpService().get(this.name, query);
  }

  findOnlyNecessary(query?: Query): any {
    return this.httpService().get([this.name, 'findOnlyNecessary'], query);
  }

  public findAllContactFunctions(contact_id: number): Observable<any> {
    return this.httpService().get([this.name, 'functions', contact_id])
  }
  getContactsForFunction(query?: Query): any {
    return this.httpService().get([this.name, 'getContactsForFunction'], query);
  }

}
