import {from, Observable} from "rxjs";
import {LocationService} from "./location.service";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import Query from "../../core/query/query";
import {map} from "rxjs";

export class CurrencyLookupProvider extends IFilterLookupProvider {
  constructor(public locationService: LocationService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    searchQuery = searchQuery || new Query();
    searchQuery.orderBy = [['currency', 'ASC']];
    return from(this.locationService.findAllUniqueCurrencies(searchQuery)).pipe(
      map((result: any) => {
        return {
          ...result,
          items: result.items.map(entry => {
            return entry.currency
          })
        };
      })
    )
  }
}
