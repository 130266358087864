
import {takeUntil, debounceTime} from 'rxjs';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable, Subject} from "rxjs";
import {AddressService} from "./core/address.service";
import Query from "../core/query/query";

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit, OnDestroy {

  searchFormControl = new FormControl();
  typeFormControl = new FormControl();
  options = [];

  query = new Query();

  filteredOptions: Observable<string[]>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public entries: Array<any> = [];

  constructor(public addressService: AddressService) {
  }

  ngOnInit() {
    this.searchFormControl.valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.destroy$),)
      .subscribe(
        inputValue => {
          this.queryByName(inputValue);
        }
      )
  }

  queryByName(q: string) {
    this.query['q'] = q;
    this.query['type'] = this.typeFormControl.value ? "E" : "I";
    this.loadData();
  }

  loadData() {

    /*this.addressService.findAll(this.query)
     .subscribe((response) => {
     this.entries = response;

     });*/

    /*this.addressService.verify(this.query)
     .subscribe((response) => {
     this.entries = response;

     });*/


    this.addressService.suggest(this.query['q'])
      .subscribe((response) => {
        this.entries = response;
      });

  }

  suggestionSelected(event) {
    console.log('suggestionSelected', event);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
