import { ContactModule } from '../contact/contact.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectRoutingModule} from './project-routing.module';
import {ProjectService} from "./core/project.service";
import {ProjectListComponent} from './project-list/project-list.component';
import {ProjectManageDialogComponent} from './shared/project-manage-dialog/project-manage-dialog.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DxDataGridModule} from "devextreme-angular/ui/data-grid";
import { DxDateBoxModule } from "devextreme-angular/ui/date-box";
import {MaterialModule} from "app/material-barrel/material-barrel.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {ProjectGridService} from "./core/project-grid.service";
import {ProjectSneakPeekComponent} from './shared/project-sneak-peek/project-sneak-peek.component';
import {DictionaryModule} from "../dictionary/dictionary.module";
import {SharedModule} from "../shared/shared.module";
import {ProjectFilterService} from "./core/project-filter.service";
import {CoreModule} from "../core/core.module";
import { CaProjectPickerComponent } from './shared/project-picker/ca-project-picker.component';

@NgModule({
    imports: [
        ProjectRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DxDataGridModule,
        MaterialModule,
        DxDateBoxModule,
        FlexLayoutModule,
        DictionaryModule,
        SharedModule,
        CoreModule,
        ContactModule
    ],
    exports: [CaProjectPickerComponent],
    declarations: [
        ProjectListComponent,
        ProjectManageDialogComponent,
        ProjectSneakPeekComponent,
        CaProjectPickerComponent
    ],
    providers: [
        ProjectService,
        ProjectGridService,
        ProjectFilterService
    ]
})
export class ProjectModule {
}
