<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{title}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <form class="ca-form" [formGroup]="form">
    <div class="flex-container" fxLayoutAlign="flex-start">
      <div class="flex-item caGap caGapRight" fxFlex="35%">
        <ca-audit-picker
          caInput
          placeholder="Audit Rule"
          formControlName="audit_rule"
          [pickerForm]="form"
          [clearEnabled]="false"
          [searchEnabled]="false"
          [auditTypeId]="30"
          (onSelectionChange)="onSelectionChange($event)"
        >
        </ca-audit-picker>
      </div>

      <ng-container *ngFor="let input of valueInputs">
        <div class="flex-item caGapRight" fxFlex="25%" *ngIf="selectedAuditSettings?.[input.name]">
          <mat-form-field caInput>
            <input matInput placeholder="{{ input.placeholder }}" formControlName="{{ input.name}}" required type="number">
            <mat-hint class="error-class"
                      *ngIf="form.hasError('required', [input.name]) && form.controls[input.name].touched">
              {{ VALIDATION.REQUIRED_ERROR }}
            </mat-hint>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </form>

  <div style="height: 300px; overflow: hidden;"
       class="app-content-list app-content-list-no-pager">
    <dx-data-grid #chargeGrid [columns]="columns" [width]="'100%'"
    [height]="'100%'" [dataSource]="charges">

      <div *dxTemplate="let d of 'currencyTemplate'">
        <div caGridTooltip class="right">
          {{ d.value || 0 | caCurrency: d.data.currency }}
        </div>
      </div>

      <div *dxTemplate="let d of 'dateTemplate'" style="text-align: left;">
        <span>{{ d.value | caDate }}</span>
      </div>
    </dx-data-grid>
  </div>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space"></div>

  <button #cancelButton mat-button type="submit" (click)="close()">
    Cancel
  </button>

  <button #saveButton mat-button type="submit" (click)="onSubmit()" [disabled]="form?.invalid">
    Save
  </button>
</mat-dialog-actions>

