import {Injectable} from "@angular/core";
import {BaseService} from "../../core/base.service";

@Injectable()
export class PaymentService extends BaseService<any> {
  constructor() {
    super("payment");
  }

  checkPaymentStatus(id: number): any {
    return this.httpService().get([this.name, 'invoice-status', id])
  }

  purchaseInvoice(invoice: any): any {
    const {
      account: {
        account_no,
        vendor: {name},
      },
      inv_date,
      due_date,
      sp_inv_num,
      invoice_id,
    } = invoice;

    let [year, month, day] = inv_date.split("-");
    const vendorInvoiceNumber = `${account_no} ${month}/${year}`;

    const body = {
      vendorName: name,
      vendorInvoiceNumber,
      invoiceNumber: sp_inv_num,
      invoiceId: invoice_id,
      invoiceDate: inv_date,
      dueDate: due_date
    };
    return this.httpService().post([this.name, 'invoice'], body)
  }
}
