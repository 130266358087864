
import {debounceTime} from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from '../../core/address.model';
import { AddressService } from '../../core/address.service';
import { SmartyStreetsReportingService } from '../../core/smartystreets-reporting.service';
import { ConfigService } from '../../../core/config/config.service';
import { LocationService } from '../../../location/core/location.service';
import { Config } from '../../../core/config/config';
import { AlertService } from '../../../shared/alert/alert.service';
import { LoaderService } from '../../../shared/loader/loader.service';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';

@Component({
  selector: 'ca-address-selection',
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.css'],
  providers: [SmartyStreetsReportingService]
})
export class AddressSelectionComponent implements OnInit, OnChanges {
  addressForm: FormGroup;

  @Input() componentTitle = '';
  @Input() address: Address;
  @Input() required = false;
  @Output() addressChanged = new EventEmitter();
  @Output() countryChanged = new EventEmitter();
  @Output() isFormMode = new EventEmitter();
  @Input() disabled = false;
  // This input changes display of address and city inputs. It's in the same row when this is true.
  @Input() addressCityInlineFlex: boolean;

  suggestions: Array<any> = [];
  pauseSuggest: boolean;
  countrySearch_id: any;
  unverified: boolean;

  formMode: boolean;
  countryId: number;
  subdivisions = [];
  countryCode: any;
  defaultCountryCode: any;
  postalCodePlaceholder: string;
  subdivisionPlaceholder: string;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'address';
  defaultValue = 'Search Address';

  verifying = false;
  selected = false;
  clicked = false;
  addressesSearchInternallyOnly = false;
  countryUSAId = 1;

  public zipPattern: string = '';

  public VALIDATION = {
    REQUIRED_ERROR: 'Field is required',
    ZIP_INVALID: '##### or #####-####',
  };


  constructor(public formBuilder: FormBuilder,
    public locationService: LocationService,
    public configService: ConfigService,
    public addressService: AddressService,
    public alertService: AlertService,
    public loaderService: LoaderService,
    public smartyStreetsErrorService: SmartyStreetsReportingService) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnChanges(change) {
    if (change && change.disabled && this.addressForm) {
      let value = change.disabled.currentValue;
      if (value) {
        this.addressForm.disable();
      } else {
        this.addressForm.enable();
      }
    }
  }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      addressSearch: { value: this.address ? this.address.address1 : '', disabled: this.disabled },
      countrySearch_id: {
        value: this.address && this.address.country_id !== null ? this.address.country_id : 1,
        disabled: this.disabled
      },
    });

    this.addressForm.controls['addressSearch'].valueChanges.pipe(
      debounceTime(250))
      .subscribe(
        inputValue => {
          if (inputValue) {
            this.suggest(inputValue);
          }
        }
      );

    this.addressForm.controls['countrySearch_id'].valueChanges.subscribe(
      searchByCountry => {
        this.suggestions = [];
        this.emitAddress();
        this.loadDataForCountry(searchByCountry)
      }
    );

    this.configService.findAll()
      .subscribe((config: Config) => {
        this.defaultCountryCode = config.location.country;
        this.loadDataForCountry(this.addressForm.get('countrySearch_id').value);
      });
  } //end ngOnInit

  onCountryChange() {
    this.addressForm.controls['addressSearch'].setValue(null);
    this.address = null;
  }

  loadDataForCountry(countryid?) {
    countryid = countryid ? countryid : this.defaultCountryCode;
    this.locationService
      .findCountryById(countryid)
      .subscribe(newcountry => {
        this.countryChanged.emit(newcountry);
        this.countryCode = newcountry.alpha_2_code;
        this.postalCodePlaceholder = newcountry.postal_code_name_label;
        this.subdivisionPlaceholder = newcountry.subdivision_name_label;
        this.zipPattern = newcountry.postal_code_regex;
        this.VALIDATION.ZIP_INVALID= newcountry.postal_code_mask;
        // this.addressForm.removeControl('postal_code');
        // this.addressForm.addControl('postal_code', new FormControl('',
        //   Validators.compose([Validators.required, Validators.pattern(`${this.zipPattern}`)])));
        /* Check if selected country has the same ID as country from config */
        if (newcountry.alpha_2_code === this.defaultCountryCode || newcountry.alpha_2_code === undefined) {
          /* SEARCH DB THEN SMARTYSTREETS */
          this.addressesSearchInternallyOnly = false;
        } else {
          /* SEARCH ONLY DB */
          this.addressesSearchInternallyOnly = true;
        }
      });
  }

  suggest(inputValue) {
    if (inputValue && inputValue.length > 2) {
      if (!this.pauseSuggest) {
        /* Search only DB(if country is not US) */
        if (this.addressesSearchInternallyOnly) {
          this.addressService
            .suggestInternal(inputValue)
            .subscribe(response => {
              this.suggestions = response;
              this.suggestions.push({ addNew: true, text: '' });
            });
        } else {
          /* Search DB then SmartyStreets(if country is US) */
          let country_id = this.addressForm.get('countrySearch_id').value
          this.addressService
            .suggest(inputValue, country_id)
            .subscribe(response => {
              this.suggestions = response;
              this.suggestions.push({ addNew: true, text: '' });
              this.smartyStreetsErrorService.errorCheck(this.suggestions);
            });
        }
      } else {
        this.pauseSuggest = false;
      }
    } else {
      this.suggestions = [];
    }
  }

  suggestionSelected(suggestion: any) {
    this.selected = true;
    if (!suggestion && !suggestion.option) {
      return;
    }

    let addressValue = suggestion.option.value;

    let address = this.findAddressByValue(this.suggestions, addressValue);

    if (address) {
      this.pauseSuggest = true;
      if (address.addNew) {
        /* User selected 'Add new' from suggestions drop down - switch to form mode instantly */
        this.switchFormMode(true);
      } else {
        /* User selected address suggestion - 'SmartyStreets' or internal */
        this.switchFormMode(false);

        if (address.internal) {
          /* Internal - no verification */
          this.address = address;
          this.emitAddress();
          this.unverified = false;
        } else {
          /* 'SmartyStreets' - verify selected suggestion */
          this.verifying = true;
          this.address = null;
          this.emitAddress();
          this.verifySuggestion(address);
        }
      }
    }
  }

  inputChange(e) {
    e.preventDefault();
    if (e.target.value.length === 0) {
      this.unverified = false;
    }
  }

  findAddressByValue(suggestions, addressValue) {
    suggestions = suggestions || [];
    return suggestions.filter((entry) => {
      return entry.text === addressValue;
    })[0];
  }

  verifySuggestion(suggestion: any) {
    this.loaderService.displayLoader();
    const address = {
      streetLine:suggestion.streetLine,
      state : suggestion.state,
      city : suggestion.city,
      zipCode : suggestion.zipcode,
      country_id: this.addressForm.get('countrySearch_id').value
    }
    this.addressService.verify(address)
        .subscribe((response) => {
        if (response.error) {
          this.unverified = true;
          this.emitAddress();
          this.loaderService.hideLoader();
          this.verifying = false;
          this.address = new Address({
            address1: suggestion.streetLine,
            city: suggestion.city,
            stateAbbreviation: suggestion.state
          });
        } else if(response.address === "UNVERIFIED ADDRESS"){
          this.address = suggestion;
          this.addressService.createOrUpdate(this.address)
            .subscribe((result) => {
              this.address = new Address(result);
              this.switchFormMode(false);
              this.emitAddress();
              this.loaderService.hideLoader();
              this.verifying = false;
              this.unverified = false;
            }, (err) => {
              this.alertService.error('', this.messages.get('CREATE_ERROR'));
            });
        }else {
          this.address = response.address;
          this.emitAddress();
          this.loaderService.hideLoader();
          this.verifying = false;
          this.unverified = false;
        }
      });
  }

  switchFormMode(value: boolean) {
    this.formMode = value;
    this.emitFormModeChanged(this.formMode)

    if (this.formMode) {
      // case when no address is picked, but only entered characters are present (new address)
      if (this.addressForm.controls['address1'] && this.addressForm.controls['address1'].value && !this.address) {
        this.addressForm.controls['address1'].reset('');
        // this.addressForm.controls['city'].setValue(this.countryCode);
        this.addressForm.controls['city'].reset('');
        this.addressForm.controls['subdivision_id'].reset(null);
        this.addressForm.controls['postal_code'].reset('');
      } else {
        this.addressForm.addControl('address1', new FormControl('', Validators.required));
        this.addressForm.addControl('city', new FormControl('', Validators.required));
        this.addressForm.addControl('subdivision_id', new FormControl(''));
        this.addressForm.addControl('postal_code', new FormControl('',
          Validators.compose([ Validators.pattern(`${this.zipPattern}`)]))
        );

        if (this.address) {
          if (this.address['address1']) {
            this.addressForm.controls['address1'].setValue(this.address['address1']);
          }

          if (this.address['address1']) {
            this.addressForm.controls['city'].setValue(this.address['city']);
          }

          if (this.address['subdivision_id']) {
            this.addressForm.controls['subdivision_id'].setValue(this.address['subdivision_id']);
          }

          if (this.address['postal_code']) {
            this.addressForm.controls['postal_code'].setValue(this.address['postal_code']);
          }

          if (this.address['stateAbbreviation']) {
            let abbreviation = this.address['stateAbbreviation'];
            this.locationService.findAllCountrySubdivisions(this.countryCode).subscribe((result) => {
              let state = result.items.find((item) => {
                return item.abbreviation === abbreviation;
              });
              this.addressForm.controls['subdivision_id'].setValue(state.id ? state.id : null);
            });
          }
        }
      }

      // reset address search field
      this.addressForm.controls['addressSearch'].reset('');
      this.address = null;
      this.emitAddress();
    }
  }

  emitAddress() {
    this.addressChanged.emit(this.address);
  }

  emitFormModeChanged(formMode: boolean) {
    this.isFormMode.emit(formMode);
  }

  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      this.locationService.findByIdSubdivision(value.subdivision_id).subscribe((res)=>{
        let address = new Address({
          address1: value.address1,
          city: value.city,
          country_id: this.addressForm.get('countrySearch_id').value,
          subdivision_id: value.subdivision_id,
          postal_code: value.postal_code,
          streetLine:value.address1,
          state:res.abbreviation
        });
        this.addressForm.controls['addressSearch'].setValue(value.address1);
        if(this.addressForm.get('countrySearch_id').value === this.countryUSAId){
          this.verifySuggestion(address);
        }else{
          this.addressService.createOrUpdate(address)
            .subscribe((result) => {
              this.address = new Address(result);
              this.emitAddress();
              this.switchFormMode(false);
            }, (err) => {
              this.alertService.error('', this.messages.get('CREATE_ERROR'));
            });
        }
      })
    } else {
      this.alertService.error('', this.messages.get('FORM_INVALID'));
    }
  }

  requiredValidator() {
    if (this.address &&
      (!this.address.internal &&
        !this.address.is_validated &&
        this.addressForm.controls.addressSearch.touched &&
        this.selected)) {
      return true
    }

    if (!this.address && !this.clicked && this.addressForm.controls.addressSearch.touched) {
      return true
    }

    if (!this.address && this.addressForm.controls.addressSearch.touched && this.selected) {
      return true
    }
  }

  mouseDown() {
    this.clicked = true;
  }

  mouseUp() {
    this.clicked = false;
  }
}
