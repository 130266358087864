import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { ContractRate } from './contract-rate';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class RateService extends BaseService<ContractRate> {

  constructor(private entityLock: EntityLockService) {
    super('contract-rate', entityLock);
  }

  save(dataToSave): any {
    return this.httpService().post([this.name, 'save'], dataToSave);
  }

  public createRate(contractId, item) {
    return this.httpService().post([this.name, contractId, 'rate'], item);
  }

  createInstance(item) {
    return this.httpService().post([this.name, 'rate'], item);
  }
  
  deleteRates(rateIds, contractInstanceId) {
    return this.httpService().delete([this.name], {
      rateIds, contractInstanceId
    });
  }

  findCategorizedRates(query): any {
    return this.httpService().get([this.name, 'categorized-rates'], this.toFilter(query));
  }

}
