<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Information</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <form class="ca-form" [formGroup]="form" fxLayout="column">
    <div fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex="25%">
        <mat-form-field caInput>
          <mat-label>User Id</mat-label>
          <input type="text" [disabled]="true" value="{{ userId }}" matInput>
        </mat-form-field>
      </div>

      <div fxFlex class="caGap">
        <mat-form-field caInput>
          <mat-label>User Name</mat-label>
          <input type="text" [disabled]="true" value="{{ userFullName }}" matInput>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayout.sm="column">
      <div fxFlex class="caGap caGapRight">
        <mat-form-field caInput>
          <mat-label>Instance Name</mat-label>
          <input type="text" [disabled]="true" value="{{ instanceName }}" matInput>
        </mat-form-field>
      </div>

      <div fxFlex class="caGap">
        <mat-form-field caInput>
          <mat-label>Version Number</mat-label>
          <input type="text" [disabled]="true" value="{{ versionNumber }}" matInput>
        </mat-form-field>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap">
      <div class="flex-item caGap" fxFlex >
        <mat-form-field caInput>
          <mat-label>Page Path</mat-label>
          <input type="text" [disabled]="true" value="{{ pagePath }}" matInput>
        </mat-form-field>
      </div>
    </div>


    <div class="flex-container" fxLayout="row wrap">
      <div class="flex-item form-group caGap" fxFlex="50%">
        <ca-default-picker caInput>
          <mat-form-field>
            <mat-label>Severity</mat-label>
            <mat-select formControlName="level" required>
              <mat-option value="{{ALERT_LEVELS_ENUM.CRITICAL}}">Critical error</mat-option>
              <mat-option value="{{ALERT_LEVELS_ENUM.ERROR}}">Error/bug</mat-option>
              <mat-option value="{{ALERT_LEVELS_ENUM.INFO}}">Note/comment</mat-option>
            </mat-select>
            <mat-hint *ngIf="form.hasError('required', ['level']) && form.controls.level.touched" class="error-class">
              {{VALIDATION.REQUIRED_ERROR}}
            </mat-hint>
          </mat-form-field>
        </ca-default-picker>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap">
      <div class="flex-item caGap" fxFlex>
        <mat-form-field caInput>
          <mat-label>Message</mat-label>
          <textarea matInput required type="text" formControlName="message" row="2"></textarea>
          <mat-hint *ngIf="form.hasError('required', ['message']) && form.controls.message.touched" class="error-class">
            {{VALIDATION.REQUIRED_ERROR}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <span class="example-fill-remaining-space"></span>
  <button mat-button type="submit" (click)="closeDialog()">
    Cancel
  </button>
  <button mat-button type="submit" [disabled]="form.invalid" (click)="onSubmit(form)">Send report</button>
</mat-dialog-actions>

