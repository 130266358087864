import {Component, OnInit} from "@angular/core";
import {takeUntil} from 'rxjs';
import {PageManageDialogComponent} from "../../../core/page-manage-dialog.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {AlertService} from "../../../shared/alert/alert.service";
import {ContractInstanceService} from "../../core/contract-instance.service";
import {MatDialogRef} from "@angular/material/dialog";
import {LoaderService} from "../../../shared/loader/loader.service";
import {DocumentService} from "../../../shared/document/core/document.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {ContractService} from "../../core/contract.service";
import Query from "../../../core/query/query";

function numOfLines(textArea, lineHeight) {
  const h0 = textArea.style.height;
  textArea.style.height = 'auto';
  const h1 = textArea.scrollHeight;
  textArea.style.height = h0;
  return Math.floor(h1 / lineHeight);
}

@Component({
  selector: 'svc-order-term-condition-manage-dialog.component',
  templateUrl: './svc-order-term-condition-manage-dialog.component.html',
  styleUrls: ['./svc-order-term-condition-manage-dialog.component.css']
})
export class SvcOrderContractScheduleTermConditionManageDialogComponent extends PageManageDialogComponent implements OnInit {

  readonly MESSAGES_MODULE: string = 'contract';
  svcOrderHeader: any;
  svcOrderTermsAndCondition: any;
  messages: IMessagesResourceService;

  query =  new Query();

  constructor(
    public dialog: DialogService,
    public formBuilder: FormBuilder,
    public alert: AlertService,
    public contractInstanceService: ContractInstanceService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<SvcOrderContractScheduleTermConditionManageDialogComponent>,
    public loaderService: LoaderService,
    public documentService: DocumentService,
    public contractService: ContractService
  ) {
    super(dialogService, dialogRef);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }
  ngOnInit() {
    this.form = this.formBuilder.group({

      name: new FormControl(this.svcOrderTermsAndCondition ? this.svcOrderTermsAndCondition.name : null, Validators.required),
      section_id: new FormControl(this.svcOrderTermsAndCondition ? this.svcOrderTermsAndCondition.section_id : null, Validators.required),
      text: new FormControl(this.svcOrderTermsAndCondition ? this.svcOrderTermsAndCondition.text : null),
      abstract: new FormControl(this.svcOrderTermsAndCondition ? this.svcOrderTermsAndCondition.abstract : null)
    });

    this.form.controls.text.valueChanges.pipe(
      takeUntil(this.destroy$))
      .subscribe(value => {
        this.setTextarea("contract_text")
      });
    this.form.controls.abstract.valueChanges.pipe(
      takeUntil(this.destroy$))
      .subscribe(value => {
        this.setTextarea("contract_abstract")
      });
  }

  ngAfterViewInit(){
    if(this.svcOrderTermsAndCondition && this.svcOrderTermsAndCondition.text) {
      this.setTextarea("contract_text");
    }
    if(this.svcOrderTermsAndCondition && this.svcOrderTermsAndCondition.abstract) {
      this.setTextarea("contract_abstract");
    }
  }

  setTextarea(id) {
    const textarea = document.getElementById(id) as HTMLTextAreaElement;
    const linesNum = numOfLines(textarea, 20)
    if(linesNum < 6) {
      textarea.style.height = `${linesNum*20}px`;
    } else {
      textarea.style.height = `100px`;
    } 
  }

  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    this.isUpdate ? this.update(value) : this.create(value);
  }

  update(value){

    this.checkEmptyStringValues(value);
    this.query.where = this.svcOrderTermsAndCondition.id
    this.toggleDialogButtons();
    this.contractService.updateSvcOrderTermsAndConditions(this.svcOrderTermsAndCondition.id, this.form.value)
      .subscribe(result => {

        this.closeDialog(result, true);
      }, (err) => {
        this.toggleDialogButtons(false)
        this.loaderService.hideLoader();
        this.alert.success('', this.messages.get('TERMS_CONDITION_UPDATE_ERROR'));
      });
  }

  create(value) {
    value.svc_order_header_id = this.svcOrderHeader.id
    this.toggleDialogButtons();
    this.contractService.createSvcOrderTermsAndConditions(value)
      .subscribe(result => {

        this.closeDialog(result, true);
      }, (err) => {
        this.toggleDialogButtons(false)
        this.loaderService.hideLoader();
        this.alert.success('', this.messages.get('TERMS_CONDITION_ERROR'));
      });
  }

  checkEmptyStringValues(values){
    const items = Object.keys(values)
    items.forEach(key => {
      if (this.form.get(key).value === ''){
        this.form.controls[`${key}`].setValue(null)
      }
    })
  }
  cancel() {
    this.closeDialog();
  }
}
