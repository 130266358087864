import { Injectable } from '@angular/core';
import { InvoiceFlowHandleService } from './invoice-flow-handle.service';
import { INVOICE_STATUS_ENUM } from './invoice-status.enum';
import { ReverseAPFeedMethod } from './reverse_ap_feed_method.enum';
import { InvoiceFacepage } from './invoice-facepage';

@Injectable()
export class InvoiceFlowSteps {
	constructor(public invoiceFlowHandleService: InvoiceFlowHandleService) {}

	getSteps(permissions, config, invoice: InvoiceFacepage, invoiceStatusMap) {
		const configGLEnable = config.invoice_flow_settings?.gl_coding_enabled;
		const configMergeInToOneStep = config.invoice_flow_settings?.merge_batch_into_one_step;
		const configPaymentEnable = config.invoice_flow_settings?.payment_enabled;
		const doNotProcessVisible = invoice.header.status_code === INVOICE_STATUS_ENUM.DO_NOT_PROCESS;
		const configSageEnable = config.invoice_flow_settings?.sage_enabled;
		const reverseAPFeedMethod = config.invoice_flow_settings?.reverse_ap_feed_method;

		const isGlCodingInProcess = invoice.header.status.id === INVOICE_STATUS_ENUM.GL_CODING_IN_PROCESS;
		const isUnsetAdjustmentsSet = invoice.header.status.id === INVOICE_STATUS_ENUM.UNSET_ADJUSTMENTS;

		const disableByCurrentStatus = isGlCodingInProcess || isUnsetAdjustmentsSet;

		const auditResults = invoice.audit_results.getDetailItems();

		return [
			{
				key: 'NEW',
				name: invoiceStatusMap['NEW'],
				permission: true,
				next_steps: ['GL_CODING'],
				previous_steps: [],
				visible: true,
				onClickFromPreviousStep: () => {},
				onClickFromNextStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.revertToNew(flow, invoice, mediator, callFunc, data);
				},
				onClick: () => {},
				disabled: !permissions.modifyPermission
			},
			{
				key: 'GL',
				name: invoiceStatusMap['GL_CODED_FULL'],
				onClickFromPreviousStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.goToGL(flow, invoice);
				},
				onClickFromNextStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.revertToGL(flow, invoice, mediator, callFunc);
				},
				onClick: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.onGLClick(flow, invoice, mediator, callFunc, data);
				},
				next_steps: ['AUDIT'],
				previous_steps: ['NEW'],
				disabled: permissions.glButtonDisabled,
				visible: configGLEnable
			},
			{
				key: 'AUDIT',
				name: invoiceStatusMap['AUDIT_PASSED'],
				onClickFromPreviousStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.goToAudit(flow, invoice, mediator, callFunc, data);
				},
				onClickFromNextStep: (flow, invoice, mediator, callFunc) => {
					this.invoiceFlowHandleService.revertToGL(flow, invoice, mediator, callFunc);
				},
				onClick: () => {},
				visible: Boolean(auditResults.length),
				next_steps: ['RFA'],
				previous_steps: ['GL'],
				disabled: !permissions.modifyPermission || disableByCurrentStatus
			},
			{
				key: 'RFA',
				name: invoiceStatusMap['RFA'],
				onClickFromPreviousStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.goToRfA(flow, invoice, mediator, callFunc, data);
				},
				onClickFromNextStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.revertToRfa(flow, invoice, mediator, callFunc, data);
				},
				onClick: () => {},
				visible: true,
				disabled: !permissions.modifyPermission || disableByCurrentStatus
			},
			{
				key: 'APPROVED',
				name: invoiceStatusMap['APPROVED'],
				onClickFromPreviousStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.goToApproved(flow, invoice, mediator, callFunc, data);
				},
				// onClickFromNextStep: () =>{},
				onClickFromNextStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.revertToApproved(flow, invoice, mediator, callFunc, data);
				},
				onClick: () => {},
				visible: true,
				disabled: permissions.approveButtonDisabled || disableByCurrentStatus
			},
			{
				key: 'GL_BATCH',
				name: invoiceStatusMap['GL_BATCH_OUTPUT'],
				onClickFromPreviousStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.goToGlBatchOutput(flow, invoice, mediator, callFunc, data);
				},
				onClickFromNextStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.revertToGlBatchOutput(flow, invoice, mediator, callFunc, data);
				},
				onClick: () => {},
				visible: configGLEnable && !configMergeInToOneStep,
				disabled: permissions.glBatchOutputButtonDisabled || !permissions.modifyPermission || disableByCurrentStatus
			},
			{
				key: 'GL_OUTPUT',
				name: invoiceStatusMap['GL_BATCH_OUTPUT_SEND'],
				onClickFromPreviousStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.goToGLOutputSent(flow, invoice, mediator, callFunc, data);
				},
				onClickFromNextStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.revertToGLOutputSent(flow, invoice, mediator, callFunc, data);
				},
				onClick: () => {},
				visible: configGLEnable && !configMergeInToOneStep,
				disabled: permissions.glDisabled || !permissions.modifyPermission || disableByCurrentStatus
			},
			{
				key: 'REVERSE_AP_FEED_RECEIVED',
				name: invoiceStatusMap['REVERSE_AP_FEED_RECEIVED'],
				onClickFromPreviousStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.goToApFeedReceived(flow, invoice, mediator, callFunc, data);
				},
				onClickFromNextStep: () => {},
				onClick: () => {},
				visible: configGLEnable && !configPaymentEnable && !configSageEnable && reverseAPFeedMethod === ReverseAPFeedMethod.MANUAL,
				disabled: !permissions.modifyPermission || disableByCurrentStatus
			},
			{
				key: 'GL_BOOKING',
				name: invoiceStatusMap['GL_BOOKING'],
				onClickFromPreviousStep: (flow, invoice, mediator, callFunc, data) => {
					this.invoiceFlowHandleService.goToGLBooking(flow, invoice, mediator, callFunc, data);
				},
				// onClickFromNextStep: (flow,invoice,mediator,callFunc,data)=>{this.invoiceFlowHandleService.revertToGLBooking(flow,invoice,mediator,callFunc,data)},
				onClickFromNextStep: () => {},
				onClick: () => {},
				visible: configGLEnable && configMergeInToOneStep,
				disabled: disableByCurrentStatus
			},
			{
				key: 'INVOICE_PAYMENT',
				name: invoiceStatusMap['INVOICE_PAYMENT'],
				// onClickFromPreviousStep: (flow,invoice,mediator,callFunc,data)=>{this.invoiceFlowHandleService.goToInvoicePayment(flow,invoice,mediator,callFunc,data)},
				onClickFromPreviousStep: () => {},
				onClickFromNextStep: () => {},
				onClick: () => {},
				visible: !!(configPaymentEnable || configSageEnable) && configGLEnable,
				disabled: disableByCurrentStatus
			},
			{
				key: 'DO_NOT_PROCESS',
				name: 'Do Not Process',
				onClickFromPreviousStep: () => {},
				onClickFromNextStep: () => {},
				onClick: () => {},
				visible: doNotProcessVisible,
				disabled: disableByCurrentStatus
			}
		];
	}
}
