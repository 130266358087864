import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';

@Injectable()
export class GlDirectService extends BaseService<any> {
  constructor() {

    super('gl-direct');
  }

  createDirectGL(apportions, charges, invoice) {
    return this.httpService().post([this.name], {
      apportions: apportions.map((x) => {
        return {
          allocation: x.gl_apportion_pct || x.allocation,
          gl_string_id: x.gl_string_id,
          dynamic_included: x.dynamic_included,
        }
      }),
      charges: charges.map(x => x.id),
      invoice_id: invoice.invoice_id
    });
  }

  update(id, {apportions, charges, invoice}) {
    return this.httpService().put([this.name, id], {
      apportions: apportions.map((x) => {
        return {
          allocation: x.gl_apportion_pct || x.allocation,
          gl_string_id: x.gl_string_id,
          dynamic_included: x.dynamic_included,
        }
      }),
      charges: charges.map(x => x.id),
      invoice_id: invoice.invoice_id
    });
  }
}
