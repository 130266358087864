import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AlertDisconnectGridService extends GridService {
	defaultColumns = [
		{
			caption: '',
			headerCellTemplate: 'selectedChargesHeaderTemplate',
			cellTemplate: 'chargeDisputeNoteTemplate',
			width: 40,
			fixed: true,
			allowSorting: false
		},
		{
			caption: 'Charge ID',
			dataField: 'charge_id',
			width: 140
		},
		{
			caption: 'Invoice ID',
			dataField: 'invoice_id',
			width: 140
		},
		{
			caption: 'SPID',
			dataField: 'spid',
			width: 160
		},
		{
			caption: 'Charge Class',
			dataField: 'charge.chg_class',
			width: 100
		},
		{
			caption: 'Charge Description 1',
			dataField: 'charge.chg_desc_1',
			width: 160
		},
		{
			caption: 'Charge Description 2',
			dataField: 'charge.chg_desc_2',
			width: 160
		},
		{
			caption: 'Charge Amount',
			dataField: 'chg_amt',
			dataType: 'currency',
			alignment: 'right',
			cellTemplate: 'rateCellTemplate',
			width: 120
		},
		{
			caption: 'Calculated Amount',
			dataField: 'calc_amt',
			dataType: 'currency',
			alignment: 'right',
			cellTemplate: 'rateCellTemplate',
			width: 140
		},
		{
			caption: 'Difference',
			dataField: 'diff',
			dataType: 'currency',
			alignment: 'right',
			cellTemplate: 'rateCellTemplate',
			width: 100
		},
		{
			caption: 'Message',
			dataField: 'message',
			width: 400
		}
	];

	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.alert.disconnect-grid', userSettings);
	}
}
