import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from '../../../shared/picker/ca-picker-base.component';
import { DictionaryService } from '../../../dictionary/core/dictionary.service'

import Query from '../../../core/query/query';
  
  @Component({
    selector: 'ca-technology-picker',
    template: PICKER_COMPONENT_TEMPLATE,
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CaTechnologyPickerComponent),
        multi: true
      },
      DictionaryService
    ]
  })
  export class CaTechnologyPickerComponent extends PickerComponentBase implements OnInit, OnChanges {
  
  
    readonly TEXT_FIELD = 'value';
  
    @Input('formControlName') formControlName;
    @Input('initialItem') initialItem;
    @Input('disabled') disabled: boolean;
    @Input('required') required: boolean;
    @Input('searchEnabled') searchEnabled: boolean = false;
    @Input('clearEnabled') clearEnabled: boolean;
    @Input('placeholder') placeholder: string = 'Select ...';
    @Input('pickerForm') pickerForm;
    @Input('valueAsString') valueAsString: boolean;
    @Input('lookupModel') lookupModel: string;
    @Input() disableCache: boolean;
  
    @Input('query') query;

    @Input() cktTypeId: number;
    @Input() initialItemId: string;
  
    public control;
  
    constructor(public dictionaryService: DictionaryService) {
      super();
    }
  
    loadEntries(): Observable<any> {
      this.query = this.query || new Query({
        where: {
          limit: 1000
        }
      });
      this.query.orderBy = [['value', 'asc']];
      this.cktTypeId ? this.query.set('ckt_type_id', this.cktTypeId) : this.query.remove('ckt_type_id');

      return this.dictionaryService.getByLookup(this.lookupModel, this.query, true);
    }
  
    search(value: string): Observable<any> {
      return this.dictionaryService.getByLookup(this.lookupModel, this.query);
    }
  
    ngOnChanges(changes: SimpleChanges) {
      this.control = this.pickerForm.controls[this.formControlName];
      
      const {cktTypeId, initialItemId} = changes;
      if (
          (cktTypeId && (!!cktTypeId.currentValue || !!cktTypeId.previousValue) && cktTypeId.currentValue !== cktTypeId.previousValue) || 
          (initialItemId && (!!initialItemId.currentValue || !!initialItemId.previousValue) && initialItemId.currentValue !== initialItemId.previousValue)
        ) {
        this.initialItem = null;
        this.initialItemId = null;
        this.loadData();
      }
    }

    triggerClearPicker() {
      this.writeValue(null);
      this.writeFiledStatus('markAsUntouched');
    }
  }
  