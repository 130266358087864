import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { MatDialogRef } from '@angular/material/dialog';

import { SortingBuilder, SortingService } from '../../../shared/sorting/sorting.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { InvoiceFacepage } from '../../core/invoice-facepage';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { ShortInvoiceGridService } from '../../core/short-invoice-grid.service';
import { PageManageDialogComponent } from 'app/core/page-manage-dialog.component';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { RandomService } from 'app/shared/random/random.service';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';

@Component({
	selector: 'app-invoice-manage-dialog',
	templateUrl: './invoice-manage-dialog.component.html'
})
export class ManageInvoiceDialogComponent extends PageManageDialogComponent implements AfterViewInit, OnInit {
	public invoices: Array<InvoiceFacepage> = [];
	public onDeleteConfirmed: Function;
	actionText = 'Delete';
	title = 'Delete invoices';
	confirmText = 'Are you sure you want to delete this invoice?';

	@ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
	public columns: Array<any>;
	public note: FormControl;
	public dnp_reason_code_id;
	public invoiceSorting: SortingBuilder;
	messages: IMessagesResourceService;
	readonly MESSAGES_MODULE: string = 'invoice';
	deletePlaceholder = 'Type in generated string shown below';
	randomString = this.randomService.getRandomCheckString();
	placeHolder = 'Note';
	config: any;
	public DNP_REASON_CODE_MODEL = LOOKUP_MODELS_ENUM.DNP_REASON_CODE.modelName;

	constructor(
		public randomService: RandomService,
		public dialogService: DialogService,
		public dialogRef: MatDialogRef<ManageInvoiceDialogComponent>,
		public sortingService: SortingService,
		public alertService: AlertService,
		public formBuilder: FormBuilder,
		public shortInvoiceGridService: ShortInvoiceGridService
	) {
		super(dialogService, dialogRef);
		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
		this.invoiceSorting = this.sortingService.builder();
	}

	ngOnInit() {
		this.columns = this.shortInvoiceGridService.columns();
		if (!this.dialogRef.componentInstance['config']) {
			this.form = this.formBuilder.group({
				note: new FormControl(this.note, Validators.required)
			});
		} else {
			this.form = this.formBuilder.group({
				note: new FormControl(this.note, Validators.required),
				dnp_reason_code_id: new FormControl(this.dnp_reason_code_id, Validators.required)
			});
		}
	}

	ngAfterViewInit(): void {
		this.setDialogContent();
	}

	close(deleted = false) {
		this.dialogRef.close({
			deleted
		});
	}

	delete() {
		this.dialogService
			.confirm({
				bodyText: this.confirmText
			})
			.afterClosed()
			.subscribe((result) => {
				if (!result) {
					return;
				}

				if (!this.dialogRef.componentInstance['config']) {
					this.onDeleteConfirmed({
						note: this.form.get('note').value,
						closeDialog: this.dialogRef.close.bind(this.dialogRef)
					});
				} else {
					this.onDeleteConfirmed({
						note: this.form.get('note').value,
						dnp_reason_code_id: this.form.get('dnp_reason_code_id').value,
						closeDialog: this.dialogRef.close.bind(this.dialogRef)
					});
				}
			});
	}

	setDialogContent() {
		if (!this.dialogRef.componentInstance['config']) {
			this.placeHolder = 'Note';
			this.shortInvoiceGridService.create(this.dataGrid.instance, {
				noDataText: 'No data'
			});
		} else {
			this.placeHolder = 'Note';
		}
	}
}
