import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of as observableOf, } from 'rxjs';
import { map } from 'rxjs';
import Query from "../../../core/query/query";
import { PickerComponentBase, PICKER_COMPONENT_TEMPLATE } from '../../../shared/picker/ca-picker-base.component';
import { LocationService } from '../../core/location.service';
import {path} from "ramda";

@Component({
  selector: 'ca-currency-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaCurrencyPickerComponent),
      multi: true
    }
  ]
})
export class CaCurrencyPickerComponent extends PickerComponentBase implements OnChanges, OnInit {

  readonly TEXT_FIELD = 'currency';
  readonly KEY_FIELD = 'id';
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() placeholder = 'Select ...';
  @Input() clearEnabled: boolean;
  @Input() searchEnabled: boolean;
  @Input() countryId: number;
  @Input() availableCurrencies: any;

  @Input() formControlName: string | number;
  @Input() pickerForm;

  @Input() query = new Query();
  control: any;

  constructor(public locationService: LocationService) {
    super();
  }

  loadEntries(): Observable<any> {
    this.control = this.pickerForm.controls[this.formControlName];
    this.query.orderBy = [['currency', 'ASC']];

    if (this.countryId || this.availableCurrencies) {
      this.query.where = {id: {$in: this.availableCurrencies}};
      this.query.limit = 1000;
    }

    return this.locationService.findAllCurrenciesByCountry(this.query)
  }

  search(value: string): any {
    this.query.where['currency'] = { '$ilike': `${value}%` };
    let currencies = this.locationService.findAllCurrenciesByCountry(this.query);
    return currencies;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = path(['pickerForm', 'controls', this.formControlName])(this);
    const {countryId, availableCurrencies} = changes;
    if ((countryId && (!!countryId.currentValue || !!countryId.previousValue) && countryId.currentValue !== countryId.previousValue) ||
      (availableCurrencies && (!!availableCurrencies.currentValue || !!availableCurrencies.previousValue) && availableCurrencies.currentValue !== availableCurrencies.previousValue)) {
      this.loadData();
    }
  }
  onScrollEnd($event) {}
}
