import {Component, OnInit} from '@angular/core';
import {DialogButtonsController} from "../../../core/dialog-buttons-controller.class";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-create-invoice-charge-profile-warning-dialog',
  templateUrl: './create-invoice-charge-profile-warning-dialog.component.html'
})
export class CreateInvoiceChargeProfileWarningDialogComponent
  extends DialogButtonsController
  implements OnInit
{
  message: string;

  constructor(
    public dialogRef: MatDialogRef<CreateInvoiceChargeProfileWarningDialogComponent>
  ) {
    super();
  }

  ngOnInit() {}

  submitValueAndClose(value) {
    this.dialogRef.close(value);
  }
}

