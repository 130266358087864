import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import Query from '../../core/query/query';

@Injectable()
export class ReconService extends BaseService<any> {

  constructor() {
    super('recon');
  }

  getInvoices(query) {
    return this.httpService().get(['recon', 'invoices'], this.toFilter(query));
  }

  getInventories(query?: Query): any {
    let concreteQuery = (query || new Query());
    return this.fetchAll(`${this.name}/inventories`, concreteQuery)
  }


  assignBillingAlias(alias, inventory_id) {
    return this.httpService().post([this.name, 'alias'], {alias, inventory_id});
  }

  fetchUnlinkedInventoryVendors(limit) {
    return this.httpService().get([this.name, 'vendors'], {limit});
  }

  fetchUnlinkedInventoryAccounts(limit, vendor_id) {
    return this.httpService().get([this.name, 'accounts'], {limit, vendor_id})
  }

}
