import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import {ContractService} from '../core/contract.service';

@Injectable({
  providedIn: 'root'
})

// @ts-ignore
export class ContractDetailsResolver  {

  constructor(private contractService: ContractService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const id = route.params.id;
    return this.contractService.findById(id).toPromise()
  }
}