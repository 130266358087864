export const LOOKUP_MODELS_ENUM = {
  ACCOUNT_STATUS: {
    modelName: "AccountStatus",
    displayName: "Account Status",
    system: true
  },
  INVOICE_STATUS: {
    modelName: "InvoiceStatus",
    displayName: "Invoice Status",
    system: true
  },
  DOCUMENT_INVOICE_TYPE: {
    modelName: "DocumentInvoiceType",
    displayName: "Document Type",
    system: true
  },
  SUBCLIENT: {
    modelName: "SubClient",
    displayName: "Account Subclient"
  },
  BILLING_FREQUENCY: {
    modelName: "BillingFrequency",
    displayName: "Billing Frequency"
  },
  ALERT_STATUS: {
    modelName: "AlertStatus",
    displayName: "Alert Status",
    system: true
  },
  ALERT_PRIORITY: {
    modelName: "AlertPriority",
    displayName: "Alert Priority",
    system: true
  },
  ALERT_TYPE: {
    modelName: "AlertType",
    displayName: "Alert Type",
    system: true
  },
  ALERT_CATEGORY: {
    modelName: "AlertCategory",
    displayName: "Alert Category",
    system: true
  },
  INVENTORY_STATUS: {
    modelName: "InventoryStatus",
    displayName: "Inventory Status",
    system: true
  },
  INVENTORY_TOPOLOGY: {
    modelName: "InventoryTopology",
    displayName: "Inventory Topology",
    system: true
  },
  INVENTORY_TYPE: {
    modelName: "InventoryType",
    displayName: "Inventory Type",
    system: true
  },
  CHARGE_TYPE: {
    modelName: "ChargeType",
    displayName: "Charge Type",
    system: true
  },
  UNSET_ADJUSTMENTS: {
    modelName: "UnsetAdjustments",
    displayName: "Unset Adjustments",
    system: true
  },
  CONTACT_FUNCTION: {
    modelName: "ContactFunction",
    displayName: "Contact Function",
    system: true
  },
  CONTRACT_TYPE: {
    modelName: "ContractType",
    displayName: "Contract Type",
    system: true
  },
  PROJECT_STATUS: {
    modelName: "ProjectStatus",
    displayName: "Project Status",
    system: true
  },
  DISPUTE_STATUS: {
    modelName: "DisputeStatus",
    displayName: "Dispute Status",
    system: true
  },
  DISPUTE_WITHHELD_STATUS: {
    modelName: "DisputeWithheldStatus",
    displayName: "Dispute Withheld Status",
    system: true
  },
  GL_CODE_SEGMENT: {
    modelName: "GlCodeSegment",
    displayName: "GL Code Segment",
    system: true
  },
  GL_RULE_FIELD: {
    modelName: "GlRuleField",
    displayName: "GL Rule Field",
    system: true
  },
  ORDER_STATUS: {
    modelName: "OrderStatus",
    displayName: "Order Status",
    system: true
  },
  ORDER_SERVICE_STATUS: {
    modelName: "OrderServiceStatus",
    displayName: "Order Service Status",
    system: true
  },
  ORDER_SERVICE_DISCONNECT_STATUS: {
    modelName: "OrderServiceDisconnectStatus",
    displayName: "Order Service Disconnect Status",
    system: true
  },
  ORDER_TYPE: {
    modelName: "OrderType",
    displayName: "Order Type",
    system: true
  },
  CONTACT_TYPE: {
    modelName: "ContactType",
    displayName: "Contact Type",
    system: true
  },
  RATE_AUDIT_RULE_FIELD: {
    modelName: "RateAuditRuleField",
    displayName: "Rate Audit Rule Field",
    system: true
  },
  RATE_AUDIT_OPERATOR_FIELD: {
    modelName: 'RateAuditOperatorField',
    displayName: 'Rate Audit Operator Field',
    system: true
  },
  INVOICE_ATTACHMENT_TYPE: {
    modelName: "InvoiceAttachmentType",
    displayName: "Invoice Attachment Type",
    system: true
  },

  DISPUTE_CATEGORY: {
    modelName: "DisputeCategory",
    displayName: "Dispute Category"
  },
  DNP_REASON_CODE: {
    modelName: "DnpReasonCode",
    displayName: "Invoice Do Not Process Reason Code"
  },
  INVENTORY_BW_MODEL: {
    modelName: "InventoryBwModel",
    displayName: "Inventory Bandwidth Model"
  },
  INVENTORY_PROTECTED: {
    modelName: "InventoryProtected",
    displayName: "Inventory Protected"
  },
  INVENTORY_PRIM_FAILOVER: {
    modelName: "InventoryPrimFailover",
    displayName: "Inventory Primary Failover"
  },
  INVENTORY_CKT_TYPE: {
    modelName: "InventoryCktType",
    displayName: "Inventory Service Type",
    system: true
  },
  INVENTORY_CIRCUIT_USAGE: {
    modelName: "InventoryCircuitUsage",
    displayName: "Inventory Service Usage"
  },
  MEDIA_TYPE: {
    modelName: "MediaType",
    displayName: "Media Type"
  },
  PAY_METH: {
    modelName: "PaymentMethod",
    displayName: "Payment Method"
  },
  ORDER_REJECT_REASON: {
    modelName: "OrderRejectReason",
    displayName: "Order Reject Reason"
  },
  ORDER_SERVICE_MISSED_REASON: {
    modelName: "OrderServiceMissedReason",
    displayName: "Order Service Missed Reason"
  },
  SITE_OWNER_CATEGORY: {
    modelName: "SiteOwnerCategory",
    displayName: "Site Owner Category"
  },
  SITE_TYPE: {
    modelName: "SiteType",
    displayName: "Site Type",
    system: true
  },
  QUOTE_SERVICE_TERM: {
    modelName: "QuoteServiceTerm",
    displayName: "Quote Service Term",
    system: true
  },
  INVENTORY_TECHNOLOGY: {
    modelName: 'InventoryTechnology',
    displayName: 'Inventory Technology',
    system: true,
  },
  QUOTING_SERVICE_TYPE: {
    modelName: "QuoteServiceType",
    displayName: "Quote Service Type",
    system: true
  },
  QUOTING_CLIENT_SERVICE_TYPE: {
    modelName: "QuoteClientServiceType",
    displayName: "Quote Client Service Type",
    system: false
  },
  QUOTE_SERVICE_MEDIUM: {
    modelName: "QuoteServiceMedium",
    displayName: "Quote Service Medium",
    system: true
  },
  QUOTE_SERVICE_STATUS: {
    modelName: "QuoteServiceStatus",
    displayName: "Quote Service Status"
  },
  DOCUMENT_TYPE: {
    modelName: 'DocumentType',
    displayName: "Document Type",
    system: true
  },
  QUOTE_HEADER_STATUS: {
    modelName: "QuoteHeaderStatus",
    displayName: "Quote Header Status",
    system: true
  },
  QUOTE_SERVICE_OFFER_METHOD: {
    modelName: "QuoteServiceRequestMethod",
    displayName: "Quote Service Request Method"
  },
  INVENTORY_HANDOFF_TYPE: {
    modelName: "InventoryHandoffType",
    displayName: "Inventory Handoff Type"
  },
  QUOTE_OPPORTUNITY_TYPE: {
    modelName: "QuoteOpportunityType",
    displayName: "Quote Opportunity Type"
  },
  NET_TYPE: {
    modelName: "NetType",
    displayName: "Net Type"
  },
  BANDWIDTH: {
    modelName: "Bandwidth",
    displayName: "Bandwidth",
    system: true,
  },
  SUSPENSE_STATUS: {
    modelName: "SuspenseStatus",
    displayName: "Suspense Status",
    system: true
  },
  NETWORK_HUB_TYPE: {
    modelName: "NetworkHubType",
    displayName: "Network Hub Type",
    system: true
  },
  NETWORK_HUB_STATUS: {
    modelName: "NetworkHubStatus",
    displayName: "Network Hub Status",
    system: true
  },
  NETWORK_HUB_CAPACITY: {
    modelName: "NetworkHubCapacity",
    displayName: "Network Hub Capacity",
    system: true
  },
  CUSTOMER_EQUIPMENT_TYPE: {
    modelName: "CustomerEquipmentType",
    displayName: "Customer Equipment Type"
  },
  CUSTOMER_EQUIPMENT_STATE: {
    modelName: "CustomerEquipmentState",
    displayName: "Customer Equipment State",
    system: true
  },
  CUSTOMER_EQUIPMENT_TRANSACTION_TYPE: {
    modelName: "CustomerEquipmentTransactionType",
    displayName: "Customer Equipment Transaction Type",
    system: true
  },
  CUSTOMER_EQUIPMENT_PRICING_MODEL: {
    modelName: "CustomerEquipmentPricingModel",
    displayName: "Customer Equipment Pricing Model"
  },
  CUSTOMER_EQUIPMENT_MANUFACTURER: {
    modelName: "CustomerEquipmentManufacturer",
    displayName: "Customer Equipment Manufacturer"
  },
  TN_TFN_STATUS: {
    modelName: "TnTfnStatus",
    displayName: "Status",
    system: true
  },
  TN_TFN_TYPE: {
    modelName: "TnTfnType",
    displayName: "Type",
    system: true
  },
  TN_TRANSACTION_TYPE: {
    modelName: "TelephoneTransactionType",
    displayName: "Transaction Type",
    system: true
  },
  TFN_VALIDATION: {
    modelName: "TfnNumberValidation",
    displayName: "TFN Validation",
    system: false
  },
  WIRELESS_DEVICE_TYPE: {
    modelName: 'WirelessDeviceType',
    displayName: 'Device Type',
    system: true
  },
  WIRELESS_DEVICE_STATUS: {
    modelName: 'WirelessDeviceStatus',
    displayName: 'Status',
    system: true
  },
  WIRELESS_DEVICE_MANUFACTURER: {
    modelName: 'WirelessDeviceManufacturer',
    displayName: 'Manufacturer',
    system: true
  },
  WIRELESS_SIM_TYEP: {
    modelName: 'WirelessSimType',
    displayName: 'Type',
    system: true
  },
  WIRLESS_SERVICE_PLAN_TYPE: {
    modelName: 'WirelessServicePlanType',
    displayName: "Type",
    system: true,
  },
  COUNTRY: {
    modelName: 'Country',
    displayName: 'Country',
    system: true
  },
  WIRELESS_USER_STATUS: {
    modelName: "WirelessUserStatus",
    displayName: "Wireless User Status",
    system: true
  },
  TAX_TYPE: {
    modelName: "TaxType",
    displayName: "Tax Type",
    system: true
  },
  RULE_TYPE: {
    modelName: "RuleType",
    displayName: "Rule Type",
    system: true
  },
  SYSTEM_REGION: {
    modelName: "SystemRegion",
    displayName: "System Region",
    system: true
  },
  AUDIT_TYPE: {
    modelName: 'AuditType',
    displayName: 'Audit Type',
    system: true
  },
  RATE_TYPE: {
    modelName: "RateType",
    displayName: "Rate Type",
    system: true
  },
  RATE_CATEGORY: {
    modelName: "RateCategory",
    displayName: "Rate Category"
  },
  UNIT_OF_MEASURE: {
    modelName: "UnitOfMeasure",
    displayName: "Contract Rate UoM"
  },
  CONTRACT_SCHEDULE_SERIES: {
    modelName: "ContractScheduleSeries",
    displayName: "Contracts Schedule Series"
  },
  CONTRACT_SCHEDULE_CATEGORY: {
    modelName: "ContractScheduleCategory",
    displayName: "Contracts Schedule Category"
  },
  CONTRACT_RATE_QTY_UOM: {
    modelName: 'RateQtyUom',
    displayName: 'Rate Qty Unit of Measure'
  },
  CONTRACT_SCHEDULE_COMMITMENT_TYPE: {
    modelName: "ContractScheduleCommitmentType",
    displayName: "Contract Schedule Commitment Type"
  },
  CONTRACT_DOCUMENT_TYPE: {
    modelName: "ContractDocumentType",
    displayName: "Contract Document Type"
  },
  TERM_UNIT_OF_MEASURE: {
    modelName: "TermUnitOfMeasure",
    displayName: "Term Unit Of Measure"
  },
  QTY_UNIT_OF_MEASURE: {
    modelName: "QtyUnitOfMeasure",
    displayName: "Qty Unit Of Measure"
  },
  INVENTORY_CONTRACT_TYPE: {
    modelName: "InventoryContractType",
    displayName: "Inventory Contract Type",
    system: true
  },
  RELATIONSHIP_TYPE: {
    modelName: "InventoryRelationshipType",
    displayName: "Relationship Type"
  },
  JUR: {
    modelName: "Jur",
    displayName: "Jur",
    system: true
  },
  AT_EXP_EVENT: {
    modelName: "AtExpirationEvent",
    displayName: "At Expiration Event",
    system: true
  },
  BULK_UPLOAD_TRANSACTION_TYPE:{
    modelName: "BulkUploadTransactionType",
    displayName: "Bulk Upload Transaction Type",
    system: true
  },
  SERVICE_PERIOD_MONTH:{
    modelName: "ServicePeriodMonth",
    displayName: "Service Period Month",
    system: true
  },
  SERVICE_PERIOD_RANGE:{
    modelName: "ServicePeriodRange",
    displayName: "Service Period Range",
    system: true
  },
  MANUAL_ADJUSTMENT_REASON_CODE: {
    modelName: "ManualAdjustmentReasonCode",
    displayName: "Invoice Manual Adjustment Process Reason Code"
  },
  REPLACE_INVOICE_CHARGE_PROFILE: {
    modelName: "ReplacementInvoiceChargeProfile",
    displayName: "Replacement Invoice Charge Profile"
  }
};
