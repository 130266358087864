import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerService} from "./core/customer.service";
import {CustomerComponent} from './customer.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "app/material-barrel/material-barrel.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {CustomerRoutingModule} from "./customer-routing.module";
import {CustomerManageDialogComponent} from './shared/customer-manage-dialog/customer-manage-dialog.component';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxDataGridModule} from 'devextreme-angular/ui/data-grid';
import {CustomerListComponent} from './customer-list/customer-list.component';
import {CustomerSneakPeekComponent} from './shared/customer-sneak-peek/customer-sneak-peek.component';
import {CustomerGridService} from "./core/customer-grid.service";
import {CustomerFilterService} from "./core/customer-filter.service";
import { CaCustomerPickerComponent } from './shared/customer-picker/ca-customer-picker.component';
import {LocationModule} from "../location/location.module";
import {SharedModule} from "../shared/shared.module";
import { CoreModule } from "../core/core.module";
import {AddressModule} from "../address/address.module";
import {NgxMaskModule} from "ngx-mask";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        LocationModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        DxButtonModule,
        DxDataGridModule,
        CustomerRoutingModule,
        CoreModule,
        AddressModule,
        NgxMaskModule
    ],
    exports: [CaCustomerPickerComponent],
    providers: [
        CustomerService,
        CustomerGridService,
        CustomerFilterService
    ],
    declarations: [
        CustomerComponent,
        CustomerManageDialogComponent,
        CustomerListComponent,
        CustomerSneakPeekComponent,
        CaCustomerPickerComponent
    ]
})
export class CustomerModule {
}
