import {
  Component, forwardRef, Input, OnInit
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable } from "rxjs";
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from "../../../shared/picker/ca-picker-base.component";
import { QuotingService } from "../../core/quoting.service";
import { BandwidthService } from "../../../bandwidth/core/bandwidth.service";
import { LOOKUP_MODELS_ENUM } from "../../../dictionary/core/lookup-models.enum";
import Query from "../../../core/query/query";
import {DictionaryService} from "../../../dictionary/core/dictionary.service";

@Component({
  selector: 'ca-client-service-type-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => CaClientServiceTypePickerComponent),
          multi: true
      },
      QuotingService,
      BandwidthService
  ]
})
export class CaClientServiceTypePickerComponent extends PickerComponentBase implements OnInit {

  readonly TEXT_FIELD = 'value';
  readonly KEY_FIELD = 'id';
  @Input('formControlName') formControlName;
  @Input('initialItem') initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('standalone') standalone: boolean = true;

  @Input('query') query: Query;

  public control;

  constructor(public dictionaryService: DictionaryService) { super(); }

  loadEntries(): Observable<any> {
    this.control = this.pickerForm.controls[this.formControlName];
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTING_CLIENT_SERVICE_TYPE.modelName);
  }

  search(value: string) {
    console.log('Serach method not implemented');
    return null;
  }

}
