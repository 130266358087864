
<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
    <button mat-icon-button matTooltip="Back" (click)="back()">
      <ca-icon [key]="'BACK'"></ca-icon>
    </button>
    <span class="toolbar-details-title">{{ schedule?.schedule_name }}</span>
  
    <span class="example-fill-remaining-space">&nbsp;</span>

    <ng-container *permission="'Modify'">
        <button mat-icon-button matTooltip="Edit Schedule"
                (click)="editSchedule()"
                *ngIf="isSummaryTab">
          <ca-icon [key]="'EDIT'"></ca-icon>
        </button>
      </ng-container>

    <ng-container *permission="'Create'">
        <button mat-icon-button matTooltip="New T/C"
                *ngIf="isTermsConditionsTab" 
                (click)="addNewTC()">
          <ca-icon [key]="'ADD_CONTRACT_SECTION'"></ca-icon>
        </button>
      </ng-container>

    <ng-container *permission="'Modify'">
      <button mat-icon-button matTooltip="Edit T/C"
            (click)="editTC()"
            [disabled]="isEditTCDisabled"
            *ngIf="isTermsConditionsTab">
        <ca-icon [key]="'EDIT'"></ca-icon>
      </button>
    </ng-container>
  
    <ng-container *permission = "'Create'">
      <button mat-icon-button matTooltip="New Rate"
            (click)="addRateData()" 
            *ngIf="isRatesTab">
        <ca-icon [key]="'ADD_CONTRACT_SECTION'"></ca-icon>
      </button>
    </ng-container>
  
    <button mat-icon-button matTooltip="Save Rates"
            [disabled]="isSaveDisabled" 
            (click)="saveRates()" 
            *ngIf="isRatesTab">
      <ca-icon [key]="'APPLY'"></ca-icon>
    </button>
  
  
    <ng-container *permission="'Delete'">
      <button mat-icon-button matTooltip="Delete Rates"
            [disabled]="isDeleteRateDisabled"
            (click)="deleteRate()" 
            *ngIf="isRatesTab">
        <ca-icon [key]="'DELETE'"></ca-icon>
      </button>
    </ng-container>
  
    <ng-container *permission="'Delete'">
      <button mat-icon-button matTooltip="Delete T/C"
            (click)="deleteTC()"
            [disabled]="isEditTCDisabled"
            *ngIf="isTermsConditionsTab">
        <ca-icon [key]="'DELETE'"></ca-icon>
      </button>
    </ng-container>
    
    <div class="vertical-divider" *ngIf="isTermsConditionsTab || isRatesTab"></div>
    <app-document-manager-commands *ngIf="isAttachmentsTab"
      [deleteDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DELETE_DOCUMENT_ACTION"
      [deleteDocumentDisablePredicate]="documentNotSelected"
      [uploadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.UPLOAD_DOCUMENT_ACTION"
      [uploadDocumentDisablePredicate]="false"
      [downloadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DOWNLOAD_DOCUMENT_ACTION"
      [downloadDocumentDisablePredicate]="documentNotSelected">
    </app-document-manager-commands>
  
    <button mat-icon-button matTooltip="Download CSV" *ngIf="isTermsConditionsTab || isRatesTab" (click)="downloadCsv()">
      <ca-icon [key]="'DOWNLOAD'"></ca-icon>
    </button>
    
    <button [hidden]="isSummaryTab || isAttachmentsTab"
      mat-icon-button matTooltip="Settings"
      (click)="gridSettings()">
      <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
    </button>

    <div class="vertical-divider"></div>
  
    <button [disabled]="isFilterDisabled"
            [ngClass]="{'on': isFilterOn}"
            (click)="toggleFilter()" 
            mat-icon-button matTooltip="Filter">
      <ca-icon [key]="'FILTER'"></ca-icon>
    </button>
  
    <button matTooltip="Notes" [ngClass]="{'on': isNotesOn}"
      (click)="toogleNotes()" mat-icon-button>
      <ca-icon [key]="notesCount > 0 ? 'NOTES' : 'NOTES_EMPTY'"></ca-icon>
    </button>
  </mat-toolbar>