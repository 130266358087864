import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';

@Injectable()
export class ReconExcludedService extends BaseService<any> {

  constructor() {
    super('recon-excluded');
  }

}
