import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxsModule } from "@ngxs/store";
import { DxDataGridModule } from "devextreme-angular/ui/data-grid";
import { DxDateBoxModule } from "devextreme-angular/ui/date-box";

import { LocationModule } from "app/location/location.module";
import { MaterialModule } from "app/material-barrel/material-barrel.module";
import { AccountModule } from "../account/account.module";
import { FilterFeatureModule } from "../common/filter/filter-feature.module";
import { ContactModule } from "../contact/contact.module";
import { ContractModule } from "../contract/contract.module";
import { CoreModule } from "../core/core.module";
import { CustomerModule } from "../customer/customer.module";
import { DemarcModule } from "../demarc/demarc.module";
import { DictionaryModule } from "../dictionary/dictionary.module";
import { GLRuleStringGridService } from "../gl-rule/core/gl-rule-string-grid.service";
import { GLStringService } from "../gl-string/core/gl-string.service";
import { GLSystemRuleModule } from "../gl-system-rules/gl-system-rule.module";
import { SharedModule } from "../shared/shared.module";
import { SiteModule } from "../site/site.module";
import { VendorModule } from "../vendor/vendor.module";
import { InventoryBillingFilterService } from "./core/inventory-billing-filter.service";
import { InventoryBillingGridService } from "./core/inventory-billing-grid.service";
import { InventoryCircuitsGridService } from "./core/inventory-circuits-grid.service";
import { InventoryContractTermGridService } from "./core/inventory-contract-term-grid-service";
import { InventoryFilterService } from "./core/inventory-filter.service";
import { InventoryGridService } from "./core/inventory-grid.service";
import { InventoryOrderGridService } from "./core/inventory-order-grid-service";
import { InventoryPricingGridService } from "./core/inventory-pricing-grid.service";
import { InventoryRateFilterService } from "./core/inventory-rate-filter.service";
import { InventoryService } from "./core/inventory.service";
import { RateContractScheduleInventoryGridService } from "./core/rate-contract-schedule-inventory-grid-service";
import { SvcOrderServiceInventoryGridService } from "./core/svc-order-service-inventory-grid.service";
import { InventoryBillingComponent } from "./inventory-details/inventory-billing/inventory-billing.component";
import { InventoryBillingState } from "./inventory-details/inventory-billing/state/inventory-billing.state";
import { InventoryCodeComponent } from "./inventory-details/inventory-code/inventory-code.component";
import { InventoryCodeState } from "./inventory-details/inventory-code/state/inventory-code.state";
import { InventoryContractTermsComponent } from "./inventory-details/inventory-contract-terms/inventory-contract-terms.component";
import { InventoryContractTermsState } from "./inventory-details/inventory-contract-terms/state/inventory-contract-terms.state";
import { InventoryDetailsTabsComponent } from "./inventory-details/inventory-details-tabs/inventory-details-tabs.component";
import { InventoryDetailsToolbarComponent } from "./inventory-details/inventory-details-toolbar/inventory-details-toolbar.component";
import { InventoryDetailsComponent } from "./inventory-details/inventory-details.component";
import { InventoryDocumentComponent } from "./inventory-details/inventory-document/inventory-document.component";
import { InventoryDocumentState } from "./inventory-details/inventory-document/state/inventory-document.state";
import { InventoryOrderComponent } from "./inventory-details/inventory-order/inventory-order.component";
import { InventoryOrderState } from "./inventory-details/inventory-order/state/inventory-order.state";
import { InventoryPricingComponent } from "./inventory-details/inventory-pricing/inventory-pricing.component";
import { InventoryPricingState } from "./inventory-details/inventory-pricing/state/inventory-pricing.state";
import { InventoryRelatedCircuitComponent } from "./inventory-details/inventory-related-circuit/inventory-related-circuit.component";
import { InventoryRelatedCircuitState } from "./inventory-details/inventory-related-circuit/state/inventory-related-circuit.state";
import { InventorySiteComponent } from "./inventory-details/inventory-site/inventory-site.component";
import { InventorySiteState } from "./inventory-details/inventory-site/state/inventory-site.state";
import { InventorySummaryComponent } from "./inventory-details/inventory-summary/inventory-summary.component";
import { InventorySummaryState } from "./inventory-details/inventory-summary/state/inventory-summary.state";
import { InvoiceChargeProfileComponent } from "./inventory-details/invoice-charge-profile/invoice-charge-profile.component";
import { InventoryDetailsState } from "./inventory-details/state/inventory-details.state";
import { InventoryListComponent } from "./inventory-list/inventory-list.component";
import { InventoryRoutingModule } from "./inventory-routing.module";
import { InventoryContractTermManageDialogComponent } from "./shared/inventory-contract-term-manage-dialog/inventory-contract-term-manage-dialog.component";
import { InventoryManageDialogComponent } from "./shared/inventory-manage-dialog/inventory-manage-dialog.component";
import { CaInventoryPickerComponent } from "./shared/inventory-picker/ca-inventory-picker.component";
import { InventoryPricingDialogComponent } from "./shared/inventory-pricing-dialog/inventory-pricing-dialog.component";
import { CaInventoryRelationshipTypePickerComponent } from "./shared/inventory-relationship-type-picker/inventory-relationship-type-picker";
import { InventorySiteManageDialogComponent } from "./shared/inventory-site-manage-dialog/inventory-site-manage-dialog.component";
import { InventorySneakPeekComponent } from "./shared/inventory-sneak-peek/inventory-sneak-peek.component";
import { InvoiceChargeProfileManageDialogComponent } from "./shared/invoice-charge-profile-manage-dialog/invoice-charge-profile-manage-dialog.component";
import { InventoryState } from "./state/inventory.state";

@NgModule({
  imports: [
    CommonModule,
    InventoryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DxDataGridModule,
    DxDateBoxModule,
    SharedModule,
    DictionaryModule,
    AccountModule,
    VendorModule,
    CustomerModule,
    ContractModule,
    CoreModule,
    SiteModule,
    GLSystemRuleModule,
    ContactModule,
    DemarcModule,
    LocationModule,
    NgxsModule.forFeature([
      InventoryState,
      InventoryDetailsState,
      InventoryBillingState,
      InventoryCodeState,
      InventoryContractTermsState,
      InventorySiteState,
      InventorySummaryState,
      InventoryOrderState,
      InventoryDocumentState,
      InventoryOrderState,
      InventoryRelatedCircuitState,
      InventoryPricingState,
    ]),
    FilterFeatureModule,
  ],
  declarations: [
    InventoryManageDialogComponent,
    InventoryListComponent,
    InventorySneakPeekComponent,
    InventoryDetailsComponent,
    InventorySiteManageDialogComponent,
    InventoryContractTermManageDialogComponent,
    InventoryPricingDialogComponent,
    CaInventoryPickerComponent,
    CaInventoryRelationshipTypePickerComponent,
    InventorySummaryComponent,
    InventorySiteComponent,
    InventoryOrderComponent,
    InventoryBillingComponent,
    InventoryCodeComponent,
    InventoryContractTermsComponent,
    InventoryPricingComponent,
    InventoryRelatedCircuitComponent,
    InventoryDocumentComponent,
    InventoryDetailsTabsComponent,
    InventoryDetailsToolbarComponent,
    InvoiceChargeProfileComponent,
    InvoiceChargeProfileManageDialogComponent
  ],
  providers: [
    InventoryService,
    InventoryGridService,
    InventoryFilterService,
    InventoryPricingGridService,
    GLRuleStringGridService,
    GLStringService,
    InventoryCircuitsGridService,
    InventoryBillingFilterService,
    InventoryBillingGridService,
    InventoryOrderGridService,
    InventoryContractTermGridService,
    SvcOrderServiceInventoryGridService,
    RateContractScheduleInventoryGridService,
    InventoryRateFilterService,
  ],
  exports: [
    InventorySneakPeekComponent,
    CaInventoryPickerComponent,
    CaInventoryRelationshipTypePickerComponent,
  ],
})
export class InventoryModule {}
