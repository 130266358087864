<mat-toolbar color="accent" mat-scroll-shrink>
  <span class="toolbar-title">Quotes</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button  *permission="'Create'" matTooltip="New Quote" mat-icon-button (click)="newQuote()">
      <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button  *permission="'Modify'" mat-icon-button matTooltip="Refresh List" (click)="refresh()">
      <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>
  <button mat-icon-button
          matTooltip="Report"
          [matMenuTriggerFor]="srmReportMenu"
          [disabled]="serviceLevel">
    <ca-icon [key]="'QUOTE_SRM_REPORT'"></ca-icon>
  </button>
  <button mat-icon-button [ngClass]="{'on': serviceLevel}"
          (click)="serviceLevelToggle()"
          matTooltip="Service Level">
    <ca-icon [key]="'SERVICE_LEVEL'"></ca-icon>
  </button>
  <mat-menu #srmReportMenu="matMenu">
    <button mat-menu-item type="button" (click)="srmReport(SRM_REPORTS.QUOTE_SUMMARY, SRM_REPORT_VIEW_TYPE.VIEW)">
      Quote Summary Report
    </button>
    <button mat-menu-item type="button" (click)="srmReport(SRM_REPORTS.QUOTE_SUMMARY, SRM_REPORT_VIEW_TYPE.CSV)">
      Quote Summary CSV
    </button>
    <button mat-menu-item type="button" (click)="srmReport(SRM_REPORTS.QUOTE_DETAILED_SUMMARY, SRM_REPORT_VIEW_TYPE.VIEW)">
      Quote Detailed Report
    </button>
    <button mat-menu-item type="button" (click)="srmReport(SRM_REPORTS.QUOTE_DETAILED_SUMMARY, SRM_REPORT_VIEW_TYPE.CSV)">
      Quote Detailed CSV
    </button>
  </mat-menu>
  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Download CSV" (click)="csv()">
      <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button (click)="gridSettings()" matTooltip="Settings">
      <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <div class="vertical-divider"></div>
  <button matTooltip="Filter" [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME)}"  (click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)" mat-icon-button>
      <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
  <button #toggleDetailsEnableButton [ngClass]="{'on': sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}" [disabled]="!selection" 
        (click)="sider?.toggle(SECTIONS.DETAILS_SECTION_NAME)" 
        mat-icon-button matTooltip="Info">
      <ca-icon [key]="'INFO'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content app-content-list dx-nodata-absolute">
  <dx-data-grid #dataGrid
            [ngClass]="{'hidden': serviceLevel}"
            (onRowClick)="onRowClick($event)"
            (onSelectionChanged)="onSelectionChanged($event)"
            (onCellClick)="onCellClick($event)"
            [columns]="columns"
            [height]="'100%'"
            [width]="'100%'"
            class="app-grid-with-pager"
            [dataSource]="quotes">
  </dx-data-grid>
  <ca-pager *ngIf="!serviceLevel" [gridPager]="defaultGridPager" [pageContext]="quotingGridService"  [query]="query" class="app-pager ca-default-border-top" (onChange)="loadData(query)"></ca-pager>
  <dx-data-grid #serviceLevelGrid
                [ngClass]="{'hidden': !serviceLevel}"
                (onRowClick)="onRowClick($event)"
                (onSelectionChanged)="onSelectionService($event)"
                (onCellClick)="onServiceCellClick($event)"
                [columns]="serviceGridColumns"
                [height]="'100%'"
                [width]="'100%'"
                class="app-grid-with-pager"
                [dataSource]="services">
    <div *dxTemplate="let cell of 'emptyCellTemplate'">
      <div>{{cell.text || 'Not selected'}}</div>
    </div>

    <div *dxTemplate="let d of 'addresstemplate'" style="text-align: left;" class="customer-inv-grid-site-template">
      <div class="customer-inv-grid-sites-rows-wraper">
        <div caGridTooltip class="customer-inv-grid-site-name">{{d.data.address?.address1}}</div>
      </div>
    </div>
  </dx-data-grid>
  <ca-pager  *ngIf="serviceLevel" [gridPager]="defaultServiceGridPager" [pageContext]="serviceLevelGridService"  [query]="serviceQuery" class="app-pager ca-default-border-top" (onChange)="loadServiceData(serviceQuery)"></ca-pager>
  <mat-sidenav #panelSide mode="side" position="end" opened="false">
    <new-app-filter *ngIf="isVisibleSideSection('filter') && !serviceLevel"
                [query]="query"
                [filterService]="quotingFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter()">
    </new-app-filter>
    <new-app-filter *ngIf="isVisibleSideSection('filter') && serviceLevel"
                [query]="serviceQuery"
                [filterService]="serviceLevelFilterService"
                (filterRequested)="filterServiceData($event)"
                (clearFilterRequested)="clearFilterService()">
    </new-app-filter>
    <app-quoting-sneak-peek [quote]="selection"
                            [serviceLevel]="serviceLevel"
                            (closeRequested)="sider?.close()"
                            [ngClass]="{'hidden': !sider?.isActive(SECTIONS.DETAILS_SECTION_NAME)}">
                            <ca-quoting-service-sneak-peek
                            [quoteService]="selectedQuoteService"
                            *ngIf="selectedQuoteService && serviceLevel"></ca-quoting-service-sneak-peek>
    </app-quoting-sneak-peek>

  </mat-sidenav>
</mat-sidenav-container>
