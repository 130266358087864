<app-invoice-details-toolbar
	(toggleNotesRequested)="toggleSider(SECTIONS.NOTES_SECTION_NAME)"
	[isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
	(toggleHistoryRequested)="toggleSider(SECTIONS.HISTORY_SECTION_NAME)"
	[isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"
	[isCSVDisabled]="false"
	[isCompleteAlertsHidden]="false"
	[isCompleteAlertsDisabled]="isCompleteDisabled()"
	(completeAlertsRequested)="completeAlerts()"
	[isChangeAlertOwnerHidden]="false"
	[isChangeAlertOwnerDisabled]="isCompleteDisabled()"
	(assignAlertsRequested)="assignAlert()"
	(downloadCSVRequested)="onDownloadCSVRequested()"
	[isDownloadPDFHidden]="false"
	[isDownloadContainerHidden]="false"
	[isDoNotProcessHidden]="true"
	[isDocumentTypeHidden]="true"
	[isAddDisputeHidden]="true"
	[isOnHoldDisabled]="true"
	[isGLChargeViewSwitchHidden]="true"
	[isAddDirectGLHidden]="true"
	[isEditDirectGLHidden]="true"
	[isGridSettingsHidden]="false"
	[isGridSettingsDisabled]="true"
	[isFilterDisabled]="true"
	[isFilterHidden]="false"
	(backRequested)="back()"
	[notesCount]="notesCount"
></app-invoice-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
	<app-invoice-details-tabs
		[activeTabIndex]="4"
		[badgeCount]="audits.length"
		[invoice]="invoice"
	>
	</app-invoice-details-tabs>
	<div
		class="app-content app-content-list tabbed-content tabbed-content-no-scroll-except-first-ch"
		style="top: 0"
	>
		<!-- Tabs Body -->
		<div class="tabs-body tab-charges grid-container dx-nodata-absolute">
			<dx-data-grid
				class="app-grid-with-pager"
				#auditGrid
				[height]="'100%'"
				[columns]="auditColumns"
				[dataSource]="audits"
				(onSelectionChanged)="onSelectionChanged($event)"
				(onRowClick)="onAuditRowClick($event)"
				(onCellClick)="onAlertCellClick($event)"
				(onRowDblClick)="onRowDblClick($event)"
			>
				<div
					*dxTemplate="let d of 'selectionBadgeHeaderTemplate'"
					class="grid-selection-badge-container"
				></div>
				<div *dxTemplate="let d of 'selectionBadgeCellTemplate'"></div>

				<div *dxTemplate="let d of 'dateTimeTemplate'">
					<div caGridTooltip class="right">
						{{ d.value.closed_at | caDateTime }}
					</div>
				</div>
				<div *dxTemplate="let d of 'dateTimeTemplate'">
					<div caGridTooltip class="right">
						{{ d.value.last_modified_at | caDateTime }}
					</div>
				</div>
				<div *dxTemplate="let d of 'dateTimeTemplate'">
					<div caGridTooltip class="right">
						{{ d.value | caDateTime }}
					</div>
				</div>
			</dx-data-grid>
			<ca-pager
				class="app-pager ca-default-border-top"
				[gridPager]="defaultGridPager"
				[pageContext]="commonAlertService"
				[query]="auditQuery"
				(onChange)="onPageAlertChange()"
			></ca-pager>
		</div>
	</div>
	<mat-sidenav #panelSide [mode]="'side'" position="end" [opened]="false">
		<ca-history
			#history
			[entityId]="invoice?.invoice_id"
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }"
			[entityType]="'invoice'"
			[invoice]="invoice"
		></ca-history>
		<ca-notes
			#testNotes
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
			[entityId]="invoice?.invoice_id"
			[entityType]="SYSTEM_MODULE.INVOICE"
			(countChanged)="onNotesCountChanged($event)"
			(notesCountChanged)="getDNPInfo($event)"
		>
			<ca-account-notes
				[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
				[entityId]="invoice?.account?.id"
				[entityType]="SYSTEM_MODULE.ACCOUNT"
				[entityName]="'Account'"
				[enableReply]="false"
			></ca-account-notes>
		</ca-notes>
	</mat-sidenav>
</mat-sidenav-container>
