import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { ContractRate } from './contract-rate';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class ContractInstanceRateService extends BaseService<ContractRate> {

  constructor(private entityLock: EntityLockService) {
    super('contract-instance-rate', entityLock);
  }


}
