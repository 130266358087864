import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import {SvcOrderService} from "./svc-order.service";

@Injectable({
  providedIn: 'root'
})

// @ts-ignore
export class SvcOrderDetailsResolver  {

  constructor(private svcOrderService: SvcOrderService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const id = route.params.id;
    return this.svcOrderService.findById(id).toPromise()
  }
}
