<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Contract Term</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">

  <form class="ca-form" [formGroup]="form" fxLayout="column">

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-lookup-model-picker
          caInput
          [lookupModel]="INVENTORY_CONTRACT_TYPE"
          formControlName="contract_type_id"
          placeholder="Contract Type"
          [pickerForm]="form"
          [required]="true"
          [clearEnabled]="true">
        </ca-lookup-model-picker>
      </div>
      <div class="flex-item caGap" fxFlex>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT">
        <ca-contract-picker
          clearEnabled="true"
          caInput
          #contractPicker
          name="contract_id"
          formControlName="contract_id"
          [query]="contractQuery"
          [pickerForm]="form"
          placeholder="Contract"
          [showOrderSvc]="false"
          [showContract]="true"
          [vendorId]="vendorId"
        >
        </ca-contract-picker>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER">
        <ca-contract-picker
          clearEnabled="true"
          caInput
          #contractPicker
          name="svc_order_id"
          formControlName="svc_order_id"
          [query]="contractQuery"
          [pickerForm]="form"
          placeholder="Svc Order"
          [showOrderSvc]="true"
          [vendorId]="vendorId"
        >
        </ca-contract-picker>
      </div>
      <div class="flex-item caGap " fxFlex *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.CONTRACT">
        <ca-contract-schedule-picker
          caInput
          clearEnabled="true"
          #contractSchedulePicker
          formControlName="contract_schedule_id"
          [disabled]="!form.get('contract_id').value"
          [query]="contractScheduleQuery"
          [pickerForm]="form"
          [contractId]="form.get('contract_id').value"
          [svcOrderServices]="false"
          placeholder="Contract Schedule">
        </ca-contract-schedule-picker>
      </div>
      <div class="flex-item caGap" fxFlex *ngIf="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER">
        <ca-contract-schedule-picker
          caInput
          clearEnabled="true"
          #contractSchedulePicker
          formControlName="svc_order_service_id"
          [disabled]="!form.get('svc_order_id').value"
          [query]="svcOrderServiceQuery"
          [pickerForm]="form"
          [svcOrderServices]="true"
          [svcOrderId]="form.get('svc_order_id').value"
          placeholder="Svc Order Service">
        </ca-contract-schedule-picker>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-currency-picker
          #currencyPicker
          clearEnabled="true"
          caInput
          (onSelectionChange)="onCurrencyChange($event)"
          formControlName="currency_id"
          [pickerForm]="form"
          [required]="true"
          placeholder="Currency"></ca-currency-picker>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-date-picker
          caInput
          placeholder="Start Billing Date"
          formControlName="start_billing_date">
        </ca-date-picker>
      </div>
      <div class="flex-item caGap" fxFlex>
        <ca-date-picker
          [disabled]="true"
          caInput
          placeholder="Expiration Date"
          formControlName="expiration_date">
        </ca-date-picker>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-currency caInput formControlName="mrc" placeholder="MRC" required="true" [currency]="selectedCurrency" [disabled]="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER"></ca-currency>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-currency caInput formControlName="nrc" placeholder="NRC" required="true" [currency]="selectedCurrency" [disabled]="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.SVC_ORDER"></ca-currency>
      </div>
      <div class="flex-item caGap" fxFlex>
        <mat-form-field caInput>
          <mat-label>Term</mat-label>
          <input matInput formControlName="term" type="number" [required]="form.get('contract_type_id').value === INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN" [readonly]="form.get('contract_type_id').value !== INVENTORY_CONTRACT_TYPE_ENUM.UNKNOWN">
        </mat-form-field>
      </div>
    </div>


  </form>
</div>

  <mat-dialog-actions class="ca-align-baseline ca-dialog-action" style="position: initial; width: 584px;">
    <div class="example-fill-remaining-space">
      <ca-confirm-delete-button
        *ngIf="isUpdate"
        #genericButton
        (deleteRequested)="deleteOrderService($event)"
        [confirmText]="'Delete?'"></ca-confirm-delete-button>
    </div>

    <button mat-button #cancelButton type="submit" (click)="cancel()">
      Cancel
    </button>

    <button mat-button #saveButton type="submit" (click)="onSubmit(form)"
            [disabled]="form?.invalid">
      Save
    </button>
  </mat-dialog-actions>

