import {Component, OnInit, ViewChild} from '@angular/core';
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {ActivatedRoute, Router} from "@angular/router";
import {DxDataGridComponent} from "devextreme-angular";
import {InvoiceDocument} from "../../core/invoice-document";
import {InvoiceDocumentService} from "../../core/invoice-document.service";
import {InvoiceDocumentGridService} from "../../core/invoice-documents-grid.service";
import {DocumentQuery} from "../../../shared/document/core/document.query";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {Observable, Subject, forkJoin} from "rxjs";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {Select, Store} from "@ngxs/store";
import {UpdateInvoiceDocumentSiderSection} from "./store/invoice-document.actions";
import {InvoiceFlowService} from "../../core/invoice-flow.service";
import {INVOICE_STATUS_ENUM} from "../../core/invoice-status.enum";
import {InvoiceDocumentDialogComponent} from "../../shared/invoice-document-dialog/invoice-document-dialog.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {HistoryComponent} from "../../../shared/history/history.component";
import {ConfigService} from "../../../core/config/config.service";
//Helper
import {isReleaseVersionGreaterThenAvailableVersion, getReleaseNumberOnly} from './helper/document';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  readonly SECTIONS = {
    FILTER_SECTION_NAME: 'filter',
    DETAILS_SECTION_NAME: 'details',
    HISTORY_SECTION_NAME: "history",
    NOTES_SECTION_NAME: 'notes',
  };
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  public DOCUMENT = {
    entity: null,
    folder: 'invoice-document',
    subfolder: ''
  };

  invoice: InvoiceFacepage;
  documentColumns: any;
  documents: InvoiceDocument[] = [];
  config: any = {};
  query: DocumentQuery = new DocumentQuery({orderBy: [["id", "ASC"]]});
  defaultGridPager: number;
  messages: IMessagesResourceService;
  sider: Sider;
  notesCount = 0;
  readonly invoiceStatusEnum = INVOICE_STATUS_ENUM;
  //TODO: Change variable name. What mean dnp ?
  dnpInfo: any = null;
  documentSelection: InvoiceDocument;
  isSelectedDocumentRow: boolean = false;
  isSelectedSystemFile: boolean = false;
  readonly MESSAGES_MODULE: string = 'invoice';
  readonly gridMessage = `This invoice was loaded prior to the Documents Tab feature. Use the Download Arrow icon above to download the container file.`;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild("documentsGrid") documentsGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide;
  @ViewChild('notes') notes;
  @ViewChild('history') history: HistoryComponent;

  @Select(state => state.invoice_alert.activeSiderSection) $activeSiderSection: Observable<any>;

  constructor(
    private readonly route: ActivatedRoute,
    public router: Router,
    public invoiceDocumentGridService: InvoiceDocumentGridService,
    public alertService: AlertService,
    public invoiceDocumentService: InvoiceDocumentService,
    public store: Store,
    public invoiceFlowService: InvoiceFlowService,
    public dialogService: DialogService,
    public configService: ConfigService,
  ) {
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

  }

  ngOnInit(): void {
    this.invoice = this.route.parent.snapshot.data.invoice;
    this.documentColumns = this.invoiceDocumentGridService.columns();
    this.loadDocumentsAndConfig(this.invoice)
    // this.getOnHoldInfo()
    this.notes && this.notes.emitCount();
    //this.getDNPInfo(this.invoice.notes, false)
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();
  }


  createInstanceDocumentGrid() {
    const {version, invoice_document} = this.config;
    const appVersion = getReleaseNumberOnly(version);
    const invoice_document_available_version = getReleaseNumberOnly(invoice_document);
    const isGreater = isReleaseVersionGreaterThenAvailableVersion(appVersion, invoice_document_available_version);
    this.invoiceDocumentGridService.create(this.documentsGrid.instance, {
      noDataText: isGreater ? this.gridMessage : this.invoiceDocumentGridService.noDataMessage
    });
  }

  loadDocumentsAndConfig({invoice_id}: InvoiceFacepage) {
    this.query.set('invoice_id', invoice_id);

    forkJoin([this.invoiceDocumentService.getDocuments(this.query), this.configService.findAll()]).subscribe(([documentData, configData]) => {
      this.config = configData;
      this.documents = documentData["items"];
      this.query.total = documentData["total"];
      this.createInstanceDocumentGrid();
    }, () => {
      this.alertService.error('', this.messages.get('INVOICE_DOCUMENT_LOAD_ERROR'));
    })
  }

  loadDocuments({invoice_id}: InvoiceFacepage) {
    this.query.set('invoice_id', invoice_id);
    this.invoiceDocumentService.getDocuments(this.query).subscribe(
      result => {
        this.documents = result.items;
        this.query.total = result.total;
      },
      () => {
        this.alertService.error('', this.messages.get('INVOICE_DOCUMENT_LOAD_ERROR'));
      }
    );
  }

  onDocumentSelectionChange(row) {
    this.documentSelection = <InvoiceDocument>row.selectedRowsData[0];
  }

  checkIsDocumentSelected() {
    this.isSelectedDocumentRow = !!(this.documentSelection && this.documentSelection.name);
    this.isSystemFile();
  }

  /**
   * If file is_system_file true, don't allow to delete file
   */
  isSystemFile() {
    this.isSelectedSystemFile = this.documentSelection?.is_system_file || false;
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  onPageChange() {
    this.loadDocuments(this.invoice);
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/invoice']);
    }
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInvoiceDocumentSiderSection(activeSection.name)
    ]);
  }

  getDNPInfo(notes: any, forceEmpty: boolean = false) {
    const dnpStatus = this.invoiceFlowService.LOCALS.DO_NOT_PROCESS

    if (forceEmpty || this.invoice.header.status_code !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
      this.dnpInfo = null
    } else {
      const dnpNotes = notes.filter(note => note.content.startsWith(dnpStatus));
      this.dnpInfo = (dnpNotes.length) ? dnpNotes[0] : {};
      this.dnpInfo.status = dnpStatus;
      this.dnpInfo.message = this.dnpInfo.content ? this.dnpInfo.content.substring(dnpStatus.length + 2) : this.messages.get('DNP_FALLBACK_MESSAGE');
    }
  }

  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }

  invokeAddDocument() {
    this.dialogService.open(InvoiceDocumentDialogComponent, {
      invoice: this.invoice,
      folder: this.DOCUMENT.folder,
      subfolder: this.invoice.invoice_id,
      edit: false,
      document: null,
      modalTitle: 'New Document'
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.loadDocuments(this.invoice)
            this.history.refreshList();
            this.alertService.success('', this.messages.get('ADD_DOCUMENT_SUCCESS'));
          }
        },
        (error) => {

          console.log(error);
        }
      );
  }

  invokeEditDocument() {
    this.dialogService.open(InvoiceDocumentDialogComponent, {
      invoice: this.invoice,
      folder: this.DOCUMENT.folder,
      subfolder: this.invoice.invoice_id,
      edit: true,
      document: this.documentSelection,
      modalTitle: 'Edit Document'
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.loadDocuments(this.invoice)
            //Meaybe we don't need this
            this.history.refreshList();
            this.alertService.success('', this.messages.get('EDIT_DOCUMENT_SUCCESS'));
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  invokeDownloadDocument() {
    this.downloadSingularDocument();
  }

  invokeDeleteDocument() {
    this.deleteDocument(this.invoice.invoice_id, this.documentSelection)
  }

  deleteDocument(invoice_id: number, document: InvoiceDocument) {
    this.dialogService.confirmCancel({
      bodyText: `Delete document ${document.name}`
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.invoiceDocumentService.deleteDocument(invoice_id, document.id)
              .subscribe(
                (deleteResult) => {
                  if (deleteResult) {
                    this.loadDocuments(this.invoice)
                    //TODO: Implement history
                    this.history.refreshList();

                    this.alertService.success('', this.messages.get('DELETE_DOCUMENT_SUCCESS'));
                  }
                },
                () => {
                  this.alertService.error('', this.messages.get('DELETE_DOCUMENT_FAIL'));
                }
              );
          }
        },
        (error) => {
          console.log('error on confirm delete document modal', error);
        }
      );
  }

  downloadSingularDocument(): any {

    const {invoice_id} = this.invoice;
    const {name: file_name, id: documentId} = this.documentSelection;
    return this.invoiceDocumentService.downloadSource(invoice_id, documentId).subscribe(
      (sourceResponse: Blob) => {
        let objectUrl = URL.createObjectURL(sourceResponse);

        let save = document.createElement('a');
        save.href = objectUrl;
        save.target = '_blank';
        save.download = `${file_name}`;
        let event = document.createEvent('MouseEvents');
        event.initMouseEvent(
          'click',
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        save.dispatchEvent(event);
      },
      error => {
        this.alertService.error('', this.messages.get('NO_DOCUMENT_FILE'));
      }
    )
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
