import {
  Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable } from "rxjs";
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from "../picker/ca-picker-base.component";
import { UserService } from "../../user/core/user.service";
import {ContactService} from "../../contact/core/contact.service";
import { path } from "ramda";

@Component({
  selector: 'ca-person-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [

    {
      provide: NG_VALUE_ACCESSOR,

      useExisting: forwardRef(() => CaPersonPickerComponent),
      multi: true
    },
    UserService
  ]
})
export class CaPersonPickerComponent extends PickerComponentBase implements OnInit, OnChanges {

  readonly TEXT_FIELD = 'full_name';
  readonly CUSTOM_FORMATING = ['first_name', 'last_name'];
  @Input('formControlName') formControlName :any;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('placeholder') placeholder = 'Select ...';
  @Input('contact_function') contact_function: number;
  @Input('siteId') siteId: number;
  @Input('type') type: number;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('clearEnabled') clearEnabled: boolean;

  @Input('initialItem') initialItem;
  @Input('pickerIcon') pickerIcon = false;
  @Input('pickerForm') pickerForm;
  @Input('pickerType') pickerType;
  @Input('contactFunctionId') contactFunctionId:any
  @Input('showAll') showAll: boolean

  control;

  constructor(
    public contactService: ContactService,
    public userService: UserService
  ) {
    super();
  }

  loadEntries(): Observable<any> {
    this.query.orderBy = [['last_name', 'ASC'], ['first_name', 'ASC']];
    if (this.pickerType === 'user') {
      return this.userService.findOnlyNecessary(this.query);
    }
    if (this.pickerType === 'contact'){
      if(this.contactFunctionId){
        this.showAll = true
        return this.contactService.getContactsForFunction(this.query);
      }else {
        return this.contactService.findOnlyNecessary(this.query);
      }
    }
  }

  search(value: string): Observable<any> {
    this.query.where['full_name'] = { '$ilike': `%${value}%` };
    if (this.pickerType === 'user') {
      return this.userService.findOnlyNecessary(this.query);
    }
    if (this.pickerType === 'contact'){
      if(this.contactFunctionId){
        return this.contactService.getContactsForFunction(this.query);
      }else {
        return this.contactService.findOnlyNecessary(this.query);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.pickerType === 'contact'){
      delete this.query.where['full_name']
      this.control = path(['pickerForm', 'controls', this.formControlName])(this);

      if (this.pickerForm.controls.contact_function && this.pickerForm.controls.contact_function.value) {
        this.query.where['contact_functions.key'] = this.pickerForm.controls.contact_function.value
      } else {
        delete this.query.where['contact_functions.key']
      }

      this.initialItem = null;
      this.loadData();
    }

    if (this.pickerType === 'user'){
      this.control = path(['pickerForm', 'controls', this.formControlName])(this);
    }

      if (this.type) {
        this.query.where.contact_type_id = this.type;
      }

      if (this.contact_function) {
        this.query.where['contact_functions.key'] = this.contact_function;
      }

      if (changes['siteId'] && changes['siteId'].currentValue) {
        this.query.set('site_id', changes['siteId'].currentValue);
      }

      // this.loadEntries()
        // .subscribe((result) => {
        //   this.entries = result.items ? result.items : result;
        //   this.query.total = result.total;
        //   this.query.page = 1;

        // });

  }
}
