import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAlertRoutingModule } from './common-alert-routing.module';
import { AlertListComponent } from './alert-list/alert-list.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreModule } from '../core/core.module';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTemplateModule } from 'devextreme-angular';
import { CommonAlertService } from './core/common-alert.service';
import { CommonAlertGridService } from './core/common-alert-grid.service';
import { CommonAlertFilterService } from './core/common-alert-filter.service';
import { AlertAccountFilterService } from './core/alert-account-filter.service';
import { VendorModule } from '../vendor/vendor.module';
import { AlertAssignDialogComponent } from './shared/alert-assign-dialog/alert-assign-dialog.component';
import { UserModule } from '../user/user.module';
import { CommonAlertSneekPeekComponent } from './shared/common-alert-sneek-peek/common-alert-sneek-peek.component';
import { AlertChargesComponent } from './alert-charges/alert-charges.component';
import { AlertBatchComponent } from './alert-batch/alert-batch.component';
import { AlertBatchInvoicesGridService } from './core/alert-batch-invoices-grid.service';
import { AlertBatchInvoicesFilterService } from './core/alert-batch-invoices-filter.service';
import { AlertChargesFilterService } from './core/alert-charges-filter.service';
import { AlertInventoryComponent } from './alert-inventory/alert-inventory.component';
import { AlertAccountComponent } from './alert-account/alert-account.component';
import { AlertChargesGridService } from './core/alert-charges-grid.service';
import { AlertChargeSneakPeekComponent } from './shared/alert-charge-sneak-peek/alert-charge-sneak-peek.component';
import { AlertInventoryGridService } from './core/alert-inventory-grid.service';
import { AlertAccountGridService } from './core/alert-account-grid.service';
import { AlertInventorySneakPeekComponent } from './shared/alert-inventory-sneak-peek/alert-inventory-sneak-peek.component';
import { AlertAccountSneakPeekComponent } from './shared/alert-account-sneak-peek/alert-account-sneak-peek.component';
import { DisputeModule } from '../dispute/dispute.module';
import { CommonAlertResolve } from './core/common-alert.resolve';
import { AlertDetailsGridService } from './core/alert-details-grid.service';
import { AlertInventoryMrcComponent } from './alert-inventory-mrc/alert-inventory-mrc.component';
import { AlertInventoryMrcGridService } from './core/alert-inventory-mrc-grid.service';
import { AlertInventoryMrcFilterService } from './core/alert-inventory-mrc-filter.service';
import { AlertInventoryMrcSneakPeekComponent } from './shared/alert-inventory-mrc-sneak-peek/alert-inventory-mrc-sneak-peek.component';
import { AlertInventoryMrcChargesGridService } from './core/alert-inventory-mrc-charges-grid.service';
import { AlertInventoryFilterService } from './core/alert-inventory/alert-inventory-filter.service';
import { AlertPreGlValidationGridService } from './core/alert-pre-gl-validation-grid.service';
import { AlertPreGlValidationComponent } from './alert-subaccount/alert-pre-gl-validation.component';
import { AlertPreGLValidationService } from './core/alert-pre-gl-validation.service';
import { AlertPreGLExclusionDialogComponent } from './shared/alert-pre-gl-exclusion-dialog/alert-pre-gl-exclusion-dialog.component';
import { AlertItemExclusionGridService } from './core/alert-item-exclusion-grid.service';
import { AlertAuditComponent } from './alert-audit/alert-audit.component';
import { AlertRateAuditComponent } from './alert-rate-audit/alert-rate-audit.component';
import { AlertAuditNavigationService } from './alert-audit/alert-audit-navigation.service';
import { AlertRateAuditGridService } from './core/alert-rate-audit-grid.service';
import { RateAuditResultService } from 'app/audit/rate-audits/core/rate-audit-result.service';
import { AuditResultService } from 'app/audit/core/audit-result.service';
import { RateAuditResultTmpGridService } from 'app/audit/rate-audits/core/rate-audit-result-tmp-grid.service';
import { RateAuditRuleService } from 'app/audit/rate-audits/core/rate-audit-rule.service';
import { AlertInventoryChargeTotalGridService } from './core/alert-inventory-charge-total-grid.service';
import { AlertMissingInventoryDetailsGridService } from './core/alert-missing-inventory-details-grid.service';
import { AlertDisconnectGridService } from './core/alert-disconnect-grid.service';
import { AuditAlertMissingInventoryFilterService } from './core/audit-alert-missing-inventory-filter.service';
import { AuditAlertInventoryMRCFilterService } from './core/audit-alert-inventory-mrc-filter.service';
import { AuditAlertDisconnectAuditFilterService } from './core/audit-alert-disconnect-audit-filter.service';
import { AuditAlertRateFilterService } from './core/audit-alert-rate-filter.service';
import { AlertDisconnectComponent } from './alert-disconnect/alert-disconnect.component';
import { AlertMissingInventoryComponent } from './alert-missing-inventory/alert-missing-inventory.component';
import { AlertMissingInventoryFilterService } from './core/alert-missing-invertory-filter.service';
import { AlertRateAuditFilterService } from './core/alert-rate-audit-filter.service';
import { AlertChargeTotalGridService } from './core/alert-charge-total-grid.service';
import { AlertMissingInventorySpidGridService } from './core/alert-missing-inventory-spid-grid.service';
import { AlertDetailsComponent } from './alert-details/alert-details.component';
import { AlertDetailsFilterService } from './core/alert-details-filter.service';
import { AlertRateAuditResultGridService } from './core/alert-rate-audit-result-grid.service';
import { AlertSummaryOnlyComponent } from './alert-summary-only/alert-summary-only.component';

@NgModule({
	imports: [
		CommonModule,
		CommonAlertRoutingModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		DxTemplateModule,
		DxDataGridModule,
		MaterialModule,
		CoreModule,
		VendorModule,
		UserModule,
		DisputeModule
	],
	declarations: [
		AlertListComponent,
		AlertAssignDialogComponent,
		CommonAlertSneekPeekComponent,
		AlertChargesComponent,
		AlertBatchComponent,
		AlertInventoryComponent,
		AlertAccountComponent,
		AlertChargeSneakPeekComponent,
		AlertInventorySneakPeekComponent,
		AlertAccountSneakPeekComponent,
		AlertAuditComponent,
		AlertRateAuditComponent,
		AlertInventoryMrcComponent,
		AlertInventoryMrcSneakPeekComponent,
		AlertPreGlValidationComponent,
		AlertPreGLExclusionDialogComponent,
		AlertDisconnectComponent,
		AlertMissingInventoryComponent,
		AlertDetailsComponent,
		AlertSummaryOnlyComponent
	],
	providers: [
		AlertDetailsFilterService,
		AlertDetailsGridService,
		CommonAlertService,
		CommonAlertGridService,
		CommonAlertFilterService,
		AlertAccountFilterService,
		AlertChargesFilterService,
		AlertBatchInvoicesFilterService,
		AlertBatchInvoicesGridService,
		AlertChargesGridService,
		AlertInventoryGridService,
		AlertAccountGridService,
		AlertInventoryMrcGridService,
		AlertInventoryMrcFilterService,
		AlertInventoryMrcChargesGridService,
		CommonAlertResolve,
		AlertInventoryFilterService,
		AlertPreGlValidationGridService,
		AlertPreGLValidationService,
		AlertItemExclusionGridService,
		AlertAuditNavigationService,
		AlertRateAuditGridService,
		RateAuditResultService,
		AuditResultService,
		RateAuditResultTmpGridService,
		RateAuditRuleService,
		AlertInventoryChargeTotalGridService,
		AlertMissingInventoryDetailsGridService,
		AlertMissingInventorySpidGridService,
		AlertDisconnectGridService,
		AuditAlertMissingInventoryFilterService,
		AuditAlertInventoryMRCFilterService,
		AuditAlertDisconnectAuditFilterService,
		AlertMissingInventoryFilterService,
		AlertRateAuditFilterService,
		AlertChargeTotalGridService,
		AuditAlertRateFilterService,
		AlertRateAuditResultGridService
	]
})
export class CommonAlertModule {}
