import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {LoggedUserGuardService} from "./shared/user-session/services/logged-user-guard.service";
import {PreloadSelectedModulesList} from './CustomPreloadStrategy';
import {DefaultThemeResolve} from "./theme/core/default-theme.resolve";
import {ConfigResolve} from "./core/config/config.resolve";
import {ThemesResolve} from "./theme/core/themes.resolve";
import {UserResolve} from "./user/core/user.resolve";


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    resolve: {
      config: ConfigResolve
    }
  },
  {
    path: '',
    redirectTo: 'dash',
    pathMatch: 'full' as Route['pathMatch'], resolve: {
      config: ConfigResolve,
      user: UserResolve,
    }
  },
  {
    path: '',
    loadChildren: () => import('./master/master.module').then(m => m.MasterModule),
    canLoad: [LoggedUserGuardService],
    resolve: {
      defaultTheme: DefaultThemeResolve,
      themesResolve: ThemesResolve,
      config: ConfigResolve,
      user: UserResolve,
    }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadSelectedModulesList })],
  exports: [RouterModule],
  providers: [LoggedUserGuardService, PreloadSelectedModulesList, ThemesResolve]
})
export class AppRoutingModule {
}
