<button
  *permission="{'action':'Create', 'key':'inventory'}"
  mat-icon-button matTooltip="Upload"
  [disabled]="uploadDocumentDisablePredicate"  
  (click)="uploadDocumentActionHandler()"
>
    <ca-icon [key]="'FILE_UPLOAD'"></ca-icon>
</button>

<button
  mat-icon-button matTooltip="Download"
  [disabled]="downloadDocumentDisablePredicate"
  (click)="downloadDocumentActionHandler()"
>
    <ca-icon [key]="'FILE_DOWNLOAD'"></ca-icon>
</button>

<button
  *permission="{'action':'Create', 'key':'inventory'}"
  mat-icon-button matTooltip="Delete"
  [disabled]="deleteDocumentDisablePredicate"
  (click)="deleteDocumentActionHandler()"
>
    <ca-icon [key]="'FILE_DELETE'"></ca-icon>
</button>
