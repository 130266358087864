import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AlertMissingInventoryDetailsGridService extends GridService {
  defaultColumns = [
    {
      caption: '',
      headerCellTemplate: 'selectedChargesHeaderTemplate',
      cellTemplate: 'chargeDisputeNoteTemplate',
      width: 40,
      fixed: true,
      allowSorting: false
    },
    {
      caption: 'Message',
      dataField: 'message',
      width: 500
    },
    {
      caption: 'Charge ID',
      dataField: 'charge_id',
      width: 150,
      alignment: 'left',
    },
    {
      dataField: 'charge.sp_serv_id',
      caption: 'SPID',
      width: 180,
    },
    {
      dataField: 'alert.vendor.name',
      caption: 'Vendor',
      width: 180,
    },
    {
      dataField: 'charge.acct_level_1',
      caption: 'BAN',
      width: 180,
    },
    {
      dataField: 'alert.invoice.sp_inv_num',
      caption: 'Invoice Number',
      width: 180,
    },
    {
      dataField: 'alert.invoice.inv_date',
      caption: 'Invoice Date',
      dataType: 'date',
      format: this.formatService.date,
      alignment: 'left',
      width: 180,
    },
    {
      dataField: 'charge.rel_sp_fac_id',
      caption: 'Related SPID',
      width: 180,
    },
    {
      dataField: 'charge.chg_code_1',
      caption: 'Charge Code 1',
      width: 220,
    },
    {
      dataField: 'charge.chg_desc_1',
      caption: 'Charge Description 1',
      width: 220,
    },
    {
      dataField: 'charge.chg_code_2',
      caption: 'Charge Code 2',
      width: 220,
    },
    {
      dataField: 'charge.chg_desc_2',
      caption: 'Charge Description 2',
      width: 220,
    },
    {
      dataField: 'charge.fac_bw',
      caption: 'Facility Bandwidth',
      width: 220,
    },
    {
      dataField: 'charge.svc_establish_date',
      caption: 'Install Date',
      dataType: 'date',
      format: this.formatService.date,
      width: 180,
    },
    {
      dataField: 'charge.chg_qty1_type',
      caption: 'Charge Qty Type',
      width: 180,
    },
    {
      dataField: 'charge.chg_qty1_used',
      caption: 'Charge Qty Used',
      cellTemplate: "numTemplate",
      width: 180,
    },
    {
      dataField: 'charge.chg_qty1_allowed',
      caption: 'Charge Qty Allowed',
      cellTemplate: "numTemplate",
      width: 180,
    },
    {
      dataField: 'charge.chg_qty1_billed',
      caption: 'Charge Qty Billed',
      cellTemplate: "numTemplate",
      width: 180,
    },
    {
      dataField: 'charge.chg_rate',
      caption: 'Billed Charge Rate',
      cellTemplate: "numTemplate",
      width: 180,
    },
    {
      dataField: 'charge.chg_amt',
      caption: 'Billed Charge Amt',
      cellTemplate: "invoiceCurrencyTemplate",
      dataType: 'currency',
      width: 180,
    }
  ];

  constructor(public userSettings: UserSettingsService,
              public formatService: FormatService) {
    super('app.alert.missing-inventory-details-grid', userSettings);
  }

}
