import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable } from "rxjs";
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from "../../../shared/picker/ca-picker-base.component";
import { QuotingService } from "../../core/quoting.service";
import Query from '../../../core/query/query';


@Component({
  selector: 'ca-nxx-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => CaNxxPickerComponent),
          multi: true
      },
      QuotingService
  ]
})
export class CaNxxPickerComponent extends PickerComponentBase implements OnInit, OnChanges {

  readonly TEXT_FIELD = 'nxx';
  readonly KEY_FIELD = 'nxx';

  @Input('formControlName') formControlName;
  @Input('initialItem') initialItem;
  /* @Input('disabled') disabled: boolean; */
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('disabled') disabled: boolean;
  @Input('npa') npa;

  public control;

  query: Query = new Query({
    orderBy: [['nxx', 'ASC']]
  });

  constructor( public quotingService: QuotingService) {
    super();
    this.disabled = true;
  }

  ngOnInit() { /* Intentionally overridden method in order not to load data on init */ }

  ngOnChanges(changes: SimpleChanges) {

    this.control = this.pickerForm.controls[this.formControlName];
    let npa_changes = changes['npa'];
    
    if(npa_changes && npa_changes.currentValue) {
      this.query.page = 1;
      this.query.offset = 0;
      this.disabled = false;
      if (!this.pickerForm.get('npa').value) {
        this.control.reset();
      }
      this.loadData();
    }else{
      this.control.reset();
      this.disabled = true;
    }
  }

  loadEntries(): Observable<any> {
    this.query.where['npa'] = this.npa;
    delete this.query.where['nxx'];
    return this.quotingService.getAllNxx(this.query);
  }

  search(value: string) {
    this.query.where['npa'] = this.npa;
    this.query.where['nxx'] = { '$ilike': `${value}%` };
    return this.quotingService.getAllNxx(this.query);
  }
}
