import {Component, OnInit} from '@angular/core';
import {FilterEntry} from '../filter-entry';
import {MatDialogRef} from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-filter-settings',
  templateUrl: './filter-settings.component.html',
  styleUrls: ['./filter-settings.component.scss']
})
export class FilterSettingsComponent implements OnInit {
  resetConfirmValue: boolean;
  selectedIndex: number;
  context: any;
  contextValues: any = [];

  constructor(public dialogRef: MatDialogRef<FilterSettingsComponent>) {
  }

  ngOnInit() {
    this.contextValues = this.context.sortedFilters.map(
      (filter: FilterEntry) => {
        return {
          field: filter.field,
          label: filter.label,
          type: filter.type,
          editor: filter.editor,
          index: filter.index,
          visible: filter.visible,
          lookupProviderName: filter.lookupProviderName,
          trueLabel: filter.trueLabel,
          falseLabel: filter.falseLabel,
          exactMatch: filter.exactMatch,
          matchCase: filter.matchCase,
          fieldToFilterWith: filter.fieldToFilterWith,
          secondRowField: filter.secondRowField,
          max: filter.max,
          defaultValue: filter.defaultValue,
          topUsage: filter.topUsage,
          sitesFields: filter.sitesFields ? {
              site: {
                label: filter.sitesFields.site.label,
                field: filter.sitesFields.site.field,
              },
              building: {
                label: filter.sitesFields.building.label,
                field: filter.sitesFields.building.field,
              },
              address1: {
                label: filter.sitesFields.address1.label,
                field: filter.sitesFields.address1.field,
              },
              city: {
                label: filter.sitesFields.city.label,
                field: filter.sitesFields.city.field,
              },
              subdivision: {
                label: filter.sitesFields.subdivision.label,
                field: filter.sitesFields.subdivision.field,
                lookupProviderName: filter.sitesFields.subdivision.lookupProviderName,
              },
              postal_code: {
                label: filter.sitesFields.postal_code.label,
                field: filter.sitesFields.postal_code.field,
              },
              vendor: {
                label: filter.sitesFields.vendor.label,
                field: filter.sitesFields.vendor.field,
              }
            }
            : null,

          vendorFields: filter.vendorFields
            ? {
              vendor: {
                label: filter.vendorFields.vendor.label,
                field: filter.vendorFields.vendor.field
              },
              account: {
                label: filter.vendorFields.account.label,
                field: filter.vendorFields.account.field
              },
            } : null,

          addressFields: filter.addressFields
            ? {
              address1: {
                label: filter.addressFields.address1.label,
                field: filter.addressFields.address1.field
              },
              city: {
                field: filter.addressFields.city.field,
                label: filter.addressFields.city.label
              },
              subdivision: {
                field: filter.addressFields.subdivision.field,
                label: filter.addressFields.subdivision.label,
                lookupProviderName:
                filter.addressFields.subdivision.lookupProviderName
              },
              postal_code: {
                field: filter.addressFields.postal_code.field,
                label: filter.addressFields.postal_code.label
              }
            }
            : null
        };
      }
    );
  }

  resetToDefault() {
    let index = 0;
    this.context.defaultContext.filters.sort((a, b) => a.label.localeCompare(b.label));
    this.context.defaultContext.filters.forEach(value => value.index = index++);
    this.saveFilterSettings(this.context.defaultContext.filters, true);
  }

  close() {
    this.dialogRef.close();
  }

  onSettingsTabChange(event) {
    this.resetConfirmValue = false;
  }

  save() {
    if (this.resetConfirmValue) {
      this.resetToDefault();
    } else {
      let index = 0;
      this.contextValues.forEach(value => {
        let existing = this.findByField(value.field);
        existing.index = index;
        existing.visible = value.visible;
        index++;
      });
      this.saveFilterSettings(this.context.filters);
    }
  }

  saveFilterSettings(toFilter, isReset?) {
    let filters = toFilter.map((filter: FilterEntry) => {
      return {
        field: filter.field,
        label: filter.label,
        type: filter.type,
        editor: filter.editor,
        index: filter.index,
        visible: filter.visible,
        lookupProviderName: filter.lookupProviderName,
        trueLabel: filter.trueLabel,
        falseLabel: filter.falseLabel,
        max: filter.max,
        defaultValue: filter.defaultValue,
        topUsage: filter.topUsage,
        fieldToFilterWith: filter.fieldToFilterWith,
        secondRowField: filter.secondRowField,

        sitesFields: filter.sitesFields ? {
            site: {
              label: filter.sitesFields.site.label,
              field: filter.sitesFields.site.field
            },
            building: {
              label: filter.sitesFields.building.label,
              field: filter.sitesFields.building.field
            },
            address1: {
              label: filter.sitesFields.address1.label,
              field: filter.sitesFields.address1.field
            },
            city: {
              label: filter.sitesFields.city.label,
              field: filter.sitesFields.city.field
            },
            subdivision: {
              label: filter.sitesFields.subdivision.label,
              field: filter.sitesFields.subdivision.field,
              lookupProviderName:
              filter.sitesFields.subdivision.lookupProviderName
            },
            postal_code: {
              label: filter.sitesFields.postal_code.label,
              field: filter.sitesFields.postal_code.field
            },
            vendor: {
              label: filter.sitesFields.vendor.label,
              field: filter.sitesFields.vendor.field
            }
          }
          : null,

        vendorFields: filter.vendorFields
          ? {
            vendor: {
              label: filter.vendorFields.vendor.label,
              field: filter.vendorFields.vendor.field
            },
            account: {
              label: filter.vendorFields.account.label,
              field: filter.vendorFields.account.field
            },
          } : null,

        addressFields: filter.addressFields
          ? {
            address1: {
              label: filter.addressFields.address1.label,
              field: filter.addressFields.address1.field
            },
            city: {
              field: filter.addressFields.city.field,
              label: filter.addressFields.city.label
            },
            subdivision: {
              field: filter.addressFields.subdivision.field,
              label: filter.addressFields.subdivision.label,
              lookupProviderName:
              filter.addressFields.subdivision.lookupProviderName
            },
            postal_code: {
              field: filter.addressFields.postal_code.field,
              label: filter.addressFields.postal_code.label
            }
          }
          : null
      };
    });
    const payload = {
      filters: filters
    };

    if (isReset) {
      this.context.filterSet = (payload['filterSet'] = []);
    }

    this.context.service.saveSettings({...payload}).subscribe(()=>{
      this.dialogRef.close(this.context);
    })
  }

  public findByField(field) {
    return this.context.filters.filter(filter => {
      return filter.field === field;
    })[0];
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.contextValues, event.previousIndex, event.currentIndex);
  }
}
