import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlertListComponent } from './alert-list/alert-list.component';
import { AlertChargesComponent } from './alert-charges/alert-charges.component';
import { AlertInventoryComponent } from './alert-inventory/alert-inventory.component';
import { AlertAccountComponent } from './alert-account/alert-account.component';
import { CommonAlertResolve } from './core/common-alert.resolve';
import { AlertInventoryMrcComponent } from './alert-inventory-mrc/alert-inventory-mrc.component';
import { AlertAuditComponent } from './alert-audit/alert-audit.component';
import { AlertRateAuditComponent } from './alert-rate-audit/alert-rate-audit.component';
import { AlertDisconnectComponent } from './alert-disconnect/alert-disconnect.component';
import { AlertMissingInventoryComponent } from './alert-missing-inventory/alert-missing-inventory.component';
import { AlertPreGlValidationComponent } from './alert-subaccount/alert-pre-gl-validation.component';
import { AlertBatchComponent } from './alert-batch/alert-batch.component';
import { AlertDetailsComponent } from './alert-details/alert-details.component';
import { AlertSummaryOnlyComponent } from './alert-summary-only/alert-summary-only.component';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				component: AlertListComponent
			},
			{
				path: ':id/details',
				component: AlertDetailsComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/charges',
				component: AlertChargesComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/inventory',
				component: AlertInventoryComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/account',
				component: AlertAccountComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/batch',
				component: AlertBatchComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/inventory-mrc',
				component: AlertInventoryMrcComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/disconnect',
				component: AlertDisconnectComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/missing-inventory',
				component: AlertMissingInventoryComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/gl-validation',
				component: AlertPreGlValidationComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/rate-audit',
				component: AlertRateAuditComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/audit',
				component: AlertAuditComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/gl-validation/:key',
				component: AlertPreGlValidationComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/auto-approval',
				component: AlertSummaryOnlyComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/invoice-balance',
				component: AlertSummaryOnlyComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			},
			{
				path: ':id/invoice-precentage',
				component: AlertSummaryOnlyComponent,
				resolve: {
					alert: CommonAlertResolve
				}
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: []
})
export class CommonAlertRoutingModule {}
