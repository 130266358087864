import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, zip as observableZip } from 'rxjs';
import { filter, flatMap } from 'rxjs';
import { ChargeQuery } from '../../charge/core/charge.query';
import { BaseService } from '../../core/base.service';
import Query from '../../core/query/query';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { InventoryQuery } from '../../inventory/core/inventory.query';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { CommonAlert } from './common-alert';
import { CommonAlertQuery } from './common-alert.query';

@Injectable()
export class CommonAlertService extends BaseService<CommonAlert> {
	readonly ALERT_DICTIONARY = {
		COMPLETE: 'Complete'
	};

	@Output() alertReloading = new EventEmitter();

	constructor(
		public dictionaryService: DictionaryService,
		public userSettings: UserSettingsService
	) {
		super('alert');
	}

	findAlertAuditResults(alertId: number, query) {
		return this.httpService().get(
			[this.name, alertId, 'audit'],
			this.toFilter(query)
		);
	}

	findChargesSummary(alertId: number) {
		return this.httpService().get([this.name, alertId, 'summary']);
	}

	findCharges(alertId: number, query: ChargeQuery) {
		let concreteQuery = query || new ChargeQuery();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'charges'],
			this.toFilter(transformedQuery)
		);
	}

	createAlertAccount(data: any): any {
		return this.httpService().post([this.name, 'alert-account'], data);
	}

	findAccountsForTreeData(data: any): any {
		return this.httpService().post([this.name, 'accounts-from-tree'], data);
	}

	getAlertsForInvoice(query: CommonAlertQuery): any {
		let concreteQuery = query || new InventoryQuery();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().get(
			[this.name, 'alerts-for-invoice'],
			this.toFilter(transformedQuery)
		);
	}

	completeAlerts(selectedAlerts, user): Observable<any> {
		return this.dictionaryService
			.getByLookup(LOOKUP_MODELS_ENUM.ALERT_STATUS.modelName)
			.pipe(
				flatMap((alertDictionaries: any) => alertDictionaries.items),
				filter(
					(alertDicItems: any) =>
						alertDicItems.value === this.ALERT_DICTIONARY.COMPLETE
				)
			)
			.pipe(
				flatMap((alertDicComplete: any) => {
					return observableZip(
						...selectedAlerts.map((alert: CommonAlert) => {
							alert.closed_at = new Date();
							alert.status = alertDicComplete;
							alert.status_id = alertDicComplete.id;
							alert.owner = user;
							alert.owner_id = user.id;
							return this.update(alert.id, alert);
						})
					);
				})
			);
	}

	changeOwners(alerts: CommonAlert[], owner_id: number, status_id: number) {
		const alert_ids = alerts.map((alert) => alert.id);
		return this.httpService().post([this.name, 'change-owners'], {
			alert_ids,
			owner_id,
			status_id
		});
	}

	findInventory(alertId: Number, query: InventoryQuery) {
		let concreteQuery = query || new InventoryQuery();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'inventory'],
			this.toFilter(transformedQuery)
		);
	}

	findAccount(alertId: Number, query: InventoryQuery) {
		let concreteQuery = query || new InventoryQuery();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'account'],
			this.toFilter(transformedQuery)
		);
	}

	findDetails(alertId: Number, query: Query = new Query()) {
		let transformedQuery = query.transform();
		return this.httpService().get(
			[this.name, alertId, 'details'],
			this.toFilter(transformedQuery)
		);
	}

	findInventoryMRCs(alertId: Number, query: Query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().get(
			[this.name, alertId, 'inventory-mrc'],
			this.toFilter(transformedQuery)
		);
	}

	findChargesByMRCandSPID(alertId: Number, query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'inventory-mrc-show'],
			this.toFilter(transformedQuery)
		);
	}

	findPreGLValidationAlerts(alertId: number, query: Query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'gl-validation'],
			this.toFilter(transformedQuery)
		);
	}

	findAlertForInvoice(invoiceId: number) {
		return this.httpService().get([this.name, invoiceId, 'invoice-alert']);
	}

	excludeAlertItems(alertId: number, items, note) {
		return this.httpService().post([this.name, 'exclude-alert-items'], {
			alertId,
			items,
			note
		});
	}

	findChargeTotal(alertId: Number, query?: Query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'charge-total'],
			this.toFilter(transformedQuery)
		);
	}

	findDisconnect(alertId: Number, query?: Query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'disconnect'],
			this.toFilter(transformedQuery)
		);
	}

	findMissingInventory(alertId: Number, query?: Query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'missing-inventory'],
			this.toFilter(transformedQuery)
		);
	}
	findMissingInventorySpid(alertId: Number, query?: Query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'missing-inventory-spid'],
			this.toFilter(transformedQuery)
		);
	}

	reloadAlerts() {
		this.alertReloading.emit('');
	}

	findBatch(alertId: Number, query: InventoryQuery) {
		let concreteQuery = query || new InventoryQuery();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, alertId, 'batch'],
			this.toFilter(transformedQuery)
		);
	}

	findAlertsByInvoiceId(query: InventoryQuery) {
		let concreteQuery = query || new InventoryQuery();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, 'batch-invoice-alerts'],
			this.toFilter(transformedQuery)
		);
	}

	findAuditAlerts(query: Query) {
		let concreteQuery = query || new InventoryQuery();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get(
			[this.name, 'find-audit-type'],
			this.toFilter(transformedQuery)
		);
	}
}
