import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TabGroup, TabService } from "app/shared/tabs/tab.service";
@Component({
  selector: "app-inventory-details-tabs",
  templateUrl: "./inventory-details-tabs.component.html",
  styleUrls: ["./inventory-details-tabs.component.css"],
})
export class InventoryDetailsTabsComponent implements OnInit, OnDestroy {
  @Output() displayPreventChangeModal: EventEmitter<any> =
    new EventEmitter<any>();

  @Input() activeTabIndex: number = 0;
  @Input() disabled: boolean = false;

  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public index: number;
  public oldIndex: number;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    public tabService: TabService
  ) {
    this.loadTabs();
  }

  ngOnInit(): void {
    const urlParam = this.activatedRoute.snapshot.url[0].path;
    this.index = this.tabGroup.tabs.find((tab) => tab.route === urlParam).key;
  }

  loadTabs() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({ key: 0, label: "Summary", route: "summary" });
    this.tabGroup.addTab({ key: 1, label: "Sites", route: "site" });
    this.tabGroup.addTab({ key: 2, label: "Orders", route: "order" });
    this.tabGroup.addTab({ key: 3, label: "Billing", route: "billing" });
    this.tabGroup.addTab({ key: 4, label: "GL Codes", route: "code" });
    this.tabGroup.addTab({ key: 5, label: "Pricing", route: "pricing" });
    this.tabGroup.addTab({
      key: 6,
      label: "Related Service",
      route: "related-circuit",
    });
    this.tabGroup.addTab({ key: 7, label: "Documents", route: "document" });
    this.tabGroup.addTab({
      key: 8,
      label: "Contract Terms",
      route: "contract-terms",
    });
    this.tabGroup.addTab({
      key: 9,
      label: "Charge Profile",
      route: "invoice-charge-profile",
    });

    this.tabGroup.onActivate.subscribe(() => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;
      });
    });
  }

  checkForDisabledProperty() {
    if (!this.disabled) {
      return;
    }
    this.displayPreventChangeModal.emit();
  }

  onTabChange(tab: any = null, index: number) {
    // if (this.disabled) {
    //   return;
    // }
    const inventory = this.activatedRoute.parent.snapshot.data.inventory;

    if (tab && (!this.oldIndex || this.oldIndex === index)) {
      this.oldIndex = index;
    }
    if (tab && this.disabled) {
      tab.selectedIndex = this.activeTabIndex;
    } else if (!tab && this.disabled) {
      this.index = this.oldIndex;
      const activeTab = this.tabGroup.tabs[index].route;
      this.router.navigate(["inventory", inventory.id, "show", activeTab]);
    } else {
      this.index = index;
      const activeTab = this.tabGroup.tabs[index].route;
      this.router.navigate(["inventory", inventory.id, "show", activeTab]);
    }
  }

  ngOnDestroy(): void {
    this.tabGroup.onActivate.unsubscribe();
  }
}
