import {Injectable} from '@angular/core';
import {GridService} from '../../../shared/grid/grid.service';
import {UserSettingsService} from '../../../user/core/user-settings.service';

@Injectable()
export class RateAuditResultTmpGridService extends GridService {
  //from each selected Settings colum we need create two columns: operator and value
  generateColumns(attributesList) {
    let generatedColumns = [];

    //TODO Load this from lookup table, those data are retrived of first step of Audit Rate
    const fieldName = {
        chg_class: 'Charge Class',
        chg_code_1: 'Charge Code 1',
        chg_code_2: 'Charge Code 2',
        chg_code_3: 'Charge Code 3',
        chg_desc_1: 'Charge Description 1',
        chg_desc_2: 'Charge Description 2',
        chg_desc_3: 'Charge Description 3',
        fac_bw: 'Facility Bandwidth'
    }
    const defaultColumns = [
        {
          caption: '',
          headerCellTemplate: 'selectionBadgeHeaderTemplate',
          cellTemplate: 'chargeDisputeNoteTemplate',
          fixed: true,
          width: 30,
          allowSorting: false
        },
        // {
        //   caption: 'Contract',
        //   dataField: 'audit.contract.name',
        //   alignment: 'left',
        // },
        {
          caption: 'Begin Date',
          dataField: 'cost_invoice_charge.beg_chg_date',
          dataType: 'date',
          width: 90,
          format: 'shortDate',
          alignment: 'left'
        },
        {
          caption: 'End Date',
          dataField: 'cost_invoice_charge.end_chg_date',
          dataType: 'date',
          width: 90,
          format: 'shortDate',
          alignment: 'left'
        },
        {
            caption: 'Rate',
            dataField: 'rate_value',
            dataType: 'number',
            alignment: 'right',
            width: 70,
            format: {
              precision: 2,
              type: 'currency'
            }
        },
        {
            caption: 'Charge Amt',
            dataField: 'cost_invoice_charge.chg_amt',
            alignment: 'right',
            dataType: 'number',
            format: {
              precision: 2,
              type: 'currency'
            }
        },
        {
            caption: 'Calc Charge',
            dataField: 'calc_chg_amt',
            dataType: 'number',
            alignment: 'right',
            format: {
              precision: 2,
              type: 'currency'
            }
        },
        {
            caption: 'Variance',
            dataField: 'chg_var',
            dataType: 'number',
            alignment: 'right',
            format: {
              precision: 2,
              type: 'currency'
            }
        }
    ];
    const invoiceNumberColumn = {
      caption: 'Invoice Number',
      dataField: 'invoice_id',
      width: 120
    };

    const chargeIdColumn = {
            caption: 'Charge ID',
            dataField: 'charge_id',
            width: 80
        };

    const spidColumn = {
        caption: 'SPID',
        dataField: 'cost_invoice_charge.sp_serv_id',
        width: 80
    }

    const createCustomColumns = (attributesList) => {
        const customColumns = [];
        attributesList.forEach(attribute => {
            customColumns.push({
                caption: fieldName[attribute] || attribute,
                dataField: `cost_invoice_charge.${attribute}`
            })
        })
        return customColumns;
    }

    generatedColumns.push(invoiceNumberColumn,chargeIdColumn, spidColumn);
    generatedColumns = generatedColumns.concat(createCustomColumns(attributesList));
    generatedColumns = generatedColumns.concat(defaultColumns);

    return generatedColumns;
  }

  constructor(public userSettings: UserSettingsService) {
    super('app.audits.new-rate-result-tmp-grid', userSettings);
  }
}
