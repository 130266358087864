import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { BANCodingGridService } from '../core/ban-coding-grid.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { PageListComponent } from '../../core/page-list.component';
import { AlertService } from '../../shared/alert/alert.service';
import { Account } from '../../account/core/account';
import { AccountService } from '../../account/core/account.service';
import Query from '../../core/query/query';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { PageContext } from '../../core/page.context';
import { SortingBuilder, SortingService } from '../../shared/sorting/sorting.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { SystemRuleSelectors } from '../core/system-rule.selectors';
import { SystemRuleStore } from '../core/system-rule.store';
import { GLSystemRuleService } from '../core/gl-system-rule.service';
import {PermissionService} from '../../permissions/core/permission.service';
import { IActions } from 'app/gl-system-rules/shared/actions.interface';
import {map, takeUntil} from "rxjs";

@Component({
  selector: 'app-ban-coding',
  templateUrl: './ban-coding.component.html',
  styleUrls: ['./ban-coding.component.css']
})
export class BANCodingComponent extends PageListComponent implements AfterViewInit, OnDestroy, OnChanges, OnInit {
  public query: Query = new Query({
    where: {
      id: { $ne: new Date().getMilliseconds() * -1 }
    }
  });

  public bans: Array<Account> = [];
  @Input() triggerReload = false;
  @Output() public onSelection = new EventEmitter();
  @Output() public onActionsLoaded = new EventEmitter();
  @Output() public onClearSelection = new EventEmitter();

  public selection: Account;
  public selectedVendor: any;

  actionSchema: IActions = {
    add: false,
    edit: false
  };

  @ViewChild(DxDataGridComponent) bansGrid: DxDataGridComponent;
  public columns: Array<any>;
  public selectedCodings: Array<any>;
  public originalCodings: Array<any>;

  public banSorting: SortingBuilder;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'gl';
  form$: any;

  permissionModify = true;

  constructor(public glSystemRuleService: GLSystemRuleService,
              public dialog: DialogService,
              public router: Router,
              public alert: AlertService,
              public banCodingGridService: BANCodingGridService,
              public accountService: AccountService,
              public settingsService: UserSettingsService,
              public sortingService: SortingService,
              public loaderService: LoaderService,
              public formBuilder: FormBuilder,
              private systemRuleStore: SystemRuleStore,
              private permissionService: PermissionService,
              private systemRuleSelectors: SystemRuleSelectors) {
    super(new PageContext({
      name: 'app.gl.ban-coding',
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.banSorting = this.sortingService.builder();
  }


  saveBANConfig(params) {
    this.selectedCodings = params;

    let createCodings = this.selectedCodings.filter((coding) => {
      coding.ban = this.selection.account_no;
      coding.vendor_id = this.selection.vendor_id;
      return coding.id == null ;
    });

    let updateCodings = this.selectedCodings.filter((coding) => {
      coding.ban = this.selection.account_no;
      coding.vendor_id = this.selection.vendor_id;
      return coding.id != null ;
    });

    let selectedIds = updateCodings.map((coding) => {
      return coding.id;
    });

    let deleteCodings = this.originalCodings.filter((coding) => {
      return selectedIds.indexOf(coding) === -1;
    });

    this.glSystemRuleService.saveBANCodings(this.selection, createCodings, updateCodings, deleteCodings)
      .then(() => {
        this.loadBANCodings();
        this.alert.success('', this.messages.get('BAN_CODING_UPDATE_SUCCESS'));
      });
  }

  ngOnChanges(changes) {
    if (changes && changes.triggerReload && changes.triggerReload.currentValue) {
      this.saveBANConfig(changes.triggerReload.currentValue);
    }
  }

  onSelectionChanged(row) {
    this.selection = <Account>row.selectedRowsData[0];
    if (this.selection) {
      this.loadBANCodings();
    }
  }

  onClearVendor() {
    this.onClearSelection.emit();
  }

  public loadBANCodings() {
    this.glSystemRuleService.loadBANCodings(this.selection.vendor_id, this.selection.account_no)
      .subscribe((result) => {
        this.selectedCodings = result.items;
        this.originalCodings = result.items.map((coding) => {
          return coding.id;
        });
        this.onSelection.emit({
          coding: this.selection,
          strings: this.selectedCodings,
          show: !this.selection.is_static,
          ruleInfo: {
            meta: {
              vendor_id: this.selection.vendor_id,
              ban: this.selection.account_no
            },
            type: 'ban'
          }
        });
      });
  }

  loadBans(query: Query) {
    if (this.selectedVendor) {
      this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

      this.glSystemRuleService.loadBANs(this.selectedVendor, query)
        .subscribe((result) => {
          this.bans = result;
          this.selectFirstRow(this.bansGrid.instance);
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        },
          () => {
            this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
          });
    } else {
      this.bans = [];
    }
  }

  public onCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.banSorting.apply(event, this.query);
      this.loadBans(this.query);
    }
  }

  ngOnInit() {
    this.loadPermissions().subscribe(() => {
            })
    this.columns = this.banCodingGridService.columns();
    this.form$ = this.systemRuleStore.select('banForm');
    this.systemRuleSelectors.getBanFormVendor(this.systemRuleStore)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        vendorId => {
          this.selectedVendor = vendorId;
          this.loadBans(this.query);
        }
      );
  }

  ngAfterViewInit(): void {
    this.banCodingGridService.create(this.bansGrid.instance, {
      noDataText: this.banCodingGridService.noDataMessage,
      sorting: {
        mode: 'none'
      }
    });
    super.ngAfterViewInit();
  }

  changeBANStatus(obj) {
    let status = this.statusChangeDisabled(obj);
    if (!status) {
      obj.gl_coding_enabled = !obj.gl_coding_enabled;
      this.accountService.codingUpdate(obj.id, obj)
        .subscribe((result) => {
          this.alert.success('', this.messages.get('BAN_CODING_GL_STATUS_CHANGE_SUCCESS'));
        });
    }
  }

  statusChangeDisabled(obj) {
    if (!obj) {
      return true;
    }
    let disabled = true;
    if (!obj.is_static) {
      disabled = false;
    } else {
      if (Number(obj.pct_total) === 100) {
        disabled = false;
      }
    }
    return disabled || this.permissionModify;
  }

  changeMethod(obj) {
    if (obj.is_static) {
      this.dialog.confirm({
        titleText: 'Please confirm',
        bodyText: 'Setting dynamic coding method will erase all assigned apportions. Do you want to continue?'
      })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            obj.is_static = !obj.is_static;
            obj.coding_method = obj.is_static ? 'static' : 'dynamic';
            obj.pct_total = 0;

            this.glSystemRuleService.codingDeleteAllBANCodings(obj.vendor_id, obj.account_no)
              .then(() => {
                this.accountService.codingUpdate(obj.id, obj)
                  .subscribe(() => {
                    this.alert.success('', this.messages.get('BAN_CODING_GL_STATUS_CHANGE_SUCCESS'));
                    this.loadBANCodings();
                  });
              })
              .catch(() => {
                // revert values
                obj.is_static = !obj.is_static;
                obj.coding_method = obj.is_static ? 'static' : 'dynamic';
                this.alert.error('', this.messages.get('ERROR'));
              });
          }
        });
    } else {
      obj.is_static = !obj.is_static;
      obj.coding_method = obj.is_static ? 'static' : 'dynamic';
      obj.gl_coding_enabled = false; // was dynamic and at moment of switch for sure do not have pct_total = 100%

      this.accountService.codingUpdate(obj.id, obj)
        .subscribe(() => {
          this.loadBANCodings();
          this.alert.success('', this.messages.get('BAN_CODING_GL_STATUS_CHANGE_SUCCESS'));
        });
    }
  }

  // redefine methods from base class to prevent detail panel hide
  public selectFirstRow(dataGrid) {
    setTimeout(() => {
      if (dataGrid && dataGrid.totalCount()) {
        dataGrid.selectRowsByIndexes([0]);
        this['selection'] = dataGrid.getSelectedRowsData()[0];
      } else {
        this['selection'] = null;
      }
    }, 500);
  }

  reset() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadPermissions() {
    const glCreate$ = this.permissionService.isAllowed("gl", "CREATE")
    const glModify$ = this.permissionService.isAllowed("gl", "MODIFY")
    return forkJoin([
      glCreate$,
      glModify$]
    ).pipe(map(([ glCreate, glModify]) => {
      this.permissionModify = !glModify;
      this.actionSchema = {
        add: glCreate,
        edit: glCreate && glModify,
      };
      this.onActionsLoaded.emit(this.actionSchema)
    }));
  }

}
