<div class="mat-dialog-top-spacer"></div>

<div class="caGap header">
	<div class="ca-dialog-title">
		<h1 mat-dialog-title>{{ title }}</h1>
	</div>

	<mat-slide-toggle
		(change)="updateChargeProfileActiveValue($event)"
		[checked]="isChargeProfileActive"
		class="mat-slider"
	>
		Charge Profiles State:
		{{ isChargeProfileActive ? 'Active' : 'Inactive' }}
	</mat-slide-toggle>
</div>

<div mat-dialog-content class="ca-dialog-content">
	<div
		style="height: 300px; overflow: hidden"
		class="app-content-list app-content-list-no-pager"
	>
		<dx-data-grid
			#chargeGrid
			[columns]="columns"
			[width]="'100%'"
			[height]="'100%'"
			[dataSource]="charges"
			[showColumnLines]="false"
			[showRowLines]="true"
			[allowColumnResizing]="true"
			[showBorders]="true"
		>
			<div *dxTemplate="let d of 'currencyTemplate'">
				<div caGridTooltip class="right">
					{{ d.value || 0 | caCurrency: d.data.currency }}
				</div>
			</div>

			<div *dxTemplate="let d of 'booleanTemplate'">
				<div caGridTooltip>{{ d.value ? 'Active' : 'Inactive' }}</div>
			</div>
		</dx-data-grid>
	</div>
</div>

<mat-dialog-actions class="ca-dialog-action">
	<div class="example-fill-remaining-space"></div>

	<button #cancelButton mat-button type="submit" (click)="close()">
		Cancel
	</button>

	<button #saveButton mat-button type="submit" (click)="onSubmit()">
		Save
	</button>
</mat-dialog-actions>
