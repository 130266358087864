<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>{{title}}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
  <!-- Contains: charge form inputs -->
  <form [formGroup]="chargeForm" #formCharge="ngForm" class="ca-form dialog-content-no-margin">
    <div class="flex-container flex-container-3" fxLayout="row">
      <div class="flex-item caGap caGapRight custom-hide-mat-error" fxFlex="23%">
        <ca-currency
          caInput
          formControlName="chg_amt"
          placeholder="Amount"
          [required]="true"
          [currency]="this.invoice.currency || 'USD'">
        </ca-currency>
        <mat-hint class="custom-mat-error-message-position"
        *ngIf="chargeForm.hasError('required', ['chg_amt']) && chargeForm.controls.chg_amt.touched">
        {{VALIDATION_CHARGE.REQUIRED_ERROR}}
        </mat-hint>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex="23%">
        <ca-lookup-model-picker fxFlex
                                caInput
                                formControlName="gl_rule_type_id"
                                placeholder="Reason"
                                [required]="'false'"
                                [lookupModel]="MANUAL_ADJUSTMENT_REASON_CODE_MODEL"
                                [pickerForm]="chargeForm">
        </ca-lookup-model-picker>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex="23%">
        <ca-date-picker [required]="false" placeholder="Begin Date"
                        caInput
                        formControlName="beg_chg_date"
        ></ca-date-picker>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex="23%">
        <ca-date-picker [required]="false" placeholder="End Date"
                        caInput
                        formControlName="end_chg_date"
        ></ca-date-picker>
      </div>
      <div class="flex-item caGap" class="charge-buttons" fxLayoutAlign="center center">
        <button mat-icon-button (click)="addCharge(chargeForm)"
                [disabled]="chargeForm?.invalid" matTooltip="Add New Charge">
          <ca-icon [key]="'ADD_CHARGE'"></ca-icon>
        </button>

        <button mat-icon-button matTooltip="Update Selected Charge"
                (click)="updateCharge(selectedCharge, chargeForm)"
                [disabled]="!selectedCharge || chargeForm?.invalid">
          <ca-icon [key]="'UPDATE_CHARGE'"></ca-icon>
        </button>

        <button mat-icon-button matTooltip="Delete Selected Charge"
                (click)="deleteCharge(selectedCharge)"
                [disabled]="!selectedCharge">
          <ca-icon [key]="'DELETE_CHARGE'"></ca-icon>
        </button>

        <button type="reset" matTooltip="Clear" mat-icon-button (click)="resetCharge()">
          <ca-icon [key]="'CLEAR_CHARGE_FORM'"></ca-icon>
        </button>
      </div>
    </div>
    <div class="flex-container flex-container-3" fxLayout="row">
      <div class="flex-item caGap caGapRight" fxFlex="79%">
        <mat-form-field caInput>
          <mat-label>Charge Description</mat-label>
          <input matInput #chargeDescription
                 type="text"
                 formControlName="chg_desc_1"/>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-container flex-container-3" fxLayout="row">
      <div class="flex-item caGap caGapRight" fxFlex="79%">
        <mat-form-field caInput>
          <mat-label>Comment</mat-label>
          <input matInput #chargeComment
                 type="text"
                 formControlName="comment"/>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="charge-grid-container">
  <dx-data-grid #chargeGrid [columns]="columns" [width]="'100%'"
  [height]="'100%'" [dataSource]="chargeEntries" (onSelectionChanged)="onSelectionChanged($event)">

  <div *dxTemplate="let d of 'currencyTemplate'">
  <div caGridTooltip class="right">{{ d.value || 0 | caCurrency: this.invoice && this.invoice.currency ? this.invoice.currency :'USD' }}</div>
  </div>
    <div *dxTemplate="let d of 'dateTemplate'" style="text-align: left;">
      <span>{{ d.value | caDate }}</span>
    </div>
  </dx-data-grid>
  </div>
</div>

<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space">
  </div>

  <button #cancelButton mat-button type="submit" (click)="close()">
    Cancel
  </button>
  <button
    #saveButton
    mat-button
    type="submit"
    (click)="onSubmit(chargeForm)"
    [disabled]="!this.chargeEntries.length">
    Save
  </button>
</mat-dialog-actions>

