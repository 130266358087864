export class StoreSelectors {
  getSelector(selectorName) {
    return (...args) => {
      let valueToReturn;
      const selector = this[selectorName];
      if (!selector) {
        throw new Error(`${selectorName} does not exist on ${this.constructor.name}`);
      }
      selector.apply(this, args).first().subscribe(value => {
        valueToReturn = value;
      });

      return valueToReturn;
    };
  }

  get raw() {
    const selectors: any = {};
    for (const selectorName in this) {
      selectors[selectorName] = this.getSelector(selectorName);
    }

    return selectors;
  }
}
