import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonAlertService } from './common-alert.service';

@Injectable()
export class CommonAlertResolve {
	constructor(public alertService: CommonAlertService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const alertId = route.params.id;
		return this.alertService.findById(alertId);
	}
}
