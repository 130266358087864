export const MESSAGES_ENUM = {
	'common': {
		INSUFFICIENT_PERMISSIONS:
			'You do not have permissions to perform this action',
		UPDATE_SUCCESS: 'User updated',
		FORM_INVALID: 'Please enter valid data for User',
		CREATE_ERROR: 'Failed to create user',
		UPDATE_ERROR: 'Failed to update user'
	},
	'users': {
		CREATE_SUCCESS: 'User created',
		UPDATE_SUCCESS: 'User updated',
		FORM_INVALID: 'Please enter valid data for User',
		CREATE_ERROR: 'Failed to create user',
		UPDATE_ERROR: 'Failed to update user'
	},
	'dash': {
		CREATE_SUCCESS: 'Dash created',
		UPDATE_SUCCESS: 'Dash updated',
		FORM_INVALID: 'Please enter valid data for Dash',
		CREATE_ERROR: 'Failed to create dash',
		UPDATE_ERROR: 'Failed to update dash'
	},
	'common-alert': {
		ERROR_LOADING_GL_VALIDATION: 'Error loading GL validation alerts',
		ERROR_LOADING_CHARGES: 'Error loading charges',
		ERROR_LOADING_INVOICES: 'Error loading invoices',
		INVALID_CHARGES_SELECTION_FOR_DISPUTE:
			'Select only Charges with same BAN and Invoice and without Dispute.',
		COMPLETE_SUCCESS: 'Selected alerts completed successfully',
		COMPLETE_FAIL: 'Failed to complete selected alerts',
		ASSIGN_SUCCESS: 'Alert owner assigned successfully',
		ASSIGN_FAIL: 'Failed to assign alert owner',
		INVOICE_RELOAD_SUCCESS: 'Invoice reload completed successfully',
		INVOICE_RELOAD_FAILED: 'Invoice reload failed or invoice already loaded',
		INVOICE_RELOAD_STATUS_GREATER_THAN_BATCH:
			'Invoice is in Batch Output status or higher',
		ITEMS_EXCLUDED: 'Items were successfully excluded.'
	},
	'account': {
		CREATE_SUCCESS: 'Account created',
		UPDATE_SUCCESS: 'Account updated',
		IS_VAT_GL_OUTPUT_ENABLED: 'This will impact <number> opened disputes',
		FORM_INVALID: 'Please enter valid data for account',
		CREATE_ERROR: 'Failed to create account',
		UPDATE_ERROR: 'Failed to update account',
		DELETE_ERROR: 'Failed to delete account',
		DUPLICATE_ACCOUNT: 'Unable to save. Vendor Account already exists'
	},
	'dictionary': {
		CREATE_SUCCESS: 'Dictionary data has been created successfully.',
		UPDATE_SUCCESS: 'Dictionary data has been updated successfully.',
		FORM_INVALID: 'Please enter valid data for dictionary',
		CREATE_ERROR: 'Failed to create dictionary',
		UPDATE_ERROR: 'Failed to update dictionary',
		DELETE_SUCCESS: 'Dictionary data has been deleted successfully.',
		DELETE_ERROR: 'Failed to delete dictionary',
		DELETE_CONFLICT_ERROR: 'Dictionary is in use and cannot be deleted.',
		REORDER_SUCCESS: 'Dictionary data has been reordered successfully.'
	},
	'roles': {
		CREATE_SUCCESS: 'Role created',
		UPDATE_SUCCESS: 'Role updated',
		FORM_INVALID: 'Please enter valid data for role',
		CREATE_ERROR: 'Failed to create role',
		UPDATE_ERROR: 'Failed to update role',
		DELETE_SUCCESS: 'Role deleted',
		DELETE_ERROR: 'Failed to delete role',
		SYSTEM_ROLE_WARNING: 'Can not delete SYSTEM role!'
	},
	'tag': {
		CREATE_SUCCESS: 'Tag created',
		UPDATE_SUCCESS: 'Tag updated',
		FORM_INVALID: 'Please enter valid data for tag',
		CREATE_ERROR: 'Failed to create tag',
		UPDATE_ERROR: 'Failed to update tag',
		DELETE_SUCCESS: 'Tag deleted',
		DELETE_ERROR: 'Failed to delete tag',
		MERGE_ERROR: 'Failed to merge tags'
	},
	'site': {
		CREATE_SUCCESS: 'Site created',
		UPDATE_SUCCESS: 'Site updated',
		FORM_INVALID: 'Please enter valid data for site',
		CREATE_ERROR: 'Failed to create site',
		UPDATE_ERROR: 'Failed to update site',
		DELETE_SUCCESS: 'Site deleted',
		DELETE_ERROR: 'Cannot delete. This site is used within the application.',
		CONSTRAIN_DELETE_ERROR:
			'Cannot delete. This site is used within the application.',
		DUPLICATE_SITE: 'Unable to save. This Site name is already in use'
	},
	'invoice': {
		ACTIVE_BATCH_ERROR: 'Invoice can be only part of one active batch.',
		ACCOUNT_REGION_ERROR: 'Non existing region on invoice account.',
		CONFIG_REGION_ERROR: 'Missing / Non existing region in the config file.',
		SOURCE_FILE_ERROR: 'Invoice source file does not exist.',
		UPDATED_INVOICES_ERROR: 'Invoice status is not approved for these invoices',
		APPROVED_INCORRECT_STATUS: 'Invoices are in incorrect status. Invoices: ',
		APPROVED_INCORRECT_STATUS_ONE_INVOICE:
			'Invoice is in incorrect status. Invoice: ',
		APPROVE_SUCCESS: 'Invoices has been successfully approved',
		GL_OUTPUT_SUCCESS: 'GL Batch Output created successfully',
		GL_OUTPUT_SUCCESS_DXC: 'Batch is created',
		GL_OUTPUT_SUCCESS_TAILWIND: 'Batch process started',
		GL_OUTPUT_WRONG_FORMAT: "Batch output format isn't correct",
		GL_OUTPUT_ERROR:
			'Mismatch found and Batch is not created. Please contact the support.',
		GL_OUTPUT_ERROR_BCM_ONE:
			'Something went wrong. Please check Alerts or contact the support.',
		GL_OUTPUT_PAYMENT_ERROR: 'GL Batch Output failed. Payment not created.',
		GL_OUTPUT_IN_PROCESS: 'GL Batch Processing started',
		GL_BATCH_NO_INVOICES: "Can't process! No invoices in GL Batch!",
		INVOICE_LOAD_ERROR: 'Failed to load invoice',
		INVOICE_DOCUMENT_LOAD_ERROR: 'Failed to load invoice documents',
		INVOICES_LOAD_ERROR: 'Failed to load invoices',
		CHARGE_LOAD_ERROR: 'Failed to load charges',
		DISPUTE_LOAD_ERROR: 'Failed to load disputes',
		SUBACCOUNT_LOAD_ERROR: 'Failed to load subaccounts',
		AUDIT_LOAD_ERROR: 'Failed to load audits',
		NOTE_LOAD_ERROR: 'Failed to load notes',
		CHARACTER_LENGTH_ERROR: '20 characters minimum',
		NO_FILE_FOUND: 'No file found',
		COMPLETE_SUCCESS: 'Selected alerts completed successfully',
		NO_VENDOR_SOURCE_FILE: 'Vendor source file not found',
		GL_CODING_INVOICE:
			'GL coding started. We will notify you when it is completed.',
		GL_CODING_SUCCESS: 'GL coding successful',
		GL_CODING_INCORRECT_STATUS: 'Invoices are in incorrect status. Invoices: ',
		GL_CODING_INCORRECT_STATUS_ONE_INVOICE:
			'Invoice is in incorrect status. Invoice: ',
		GL_CODING_ERROR: 'GL coding error:',
		MANUAL_GL_ASSIGN_SUCCESS: 'Direct gl codes have been assigned',
		MANUAL_GL_ASSIGN_CANCELLED: 'No direct gl codes have been assigned',
		MANUAL_GL_DELETED: 'Direct gl codes have been removed successfully',
		CHANGE_ADJUSTMENT_SUCCESS: 'Adjustments set',
		CHANGE_ADJUSTMENT_ALL_SUCCESS:
			'All Adjustments are set. Invoice Process started',
		DELETE_SUCCESS: 'Invoices deleted',
		ON_HOLD: 'Invoice is on hold now',
		OFF_HOLD: 'On Hold removed',
		ACCOUNT_UPDATED: 'Account updated',
		IS_VAT_GL_OUTPUT_ENABLED: 'This will impact on <number> opened disputes',
		INACTIVE_ACCOUNT_CANNOT_RUN_RULES:
			'The Account VAT GL Output is inactive and cannot run custom GL VAT Rules',
		ACTIVE_ACCOUNT_CANNOT_RUN_WITHOUT_RULES:
			'The Account VAT GL Output is active and cannot run GL Processing without custom GL VAT Rules',
		DNP_FALLBACK_MESSAGE: 'Done before 4.6.2 release',
		INVOICE_HAS_DISPUTE:
			'Invoice can not be deleted, invoice have active dispute',
		INVOICE_ON_HOLD_MESSAGE: 'Invoice is on hold. Invoices: ',
		DUPLICATE_RULE: 'Cannot add duplicate rule',
		ADD_DOCUMENT_SUCCESS: 'Document created successfully.',
		EDIT_DOCUMENT_SUCCESS: 'Document edited successfully.',
		ADD_DOCUMENT_FAIL: 'Failed to upload file.',
		EDIT_DOCUMENT_FAIL: 'Failed to edit file.',
		DELETE_DOCUMENT_SUCCESS: 'Document deleted successfully.',
		DELETE_DOCUMENT_FAIL: 'Failed to delete document',
		NO_DOCUMENT_FILE: 'Document file not found',
		GL_OUTPUT_INVOICE_LIMIT: 'GL Output; Must be less than <number>',
		CHARGE_CREATE_SUCCESS: 'Charge created',
		CHARGE_DELETE_SUCCESS: 'Charges deleted',
		CHARGE_PROFILE_CREATE_SUCCESS: 'Profile charge(s) created',
		CHARGE_PROFILE_CREATE_FAIL: 'Failed to create Profile charge(s)'
	},
	'dispute': {
		DISPUTE_CREATE_SUCCESS: 'New dispute has been created',
		DISPUTE_CHARGE_ADDED: 'Charge added to existing dispute',
		GET_DISPUTE_BY_ID_ERROR: 'Failed to load dispute',
		CREATE_ERROR: 'Failed to create dispute',
		UPDATE_ERROR: 'Failed to update dispute',
		DELETE_CHARGES_ERROR: 'Failed to delete charges on dispute',
		DELETE_WITHHELD_CHARGE:
			"Can't delete withheld charge line for Invoice status greater than GL Coded.",
		DELETE_WITHHELD_DISPUTE:
			"Can't delete Dispute with withheld charge line(s) for Invoice status greater than GL Coded.",
		UPDATE_SUCCESS: 'Dispute updated',
		DELETE_CHARGES_SUCCESS: 'Charges deleted',
		FORM_INVALID: 'Please enter valid data for Dispute',
		CHARGES_INVALID: 'Please provide charge(s) for Dispute',
		CALCULATED_CHARGES_INVALID: 'Enter Calculated Charges for all Charges',
		MISSING_AMOUNT_AWARDED:
			'Enter Dispute Amount Awarded value for records with Resolution Date',
		NO_WITHHELD_COMPLETED_INVOICE:
			"Can't add Withheld Dispute for Completed Invoice",
		DELETE_SUCCESS: 'Dispute deleted',
		DELETE_ERROR: 'Failed to delete dispute',
		AMOUNT_AWARDED_INVALID:
			"Dispute Amount Awarded can't be negative or greater than Dispute Amount",
		INVALID_WITHHELD_AP_OUTPUT:
			'A disputed charge cannot have AP Withheld set to ‘Yes’ when the Payment Withheld is set to ‘No’'
	},
	'charge': {
		MANUAL_GL_ASSIGN_SUCCESS: 'Direct gl codes have been assigned',
		MANUAL_GL_ASSIGN_REMOVED: 'Direct gl codes have been removed successfully',
		MANUAL_GL_ASSIGN_CANCELLED: 'No direct gl codes have been assigned',
		INVALID_CHARGES_SELECTION_FOR_DISPUTE:
			'Select only Charges with same BAN and Invoice and without Dispute',
		CREATE_ERROR: 'Failed to create charge',
		CREATE_SUCCESS: 'Charge created',
		DELETE_ERROR: 'Failed to delete charge',
		DELETE_SUCCESS: 'Charge deleted',
		CHARGE_PROFILE_CREATE_SUCCESS: 'Profile charge(s) created',
		CHARGE_PROFILE_CREATE_FAIL: 'Failed to create Profile charge(s)'
	},
	'rate-audit': {
		UPDATE_SUCCESS: 'Rate audit updated successfully',
		DELETE_CONFIRM: 'Are you sure you want to delete rate audit',
		DELETE_SUCCESS: 'Rate audit deleted',
		DELETE_ERROR: 'Failed to delete rate audit',
		CREATE_SUCCESS: 'Rate audit created',
		CREATE_ERROR: 'Failed to create rate audit',
		UPDATE_ERROR: 'Failed to update rate audit',
		BAN_CONFIG_CREATE_ERROR: 'Failed to create ban config',
		BAN_CONFIG_DELETE_ERROR: 'Failed to delete ban config',
		BAN_CONFIG_UPDATE_ERROR: 'Failed to update ban config',
		FORM_INVALID: 'Form values are not valid',
		BAN_CONFIG_LOAD_ERROR: 'Error loading BAN config for rate audit',
		CANNOT_DELETE_ALL_RULES: 'Cannot remove all rules from Audit',
		AUDIT_DELETE_ERROR: 'Cannot delete rule',
		DELETE_RULE_SUCCESS: 'Rule deleted successfully',
		DELETE_RULES_SUCCESS: 'Rules deleted successfully'
	},
	'building': {
		CREATE_SUCCESS: 'Building created',
		UPDATE_SUCCESS: 'Building updated',
		DELETE_SUCCESS: 'Building deleted',
		FORM_INVALID: 'Please enter valid data for building',
		CREATE_ERROR: 'Failed to create building',
		UPDATE_ERROR: 'Failed to update building',
		DELETE_ERROR: 'Failed to delete building',
		CREATE_ADDRESS_ERROR: 'Failed to create address',
		CONSTRAIN_DELETE_ERROR: 'Cannot delete, Building in use',
		DUPLICATE_BUILDING: 'Unable to save. This Building name is already in use'
	},
	'contact': {
		CREATE_SUCCESS: 'Contact created',
		UPDATE_SUCCESS: 'Contact updated',
		DELETE_SUCCESS: 'Contact deleted',
		FORM_INVALID: 'Please enter valid data for contact',
		CREATE_ERROR: 'Failed to create contact',
		UPDATE_ERROR: 'Failed to update contact',
		DELETE_ERROR: 'Cannot delete contact in use',
		CREATE_ADDRESS_ERROR: 'Failed to create address',
		CONSTRAIN_DELETE_ERROR: 'Contact is in use and cannot be deleted',
		DUPLICATE_CONTACT: 'Unable to save. This Contact name is already in use'
	},
	'customer': {
		CREATE_SUCCESS: 'Customer created',
		UPDATE_SUCCESS: 'Customer updated',
		DELETE_SUCCESS: 'Customer deleted',
		FORM_INVALID: 'Please enter valid data for customer',
		CREATE_ERROR: 'Failed to create customer',
		UPDATE_ERROR: 'Failed to update customer',
		DELETE_ERROR: 'Cannot delete customer in use',
		CONSTRAIN_DELETE_ERROR: 'Customer in use and cannot be deleted',
		CREATE_ADDRESS_ERROR: 'Failed to create address',
		DUPLICATE_CUSTOMER: 'Unable to save. This Company name is already in use'
	},
	'vendor': {
		CREATE_SUCCESS: 'Vendor created',
		UPDATE_SUCCESS: 'Vendor updated',
		DELETE_SUCCESS: 'Vendor deleted',
		FORM_INVALID: 'Please enter valid data for vendor',
		CREATE_ERROR: 'Failed to create vendor',
		UPDATE_ERROR: 'Failed to update vendor',
		DELETE_ERROR: 'Failed to delete vendor',
		DELETE_ACTIVE_VENDOR_ERROR: 'Active Vendor can not be deleted',
		MOVE_ENTITY_SUCCESS: 'Vendor Entity has been moved successfully.',
		CREATE_ENTITY_SUCCESS: 'Vendor Entity has been created successfully.',
		DELETE_ENTITY_SUCCESS: 'Vendor Entity has been deleted successfully.',
		DELETE_ENTITY_ERROR: 'Error delete Entity.',
		NO_IMAGE_FOUND: 'No image',
		NO_VENDORS_FOUND: 'Failed to get vendors.',
		CREATE_ENTITY_ERROR: 'Entity with this name already exist.',
		DUPLICATE_VENDOR: 'Unable to save. This Vendor name is already in use'
	},
	'vendor-remit-address': {
		CREATE_SUCCESS: 'Vendor remit address created',
		UPDATE_SUCCESS: 'Vendor remit address updated',
		DELETE_SUCCESS: 'Vendor remit address deleted',
		FORM_INVALID: 'Please enter valid data for vendor remit address',
		CREATE_ERROR: 'Failed to create vendor remit address',
		UPDATE_ERROR: 'Failed to update vendor remit address',
		DELETE_ERROR: 'Failed to delete vendor remit address',
		DELETE_ACTIVE_ITEM_ERROR: 'Cannot delete vendor remit address in use',
		CREATE_ADDRESS_ERROR: 'Failed to create address',
		DUPLICATE_VENDOR_REMIT_ADDRESS:
			'Unable to save. This Vendor Location ID is already in use'
	},
	'contract': {
		CREATE_SUCCESS: 'Contract has been created successfully',
		UPDATE_SUCCESS: 'Contract updated successfully',
		FORM_INVALID: 'Please enter valid data for contract',
		DELETE_SUCCESS: 'Contract deleted successfully',
		DELETE_ERROR: 'Failed to delete contract',
		CONSTRAIN_DELETE_ERROR: 'Cannot delete Contract in use',
		CREATE_ERROR: 'Failed to create new contract',
		UPDATE_ERROR: 'Failed to update contract',
		DOCUMENT_GET_ERROR: 'Failed to get documents',
		DOCUMENT_UPLOAD_SUCCESS: 'Document uploaded succesfully',
		DOCUMENT_UPLOAD_ERROR: 'Failed to upload document',
		DOCUMENT_DELETE_SUCCESS: 'Document deleted successfully',
		DOCUMENT_DELETE_ERROR: 'Failed to delete document',
		SECTION_GET_ERROR: 'Failed to get contract sections',
		SECTION_DELETE_ERROR: 'Failed to delete contract section',
		SECTION_CREATE_SUCCESS: 'New section created successfully',
		SECTION_CREATE_ERROR: 'Failed to create new contract section',
		SECTION_UPDATE_SUCCESS: 'Section updated successfully',
		SECTION_UPDATE_ERROR: 'Failed to update contract section',
		SECTION_DELETE_SUCCESS: 'Contract section deleted successfully',
		SECTION_FORM_INVALID: 'Please enter valid data for contract section',
		VENDOR_GET_ERROR: 'Failed to get contract vendor',
		CONTRACT_VENDOR_GET_ERROR: 'Failed to get contracts by vendor',
		DOCUMENT_NO_FILE: 'Please select a file to upload',
		DELETE_CONFLICT_ERROR: 'Document is in use and cannot be deleted.',
		RATE_DELETE_SUCCESS: 'Contract rates deleted successfully',
		RATE_DELETE_ERROR: 'Failed to delete contract rates',
		UPDATE_RATE_SUCCESS: 'Contract rates updated successfully',
		RATE_CONSTRAIN_DELETE_ERROR: 'Rate in use and cannot be deleted',
		INSTANCE_CREATE_SUCCESS: 'New instance created successfully',
		INSTANCE_CREATE_ERROR: 'Failed to cteate new instance',
		INSTANCE_UPDATE_SUCCESS: 'Instance updated successfully',
		INSTANCE_UPDATE_ERROR: 'Failed to update instance',
		TERMS_CONDITION_SUCCESS: 'New Terms & Conditions created successfully',
		TERMS_CONDITION_ERROR: 'Failed to create Term and Condition',
		TERMS_CONDITION_DELETE_SUCCESS: 'Terms & Conditions deleted successfully',
		TERMS_CONDITION_DELETE_ERROR: 'Failed to delete Term & Condition',
		TERMS_CONDITION_UPDATE_SUCCESS: 'Terms & Conditions updated successfully',
		TERMS_CONDITION_UPDATE_ERROR: 'Failed to update Term & Condition',
		CONTRACT_RATES_DISCARD_CHANGES:
			'You have unsaved rate changes! Do you want to discard them'
	},
	'schedule': {
		CREATE_SUCCESS: 'Contract schedule has been created successfully',
		UPDATE_SUCCESS: 'Contract schedule updated successfully',
		FORM_INVALID: 'Please enter valid data for contract schedule',
		DELETE_SUCCESS: 'Contract schedule deleted successfully',
		DELETE_ERROR: 'Failed to delete contract schedule',
		DELETE_USED_ERROR: 'Cannot delete schedules with rates used in audits',
		CONSTRAIN_DELETE_ERROR: 'Cannot delete contract schedule in use',
		CREATE_ERROR: 'Failed to create new contract schedule',
		UPDATE_ERROR: 'Failed to update contract schedule',
		TERMS_CONDITION_SCHEDULE_SUCCESS:
			'New Terms & Conditions created successfully',
		TERMS_CONDITION_SCHEDULE_ERROR: 'Failed to create Term and Condition',
		TERMS_CONDITION_SCHEDULE_DELETE_SUCCESS:
			'Terms & Conditions deleted successfully',
		TERMS_CONDITION_SCHEDULE_DELETE_ERROR: 'Failed to delete Term & Condition',
		TERMS_CONDITION_SCHEDULE_UPDATE_SUCCESS:
			'Terms & Conditions updated successfully',
		TERMS_CONDITION_SCHEDULE_UPDATE_ERROR: 'Failed to update Term & Condition ',
		UPDATE_RATE_SUCCESS: 'Contract rates updated successfully',
		RATE_DELETE_SUCCESS: 'Contract rates deleted successfully',
		RATE_DELETE_ERROR: 'Failed to delete contract rates',
		SCHEDULE_ALREADY_EXISTS: 'Name already exists'
	},
	'svc-order': {
		CREATE_SUCCESS: 'Service Order has been created successfully',
		UPDATE_SUCCESS: 'Service Order updated successfully',
		FORM_INVALID: 'Please enter valid data for order',
		DELETE_SUCCESS: 'Order deleted successfully',
		DELETE_ERROR: 'Failed to delete order',
		CONSTRAIN_DELETE_ERROR: 'Cannot delete Order in use',
		CREATE_ERROR: 'Failed to create new order',
		UPDATE_ERROR: 'Failed to update order',
		DOCUMENT_GET_ERROR: 'Failed to get documents',
		DOCUMENT_UPLOAD_SUCCESS: 'Document uploaded succesfully',
		DOCUMENT_UPLOAD_ERROR: 'Failed to upload document',
		DOCUMENT_DELETE_SUCCESS: 'Document deleted successfully',
		DOCUMENT_DELETE_ERROR: 'Failed to delete document',
		VENDOR_GET_ERROR: 'Failed to get order vendor',
		ORDER_VENDOR_GET_ERROR: 'Failed to get orders by vendor',
		DOCUMENT_NO_FILE: 'Please select a file to upload',
		DELETE_CONFLICT_ERROR: 'Document is in use and cannot be deleted.',
		TERMS_CONDITION_SVC_ORDER_SUCCESS:
			'New Terms & Conditions created successfully',
		TERMS_CONDITION_SVC_ORDER_ERROR: 'Failed to create Term and Condition',
		TERMS_CONDITION_SVC_ORDER_DELETE_SUCCESS:
			'Terms & Conditions deleted successfully',
		TERMS_CONDITION_SVC_ORDER_DELETE_ERROR: 'Failed to delete Term & Condition',
		TERMS_CONDITION_SVC_ORDER_UPDATE_SUCCESS:
			'Terms & Conditions updated successfully',
		TERMS_CONDITION_SVC_ORDER_UPDATE_ERROR: 'Failed to update Term & Condition',
		INVENTORY_NOT_FOUND: 'Inventory with SPID not found',
		INVENTORY_GET_ERROR: 'Failed to get Inventory'
	},
	'customer-inventory': {
		CREATE_SUCCESS: 'Customer inventory created',
		UPDATE_SUCCESS: 'Customer inventory updated',
		FORM_INVALID: 'Please enter valid data for customer inventory',
		CREATE_ERROR: 'Failed to create customer inventory',
		UPDATE_ERROR: 'Failed to update customer inventory',
		DELETE_SUCCESS: 'Customer inventory deleted',
		DELETE_ERROR: 'Failed to delete customer inventory',
		SITE_EXIST_ERROR: 'Error adding site. Site already added!',
		CONSTRAIN_DELETE_ERROR: 'Customer inventory in use and cannot be deleted'
	},
	'inventory': {
		CREATE_SUCCESS: 'Inventory created',
		UPDATE_SUCCESS: 'Inventory updated',
		FORM_INVALID: 'Please enter valid data for inventory',
		CREATE_ERROR: 'Failed to create inventory',
		UPDATE_ERROR: 'Failed to update inventory',
		DELETE_SUCCESS: 'Inventory deleted',
		DELETE_ERROR: 'Failed to delete inventory',
		DUPLICATE_UNIQUE_ID: 'Inventory with entered "SP Svc ID" already exists',
		DUPLICAT_VENDOR_ACCOUNT_SP_CKT_ID:
			'Cannot save.  The Vendor, Account, and SP Service ID combination already exists.',
		ADD_PRICING_SUCCESS: 'Pricing document created successfully.',
		DELETE_PRICING_SUCCESS: 'Pricing document deleted successfully.',
		DELETE_PRICING_FAIL: 'Failed to delete pricing document',
		DELETE_SITE_SUCCESS: 'Site deleted successfully.',
		DELETE_SITE_FAIL: 'Failed to delete site',
		GL_STRINGS_SAVE_SUCCESS: 'GL Codes have been saved successfully.',
		GL_STRINGS_SAVE_ERROR: 'Error saving GL Codes.',
		FETCH_CIRCUITS_ERROR: 'Error fetching related services',
		FETCH_CHARGE_PROFILE_ERROR: 'Error fetching charge profiles',
		SITE_EXIST_ERROR: 'Error adding site. Site already added!',
		RELATED_CIRCUIT_ALREADY_SELECTED: 'Selected circuit is already in list',
		ADD_PRICING_FAIL: 'Failed to upload file.',
		GET_PRICING_FAIL: 'File not found',
		NOT_UNIQUE_ALIAS:
			'You are trying to enter billing alias which already exists for the selected vendor - BAN combination',
		ASSIGNED_SUCCESS: 'Inventory is assigned to the contract successfully',
		UNASSIGNED_SUCCESS:
			'Inventory is unassigned from the contract successfully',
		CREATE_CONTRACT_TERM_SUCCESS: 'Contract term is created',
		UPDATE_CONTRACT_TERM_SUCCESS: 'Contract term is updated',
		CREATE_CONTRACT_TERM_ERROR: 'Failed to create contract term',
		UPDATE_CONTRACT_TERM_ERROR: 'Failed to update contract term',
		DELETE_CONTRACT_TERM_SUCCESS: 'Contract term deleted',
		DELETE_CONTRACT_TERM_ERROR: 'Failed to delete contract term',
		INVOICE_CHARGE_PROFILE_DISCARD_CHANGES:
			'You have unsaved Charge Profile changes! Do you want to discard them',
		CHARGE_PROFILE_UPDATE_SUCCESS: 'Profile charge(s) updated',
		CHARGE_PROFILE_UPDATE_FAIL: 'Failed to update Profile charge(s)'
	},
	'demarc': {
		DEMARC_NO_SITE: 'Site not selected'
	},
	'recon': {
		CREATE_SUCCESS: 'Inventory created.',
		ADD_BILLING_SUCCESS: 'Billing alias added successfully',
		ADD_TO_EXCLUSION_LIST: 'Inventory added to exclusion list',
		EXCLUDING_RECON_ERROR: 'Error while excluding'
	},
	'customer-equipment': {
		CREATE_SUCCESS: 'Customer Equipment has been created successfully',
		UPDATE_SUCCESS: 'Customer Equipment has been updated successfully',
		DELETE_SUCCESS: 'Customer Equipment has been deleted successfully',
		FORM_INVALID: 'Please enter valid data for customer equipment',
		CREATE_TRANSACTION_SUCCESS:
			'Customer Equipment Transaction has been created successfully',
		EDITING_LOCKED: "Customer Equipment is disposed and can't be edited"
	},
	'gl': {
		NO_INVOICES: 'Please select at least one Invoice!',
		DEACTIVATE_CONFIRM:
			'GL Batch deactivation is permanent! All invoices in GL Batch will revert to Approved state!',
		DEACTIVATE_SUCCESS: 'GL Batch is successfully set to inactive.',
		PARTIAL_DEACTIVATE_SUCCESS: 'Invoices were successfully deactivated.',
		PARTIAL_SINGLE_DEACTIVATE_SUCCESS: 'Invoice was successfully deactivated.',
		ADD_INVOICES_SUCCESS:
			'Selected invoice(s) are successfully added to GL Batch.',
		GL_BATCH_DELETE_CONFIRM:
			'Are you sure you want to remove selected invoice(s) from GL Batch?',
		REMOVE_INVOICES_SUCCESS:
			'Selected invoice(s) are successfully removed from GL Batch.',
		NO_INVOICES_ALERT: "Can't process! No invoices in GL Batch!",
		RE_PROCESS_SUCCESS: 'GL Batch has been successfully re-processed',
		RE_PROCESS_ERROR: 'Error processing GL Batch',
		GL_CODE_DELETE_SUCCESS: 'GL Code has been deleted successfully.',
		GL_CODE_UPDATE_SUCCESS: 'GL Code has been updated successfully.',
		GL_CODE_CREATE_SUCCESS: 'GL Code has been created successfully.',
		GL_CODE_DELETE_CONFIRM: 'Are you sure you want to delete GL Code ',
		GL_CODE_DELETE_ACTIVE_ERROR: 'Active GL Code can not be deleted.',
		DELETE_ERROR: 'An error occurred. Please try again later',
		CONSTRAIN_DELETE_ERROR: 'This Rule is in use and cannot be deleted',
		GL_CODE_FORM_INVALID: 'Please enter valid data for GL Code',
		NOT_UNIQUE: 'Segment Value must be unique.',
		GL_RULE_FORM_INVALID: 'Please enter valid data for GL Rule',
		GL_RULE_DELETE_SUCCESS: 'GL Rule has been deleted successfully.',
		GL_RULE_UPDATE_SUCCESS: 'GL Rule has been updated successfully.',
		GL_RULE_CREATE_SUCCESS: 'GL Rule has been created successfully.',
		GL_RULE_SAVE_SUCCESS: 'GL Rules have been saved successfully.',
		RULES_LOAD_ERROR: 'Failed to load rules',
		RULES_PROCESS_SUCCESS: 'Will be processed',
		RULES_PROCESS_VENDOR_BAN_ERROR: 'You need to specify a Vendor and a BAN',
		SAVE_ERROR: 'An error occurred. Please try again later',
		GL_STRING_UPDATE_SUCCESS: 'GL String has been updated successfully.',
		GL_STRING_CREATE_SUCCESS: 'GL String has been created successfully.',
		GL_STRING_FORM_INVALID: 'Please enter valid data for GL String',
		GL_STRING_DUPLICATE_ERROR: 'There is GL String with selected codes',
		GL_STRING_ERROR: 'Error creating new GL String',
		GL_STRING_REPLACE_SUCCESS: 'GL Strings are replaced successfully',
		GL_STRING_REPLACE_ERROR: 'Error occurred while trying to replace strings',
		GL_STRING_REPLACE_INACTIVE_ERROR: 'Inactive Strings cannot be replaced!',
		GL_STRING_REPLACE_EQ_ERROR: 'Please select different string as replacement',
		GL_STRING_REPLACE_SELECTION_ERROR:
			'Please select second string for replacement',
		GL_STRING_REPLACE_CANCEL: 'String replace canceled',
		BAN_CODING_UPDATE_SUCCESS:
			'GL Coding has been changed successfully for selected BAN',
		BAN_CODING_FORM_INVALID: 'Apportion sum on all GL strings needs to be 100%',
		ERROR: 'An error occurred. Please try again later',
		BAN_CODING_GL_STATUS_CHANGE_SUCCESS:
			'GL Coding has been changed successfully for selected BAN',
		SYSTEM_RULE_ERROR:
			'GL String selection is not saved. Apportion total must be 100%.',
		INVENTORY_CODING_VENDOR:
			'Inventory GL Coding status has been changed on Vendor level for ',
		INVENTORY_CODING_BAN:
			'Inventory GL Coding status has been changed on BAN level for Account ',
		SPID_CODING_GL_STATUS_CHANGE_SUCCESS:
			'GL Coding has been changed successfully for selected BAN',
		SUB_ACCOUNT_UPDATE_SUCCESS:
			'Sub Account GL Strings have been successfully updated',
		SUB_ACCOUNT_GL_STATUS_CHANGE_SUCCESS:
			'GL Coding has been changed successfully for selected BAN',
		CRITERIA_DATA_ERROR: 'No data for this criteria',
		NEXT_STEP_ERROR: 'At least one rule needs to be added',
		GL_CODING_INVOICE:
			'GL coding started. We will notify you when it is completed.',
		GL_CODING_SUCCESS: 'GL coding successful',
		GL_CODING_INCORRECT_STATUS: 'Invoice is in incorrect status',
		GL_CODING_ERROR: 'GL coding error:',
		GL_NO_PERMISSION_DELETE:
			"User don't have permissions to delete GL Allocations",
		INACTIVE_ACCOUNT_CANNOT_RUN_RULES:
			'The Account VAT GL Output is inactive and cannot run custom GL VAT Rules',
		ACTIVE_ACCOUNT_CANNOT_RUN_WITHOUT_RULES:
			'The Account VAT GL Output is active and cannot run GL Processing without custom GL VAT Rules'
	},
	'report-gallery': {
		LOAD_DATA_FAIL: 'Failed to load report data.'
	},
	'srm': {
		CREATE_SUCCESS: 'Report created',
		CREATE_ERROR: 'Failed to create report',
		DUPLICATE_NAME_ERROR: 'Name is already taken',
		UPDATE_SUCCESS: 'Report updated',
		UPDATE_ERROR: 'Failed to update report',
		DELETE_PUBLIC_SUCCESS: 'Report status changed to private',
		DELETE_SUCCESS: 'Report deleted',
		DELETE_ERROR: 'Failed to delete report'
	},
	'order': {
		FORM_INVALID: 'Form values are not valid',
		UPDATE_SUCCESS: 'Order has been updated successfully.',
		DELETE_SERVICE_SUCCESS: 'Order Service has been deleted successfully.',
		DELETE_SITE_SUCCESS: 'Site deleted successfully.',
		DELETE_SITE_FAIL: 'Failed to delete site',
		ORDER_STATUS_CHANGE_ALERT_TITLE: 'Order Status Change',
		ORDER_SERVICE_STATUS_CHANGE_ALERT_TITLE: 'Order Service Status Change',
		DELETE_SUCCESS: 'Order has been deleted successfully.',
		VENDOR_SEND_SUCCESS: 'Order Sent to Vendor.',
		CREATE_SUCCESS: 'Order has been created successfully.',
		CREATE_ERROR: 'Error creating new order',
		DELETE_ERROR: 'An error occurred.',
		UPDATE_ERROR: 'Error updating order',
		ORDER_SERVICE_CREATE_ERROR: 'Error creating new order service',
		SITE_EXIST_ERROR: 'Error adding site. Site already added!',
		ORDER_SERVICE_SITE_UPDATE_ERROR: 'Error adding site!',
		ORDER_SERVICE_INVALID: 'Please enter valid data for Order Service',
		ORDER_SERVICE_REQUIRE_RFA_STATE:
			'All Order services need to be in the RFA state',
		ORDER_SERVICE_REQUIRE_NEW_STATE:
			'All Order services need to be in the New state',
		ORDER_SERVICE_REQUIRED: 'Required fields are missing',
		ORDER_SERVICE_COMPLETE_NOT_ALLOWED:
			'Not allowed to complete a service with failed test',
		DUPLICATE_UNIQUE_ID: 'The inventory is not unique',
		ORDER_SERVICE_DELETE_NOT_NEW: 'Only NEW Order Service can be deleted',
		ORDER_SERVICE_DELETE_NOT_NEW_RFA:
			'Only New and RfA Order Service can be deleted',
		ORDER_DELETE_NOT_ALLOWED: 'Not allowed to delete this order',
		ORDER_DELETE_NOT_ALLOWED_PASSED_RFA:
			'Not allowed to delete this order. Passed RFA state.',
		ORDER_DELETE_NOT_ALLOWED_PASSED_APPROVED:
			'Not allowed to delete this order. Passed Approved state.',
		ORDER_DELETE_NOT_ALLOWED_ACTIVE_SERVICES:
			'Not allowed to delete an order with active services.',
		ORDER_COMPLETE_NOT_ALLOWED:
			'Not allowed to complete an order with failed test',
		CHARACTER_LENGTH_ERROR: '20 characters minimum',
		ORDER_RECORD_ONLY_APPROVED: 'Order must be Approved first',
		ORDER_SERVICE_TEMP_BAN_CKT:
			'Both BAN and CP Svc ID must not be TEMP. Change required.',
		DUPLICATE_VENDOR_ACCOUNT_SP_CKT_ID:
			'The Inventory with entered Vendor, Account, and SP Circuit ID combination already exists.'
	},
	'project': {
		DELETE_SUCCESS: 'Project has been deleted successfully.',
		UPDATE_SUCCESS: 'Project has been updated successfully.',
		CREATE_SUCCESS: 'Project has been created successfully.',
		FORM_INVALID: 'Please enter valid data for Project',
		DELETE_ERROR: 'An error occurred.',
		CREATE_ERROR: 'Failed to create project',
		UPDATE_ERROR: 'Failed to update project',
		CONSTRAIN_DELETE_ERROR: 'Project is in use and cannot be deleted'
	},
	'quote': {
		CLOSE_QUOTE_SUCCESS: 'Quote has been closed.',
		CLOSE_ERROR: 'Error closing Quote',
		FORM_INVALID: 'Form values are not valid',
		EMAIL_SEND: 'Email sent to vendors',
		UPDATE_SUCCESS: 'Quote has been updated',
		CREATE_FAIL: 'Failed to update Quote',
		UPDATE_FAIL: 'Failed to update Quote',
		UPDATE_QUOTE_SUCCESS: 'Quote has been updated successfully.',
		UPDATE_SERVICE_SUCCESS: 'Quote Service has been updated successfully.',
		CREATE_SERVICE_SUCCESS: 'Quote Service has been added successfully.',
		EDIT_QUOTE_TOOLTIP: 'Edit Quote',
		EDIT_SERVICE_TOOLTIP: 'Edit Service',
		CREATE_SUCCESS: 'Quote has been created successfully.',
		CREATE_ERROR: 'Error creating new Quote',
		UPDATE_ERROR: 'Error updating Quote',
		DELETE_ERROR: 'Error deleting Quote',
		QUOTE_SERVICE_CREATE_ERROR: 'Error creating new service',
		QUOTE_SERVICE_UPDATE_ERROR: 'Error updating service',
		QUOTE_SERVICE_DELETE_ERROR: 'Error deleting service',
		QUOTE_SERVICE_FORM_INVALID: 'Form values are not valid',
		QUOTE_SERVICE_CREATE_SUCCESS: 'Request submitted',
		QUOTE_SERVICE_UPDATE_SUCCESS: 'Request submitted',
		SEND_QUOTE_ERROR: 'Error sending quote request'
	},
	'address': {
		NO_ADDRESS_SELECTED: 'No address selected',
		VERIFYING: 'Verifying',
		NO_VERIFIED_ADDRESS: 'Address cannot be verified',
		FORM_INVALID: 'Please enter valid data for Address',
		CREATE_ERROR: 'Error create address',
		VALIDATION_ERROR: 'Unable to validate address'
	},
	'auth': {
		INVALID_USER: 'Invalid user credentials',
		ERROR_FETCHING_EMAIL: 'Invalid email',
		INACTIVE_USER: 'This user is set to inactive',
		USER_NOT_FOUND: 'User not found',
		EMAIL_SENT: 'Reset password email sent',
		PASSWORD_UPDATE_SUCCESS: 'Password updated successfully',
		PASSWORD_UPDATE_ERROR: 'Entered token is invalid',
		PASSWORD_UPDATE_TOKEN_NOT_FOUND: 'Entered token not found.',
		INVALID_FORM: 'Entered values are incorrect',
		OLD_PASSWORD_USED: 'Please do not use your old password',
		PASSWORDS_DONT_MATCH: "Password and confirm password don't match",
		EXPIRED_RESET_TOKEN: 'Expired Reset Token',
		EMAIL_SUBMIT:
			'Reset password request successfully submitted.\nReset email is from [email].\nIf you dont receive the email, please check your spam/junk folder.'
	},
	'autoapprover': {
		SETTINGS_UPDATE_SUCCESS: 'Auto approver settings updated successfully',
		SETTINGS_UPDATE_ERROR: 'Failed to update auto approver settings',
		MAX_ERROR: 'Amount Max must be greater than Amount Min',
		MIN_ERROR: 'Amount Min must be smaller than Amount Max'
	},
	'core': {
		DATA_LOCKED: 'Locked by ',
		TIMER_EXPIRED: 'Edit timed out'
	},
	'theme': {
		UPDATE_SUCCESS: 'Theme applied'
	},
	'shared': {
		NO_FILE: 'Please select a file to upload',
		UPLOAD_ERROR: 'Error on file upload.',
		DOC_DELETE_SUCCESS: 'Document deleted successfully',
		DOC_DELETE_FAIL: 'Failed to delete document',
		DOC_UPLOAD_SUCCESS: 'Document added successfully',
		DOC_UPLOAD_FAIL: 'Failed to upload document',
		PAGE_INFO_SUBMIT_ERROR: 'Error submitting feedback',
		PAGE_INFO_FORM_INVALID: 'Form values are not valid',
		PAGE_INFO_SUBMIT_SUCCESS: 'Report sent successfully'
	},
	'network-hub': {
		FETCH_NETWORK_HUBS_ERROR: 'Failed to fetch network hub list',
		GET_PARAMS_ERROR: 'Failed to get params from URL',
		FETCH_HUB_BY_ID_ERROR: 'Failed to fetch network hub',
		FETCH_HUB_RELATED_CIRCUITS: 'Failed to fetch network hub circuits',
		UPDATE_SUCCESS: 'Network hub has been updated successfully',
		FORM_INVALID: 'Please enter valid data for Network Hub',
		HIERARCHY_NOT_SELECTED: 'Please select hub card',
		HIERARCHY_CIRCUIT_NOT_SELECTED: 'Please select inventory from grid'
	},
	'avatar': {
		FAILED: 'Failed to load',
		GENERATING: 'Generating preview',
		SIZE_TOO_LARGE: 'Image is too large for upload'
	},
	'tn-tfn': {
		ASSIGNED_SUCCESS: 'Items have been assigned',
		UPDATE_SUCCESS: 'Item has been updated',
		DEACTIVATED_SUCCESS: 'Items have been deactivated',
		CREATED_SUCCESS: 'Item has been added',
		CREATE_ERROR: 'Failed to create telephone number',
		UPDATE_ERROR: 'Failed to update telephone number',
		TRANSACTION_CREATE_ERROR: 'Failed to create transaction',
		DUPLICATE_NUMBER: 'Number already exists'
	},
	'chart': {
		LOADING: 'Loading...'
	},
	'wireless': {
		CREATE_SUCCESS: 'Device created',
		UPDATE_SUCCESS: 'Device updated',
		FORM_INVALID: 'Please enter valid data for plan',
		CREATE_ERROR: 'Failed to create plan',
		UPDATE_ERROR: 'Failed to update plan',
		DELETE_SUCCESS: 'Device deleted',
		DELETE_ERROR: 'Failed to delete plan',
		USER_ASSIGNED_SUCCESS: 'User assigned',
		USER_ASSIGNED_ERROR: 'User assignment failed',
		USER_DEASSIGNED_SUCCESS: 'User deassigned',
		USER_DEASSIGNED_ERROR: 'User deassignment failed',
		HISTORY_DATA_LOAD_ERROR: 'Failed to load history data',
		PLAN_DATA_LOAD_ERROR: 'Failed to load plan data',
		PLAN_FEATURES_DATA_LOAD_ERROR: 'Failed to load plan features data',
		PLAN_PRICES_DATA_LOAD_ERROR: 'Failed to load plan prices data',
		PLAN_CREATE_SUCCESS: 'Plan created',
		PLAN_CREATE_ERROR: 'Plan create failed',
		PLAN_UPDATE_SUCCESS: 'Plan updated',
		PLAN_UPDATE_ERROR: 'Plan update failed',
		PLAN_DELETE_SUCCESS: 'Plan deleted',
		PLAN_DELETE_ERROR: 'Plan delete failed',
		CONSTRAIN_DELETE_ERROR:
			'This plan have created instances and it cannot be deleted',
		PLAN_INSTANCE_CREATE_SUCCESS: 'Plan instance created',
		PLAN_INSTANCE_CREATE_ERROR: 'Plan instance create failed',
		PLAN_INSTANCE_UPDATE_SUCCESS: 'Plan instance updated',
		PLAN_INSTANCE_UPDATE_ERROR: 'Plan instance update failed',
		PLAN_INSTANCE_DELETE_SUCCESS: 'Plan instance deleted',
		PLAN_INSTANCE_DELETE_ERROR: 'Plan instance delete failed'
	},
	'wireless-feature': {
		FEATURE_CREATE_SUCCESS: 'Feature created',
		FEATURE_CREATE_ERROR: 'Feature create failed'
	},
	'wireless-price': {
		PRICE_CREATE_SUCCESS: 'Price created',
		PRICE_CREATE_ERROR: 'Price create failed'
	},
	'wireless-user': {
		CREATE_SUCCESS: 'User created',
		UPDATE_SUCCESS: 'User updated',
		DELETE_SUCCESS: 'User deleted',
		DELETE_ERROR: 'Failed to delete User',
		FORM_INVALID: 'Please enter valid data for SIM',
		CREATE_ERROR: 'Failed to create User',
		UPDATE_ERROR: 'Failed to update User'
	},
	'wireless-sim': {
		CREATE_SUCCESS: 'SIM created',
		UPDATE_SUCCESS: 'SIM updated',
		FORM_INVALID: 'Please enter valid data for SIM',
		CREATE_ERROR: 'Failed to create SIM',
		UPDATE_ERROR: 'Failed to update SIM',
		DELETE_SUCCESS: 'SIM deleted',
		DELETE_ERROR: 'Failed to delete SIM'
	},
	'wireless-analytics': {},
	'file-download': {
		NON_EXISTING_FILE: 'Sorry. The requested file does not exist!'
	},
	'tax': {
		CREATE_SUCCESS: 'Tax created',
		UPDATE_SUCCESS: 'Tax updated',
		FORM_INVALID: 'Please enter valid data for tax',
		CREATE_ERROR: 'Failed to create tax',
		UPDATE_ERROR: 'Failed to update tax',
		DELETE_SUCCESS: 'Tax deleted',
		DELETE_ERROR: 'Failed to delete Tax',
		CONSTRAIN_DELETE_ERROR: 'This Tax is in use and cannot be deleted'
	},
	'bulk-upload': {
		FORMAT_ERROR: ' not valid format',
		BULK_UPLOAD_START: 'Start uploading file',
		BULK_DOWNLOAD_START:
			'Start downloading file. We will notify you when it is completed.',
		BULK_UPLOAD_DOWNLOAD_TEMPLATE_ERROR: 'Failed to download template',
		BULK_UPLOAD_DOWNLOAD_LIMIT_1: 'Download template has reached the limit',
		BULK_UPLOAD_DOWNLOAD_LIMIT_2:
			'Please, use Critera to reduce amount of records',
		BULK_UPLOAD_DOWNLOAD_FILE_FAIL: 'File not found',
		BULK_UPLOAD_SUCCESS: 'Upload file process start',
		BULK_UPLOAD_FAIL: 'Upload file has errors',
		BULK_UPLOAD_ERROR: 'Upload file process failed',
		BULK_UPLOAD_FILTER_FAIL: 'Filter not found',
		BULK_UPLOAD_FAILED_METADATA_VALIDATION: 'Template is not valid',
		BULK_UPLOAD_FAILED_UPLOAD_LIMIT: 'Max limit of upload rows is reached',
		BULK_UPLOAD_FAILED_VERSION_VALIDATION: 'Version of template is not valid',
		BULK_UPLOAD_FAILED_HEADER: 'Invalid header data',
		INVALID_DOCUMENT_TYPE:
			'Uploaded file is not a valid Bulk Data Upload document.'
	},
	'suspense': {
		FETCH_ERROR: 'Failed to load records',
		INVOICE_UPDATE_SUCCESS: 'Invoice update successful',
		INVOICE_UPDATE_ERROR: 'Invoice update failed',
		INVOICE_REPROCESS_SUCCESS: 'Invoice re-process successful',
		INVOICE_REPROCESS_ERROR: 'Invoice re-process failed',
		NO_FILE_FOUND: 'No file found'
	}
};
