import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from '../../../shared/picker/ca-picker-base.component';
import { CustomerService } from '../../core/customer.service';
import { CustomerQuery } from '../../core/customer.query';


@Component({
  selector: 'ca-customer-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaCustomerPickerComponent),
      multi: true
    },
    CustomerService
  ]
})
export class CaCustomerPickerComponent extends PickerComponentBase implements OnInit, OnChanges {
  readonly TEXT_FIELD = 'company';

  query: CustomerQuery = new CustomerQuery({
    orderBy: [['company', 'ASC']],
    limit: 20
  });

  @Input() formControlName;
  @Input() initialItem;
  @Input() initialItemId;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() clearEnabled: boolean;
  @Input() searchEnabled: boolean;
  @Input() placeholder = 'Select ...';
  @Input() pickerForm;

  public control;

  constructor(public customerService: CustomerService) {
    super();
  }

  loadInitialItem(): Observable<any> {
    return this.customerService.findById(this.initialItemId);
  }

  loadEntries(): Observable<any> {
    return this.customerService.findCustomersOnly(this.query);
  }

  search(value: string): Observable<any> {
    this.query.where['company'] = { '$ilike': `${value}%` };
    return this.customerService.findCustomersOnly(this.query);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];
  }
}
