import {Injectable} from '@angular/core';
import { Router } from "@angular/router";
import {UserSessionService} from "./user-session.service";

@Injectable()
export class LoggedUserGuardService  {

    constructor(public userSessionService: UserSessionService, public router: Router) {
    }

    canLoad() {
        if(!this.userSessionService.isSessionValid()) {
          this.router.navigate(['/auth/sign-in']);
          return false;
        }

        return true;


    }

}
