import {takeUntil} from 'rxjs';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TabGroup, TabService} from '../../shared/tabs/tab.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ContractQuery} from '../core/contract.query';
import {Contract} from '../core/contract';
import {ContractService} from '../core/contract.service';
import {PageListComponent} from '../../core/page-list.component';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {VendorService} from "../../vendor/core/vendor.service";
import {NavStateService} from "../../master/nav-state.service";
import {DialogService} from "../../shared/dialog/dialog.service";
import {AlertService} from "../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import {Sider, SiderSection, SiderSettings} from 'app/core/sider/sider';
import {LOOKUP_ENUM} from "../../dictionary/core/lookup.enum";
import {ContractFilterService} from "../core/contract-filter.service";
import {ConfigService} from "../../core/config/config.service";
import {ContractShellVendorGridService} from "../core/contract-shell-vendor-grid.service";
import {ContractShellMenageDialogComponent} from "../shared/contract-shell-menage-dialog/contract-shell-menage-dialog.component";
import {DxDataGridComponent} from "devextreme-angular";
import {SvcOrderService} from "../core/svc-order.service";
import {SvcOrderHeaderGridService} from "../core/svc-order-header-grid.service";
import {SvcOrderHeaderManageDialogComponent} from "../shared/svc-order-header-manage-dialog/svc-order-header-manage-dialog.component";
import {SvcOrderHeader} from "../core/svc-order-header";
import Query from "../../core/query/query";
import {SvcOrderHeaderFilterService} from "../core/svc-order-header-filter.service";
import {LoaderService} from "../../shared/loader/loader.service";
import {SortingService} from "../../shared/sorting/sorting.service";
import {GridSettingsComponent} from "../../core/grid/grid-settings.component";


@Component({
  selector: 'app-contract-vendor-info',
  templateUrl: './contract-vendor-info.component.html',
  styleUrls: ['./contract-vendor-info.component.css']
})
export class ContractVendorInfoComponent extends PageListComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  @ViewChild('docManager') docManager;
  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild(DxDataGridComponent) orderHeaderDataGrid: DxDataGridComponent;


  tabGroup: TabGroup;
  public tabChanged: boolean;

  query: ContractQuery = new ContractQuery();
  totalSchedulesQuery: Query = new Query();
  orderQuery: Query = new Query();

  contractVendor: any = {};
  contractVendorName: string = '';
  contractVendorId: number;
  selection: any;
  selectionOrder: any;
  activeTabIndex: number = 0;
  activeSchedule: number = 0;
  totalSchedule: number = 0;

  contractTerminationDays: { warning: number, danger: number };

  contracts;
  svcOrderHeaders;
  data;
  public columns: Array<any>;
  public orderHeaderColumns: Array<any>;

  readonly nonFilterQuery = new ContractQuery();

  DOCUMENT_INFO = {
    FOLDER: 'contracts',
    SUBFOLDER: '',
    PARENT_TYPE: this.SYSTEM_MODULE.ORDER_SERVICE,
    ENTITY_TYPE: this.SYSTEM_MODULE.ORDER_SERVICE
  };

  readonly DOCUMENT_MANAGER_SETTINGS = {
    DELETE_DOCUMENT_ACTION: this.deleteDocument.bind(this),
    UPLOAD_DOCUMENT_ACTION: this.upload.bind(this),
    DOWNLOAD_DOCUMENT_ACTION: this.download.bind(this)
  };

  readonly sideSections = {
    none: null,
    notes: 'notes',
    history: 'history',
    filter: 'filter'
  };

  messages: IMessagesResourceService;
  messagesOrder: IMessagesResourceService;
  readonly CONTRACT_MESSAGES_MODULE: string = "contract";
  readonly ORDER_MESSAGES_MODULE: string = "svc-order";
  isGridSettingsDisabled = true;
  public columnSorting: any[];
  public orderHeaderColumnsSorting: any[];


  sortingBuilder;

  constructor(public tabService: TabService,
    public route: ActivatedRoute,
    public router: Router,
    public contractService: ContractService,
    public svcOrderService: SvcOrderService,
    public settingsService: UserSettingsService,
    public vendorService: VendorService,
    public dialogService: DialogService,
    public contractFilterService: ContractFilterService,
    public svcOrderHeaderFilterService: SvcOrderHeaderFilterService,
    public alertService: AlertService,
    public configService: ConfigService,
    public contractShellVendorGridService: ContractShellVendorGridService,
    public orderServiceHeaderGridService: SvcOrderHeaderGridService,
    public loaderService: LoaderService,
    public sortingService: SortingService) {
    super(new PageContext({
      name: 'app.contract.contract-vendor',
      settings: settingsService
    }));

    this.messages = ResourcesService.messages(this.CONTRACT_MESSAGES_MODULE);
    this.messagesOrder = ResourcesService.messages(this.ORDER_MESSAGES_MODULE);

    this.sortingBuilder = this.sortingService.builder();

    this.configService.get()
      .subscribe((config) => {
        this.contractTerminationDays = config && config.contractTerminationDays;
      })
  }

  ngOnInit() {
    this.tabGroup = this.tabService.create();

    this.tabGroup.addTab({ key: 1, title: 'Summary' });
    this.tabGroup.addTab({ key: 2, title: 'Contracts' });
    this.tabGroup.addTab({ key: 3, title: 'Service Orders' });

    this.tabGroup.activate(1);
    this.columns = this.contractShellVendorGridService.columns();
    this.orderHeaderColumns = this.orderServiceHeaderGridService.columns();

    this.tabGroup.onActivate.subscribe((tab) => {


      if (this.tabGroup.isActive(2) && this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME)) {
        this.panelSide.open(this.SECTIONS.FILTER_SECTION_NAME);
        this.tabChanged = !this.tabChanged
      }
      else {
        this.panelSide.close();
      }

    });

    this.route.params.pipe(
      takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        const contractVendorId = params['id'];
        this.contractVendorId = Number(contractVendorId);

        this.DOCUMENT_INFO.SUBFOLDER = `${contractVendorId}/so_`;


        this.vendorService.findById(contractVendorId)
          .subscribe(
          (vendor) => {
            this.contractVendorName = vendor.name;

            // this.initContractsColumns()
            this.contractShellVendorGridService.loadSettings()
              .subscribe((settings) => {
                this.initContractsColumns(settings);
              });

            this.orderServiceHeaderGridService.loadSettings()
              .subscribe((settings) => {
                this.initOrderServicesColumns(settings);
              });
            // this.initOrderServicesColumns()
            this.contractService.findOneContractByVendorId(this.contractVendorId)
              .subscribe(res => {
                this.data = res.items[0]
              });

            // this.loadContractsByVendor(contractVendorId, this.nonFilterQuery, true);
            // this.loadContractsByVendor(contractVendorId, this.nonFilterQuery);
            // this.loadOrderHeadersByVendor(contractVendorId, this.orderQuery);
          },
          (error) => {
            this.alertService.error('', this.messages.get('VENDOR_GET_ERROR'));
          }
          );

      });
  }

  ngAfterViewInit() {
    this.sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME)
    ], this.panelSide));

    this.sider.onChange((section: SiderSection) => {
      this.saveSettings({ siderSection: section.name });
    });

    if (this.settings && this.settings['siderSection']) {
      this.sider.setActiveSection(this.sider.findSection(this.settings['siderSection']));
    }

    if (this.panelSide) {
      this.panelSide.disableClose = true;
    }
    this.contractShellVendorGridService.create(this.dataGrid.instance, {});
    this.orderServiceHeaderGridService.create(this.orderHeaderDataGrid.instance, {});
    this.selectFirstRow();
    super.ngAfterViewInit();

  }
  gridSettings() {
    if (this.tabGroup.isActive(2)) {
      // Contracts
      this.contractsGridSettings();
    } else if (this.tabGroup.isActive(3)) {
      // Service Orders
      this.serviceOrdersGridSettings()
    }
  }
  contractsGridSettings() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.contractShellVendorGridService,
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initContractsColumns(settings);
        }
      });
  }
  serviceOrdersGridSettings() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.orderServiceHeaderGridService,
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initOrderServicesColumns(settings);
        }
      });
  }

  initOrderServicesColumns(settings) {
    this.orderHeaderColumnsSorting = this.orderServiceHeaderGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
    this.orderHeaderColumns = this.orderServiceHeaderGridService.getColumns(settings && settings.columns ? settings.columns : [])
    if (this.orderHeaderColumnsSorting.length) {
      this.orderQuery.orderBy = this.orderHeaderColumnsSorting;
    }

    if (this.orderHeaderDataGrid && this.orderHeaderDataGrid.instance) {
      this.orderHeaderDataGrid.instance.option('columns', this.columns);
    }
    this.loadOrderHeadersByVendor(this.contractVendorId, this.orderQuery);
    // this.loadOrderHeadersByVendor(contractVendorId, this.orderQuery);

  }

  initContractsColumns(settings) {
    this.columnSorting = this.contractShellVendorGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
    this.columns = this.contractShellVendorGridService.getColumns(settings && settings.columns ? settings.columns : [])
    if (this.columnSorting.length) {
      this.query.orderBy = this.columnSorting;
    }

    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.option('columns', this.columns);
    }
    // this.loadContractsByVendor(this.contractVendorId, this.query);
    this.loadContractsByVendor(this.contractVendorId, this.nonFilterQuery, true);
    this.loadContractsByVendor(this.contractVendorId, this.nonFilterQuery);
  }

  onTabChange(index) {

    if (this.sider.isActive(this.SECTIONS.FILTER_SECTION_NAME)) {
      this.toggleFilter()
    }

    this.tabChanged = !this.tabChanged;

    let tab = this.tabGroup.tabs[index];
    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }
    if(index === 1){
      this.loadContractsByVendor(this.contractVendorId, this.nonFilterQuery);
    }
    if (
      this.tabGroup.isActive(2) ||
      this.tabGroup.isActive(3)
    ) {
      this.isGridSettingsDisabled = false;
    } else {
      this.isGridSettingsDisabled = true;
    }

    if(index === 2){
      this.clearOrderFilter(this.orderQuery);
    }

  }

  loadContractsByVendor(vendorId: number, query: ContractQuery, totalContracts?) {

    if(totalContracts){
      this.totalSchedulesQuery.limit = 10000;
      this.totalSchedulesQuery['where']['vendor_id'] = vendorId;
    }else {
      totalContracts = false
    }
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);
    query['where']['vendor_id'] = vendorId;

    this.query = query;

    this.contractService.findAll(totalContracts ? this.totalSchedulesQuery : this.query)
      .subscribe(
      (contracts) => {
      if(!totalContracts){
        this.contracts = contracts.items;
        this.query.total = contracts.total;
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      }
        if(totalContracts){
          contracts.items.forEach(item => {
            if(item.active_schedules){
              this.activeSchedule += item.active_schedules
            }
            if(item.total_schedules){
              this.totalSchedule += item.total_schedules
            }
          })
        }
      },
      (error) => {
        this.alertService.error('', this.messages.get('CONTRACT_VENDOR_GET_ERROR'));
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);

      }
      );
  }

  loadOrderHeadersByVendor(vendorId: number, query: Query) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    query['where']['vendor_id'] = vendorId;

    this.orderQuery = query;

    this.svcOrderService.findAllByVendor(this.orderQuery)
      .subscribe(
        (orderHeaders) => {

          this.svcOrderHeaders = orderHeaders.items;
          this.orderQuery.total = orderHeaders.total;
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);


        },
        (error) => {
          this.alertService.error('', this.messages.get('ORDER_VENDOR_GET_ERROR'));
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);

        }
      );
  }

  showDetails() {
    if(this.selection && this.tabGroup.isActive(2)) {
      this.router.navigate(['/contract', this.selection.id, 'show']);
    }
    if(this.selectionOrder && this.tabGroup.isActive(3)) {
      this.router.navigate(['/contract', this.selectionOrder.id, 'order', 'show', 'summary']);
    }
  }

  back() {
    this.router.navigate(['/contract']);
  }

  filterData(query: ContractQuery) {
    this.query = query;

    this.loadContractsByVendor(this.contractVendorId, this.query);

  }

  public clearFilter(query: ContractQuery) {
    this.filterData(query)
  }

  filterOrderData(query: ContractQuery) {
    this.orderQuery = query;

    this.loadOrderHeadersByVendor(this.contractVendorId, this.orderQuery);

  }

  public clearOrderFilter(query: ContractQuery) {
    query.where = {};
    this.filterOrderData(query)
  }

  onSelectionChanged(row) {
    this.selection = row.selectedRowsData[0];
  }

  onSelectionOrderChanged(row) {
    this.selectionOrder = row.selectedRowsData[0];
  }

  newOrderHeader() {
    this.dialogService.open(SvcOrderHeaderManageDialogComponent, {
      svcOrderHeader: new SvcOrderHeader({
        vendor_id: this.contractVendorId,
        disableVendorPicker: true})
    }, {width: '500px'})
      .afterClosed()
      .subscribe(result => {

          this.loadOrderHeadersByVendor(this.contractVendorId, this.orderQuery);
          this.alertService.error('', this.messagesOrder.get('CREATE_SUCCESS'));
      }, (err) => {
        this.alertService.error('', this.messagesOrder.get('CREATE_ERROR'));
      });
  }

  newContract() {
    this.dialogService.open(ContractShellMenageDialogComponent, {
      contract: new Contract({
        vendor_id: this.contractVendorId,
        disableVendorPicker: true})
    }, {width: '500px'})
      .afterClosed()
      .subscribe(result => {
          this.loadContractsByVendor(this.contractVendorId, this.nonFilterQuery);
          this.alertService.error('', this.messages.get('CREATE_SUCCESS'));
      }, (err) => {
        this.alertService.error('', this.messages.get('CREATE_ERROR'));
      });
  }

  editContract(contract) {
    this.dialogService.open(ContractShellMenageDialogComponent,
      {
      contract: new Contract(
        {id: contract.id,
              name: contract.name,
              description: contract.description,
              vendor_id: this.contractVendorId,
              company_name: contract.company_name,
              disableVendorPicker: true})
      },{width: '500px'}
    )
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.loadContractsByVendor(this.contractVendorId, this.nonFilterQuery);
          this.alertService.error('', this.messages.get('UPDATE_SUCCESS'));
        }
      }, (err) => {
        this.alertService.error('', this.messages.get('UPDATE_ERROR'));
      });
  }

  editOrderHeader(svcOrderHeader) {
    this.dialogService.open(SvcOrderHeaderManageDialogComponent,
      {
        svcOrderHeader: new SvcOrderHeader({
            id: svcOrderHeader.id,
            name: svcOrderHeader.name,
            description: svcOrderHeader.description,
            vendor_id: this.contractVendorId,
            vendor_so_number: svcOrderHeader.vendor_so_number,
            internal_so_number: svcOrderHeader.internal_so_number,
            so_date: svcOrderHeader.so_date,
            disableVendorPicker: true
        })
      }, {width: '500px'}
    )
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.loadOrderHeadersByVendor(this.contractVendorId, this.orderQuery);
          this.alertService.error('', this.messagesOrder.get('UPDATE_SUCCESS'));
        }
      }, (err) => {
        this.alertService.error('', this.messagesOrder.get('UPDATE_ERROR'));
      });
  }

  upload() {
    this.docManager.addEntityDocument();
  }

  download() {
    this.docManager.downloadDocument(this.selection, this.DOCUMENT_INFO.FOLDER);
  }

  deleteDocument() {
    this.docManager.deleteEntityDocument(this.selection);
  }

  ngOnDestroy() {
    if (this.sider.getActiveSection().name === this.sideSections.filter) {
      this.toggleFilter();
    }
    this.destroy$.next(true);
    this.destroy$.complete();
    this.tabGroup.onActivate.unsubscribe();
  }

  refreshOrders() {
    this.loadOrderHeadersByVendor(this.contractVendorId, this.orderQuery)
  }

  refreshContracts() {
    this.loadContractsByVendor(this.contractVendorId, this.query)
  }

  onCellClickOrder(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.sortingBuilder.apply(event, this.orderQuery);
      this.loadOrderHeadersByVendor(this.contractVendorId, this.orderQuery);
    }
  }

  onCellClickContract(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.sortingBuilder.apply(event, this.query);
      this.loadContractsByVendor(this.contractVendorId, this.query);
    }
  }

  toggleFilter() {
    this.sider.toggle(this.SECTIONS.FILTER_SECTION_NAME);
    this.tabGroup.forceRerender();
  }

  contractCsv() {
    const { contractShellVendorGridService, contractService, query } = this;
    contractShellVendorGridService.csvMap().subscribe(fields => {
      contractService.exportToCSV(
        'contracts',
        {
          fields,
          query
        })
    });
  }

  serviceOrderCsv() {
    const { orderServiceHeaderGridService, svcOrderService, orderQuery: query } = this;
    orderServiceHeaderGridService.csvMap().subscribe(fields => {
      svcOrderService.exportToCSV(
        'svc-order',
        {
          fields,
          query,
          fetchDataHandler: (query) => { return svcOrderService.findAllByVendor(query) }
        })
    });
  }
}
