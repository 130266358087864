import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ContractTermsConditions} from "../../core/contract-terms-conditions";
import {ContractSection} from "../../core/contract-section";
import {SortingBuilder, SortingService} from "../../../shared/sorting/sorting.service";
import Query from "../../../core/query/query";
import {ContractService} from "../../core/contract.service";
import {SvcOrderService} from "../../core/svc-order.service";
import {of as observableOf, Subject} from "rxjs";
import {
  SvcOrderContractTermAndConditionGridService
} from "../../core/svc-order-contract-term-and-condition-grid.service";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {GridSettingsComponent} from "../../../core/grid/grid-settings.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {
  SvcOrderContractTermAndConditionFilterService
} from "../../core/svc-order-contract-term-and-condition-filter.service";
import {
  SvcOrderContractScheduleTermConditionManageDialogComponent
} from "../../shared/svc-order-term-condition-manage-dialog/svc-order-term-condition-manage-dialog.component";
import {mergeMap} from "rxjs";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";

@Component({
  selector: 'app-svc-order-details-terms-conditions',
  templateUrl: './svc-order-details-terms-conditions.component.html',
  styleUrls: ['./svc-order-details-terms-conditions.component.css']
})
export class SvcOrderDetailsTermsConditionsComponent extends PageDetailsComponent implements OnInit {

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "svc-order";
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  public svcOrderTermConditionColumns: Array<any>;
  public svcOrderTermsConditions: Array<ContractTermsConditions>
  selection: any;
  public svcOrderTermsConditionSorting: SortingBuilder;
  public svcOrderTermsConditionQuery: Query =new Query({orderBy: [['id', 'ASC']]});
  svcOrderHeader: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public sveOrderTermsAndConditionsColumnsSorting: any[];
  public isEditTermsDisabled: boolean = true
  public isDeleteTermsDisabled: boolean = true

  constructor(public route: ActivatedRoute,
              public router: Router,
              public contractService: ContractService,
              public svcOrderService: SvcOrderService,
              public sortingService: SortingService,
              public svcOrderContractTermConditionGridService: SvcOrderContractTermAndConditionGridService,
              public settingsService: UserSettingsService,
              public dialog: DialogService,
              public svcOrderContractTermAndConditionFilterService: SvcOrderContractTermAndConditionFilterService,
              public alert: AlertService,

  ) {
    super(new PageContext({
      name: 'app.svc-oder.svc-order-details-terms-conditions',
      settings: settingsService
    }))
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.svcOrderTermsConditionSorting = this.sortingService.builder();

  }

  ngOnInit(): void {
    this.svcOrderHeader = this.getSvcOrder()
    this.svcOrderContractTermConditionGridService.loadSettings()
      .subscribe((settings) => {
        this.initTermsAndConditionsColumns(settings);
      });
  }
  private getSvcOrder() {
    return this.route.parent.snapshot.data.svc_order;
  }

  public async loadData(id: number) {
    this.svcOrderHeader = await this.loadSvcOrder(id).toPromise();
  }

  loadSvcOrder(svcId) {
    return this.svcOrderService.findById(svcId)
  }


  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/contract']);
    }
  }

  onSelectionChanged(row) {
    this.selection = <ContractSection>row.selectedRowsData[0];
    if(this.selection){
      this.isEditTermsDisabled = false
      this.isDeleteTermsDisabled = false
    } else {
      this.isEditTermsDisabled = true
      this.isDeleteTermsDisabled = true
    }
  }

  public onSvcOrderTermsAndConditionCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.svcOrderTermsConditionSorting.apply(event, this.svcOrderTermsConditionQuery);
      this.loadSvcOrderTermsAndConditions(this.svcOrderTermsConditionQuery);
    }
  }

  loadSvcOrderTermsAndConditions(query?){
    query.where.svc_order_header_id = this.svcOrderHeader.id;
    if(query){
      this.svcOrderTermsConditionQuery= query
    }
    this.contractService.findAllSvcOrderTermsAndConditions(this.svcOrderTermsConditionQuery)
      .subscribe((result) =>{
        this.svcOrderTermsConditions = result.items;
        this.svcOrderTermsConditionQuery.total = result.total
      })
  }

  initTermsAndConditionsColumns(settings) {
    this.sveOrderTermsAndConditionsColumnsSorting = this.svcOrderContractTermConditionGridService.getOrderBy(settings && settings.sorting ? settings.sorting : []);
    this.svcOrderTermConditionColumns = this.svcOrderContractTermConditionGridService.getColumns(settings && settings.columns ? settings.columns : [])

    if (this.sveOrderTermsAndConditionsColumnsSorting.length) {
      this.svcOrderTermsConditionQuery.orderBy = this.sveOrderTermsAndConditionsColumnsSorting;
    }

    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.option('columns', this.svcOrderTermConditionColumns);
    }
    this.defaultGridPager = settings ? settings.gridPager : 20;;
    this.svcOrderTermsConditionQuery.limit = this.defaultGridPager;

    this.loadSvcOrderTermsAndConditions(this.svcOrderTermsConditionQuery);
  }

  gridSettings() {
      this.termsAndConditionsGridSettings()
  }

  termsAndConditionsGridSettings() {
    this.dialog
      .open(GridSettingsComponent, {
        service: this.svcOrderContractTermConditionGridService,
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initTermsAndConditionsColumns(settings);
        }
      });
  }

  csv() {
    const { svcOrderContractTermConditionGridService, contractService, svcOrderTermsConditionQuery: query } = this;
    svcOrderContractTermConditionGridService.csvMap().subscribe(fields => {
      contractService.exportToCSV(
        'svc_order_t&c',
        {
          fields,
          query,
          fetchDataHandler: (query) => { return contractService.findAllSvcOrderTermsAndConditions(query) }
        })
    });
  }

  toggleFilter() {
    this.toggleSider(this.SECTIONS.FILTER_SECTION_NAME)
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
  }

  clearSvcOrderTermsAndConditionFilter(query: any) {
    this.svcOrderTermsConditionQuery = query;
    this.loadSvcOrderTermsAndConditions(this.svcOrderTermsConditionQuery);
  }

  addNewSvcOrderTC(){
    this.dialog.open(SvcOrderContractScheduleTermConditionManageDialogComponent, {
      svcOrderHeader: this.svcOrderHeader
    }, {width: '600px'})
      .afterClosed()
      .subscribe(result => {
          if (result) {
            this.loadSvcOrderTermsAndConditions(this.svcOrderTermsConditionQuery);
            this.alert.success('', this.messages.get('TERMS_CONDITION_SVC_ORDER_SUCCESS'));
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('TERMS_CONDITION_SVC_ORDER_ERROR'));
        });
  }

  editSvcOrderTC(){
    this.dialog.edit(
      SvcOrderContractScheduleTermConditionManageDialogComponent,
      {
        svcOrderTermsAndCondition: this.contractService.findSvcOrderTCForEdit(this.selection.id),
        isUpdate: true,
        svcOrderHeader: this.svcOrderHeader
      }, {width: '600px'})
      .subscribe((items: any) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(mergeMap(x => x.afterClosed()))
            .subscribe((result: any) => {
              if (result) {
                this.alert.error('', this.messages.get('TERMS_CONDITION_SVC_ORDER_UPDATE_SUCCESS'));

                this.loadSvcOrderTermsAndConditions(this.svcOrderTermsConditionQuery);
                if (result.cancelByTimer || result.status == 2) {
                  return this.alert.success('', this.messages.get('TIMER_EXPIRED'));
                }
              }
            })
        }
      })
  }

  deleteSvcOrderTC(){
    let svcOrderTermsConditionId = this.selection.id

    this.dialog.confirm({
      bodyText: `Are you sure sure you want to delete Terms And Conditions?`
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if(result){
            this.alert.error('', this.messages.get('TERMS_CONDITION_SVC_ORDER_DELETE_SUCCESS'));

            this.contractService.deleteSvcOrderTC(svcOrderTermsConditionId)
              .subscribe(() => {
                this.loadSvcOrderTermsAndConditions(this.svcOrderTermsConditionQuery);
              })
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('TERMS_CONDITION_DELETE_ERROR'));
        }
      );
  }


}
