
import {debounceTime, takeUntil} from 'rxjs';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { ValidationService } from "../validation/validation.service";

@Component({
  selector: 'ca-picker',
  templateUrl: './ca-picker.component.html',
  styleUrls: ['./ca-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaPickerComponent),
      multi: true
    }
  ]
})
export class CaPickerComponent implements OnInit, ControlValueAccessor, OnDestroy {
  SECOND_LINE_TEXTS = {
    NETWORK_HUB_CAPACITY: 'hub_capacity_value',
    SITE_ADDRESS: 'search_address',
    BUILDING_ADDRESS:'building_address'
  };

  _value: any = '';
  _entries: Array<any> = [];

  selectFormControl = new FormControl(null);
  readonly VALIDATION = ValidationService.MESSAGES;

  @Input() name = '';
  @Input() pickerIcon = false;
  @Input() keyField = 'id';
  @Input() textField = 'name';
  @Input() customFormating: Array<any> = [];
  @Input() customFormatingDate: Array<any> = [];
  @Input() pickerType = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() multiple = false;
  @Input() searchEnabled = true;
  @Input() clearEnabled = false;
  @Input() required = false;
  @Input() loading = true;
  @Input() initialItem;
  @Input() control: FormControl = new FormControl();
  @Input() returnWholeEntry = false;
  @Output() onScrollEnd: EventEmitter<any> = new EventEmitter();
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter();
  @Output('onSelectionChange') onSelectionChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() onClear: EventEmitter<any> = new EventEmitter();
  searchFormControl = new FormControl();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('picker') picker;
  @Input() secondLineTextField: string;
  @Input() secondTextField: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  private selection: any;
  public showAll = false;
  @Input() italicStyleOptionPredicate = (option) => (false);

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.select(this._value);
    this.onChange(val);
  }

  get entries() {
    return this._entries;
  }

  @Input('entries')
  set entries(value) {
    let item = this.initialItem;
    const key = this.keyField;
    value = value || [];

    if (value.length && item) {
      this.ensureItemInEntries(value, item);
    }

    this.ensureNoDuplicates(value);
    this._entries = value;
    if (item) {
      this.select(item[key]);
      this.value = item[key];
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSearchInputEnter($event) {
    this.picker.close();
  }

  openedChange(isOpened) {
    if (isOpened){
      this.onSearchChange.emit({
        value: ''
      });

      if (this.searchInput && this.searchInput.nativeElement) {
        this.searchInput.nativeElement.focus();
      }

      this.onTouched();

      this.selectFormControl.markAsTouched();
    } else {
      this.searchFormControl.setValue(null, { emitEvent: false });
    }

  }

  ngOnInit(): void {
    if (this.control) {
      this.selectFormControl = this.control;
    }

    if (this.disabled) {
      this.selectFormControl.disable();
    } else {
      this.searchFormControl.enable();
    }

    this.selectFormControl.valueChanges.pipe(
      takeUntil(this.destroy$))
      .subscribe(
        valueChange => {
          this.writeValue(valueChange);
        }
      );

    this.searchFormControl.valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.destroy$),)
      .subscribe(
        value => {
          this.onSearchChange.emit({
            value: value
          });


          setTimeout(() => {
            /*fixes lost focus on search input*/
            if (this.searchInput && this.searchInput.nativeElement) {
              this.searchInput.nativeElement.focus();
            }
          }, 150);
        }
      );
  }

  clearHandler = () => {
    this.value = null;
    this.selectFormControl.reset();
    this.picker.close();
    this.onClear.emit();
  };

  onSelectionChange($event) {
    this.onChange($event.value);
    if (!this.disabled) {
      this.writeValue($event.value);
      this.onSelectionChangeEmitter.emit($event);
    }
  }

  scrollHandler($event) {
    let element = $event.srcElement;
    if (Math.ceil(element.scrollTop) >= (element.scrollHeight - element.clientHeight)) {
      this.onScrollEnd.emit({
        element
      });
    }
  }

  private ensureItemInEntries(entries, item) {
    let key = this.keyField;
    let existing = entries.filter((entry) => {
      return item[key] === entry[key];
    });

    if (!existing.length && item) {
      entries.push(item);
    }
  }

  private ensureNoDuplicates(entries) {
    let key = this.keyField;

    let output = [];
    for (let i = 0; i < entries.length; i++) {
      if (output.indexOf(entries[i][key]) === -1) {
        output.push(entries[i][key]);
      }
    }
  }

  private select(value) {
    if (!value) {
      return;
    }

    let key = this.keyField;

    this.selection = this.entries.filter((entry) => {
      return value === entry[key];
    })[0];
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  toggleShowALl(showAll: any) {
    this.showAll = !showAll;
  }
}
