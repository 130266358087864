import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxsModule } from '@ngxs/store';
import { GlBatchCreateInvoiceGridService } from 'app/gl-batch/core/gl-batch-create-invoice-grid.service';
import { GlRuleExecutionChargesFlatGridService } from 'app/gl-rule-execution/core/gl-rule-execution-charges-flat-grid.service';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { DxTemplateModule } from 'devextreme-angular';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { AuditConfigService } from '../audit/core/audit-config.service';
import { AuditGridService } from '../audit/core/audit-grid.service';
import { AuditsService } from '../audit/core/audits.service';
import { ChargeModule } from '../charge/charge.module';
import { CommonAlertModule } from '../common-alert/common-alert.module';
import { FilterFeatureModule } from '../common/filter/filter-feature.module';
import { CoreModule } from '../core/core.module';
import { DictionaryService } from '../dictionary/core/dictionary.service';
import { DictionaryModule } from '../dictionary/dictionary.module';
import { DisputeModule } from '../dispute/dispute.module';
import { GlBatchInvoiceGridService } from '../gl-batch/core/gl-batch-invoice-grid.service';
import { GLBatchService } from '../gl-batch/core/gl-batch.service';
import { GLFiltersModule } from '../gl-filters/gl-filters.module';
import { ChargeStringsGridService } from '../gl-rule-execution/core/charge-strings-grid.service';
import { GLChargeDetailViewService } from '../gl-rule-execution/core/gl-charge-detail-view.service';
import { GlRuleExecutionChargesGridService } from '../gl-rule-execution/core/gl-rule-execution-charges-grid.service';
import { GLRuleExecutionFilterService } from '../gl-rule-execution/core/gl-rule-execution-filter.service';
import { GLRuleExecutionFlatFilterService } from '../gl-rule-execution/core/gl-rule-execution-flat-filter.service';
import { GlRuleExecutionGLStringsGridService } from '../gl-rule-execution/core/gl-rule-execution-gl-strings-grid.service';
import { GLStringFilterService } from '../gl-rule-execution/core/gl-string-filter.service';
import { GLService } from '../gl-rule-execution/core/gl.service';
import { IntuitService } from '../intuit/core/intuit.service';
import { SharedModule } from '../shared/shared.module';
import { ChangeAdjustmentManageDialogComponent } from './change-adjustment/change-adjustment-manage-dialog.component';
import { AuditRunGridService } from './core/audit-run-grid.service';
import { BillService } from './core/bill.service';
import { DisputeGridService } from './core/dispute-grid.service';
import { InvoiceAccountGridService } from './core/invoice-account-grid.service';
import { InvoiceChargeFilterService } from './core/invoice-charge-filter.service';
import { InvoiceChargeProfileBrowserFilterService } from './core/invoice-charge-profile-browser-filter.service';
import { InvoiceChargeProfileBrowserGridService } from './core/invoice-charge-profile-browser-grid.service';
import { InvoiceChargeProfileFilterService } from './core/invoice-charge-profile-filter.service';
import { InvoiceChargeProfileGridService } from './core/invoice-charge-profile-grid.service';
import { InvoiceChargeGridService } from './core/invoice-charges-grid.service';
import { InvoiceContactGridService } from './core/invoice-contact-grid.service';
import { InvoiceDocumentService } from './core/invoice-document.service';
import { InvoiceDocumentGridService } from './core/invoice-documents-grid.service';
import { InvoiceFacepageService } from './core/invoice-facepage.service';
import { InvoiceFilterService } from './core/invoice-filter.service';
import { InvoiceFlowHandleService } from './core/invoice-flow-handle.service';
import { InvoiceFlowSteps } from './core/invoice-flow-steps.context';
import { InvoiceFlowService } from './core/invoice-flow.service';
import { InvoiceGlRuleExecutionFilterService } from './core/invoice-gl-rule-execution-filter.service';
import { InvoiceGlRuleExecutionFlatFilterService } from './core/invoice-gl-rule-execution-flat-filter.service';
import { InvoiceGlStringFilterService } from './core/invoice-gl-string-filter.service';
import { InvoiceGridService } from './core/invoice-grid.service';
import { InvoiceNoteGridService } from './core/invoice-note-grid.service';
import { InvoiceSubaccountFilterService } from './core/invoice-subaccount-filter.service';
import { SubAccountGridService } from './core/invoice-subaccount-grid.service';
import { PaymentService } from './core/payment.service';
import { ShortInvoiceChargeGridService } from './core/short-invoice-charges-grid.service';
import { ShortInvoiceChargeProfileGridService } from './core/short-invoice-charges-profile-grid.service';
import { ShortInvoiceChargeProfileOnInventoryGridService } from './core/short-invoice-charges-profile-on-inventory-grid.service';
import { ShortInvoiceGridService } from './core/short-invoice-grid.service';
import { GLBatchCreateDialogComponent } from './gl-batch-create-dialog/gl-batch-create-dialog.component';
import { InvoiceChargeProfileBrowserComponent } from './invoice-charge-profile-browser/invoice-charge-profile-browser.component';
import { InvoiceAlertCsvService } from './invoice-details/alerts/core/invoice-alert-grid-csv.service';
import { InvoiceAlertGridService } from './invoice-details/alerts/core/invoice-alert-grid.service';
import { InvoiceAlertsComponent } from './invoice-details/alerts/invoice-alerts.component';
import { InvoiceAlertState } from './invoice-details/alerts/state/invoice-alert.state';
import { InvoiceChargesComponent } from './invoice-details/charges/invoice-charges.component';
import { InvoiceChargesState } from './invoice-details/charges/state/invoice-charges.state';
import { InvoiceDisputesComponent } from './invoice-details/disputes/invoice-disputes.component';
import { InvoiceDisputeState } from './invoice-details/disputes/state/invoice-disputes.state';
import { DocumentsComponent } from './invoice-details/documents/documents.component';
import { InvoiceGlChargeDetailsComponent } from './invoice-details/gl-charge-details/invoice-gl-charge-details.component';
import { InvoiceGlChargeState } from './invoice-details/gl-charge-details/state/invoice-gl-charge.state';
import { InvoiceGlStringsComponent } from './invoice-details/gl-strings/invoice-gl-strings.component';
import { InvoiceGlStringState } from './invoice-details/gl-strings/state/invoice-gl-string.state';
import { InvoiceDetailsTabsComponent } from './invoice-details/invoice-details-tabs/invoice-details-tabs.component';
import { InvoiceDetailsToolbarComponent } from './invoice-details/invoice-details-toolbar/invoice-details-toolbar.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { InvoiceNotesAndContactsComponent } from './invoice-details/notes-and-contacts/invoice-notes-and-contacts.component';
import { InvoiceNoteContactState } from './invoice-details/notes-and-contacts/state/invoice-note-contact.state';
import { InvoiceOverviewComponent } from './invoice-details/overview/invoice-overview.component';
import { InvoiceOverviewState } from './invoice-details/overview/state/invoice-overview.state';
import { InvoiceDetailsState } from './invoice-details/state/invoice-details.state';
import { InvoiceSubAccountsComponent } from './invoice-details/sub-accounts/invoice-sub-accounts.component';
import { InvoiceSubAccountsState } from './invoice-details/sub-accounts/state/invoice-sub-accounts.state';
import { InvoiceFlowComponent } from './invoice-flow/invoice-flow.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceRoutingModule } from './invoice-routing.module';
import { AuditRunComponent } from './shared/audit-run/audit-run.component';
import { CreateInvoiceChargeProfileDialogComponent } from './shared/create-invoice-charge-profile-dialog/create-invoice-charge-profile-dialog.component';
import { CreateInvoiceChargeProfileDuplicateWarningDialogComponent } from './shared/create-invoice-charge-profile-duplicate-warning-dialog/create-invoice-charge-profile-duplicate-warning-dialog.component';
import { CreateInvoiceChargeProfileWarningDialogComponent } from './shared/create-invoice-charge-profile-warning-dialog/create-invoice-charge-profile-warning-dialog.component';
import { InvoiceApprovalManageComponent } from './shared/invoice-approval-manage/invoice-approval-manage.component';
import { InvoiceApproveDialogComponent } from './shared/invoice-approve-dialog/invoice-approve-dialog.component';
import { InvoiceBalanceErrorDialogComponent } from './shared/invoice-balance-error-dialog/invoice-balance-error-dialog.component';
import { InvoiceConfirmDialog } from './shared/invoice-confirm-dialog/invoice-confirm-dialog.component';
import { InvoiceCreateChargeForAdjustmentDialogComponent } from './shared/invoice-create-charge-for-ajustment-dialog/invoice-create-charge-for-adjustment-dialog.component';
import { InvoiceDeleteChargeDialogComponent } from './shared/invoice-delete-charge-dialog/invoice-delete-charge-dialog.component';
import { InvoiceDocumentDialogComponent } from './shared/invoice-document-dialog/invoice-document-dialog.component';
import { ManageInvoiceDialogComponent } from './shared/invoice-manage-dialog/invoice-manage-dialog.component';
import { InvoiceResetToNewManageComponent } from './shared/invoice-reset-to-new-manage/invoice-reset-to-new-manage.component';
import { InvoiceRevertToApprovedManageComponent } from './shared/invoice-revert-to-approved-manage/invoice-revert-to-approved-manage.component';
import { InvoiceSneakPeekComponent } from './shared/invoice-sneak-peek/invoice-sneak-peek.component';
import { InvoiceStatusGlComponent } from './shared/invoice-status-gl/invoice-status-gl.component';

@NgModule({
	imports: [
		CommonModule,
		InvoiceRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		FlexLayoutModule,
		DictionaryModule,
		DxDataGridModule,
		DxTemplateModule,
		MatButtonToggleModule,
		SharedModule,
		ChargeModule,
		DisputeModule,
		CommonAlertModule,
		CoreModule,
		GLFiltersModule,
		NgxsModule.forFeature([
			InvoiceDetailsState,
			InvoiceOverviewState,
			InvoiceChargesState,
			InvoiceSubAccountsState,
			InvoiceDisputeState,
			InvoiceAlertState,
			InvoiceNoteContactState,
			InvoiceGlChargeState,
			InvoiceGlStringState
		]),
		FilterFeatureModule
	],
	exports: [],
	declarations: [
		InvoiceListComponent,
		InvoiceSneakPeekComponent,
		InvoiceApproveDialogComponent,
		InvoiceBalanceErrorDialogComponent,
		InvoiceDetailsComponent,
		InvoiceFlowComponent,
		InvoiceStatusGlComponent,
		InvoiceApprovalManageComponent,
		InvoiceConfirmDialog,
		GLBatchCreateDialogComponent,
		InvoiceRevertToApprovedManageComponent,
		InvoiceResetToNewManageComponent,
		ChangeAdjustmentManageDialogComponent,
		ManageInvoiceDialogComponent,
		AuditRunComponent,
		InvoiceChargesComponent,
		InvoiceOverviewComponent,
		InvoiceDisputesComponent,
		InvoiceSubAccountsComponent,
		InvoiceNotesAndContactsComponent,
		InvoiceGlStringsComponent,
		InvoiceGlChargeDetailsComponent,
		InvoiceAlertsComponent,
		InvoiceDetailsToolbarComponent,
		InvoiceDetailsTabsComponent,
		DocumentsComponent,
		InvoiceDocumentDialogComponent,
		InvoiceDeleteChargeDialogComponent,
		InvoiceCreateChargeForAdjustmentDialogComponent,
		CreateInvoiceChargeProfileDialogComponent,
		CreateInvoiceChargeProfileWarningDialogComponent,
		InvoiceChargeProfileBrowserComponent,
		CreateInvoiceChargeProfileDuplicateWarningDialogComponent
	],
	providers: [
		InvoiceFacepageService,
		InvoiceDocumentService,
		InvoiceGridService,
		InvoiceAlertGridService,
		InvoiceAlertCsvService,
		InvoiceAccountGridService,
		InvoiceContactGridService,
		InvoiceNoteGridService,
		InvoiceFilterService,
		GLBatchService,
		InvoiceSubaccountFilterService,
		InvoiceChargeFilterService,
		DictionaryService,
		InvoiceChargeGridService,
		InvoiceDocumentGridService,
		GlBatchInvoiceGridService,
		SubAccountGridService,
		ShortInvoiceGridService,
		GlRuleExecutionChargesGridService,
		ChargeStringsGridService,
		GLChargeDetailViewService,
		GLRuleExecutionFlatFilterService,
		GLRuleExecutionFilterService,
		GLStringFilterService,
		GlRuleExecutionGLStringsGridService,
		GlRuleExecutionChargesFlatGridService,
		GLService,
		AuditsService,
		AuditGridService,
		AuditConfigService,
		AuditRunGridService,
		GlBatchCreateInvoiceGridService,
		DisputeGridService,
		InvoiceGlStringFilterService,
		InvoiceGlRuleExecutionFilterService,
		InvoiceGlRuleExecutionFlatFilterService,
		InvoiceFlowSteps,
		InvoiceFlowHandleService,
		InvoiceFlowService,
		PaymentService,
		BillService,
		IntuitService,
		ShortInvoiceChargeGridService,
		ShortInvoiceChargeProfileGridService,
		ShortInvoiceChargeProfileOnInventoryGridService,
		InvoiceChargeProfileGridService,
		InvoiceChargeProfileFilterService,
		InvoiceChargeProfileBrowserFilterService,
		InvoiceChargeProfileBrowserGridService
	]
})
export class InvoiceModule {}
