import { Injectable } from '@angular/core';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';

@Injectable()
export class AlertAccountGridService extends GridService {
  constructor(
    public userSettings: UserSettingsService,
    public formatService: FormatService
  ) {
    super('app.alert.alert-account-grid', userSettings);
  }

  defaultColumns = [
    {
      caption: 'Account',
      dataField: 'account.account_no',
      width: 160
    },
    {
      caption: 'Vendor',
      dataField: 'account.vendor.name',
      width: 160
    },
    {
      dataField: 'account.entity.name',
      caption: 'Entity',
      width: 160
    },
    {
      dataField: 'account.status.value',
      caption: 'Status',
      width: 160
    },
    {
      dataField: 'account.country.alpha_2_code',
      caption: 'Country',
      width: 160
    },
    {
      dataField: 'rule_name',
      caption: 'Rule Name',
      width: 160
    },
  ];

}
