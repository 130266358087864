import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ascend, descend, pipe, prop, sortWith, toLower } from 'ramda';

import { DictionaryService } from '../../core/dictionary.service';
import { LookupModel } from '../../core/lookup.model';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';


const getSorter = (type: 'asc' | 'desc', valueToSortBy: string): any => {
  const propByParam = prop(valueToSortBy);
  const sort = valueToSortBy === 'standardized_value' ? propByParam : pipe(propByParam, toLower);

  return sortWith([
    type === 'asc' ? ascend(sort) : descend(sort)
  ]);
};

@Component({
  selector: 'app-dictionary-reorder',
  templateUrl: './dictionary-reorder.component.html',
  styleUrls: ['./dictionary-reorder.component.css']
})
export class DictionaryReorderComponent implements OnInit {
  formTitle: string;
  lookup: LookupModel;
  lookupItems: Array<LookupModel>;
  itemsSortedInASC: boolean;
  isBandwidthDictionary: boolean;

  constructor(
    public dialogRef: MatDialogRef<DictionaryReorderComponent>,
    public dictionaryService: DictionaryService) {}

  ngOnInit() {
    if (this.lookup) {
      this.formTitle = 'Reorder entries for "' + this.lookup.display_name + '"';
    } else {
      this.close();
    }

    this.isBandwidthDictionary = this.lookup.display_name === LOOKUP_MODELS_ENUM.BANDWIDTH.displayName;
  }

  sortItems(value?: string) {
    !this.itemsSortedInASC ? this.sortAlphabeticallyAsc(value) : this.sortAlphabeticallyDesc(value);
  }

  sortAlphabeticallyAsc(value = 'value') {
    this.itemsSortedInASC = true;
    this.lookupItems = getSorter('asc', value)(this.lookupItems);
  }

  sortAlphabeticallyDesc(value = 'value') {
    this.itemsSortedInASC = false;
    this.lookupItems = getSorter('desc', value)(this.lookupItems);
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  save() {
    let inc = 0;
    this.lookupItems.forEach(elem => {
      elem.order_number = inc++;
      this.dictionaryService.updateLookup(this.lookup.lookup_model, elem.id, elem)
        .subscribe(() => {
          this.close(this.lookupItems);
        });
    });
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.lookupItems, event.previousIndex, event.currentIndex);
  }
}
