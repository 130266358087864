<form
  class="filter-form"
  fxLayout="column"
  style="
    height: 100%;
    overflow-y: hidden;
    padding: 0 6px 5px 6px;
">
  <mat-toolbar fxFlex="50px" color="accent" class="filters-commands"
               cdkDropList
               #trash="cdkDropList"
               (cdkDropListDropped)="onRemove($event)"
  >
    <mat-toolbar-row class="filters-trash hide" [ngClass]="{'dnd-drag-over' : dragging, 'show': dragging}"
    >
    <ca-icon [key]="'DELETE'"></ca-icon>
    </mat-toolbar-row>
      <mat-toolbar-row [ngClass]="{'hide' : dragging}">
    <span class="filters-title">Filters</span>
    <span class="example-fill-remaining-space"></span>

    <new-filter-picker
      [filters]="filterContext?.visibleFilters"
      [filterInput]="filterInput"
      (removeFilterCard)="removeFilterCard($event)"
    ></new-filter-picker>

    <button
      mat-icon-button
      (click)="applyFilter()"
      matTooltip="Apply"
      type="submit"
      [disabled]="disableButtons"
    >
      <ca-icon [key]="'APPLY_FILTER'"></ca-icon>
    </button>
    <button mat-icon-button (click)="clearFilter()" matTooltip="Clear All" [disabled]="disableButtons">
      <ca-icon [key]="'CLEAR_FILTER'"></ca-icon>
    </button>
    <button mat-icon-button (click)="settings()" matTooltip="Settings">
      <ca-icon [key]="'FILTER_SETTINGS'"></ca-icon>
    </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <new-filter-input
    cdkScrollable
    [context]="filterContext"
    [fetchReconQuery]="fetchReconQuery"
    #filterInput
    [query]="queryCloned"
    [trashRef] = "trash"
    (draggingEmitter)= "draggingListen($event)"
    [initialDateRange]="initialDateRange"
    (numbersDidNotPassValidation)="numberValidation($event)"
    [entryInterceptor]="entryInterceptor"
  >
  </new-filter-input>
</form>
