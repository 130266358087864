
import {of as observableOf, Observable} from 'rxjs';
import {TagAdministrationService} from '../../core/tag-administration.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {Tag} from '../../core/tag';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {PageManageDialogComponent} from '../../../core/page-manage-dialog.component';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {IMessagesResourceService, ResourcesService} from "../../../core/resources/resources.service";
import {EntityEditContext} from "../../../shared/entity-lock/entity-edit-context";
import {flatMap} from "rxjs";

@Component({
  selector: 'app-tag-manage-dialog',
  templateUrl: './tag-manage-dialog.component.html',
  styleUrls: ['./tag-manage-dialog.component.css'],
})
export class TagManageDialogComponent extends PageManageDialogComponent implements OnInit {

  tag: any;
  selectedRows;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "tag";

  public currentSelection: any;

  constructor(public alertService: AlertService,
              public tagService: TagAdministrationService,
              public dialogService: DialogService,
              public dialogRef: MatDialogRef<TagManageDialogComponent>,
              public formBuilder: FormBuilder) {
    super(dialogService, dialogRef);
    this.tag = (this.tag) ? this.tag : new Tag();
    this.selectedRows = (this.selectedRows) ? this.selectedRows : [];

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {

    if (this.tag && this.tag.id) {
      this.isUpdate = true;
    }

    this.form = this.formBuilder.group({
      id: new FormControl(this.tag.id),
      name: new FormControl(this.tag.name, Validators.required),
    });

    this.formTitle = this.isUpdate ? 'Edit Tag' : this.selectedRows.length ? 'Merge Tags' : 'Create Tag';
    this.afterInit();

  }

  init() {
    if (this.tag && this.tag.id) {
      return this.tagService.findByIdForEdit(this.tag.id, new EntityEditContext({
        dialogRef: this.dialogRef
      }))
        .pipe(flatMap((tag) => {
          this.tag = tag;
          return observableOf(tag);
        }))
    }
    return observableOf(this.tag);
  }

  onSubmit({value, valid}: { value: Tag, valid: boolean }) {
    if (valid) {
      if (this.tag.id) {
        this.updateTag(value);
      } else if (this.selectedRows.length > 1) {
        this.mergeTags(value);
      }
      else {
        this.createTag(value);
      }
    } else {
      this.alertService.error('', this.messages.get('FORM_INVALID'));
    }
  }

  createTag(tag: Tag) {
    this.toggleDialogButtons();
    this.tagService.create(tag)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (error) => {
          this.toggleDialogButtons(false);
          this.alertService.error('', this.messages.get('CREATE_ERROR'));
        }
      );
  }

  updateTag(tag: Tag) {
    this.toggleDialogButtons();
    this.tagService.update(this.tag.id, tag)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (error) => {
          this.toggleDialogButtons(false);
          this.alertService.error('', this.messages.get('UPDATE_ERROR'));
        }
      );
  }

  deleteTag(event) {
    if (this.isUpdate && event) {
      this.toggleDialogButtons();
      this.tagService.delete(this.tag.id)
        .subscribe((deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({deleted: true});
          }
        }, (err) => {
          this.toggleDialogButtons(false);
          this.alertService.error('', this.messages.get('DELETE_ERROR'));
        });
    }
  }

  mergeTags(value: Tag) {
    this.toggleDialogButtons();
    this.tagService.merge(this.selectedRows.map(item => {
      return item.id
    }), value.name).subscribe(
      (result) => {
        this.closeDialog(result, true);
      },
      (error) => {
        this.toggleDialogButtons(false);
        this.alertService.error('', this.messages.get('MERGE_ERROR'));
      }
    );
  }

  selectTag(tag: any) {
    this.form.patchValue({name: tag.name});
    if (this.currentSelection) {
      this.currentSelection.isSelected = false;
    }
    tag.isSelected = true;
    this.currentSelection = tag;
  }

  cancel() {
    this.tagService.cancelEdit();
    this.closeDialog();
  }
}
