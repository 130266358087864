import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from 'app/material-barrel/material-barrel.module';

import {CoreModule} from "../core/core.module";

import {UserService} from './core/user.service';
import {UserManageDialogComponent} from './shared/user-manage-dialog/user-manage-dialog.component';
import {UserListComponent} from './user-list/user-list.component';
import {UserComponent} from './user.component';
import {UserRoutingModule} from './user-routing.module';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxDataGridModule} from 'devextreme-angular/ui/data-grid';

import {FlexLayoutModule} from '@angular/flex-layout';

import {UserSneakPeekComponent} from './shared/user-sneak-peek/user-sneak-peek.component';
import {DictionaryModule} from "../dictionary/dictionary.module";
import { CaUserPickerComponent } from './shared/user-picker/ca-user-picker.component';

// REVIEW: not importing SharedModule but specific component?
import {SharedModule} from '../shared/shared.module';
import {UserGridService} from "./core/user-grid.service";
import {UserSettingsService} from "./core/user-settings.service";
import {UserFilterService} from "./core/user-filter.service";
import { RolesService } from '../roles/core/roles.service';
import {UserResolve} from "./core/user.resolve";
import {ProfileManageDialogComponent} from "./shared/profile-manage-dialog/profile-manage-dialog.component";
import {LocationModule} from "../location/location.module";
import { NgxMaskModule, IConfig } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UserRoutingModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        DictionaryModule,
        DxButtonModule,
        DxDataGridModule,
        MaterialModule,
        SharedModule,
        CoreModule,
        LocationModule,
        NgxMaskModule.forRoot()
    ],
    exports: [
        CaUserPickerComponent
    ],
    declarations: [
        UserManageDialogComponent,
        ProfileManageDialogComponent,
        UserListComponent,
        UserComponent,
        UserSneakPeekComponent,
        CaUserPickerComponent
    ],
    providers: [
        UserService,
        UserSettingsService,
        UserGridService,
        UserFilterService,
        RolesService,
        UserResolve
    ]
})
export class UserModule {
}
