import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PageContext} from "../../core/page.context";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {PageDetailsComponent} from "../../core/page-details.component";
@Component({
  selector: 'app-svc-order-details',
  templateUrl: './svc-order-details.component.html',
  styleUrls: ['./svc-order-details.component.scss']
})
export class SvcOrderDetailsComponent extends PageDetailsComponent implements OnInit, AfterViewInit {

  constructor(public settingsService: UserSettingsService
  ) {
    super(new PageContext({
      name: "app.contract.svc-order-details",
      settings: settingsService
    }));
  }


  ngOnInit() {

  }

}
