import { Observable } from "rxjs";
import { VendorService } from "./vendor.service";
import { IFilterLookupProvider } from "../../core/filter/filter-lookup.provider";
import Query from "../../core/query/query";
import { map } from "rxjs";

export class VendorLookupProvider extends IFilterLookupProvider {
  constructor(
    public vendorService: VendorService,
    public allVendors?: boolean,
    public simple?: boolean
  ) {
    super();
  }

  findAll(searchQuery?: Query | string): Observable<any> {
    let query = new Query();
    if (typeof searchQuery === "string") {
      query = new Query({
        where: {
          vendor: {
            $ilike: `%${searchQuery}%`,
          },
        },
      });
    } else if (searchQuery) {
      query = new Query(searchQuery);
    }

    const formatedQuery = this.formatQuery(query);
    formatedQuery.orderBy = [["name", "ASC"]];

    if (!this.allVendors) {
      return this.getWithActiveBans(formatedQuery);
    }
    if (this.simple) {
      return this.getSimpleVendors(formatedQuery);
    }
    return this.getAllVendors(formatedQuery);
  }

  map(items: any) {
    return items.map((item) => ({ key: item.id, value: item.name }));
  }

  private getWithActiveBans(searchQuery): Observable<any> {
    return this.vendorService
      .findVendorsWithBans(searchQuery)
      .pipe(map((result: any) => this.returnVendorResult(result)));
  }

  private getSimpleVendors(searchQuery): Observable<any> {
    return this.vendorService
      .findOnlySimpleVendors(searchQuery)
      .pipe(map((result: any) => this.returnVendorResult(result)));
  }

  private getAllVendors(searchQuery?): Observable<any> {
    return this.vendorService
      .findOnlyVendors(searchQuery)
      .pipe(map((result: any) => this.returnVendorResult(result)));
  }

  private formatQuery(query: Query) {
    if (query && query.where && query.where.vendor) {
      query.where.name = query.where.vendor;
      delete query.where.vendor;
    } else if (query && query.where && query.where.vendor_name) {
      query.where.name = query.where.vendor_name;
      delete query.where.vendor_name;
    }
    return query;
  }

  private returnVendorResult(result: any) {
    return {
      items: result.items.map((entry) => {
        return entry.name;
      }),
      total: result.total,
    };
  }
}
