import { Injectable } from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Project} from "./project";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class ProjectService extends BaseService<Project>{

  constructor(private entityLock: EntityLockService) {
    super('project', entityLock);
  }

}
