import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DisputeQuery } from 'app/dispute/core/dispute.query';
import { DisputeService } from 'app/dispute/core/dispute.service';

@Component({
	selector: 'ca-dispute-dropdown',
	templateUrl: './dispute-dropdown.component.html',
	styleUrls: ['./dispute-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DisputeDropdownComponent),
			multi: true
		}
	]
})
export class DisputeDropdownComponent implements OnInit, ControlValueAccessor {
	@Input('value') _value: number;
	@Output() onPickerChange = new EventEmitter();
	@Input() label: string = 'Dispute';
	@Input() query?: DisputeQuery;

	public disputes = [];

	get value() {
		return this._value;
	}

	set value(val) {
		this._value = val;
		this.propagateChange(val);
		this.onPickerChange.emit(this.value);
	}

	constructor(private disputeService: DisputeService) {}

	ngOnInit(): void {
		this.disputeService
			.findAll(
				new DisputeQuery({
					orderBy: [['created_at', 'DESC']],
					...this.query
				})
			)
			.subscribe((result) => {
				this.disputes = result.items.map((dispute) => {
					return {
						value: dispute.id,
						label: dispute.dispute_id
					};
				});
			});
	}

	writeValue(value: any): void {
		this.value = value;
	}

	propagateChange = (_: any) => {};

	registerOnChange(fn: any): void {
		this.propagateChange = (value) => {
			Promise.resolve(null).then(() => fn(value));
		};
	}

	registerOnTouched(fn: any): void {}
}
