import {Action, State, StateContext, Store} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {UserService} from "../core/user.service";
import {LoadUser, LoadUserSettings, SetUser, SetUserSettings} from "./user.actions";
import {UserSettingsService} from "../core/user-settings.service";

@State<any>({
  name: 'user',
  defaults: {
    user: {},
    settings: {}
  },
})
@Injectable()
export class UserState {

  constructor(
    private store: Store,
    private userService: UserService,
    private userSettingsService: UserSettingsService
  ) {}

  @Action(LoadUser)
  loadUser(ctx: StateContext<any>): any {
    const state = ctx.getState();
    if (Object.keys(state?.user || {}).length)
      return Promise.resolve(state.user)

    return this.userService.me().toPromise()
      // .then((user) => {
      //   ctx.setState({
      //     ...(state || {}),
      //     user,
      //   })
      // });
  }

  @Action(SetUser)
  setUser(ctx: StateContext<any>, action: SetUser): void {
    const { user } = action.payload;
    const state = ctx.getState();

    ctx.setState({
      ...(state || {}),
      user,
    });
  }

  @Action(LoadUserSettings)
  loadUserSettings(ctx: StateContext<any>): any {
    const state = ctx.getState();
    if (Object.keys(state?.settings || {}).length)
      return Promise.resolve(state.settings)

    return this.userSettingsService.me().toPromise()
      // .then((settings) => {
      //   // console.log('settings:', settings)
      //   ctx.setState({
      //     ...state,
      //     settings,
      //   })
      // });
  }

  @Action(SetUserSettings)
  setUserSettings(ctx: StateContext<any>, action: SetUserSettings): void {
    const { settings } = action.payload;
    const state = ctx.getState();

    ctx.setState({
      ...state,
      settings,
    });
  }
}
