<app-invoice-details-toolbar
	[invoice]="invoice"
	(toggleFilterRequested)="onFilterToggle()"
	[isFilterOn]="($activeSiderSection | async) == SECTIONS.FILTER_SECTION_NAME"
	(toggleNotesRequested)="onNoteToggle()"
	[isNotesOn]="($activeSiderSection | async) == SECTIONS.NOTES_SECTION_NAME"
	(toggleHistoryRequested)="onHistoryToggle()"
	[isHistoryOn]="($activeSiderSection | async) == SECTIONS.HISTORY_SECTION_NAME"
	(downloadCSVRequested)="onDownloadCSVRequested()"
	[isDoNotProcessHidden]="true"
	[isDocumentTypeHidden]="true"
	[isAddDisputeHidden]="true"
	[isOnHoldDisabled]="true"
	[isGLChargeViewSwitchHidden]="true"
	[isAddDirectGLHidden]="true"
	[isEditDirectGLHidden]="true"
	[isGridSettingsDisabled]="false"
	(openGridSettingsRequested)="onOpenGridSettingsRequested()"
	[isDropDownFileHidden]="false"
	[isDropDownCsvHidden]="false"
	[isDropDownCsvDisabled]="false"
	[isDropDownUploadDocumentDisabled]="true"
	[isDownloadContainerHidden]="true"
	[isDownloadPDFHidden]="true"
	[isCSVHidden]="true"
	[notesCount]="notesCount"
	[isFilterDisabled]="false"
	(backRequested)="back()"
></app-invoice-details-toolbar>
<mat-sidenav-container class="app-content tabbed-content dx-nodata-absolute">
	<app-invoice-details-tabs
		[activeTabIndex]="3"
		[invoice]="invoice"
	></app-invoice-details-tabs>
	<div
		class="app-content app-content-list tabbed-content tabbed-content-no-scroll-except-first-ch"
		style="top: 0"
	>
		<!-- Tabs Body -->
		<div class="tabs-body tabs-disable-scroll">
			<dx-data-grid
				class="app-grid-with-pager"
				#subAccountSummaryGrid
				[height]="'100%'"
				[columns]="subAccountSummaryColumns"
				(onCellClick)="onSubAccountCellClick($event)"
				[dataSource]="subAccountSummary"
				[showRowLines]="true"
				[showColumnLines]="false"
			>
				<dxo-sorting mode="none"></dxo-sorting>
				<dxo-paging [pageSize]="10" [enabled]="false"></dxo-paging>
				<div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
					<div caGridTooltip class="right">
						{{ d.value | caCurrency: d.data.currency }}
					</div>
				</div>
			</dx-data-grid>
			<ca-pager
				class="app-pager ca-default-border-top"
				[gridPager]="defaultGridPager"
				[pageContext]="subaccountGridService"
				[query]="subAccountQuery"
				(onChange)="
					invoice?.invoice_id
						? onPageSubAccountChange(invoice?.invoice_id)
						: null
				"
			></ca-pager>
		</div>
	</div>
	<mat-sidenav #panelSide mode="side" position="end" opened="false">
		<app-filter-container
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
			[query]="subAccountQuery"
			[filterService]="subaccountFilterService"
			(filterRequested)="filterData($event)"
			(clearFilterRequested)="clearFilter()"
		></app-filter-container>

		<ca-history
			#history
			[entityId]="invoice?.invoice_id"
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }"
			[entityType]="'invoice'"
			[invoice]="invoice"
		></ca-history>

		<ca-notes
			#testNotes
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
			[entityId]="invoice?.invoice_id"
			[entityType]="SYSTEM_MODULE.INVOICE"
			(countChanged)="onNotesCountChanged($event)"
			(notesCountChanged)="getDNPInfo($event)"
		>
			<ca-account-notes
				[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
				[entityId]="invoice?.account?.id"
				[entityType]="SYSTEM_MODULE.ACCOUNT"
				[entityName]="'Account'"
				[enableReply]="false"
			></ca-account-notes>
		</ca-notes>
	</mat-sidenav>
</mat-sidenav-container>
