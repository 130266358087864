import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";

@Injectable()
export class ReportingService extends BaseService<any> {

  readonly reportType: any = {
    TEMPLATE: 'template',
    PRIVATE: 'private',
    PUBLIC: 'public'
  };

  readonly dataTypes: any = {
    string: 'string',
    currency: 'currency',
    numeric: 'numeric',
    date: 'date',
    datetime: 'datetime'
  };

  constructor() {
    super('report');
  }

  findByType(type) {
    return this.httpService().get([this.name, type, 'find']);
  }

  findByReportType(type) {
    return this.httpService().get([this.name, type, 'findByReportType']);
  }

  loadDataView(key) {
    return this.httpService().get([this.name, key, 'dataview']);
  }

  findByKey(key: string) {
    return this.httpService().get([this.name, key, 'report']);
  }

  execute(report) {
    return this.httpService().post([this.name, 'execute'], report)
  }

  clone(report) {
    return this.httpService().post([this.name, 'execute'], report)
  }

  getConfig() {
    return this.httpService().get([this.name, 'config']);
  }

}
