import { PageInfo } from './page-info';
import { Injectable } from '@angular/core';
import { BaseService } from '../../../core/base.service';

@Injectable()
export class PageInfoService extends BaseService<PageInfo> {

  constructor() {
    super('user-report');
  }

  sendReport(data: PageInfo) {
    return this.httpService().post([this.name, 'sendReport'], data);
  }

}
