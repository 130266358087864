export class LoadUser {
  static readonly type = '[USER] Load user';
  constructor() {}
}

export class SetUser {
  static readonly type = '[USER] Set current user';
  constructor(public payload: { user: any }) {}
}

export class LoadUserSettings {
  static readonly type = '[USER_SETTINGS] Load user settings';
  constructor() {}
}

export class SetUserSettings {
  static readonly type = '[USER_SETTINGS] Set current settings';
  constructor(public payload: { settings: any }) {}
}
