import { CoreModule } from '../core/core.module';
import { TagAdministrationFilterService } from './core/tag-administration-filter.service';
import { SharedModule } from '../shared/shared.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { TagAdministrationService } from './core/tag-administration.service';
import { TagAdministrationGridService } from './core/tag-administration-grid.service';
import { TagAdministrationComponent } from './tag-administration.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagAdministrationListComponent } from './tag-administration-list/tag-administration-list.component';
import { TagAdministrationRoutingModule } from './tag-administration-routing.module';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TagManageDialogComponent } from './shared/tag-manage-dialog/tag-manage-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        TagAdministrationRoutingModule,
        MaterialModule,
        DxDataGridModule,
        SharedModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule
    ],
    declarations: [TagAdministrationComponent, TagAdministrationListComponent, TagManageDialogComponent],
    providers: [TagAdministrationService, TagAdministrationGridService, TagAdministrationFilterService]
})
export class TagAdministrationModule { }
