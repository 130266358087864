import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  PICKER_COMPONENT_TEMPLATE,
  PickerComponentBase
} from '../../../shared/picker/ca-picker-base.component';
// import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { VendorService } from '../../core/vendor.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ca-vendor-entity-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VendorEntityPickerComponent),
      multi: true
    }
  ]
})
export class VendorEntityPickerComponent extends PickerComponentBase implements OnInit, OnChanges {

  readonly TEXT_FIELD = 'name';
  @Input('formControlName') formControlName;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('placeholder') placeholder = 'Select ...';
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('vendorId') vendorId: number;

  @Input('initialItem') initialItem;
  @Input('pickerForm') pickerForm;

  control;

  constructor(
    public vendorService: VendorService,
  ) {
    super();
  }

  ngOnInit() { }

  loadEntries(): Observable<any> {
    this.query.limit = 100000
    return this.vendorService.findAllEntitiesByQuery(this.vendorId, this.query);
  }

  search(value: string): Observable<any> {
    this.query.where['name'] = { '$ilike': `${value}%` };
    return this.vendorService.findAllEntitiesByQuery(this.vendorId, this.query);
  }

  ngOnChanges(changes: SimpleChanges) {

    this.control = this.pickerForm.controls[this.formControlName];

    let vendorId = changes['vendorId'];

    if (vendorId && vendorId.currentValue) {

      if (!this.initialItem) {
        this.control.reset();
      }

      this.initialItem = null;

      this.loadData();
    }
  }

}
