import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AlertRateAuditResultGridService extends GridService {
	defaultColumns = [
		{
			caption: '',
			headerCellTemplate: 'selectedChargesHeaderTemplate',
			cellTemplate: 'chargeDisputeNoteTemplate',
			width: 40,
			fixed: true,
			allowSorting: false
		},
		{
			caption: 'Charge ID',
			dataField: 'charge_id',
			width: 140
		},
		{
			caption: 'Begin Date',
			dataField: 'cost_invoice_charge.beg_chg_date',
			dataType: 'date',
			width: 90,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'End Date',
			dataField: 'cost_invoice_charge.end_chg_date',
			dataType: 'date',
			width: 90,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'Rate',
			dataField: 'rate_value',
			dataType: 'number',
			alignment: 'right',
			width: 70,
			format: {
				precision: 2,
				type: 'currency'
			}
		},
		{
			caption: 'Charge Amt',
			dataField: 'cost_invoice_charge.chg_amt',
			alignment: 'right',
			dataType: 'number',
			format: {
				precision: 2,
				type: 'currency'
			}
		},
		{
			caption: 'Calc Charge',
			dataField: 'calc_chg_amt',
			dataType: 'number',
			alignment: 'right',
			format: {
				precision: 2,
				type: 'currency'
			}
		},
		{
			caption: 'SPID',
			dataField: 'cost_invoice_charge.sp_serv_id',
			width: 160
		},
		{
			caption: 'Variance',
			dataField: 'chg_var',
			dataType: 'number',
			alignment: 'right',
			format: {
				precision: 2,
				type: 'currency'
			}
		}
	];

	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.alert.rate-audit-result-grid', userSettings);
	}
}
