import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Tag} from './tag';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class TagAdministrationService extends BaseService<Tag> {

  constructor(private entityLock: EntityLockService) {
    super('tags', entityLock);
  }

  public merge(tag_ids:number [], tag: string): any {
    return this.httpService().post([this.name, 'merge'], { tag_ids: tag_ids, tag: tag })
  }
}
