<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>History Details for {{ getHistoryTitle(historyItem) }}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" style="overflow-y: hidden">
  <div fxLayout="column">
    <div fxLayout>
      <strong fxFlex></strong>

      <div class="ca-form" fxFlex="0 0 80%" fxLayout="row wrap" fxLayout.sm="column" fxLayoutGap="24px">
        <ca-default-picker fxFlex hiDensityInput>
          <mat-form-field>
            <mat-select fxFlex="1 0 calc(50% - 5px)" placeholder="Date" [(ngModel)]="historyOne"
                        (selectionChange)="onDateOneChange($event)">
              <mat-option *ngFor="let history of historyAll"
                          [disabled]="history.id === historyOne || history.id === historyTwo" [value]="history.id">
                {{ history?.created_at | caDateTime }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ca-default-picker>

        <ca-default-picker fxFlex hiDensityInput>
          <mat-form-field>
            <mat-select fxFlex="1 0 calc(50% - 5px)" placeholder="Date" [(ngModel)]="historyTwo"
                        (selectionChange)="onDateTwoChange($event)">
              <mat-option *ngFor="let history of historyAll"
                          [disabled]="history.id === historyTwo || history.id === historyOne" [value]="history.id">
                {{ history?.created_at | caDateTime }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ca-default-picker>
      </div>
    </div>

    <div>
      <div class="history-compare-overflow">
        <!-- Action -->
        <div fxLayout class="ca-default-border-top">
          <span fxFlex class="adjust-span-padding">Action</span>

          <div fxFlex="0 0 80%" fxLayout fxLayoutGap="24px">
            <div fxFlex>
              <span class="adjust-span-padding">{{ historyItem?.action_key }}</span>
            </div>

            <div fxFlex
                 [ngClass]="{'info': (historyItemTwo && historyItemTwo.action_key && historyItem.action_key !== historyItemTwo.action_key)}">
              <span class="adjust-span-padding">{{ historyItemTwo?.action_key }}</span>
            </div>
          </div>
        </div>

        <!-- Author -->
        <div fxLayout class="ca-default-border-top">
          <span fxFlex class="adjust-span-padding">Author</span>

          <div fxFlex="0 0 80%" fxLayout fxLayoutGap="24px">
            <div fxFlex>
              <span class="adjust-span-padding">{{ historyItem?.user?.full_name }}</span>
            </div>

            <div fxFlex
                 [ngClass]="{'info': (historyItemTwo && historyItemTwo.user.full_name && historyItem.user.full_name !== historyItemTwo?.user?.full_name)}">
              <span class="adjust-span-padding">{{ historyItemTwo?.user?.full_name }}</span>
            </div>
          </div>
        </div>

        <!-- meta_data loop -->
        <div *ngFor="let key of temp">
          <div *ngIf="historyOneMetaData[key]?.label" fxLayout class="history-compare-row ca-default-border-top">
            <ng-container>
              <span fxFlex class="adjust-span-padding">{{ historyOneMetaData[key]?.label }}</span>

              <div fxFlex="0 0 80%" fxLayout fxLayoutGap="24px">
                <div fxFlex class="overflow-wrap account-content-left">
                  <ng-container *ngIf="key !== 'custom_values'
                    && key !== 'changed_by_id'
                    && key !== 'status'
                    && key !== 'contacts'
                    && key !== 'customer_circuit_id'
                    && key !== 'contact_name'
                    && key !== 'contract_name'
                    && key !== 'alias'
                    && key !== 'rules_to_replace'
                    && (key !== NETWORK_INVENTORY_VALUES.VENDOR_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.VENDOR_ENTITY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CUSTOMER_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.SITE_A_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.SITE_Z_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.BW_MAX_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CIRCUIT_BW_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.STATUS_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.TECHNOLOGY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.BW_MODEL_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CKT_USAGE_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.PROTECTED_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.PRIM_FAILOVER_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.TOPOLOGY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.HANDOFF_TYPE_KEY)
                    && (key !== INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.USER_ID_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.WITHHELD_STATUS_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CURRENCY_KEY)
                    && (key !== QUOTING_VALUES.QUOTE_SERVICE_TYPE_KEY)
                    && (key !== QUOTING_VALUES.CIRCUIT_BENDWIDTH_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.BW_MODEL_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.TOPOLOGY_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.STATUS_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.TECHNOLOGY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.COUNTRY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CONFIGURATION_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.SUBCLIENT_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.PAY_METH_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.BILLING_FREQ_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.MEDIA_TYP_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.REGION_KEY)
                    && (key !== QUOTING_VALUES.QUOTE_SERVICE_STATUS_KEY)
                    && (key !== GL_RULES.CONTEXT)
                    && (key !== GL_RULES.ACCOUNTS)
                    && (key !== CHARGE_VALUES.CHARGE_REASON_CODE_KEY)
                    && (key !== INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE_KEY)
">

                    <span class="adjust-span-padding">
                      {{historyItem.meta_data[key]?.value || 0 == historyItem.meta_data[key]?.value ? (
                      historyItem.meta_data[key]?.type === "DATE" ?
                        (historyItem.meta_data[key]?.value | caDateTime) : ((key == "foc_date_history") || (key == "service_test_date_history") ?
                        "" : historyItem.meta_data[key]?.type === "number" ?
                          (historyItem.meta_data[key]?.value | caNumber) : historyItem.meta_data[key]?.type === 'boolean' ?
                            (historyItem.meta_data[key]?.value | caYesNo) : historyItem.meta_data[key]?.value)) : ""}}

                      {{((historyItem.meta_data[key]?.value === undefined)) ? (
                      checkDate(historyItem.meta_data[key]?.value) ?
                        (historyItem.meta_data[key]?.value | caDateTime) : historyItem.meta_data[key]?.value) : ""}}


                      {{historyItem.id && (!historyItem.meta_data[key] || !historyItem.meta_data[key] ||
                      !historyItem.meta_data[key]?.value) && !isBool(historyItem.meta_data[key]?.value) ? "N/A" : ""}}
                    </span>
                    <span *ngIf="((key === 'foc_date_history') || (key === 'service_test_date_history'))"
                          class="adjust-span-padding">
                      {{(historyItem.meta_data[key]?.value && historyItem.meta_data[key]?.value[0]) ?
                      (historyItem.meta_data[key]?.value[0].changed_at | caDateTime) : ""}}
                    </span>
                  </ng-container>

                  <ng-container *ngIf="key === 'contacts'">
                    <span>{{ historyItem.meta_data[key]?.text || 'N/A'}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === 'customer_circuit_id'">
                    <span>{{ historyItem.meta_data[key]?.value === "null" || historyItem.meta_data[key]?.value == null ? 'N/A' : historyItem.meta_data[key]?.value}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === 'contact_name'">
                    <span>{{ historyItem.meta_data[key]?.value === "null" || historyItem.meta_data[key]?.value == null ? 'N/A' : historyItem.meta_data[key]?.value}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === 'contract_name'">
                    <span>{{ historyItem.meta_data[key]?.value === "null" || historyItem.meta_data[key]?.value == null ? 'N/A' : historyItem.meta_data[key]?.value}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === 'alias'">
                    <span>{{ historyItem.meta_data[key]?.value == null ? 'N/A' : historyItem.meta_data[key]?.value}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === 'custom_values'">
                    <span>{{getCustomValueText(historyItem.meta_data[key]?.value)}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === 'changed_by_id'">
                    <span>{{changedBy}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === 'status' && historyItem.entity_type !== HISTORY_TYPES.customerInventory">
                    <span>{{status || 'N/A'}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === 'rules_to_replace'">
                    <span class="adjust-span-padding">{{getCustomRules(historyItem.meta_data[key]?.value)}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.VENDOR_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.VENDOR}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.COUNTRY_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.COUNTRY}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CONFIGURATION_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.CONFIGURATION}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.BILLING_FREQ_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.BILLING_FREQ}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.PAY_METH_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.PAY_METH}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.MEDIA_TYP_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.MEDIA_TYP}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SUBCLIENT_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.SUBCLIENT}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.REGION_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.REGION}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.VENDOR_ENTITY_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.VENDOR_ENTITY}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CUSTOMER_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.CUSTOMER}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SITE_A_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.SITE_A}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SITE_Z_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.SITE_Z}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.BW_MAX_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.BW_MAX}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CIRCUIT_BW_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.CIRCUIT_BW}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.STATUS_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.STATUS}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.TECHNOLOGY_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.TECHNOLOGY}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.BW_MODEL_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.BW_MODEL}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CKT_USAGE_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.CKT_USAGE}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.PROTECTED_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.PROTECTED}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.PRIM_FAILOVER_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.PRIM_FAILOVER}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.TOPOLOGY_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.TOPOLOGY}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.HANDOFF_TYPE_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.HANDOFF_TYPE}}</span>
                  </ng-container>
                  <ng-container *ngIf="key === INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE_KEY">
                    <span>{{INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE}}</span>
                  </ng-container>
                  <ng-container *ngIf="key === CHARGE_VALUES.CHARGE_REASON_CODE_KEY">
                    <span>{{CHARGE_VALUES.CHARGE_REASON_CODE}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.USER_ID_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.USER_ID}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.WITHHELD_STATUS_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.WITHHELD_STATUS}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CURRENCY_KEY">
                    <span>{{NETWORK_INVENTORY_VALUES.CURRENCY}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH_KEY">
                    <span>{{CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.BW_MODEL_KEY">
                    <span>{{CUSTOMER_INVENTORY_VALUES.BW_MODEL}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.TOPOLOGY_KEY">
                    <span>{{CUSTOMER_INVENTORY_VALUES.TOPOLOGY}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.STATUS_KEY">
                    <span>{{CUSTOMER_INVENTORY_VALUES.STATUS}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.TECHNOLOGY_KEY">
                    <span>{{CUSTOMER_INVENTORY_VALUES.TECHNOLOGY}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === QUOTING_VALUES.QUOTE_SERVICE_TYPE_KEY">
                    <span>{{QUOTING_VALUES.QUOTE_SERVICE_TYPE}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === QUOTING_VALUES.CIRCUIT_BENDWIDTH_KEY">
                    <span>{{QUOTING_VALUES.CIRCUIT_BENDWIDTH}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === QUOTING_VALUES.QUOTE_SERVICE_STATUS_KEY">
                    <span>{{QUOTING_VALUES.QUOTE_SERVICE_STATUS}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === GL_RULES.CONTEXT">
                    <span>{{getContextText(historyItem.meta_data[key]?.value)}}</span>
                  </ng-container>

                  <ng-container *ngIf="key === INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE_KEY">
                    <span>{{INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE}}</span>
                  </ng-container>

                  <ng-container *ngIf="isContentPresent(key, true)">
                    <button class="copy-icon copy-icon-left" mat-icon-button *ngIf="key === GL_RULES.ACCOUNTS"
                            (click)="copyContent(key, true)" matTooltip="Click here to copy the content"
                    >
                      <ca-icon [key]="'SRM_SAVE_AS'"></ca-icon>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="key === GL_RULES.ACCOUNTS">
                    <div *ngFor="let account of getAccountContextText(historyItem.meta_data[key]?.value)"
                         class="account-string-value">
                      {{account}}
                    </div>
                  </ng-container>
                </div>

                <ng-container *ngIf="historyItemTwo">
                  <div fxFlex class="overflow-wrap account-content-right">
                    <ng-container
                      *ngIf="key !== 'custom_values'
                    && key !== 'changed_by_id'
                    && key !== 'status'
                    && key !== 'contacts'
                    && key !== 'customer_circuit_id'
                    && key !== 'contact_name'
                    && key !== 'contract_name'
                    && key !== 'alias'
                    && key !== 'rules_to_replace'
                    && (key !== NETWORK_INVENTORY_VALUES.VENDOR_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.VENDOR_ENTITY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CUSTOMER_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.SITE_A_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.SITE_Z_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.BW_MAX_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CIRCUIT_BW_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.STATUS_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.TECHNOLOGY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.BW_MODEL_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CKT_USAGE_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.PROTECTED_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.PRIM_FAILOVER_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.TOPOLOGY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.HANDOFF_TYPE_KEY)
                    && (key !== INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.USER_ID_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.WITHHELD_STATUS_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CURRENCY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.COUNTRY_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.CONFIGURATION_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.SUBCLIENT_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.PAY_METH_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.BILLING_FREQ_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.MEDIA_TYP_KEY)
                    && (key !== NETWORK_INVENTORY_VALUES.REGION_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.BW_MODEL_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.TOPOLOGY_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.STATUS_KEY)
                    && (key !== CUSTOMER_INVENTORY_VALUES.TECHNOLOGY_KEY)
                    && (key !== QUOTING_VALUES.QUOTE_SERVICE_TYPE_KEY)
                    && (key !== QUOTING_VALUES.CIRCUIT_BENDWIDTH_KEY)
                    && (key !== QUOTING_VALUES.QUOTE_SERVICE_STATUS_KEY)
                    && (key !== GL_RULES.CONTEXT)
                    && (key !== GL_RULES.ACCOUNTS)
                    && (key !== CHARGE_VALUES.CHARGE_REASON_CODE_KEY)
                    && (key !== INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE_KEY)">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value !== historyItem.meta_data[key]?.value) }">
                      <span class="adjust-span-padding">
                        {{historyItemTwo.meta_data[key]?.value || 0 == historyItemTwo.meta_data[key]?.value ? (
                        historyItemTwo.meta_data[key]?.type === "DATE" ?
                          (historyItemTwo.meta_data[key]?.value | caDateTime) : ((key == "foc_date_history") || (key == "service_test_date_history") ?
                          "" : historyItemTwo.meta_data[key]?.type === "number" ?
                            (historyItemTwo.meta_data[key]?.value | caNumber) : historyItemTwo.meta_data[key]?.type === 'boolean' ?
                              (historyItemTwo.meta_data[key]?.value | caYesNo) : historyItemTwo.meta_data[key]?.value)) : ""}}

                        {{((historyItemTwo.meta_data[key]?.value === undefined)) ? (
                        checkDate(historyItemTwo.meta_data[key]?.value) ? (historyItemTwo.meta_data[key]?.value | caDateTime) : historyItemTwo.meta_data[key]?.value) : ""}}

                        {{historyItemTwo.id && (!historyItemTwo.meta_data[key] || !historyItemTwo.meta_data[key] ||
                        !historyItemTwo.meta_data[key]?.value) && !isBool(historyItem.meta_data[key]?.value) ? "N/A" : ""}}
                      </span>
                        <span *ngIf="(key === 'foc_date_history') || (key === 'service_test_date_history')"
                              class="adjust-span-padding">{{(historyItemTwo.meta_data[key]?.value && historyItemTwo.meta_data[key]?.value[0]) ? (historyItemTwo.meta_data[key]?.value[0].changed_at | caDateTime) : ""}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === 'contacts'">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{ historyItemTwo.meta_data[key]?.text || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === 'customer_circuit_id'">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{ historyItemTwo.meta_data[key]?.value || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === 'contact_name'">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{ historyItemTwo.meta_data[key]?.value || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === 'contract_name'">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null ||  historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{ historyItemTwo.meta_data[key]?.value || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === 'alias'">
                      <div fxFlex
                           [ngClass]="{ 'info': (
                           historyItemTwo.meta_data[key] == null ||
                           (historyItemTwo.meta_data[key]?.value && historyItem.meta_data[key]?.value ?
                           historyItemTwo.meta_data[key]?.value.toString() != historyItem.meta_data[key]?.value.toString() :
                           historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value))}">
                        <span>{{ historyItemTwo.meta_data[key]?.value || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === 'custom_values'">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{getCustomValueText(historyItemTwo.meta_data[key]?.value)}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === 'rules_to_replace'">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || getCustomRules(historyItemTwo.meta_data[key]?.value) != getCustomRules(historyItem.meta_data[key]?.value)) }">
                        <span
                          class="adjust-span-padding">{{getCustomRules(historyItemTwo.meta_data[key]?.value)}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === 'changed_by_id'">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{changedByTwo}}</span>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="key === 'status' && historyItem.entity_type !== HISTORY_TYPES.customerInventory">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{statusTwo || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.VENDOR_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.VENDOR_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.COUNTRY_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.COUNTRY_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CONFIGURATION_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.CONFIGURATION_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SUBCLIENT_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.SUBCLIENT_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.MEDIA_TYP_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.MEDIA_TYP_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.BILLING_FREQ_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.BILLING_FREQ_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.PAY_METH_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.PAY_METH_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.REGION_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.REGION_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.VENDOR_ENTITY_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.VENDOR_ENTITY_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CUSTOMER_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.CUSTOMER_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SITE_A_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.SITE_A_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SITE_Z_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.SITE_Z_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.BW_MAX_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.BW_MAX_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CIRCUIT_BW_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.CIRCUIT_BW_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.STATUS_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.STATUS_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.TECHNOLOGY_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.TECHNOLOGY_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.BW_MODEL_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.BW_MODEL_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CKT_USAGE_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.CKT_USAGE_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.PROTECTED_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.PROTECTED_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.PRIM_FAILOVER_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.PRIM_FAILOVER_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.TOPOLOGY_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.TOPOLOGY_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.HANDOFF_TYPE_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.HANDOFF_TYPE_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="key === CHARGE_VALUES.CHARGE_REASON_CODE_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{CHARGE_VALUES.CHARGE_REASON_CODE_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.USER_ID_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.USER_ID_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.WITHHELD_STATUS_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.WITHHELD_STATUS_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === NETWORK_INVENTORY_VALUES.CURRENCY_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{NETWORK_INVENTORY_VALUES.CURRENCY_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.BW_MODEL_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{CUSTOMER_INVENTORY_VALUES.BW_MODEL_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.TOPOLOGY_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{CUSTOMER_INVENTORY_VALUES.TOPOLOGY_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.STATUS_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{CUSTOMER_INVENTORY_VALUES.STATUS_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === CUSTOMER_INVENTORY_VALUES.TECHNOLOGY_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{CUSTOMER_INVENTORY_VALUES.TECHNOLOGY_TWO || 'N/A'}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === QUOTING_VALUES.QUOTE_SERVICE_TYPE_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{QUOTING_VALUES.QUOTE_SERVICE_TYPE_TWO}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === QUOTING_VALUES.CIRCUIT_BENDWIDTH_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{QUOTING_VALUES.CIRCUIT_BENDWIDTH_TWO}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === QUOTING_VALUES.QUOTE_SERVICE_STATUS_KEY">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || historyItemTwo.meta_data[key]?.value != historyItem.meta_data[key]?.value) }">
                        <span>{{QUOTING_VALUES.QUOTE_SERVICE_STATUS_TWO}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === GL_RULES.CONTEXT">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || getContextText(historyItemTwo.meta_data[key]?.value) != getContextText(historyItem.meta_data[key]?.value)) }">
                        <span>{{getContextText(historyItemTwo.meta_data[key]?.value)}}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="key === INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE_KEY">
                      <span>{{INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE_TWO || 'N/A'}}</span>
                    </ng-container>

                    <ng-container *ngIf="isContentPresent(key)">
                      <button class="copy-icon copy-icon-right" mat-icon-button *ngIf="key === GL_RULES.ACCOUNTS"
                              (click)="copyContent(key)" matTooltip="Click here to copy the content"
                      >
                        <ca-icon [key]="'SRM_SAVE_AS'"></ca-icon>
                      </button>
                    </ng-container>

                    <ng-container *ngIf="key === GL_RULES.ACCOUNTS">
                      <div fxFlex
                           [ngClass]="{ 'info': (historyItemTwo.meta_data[key] == null || getAccountContextText(historyItemTwo.meta_data[key]?.value) != getAccountContextText(historyItem.meta_data[key]?.value)) }">
                        <div *ngFor="let account of getAccountContextText(historyItemTwo.meta_data[key]?.value)"
                             class="account-string-value">
                          {{account}}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<mat-dialog-actions class="ca-dialog-action">
  <div class="example-fill-remaining-space"></div>

  <button mat-button type="submit" (click)="close()">
    Cancel
  </button>
</mat-dialog-actions>
