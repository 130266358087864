import {Component, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {AlertService} from '../../../shared/alert/alert.service';
import {DocumentService} from '../../../shared/document/core/document.service';
import {HttpHeaders, HttpRequest} from '@angular/common/http';
import {LoaderService} from "../../../shared/loader/loader.service";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {IMessagesResourceService, ResourcesService} from 'app/core/resources/resources.service';
import Query from '../../../core/query/query';
import {DialogButtonsController} from '../../../core/dialog-buttons-controller.class'
import {LOOKUP_MODELS_ENUM} from "../../../dictionary/core/lookup-models.enum";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ContractService} from '../../core/contract.service';
import {debounceTime, takeUntil} from "rxjs";

@Component({
  selector: 'app-document-upload-dialog',
  templateUrl: './document-upload-dialog.component.html',
  styleUrls: ['./document-upload-dialog.component.css']
})
export class DocumentUploadDialogComponent extends DialogButtonsController {

  @ViewChild('fileInput') fileInput;

  contractId: number;
  contract_schedule_id: number;
  disableContractSchedulePicker: boolean;
  description: string;
  document;
  public entity;
  public folder: string;
  public subfolder: string;
  public entity_type;
  public entity_id;
  public master_type;
  public master_id;
  public isUploading: boolean = false;
  public progressMode = 'determinate';
  public fileName: string = '';
  public files = [];
  readonly CONTRACT_DOCUMENT_TYPE_MODEL: string =
    LOOKUP_MODELS_ENUM.CONTRACT_DOCUMENT_TYPE.modelName;

  readonly CONTRACTS_DOCUMENT_FOLDER_NAME: string = 'contracts';

  form: FormGroup;
  contractScheduleQuery = new Query;

  public fileNameTaken: any = null;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "contract";

  readonly CONTRACT_DOCUMENT_TYPE = LOOKUP_ENUM.DOCUMENT_TYPE;

  public fileType: string = this.CONTRACT_DOCUMENT_TYPE.SUPPORTING_DOCUMENT.toString();

  constructor(public documentService: DocumentService,
              public contractService: ContractService,
              public alert: AlertService,
              public formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<DocumentUploadDialogComponent>,
              public loaderService: LoaderService) {
                super();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
       document_type_id: new FormControl(this.document ? this.document.document_type_id : ''),
       description: new FormControl(this.document ? this.document.description : ''),
       contract_schedule_id: new FormControl(this.document ? this.document.contract_schedule_id : ''),
       file:new FormControl(this.document ? this.document.file : {}),
    });
    if(this.disableContractSchedulePicker){
      this.form.get('contract_schedule_id').patchValue(+this.contract_schedule_id);
      this.form.get('contract_schedule_id').disable();
    }
    this.form.controls['contract_schedule_id'].valueChanges.pipe(
      debounceTime(250))
      .subscribe(value => {
        if(this.fileName && value){
          this
            .isFileNameTaken(value, this.fileName)
            .subscribe(
              result => {
                this.fileNameTaken = result.fileExists;
              }
            );
        }else{
          this.fileNameTaken = null
        }
      })
  }

  onFileSelect(event) {
    const contractScheduleId = this.form.get('contract_schedule_id').value;
    if (event.target && event.target.files.length && contractScheduleId) {
      this.fileName = event.target.files[0].name;
      this.form.patchValue({file:event.target.files[0]})
      this.files = event.target.files;

      this
        .isFileNameTaken(contractScheduleId, this.fileName)
        .subscribe(
          result => {
            this.fileNameTaken = result.fileExists;
          }
        );
    }
    else {
      this.fileName = event.target.files[0].name;
      this.form.patchValue({file:event.target.files[0]})
      this.files = event.target.files;
    }
  }

  onDrop(files) {
    const contractScheduleId = this.form.get('contract_schedule_id').value;
    if (files && files.length && contractScheduleId) {
      this.fileName = files[0].name;
      this.files = files;
      this.form.patchValue({file:files[0]})

      this
        .isFileNameTaken(contractScheduleId, this.fileName)
        .subscribe(
          result => {
            this.fileNameTaken = result.fileExists;
          }
        );
    } else {
      this.fileName = files[0].name;
      this.files = files;
      this.form.patchValue({file:files[0]})
    }
  }

  isFileNameTaken(scheduleId: number, fileName: string) {

    this.fileNameTaken = null;

    const query = new Query({
      where: {
        contract_schedule_id:scheduleId,
        document_name:fileName
      },
    });

    return this
      .contractService
      .findDuplicateDocument(query);
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  onSubmit(document) {
    if (document) {
      this.create(document);
    }
    else {
      this.alert.error('', this.messages.get('DOCUMENT_NO_FILE'));
    }
  }

  create(document) {

    this.loaderService.displayLoader();

    this.isUploading = true;
    this.progressMode = 'indeterminate';
    let formData: FormData = new FormData();
    formData.append('files', document.value.file, document.value.file.name);
    formData.append('description', document.value.description || 'N/A');
    formData.append('contract_schedule_id',document.value.contract_schedule_id || this.form.get('contract_schedule_id').value);
    formData.append('document_type_id',document.value.document_type_id)
    formData.append('folder', this.CONTRACTS_DOCUMENT_FOLDER_NAME);

    this.toggleDialogButtons();
    this.contractService.createDocument(this.contractId, formData)
      .subscribe(
        (result) => {
          this.toggleDialogButtons(false);
          this.loaderService.hideLoader();
          this.dialogRef.close(result);
        },
        (error) => {
          this.toggleDialogButtons(false);
          this.loaderService.hideLoader();
          this.alert.error('', this.messages.get('DOCUMENT_UPLOAD_ERROR'));
          this.progressMode = 'determinate';
          this.isUploading = false;
        }
      );

  }

}
