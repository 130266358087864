import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import * as moment from 'moment';

import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { PageListComponent } from '../../core/page-list.component';
import { PageContext } from '../../core/page.context';
import Query from '../../core/query/query';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { ReportService } from '../../report/core/report.service';
import { AlertService } from '../../shared/alert/alert.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { QoutingFilterService } from '../core/qouting-filter.service';
import { Quote } from '../core/quote';
import { QUOTE_HEADER_STATUS } from '../core/quote-header-status.enum';
import { QUOTE_SERVICE_STATUS } from '../core/quote-service-status.enum';
import { QuotingGridService } from '../core/quoting-grid.service';
import { QuotingQuery } from '../core/quoting.query';
import { QuotingService } from '../core/quoting.service';
import { ServiceLevelFilterService } from '../core/service-level-filter.service';
import { ServiceLevelGridService } from '../core/service-level-grid.service';
import { ServiceLevelQuery } from '../core/service-level.query';
import { QuotingManageDialogComponent } from '../shared/quoting-manage-dialog/quoting-manage-dialog.component';


@Component({
  selector: 'app-quoting-list',
  templateUrl: './quoting-list.component.html',
})
export class QuotingListComponent extends PageListComponent implements AfterViewInit, OnInit {
  public quotes: Array<Quote>;
  public services: Array<Quote>;
  public selection: Quote;
  defaultServiceGridPager: number;
  readonly QUOTE_HEADER_STATUS = QUOTE_HEADER_STATUS.OPEN;
  readonly QUOTE_SERVICE_STATUS = QUOTE_SERVICE_STATUS.OPEN;

  public query: QuotingQuery = this.getDefaultQuery();
  public serviceQuery: ServiceLevelQuery = this.getDefaultServiceQuery();

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'quote';

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('serviceLevelGrid') serviceLevelGrid: DxDataGridComponent;
  public columns: Array<any>;
  public sorting: any[][];
  public serviceSorting: any[][];
  public quote: Quote;
  public selectedQuoteService;
  public serviceLevel = true;
  public serviceGridColumns: Array<any>;

  SRM_REPORTS = {
    QUOTE_SUMMARY: {
      reportKey: 'quote_summary',
      fileNamePrefix: 'QuoteSummary'
    },
    QUOTE_DETAILED_SUMMARY: {
      reportKey: 'quote_detailed_summary',
      fileNamePrefix: 'QuoteDetailedReport'
    },
  };

  SRM_REPORT_VIEW_TYPE = {
    VIEW: 'view',
    CSV: 'csv'
  };

  constructor(
    public quotingService: QuotingService,
    public dialogService: DialogService,
    public alertService: AlertService,
    public quotingGridService: QuotingGridService,
    public serviceLevelGridService: ServiceLevelGridService,
    public router: Router,
    public loaderService: LoaderService,
    public settingsService: UserSettingsService,
    public quotingFilterService: QoutingFilterService,
    public serviceLevelFilterService: ServiceLevelFilterService,
    public reportService: ReportService) {
    super(new PageContext({
      name: 'app.quoting.quoting-list',
      settings: settingsService
    }));
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  public getDefaultQuery(pageSize?: number) {
    let defaultQuery = {
      where: {
        'quote_header_status': this.QUOTE_HEADER_STATUS
      },
      limit: pageSize ? pageSize : 20,
      orderBy: [['id', 'DESC']],
    };
    return new QuotingQuery(Object.assign({}, defaultQuery));
  }

  public getDefaultServiceQuery(pageSize?: number) {
    let defaultServiceQuery = {
      where: {
        'service_status': this.QUOTE_SERVICE_STATUS
      },
      limit: pageSize ? pageSize : 20,
      orderBy: [['id', 'DESC']],
    };
    return new ServiceLevelQuery(Object.assign({}, defaultServiceQuery));
  }

  loadData(query?: QuotingQuery) {
    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    const clearSelection = () => {
      this.selection = null;
      this.selectedQuoteService = null;
    }

    this.quotingService.findAll(query)
      .subscribe((result) => {
        this.quotes = result.items;
        this.query.total = result.total;
        this.serviceLevel ? clearSelection() : this.selectFirstRow();
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      })
  }

  ;

  loadServiceData(query?: Query) {
    this.loaderService.displayLoaderAndManageGrid([this.serviceLevelGrid]);
    this.quotingService.findAllServices(query).subscribe((result) => {
      this.services = result.items;
      this.serviceQuery.total = result.total;
      this.loaderService.hideLoaderAndManageGrid([this.serviceLevelGrid]);
      this.selection = null;
      this.selectedQuoteService = null;
    });
  }

  ;

  newQuote() {
    this.dialogService.open(QuotingManageDialogComponent, {
      quote: {}
    })
      .afterClosed()
      .subscribe((result) => {
        this.refresh();
        if (result) {
          this.router.navigate(['/quotes', result.id, 'show']);
          this.loadData(null);
          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  refresh() {
    this.serviceLevel ? this.loadServiceData(this.serviceQuery) : this.loadData(this.query);
  }

  filterData(query: QuotingQuery) {
    this.query = query;
    this.loadData(query);
  }

  filterServiceData(query: ServiceLevelQuery) {
    this.serviceQuery = query;
    this.loadServiceData(query);
  }

  clearFilter() {
    this.query = this.getDefaultQuery(this.query.limit);
    this.loadData(this.query);
  }

  clearFilterService() {
    this.serviceQuery = this.getDefaultServiceQuery(this.serviceQuery.limit);
    this.loadServiceData(this.serviceQuery);
  }

  serviceLevelToggle() {
    this.serviceLevel = !this.serviceLevel;
    if (this.serviceLevel) {
      this.serviceLevelGridService.create(this.serviceLevelGrid.instance, {
        noDataText: this.serviceLevelGridService.noDataMessage
      });
      this.serviceLevelGrid.instance.refresh();
      this.serviceLevelGrid.instance.repaint();
    } else {
      this.quotingGridService.create(this.dataGrid.instance, {
        noDataText: this.quotingGridService.noDataMessage
      });
      this.dataGrid.instance.refresh();
      this.dataGrid.instance.repaint();
    }
    this.context.settings.save(this.context.name, { service_level: this.serviceLevel });
  }

  csv() {
    const { findAll, fields, query } = this.serviceLevel ? {
      findAll: this.quotingService.findAllServices.bind(this.quotingService),
      fields: this.serviceLevelGridService.csvMap(),
      query: this.serviceQuery
    } : {
      findAll: this.quotingService.findAll.bind(this.quotingService),
      fields: this.quotingGridService.csvMap(),
      query: this.query
    };

    this.quotingService.exportToCSV(
      'quotes',
      {
        fields,
        query,
        fetchDataHandler: csvQuery => {
          return findAll(csvQuery);
        }
      }
    );
  }

  onSelectionChanged(row) {
    this.selection = <Quote>row.selectedRowsData[0];
  }

  onSelectionService(row) {
    if (row.selectedRowsData.length > 0) {
      const { quote } = row.selectedRowsData[0];
      this.selectedQuoteService = row.selectedRowsData[0];
      this.selection = quote;
    } else {
      this.selection = null;
      this.selectedQuoteService = null;
    }
  }

  ngOnInit() {
    this.columns = this.quotingGridService.columns();
    this.serviceGridColumns = this.serviceLevelGridService.columns();
    if (this.serviceLevelGridService.sorting()) {
      this.serviceQuery.orderBy = this.serviceLevelGridService.sorting()
    } else {
      this.serviceQuery.orderBy = [['id', 'DESC']];
    }

    this.setDefaultGridPagers();

    this.loadData(this.query);
    this.loadServiceData(this.serviceQuery);
    this.settings.hasOwnProperty('service_level') && (this.serviceLevel = this.settings.service_level);
  }

  ngAfterViewInit(): void {
    if (this.serviceLevel) {
      this.serviceLevelGridService.create(this.serviceLevelGrid.instance, {
        noDataText: this.serviceLevelGridService.noDataMessage
      });
    } else {
      this.quotingGridService.create(this.dataGrid.instance, {
        noDataText: this.quotingGridService.noDataMessage
      });
    }

    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  showDetails() {
    if (this.selectedQuoteService) {
      this.router.navigate(['/quotes', this.selectedQuoteService.quote.id, 'show', this.selectedQuoteService.id]);
    } else if (this.selection) {
      this.router.navigate(['/quotes', this.selection.id, 'show']);
    }
  }

  onServiceCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      if (this.currentSortIndex > -1) {
        event.component.columnOption(this.currentSortIndex, 'caption', this.originalCaption);
      }

      const sameColumn = this.currentSortIndex === event.column.index;

      this.currentSortIndex = event.column.index;

      this.originalCaption = event.component.originalCaption || event.component.columnOption(this.currentSortIndex, 'caption');
      event.component.isSortAsc = sameColumn ? !event.component.isSortAsc : true;

      const isSortAsc = sameColumn ? event.component.isSortAsc : true;

      event.component.columnOption(
        event.column.index,
        'caption',
        isSortAsc ? this.originalCaption + ' ↑' : this.originalCaption + ' ↓'
      );

      this.sortServices([[event.column.dataField, isSortAsc ? 'ASC' : 'DESC']]);
    }
  }

  sortServices(sorting) {
    this.serviceQuery['orderBy'] = sorting;

    this.loadServiceData(this.serviceQuery);
    this.selectFirstRow();
  }

  gridSettings() {
    if (this.serviceLevel) {
      this.dialogService.open(GridSettingsComponent, {
        service: this.serviceLevelGridService
      })
        .afterClosed()
        .subscribe((settings) => {
          if (settings) {
            this.resetDataPager();
            this._init();
          }
        });
    } else {
      this.dialogService.open(GridSettingsComponent, {
        service: this.quotingGridService
      })
        .afterClosed()
        .subscribe((settings) => {
          if (settings) {
            this.resetDataPager();
            this._init();
          }
        });
    }
  }

  resetDataPager() {
    if(this.serviceLevel) {
      this.defaultServiceGridPager = this.serviceQuery.limit;
    } else {
      this.defaultGridPager = this.query.limit;
    }
  }

  setDefaultGridPagers() {
    this.defaultGridPager = this.quotingGridService.getGridPager();
    this.defaultServiceGridPager = this.serviceLevelGridService.getGridPager();
    this.query.limit = this.defaultGridPager;
    this.serviceQuery.limit = this.defaultServiceGridPager;
  }

  _init() {
    this.columns = this.quotingGridService.columns();
    this.sorting = this.quotingGridService.sorting();
    this.serviceSorting = this.serviceLevelGridService.sorting();
    this.serviceGridColumns = this.serviceLevelGridService.columns();

    if (this.sorting.length) {
      this.query.orderBy = this.sorting;
    } else {
      this.query.orderBy = [['id', 'DESC']];
    }

    if (this.serviceSorting) {
      this.serviceQuery.orderBy = this.serviceSorting;
    } else {
      this.serviceQuery.orderBy = [['id', 'DESC']];
    }

    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.option('columns', this.columns);
    }

    if (this.serviceLevelGrid.instance) {
      this.serviceLevelGrid.instance.option('columns', this.serviceGridColumns);
    }

    this.loadData(this.query);
    this.loadServiceData(this.serviceQuery);
  }

  srmReport(srmReport, viewType) {
    let reportKey = srmReport.reportKey;
    let reportFilter;
    let quotes = this.quotes;
    let quote_ids = [];

    quotes.forEach((quote) => {
      quote_ids.push(quote.id);
    });


    if (reportKey === this.SRM_REPORTS.QUOTE_SUMMARY.reportKey || reportKey === this.SRM_REPORTS.QUOTE_DETAILED_SUMMARY.reportKey) {
      reportFilter = { header_id: { $in: quote_ids } };
      let to_local_storage = JSON.stringify(reportFilter);
      localStorage.setItem('local_storage_params', to_local_storage);
    }

    if (viewType === this.SRM_REPORT_VIEW_TYPE.VIEW) {
      this.router.navigate(['report-gallery', reportKey, 'show']);
    } else if (viewType === this.SRM_REPORT_VIEW_TYPE.CSV) {
      let filterString = [];
      /*  Object.keys(reportFilter).forEach(key => filterString.push(reportFilter[key])); */
      let fileName = `${srmReport.fileNamePrefix}_${filterString.join('_')}_${moment().format('MM-DD-YYYY')}`;


      let report;
      let fields;

      this
        .reportService
        .loadByIdOrKey(reportKey)
        .subscribe(
          result => {
            report = result;
            fields = report
              .settings
              .fields
              .filter(field => field.selected)
              .map(field => ({ value: field.display_name, label: field.display_name }));

            report.limit = 1000000;
            report.offset = 0;

            const csvQuery = new Query({ where: reportFilter });

            report.dynamic_filter_query = csvQuery.where;

            this.reportService.exportToCSV(
              fileName,
              {
                fields,
                query: csvQuery,
                fetchDataHandler: this.reportService.execute.bind(this.reportService, { data: report })
              },
            );
          }
        );
    }
  }
}
