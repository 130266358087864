import {Action, State, StateContext, Store} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {PermissionService} from "./core/permission.service";
import {LoadPermissions, SetPermissions} from "./permission.acctions";

@State<any>({
  name: 'permissions',
  defaults: {
    permissions: [],
    resources: [],
  },
})
@Injectable()
export class PermissionsState {

  constructor(
    private store: Store,
    private permissionService: PermissionService
  ) {}

  @Action(LoadPermissions)
  loadPermissions(ctx: StateContext<any>): any {

    const state = ctx.getState();
    if (state && state.length) {
      return Promise.resolve(state);
    }

    return this.permissionService.loadPermissions().toPromise()
      // .then((result) => {
      //   const permissions = result[0];
      //   const {resources} = result[1];
      //   ctx.setState({
      //     permissions,
      //     resources
      //   })
      // });
  }

  @Action(SetPermissions)
  setPermissions(ctx: StateContext<any>, action: SetPermissions): void {
    const { permissions, resources } = action.payload;

    ctx.setState({
      permissions,
      resources
    });
  }
}
