import {forkJoin, Observable, of as observableOf} from 'rxjs';

import {map, mergeMap, takeUntil} from 'rxjs';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

import {Inventory} from '../core/inventory';
import {InventoryQuery} from '../core/inventory.query';
import {GridSettingsComponent} from '../../core/grid/grid-settings.component';
import {PageListComponent} from '../../core/page-list.component';
import {InventoryService} from '../core/inventory.service';
import {InventoryGridService} from '../core/inventory-grid.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {AlertService} from '../../shared/alert/alert.service';
import {InventoryManageDialogComponent} from '../shared/inventory-manage-dialog/inventory-manage-dialog.component';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageContext} from '../../core/page.context';
import {LoaderService} from '../../shared/loader/loader.service';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {INVENTORY_STATUS_ENUM} from '../core/inventory-status.enum';
import {DataLockDialogService} from '../../core/data-lock/data-lock-dialog.service';
import {InventoryFilterService} from '../core/inventory-filter.service';
import {InventoryCsvInterceptor} from '../core/inventory-csv-interceptor';
import {PermissionService} from "../../permissions/core/permission.service";
import {ConfigService} from '../../core/config/config.service';
import {LOOKUP_ENUM} from '../../dictionary/core/lookup.enum';
import {OrderManageDialogComponent} from 'app/order/shared/order-manage-dialog/order-manage-dialog.component';
import {Select, Store} from "@ngxs/store";
import {QueryBuilder} from "../../common/query/query.builder";
import Query from "../../core/query/query";
import {UpdateInventoryQuery} from "../state/inventory.actions";

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.css']

})
export class InventoryListComponent extends PageListComponent
  implements AfterViewInit, OnInit, OnDestroy {
  public query: InventoryQuery = new InventoryQuery({
    orderBy: [['vendor_id', 'ASC'], ['type_id', 'ASC'], ['id', 'ASC']]
  });

  public inventories: Array<Inventory>;
  public selection: Inventory;
  public selectionActive = false;
  public selectedRows: Array<number> = [];
  public selectedRowsData: Array<object> = [];
  public delaySelectionChange = false;
  public filter: any;
  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'inventory';
  readonly CORE_MODULE: string = 'core';
  readonly INVENTORY_STATUS_ENUM = INVENTORY_STATUS_ENUM;
  readonly COMPONENT_NAME = 'inventory-list';
  readonly custom_values_config_key = 'inventory_detail';

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public columns: Array<any>;
  public sorting: any[][];
  @Select(state => state.inventory.query) $query: Observable<Query>;

  isEnableDisconnect: boolean;
  isOrderEnabled: boolean;
  public permissionOrderCreate: boolean = false;


  adjustmentsKeys = Object.keys(LOOKUP_ENUM.ORDER_TYPE).filter(
    item => item !== 'INSTALL'
  );

  constructor(
    public store: Store,
    public queryBuilder: QueryBuilder,
    public route: ActivatedRoute,
    public inventoryService: InventoryService,
    public dialogService: DialogService,
    public alertService: AlertService,
    public inventoryGridService: InventoryGridService,
    public router: Router,
    public settingsService: UserSettingsService,
    public dataLockDialogService: DataLockDialogService,
    public loaderService: LoaderService,
    public inventoryFilterService: InventoryFilterService,
    public permissionService: PermissionService,
    public configService: ConfigService
  ) {
    super(
      new PageContext({
        name: 'app.inventory.inventory-list',
        settings: settingsService
      })
    );

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  loadPermissions() {
    const inventoryModify$ = this.permissionService.isAllowed("inventory", "MODIFY");
    const orderCreate$ = this.permissionService.isAllowed("orders", "CREATE");
    return forkJoin(
      inventoryModify$,
      orderCreate$
    ).pipe(map(([inventoryModify, orderCreate]) => {
      this.isEnableDisconnect = inventoryModify && this.isOrderEnabled;
      this.permissionOrderCreate = orderCreate
    }));
  }

  loadData(query?: InventoryQuery) {

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.delaySelectionChange = true;
    if (this.selectedRows.length) {
      this.inventories = [];
    }
    this.inventoryService.findAllLargeRequest(query).subscribe(
      (result: any) => {
        this.inventories = result.items;
        this.query.total = result.total;
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        if (this.inventories.length) {
          setTimeout(() => {
            this.delaySelectionChange = false;
            this.dataGrid.instance.selectRows(this.selectedRows, false);
          }, 500);
        }
      },
      () => {
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      }
    );
  }

  filterData(query: InventoryQuery) {
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query, route: this.route, queryBuilder: InventoryQuery
    })
  }

  clearFilter() {
    this.queryBuilder.buildQueryAndNavigate({
      component: this, query: new InventoryQuery(), route: this.route, queryBuilder: InventoryQuery
    })
  }

  refresh() {
    this.loadData(this.query);
  }

  async newInventory() {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

    this.dialogService
      .open(
        InventoryManageDialogComponent, {
          inventory: {}
        },{
          width: hasCustomFields ? '1200px' : '880px',
          height: '600px'
        }
      )
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.refresh();
          this.alertService.success('', this.messages.get('CREATE_SUCCESS'));
        }
      });
  }

  afterModalClosed(result, item) {
    if (result) {
      if (result.cancelByTimer || result.status == 2) {
        return this.alertService.success('', this.coreMessages.get('TIMER_EXPIRED'));
      } else if (result.deleted) {
        this.selectedRows = [];
        this.loadData(this.query);
        this.selectFirstRow();
        setTimeout(() => {
          this.alertService.snackBar.dismiss();
          this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
        }, 255);
      } else if (result.id) {
        Object.assign(item, result);
        this.dataGrid.instance.refresh();
        this.refresh();
        this.alertService.success('', this.messages.get('UPDATE_SUCCESS'));
      }
    }
  }

  async editInventory(inventory) {
    const hasCustomFields = await this.configService.hasCustomFields(this.custom_values_config_key);

    this.dialogService
      .edit(InventoryManageDialogComponent, {
          inventory: this.inventoryService.findByIdForEdit(inventory.id)
        }, {
          width: hasCustomFields ? '1200px' : '880px',
          height: '600px'
        }
      )
      .subscribe((items: any) => {
        if (items) {
          let obs = observableOf(items);
          obs.pipe(
            mergeMap(x => x.afterClosed()))
            .subscribe((result: any) => {
              this.afterModalClosed(result, inventory)
            });
        }
      });
  }

  csv() {
    const {inventoryGridService, inventoryService, query} = this;
    inventoryGridService.csvMap().subscribe(fields => {
      const inventoryTransformMiddleware = (items) => {
        const inventoryCSVInterceptor = new InventoryCsvInterceptor();

        return items.map(item => inventoryCSVInterceptor.transform(item));
      }

      inventoryService.exportToCSV('inventory', {
        fields,
        query,
        middleware: [inventoryTransformMiddleware]
      });
    });
  }

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => this.onQueryParamsChange(params));
    this.$query.pipe(takeUntil(this.destroy$)).subscribe((query) => this.onQueryChange(query));

    this.configService.get()
      .subscribe((config) => {
        this.isOrderEnabled = config && config.order ? config.order.enabled : false;
        this.loadPermissions().subscribe(() => {
        })
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /** Selection badge active */
  loadSelected() {
    if (this.query && this.query['where'] && !this.query['where'].hasOwnProperty('id')) {
      this.filter = JSON.parse(JSON.stringify(this.query['where']));
    }
    if (this.selectionActive) {
      this.query.remove('id');
      this.query['where'] = this.filter;
    } else {
      this.query['where'] = {};
      this.query.set('id', {
        $in: this.selectedRows
      });
    }

    this.query.offset = 0;
    this.query.page = 1;
    this.selectionActive = !this.selectionActive;
    this.loadData(this.query);
  }

  findIndexInSelectedRows(id): number {
    for (let i = 0, l = this.selectedRows.length; i < l; i++) {
      if (this.selectedRows[i] === id) {
        return i;
      }
    }
    return -1;
  }

  onSelectionChanged(event) {
    if (!this.delaySelectionChange && this.inventories.length) {
      // ** Deselected rows */
      if (event.currentDeselectedRowKeys.length) {
        event.currentDeselectedRowKeys.forEach(id => {
          let index = this.findIndexInSelectedRows(id);
          if (index > -1) {
            this.selectedRows.splice(index, 1);
            let filterSelectedRowsData = this.selectedRowsData.filter((item) => {
              return item['id'] !== id
            })
            this.selectedRowsData = [...filterSelectedRowsData];
          }
        });
      }

      // ** Selected rows */
      if (event.currentSelectedRowKeys.length) {
        event.currentSelectedRowKeys.forEach(id => {
          let index = this.findIndexInSelectedRows(id);
          if (index === -1) {
            this.selectedRows.push(id);
            let filteredSelectedRows = event.selectedRowsData.filter(item => {
              return item.id === id
            });
            this.selectedRowsData.push(filteredSelectedRows[0]);
          }
        });
      }

      if (this.selectedRows.length > 1) {
        this.selection = null;
      } else {
        this.selection = <Inventory>event.selectedRowsData[0];
      }

      if (this.selectionActive && !this.selectedRows.length) {
        this.loadSelected();
      }
    }
  }

  ngAfterViewInit(): void {
    this.inventoryGridService.create(this.dataGrid.instance, {
      selection: {
        mode: 'multiple',
        selectAllMode: 'page'
      }
    });
    this.selectFirstRow();
    super.ngAfterViewInit();
  }

  showDetails() {
    if (this.selection) {
      this.router.navigate(['/inventory', this.selection.id, 'show', 'summary']);
    }
  }

  gridSettings() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.inventoryGridService,
        sliderMinimum: 1
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this._init();

          this.dataGrid.instance.refresh();
          this.dataGrid.instance.repaint();
        }
      });
  }

  getGridService() {
    return this.inventoryGridService;
  }

  getDefaultSort(): any {
    return [
      ['vendor_id', 'ASC'],
      ['type_id', 'ASC'],
      ['id', 'ASC']
    ];
  }


  isCreateOrderEnabled() {
    if (this.selectedRowsData.length) {
      const vendor = this.selectedRowsData[0]['vendor_id'];
      const currency = this.selectedRowsData[0]['currency_id'];
      if (!vendor || !currency) return false

      return this.selectedRowsData.every(item => {
        return vendor === item['vendor_id'] && item['status_id'] === this.INVENTORY_STATUS_ENUM.ACTIVE && currency === item['currency_id']
      });
    }
    return false;
  }

  handleCreateOrderModalClose = res => {
    if (res) {
      this.refresh();
      this.alertService.success(
        '',
        this.messages.get('UPDATE_SUCCESS')
      );
    }
  }

  async createOrder(type) {

    this.dialogService.open(OrderManageDialogComponent, {
      order: {},
      type: type,
      inventories: this.selectedRowsData
    }, {width: '1200px'})
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.refresh();
        }
      });
  }

  sortColumn(sorting) {
    this.query = this.query || new InventoryQuery();
    this.query.orderBy = sorting;

    this.loadData(this.query);
  }

  private onQueryParamsChange(params: Params) {
    // to make sure query is empty when no params in the url
    if (Object.keys(params).length === 0) {
      this.query = new InventoryQuery()
    }
    const query = this.queryBuilder.build(this.query, null, this.COMPONENT_NAME).getQuery()
    this.store.dispatch([
      new UpdateInventoryQuery({query, key: params.key})
    ]);
  }

  private onQueryChange(query: Query) {
    this.query = new InventoryQuery(query);
    this._init();
  }
}
