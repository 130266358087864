import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NewRuleBUilderService } from './dx-rule-builder.service';
import { FilterConverterHelper } from './filter-converter';
import { isEqual } from 'lodash';

let inOperation = {
	name: 'in',
	caption: 'In',
	icon: 'check',
	editorTemplate: 'tagBoxTemplate',
	calculateFilterExpression(filterValue: any, field: any) {
		return [field.dataField, 'in', filterValue];
	}
};
const expected = [['chg_class', '=', 'mrc'], 'and', ['chg_desc_1', 'contains', 'LD'], 'and', ['acct_level_2', '=', null]];
const actual = [['chg_class', '=', 'mrc'], 'and', ['chg_desc_1', 'contains', 'LD'], 'and', [['acct_level_2', '=', null], 'or', ['acct_level_2', '=', '']]];

let notInOperation = {
	name: 'notIn',
	caption: 'Not In',
	icon: 'check',
	editorTemplate: 'tagBoxTemplate',
	calculateFilterExpression(filterValue: any, field: any) {
		return [field.dataField, 'notIn', filterValue];
	}
};

function simplifyConditions(conditions) {
	if (!Array.isArray(conditions) || conditions.length !== 3) return conditions;

	const [firstCondition, operator, lastCondition] = conditions;

	if (!Array.isArray(firstCondition) || !Array.isArray(lastCondition)) {
		return conditions;
	}

	const blankOperators = ['', null];
	const isBlankCondition =
		firstCondition[0] === lastCondition[0] && firstCondition[1] === lastCondition[1] && (blankOperators.includes(firstCondition[2]) || blankOperators.includes(lastCondition[2]));

	return isBlankCondition ? [firstCondition[0], firstCondition[1], null] : conditions;
}

@Component({
	selector: 'app-new-rule-builder',
	templateUrl: './dx-rule-builder.component.html',
	styleUrls: ['./dx-rule-builder.component.css']
})
export class NewRuleBuilderComponent implements OnInit, OnChanges {
	@Input() ruleTypeState;
	@Output() filterValueChanged = new EventEmitter();
	@Output() valueChanged = new EventEmitter();
	@Input() fields: Array<any>;
	@Input() filter: any;
	gridFilterValue: any;
	customOperations: Array<any>;
	groupOperations: string[] = ['and', 'or'];

	charge: any;
	prevFilterModel: any;

	filterText;
	dataSourceText;
	myFormatText;
	internalFilter;

	constructor(private service: NewRuleBUilderService) {}

	ngOnInit() {
		this.fields = this.service.getFields(); // fix issue with changeind category this.ruleTypeState['chargeTypeCategory']);
		if (!this.filter || !this.filter.length) {
			this.internalFilter = this.service.getFilter();
			this.filterValueChanged.emit(this.internalFilter);
		}
		this.customOperations = [inOperation, notInOperation];
		this.gridFilterValue = this.internalFilter;
		// this.filterValueChanged.emit(FilterConverterHelper.dxFilterFormatToStandard(this.filter));
	}

	ngOnChanges(changes: SimpleChanges): void {
		const filterChanges = changes['filter'];

		if (filterChanges && this.filter && !isEqual(filterChanges.previousValue, this.filter)) {
			this.internalFilter = this.filter.map((f) => simplifyConditions(f));
		}
	}

	detectAndPopulateNewField = (e) => {
		if (!this.charge) return;
		if (!this.prevFilterModel) {
			this.prevFilterModel = [...e.component._model];
		}
		const prevModelStr = JSON.stringify(this.prevFilterModel);
		const modelStr = JSON.stringify(e.component._model);

		if (prevModelStr != modelStr) {
			this.prevFilterModel = [...e.component._model];
			this.internalFilter = e.component._model = FilterConverterHelper.populateEmptyFields(e.component._model, this.charge);
		}
	};

	onContentReady(e) {
		this.detectAndPopulateNewField(e);
	}

	updateTexts(e) {
		this.valueChanged.emit(this.internalFilter);
		const expression = e.component.getFilterExpression();
		if (expression) {
			const value = FilterConverterHelper.dxFilterFormatToStandard(expression);
			this.filterValueChanged.emit(value);

			this.filterText = NewRuleBuilderComponent.formatValue(e.component.option('value'));
			this.dataSourceText = NewRuleBuilderComponent.formatValue(e.component.getFilterExpression());
			this.myFormatText = NewRuleBuilderComponent.adaptFormatValue(e.component.getFilterExpression());
		}
	}

	// TODO make this functions to adapt JSON form for saving the rule
	private static adaptFormatValue(value) {
		const newValue = FilterConverterHelper.dxFilterFormatToStandard(value);

		return JSON.stringify(newValue);
	}

	private static formatValue(value, spaces = 0) {
		if (value && Array.isArray(value[0])) {
			var TAB_SIZE = 4;
			spaces = spaces || TAB_SIZE;
			return (
				'[' +
				NewRuleBuilderComponent.getLineBreak(spaces) +
				value
					.map(function (item) {
						return Array.isArray(item[0]) ? NewRuleBuilderComponent.formatValue(item, spaces + TAB_SIZE) : JSON.stringify(item);
					})
					.join(',' + NewRuleBuilderComponent.getLineBreak(spaces)) +
				NewRuleBuilderComponent.getLineBreak(spaces - TAB_SIZE) +
				']'
			);
		}
		return JSON.stringify(value);
	}

	private static getLineBreak(spaces) {
		return '\r\n' + new Array(spaces + 1).join(' ');
	}
}
