import { NgModule } from '@angular/core';
import { MaterialModule } from 'app/material-barrel/material-barrel.module';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { TagInputModule } from 'ngx-chips';

import { QuotingRoutingModule } from './quoting-routing.module';
import { SharedModule } from '../shared/shared.module';
import { VendorModule } from '../vendor/vendor.module';
import { QuotingService } from './core/quoting.service';
import { QuotingGridService } from './core/quoting-grid.service';
import { QuotingDetailsComponent } from './quoting-details/quoting-details.component';
import { QuotingListComponent } from './quoting-list/quoting-list.component';
import { QuotingManageDialogComponent } from './shared/quoting-manage-dialog/quoting-manage-dialog.component';
import { QuotingDetailsGridService } from './core/quoting-details-grid.service';
import { QuotingDetailsRequestsComponent } from './quoting-details-offers/quoting-details-offers.component';
import { QuotingDetailsRequestsGridService } from './core/quoting-details-offers-grid.service';
import { ContactModule } from '../contact/contact.module';
import { CustomerModule } from '../customer/customer.module';
import { QoutingRequestManageDialogComponent } from './shared/qouting-offer-manage-dialog/qouting-offer-manage-dialog.component';
import { DictionaryModule } from '../dictionary/dictionary.module';
import { QoutingFilterService } from './core/qouting-filter.service';
import { CoreModule } from '../core/core.module';
import { QuoteServiceVendorGridService } from './core/quote-service-vendor-grid.service';
import { ConfirmQuoteDialogComponent } from './shared/confirm-quote-dialog/confirm-quote-dialog.component';
import { EmailSendFormComponent } from './shared/email-send-form/email-send-form.component';
import { AddressModule } from '../address/address.module';
import { CloseQuoteDialogComponent } from './shared/close-quote-dialog/close-quote-dialog.component';
import { SummaryPageGridService } from './core/summary-page-grid.service';
import { WizardVendorGridService } from './core/wizard-selected-vendors-grid.service';
import { WizardAllVendorsGridService } from './core/wizard-all-vendors-grid.service';
import { QuotingSneakPeekComponent } from './shared/quoting-sneak-peek/quoting-sneak-peek.component';
import { BandwidthModule } from '../bandwidth/bandwidth.module';
import { EmailTagComponent } from './shared/email-tag/email-tag.component';
import { QuotingReportComponent } from './quoting-report/quoting-report.component';
import { QuotingReportFilterService } from './core/quoting-report-filter.service';
import { QuotingReportService } from './core/quoting-report.service';
import { ChartPdfExportService } from '../report-gallery/core/chart-pdf-export.service';
import { QuoteRequestGridStatusPipePipe, QuoteRequestStatusPipe } from './shared/quote-request-status/quote-request-status.pipe';
import { CaServiceTypePickerComponent } from './shared/service-type-picker/ca-service-type-picker.component';
import { CaNpaPickerComponent } from './shared/npa-picker/ca-npa-picker.component';
import { CaNxxPickerComponent } from './shared/nxx-picker/ca-nxx-picker.component';
import { ReportService } from '../report/core/report.service';
import { SelectedHubsPipe } from './core/selected-hubs.pipe';
import { QuotingOverviewGridService } from './core/quoting-overview-grid.service';
import { QuoteServiceTypePipe } from './shared/quote-service-type-pipe/quote-service-type.pipe';
import { CaClientServiceTypePickerComponent } from './shared/client-service-type-picker/ca-service-type-picker.component';
import { QuotingServiceDialogComponent } from './shared/quoting-service-manage-dialog/quoting-service-manage-dialog.component';
import { ServiceLevelGridService } from './core/service-level-grid.service';
import { ServiceLevelFilterService } from './core/service-level-filter.service';
import { QuoteDetailsComponent } from './quote-details/quote-details.component';
import { QuoteResolver } from './core/quote.resolver';
import { QuotingServiceSneakPeekComponent } from './shared/quoting-service-sneak-peek/quoting-service-sneak-peek.component';
import { QuoteServiceAddressAPipe, QuoteServiceAddressZPipe } from './shared/quote-address/quote-address.pipe';

@NgModule({
    imports: [
        CommonModule,
        // RlTagInputModule,
        TagInputModule,
        QuotingRoutingModule,
        MaterialModule,
        FlexLayoutModule,
        SharedModule,
        VendorModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxTagBoxModule,
        FormsModule,
        ReactiveFormsModule,
        ContactModule,
        CustomerModule,
        DictionaryModule,
        CoreModule,
        AddressModule,
        BandwidthModule,
        DxChartModule
    ],
    declarations: [
        QuotingDetailsComponent,
        QuotingListComponent,
        QuotingManageDialogComponent,
        QuotingDetailsRequestsComponent,
        QoutingRequestManageDialogComponent,
        CaNpaPickerComponent,
        ConfirmQuoteDialogComponent,
        EmailSendFormComponent,
        CloseQuoteDialogComponent,
        QuotingSneakPeekComponent,
        EmailTagComponent,
        QuotingReportComponent,
        QuoteRequestStatusPipe,
        CaServiceTypePickerComponent,
        CaNpaPickerComponent,
        CaNxxPickerComponent,
        SelectedHubsPipe,
        QuoteServiceTypePipe,
        CaClientServiceTypePickerComponent,
        QuotingServiceDialogComponent,
        QuoteDetailsComponent,
        QuotingServiceSneakPeekComponent,
        QuoteRequestGridStatusPipePipe,
        QuoteServiceAddressAPipe,
        QuoteServiceAddressZPipe
    ],
    providers: [
        QuotingService,
        QuotingGridService,
        QoutingFilterService,
        QuotingDetailsGridService,
        QuotingDetailsRequestsGridService,
        QuoteServiceVendorGridService,
        SummaryPageGridService,
        WizardVendorGridService,
        WizardAllVendorsGridService,
        QuotingReportFilterService,
        QuotingReportService,
        ChartPdfExportService,
        CurrencyPipe,
        ReportService,
        QuotingOverviewGridService,
        ServiceLevelGridService,
        ServiceLevelFilterService,
        QuoteResolver,
        QuoteRequestStatusPipe,
        QuoteRequestGridStatusPipePipe,
        QuoteServiceAddressAPipe,
        QuoteServiceAddressZPipe
    ],
    exports: [
        CaServiceTypePickerComponent,
        CaNxxPickerComponent,
        CaNpaPickerComponent,
        QuoteRequestStatusPipe,
        QuoteServiceTypePipe
    ]
})
export class QuotingModule {
}
