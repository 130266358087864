import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule, registerLocaleData} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {MasterModule} from './master/master.module';
import {OrderModule} from './order/order.module';
import {TagAdministrationModule} from './tag/tag.module';
import {AppService} from './app.service';
import {GridService} from './shared/grid/grid.service';
import {UserService} from './user/core/user.service';
import {UserSettingsService} from './user/core/user-settings.service';
import {ChartReloadService} from './theme/core/chart-reload.service';
import {LoaderService} from './shared/loader/loader.service';
import {DataLockService} from './core/data-lock/data-lock.service';
import {NavStateService} from './master/nav-state.service';
import {ServiceLocator} from './core/locator.servce';
import {Cache} from './core/cache.servce';


import {MatMomentDateModule} from '@angular/material-moment-adapter';

import {DefaultThemeResolve} from './theme/core/default-theme.resolve';
import {ChartService} from './shared/chart/chart.service';
import {DataLockDialogService} from './core/data-lock/data-lock-dialog.service';
import {HttpClientModule} from '@angular/common/http';
import {CacheFactory} from "cachefactory";
import {NgxsModule} from "@ngxs/store";
import {environment} from "../environments/environment";
import {PermissionsState} from "./permissions/permissions.state";
import {UserState} from "./user/state/user.state";
import {ConfigState} from "./core/config/config.state";
import {InvoicesState} from "./invoice/state/invoice.state"


const toastrOptions = {
  positionClass: 'toast-bottom-right'
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MasterModule,
    ToastrModule.forRoot(toastrOptions),
    OrderModule,
    TagAdministrationModule,
    MatMomentDateModule,
    NgxsModule.forRoot([PermissionsState, UserState, ConfigState, InvoicesState], {
      developmentMode: !environment.production
    }),
  ],
  exports: [],
  providers: [
    AppService,
    GridService,
    UserService,
    UserSettingsService,
    ChartReloadService,
    LoaderService,
    DataLockService,
    DataLockDialogService,
    NavStateService,
    DefaultThemeResolve,
    ChartService,
    ServiceLocator,
    {
      provide: LOCALE_ID,
      useFactory: () => localStorage.getItem('__localeId') ? localStorage.getItem('__localeId') : 'en'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {    // Create global Service Injector.
    if (!localStorage.getItem('__localeId')) {
      localStorage.setItem('__localeId', 'en')
    }
    import(
      /* webpackExclude: /\.d\.ts$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-base" */
      `../../node_modules/@angular/common/locales/${localStorage.getItem('__localeId')}.mjs`).then(locale => {
      registerLocaleData(locale.default);
    });

    ServiceLocator.injector = this.injector;
    Cache.factory = new CacheFactory();
  }
}
