import { BehaviorSubject, Observable } from 'rxjs';
import { path } from 'ramda';

import { environment } from '../../../environments/environment';
import {filter, map, scan} from "rxjs";

const getSelectorFunction = stateSelector => {
  if (typeof stateSelector === 'function') {
    return stateSelector;
  }

  if (Array.isArray(stateSelector)) {
    return path(stateSelector);
  }

  if (typeof stateSelector === 'string') {
    return path([stateSelector]);
  }

  throw new Error('selector must be either function, string or array');
};

export class Store {
  logEnabled = !environment.production;
  debugLabel = '';
  private state;
  private notifications;

  constructor(private initialState) {
    if (initialState === undefined || initialState === null) {
      throw new Error('initial state can not be undefined nor null');
    }

    this.state = new BehaviorSubject(initialState);
    this.notifications = this.state.asObservable();
  }

  getStateAsObservable() {
    return this.notifications;
  }

  getState() {
    return this.state.value;
  }

  // dissallowing setting state directly outside of store service
  private setStateAsync(onChangeState) {
    this.state.next({
      ...this.getState(),
      ...onChangeState(this.getState())
    });
  }

  private setStateSync(newState) {
    this.state.next(typeof newState === 'object' ? {
      ...this.getState(),
      ...newState,
    } : newState);
  }

  private logState(oldState) {
    if (this.logEnabled) {
      const newState = this.getState();
      const changedKeys = Object
        .keys(this.getState())
        .filter(key => newState[key] !== oldState[key]);

      const table = changedKeys.reduce((acc, changedStatePiece) => {
        acc[changedStatePiece] = {
          oldState: oldState[changedStatePiece],
          newState: newState[changedStatePiece]
        };
        return acc;
      }, {});
      console.log('\n');
      console.log('\n');
      console.log('************************************************************');
      console.log(`${this.debugLabel} state changed:`);
      console.table(table);
      console.log(`Current ${this.debugLabel.toLowerCase()} state:`, newState);
    }
  }

  protected setState(onChangeState) {
    const oldState = this.getState();
    let result;
    if (typeof onChangeState === 'function') {
      result = this.setStateAsync(onChangeState);
    } else {
      result = this.setStateSync(onChangeState);
    }
    this.logState(oldState);
    return result;
  }

  select(selector: Function | string | string[]): Observable<any> {
    const selectorFn = getSelectorFunction(selector);
    return this.getStateAsObservable().pipe(scan((acc, nextState): any => {
      const selected = selectorFn(nextState);
      return {
        state: nextState,
        selectedSlice: selected,
        changed: acc.state === undefined || selected !== selectorFn(acc.state)
      };
    }, {
      state: undefined,
      changed: false,
      selectedSlice: {}
    }),
      filter(({ changed }) => changed),
      map(({ selectedSlice }) => selectedSlice)
      )
  }

  asyncData(fn: (state?: any) => Observable<any>): Promise<any> {
    this.setState({
      loading: true
    });

    return new Promise((resolve, reject) => {
      fn(this.getState()).subscribe(
        result => {
          this.setState({
            loading: false,
            ...result
          });
          resolve(this.getState());
        },
        error => {
          this.setState({
            error,
            loading: false
          });
          reject(error);
        }
      );
    });
  }

  resetState() {
    this.setState(this.initialState);
  }

  connect(context) {
    let oldState = this.state.value;

    return mapState => {
      return this.notifications.subscribe(state => {
        Object.assign(context, mapState(state, oldState));
        oldState = state;
      });
    };
  }
}
