import {Component, OnInit, EventEmitter, Output, HostListener, ViewChild} from '@angular/core';
import {filter, takeUntil} from "rxjs";
import {of as observableOf, Subject} from 'rxjs';
import {Router } from '@angular/router';
import {UserService} from 'app/user/core/user.service';
import Query from '../../core/query/query';
import {User} from '../../user/core/user';
import {FileService} from '../file/file.service';
import {NotificationService} from './notification.service';
import {CommonAlertService} from "../../common-alert/core/common-alert.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification-component.component.html',
  styleUrls: ['./notification-component.component.scss', '../../../assets/widgets/notification.scss']
})
export class NotificationComponent implements OnInit {
  @ViewChild('menuTrigger') trigger;
  notifications = [];
  notificationsTotal = 0;
  newNotificationsCount = 0;
  singleDigit = false;
  userId: number;
  destroy$: Subject<boolean> = new Subject<boolean>();

  public ROUTING_PLACES = {
    'GL_BATCH_DETAILS': 'gl_batch_detail',
  };
  readonly AUDIT_RULE_NAMES = {
    'GL_UNCODED_CHARGES': 'GL_UNCODED_CHARGES',
    'CHARGE': 10,
    'INVENTORY_MRC': 25,
    'GL_VALIDATION': 70,
    'ACCOUNT': 50,
    'GL_BATCH': 90
  }

  query: Query = new Query({
    limit: 100,
    orderBy: [['created_at', 'DESC']]
  });



  public isLoading = true;

  constructor(private notificationService: NotificationService,
              private fileService: FileService,
              private userService: UserService,
              public router: Router,
              public commonAlertService: CommonAlertService,) {
  }

  ngOnInit(): void {
    this.userService.me().subscribe((res: User) => {
      this.userId = res.id;
    });

    this.findAllNewNotifications();
    this.notificationService.listen().pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data.user_id === this.userId) {
          this.isLoading = false;
          let notification = Object.assign({}, data, {is_new: true});
          if(notification && !notification.is_archived){
            this.notifications.splice(0, 0, notification);
            this.updateNotificationsCount();
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private updateNotificationsCount() {
    this.newNotificationsCount = this.getNewNotifications().length;
    this.singleDigit = this.getNewNotifications().length < 10 && this.getNewNotifications().length > 0;
  }

  private findAllNewNotifications() {
    this.query.set('is_archived', { $ne: true });
    this.notificationService.findAll(this.query)
      .subscribe((data) => {
        this.notifications = data.items.filter((x) => {
          return !x.is_archived;
        }).sort((a, b) =>  b.id - a.id);
        this.notificationsTotal = data.total
        this.updateNotificationsCount();
        this.isLoading = false;
      });
  }

  handleMoreButtonClick(ev) {
    ev.stopPropagation();
  }

  handleActionMenuClick(ev) {
    ev.stopPropagation();
  }

  // TODO: File download is specific case and should not be part of the notifications
  download(entry, ev) {
    this.fileService.download(entry.context).subscribe();
  }

  setRead(entry, ev) {
    ev.stopPropagation();
    entry.is_read = !entry.is_read;
    this.notificationService.read(entry.id, entry.is_read);
    this.updateNotificationsCount();
  }

  readAll(ev?: any) {
    this.notifications.forEach(e => e.is_read = true);
    this.notificationService.readAll();
  }

  archiveAll() {
    this.notificationService.archiveMany(this.notifications.map(x => x.id));
    this.notifications = [];
  }

  remove(entry, ev) {
    ev.stopPropagation();
    entry.is_archived = true;
    this.notifications.splice(this.notifications.indexOf(entry), 1);
    this.notificationService.archive(entry.id);
    this.updateNotificationsCount();
  }

  private resetNewNotifications() {
    this.notifications.forEach((notification) => {
      notification.is_new = false;
    });
    this.newNotificationsCount = 0;
  }

  private getNewNotifications() {
    return this.notifications.filter((notification) => {
      return notification.is_new && !notification.is_read;
    })
  }

  hasDownload(entry) {
    return entry && entry.context && entry.context.bucket && entry.context.key && entry.context.location && entry.context.name;
  }

  hasBatchLink(entry) {
    return entry && entry.context && entry.context.batch_id;
  }
  hasAlertLink(entry){
    return entry && entry.alert_id
  }

  endScrollHandler($event) {
    let element = $event.srcElement;
    if (Math.ceil(element.scrollTop) >= (element.scrollHeight - element.clientHeight) && this.notifications.length < this.notificationsTotal ) {
      this.query.set('is_archived', { $ne: true });
      this.query.offset = this.query.offset + 100
      this.notificationService.findAll(this.query)
      .subscribe((data) => {
        this.notifications = this.notifications.concat(data.items.filter((x) => {
          return !x.is_archived;
        }).sort((a, b) =>  b.id - a.id));
        this.updateNotificationsCount();
        this.isLoading = false;
        });
    }
  }
  redirectTo(entry, tabName){
    if(tabName === this.ROUTING_PLACES.GL_BATCH_DETAILS){
      if(entry && entry.context && entry.context.batch_id) {
        this.router.navigate(['gl-batch', entry.context.batch_id, 'show']);
      }
    }
  }
  redirectToAlert(entry){
    if(entry && entry.alert_id){
        this.commonAlertService.findById(entry.alert_id).subscribe(
          alert => {
            this.router.navigateByUrl('/alert', {skipLocationChange: true}).then(() => {
              if (alert.rule_name === this.AUDIT_RULE_NAMES.GL_UNCODED_CHARGES && alert.vendor_id && alert.account_id) {
                this.router.navigate(['gl-rule-execution', alert.vendor_id, alert.account_id, 'charges']);
              } else if (alert.type_id === this.AUDIT_RULE_NAMES.CHARGE) {
                this.router.navigate(['alert', alert.id, 'charges']);
              } else if (alert.type_id === this.AUDIT_RULE_NAMES.INVENTORY_MRC) {
                this.router.navigate(['alert', alert.id, 'inventory-mrc']);
              } else if (alert.type_id === this.AUDIT_RULE_NAMES.GL_VALIDATION) {
                this.router.navigate(['alert', alert.id, 'gl-validation']);
              } else if (alert.type_id === this.AUDIT_RULE_NAMES.ACCOUNT) {
                this.router.navigate(['alert', alert.id, 'account']);
              } else if (alert.type_id === this.AUDIT_RULE_NAMES.GL_BATCH) {
                this.router.navigate(['alert', alert.id, 'batch']);
              } else {
                this.router.navigate(['alert', alert.id, 'inventory']);
              }
              this.trigger.closeMenu()
          },
          error => {
            console.log('Error fetching alert info');
          }
        );
      });
      // this.router.navigate(['alert', entry.alert_id, 'inventory']);
    }
  }
}
