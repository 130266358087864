import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ShortInvoiceChargeProfileGridService } from 'app/invoice/core/short-invoice-charges-profile-grid.service';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { InvoiceChargeService } from '../../../charge/core/invoice-charge.service';
import { DialogButtonsController } from '../../../core/dialog-buttons-controller.class';
import {
	IMessagesResourceService,
	ResourcesService
} from '../../../core/resources/resources.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { CreateInvoiceChargeProfileWarningDialogComponent } from '../create-invoice-charge-profile-warning-dialog/create-invoice-charge-profile-warning-dialog.component';
import { CreateInvoiceChargeProfileDuplicateWarningDialogComponent } from '../create-invoice-charge-profile-duplicate-warning-dialog/create-invoice-charge-profile-duplicate-warning-dialog.component';
import { ValidationService } from '../../../shared/validation/validation.service';
import { InventoryService } from '../../../inventory/core/inventory.service';
import Query from 'app/core/query/query';

@Component({
	selector: 'app-create-invoice-charge-profile-dialog',
	templateUrl: './create-invoice-charge-profile-dialog.component.html',
	styleUrls: ['./create-invoice-charge-profile-dialog.component.css']
})
export class CreateInvoiceChargeProfileDialogComponent
	extends DialogButtonsController
	implements OnInit
{
	readonly MESSAGES_MODULE: string = 'charge';
	readonly VALIDATION = ValidationService.MESSAGES;

	private messages: IMessagesResourceService;

	public title;
	public selectedCharge: any;
	public columns: Array<any>;
	public charges: Array<any> = [];
	public reset = false;

	form: FormGroup;
	public selectedAuditSettings = null;
	valueInputs = [
		{ name: 'min_value', placeholder: 'Min Value' },
		{ name: 'max_value', placeholder: 'Max Value' }
	];

	@ViewChild('chargeGrid') chargeGrid: DxDataGridComponent;
	@ViewChild('formCharge') formCharge: NgForm;

	constructor(
		public dialogRef: MatDialogRef<CreateInvoiceChargeProfileDialogComponent>,
		public alert: AlertService,
		public invoiceChargeService: InvoiceChargeService,
		public inventoryService: InventoryService,
		public shortInvoiceChargeProfileGridService: ShortInvoiceChargeProfileGridService,
		public dialogService: DialogService,
		public formBuilder: FormBuilder
	) {
		super();
		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
	}

	ngOnInit(): void {
		this.columns = this.shortInvoiceChargeProfileGridService.columns();

		this.form = this.formBuilder.group({
			audit_rule: [null, Validators.required]
		});
	}

	ngAfterViewInit() {
		if (this.chargeGrid) {
			this.shortInvoiceChargeProfileGridService.create(
				this.chargeGrid.instance,
				{
					noDataText: this.shortInvoiceChargeProfileGridService.noDataMessage,
					columnMinWidth: 50
				}
			);
		}
	}

	onSelectionChange($event: Event) {
		this.selectedAuditSettings =
			$event['selection'] && $event['selection']['settings'];

		this.valueInputs.forEach((input) => {
			if (this.form.get(input.name)) this.form.removeControl(input.name);
		});

		if (this.selectedAuditSettings) {
			for (const [key, value] of Object.entries(this.selectedAuditSettings)) {
				if (!!value)
					this.form.addControl(
						key,
						this.formBuilder.control('', Validators.required)
					);
			}
		}
	}

	onSubmit() {
		// Check if multiple SPIDs or Charge Types, break if users exists, continue if he agrees
		const spids = [...new Set(this.charges.map((charge) => charge.sp_serv_id))];
		const chargeTypes = [
			...new Set(this.charges.map((charge) => charge.chg_class))
		];
		const uniqueProfileIDs = [
			...new Set(
				this.charges.map(
					(charge) =>
						`${charge.vendor_id}-${charge.acct_level_1}-${charge.acct_level_2 || ''}-${charge.sp_serv_id || ''}-${charge.chg_desc_1 || ''}-${charge.chg_class || ''}-${charge.site_a_addr_1 || ''}-${charge.site_a_addr_2 || ''}-${charge.site_a_addr_3 || ''}-${charge.site_a_id || ''}-${charge.site_a_addr_zip || ''}-${charge.site_z_addr_1 || ''}-${charge.subscriber || ''}`
				)
			)
		];

		const warningMessage =
			spids.length > 1 && chargeTypes.length > 1
				? 'Selected charges contains multiple SPIDs and multiple Charge types.'
				: spids.length > 1
					? 'Selected charges contains multiple SPIDs.'
					: chargeTypes.length > 1
						? 'Selected charges contains multiple Charge types.'
						: '';

		const query = new Query();
		query.where.is_active = true;
		this.inventoryService
			.findAllInvoiceChargeProfiles(query)
			.subscribe((result) => {
				const savedUniqueProfileIds = result.items.map(
					(item) => item.unique_profile_id
				);
				const numOfDuplicates = [...new Set(uniqueProfileIDs)].filter((value) =>
					new Set(savedUniqueProfileIds).has(value)
				);

				const warningDuplicate = `${numOfDuplicates.length} of the select charges already has a profile configured. Do you want to replace all existing records or insert just the selected ones?`;

				if (!warningMessage) {
					if (numOfDuplicates.length) {
						this.dialogService
							.open(CreateInvoiceChargeProfileDuplicateWarningDialogComponent, {
								width: '920px',
								height: '540px',
								message: warningDuplicate
							})
							.afterClosed()
							.subscribe(async (replacement) => {
								if (!replacement) return this.close();
								this.addInvoiceChargeProfiles(replacement);
							});
					} else {
						this.addInvoiceChargeProfiles();
					}
				} else {
					this.dialogService
						.open(CreateInvoiceChargeProfileWarningDialogComponent, {
							width: '920px',
							height: '540px',
							message: warningMessage
						})
						.afterClosed()
						.subscribe(async (confirmed) => {
							if (!confirmed) return this.close();

							if (numOfDuplicates.length) {
								this.dialogService
									.open(
										CreateInvoiceChargeProfileDuplicateWarningDialogComponent,
										{
											width: '920px',
											height: '540px',
											message: warningDuplicate
										}
									)
									.afterClosed()
									.subscribe(async (replacement) => {
										if (!replacement) return this.close();
										this.addInvoiceChargeProfiles(replacement);
									});
							} else {
								this.addInvoiceChargeProfiles();
							}
						});
				}
			});
	}

	addInvoiceChargeProfiles(replacement?: number): void {
		this.inventoryService
			.addInvoiceChargeProfiles(
				this.charges.map((chg) => chg.id),
				this.form.value.audit_rule,
				this.form.value.min_value || this.form.value.exact_value || 0,
				this.form.value.max_value || this.form.value.exact_value || 0,
				replacement || 0
			)
			.subscribe(
				(response) => {
					this.close({ status: 200, data: response });
				},
				() => {
					this.close({
						status: 500,
						message: 'Failed to add Invoice Charge Profile records'
					});
				}
			);
	}

	close(result?: any) {
		this.dialogRef.close(result);
	}
}
