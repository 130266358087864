import {
  Component, forwardRef, Input, OnInit
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable } from "rxjs";
import { PICKER_COMPONENT_TEMPLATE, PickerComponentBase } from "../../../shared/picker/ca-picker-base.component";
import { QuotingService } from "../../core/quoting.service";
import { BandwidthService } from "../../../bandwidth/core/bandwidth.service";
import Query from "../../../core/query/query";

@Component({
  selector: 'ca-service-type-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => CaServiceTypePickerComponent),
          multi: true
      },
      QuotingService,
      BandwidthService
  ]
})
export class CaServiceTypePickerComponent extends PickerComponentBase implements OnInit {

  readonly TEXT_FIELD = 'value';
  readonly KEY_FIELD = 'id';
  @Input('formControlName') formControlName;
  @Input('initialItem') initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('standalone') standalone: boolean = true;

  @Input('query') query: Query;

  public control;

  constructor(public bandwidthService: BandwidthService) { super(); }

  loadEntries(): Observable<any> {
    this.control = this.pickerForm.controls[this.formControlName];
    return this.bandwidthService.findAllTypes(this.query);
  }

  search(value: string): Observable<any> {
      this.query.set('value', { '$ilike': `${value}%` });
      return this.bandwidthService.findAllTypes(this.query);
  }

}
