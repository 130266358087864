import {Injectable, EventEmitter} from '@angular/core';

@Injectable()
export class LoaderService {

  public onChange: EventEmitter<any> = new EventEmitter();

  public loaderVisible: boolean = false;
  public navigationLoaderVisible: boolean = false;

  constructor() {

  }

  emit(visible: boolean) {
    this.onChange.emit({
      visible: visible
    })
  }

  subscribe(generatorOrNext?: any, error?: any, complete?: any) {
    this.onChange.subscribe(generatorOrNext, error, complete);
  }

  isLoaderVisible(navigation?:boolean) {
    return navigation ? this.navigationLoaderVisible : this.loaderVisible;
  }

  displayLoader(navigation?:boolean) {
    if(navigation) {
      this.navigationLoaderVisible = true;
    }
    else {
      this.loaderVisible = true;
    }
  }

  hideLoader(navigation?:boolean) {
    if(navigation) {
      this.navigationLoaderVisible = false;
    }
    else {
      this.loaderVisible = false;
    }
  }

  displayLoaderAndManageGrid(grids: Array<any>, navigation?:boolean) {
    this.displayLoader(navigation);
    grids.forEach((grid) => {      
      if(grid){
        grid.dataSource = [];
        if(grid.instance && grid.instance._options){
          grid.instance.option({noDataText: 'Loading...'})

        }
      }
    });
  }

  hideLoaderAndManageGrid(grids: Array<any>, navigation?: boolean) {
    this.hideLoader(navigation);
    grids.forEach((grid) => {
      setTimeout(() => {
        if (grid && grid.instance && grid.instance._options) {
          let items = grid.instance._options.dataSource || [];
          if (!items || !items.length) {
            grid.instance.option({noDataText: 'No data'})
          }
        }
      });
    });
  }

}
