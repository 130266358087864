import {Component, OnInit, ViewChild} from '@angular/core';
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {HistoryComponent} from "../../../shared/history/history.component";
import Query from "../../../core/query/query";
import {SvcOrderServiceGridService} from "../../core/svc-order-service-grid.service";
import {ActivatedRoute, Router} from "@angular/router";
import {catchError, map} from "rxjs";
import {throwError as observableThrowError} from "rxjs/internal/observable/throwError";
import {DocumentService} from "../../../shared/document/core/document.service";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {DocumentGridService} from "../../../shared/document/core/document-grid.service";

@Component({
  selector: 'app-svc-order-details-attachments',
  templateUrl: './svc-order-details-attachments.component.html',
  styleUrls: ['./svc-order-details-attachments.component.css']
})
export class SvcOrderDetailsAttachmentsComponent extends PageDetailsComponent implements OnInit {

  @ViewChild('history') history: HistoryComponent;
  @ViewChild('documentsManager') documentsManager;

  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  public tabChanged: boolean;
  svcOrderServices: any;
  selection: any;
  svcOrderHeaderId: number
  svcOrderHeader: any;
  documentQuery = new Query({});


  DOCUMENT_INFO = {
    FOLDER: 'service-orders',
    SUBFOLDER: '',
    PARENT_TYPE: this.SYSTEM_MODULE.SERVICE_ORDERS,
    ENTITY_TYPE: this.SYSTEM_MODULE.SERVICE_ORDERS
  };

  constructor(public route: ActivatedRoute,
              public router: Router,
              public svcOrderServiceGridService: SvcOrderServiceGridService,
              public documentService: DocumentService,
              public settingsService: UserSettingsService,
              public documentGridService: DocumentGridService,


  ) {
    super(new PageContext({
      name: 'app.svc-oder.svc-order-details-attachments',
      settings: settingsService
    }))
  }

  ngOnInit(): void {
     this.svcOrderHeader = this.getSvcOrder()
     this.svcOrderHeaderId = this.svcOrderHeader.id
  }

  private getSvcOrder() {
    return this.route.parent.snapshot.data.svc_order;
  }

  docSelected(doc: Document) {
    this.selection = doc;
  }

  onPageDocumentChange(query:Query){
    this.documentQuery = query
    this.documentsManager.fetchDocsForMaster(this.documentQuery)
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/contract']);
    }
  }

  deleteDocument() {
    this.documentsManager.deleteEntityDocument(this.selection,this.documentQuery);
  }
  upload() {
    this.documentsManager.addEntityDocument(this.documentQuery);
  }
  download() {
    this.documentService.downloadDocument(this.selection.id, this.DOCUMENT_INFO.FOLDER)
      .pipe(map(response => this.extractBlob(response)),
        catchError(error => observableThrowError(error)))
      .subscribe((response: Blob) => {
        const name = this.selection.file_name;
        this.documentService.initiateDownload(name, response);
      });
  }

  public extractBlob(data) {
    let blob = new Blob([data], {type: this.selection.type});
    return blob || {};
  }


}
