import { Injectable } from '@angular/core';
import { FilterService } from '../../core/filter/filter.service';
import Query from "../../core/query/query";
import { CustomerLookupProvider } from "../../customer/core/customer-lookup.provider";
import { CustomerService } from "../../customer/core/customer.service";
import { DictionaryService } from "../../dictionary/core/dictionary.service";
import { LookupModelLookupProvider } from "../../dictionary/core/lookup-model-lookup.provider";
import { LOOKUP_MODELS_ENUM } from "../../dictionary/core/lookup-models.enum";
import { LocationService } from "../../location/core/location.service";
import { SubdivisionLookupProvider } from "../../location/core/subdivision-lookup.provider";
import { UserSettingsService } from "../../user/core/user-settings.service";
import { customerInventoryFilterContext } from './customer-inventory-filter.context';
import { DefaultDateRangesLookupProvider } from 'app/core/filter/default-date-ranges-lookup.provider';
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {Observable} from "rxjs";
import {map} from "rxjs";
import {CurrencyLookupProvider} from "../../location/core/currency-lookup.provider";
import {SiteLookupProvider} from "../../site/core/site-lookup.provider";
import {SiteService} from "../../site/core/site.service";


export class BandwidthModelLookupProvider extends IFilterLookupProvider {

  lookupModel: string = LOOKUP_MODELS_ENUM.INVENTORY_BW_MODEL.modelName;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  public entries : any;

  findAll(searchQuery?): Observable<any> {
    return this.dictionaryService.getByLookup(this.lookupModel).pipe(
      map((result: any) => {
        let lookupOptions = result.items.map((entry) => {
          return {
            key: entry.id,
            value: entry.value
          };
        });
        this.entries = lookupOptions;
        return this.entries;
      }));
  }
}

@Injectable()
export class CustomerInventoryFilterService extends FilterService {
  customRanges: any = [
    {
      'label': 'Last 2 years'
    },
    {
      'label': 'Last 6 months'
    }
  ];
  excludeRanges: any = [
    {
      'label': 'Prior month'
    },
    {
      'label': 'Last week'
    },
  ];

  constructor(
    public locationService: LocationService,
    public userSettingsService: UserSettingsService,
    public dictionaryService: DictionaryService,
    public customerService: CustomerService,
    public siteService: SiteService,
  ) {
    super("app.customer-inventory.customer-inventory-filter", customerInventoryFilterContext, userSettingsService);

    this.addLookupProvider('subdivision', new SubdivisionLookupProvider(locationService, true));
    this.addLookupProvider('customer', new CustomerLookupProvider(customerService));
    this.addLookupProvider('bandwidth', new LookupModelLookupProvider(dictionaryService, LOOKUP_MODELS_ENUM.BANDWIDTH.modelName, true, true, new Query({limit: 1000})));
    this.addLookupProvider('topology', new LookupModelLookupProvider(dictionaryService,
      LOOKUP_MODELS_ENUM.INVENTORY_TOPOLOGY.modelName, true));
    this.addLookupProvider('defaultDateRanges', new DefaultDateRangesLookupProvider(this.customRanges, this.excludeRanges, true));

    this.addLookupProvider('bandwidthModel', new BandwidthModelLookupProvider(dictionaryService));
    this.addLookupProvider(
      'status',
      new LookupModelLookupProvider(
        dictionaryService,
        LOOKUP_MODELS_ENUM.INVENTORY_STATUS.modelName, true
      )
    );
    this.addLookupProvider('currency',new CurrencyLookupProvider(locationService))
    this.addLookupProvider('site', new SiteLookupProvider(siteService));
  }
}
