import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Bandwidth} from "./bandwidth";

@Injectable()
export class BandwidthService extends BaseService<Bandwidth> {

  constructor() {
    super('bandwidth');
  }

  findAllTypes(query?) {
    return this.httpService().get(['bandwidth', 'types'], this.toFilter(query));
  }

  findAllByType(type: any) {
    return this.httpService().get(['bandwidth', 'types', type]);
  }

}
