import { Injectable } from '@angular/core';
import { FormatService } from '../../shared/format/format.service';
import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { AuditsService } from 'app/audit/core/audits.service';

@Injectable()
export class InvoiceChargeProfileBrowserGridService extends GridService {
	public chargeProfileAuditRuleType: any = [];
	public defaultColumns = [];

	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService,
		public auditService: AuditsService
	) {
		super('app.invoice.charge-profile-browser', userSettings);
	}

	getChargeColumns() {
		this.defaultColumns = [
			{
				caption: '',
				headerCellTemplate: 'selectionBadgeHeaderTemplate',
				cellTemplate: 'selectionBadgeCellTemplate',
				fixed: true,
				width: 40,
				allowSorting: false,
				index: 1
			},
			{
				caption: 'Vendor',
				dataField: 'vendor.name',
				dataType: 'string',
				allowSorting: false,
				width: 200,
				index: 2
			},
			{
				caption: 'Account',
				dataField: 'ban',
				dataType: 'string',
				allowSorting: true,
				width: 150,
				index: 3
			},
			{
				caption: 'SPID',
				dataField: 'spid',
				dataType: 'string',
				allowSorting: true,
				width: 200,
				index: 4
			},
			{
				caption: 'Charge Type',
				dataField: 'charge_type',
				dataType: 'number',
				width: 120,
				allowSorting: true,
				alignment: 'right',
				index: 4
			},
			{
				caption: 'Charge Amount',
				dataField: 'charge_amount',
				dataType: 'currency',
				cellTemplate: 'currencyTemplate',
				width: 120,
				allowSorting: true,
				index: 4
			},
			{
				caption: 'Charge Description',
				dataField: 'charge_description',
				dataType: 'string',
				width: 200,
				allowSorting: true,
				index: 4
			},
			{
				caption: 'Audit Rule Type',
				dataField: 'audit_rule_type',
				lookup: {
					dataSource: this.chargeProfileAuditRuleType,
					valueExpr: 'id',
					displayExpr: 'name'
				},
				width: 200,
				allowSorting: true,
				index: 5
			},
			{
				caption: 'Expected Min Value',
				dataField: 'audit_rule_expected_min_value',
				dataType: 'currency',
				// cellTemplate: "currencyTemplate",
				width: 200,
				allowSorting: true,
				index: 6
			},
			{
				caption: 'Expected Max Value',
				dataField: 'audit_rule_expected_max_value',
				dataType: 'currency',
				// cellTemplate: "currencyTemplate",
				width: 200,
				allowSorting: true,
				index: 7
			},
			{
				caption: 'Is Active',
				dataField: 'is_active',
				dataType: 'boolean',
				cellTemplate: 'booleanTemplate',
				transformValue: {
					true: 'Active',
					false: 'Inactive'
				},
				width: 150,
				index: 8
			},
			{
				caption: 'Created By',
				dataField: 'created_by_user.username',
				dataType: 'string',
				alignment: 'left',
				width: 150,
				index: 9
			},
			{
				caption: 'Create Date',
				dataField: 'created_date',
				dataType: 'date',
				cellTemplate: 'dateTemplate',
				alignment: 'left',
				width: 150,
				index: 10
			},
			{
				caption: 'Last Updated By',
				dataField: 'updated_by_user.username',
				dataType: 'string',
				alignment: 'left',
				width: 150,
				index: 11
			},
			{
				caption: 'Last Updated Date',
				dataField: 'last_updated_date',
				dataType: 'date',
				cellTemplate: 'dateTemplate',
				alignment: 'left',
				width: 200,
				index: 12
			}
		];

		return this.columns();
	}
}
