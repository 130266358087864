import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { Customer } from './customer';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';
import Query from '../..//core/query/query';

@Injectable()
export class CustomerService extends BaseService<Customer> {
  constructor(
    public entityLock: EntityLockService
  ) {
    super('customers', entityLock);
  }

  findAllList(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'list'], this.toFilter(transformedQuery));
  }
  findCustomersOnly(query?: Query): any {
    let concreteQuery = query || new Query();
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'filtered'], this.toFilter(transformedQuery));
  }
}
