import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../core/base.service';

class Notification {
  id: number
}

@Injectable()
export class NotificationService extends BaseService<Notification> {
  private url = environment.BASE_API_URL;
  private socket;

  constructor() {
    super('notifications', null);
  }

  read(notificationId, isRead) {
    this.httpService().post([this.name, notificationId, 'read'], {is_read: isRead})
  }

  readAll(isRead = true) {
    this.httpService().post([this.name, 'read/all'], {is_read: isRead});
  }

  archive(notificationId: number) {
    this.httpService().post([this.name, notificationId, 'archive'])
  }

  archiveMany(list: Array<number>) {
    this.httpService().post([this.name, 'archive/all'], {list: list});
  }

  sendNotification(notification) {
    this.socket.emit('notification', notification);
  }

  listen() {
    return new Observable(observer => {
      this.socket = io(this.url);

      this.socket.on('notification', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }

  listenLocked() {
    return new Observable(observer => {
      this.socket = io(this.url);

      this.socket.on('locked-invoice-notification', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }
}
