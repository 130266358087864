import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { ContractInstance } from './contract-instance';
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";

@Injectable()
export class ContractInstanceService extends BaseService<ContractInstance> {
  section: string = 'section';

  constructor(private entityLock: EntityLockService) {
    super('contract-instance', entityLock);
  }

  
}
