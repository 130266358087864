import {Injectable} from "@angular/core";
import {BaseService} from "../../core/base.service";
import {ChargesCallCost} from "./charges-call-cost.model";

@Injectable()
export class ChargesCallCostService extends BaseService<ChargesCallCost> {
  constructor() {
    super('charges_call_cost');
  }

  getDistinctVendors() {
    return this.httpService().get([this.name, 'getDistinctVendors'])
  }

  getCallCostReport(query) {
    return this.httpService().get([this.name, 'getCallCostReport'], this.toFilter(query));
  }
}
