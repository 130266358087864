import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseService} from "../../core/base.service";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {Intuit} from "./intuit";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class IntuitService extends BaseService<Intuit> {

  constructor(
    private entityLock: EntityLockService,
    public http: HttpClient,
  ) {
    super('intuit', entityLock);
  }

  destroyToken() {
    return this.httpService().delete([this.name, 'destroy-token']);
  }

  authorize() {
    const endPoint: string = [this.name, '/auth'].join('');
    return this.http.get(`${environment.API_URL}${endPoint}`);
  }

  sendAuthParamsAndGenerateToken(url: any) {
    return this.httpService().post([this.name, 'generate-token'], {url});
  }

  validateToken(): Observable<any> {
    return this.httpService().post([this.name, 'validate-token']);
  }

  checkIntuitBillStatus(id: number): any {
    return this.httpService().get([this.name, 'check-bill-status'], id);
  }
}
