import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import Query from "../../query/query";
import { RangeFilterBaseComponent } from "../range-filter-base/range-filter-base.component";

@Component({
  selector: "number-range-negative-filter-input",
  templateUrl: "./range-negative-filter.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeNegativeFilterInputComponent),
      multi: true
    }
  ]
})
export class RangeNegativeFilterInputComponent extends RangeFilterBaseComponent
  implements OnInit, OnChanges {
  @Input("query")
  query: Query;
  @Input("entry")
  entry: any;
  @Output()
  numbersDidNotPassValidation: EventEmitter<boolean> = new EventEmitter();

  internalNumberQuery: any = {
    from: null,
    to: null
  };

  constructor() {
    super(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["query"]) {
      let query = changes["query"].currentValue;
      query["where"] = query["where"] || {};

      this.internalNumberQuery = {
        from: null,
        to: null
      };

      this.resetErrors();

      this.numbersDidNotPassValidation.emit(false);
    }
  }

  ngOnInit() {}

  ngOnNumberChange(event, entry, focus: string) {
    let from = null;
    let to = null;
    this.internalNumberQuery['max'] =  entry ? entry.max : null;
    let max = this.internalNumberQuery['max'];

    if (
      this.internalNumberQuery["from"] ||
      this.internalNumberQuery["from"] === 0
    ) {
      from = +this.internalNumberQuery["from"];
    }

    if (
      this.internalNumberQuery["to"] ||
      this.internalNumberQuery["to"] === 0
    ) {
      to = +this.internalNumberQuery["to"];
    }

    // Validate FROM or TO field
    focus === this.FOCUS.TO
      ? this.validateToField(from, to, max)
      : this.validateFromField(to, from, max);

    this.numbersDidNotPassValidation.emit(
      this.fromNumberError || this.toNumberError
    );

    if (this.query && from !== null && to !== null) {
        this.query.where[entry["field"]] = { $between: [from, to] };
      } else if (from !== null) {
        this.query.where[entry["field"]] = { $gte: from };
      } else if (to !== null) {
        this.query.where[entry["field"]] = { $lte: to };
      } else {
        delete this.query.where[entry["field"]];
      }
    }

  onKeyDownArrowUp() {
    if (
      this.internalNumberQuery["from"] &&
      this.internalNumberQuery["to"] === null
    ) {
      this.internalNumberQuery["to"] = this.internalNumberQuery["from"];
    }
    if (
      this.internalNumberQuery["to"] &&
      this.internalNumberQuery["from"] === null
    ) {
      this.internalNumberQuery["from"] = this.internalNumberQuery["to"];
    }
  }

  onKeyDownArrowDown() {
    if (
      this.internalNumberQuery["to"] &&
      this.internalNumberQuery["from"] === null
    ) {
      this.internalNumberQuery["from"] = this.internalNumberQuery["to"];
    }
    if (
      this.internalNumberQuery["from"] &&
      this.internalNumberQuery["to"] === null
    ) {
      this.internalNumberQuery["to"] = this.internalNumberQuery["from"];
    }
  }
}
