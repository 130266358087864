import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Inventory } from 'app/inventory/core/inventory';
import {Pricing} from "../../core/pricing";
import {ActivatedRoute} from "@angular/router";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {INVENTORY_STATUS_ENUM} from '../../core/inventory-status.enum';
import {OrderManageDialogComponent} from "../../../order/shared/order-manage-dialog/order-manage-dialog.component";
import {DialogService} from "../../../shared/dialog/dialog.service";

@Component({
  selector: 'app-inventory-details-toolbar',
  templateUrl: './inventory-details-toolbar.component.html',
  styleUrls: ['./inventory-details-toolbar.component.css']
})
export class InventoryDetailsToolbarComponent implements OnInit {

  readonly PRICING_MANAGER_SETTINGS = {
    DELETE_PRICING_ACTION: this.invokeDeletePricing.bind(this),
    UPLOAD_PRICING_ACTION: this.addPricing.bind(this),
    DOWNLOAD_PRICING_ACTION: this.invokeDownloadPricing.bind(this)
  }

  readonly INVENTORY_STATUS_ENUM = INVENTORY_STATUS_ENUM;

  readonly DOCUMENT_MANAGER_SETTINGS = {
    DELETE_DOCUMENT_ACTION: this.deleteDocument.bind(this),
    UPLOAD_DOCUMENT_ACTION: this.upload.bind(this),
    DOWNLOAD_DOCUMENT_ACTION: this.download.bind(this)
  }

  @Input() inventory: Inventory;
  @Input() isEditInventoryDisabled: boolean = true;
  @Input() isEditInventoryHidden: boolean = false;
  @Input() isEditChargeProfileDisabled: boolean = true;
  @Input() isEditChargeProfileHidden: boolean = true;
  @Input() isCreateOrderHidden: boolean = false;
  @Input() isAddGLDisabled: boolean = true;
  @Input() isAddGLHidden: boolean = false;
  @Input() isAddContractTermsDisabled: boolean = false;
  @Input() isEditContractTermsDisabled: boolean = true;
  @Input() isDeleteContractTermsDisabled: boolean = true;
  @Input() isContractTermsHidden: boolean = true;
  @Input() isAddPricingDisabled: boolean = true;
  @Input() isAddPricingHidden: boolean = false;
  @Input() pricingSelection: Pricing;
  @Input() documentSelection: Document;
  @Input() isPricingDocumentAction: boolean = false;
  @Input() isDocumentAction: boolean = false;
  @Input() isCreateOrderDisabled: boolean = true;
  @Input() isFilterOn: boolean = false;
  @Input() isFilterDisabled: boolean = true;
  @Input() isNotesOn: boolean = false;
  @Input() notesCount: number = 0;
  @Input() isHistoryOn: boolean = false;
  @Input() isCSVDisabled = true;
  @Input() isCSVHidden = false;
  @Input() isSettingsDisabled = true;
  @Input() isSettingsHidden = true;
  @Input() isNotesDisabled = false;
  @Input() isHistoryDisabled = false;
  @Input() isChargeProfileTab = false;
  @Input() isSaveDisabled = false;

  @Output() backRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() addGLRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() editInventoryRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() createOrderRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleFilterRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleNotesRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleHistoryRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() addPricingRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() invokeDeletePricingRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() invokeDownloadPricingRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteDocumentRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadCSVRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() createContractTermRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() editContractTermRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteContractTermRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() editChargeProfileStatusRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridSettingsRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() chargeProfileSaveRequested: EventEmitter<any> = new EventEmitter<any>();

  
  adjustmentsKeys = Object.keys(LOOKUP_ENUM.ORDER_TYPE).filter(
    item => item !== 'INSTALL'
  );
  constructor(public route: ActivatedRoute,
              public dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
  }

  back() {
    this.backRequested.emit();
  }

  addGL() {
    this.addGLRequested.emit();
  }

  edit() {
    this.editInventoryRequested.emit();
  }

  createOrder(type) {
    this.createOrderRequested.emit(type);

    this.dialogService.open(OrderManageDialogComponent, {
      order: {},
      type: type,
      inventories: [this.inventory]
    }, {width: '1200px'})
  }

  toggleFilter() {
    this.toggleFilterRequested.emit();
  }

  toggleNotes() {
    this.toggleNotesRequested.emit();
  }

  toggleHistory() {
    this.toggleHistoryRequested.emit();
  }


  addPricing() {
    this.addPricingRequested.emit();
  }

  invokeDeletePricing() {
    this.invokeDeletePricingRequested.emit();
  }

  invokeDownloadPricing() {
    this.invokeDownloadPricingRequested.emit();
  }

  deleteDocument() {
    this.deleteDocumentRequested.emit();
  }

  upload() {
    this.uploadRequested.emit();
  }

  download() {
    this.downloadRequested.emit();
  }

  csv() {
    this.downloadCSVRequested.emit();
  }

  addContractTerm() {
    this.createContractTermRequested.emit();
  }

  editContractTerm() {
    this.editContractTermRequested.emit();
  }

  deleteContractTerm() {
    this.deleteContractTermRequested.emit();
  }

  isCreateOrderEnabled() {
    if (this.inventory) {
      const vendor = this.inventory['vendor_id'];
      const currency = this.inventory['currency_id'];

      if (!vendor || !currency) {
        return false
      }
      return vendor === this.inventory['vendor_id'] && this.inventory['status_id'] === this.INVENTORY_STATUS_ENUM.ACTIVE && currency === this.inventory['currency_id']
    }
    return false;
  }

  editChargeProfileStatus() {
    this.editChargeProfileStatusRequested.emit();
  }

  gridSettings() {
    this.gridSettingsRequested.emit();
  }

  saveChargeProfile() {
    this.chargeProfileSaveRequested.emit();
  }
}
