
import {of as observableOf } from 'rxjs';
import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TabGroup, TabService } from '../../../shared/tabs/tab.service';


import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { ConfigService } from '../../../core/config/config.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { Order } from '../../core/order';
import { OrderService } from '../../core/order.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { EntityEditContext } from '../../../shared/entity-lock/entity-edit-context';
import { ORDER_STATUS } from '../../core/order-status.enum';
import { ORDER_TYPE } from '../../core/order-type.enum';
import { OrderService as OrderServiceModel } from '../../../order-service/core/order-service';
import { ORDER_SERVICE_STATUS } from '../../../order-service/core/order-service-status.enum';
import { ORDER_SERVICE_DISCONNECT_STATUS } from '../../../order-service/core/order-service-disconnect-status.enum';

import {Contacts} from "../../core/contact";
import {ContactFormService} from "../../core/contact-form.service";
import * as _ from "lodash";
import { OrderServiceService } from 'app/order-service/core/order-service.service';
import { InventoryService } from 'app/inventory/core/inventory.service';
import { Router } from '@angular/router';
import {flatMap} from "rxjs";

@Component({
  selector: 'app-order-manage-dialog',
  templateUrl: './order-manage-dialog.component.html',
  styleUrls: ['./order-manage-dialog.component.css']
})
export class OrderManageDialogComponent extends PageManageDialogComponent implements OnInit {
  order: any;
  type:Object;
  formTitle: string;
  labels: Array<any> = [];
  contacts: Contacts;
  readonly ORDER_TYPE_ENUM = ORDER_TYPE;

  inventories: any

  public fields;
  public categories = [];
  private prepared_fields: {};
  showCategory:boolean = false;

  @ViewChild('currencyPicker') currencyPicker;

  selectedCurrency: any;
  messages: IMessagesResourceService;

  readonly MESSAGES_MODULE: string = 'order';
  readonly CONTACT_TYPE_ENUM: any = LOOKUP_ENUM.CONTACT_TYPE;
  readonly CONTACT_FUNCTION_ENUM: any = LOOKUP_ENUM.CONTACT_FUNCTION;
  readonly ORDER_STATUS_ENUM = ORDER_STATUS;
  readonly ORDER_SERVICE_STATUS_ENUM = ORDER_SERVICE_STATUS
  readonly ORDER_SERVICE_DISCONNECT_STATUS_ENUM = ORDER_SERVICE_DISCONNECT_STATUS;

  public tabGroup: TabGroup;
  public tabChanged: boolean;
  public activeTabIndex = 0;

  constructor(
    public alert: AlertService,
    public formBuild: FormBuilder,
    public tabService: TabService,
    public orderService: OrderService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<OrderManageDialogComponent>,
    public contactFormService: ContactFormService,
    public configService: ConfigService,
    public orderServiceService: OrderServiceService,
    public inventoryService: InventoryService,
    public router: Router,

  ) {
    super(dialogService, dialogRef);
    this.order = (this.order) ? this.order : new Order();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  get orderAlreadyAccepted() {
    return this.order.status_id >= this.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED
  }

  get isInventory() {
    return this.inventories
  }

  ngOnInit() {
    if (this.inventories) {
      this.order.vendor_id = this.inventories[0].vendor_id
      this.order.currency_id = this.inventories[0].currency_id
      this.order.vendor = this.inventories[0].vendor
    }

    this.isUpdate = !!this.order.id;

    if (this.isUpdate)
      this.selectedCurrency = this.order.currency_obj ? this.order.currency_obj.currency : null

    this.tabGroup = this.tabService.create();

    let controlsConfig = {
      vendor_id: new FormControl({
        value: this.order.vendor_id || null,
        disabled: (this.isInventory || this.orderAlreadyAccepted) ? true : false
      }, Validators.required),
      request_date: new FormControl({
        value: (this.order && this.order.request_date) ? new Date(this.order.request_date) : null,
        disabled: this.orderAlreadyAccepted
      }, Validators.required),
      total_est_nrc: new FormControl(this.order.total_est_nrc),
      total_est_mrc: new FormControl(this.order.total_est_mrc),
      project_id: new FormControl(this.order.project_id),
      justification_description: new FormControl(this.order.justification_description),
      additional_info: new FormControl(this.order.additional_info),
      // currency_id: new FormControl(this.order.currency_id),
      currency_id: new FormControl({
        value: this.order.currency_id,
        disabled: this.isInventory
      }),
      contacts: new FormControl('', Validators.required)
    }

    this.configService.loadCustomFields()
      .subscribe(config => {
        this.fields = config.custom_values.order_header;
        let fieldsWithCategory = [];
        let cus_val = this.order.custom_values && this.isUpdate ? this.order.custom_values : {};
        for (let i = 0; i < this.fields.length; i++) {
          if(this.fields[i].category && this.fields[i].category.length > 0){
            fieldsWithCategory = [...fieldsWithCategory, this.fields[i].category];
          }
          if (!this.categories.includes(this.fields[i].category)) {
            this.categories.push(this.fields[i].category)
          }
          this.fields[i].value = cus_val[this.fields[i].field_name]
          this.form.addControl(this.fields[i].field_name, new FormControl(this.fields[i].value));
        }
        if(fieldsWithCategory.length === this.fields.length){
          this.showCategory= true;
        }
      });

    this.form = this.formBuild.group(controlsConfig);
    if (this.inventories) {
      this.form.patchValue({
        vendor_id: this.order.vendor_id,
        currency_id: this.order.currency_id
      })
    }
    this.formTitle = this.isUpdate ? 'Edit Order No: ' + this.order.full_order_id.toUpperCase() : `Create ${this.type} Order`;
    this.initializeLabels();
    this.loadTabs();
    this.afterInit();
  }

  // Workaround for angular component issue #13870
  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  init() {
    if (this.order && this.order.id) {
      return this.orderService.findByIdForEdit(this.order.id, new EntityEditContext({
        dialogRef: this.dialogRef
      })).pipe(
        flatMap((order) => {
          this.order = order;
          return observableOf(order);
        }))
    }
    return observableOf(this.order);
  }
  onTabChange(index) {
    let tab = this.tabGroup.tabs[index];
    if (!tab.disabled) {
      this.activeTabIndex = index;
      this.tabGroup.activate(tab.key);
    }
  }

  onSubmit({ value, valid }: { value: Order, valid: boolean }): void {
    if (valid) {
      this.prepared_fields = {}
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].field_name in value) {
          this.prepared_fields[this.fields[i].field_name] = value[this.fields[i].field_name];
        }
      }
      value.custom_values = this.prepared_fields;
      if (this.order.id) {
        this.updateOrder(value);
      } else {
        value = Object.assign(value, this.order)
        this.createOrder(value);
      }
    } else {
      this.alert.error('', this.messages.get('FORM_INVALID'));
    }
  }

  createOrder(order: Order) {
    let typeOfOrderId;
    for (const property in this.ORDER_TYPE_ENUM){
      if(property === this.type){
        typeOfOrderId = this.ORDER_TYPE_ENUM[property]
      }
    }
    order.total_est_mrc = this.unmask(order.total_est_mrc);
    order.total_est_nrc = this.unmask(order.total_est_nrc);
    order.type_id = typeOfOrderId;
    order.status_id = ORDER_STATUS.NEW;
    if (this.inventories && order.vendor) {
      delete order.vendor
    }

    this.toggleDialogButtons();
    this.orderService.create(order)
      .subscribe(
        (result) => {
          if (this.inventories) {
            this.createOrderServices(typeOfOrderId, result)
          } else {
            this.closeDialog(result, true);
          }
        },
        (error) => {
          console.log('error', error)
          this.toggleDialogButtons(false);
          this.alert.error('', this.messages.get('UPDATE_ERROR'));
        }
      );
  }

  async createOrderServices(orderType, order) {
    let total = this.inventories.length;
    let current = 0;
    this.inventories.forEach((inventory, index) => {
      let service = new OrderServiceModel();
      service.inventory_id = inventory.id;
      service.order_id = this.order.id = order.id;
      if (this.ORDER_TYPE_ENUM.DISCONNECT === orderType) {
        service.disconnect_state = this.ORDER_SERVICE_DISCONNECT_STATUS_ENUM.NEW_PENDING
      }
      service.state = this.ORDER_SERVICE_STATUS_ENUM.NEW_PENDING
      service.des_due_date = inventory.des_due_date;
      service.description = inventory.description;
      service.expedited_order = inventory.expedited_order;
      service.service_id = (index + 1);
      service.ban = inventory.ban

      this.orderServiceService.createOnlyService(service)
        .subscribe((result) => {
          current++;
          if (total === current) {
            this.updateInventory(order.id, orderType);
          } else this.closeDialog(null, true);
        }, (error) => {
          console.log('error', error)
          this.alert.error('', this.messages.get('CREATE_ERROR'));
        });
    })
  }

  public updateInventory(orderId, orderType) {
    let total = this.inventories.length;
    let current = 0;
    this.inventories.forEach((inventory) => { /////
      if (this.ORDER_TYPE_ENUM.DISCONNECT === orderType) {
        inventory.status_id = LOOKUP_ENUM.INVENTORY_STATUS.PENDING_DISCONNECT;
      } else {
        inventory.status_id = LOOKUP_ENUM.INVENTORY_STATUS.IN_PROCESS;
      }
      this.inventoryService.update(inventory.id, inventory)
        .subscribe(() => {
            current++;
            if (total === current) {
              this.alert.success('', this.messages.get('CREATE_SUCCESS'));
              this.closeDialog({}, true);
              this.router.navigateByUrl(`/order/${orderId}/show`, { state: { services: true } });
            }
          }, (error) => {
            this.toggleDialogButtons(false);
            const message = error.status = 400 && error.data === 'Duplicate unique id' ? 'DUPLICATE_UNIQUE_ID' : 'UPDATE_ERROR';
            this.alert.error('', this.messages.get(message));
          });
    });
  }

  updateOrder(order: Order) {
    order.total_est_mrc = this.unmask(order.total_est_mrc);
    order.total_est_nrc = this.unmask(order.total_est_nrc);
    order.request_date = order.request_date
    this.toggleDialogButtons();
    this.orderService.update(this.order.id, order)
      .subscribe(
        (result) => {
          this.closeDialog(result, true);
        },
        (error) => {
          this.toggleDialogButtons(false);
          this.alert.error('', this.messages.get('UPDATE_ERROR'));
        }
      );
  }

  deleteOrder(event) {
    const { services, status_id } = this.order;

    const stateAllowsDeletion = (
      (this.order.type_id != this.ORDER_TYPE_ENUM.RECORD_ONLY) && (status_id === this.ORDER_STATUS_ENUM.NEW || status_id === this.ORDER_STATUS_ENUM.NEW_RESET || status_id === this.ORDER_STATUS_ENUM.NEW_REJECTED || status_id === this.ORDER_STATUS_ENUM.RFA)) ||
      (this.order.type_id == this.ORDER_TYPE_ENUM.RECORD_ONLY && (status_id === this.ORDER_STATUS_ENUM.NEW || status_id === this.ORDER_STATUS_ENUM.NEW_RESET || status_id === this.ORDER_STATUS_ENUM.NEW_REJECTED || status_id === this.ORDER_STATUS_ENUM.RFA || status_id === this.ORDER_STATUS_ENUM.APPROVED))

    const canBeDeleted = (services && !services.length && stateAllowsDeletion) ? true : false

    const alertCantBeDeleted = (activeServices = false) => {
      setTimeout(() => {
        if (activeServices)
          this.alert.error('', this.messages.get('ORDER_DELETE_NOT_ALLOWED_ACTIVE_SERVICES'))
        else
          this.alert.error('', this.messages.get('ORDER_DELETE_NOT_ALLOWED_PASSED_RFA'))
      }, 500);
    }
    const proceedWithDeletion = () => {
      if (this.order && this.order.id && event) {
        this.toggleDialogButtons();
        this.orderService.delete(this.order.id)
          .subscribe((deleteResult) => {
            if (deleteResult) {
              this.dialogRef.close({ deleted: true });
            }
          }, (err) => {
            this.toggleDialogButtons(false);
            this.alert.error('', this.messages.get('DELETE_ERROR'));
          });
      }
    }

    ///
    // canBeDeleted ? proceedWithDeletion() : alertCantBeDeleted();
    canBeDeleted ? proceedWithDeletion() : !stateAllowsDeletion ? alertCantBeDeleted() : alertCantBeDeleted(true)
  }

  loadTabs() {
    this.tabGroup.addTab({key: 1, title: 'Summary'});
    this.tabGroup.addTab({key: 2, title: 'Contacts'});

    this.tabGroup.activate(1);

    this.tabGroup.onActivate.subscribe((tab) => {
      setTimeout(() => {
        this.tabChanged = !this.tabChanged;

      });
    });
  }

  showLabel(date: string) {
    return this.labels.indexOf(date) > -1
  }

  // TODO optimize this
  public toggleLabel(date: string) {
    if (this.form.controls[date].value) {
      if (this.labels.indexOf(date) === -1) {
        this.labels.push(date);
      }
    } else {
      if (this.labels.indexOf(date) === -1) {
        this.labels.push(date);
      } else {
        const index = this.labels.indexOf(date);
        this.labels.splice(index, 1);
      }
    }
  }

  public initializeLabels() {
    let dates = ['request_date'];
    dates.forEach((item) => {
      if (this.form.controls[item].value) {
        this.labels.push(item);
      }
    });
  }

  public unmask(value) {
    if (value) {
      // http://stackoverflow.com/questions/559112
      return Number(value.replace(/[^0-9\.]+/g, ''));
    }
  }

  cancel() {
    this.orderService.cancelEdit();
    this.closeDialog();
  }

  onCurrencyChange(event) {
    this.selectedCurrency = event.selection.currency;
  }

  onContactsChanged(event: any) {
    this.form.controls['contacts'].setValue(event);
  }
}
