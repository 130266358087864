import { Injectable } from '@angular/core';
import { GridService } from '../../../../shared/grid/grid.service';
import { FormatService } from '../../../../shared/format/format.service';
import { UserSettingsService } from '../../../../user/core/user-settings.service';

@Injectable()
export class InvoiceAlertCsvService extends GridService {
	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.common.invoice-alerts-csv', userSettings);
	}

	defaultColumns = [
		{
			caption: 'Type',
			dataField: 'type.value',
			visible: true,
			width: 120
		},
		{
			caption: 'Category',
			dataField: 'category.value',
			visible: true,
			width: 120
		},
		{
			caption: 'Create Time',
			dataField: 'created_at',
			dataType: 'datetime',
			cellTemplate: 'dateTimeTemplate',
			alignment: 'left',
			visible: true,
			width: 160
		},
		{
			caption: 'Created by',
			dataField: 'creator',
			visible: true,
			width: 130
		},
		{
			caption: 'Rule Name',
			dataField: 'rule_name',
			visible: true,
			width: 130
		},
		{
			caption: 'Message',
			dataField: 'message',
			visible: true,
			width: 220
		},
		{
			caption: 'Priority',
			dataField: 'priority.value',
			visible: true,
			width: 120
		},
		{
			caption: 'Owner',
			dataField: 'owner',
			visible: true,
			width: 120
		},
		{
			caption: 'Last Modification Time',
			dataField: 'last_modified_at',
			dataType: 'datetime',
			cellTemplate: 'dateTimeTemplate',
			alignment: 'left',
			visible: true,
			width: 200
		},
		{
			caption: 'Status',
			dataField: 'status.value',
			visible: true,
			width: 120
		},
		{
			caption: 'Closed Time',
			dataField: 'closed_at',
			dataType: 'datetime',
			cellTemplate: 'dateTimeTemplate',
			alignment: 'left',
			visible: true,
			width: 140
		},
		{
			caption: 'Run ID',
			dataField: 'run_id',
			visible: true,
			width: 120
		}
	];
}
