import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import {debounceTime, takeUntil} from 'rxjs';
import Query from '../../query/query';
import { FilterEntry } from '../filter-entry';
import { BuildingFilterService } from '../../../building/core/building-filter.service';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'ca-address-filter',
  templateUrl: 'address-filter.component.html',
  styleUrls: ['./address-filter.component.scss']
})

export class AddressFilterComponent implements OnInit, OnChanges {
  @Output() queryChanged: EventEmitter<Query> = new EventEmitter();
  @Input('entry') entry: FilterEntry;
  @Input('query') query: Query;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('picker') picker;

  searchFormControl = new FormControl();
  selectFormControl = new FormControl(null);

  internalStringQuery;

  internalAddressQuery: any = {
    address1: '',
    city: '',
    subdivision: null,
    postal_code: ''
  };

  readonly ADDRESS_FIELDS = {
    ADDRESS1: 'address1',
    CITY: 'city',
    SUBDIVISION: 'subdivision',
    POSTAL_CODE: 'postal_code'
  };

  constructor(public buildingFilterService: BuildingFilterService) { }

  ngOnInit() {
    this.searchFormControl.valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.destroy$),)
      .subscribe(
        value => {
          this.searchSubdivision(value);
        }
      );
  }

  searchSubdivision(value) {
    let myQuery = new Query();
    myQuery.where = {
      $or: [
        {abbreviation: { '$ilike': `%${value}%` }},
        {name: { '$ilike': `%${value}%` }}
      ]
    }
    this.entry.addressFields.subdivision['lookupProvider'].findAll(myQuery)
        .subscribe((result) => {
          this.entry.addressFields.subdivision['lookupItems'] = result;
        });
  }

  openedChange(isOpened) {
    if (isOpened){
      this.searchSubdivision('');
      this.selectFormControl.markAsTouched();
    } else {
      this.searchFormControl.setValue(null, { emitEvent: false });
    }
  }

  clearSubdivision() {
    this.selectFormControl.reset();
    this.picker.close();
    this.internalAddressQuery.subdivision = null;
    delete this.query?.where[this.entry['field']];
    this.queryChanged.emit(this.query)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['query']) {
      this.clearAddress()
    }

    if (changes['entry']) {
      if (!this.entry.addressFields.subdivision.lookupItems) {
        const subdivisionLookupProvider = this.buildingFilterService.getLookupProvider(this.entry.addressFields.subdivision.lookupProviderName).provider;
        subdivisionLookupProvider.findAll()
          .subscribe((result) => {
            this.entry.addressFields.subdivision['lookupProvider'] = subdivisionLookupProvider;
            this.entry.addressFields.subdivision['lookupItems'] = result;
          });
      }
    }
  }

  clearAddress() {
    this.internalAddressQuery = {
      address1: '',
      city: '',
      subdivision: null,
      postal_code: ''
    };
    delete this.query?.where[this.entry['field']];
    this.queryChanged.emit(this.query)
  }

  toggleExact(entry) {
    entry.exact = !entry.exact;
    this.ngOnAddressChange();
  }

  toggleCase(entry) {
    entry.caseSensitive = !entry.caseSensitive;
    this.ngOnAddressChange();
  }

  ngOnStringChange() {
    this.setStringQuery(this.internalStringQuery, this.entry['field'], this.entry.caseSensitive, this.entry.exact);
  }

  ngOnAddressChange() {
    for (let i in this.ADDRESS_FIELDS) {
      let addressField = this.ADDRESS_FIELDS[i];
      let value = this.internalAddressQuery[addressField];
      if (value) {
        if (addressField === this.ADDRESS_FIELDS.SUBDIVISION) {
          this.query.where[this.entry.addressFields[addressField]['field']] = value;
        } else {
          this.setStringQuery(
            value,
            this.entry.addressFields[addressField]['field'],
            this.entry.caseSensitive,
            this.entry.exact
          );
        }
      } else {
        delete this.query?.where[this.entry.addressFields[addressField]['field']];
      }
    }
    this.queryChanged.emit(this.query)
  }

  setStringQuery(value: string, field: string, caseSensitive: boolean, exactMatch) {
    let operator = caseSensitive ? '$like' : '$ilike';
    let suffix = exactMatch ? '' : '%';
    let prefix = exactMatch ? '' : '%';

    if (value) {
      value = value.trim();
      this.query.where[field] = this.query.where[field] || {};
      delete this.query.where[field]['$ilike'];
      delete this.query.where[field]['$like'];
      this.query.where[field][operator] = prefix + value + suffix;
    } else {
      delete this.query?.where[field];
    }
  }
}
