import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {ReportingService} from '../../srm/core/srm.service';
import {path, identity, map} from 'ramda';
import {ConfigService} from "../../core/config/config.service";
import {flatMap} from "rxjs";
import Query from '../../core/query/query';

const filterFactory = (operatorParsers) => (query) => {
  if (!query) {
    return identity;
  }
  const [operator, value] = Object.entries(query)[0];

  return operatorParsers(value, query)[operator] || (() => {
    throw new Error('Not supported operator');
  });
}

const getNameFilter = filterFactory((value) => {
  const filter = (regexFlags?: string) => (report) => {
    const regexString = (value as string).replace(/%/g, '.*')
    const regex = new RegExp(`^${regexString}$`, regexFlags);
    return regex.test(report.display_name);
  }
  return {
    '$like': filter(),
    '$ilike': filter('i')
  };
})


const getTypeFilter = filterFactory((value) => {
  return {
    '$in': (report) => (value as Array<string>).includes(report.report_type)
  };
});

@Injectable()
export class ReportService extends BaseService<any> {
  constructor(private configService: ConfigService,
              public srmReportingService: ReportingService) {
    super('report');
  }


  filterCustomReports(query, customReports) {
    const nameQuery = path(['where', 'display_name'])(query);
    const nameFilter = getNameFilter(nameQuery);
    const typeQuery = path(['where', 'report_type'])(query);
    const typeFilter = getTypeFilter(typeQuery);
    return customReports
      .filter(report => typeFilter ? typeFilter(report) : report)
      .filter(report => nameFilter ? nameFilter(report) : report)
  }


  find(type: string, query: any) {
    return this.httpService().get([this.name, type, 'find'], this.toFilter(query));
  }

  findById(reportId: number) {
    return this.httpService().get([this.name, reportId]);
  }

  execute(query) {
    return this.httpService().post([this.name, 'execute'], query);
  }

  ;

  glSegmentReport(query) {
    return this.httpService().get([this.name, 'gl/segment'], query)
  }

  ;

  chargesByVendorReport() {
    return this.httpService().get([this.name, 'charge/vendor']);
  }

  ;

  ordersByVendorReport() {
    return this.httpService().get([this.name, 'orders/vendor']);
  }

  ;

  disputeAgingReport() {
    return this.httpService().get([this.name, 'disputes/aging']);
  }

  ;

  invoiceTrendReport(accountNo: string, vendorId: number) {
    return this.httpService().get([this.name, 'invoice/trend', accountNo, vendorId]);
  }

  public mapCSVfields(fields) {
    return fields
      .map((column) => {
        return {
          value: column,
          label: column,
        };
      });
  }


  public loadByIdOrKey(idOrKey) {
    if (!isNaN(idOrKey)) {
      return this.srmReportingService.findById(idOrKey);
    } else {
      return this.srmReportingService.findByKey(idOrKey);
    }
  }

  public loadSRM() {
    return this.httpService().get([this.name, 'findSRM']);
  }

  private executeCustomReport(url, displayName) {
    return (query?) => {
      return this.httpService().post([this.name, url], {
        filter: query,
        display_name: displayName
      })
    };
  }

  getEmbedURL(dashboardId) {
    let concreteQuery = new Query();
    concreteQuery.where.dashboardId = dashboardId;
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get([this.name, 'embedURL'], this.toFilter(transformedQuery));
  }

  getEmbedURLForAuthor() {
    return this.httpService().get([this.name, 'embedURLAuthor']);
  }

  getAllDashboards() {
    return this.httpService().get([this.name, 'listDashboards']);
  }

  createReport(report) {
    return this.httpService().post(this.name, report)
  }

  executeMonthlyDetails(query?) {
    return this.executeCustomReport('execute/custom/monthly-details', 'Monthly Details Report')(query);
  }

  executeInvoiceItemCost(query?) {
    return this.executeCustomReport('execute/custom/invoice-item-cost', 'Invoice Item Cost Report')(query);
  }

  executeGlobalSpendTrend(query?) {
    return this.executeCustomReport('execute/custom/global-spend-trend', 'Global Spend Trend Report')(query)
  }

  executeThreshold(query?) {
    return this.executeCustomReport('execute/custom/threshold', 'Treshold Report')(query);
  }

  executeAccrual(query?) {
    return this.executeCustomReport('execute/custom/accrual', 'Accrual Report')(query);
  }

  executeMonthlySpend(query?) {
    return this.executeCustomReport('execute/custom/monthly-spend', 'Monthly Spend Report')(query);
  }

  executeSPIDWithoutGLCodes(query?) {
    return this.executeCustomReport('execute/custom/spid-without-gl-codes', 'SPID without GL codes')(query);
  }

  executeMonthlyInvoiceReceived(query?) {
    return this.executeCustomReport('execute/custom/monthly-invoice-received', 'Montly Invoice Received')(query);
  }

  executeMonthlyInvoiceSpidCost(query?) {
    return this.executeCustomReport('execute/custom/monthly-invoice-spid-cost', 'Montly Invoice Spid Cost')(query);
  }

  executeMonthlyInvoiceProcessingSpid(query?) {
    return this.executeCustomReport('execute/custom/monthly-invoice-processing-spid',
      'Monthly Invoice Processing by SPID')(query);
  }
}
