import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  PICKER_COMPONENT_TEMPLATE,
  PickerComponentBase
} from '../../../shared/picker/ca-picker-base.component';
import {Observable, tap} from 'rxjs';
import {AuditsService} from '../../core/audits.service';

@Component({
  selector: 'ca-audit-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaAuditPickerComponent),
      multi: true
    }
  ]
})
export class CaAuditPickerComponent
  extends PickerComponentBase
  implements OnInit, OnChanges
{
  readonly TEXT_FIELD = 'name';
  readonly KEY_FIELD = 'id';

  @Input('formControlName') formControlName;
  @Input("initialItem") initialItem;
  @Input('pickerForm') pickerForm;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('placeholder') placeholder = 'Select ...';
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('clearEnabled') clearEnabled: boolean;
  @Input('query') query;
  @Input({required: true}) auditTypeId: number;

  @Output() entriesLoaded: EventEmitter<any[]> = new EventEmitter<any[]>();

  public control;

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];
    if (changes.customerID) {
      this.loadData()
    }
  }

  constructor(public auditService: AuditsService) {
    super();
  }

  loadEntries(): Observable<any> {
    return this.auditService.findAllForType(this.auditTypeId, this.query).pipe(tap(response => {
      this.entriesLoaded.emit(response.items)
    }))
  }

  search(value: string): Observable<any> {
    return this.auditService.findAllForType(this.auditTypeId, this.query);
  }
}
