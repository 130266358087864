<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
      <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-details-title">{{ order?.full_order_id }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>
  <button  *permission="'Create'" mat-icon-button (click)="newService()" matTooltip="Add"
           [disabled]="order?.status_id >= ORDER_STATUS_ENUM.RFA || order?.type_id == ORDER_TYPE.DISCONNECT"
  >
      <ca-icon [key]="'ADD_NEW'"></ca-icon>
  </button>
  <button *permission="'Modify'" mat-icon-button matTooltip="Edit"
          [disabled]="selectedOrderSvc === null || isEditDisabled(selectedOrderSvc) || order?.status_id>=ORDER_STATUS_ENUM.COMPLETED"
          (click)="edit(tabGroup.activeKey)">
          <ca-icon [key]="'EDIT'"></ca-icon>
  </button>

  <button
  mat-icon-button matTooltip="cancelButtonTitle"
  [disabled]="isCancelDisabled || isCancelOrderDisabled() || isDocumentTab"
  (click)="cancel()">
  <ca-icon
    [key]="'DISCONNECT_ORDER'"
    [tooltip]="cancelButtonTitle"
  ></ca-icon>
</button>

  <div *ngIf="tabGroup.isActive(tabs.DOCUMENTS)" class="vertical-divider"></div>

  <app-document-manager-commands
    *ngIf="tabGroup.isActive(tabs.DOCUMENTS)"

    [deleteDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DELETE_DOCUMENT_ACTION"
    [deleteDocumentDisablePredicate]="isDownloadDisabled(selection)"

    [uploadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.UPLOAD_DOCUMENT_ACTION"
    [uploadDocumentDisablePredicate]="false"

    [downloadDocumentActionHandler]="DOCUMENT_MANAGER_SETTINGS.DOWNLOAD_DOCUMENT_ACTION"
    [downloadDocumentDisablePredicate]="isDownloadDisabled(selection)"
  >
  </app-document-manager-commands>

  <div class="vertical-divider"></div>

  <button [ngClass]="{'on' : sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.NOTES_SECTION_NAME)"
          mat-icon-button
          matTooltip="Notes">
          <ca-icon [key]="notesCount > 0 ? 'NOTES' : 'NOTES_EMPTY'"></ca-icon>
  </button>
  <button [ngClass]="{'on' : sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
          (click)="sider?.toggle(SECTIONS.HISTORY_SECTION_NAME)"
          mat-icon-button
          matTooltip="History">
          <ca-icon [key]="'HISTORY'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content tabbed-content">
    <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="activeTabIndex">
      <mat-tab [label]="tab.title" [disabled]="tab.disabled" *ngFor="let tab of tabGroup.tabs">
          <ng-template mat-tab-label *ngIf="tab.key==2" style="display: flex; align-items: center;">
            <span>Services</span>&nbsp;
            <span
                *ngIf="order.services.length>0"
                class="selection-badge">
                  {{this.order.services.length}}
            </span>
          </ng-template>
      </mat-tab>
  </mat-tab-group>

  <div [ngClass]="{'hidden': !tabGroup.isActive(1)}" class="tabs-body allow-scroll" *ngIf="flow"
    fxFlex fxLayout="column">
    <ca-flow class="order-flow card-flow-wrapper-8" [step]="flow?.currentStep" [flow]="flow"
             (onStepSelection)="onStepSelection($event)"></ca-flow>

    <div class="flex-container ca-card custom-mat-card-container mt-0" fxLayout="row">
      <!--ORDER INFO SECTION-->
      <div fxFlex>
        <mat-card fxFlex fxFlexFill caCard>
          <mat-card-header>
            Order Info
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Vendor</label>
                <p>
                  {{ order?.vendor?.name || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Type</label>
                <p>
                  {{ order?.type?.value || 'N/A'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Project</label>
                <p>
                  {{ order?.project?.name || 'N/A'}}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Request Date</label>
                <p>
                  {{ (order?.request_date | caDate) || 'N/A'}}
                </p>
              </div>
            </div>
            <div fxLayout="row" class="entry-list">
              <div fxFlex class="entry-list-item">
                <label>Processor</label>
                <p>
                  {{ order?.processor?.full_name || 'N/A'}}
                </p>
                <p>
                  {{ order?.processor?.email }}
                </p>
                <p>
                  {{ order?.processor?.mobile_number }}
                </p>
              </div>
              <div fxFlex class="entry-list-item">
                <label>Requester-Initiator</label>
                <p>
                  {{ order?.requester?.full_name || 'N/A'}}
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!--APPROVAL INFO SECTION-->
      <div fxFlex>
        <mat-card fxFlex fxFlexFill caCard>
            <mat-card-header>
              Approval Info
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="row" class="entry-list">
                <div fxFlex="50%" class="entry-list-item">
                  <label>Total Est MRC</label>
                  <p>
                    {{ (order?.total_est_mrc | caCurrency:order?.currency_obj?.currency) || 'N/A'}}
                  </p>
                </div>
                <div fxFlex="50%" class="entry-list-item">
                  <label>Total Est NRC</label>
                  <p>
                    {{ (order?.total_est_nrc | caCurrency:order?.currency_obj?.currency) || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Justification</label>
                  <p>
                    {{ order?.justification_description || 'N/A'}}
                  </p>
                </div>
                <div fxFlex class="entry-list-item">
                  <label>Approver</label>
                  <p>
                    {{ order?.approver?.full_name || 'N/A'}}
                  </p>
                </div>
              </div>
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item">
                  <label>Additional Info</label>
                  <p>
                    {{ order?.additional_info || 'N/A'}}
                  </p>
                </div>
              </div>
            </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="flex-container ca-card custom-mat-card-container" fxLayout="row">
      <!--CONTACT INFO SECTION-->
      <div fxFlex="50%">
        <mat-card fxFlex fxFlexFill caCard>
          <mat-card-header>
            Contact Info
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" class="entry-list" *ngFor="let contacts of groupedContacts">
              <div fxFlex="50%" class="entry-list-item" *ngFor="let contact of contacts; let i = index;">
                <label>{{getFunctionName(contact.contact_function)}}</label>
                <p>
                  {{ contact.first_name || 'N/A'}} {{ contact.last_name || 'N/A'}}
                </p>
                <p>
                  {{ contact.email || 'N/A'}}
                </p>
                <p>
                  {{ contact.mobile_number || 'N/A'}}
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- NEW TAB -->
  <div [ngClass]="{'hidden': !tabGroup.isActive(2)}" class="tabs-body allow-scroll">
    <!--SECTION ORDER SERVICES -->
    <div class="flex-container details bottom-padding" fxLayout="row"
         *ngIf="order?.services?.length > 0"><!-- fxLayout.sm="column"-->
      <div fxFlex class="flex-item ca-card" fxFlex style="width: -webkit-fill-available"><!--fxFlex.sm="100%" -->
        <mat-accordion class="ca-accordion ca-order-service-accordion" #accordion [multi]="false">
          <mat-expansion-panel [expanded]="orderSvc.id === selectedOrderSvc?.id" #group
                           *ngFor="let orderSvc of order?.services | sortPipe:'full_service_id'; let i = index;">
            <mat-expansion-panel-header class="ca-expansion-panel-header-title" (click)="selectOrderService(true, orderSvc)" [expandedHeight]="'48px'" [collapsedHeight]="'48px'">
                {{orderSvc?.full_service_id || 'N/A'}}
            </mat-expansion-panel-header>

            <ca-flow class="order-flow card-flow-wrapper-16" [step]="getOrderServiceFlow(i)?.flow?.currentStep"
                      [flow]="getOrderServiceFlow(orderSvc.id)?.flow"
                      [disabled]="order?.status_id<flowService.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED
                              && orderSvc.state != ORDER_SERVICE_STATUS_ENUM.NEW"
                      (onStepSelection)="onStepSelectionOrderService(orderSvc,$event)"
                      *ngIf="order.type_id == this.ORDER_TYPE.CHANGE"></ca-flow>


            <ca-flow class="order-flow card-flow-wrapper-16"
                      [step]="getOrderServiceRecordOnlyFlow(i)?.flow?.currentStep"
                      [flow]="getOrderServiceRecordOnlyFlow(i)?.flow"
                      [disabled]="order?.status_id<flowService.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED
                              && orderSvc.state != ORDER_SERVICE_STATUS.NEW"
                      (onStepSelection)="onStepSelectionOrderServiceRecordOnly(i,orderSvc,$event)"
                      *ngIf="order.type_id == this.ORDER_TYPE.RECORD_ONLY"></ca-flow>


            <ca-flow class="order-flow card-flow-wrapper-16"
                      [step]="getOrderServiceFlow(i)?.flow?.currentStep"
                      [flow]="getOrderServiceFlow(orderSvc.id)?.flow"
                      [disabled]="order?.status_id<flowService.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED
                              && orderSvc.state != ORDER_SERVICE_STATUS_ENUM.NEW"
                      (onStepSelection)="onStepSelectionOrderService(orderSvc,$event)"
                      *ngIf="order.type_id == this.ORDER_TYPE.INSTALL"></ca-flow>


          <ca-flow class="order-flow card-flow-wrapper-16"
                      [step]="getOrderServiceDisconnectFlow(i)?.flow?.currentStep"
                     [flow]="getOrderServiceDisconnectFlow(i)?.flow"
                     [disabled]="order?.status_id<flowService.ORDER_STATUS_ENUM.VENDOR_ACCEPT_RECEIVED
                      && orderSvc.disconnect_state != ORDER_SERVICE_DISCONNECT_STATUS.NEW"
                     (onStepSelection)="onStepSelectionOrderServiceDisconnect(i,orderSvc,$event)"
                     *ngIf="order.type_id == this.ORDER_TYPE.DISCONNECT"></ca-flow>

            <div class="flex-container" fxLayout="row" class="order-tabs" style="width: 100%; position: relative">
              <div fxLayout="column">
                <div class="tab-navigation-toggle-wrapper">
                  <button class="nav-toggle" mat-icon-button (click)="toggleNavigation()">
                    <ca-icon [key]="'NAV_TOGGLE'"></ca-icon>
                  </button>
                </div>
                <mat-drawer fxFlex="100px" mode="side"
                             [opened]="true"
                             style="display: contents; visibility: visible !important;">
                  <nav class="tab-navigation-wrapper" [ngClass]="{'label-hidden': !navExpand}">
                    <ul style="list-style-type: none;" class="tab-navigation-list">
                      <li>
                        <button class="ord-serv-menu-item" (click)="changeDetail(1)" [ngClass]="{'ord-serv-menu-item-active' : testTab === 1}" matTooltip="Info">
                          <ca-icon [key]="'INFO'" class="tab-navigation-icon"></ca-icon>
                          <span class="label" [ngClass]="{'label-hidden': !navExpand}">Info</span>
                        </button>
                      </li>
                      <li>
                        <button class="ord-serv-menu-item" (click)="changeDetail(2)" [ngClass]="{'ord-serv-menu-item-active' : testTab === 2}" matTooltip="Financial / Contract">
                          <ca-icon [key]="'FINANCIAL'" class="tab-navigation-icon"></ca-icon>
                          <span class="label" [ngClass]="{'label-hidden': !navExpand}">Financial / Contract</span>
                        </button>
                      </li>
                      <li>
                        <button class="ord-serv-menu-item" (click)="changeDetail(3)" [ngClass]="{'ord-serv-menu-item-active' : testTab === 3}" matTooltip="ervice Configuration">
                          <ca-icon [key]="'CIRCUIT'" class="tab-navigation-icon"></ca-icon>
                          <span class="label" [ngClass]="{'label-hidden': !navExpand}">Service Config</span>
                        </button>
                      </li>
                      <li>
                        <button class="ord-serv-menu-item" (click)="changeDetail(4)" [ngClass]="{'ord-serv-menu-item-active' : testTab === 4}" matTooltip="Site A (Carrier)">
                          <ca-icon [key]="'SITE'" class="tab-navigation-icon icon-rotate-270"></ca-icon>
                          <span class="label" [ngClass]="{'label-hidden': !navExpand}">Site A (Carrier)</span>
                        </button>
                      </li>
                      <li>
                        <button class="ord-serv-menu-item" (click)="changeDetail(5)" [ngClass]="{'ord-serv-menu-item-active' : testTab === 5}" matTooltip="Site Z (Customer)">
                          <ca-icon [key]="'SITE'" class="tab-navigation-icon icon-rotate-90"></ca-icon>
                          <span class="label" [ngClass]="{'label-hidden': !navExpand}">Site Z (Customer)</span>
                        </button>
                      </li>
                      <li>
                        <button class="ord-serv-menu-item" (click)="changeDetail(6)" [ngClass]="{'ord-serv-menu-item-active' : testTab === 6}" matTooltip="GL Codes">
                          <ca-icon [key]="'SITE'" class="tab-navigation-icon icon-rotate-180"></ca-icon>
                          <span class="label" [ngClass]="{'label-hidden': !navExpand}">GL Codes</span>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </mat-drawer>
              </div>

              <div fxFlex>
                <div *ngIf="testTab === 6" class="new-gl-string-button">
                  <button
                    mat-icon-button matTooltip="New"
                    *permission="{'action':'Create', 'key':'gl'}"
                    (click)="addString()"
                  >
                    <ca-icon [key]="'ADD_NEW'"></ca-icon>
                  </button>
                </div>
                <mat-card 
                  style="overflow: scroll; padding-bottom: 16px; margin-left: 0 !important; height: 240px; margin-top: 0 !important; margin-bottom: 0 !important;"
                  [class.move-mat-card-content]="testTab !== 6">
                  <!--------- Info ------->
                  <mat-card-content *ngIf="testTab === 1">
                    <div class="entry-list-container">
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>SP Service ID</label>
                          <p>{{ orderSvc?.inventory?.sp_ckt_id || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Billing Account</label>
                          <p>{{ orderSvc?.inventory?.ban || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Desired Due Date</label>
                          <p>{{ (orderSvc?.des_due_date | caDate) || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Expedite Order</label>
                          <p>{{ orderSvc?.expedited_order ? "Yes" : "No" }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Customer Service ID</label>
                          <p>{{ orderSvc?.inventory?.customer_inventory?.customer_circuit_id || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Customer</label>
                          <p>{{ orderSvc?.inventory?.customer?.company || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Service Order Number</label>
                          <p>{{ orderSvc?.order_number || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Purchase Order Number</label>
                          <p>{{ orderSvc?.purchase_number || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Description</label>
                          <p>{{ orderSvc?.description || 'N/A'}}</p>
                        </div>
                      </div>
                    </div>
                    <!--CUSTOM FIELDS-->
                    <mat-divider *ngIf="customFieldsExist1"></mat-divider><br *ngIf="customFieldsExist1">
                    <div class="entry-list-container" *ngIf="customFieldsExist1">
                      <!--///-->
                      <div fxLayout="row" class="entry-list" style="width: 100%">
                        <div fxFlex class="entry-list-item">
                          <div class="entry-list-item-title">
                            <p>Custom Attributes</p>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list" *ngFor="let field of fields.order_service_summary">
                        <div fxFlex class="entry-list-item">
                          <label>{{ field.placeholder }}</label>
                          <p>
                            {{ orderSvc?.custom_values[field.field_name] ? orderSvc?.custom_values[field.field_name] : 'N/A' }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                  <!--------- Financial / Contract ------->
                  <mat-card-content *ngIf="testTab === 2">
                    <div class="entry-list-container">
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Contract</label>
                          <p>{{ orderSvc?.inventory?.contract?.name || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Term</label>
                          <p>{{ orderSvc?.inventory?.term || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Exp Date</label>
                          <p> {{ (orderSvc?.inventory?.exp_date | caDate) || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Quote Service ID</label>
                          <p>{{ orderSvc?.quote_service?.quote_service_id || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>MRC</label>
                          <p>{{ (orderSvc?.inventory?.est_mrc | caCurrency:order?.currency_obj?.currency) || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>NRC</label>
                          <p>{{ (orderSvc?.inventory?.est_nrc | caCurrency:order?.currency_obj?.currency) || 'N/A'}}</p>
                        </div>
                      </div>
                    </div>
                    <!--CUSTOM FIELDS-->
                    <mat-divider *ngIf="customFieldsExist2"></mat-divider><br *ngIf="customFieldsExist2">
                    <div class="entry-list-container" *ngIf="customFieldsExist2">
                      <!--///-->
                      <div fxLayout="row" class="entry-list" style="width: 100%">
                        <div fxFlex class="entry-list-item">
                          <div class="entry-list-item-title">
                            <p>Custom Attributes</p>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list" *ngFor="let field of fields.order_service_financial_contract">
                        <div fxFlex class="entry-list-item">
                          <label>{{ field.placeholder }}</label>
                          <p>
                            {{ orderSvc?.custom_values[field.field_name] ? orderSvc?.custom_values[field.field_name] : 'N/A' }}
                          </p>
                        </div>
                      </div>
                    </div>

                  </mat-card-content>
                  <!--------- Circuit Configuration ------->
                  <mat-card-content *ngIf="testTab === 3">
                    <div class="entry-list-container">
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Topology</label>
                          <p>{{ orderSvc?.inventory?.topology?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Service Bandwidth</label>
                          <p>{{ orderSvc?.inventory?.circuit_bandwidth?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Port Bandwidth</label>
                          <p>{{ orderSvc?.inventory?.bandwidth_max?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Technology</label>
                          <p>{{ orderSvc?.inventory?.technology?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Type</label>
                          <p>{{ orderSvc?.inventory?.inventory_ckt_type?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Usage</label>
                          <p>{{ orderSvc?.inventory?.inventory_ckt_usage?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Bandwidth Model</label>
                          <p>{{ orderSvc?.inventory?.inventory_bw_model?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Protection</label>
                          <p>{{ orderSvc?.inventory?.inventory_protected?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Primary/Failover</label>
                          <p>{{ orderSvc?.inventory?.inventory_prim_failover?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Handoff Type</label>
                          <p>{{ orderSvc?.inventory?.inventory_handoff_type?.value || 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>VLAN ID</label>
                          <p>{{ orderSvc?.inventory?.vlan_id || 'N/A' }}</p>
                        </div>
                      </div>
                    </div>
                    <!--CUSTOM FIELDS-->
                    <mat-divider *ngIf="customFieldsExist3"></mat-divider><br *ngIf="customFieldsExist3">
                    <div class="entry-list-container" *ngIf="customFieldsExist3">
                      <!--///-->
                      <div fxLayout="row" class="entry-list" style="width: 100%">
                        <div fxFlex class="entry-list-item">
                          <div class="entry-list-item-title">
                            <p>Custom Attributes</p>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list" *ngFor="let field of fields.order_service_configuration">
                        <div fxFlex class="entry-list-item">
                          <label>{{ field.placeholder }}</label>
                          <p>
                            {{ orderSvc?.custom_values[field.field_name] ? orderSvc?.custom_values[field.field_name] : 'N/A' }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                  <!--------- Site A ------->
                  <mat-card-content *ngIf="testTab === 4">
                    <div class="entry-list-container">
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Site</label>
                          <p>{{ orderSvc?.inventory?.siteA?.site_id || 'N/A'}}</p>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list"
                          *ngIf="isSiteTypeOf(orderSvc?.inventory?.siteA?.type?.site_type_id, SITE_TYPES_ENUM.VENDOR)">
                        <div fxFlex class="entry-list-item">
                          <label>Vendor</label>
                          <p>
                            {{ orderSvc?.inventory?.siteA?.vendor?.name || 'N/A'}}
                          </p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list"
                          *ngIf="isSiteTypeOf(orderSvc?.inventory?.siteA?.type?.site_type_id, SITE_TYPES_ENUM.CUSTOMER)">
                        <div fxFlex class="entry-list-item">
                          <label>Customer</label>
                          <p>
                            {{ orderSvc?.inventory?.siteA?.customer?.company || 'N/A'}}
                          </p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list"
                          *ngIf="!isSiteTypeOf(orderSvc?.inventory?.siteA?.type?.site_type_id, SITE_TYPES_ENUM.VENDOR)
                              && !isSiteTypeOf(orderSvc?.inventory?.siteA?.type?.site_type_id, SITE_TYPES_ENUM.CUSTOMER)">
                        <div fxFlex class="entry-list-item">
                          <label>Internal</label>
                          <p>{{'N/A'}}</p>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Site Type</label>
                          <p>{{ orderSvc?.inventory?.siteA?.type?.value || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Room</label>
                          <p>{{ orderSvc?.inventory?.siteA?.room || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Floor</label>
                          <p>{{ orderSvc?.inventory?.siteA?.floor || 'N/A'}}</p>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list" *ngIf="orderSvc?.inventory?.level2">
                        <div fxFlex class="entry-list-item">
                          <label>{{orderSvc?.inventory?.level2?.name || 'N/A'}}</label>
                          <p *ngIf="orderSvc?.inventory?.level2?.name">Slot {{orderSvc?.inventory?.level2?.order}}</p>
                          <p *ngIf="!orderSvc?.inventory?.level2?.name">No order</p>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list"
                        [ngStyle]="{'width': (orderSvc?.inventory?.siteA?.building?.name ? '75%' : '25%')}"
                      >
                        <div fxFlex class="entry-list-item">
                          <label>Building</label>
                          <p>
                            {{ orderSvc?.inventory?.siteA?.building?.name || 'N/A'}}{{ orderSvc?.inventory?.siteA?.building?.address ? ',' : '' }}
                            <ng-container *ngIf="orderSvc?.inventory?.siteA?.building?.address">
                              {{ orderSvc?.inventory?.siteA?.building?.address?.address1 ? orderSvc?.inventory?.siteA?.building?.address?.address1 + ',' : '' }}
                              {{ orderSvc?.inventory?.siteA?.building?.address?.city }},
                              <ng-container *ngIf="orderSvc?.inventory?.siteA?.building?.address?.subdivision?.abbreviation">{{ orderSvc?.inventory?.siteA?.building?.address?.subdivision?.abbreviation}}, </ng-container>
                              {{ orderSvc?.inventory?.siteA?.building?.address?.postal_code }},
                              {{ orderSvc?.inventory?.siteA?.building?.address?.country?.name }}
                            </ng-container>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="entry-list-container">
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Row</label>
                          <p>{{orderSvc?.siteADemarcs?.length && orderSvc.siteADemarcs[0].row_id ? orderSvc.siteADemarcs[0].row_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Rack</label>
                          <p>{{orderSvc?.siteADemarcs?.length && orderSvc.siteADemarcs[0].rack_id ? orderSvc.siteADemarcs[0].rack_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Shelf</label>
                          <p>{{orderSvc?.siteADemarcs?.length && orderSvc.siteADemarcs[0].shelf_id ? orderSvc.siteADemarcs[0].shelf_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>CPE</label>
                          <p>{{orderSvc?.siteADemarcs?.length && orderSvc.siteADemarcs[0].cpe_id ? orderSvc.siteADemarcs[0].cpe_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Card</label>
                          <p>{{orderSvc?.siteADemarcs?.length && orderSvc.siteADemarcs[0].card_id ? orderSvc.siteADemarcs[0].card_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Port</label>
                          <p>{{orderSvc?.siteADemarcs?.length && orderSvc.siteADemarcs[0].port_id ? orderSvc.siteADemarcs[0].port_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Extended</label>
                          <p>{{orderSvc?.siteADemarcs?.length ? orderSvc.siteADemarcs[0].extended ? 'Yes' : 'No' : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Notes</label>
                          <p>{{orderSvc?.siteADemarcs?.length && orderSvc.siteADemarcs[0].notes ? orderSvc.siteADemarcs[0].notes : 'N/A' }}</p>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                  <!--------- Site Z ------->
                  <mat-card-content *ngIf="testTab === 5">
                    <div class="entry-list-container">
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Site</label>
                          <p>{{ orderSvc?.inventory?.siteZ?.site_id || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list" *ngIf="isSiteTypeOf(orderSvc?.inventory?.siteZ?.type?.site_type_id, SITE_TYPES_ENUM.VENDOR)">
                        <div fxFlex class="entry-list-item">
                          <label>Vendor</label>
                          <p>{{ orderSvc?.inventory?.siteA?.vendor?.name || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list" *ngIf="isSiteTypeOf(orderSvc?.inventory?.siteZ?.type?.site_type_id, SITE_TYPES_ENUM.CUSTOMER)">
                        <div fxFlex class="entry-list-item">
                          <label>Customer</label>
                          <p>{{ orderSvc?.inventory?.siteA?.customer?.company || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list"
                          *ngIf="!isSiteTypeOf(orderSvc?.inventory?.siteZ?.type?.site_type_id, SITE_TYPES_ENUM.VENDOR)
                              && !isSiteTypeOf(orderSvc?.inventory?.siteZ?.type?.site_type_id, SITE_TYPES_ENUM.CUSTOMER)">
                        <div fxFlex class="entry-list-item">
                          <label>Internal</label>
                          <p>{{'N/A'}}</p>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Site Type</label>
                          <p>{{ orderSvc?.inventory?.siteZ?.type?.value || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Room</label>
                          <p>{{ orderSvc?.inventory?.siteZ?.room || 'N/A'}}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Floor</label>
                          <p>{{ orderSvc?.inventory?.siteZ?.floor || 'N/A'}}</p>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list"
                        [ngStyle]="{'width': (orderSvc?.inventory?.siteZ?.building?.name ? '75%' : '25%')}"
                      >
                        <div fxFlex class="entry-list-item">
                          <label>Building</label>
                          <p>
                            {{ orderSvc?.inventory?.siteZ?.building?.name || 'N/A'}}{{ orderSvc?.inventory?.siteZ?.building?.address ? ',' : '' }}
                            <ng-container *ngIf="orderSvc?.inventory?.siteZ?.building?.address">
                              {{ orderSvc?.inventory?.siteZ?.building?.address?.address1 ? orderSvc?.inventory?.siteZ?.building?.address?.address1 + ',' : '' }}
                              {{ orderSvc?.inventory?.siteZ?.building?.address?.city }},
                              <ng-container *ngIf="orderSvc?.inventory?.siteZ?.building?.address?.subdivision?.abbreviation">{{ orderSvc?.inventory?.siteZ?.building?.address?.subdivision?.abbreviation}}, </ng-container>
                              {{ orderSvc?.inventory?.siteZ?.building?.address?.postal_code }},
                              {{ orderSvc?.inventory?.siteZ?.building?.address?.country?.name }}
                            </ng-container>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="entry-list-container">
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Row</label>
                          <p>{{orderSvc?.siteZDemarcs?.length && orderSvc.siteZDemarcs[0].row_id ? orderSvc.siteZDemarcs[0].row_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Rack</label>
                          <p>{{orderSvc?.siteZDemarcs?.length && orderSvc.siteZDemarcs[0].rack_id ? orderSvc.siteZDemarcs[0].rack_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Shelf</label>
                          <p>{{orderSvc?.siteZDemarcs?.length && orderSvc.siteZDemarcs[0].shelf_id ? orderSvc.siteZDemarcs[0].shelf_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>CPE</label>
                          <p>{{orderSvc?.siteZDemarcs?.length && orderSvc.siteZDemarcs[0].cpe_id ? orderSvc.siteZDemarcs[0].cpe_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Card</label>
                          <p>{{orderSvc?.siteZDemarcs?.length && orderSvc.siteZDemarcs[0].card_id ? orderSvc.siteZDemarcs[0].card_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Port</label>
                          <p>{{orderSvc?.siteZDemarcs?.length && orderSvc.siteZDemarcs[0].port_id ? orderSvc.siteZDemarcs[0].port_id : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Extended</label>
                          <p>{{orderSvc?.siteZDemarcs?.length ? orderSvc.siteZDemarcs[0].extended ? 'Yes' : 'No' : 'N/A' }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" class="entry-list">
                        <div fxFlex class="entry-list-item">
                          <label>Notes</label>
                          <p>{{orderSvc?.siteZDemarcs?.length && orderSvc.siteZDemarcs[0].notes ? orderSvc.siteZDemarcs[0].notes : 'N/A' }}</p>
                        </div>
                      </div>
                    </div>
                    <!--CUSTOM FIELDS-->
                    <mat-divider *ngIf="customFieldsExist4"></mat-divider><br *ngIf="customFieldsExist4">
                    <div class="entry-list-container" *ngIf="customFieldsExist4">
                      <!--///-->
                      <div fxLayout="row" class="entry-list" style="width: 100%">
                        <div fxFlex class="entry-list-item">
                          <div class="entry-list-item-title">
                            <p>Custom Attributes</p>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="row" class="entry-list" *ngFor="let field of fields.order_service_site_a_z">
                        <div fxFlex class="entry-list-item">
                          <label>{{ field.placeholder }}</label>
                          <p>
                            {{ orderSvc?.custom_values[field.field_name] ? orderSvc?.custom_values[field.field_name] : 'N/A' }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>

                  <!--------- GL Codes ------->
                  <mat-card-content *ngIf="testTab === 6">
                    <div class="app-content gl-codes-content dx-nodata-absolute">
                      <dx-data-grid
                        #glCodeGrid
                        class="app-grid-with-pager gl-string-grid"
                        [columns]="previewGridColumns"
                        [dataSource]="selectedCodings"
                        [showRowLines]="true"
                        [showColumnLines]="false">
                      </dx-data-grid>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div *ngIf="!order?.services?.length" class="ca-empty-state-container">
      <span class="grid-message">No data</span>
    </div>
  </div>

  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(3)}" class="tabs-body grid-container"
       style="overflow-y: hidden;">
       <!--SECTION DOCUMENTS-->
       <ng-container *ngIf="tabGroup.isActive(tabs.DOCUMENTS)">
         <app-document-manager [tabChanged]="tabChanged"
         [folder]="DOCUMENT_INFO.FOLDER"
         [parentType]="selectedEntity?.type"
         [subfolder]="DOCUMENT_INFO.SUBFOLDER"
         [masterId]="selectedMaster?.id"
         [masterType]="selectedMaster?.type"
         [entityType]="selectedEntity?.type"
         [entityId]="selectedEntity?.id"
         [entities]="entities"
         [mapMeta]="mapDocumentsMeta"
         (docAdd)="history.refreshList()"
         (docSelected)="docSelected($event)"
         (docDelete)="history.refreshList()"
         #documentsManager>
        </app-document-manager>
      </ng-container>
  </div>

  <mat-sidenav #panelMore [mode]="'side'" position="end" [opened]="false">
    <ca-notes-list *ngIf="dataReady"
                   [master]="selectedMaster"
                   [entity]="selectedEntity"
                   [groups]="groups"
                   [multiple]="false"
                   #notes
                   [ngClass]="{'hidden': !sider?.isActive(SECTIONS.NOTES_SECTION_NAME)}"
                   (countChanged)="onNotesCountChanged($event)"></ca-notes-list>
    <ca-history #history [entityId]="order?.id" [order]="order"
                [ngClass]="{'hidden': !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME)}"
                [entityType]="'order'"></ca-history>
  </mat-sidenav>
</mat-sidenav-container>
