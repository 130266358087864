import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs';
import {Injectable} from "@angular/core";
import {HttpResponse, HttpParams} from '@angular/common/http';


import {environment} from "../../../environments/environment";
import Auth from "../../shared/user-session/auth";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ChartPdfExportService {
  constructor(public http: HttpClient) {
  }

  exportToPdf(base64data, reportTitle: string): any {
    // let cookie = Auth.getSession();
    // let params: HttpParams = new HttpParams();
    // params.set('authorization', cookie.token);
    //
    // let endPoint: string = [environment.API_URL, 'report/reportPdf'].join('');
    // // @ts-ignore
    // return this.http.post(endPoint, {"base64data": base64data, "reportTitle": reportTitle}, {responseType: 2, search: params})
    //   .pipe(
    //   map(this.extractPdfBlob),
    //   catchError(this.handleHttpError));
  }

  public extractPdfBlob(response: HttpResponse<any>) {
    let blob = new Blob([<BlobPart><unknown>response], {type: 'application/pdf'});
    return blob || {};
  }

  public handleHttpError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      const body = error || '';
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
