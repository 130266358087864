import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

import {DialogService} from '../../shared/dialog/dialog.service';
import {AlertService} from '../../shared/alert/alert.service';
import {TabGroup, TabService} from '../../shared/tabs/tab.service';
import {ChargeQuery} from '../../charge/core/charge.query';
import {InvoiceChargeService} from '../../charge/core/invoice-charge.service';
import {Inventory} from '../core/inventory';
import {InventoryService} from '../core/inventory.service';
import {Pricing} from '../core/pricing';
import {InventoryPricingGridService} from '../core/inventory-pricing-grid.service';
import {HistoryComponent} from '../../shared/history/history.component';
import {INVENTORY_TOPOLOGY_ENUM} from '../core/inventory-topology.enum';
import {InventoryCircuitsGridService} from '../core/inventory-circuits-grid.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {PageContext} from '../../core/page.context';
import {UserSettingsService} from '../../user/core/user-settings.service';
import {PageDetailsComponent} from '../../core/page-details.component';
import {SortingBuilder, SortingService} from '../../shared/sorting/sorting.service';
import {IMessagesResourceService, ResourcesService} from '../../core/resources/resources.service';
import {LOOKUP_ENUM} from '../../dictionary/core/lookup.enum';
import Entity from '../../core/entity.model';
import {DemarcService} from '../../demarc/core/demarc.service';
import {Demarc} from '../../demarc/core/demarc.model';
import {DataLockDialogService} from '../../core/data-lock/data-lock-dialog.service';
import {DictionaryService} from '../../dictionary/core/dictionary.service';
import {PermissionService} from "../../permissions/core/permission.service";
import {ConfigService} from '../../core/config/config.service';
import {DocumentService} from "../../shared/document/core/document.service";
import Query from '../../core/query/query';
import {InventoryContractTerm} from "../core/inventory-contract-term";

@Component({
  selector: 'app-inventory-details',
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.css']
})
export class InventoryDetailsComponent extends PageDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  //tabs = TABGROUP;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;
  readonly ORDER_TYPE = LOOKUP_ENUM.ORDER_TYPE;

  @ViewChild('panelMore') panelMore;
  @ViewChild('panelMoreTabs') panelMoreTabs;
  @ViewChild('chargesGrid') chargesGrid: DxDataGridComponent;
  @ViewChild('pricingsGrid') pricingGrid: DxDataGridComponent;
  @ViewChild('preveiwGrid') preveiwGrid: DxDataGridComponent;
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('circuitsGrid') circuitsGrid: DxDataGridComponent;
  @ViewChild('contractTermGrid') contractTermGrid: DxDataGridComponent;
  @ViewChild('documentsManager') documentsManager;
  @ViewChild('ordersGrid') ordersGrid: DxDataGridComponent;



  public chargeQuery: ChargeQuery = new ChargeQuery();
  chargesSorting: SortingBuilder;
  contractTermsSorting: SortingBuilder;
  public charges: Array<any>;
  public inventory: Inventory;
  public inventoryId: number;
  public pricing: Array<Pricing>;
  public circuits: Array<any> = [];
  public level1: any;
  public level2: any;
  public notesCount = 0;
  public glQuery: Query = new Query();
  public orderQuery: Query = new Query();

  public chargesColumns: any;
  public ordersColumns: any;


  public selectedCodings: Array<any> = [];

  public contractTerms: Array<any> = [];
  public contractTermsColumns;
  public contractTermsQuery: Query = new Query();
  public contractTermsSelected: InventoryContractTerm;

  public tabGroup: TabGroup;
  public tabChanged: boolean;
  activeTabIndex = 0;

  public addFunctions;

  public billingAliasesTooltip = ``;
  public billingAliases: Array<any> = [];
  public orders: Array<any> = [];


  public DOCUMENT = {
    entity: null,
    folder: 'inventories',
    subfolder: ''
  };

  _isSidePanelOpen = false;
  public INVENTORY_TOPOLOGY = INVENTORY_TOPOLOGY_ENUM;


  messages: IMessagesResourceService;
  coreMessages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'inventory';
  readonly CORE_MODULE: string = 'core';


  DOCUMENT_INFO = {
    FOLDER: 'inventories',
    SUBFOLDER: '',
    PARENT_TYPE: this.SYSTEM_MODULE.INVENTORY,
    ENTITY_TYPE: this.SYSTEM_MODULE.INVENTORY
  };

  selectedMaster: Entity;
  selectedEntity: Entity;
  entities: Array<Entity> = [];

  siteADemarcs: Array<Demarc> = [];
  siteZDemarcs: Array<Demarc> = [];

  permissionOrderView: boolean;
  permissionDisputeView: boolean;

  tabs = [
    {label: 'Summary', key: 'summary'},
    {label: 'Sites', key: 'site'},
    {label: 'Orders', key: 'order'},
    {label: 'Billing', key: 'billing'},
    {label: 'GL Codes', key: 'code'},
    {label: 'Pricing', key: 'pricing'},
    {label: 'Related Service', key: 'related-circuit'},
    {label: 'Documents', key: 'document'},
    {label: 'Contract Terms', key: 'contract-terms'},
    {label: 'Charge Profile', key: 'invoice-charge-profile'}
  ];

  constructor(public alertService: AlertService,
              public dialogService: DialogService,
              public chargeService: InvoiceChargeService,
              public documentService: DocumentService,
              public pricingGridService: InventoryPricingGridService,
              public inventoryService: InventoryService,
              public tabService: TabService,
              public permissionService: PermissionService,
              public route: ActivatedRoute,
              public router: Router,
              public inventoryCircuitsGridService: InventoryCircuitsGridService,
              public loaderService: LoaderService,
              public settingsService: UserSettingsService,
              public sortingService: SortingService,
              public demarcService: DemarcService,
              public dataLockDialogService: DataLockDialogService,
              public dictionaryService: DictionaryService,
              public configService: ConfigService,
        ) {
    super(new PageContext({
      name: 'app.inventory.inventory-details',
      settings: settingsService
    }));

    this.chargesSorting = this.sortingService.builder();
    this.contractTermsSorting = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.coreMessages = ResourcesService.messages(this.CORE_MODULE);
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }


}
