import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Account} from "./account";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {ApiService} from "../../core/api";
import Query from "../../core/query/query";
import {Observable} from "rxjs";


@Injectable()
export class AccountService extends BaseService<Account> {

  constructor(private entityLock: EntityLockService) {
    super('account', entityLock);
  }

  findAccountsForDisputeVATCheck(query): Observable<any> {
    let concreteQuery = (query || new Query({limit: 100000}));
    let transformedQuery = concreteQuery.transform();
    return this.httpService().post(`${this.name}/findAccountsForDisputeVATCheck`, this.toFilter(transformedQuery))

  }

  findOnlyAccounts(query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().post(`${this.name}/findAccountsForPicker`, this.toFilter(transformedQuery));
  }

  findAccountsWithInvoices(query): any {
    return this.findAll(query);
  }

  findActiveAccountsWithInvoices(query): any {
    return this.findAll(query);
  }

  findActiveAccounts(query): any {
    if (query) query.limit = 10000;
    let concreteQuery = (query || new Query({limit: 10000}));
    let transformedQuery = concreteQuery.transform();
    //Request query has limitation he can receive a limited number of characters. We decided to use the post method because the limit is larger. We didn't find a solution for how to change the req.query limit
    return this.httpService().post(`${this.name}/findAccounts`, this.toFilter(transformedQuery))
  }

  findAccountsForPicker(query): any {
    if (query) query.limit = 50;
    let concreteQuery = (query || new Query({limit: 50}));
    let transformedQuery = concreteQuery.transform();
    return this.httpService().post(`${this.name}/findAccountsForPicker`, this.toFilter(transformedQuery))
  }

  findActiveAccountsWithInvoicesAndSubAccounts(query): any {
    return this.findAll(query);
  }

  save(dataToSave): any {
    return this.httpService().post(`${this.name}/save`, dataToSave)
  }

  findVendors(query): any {
    return this.httpService().get(`${this.name}/vendors`, query);
  }

  codingUpdate(id, account): any {
    return this.httpService().put(`${this.name}/coding/${id}`, account);
  }

  codingCreateUpdate(id, account): any {
    return this.httpService().put(`${this.name}/coding-create/${id}`, account);
  }

  findAccountCustomRules(account_id): any {
    return this.httpService().get([this.name, 'find-account-custom-rules', account_id])
  }

  checkGLRules(newAccount) {
    return this.httpService().post(`${this.name}/check-gl-rules`, newAccount)
  }

  findAllForRateAudit(query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get(`${this.name}/findAllForRateAudit`, this.toFilter(transformedQuery))

  }

}
