import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {ThemeService} from './theme.service';
import { ChartService } from '../../shared/chart/chart.service';

@Injectable()
export class DefaultThemeResolve  {

  readonly DEFAULT_THEME_NAME = 'Default';

  constructor(
    public themeService: ThemeService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {

    return new Promise<any>((resolve, reject) => {
      return this.themeService.findOne({ where: { name: this.DEFAULT_THEME_NAME } })
        .subscribe((result) => {
          resolve(ChartService.setDefaultTheme(result))
        })
    });


  }
}
