import {Inject, Injectable, OnInit} from '@angular/core';
import {EntityLockService} from '../shared/entity-lock/entity-lock.service';
import Query from './query/query';
import {ServiceLocator} from "./locator.servce";
import {ConfigService} from "./config/config.service";
import {ApiService} from "./api";
import qs from "qs";
import {Observable, flatMap, map, of as observableOf} from "rxjs";


@Injectable()
export class BaseService<T> implements OnInit {

  maxCsvNumber: any;

  constructor(@Inject('name') public name: string,
              private lockService?: EntityLockService
  ) {
  }

  ngOnInit() {}

  protected httpService() {
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService;
  }

  public toFilter(query: any): any {
    if (!query) {
      return;
    }

    const filter = qs.stringify(query);
    return {filter};
  }

  findAll(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    transformedQuery = this.removeSpecialParams(transformedQuery);
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.get(this.name, transformedQuery);
  }

  findAllCustom(path: string, query: Query): Observable<any> {
    const apiService = ServiceLocator.injector.get(ApiService);
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    return apiService.get(path, transformedQuery);
  }

  fetchAll(url: string, query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    transformedQuery = this.removeSpecialParams(transformedQuery);
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.get(url, transformedQuery);
  }


  removeSpecialParams(query) {
    const ignored = ['$gpx'];
    let where = {};
    if (!query.where) query.where = {};
    Object.keys(query.where)
      .forEach((key) => {
        const param = query.where[key];
        if (typeof param !== 'object' || param === null) {
          where[key] = param;
        } else {
          const reduced = {};
          Object.keys(param).forEach((k) => {
            if (ignored.indexOf(k) === -1) {
              reduced[k] = param[k]
            }
          })
          where[key] = reduced;
        }
      });

    return Object.assign({}, query, {where});
  }

  findAllLargeRequest(query?: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    transformedQuery = this.removeSpecialParams(transformedQuery);
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.post(`${this.name}/find`, transformedQuery);
  }

  getConfig(): any {
    const configService = ServiceLocator.injector.get(ConfigService);
    return configService.findAll();
  }

  exportToCSV(
    name,
    {
      query = new Query(),
      fields = null,
      settings = {currencyField: 'currency'},
      fetchDataHandler = this.findAll.bind(this),
      middleware = [],
      fetchCSVDataHandler = this.fetchCSVData.bind(this),
      CSVDataHandler = this.downloadCSVFile.bind(this),
    } = {}
  ) {
    return this.getConfig()
      .subscribe((config) => {
        this.maxCsvNumber = config.download.max_number_of_records;
        if (query.total <= this.maxCsvNumber) {
          const {generateCSVQuery} = this;

          const csvQuery = generateCSVQuery(query, fields, settings);

          return fetchDataHandler(csvQuery).pipe(
            flatMap(
              (data: any) => {
                const items = (data.items && data.items.items) || data.items || data.rows || data;
                const total = data.total || (data.items && data.items.total) || items.length;
                const dataItems = middleware.reduce((accumulator, dataTransormAction) => dataTransormAction(accumulator), items);
                return total === -1 ? observableOf(this.alertSuccess()) : fetchCSVDataHandler(fields, dataItems, settings)
              }
            ))
            .subscribe((csvData) => {
              if (csvData) {
                CSVDataHandler({name, data: csvData})
              }
            });
        } else {
          return this.alertFail();
        }
      })

  }

  generateCSVQuery(query = new Query(), fields = [], settings = {}) {
    const csvQuery = new Query(query.transform());
    csvQuery.limit = 1000000;
    csvQuery.offset = 0;
    csvQuery.meta = {contentType: 'csv', fields};
    csvQuery.settings = settings;

    return csvQuery;
  }

  fetchCSVData = (fields, items, settings) => {
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.post('csv', {fields, items, settings});
  };

  downloadCSVFile = ({name, data}) => {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:attachment/csv;charset=utf-8,\uFEFF' + encodeURIComponent(data._str));
    element.setAttribute('target', '_blank');
    element.setAttribute('download', `${name}.csv`);
    element.click();
  };

  alertSuccess() {
    try {
      // @ts-ignore
      window.csvAlertProcessingStarted();
    } catch (err) {
    }
  }

  alertFail() {
    try {
      // @ts-ignore
      window.csvAlertProcessingFailed(this.maxCsvNumber);
    } catch (err) {
    }
  }

  findById(id: number): any {
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.get(`${this.name}/${id}`);
  }

  findByIdForEdit(id: number, config?: any) {
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.get(`${this.name}/${id}/edit`);
  }


  findOne(query: Query): any {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();
    const apiService = ServiceLocator.injector.get(ApiService);

    return apiService.get(`${this.name}`, this.toFilter(transformedQuery)).pipe(map((result: any) => result.items[0]));
  }

  cancelEdit() {
    if (this.lockService) {
      this.lockService.cancelAll();
    }
  }

  completeEdit() {
    if (this.lockService) {
      this.lockService.completeAll();
    }
  }

  create(item: T): any {
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.post(`${this.name}`, item);
  }

  update(id: number, item: T): any {
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.put(`${this.name}/${id}`, item).pipe(map(result => {
            this.completeEdit();
            return result
    }));
  }

  remove(id: number) {
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.delete([this.name, id]);
  }

  delete(id: number) {
    const apiService = ServiceLocator.injector.get(ApiService);
    return apiService.delete([this.name, id]);
  }

  isEmpty(obj) {
    let hasOwnProperty = Object.prototype.hasOwnProperty;

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (let key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  }
}
