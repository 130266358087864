import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {Quote} from './quote';
import {DictionaryService} from '../../dictionary/core/dictionary.service';
import {NetworkHubService} from '../../network-hub/core/network-hub.service';
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";
import {Bandwidth} from "../../bandwidth/core/bandwidth";
import {ConfigService} from "../../core/config/config.service";
import Query from "../../core/query/query";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import {flatMap, map} from "rxjs";


@Injectable()
export class QuotingService extends BaseService<Quote> {

  quotes: Quote[] = [];
  bandwidth: Bandwidth[] = [];
  service_type: Array<any>;

  readonly QUOTE_SERVICE_TERM_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.QUOTE_SERVICE_TERM.modelName;

  constructor(public networkHubService: NetworkHubService,
              public dictionaryService: DictionaryService,
              public configService: ConfigService,
              private entityLock: EntityLockService) {
    super('quotes', entityLock);
  }

  findAllServiceTypes() {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName)
  }

  findAllServiceTerms() {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTE_SERVICE_TERM.modelName);
  }

  findAllClientServiceTypes() {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTING_CLIENT_SERVICE_TYPE.modelName)
  }

  findAllQuotingTypes() {
    return this.dictionaryService.getByLookup(LOOKUP_MODELS_ENUM.QUOTING_SERVICE_TYPE.modelName)
  }

  findByServiceId(id: number) {

    return new Promise((resolve, reject) => {
      this.findAll().subscribe((quotes) => {
        if (quotes && quotes[0] && quotes[0].services) {
          resolve(quotes[0].services[0]);
        }
        if (quotes && quotes.services && quotes.services.offers) {
          resolve(quotes.services.offers[0]) //TODO
        }
      });
    });
    // return this.getAll().map(quotes => quotes.find(quote => quote.id == id))
  }

  findByServiceIdForEdit(id: number, idService: number) {
    return this.httpService().get([this.name, id, 'service', idService, 'edit']);
  }

  findAllServices(query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get([this.name, 'service', 'services'], this.toFilter(transformedQuery));
  }

  findServices(quote_id: number, query) {
    query.where.quote_id = quote_id;
    return this.httpService().get([this.name, quote_id, 'service'], {filter: query})
  }

  findRequests(quote_id: number, quote_service_id: number, query) {
    query.where.quote_service_id = quote_service_id;
    return this.httpService().get([this.name, quote_id, 'service', quote_service_id, 'offers'], {filter: query});
  }

  findAllRequests(quote_id: number, query) {
    return this.httpService().get([this.name, quote_id, 'service', 'offers', 'all'], {filter: query});
  }

  findQuoteHeaderRequests(quote_id: number, query) {
    return this.httpService().get([this.name, quote_id, 'offers'], {filter: query});
  }

  addService(quote_id: number, data): any {
    return this.httpService().post([this.name, quote_id, 'service'], data)
  }

  updateService(quoteServiceId: number, item: any): any {
    return this.httpService().put([this.name, quoteServiceId, 'service'], item)
  }

  findRequestForEdit(id: number, idService: number, idOffer: number) {
    return this.httpService().get([this.name, id, 'service', idService, 'offers', idOffer, 'edit'])
  }

  getRequests(quote_id: number, quoteServiceId: number) {
    return this.httpService().get([this.name, quote_id, 'service', quoteServiceId, 'offers'])
  }

  addRequest(quote_id: number, quoteServiceId: number, item) {
    return this.httpService().post([this.name, quote_id, 'service', quoteServiceId, 'offers'], item)
  }

  updateRequest(quote_id: number, quoteServiceId: number, quoteRequestId: number, item) {
    return this.httpService().put([this.name, quote_id, 'service', quoteServiceId, 'offers', quoteRequestId], item)
      .pipe(map((result) => {
        this.completeEdit();
        return result;
      }));
  }

  confirmQuote(quote_id: number, quoteServiceId: number, quoteRequestId: number, term: number) {
    return this.httpService().post([this.name, quote_id, 'service', quoteServiceId, 'offers', quoteRequestId, 'complete', term])
  }

  createRequest(quote_id: number, quoteServiceId: number, quoteRequestId: number, item) {
    return this.httpService().post([this.name, quote_id, 'service', quoteServiceId, 'offers', quoteRequestId], item)
  }

  getAllHubs(query) {
    return this.networkHubService.findAll(query)
      .pipe(flatMap((hubs: any) => {
        return observableOf(hubs.items.map((hub) => {
          return {
            id: hub.id,
            name: hub.hub_name,
            vendor_id: hub.vendor_id,
            vendor: hub.vendor
          }
        }))
      }))
  }

  getAllNpa(query) {
    return this.httpService().get(['equipments', 'lerg', 'npa'], query)
  }

  getAllNxx(query) {
    let npa = query.where.npa;
    return this.httpService().get(['equipments', 'lerg', 'nxx', npa], query)
  }

  updateQuote(quote_id: number, item) {
    return this.httpService().put([this.name, quote_id], item);
  }

  sendEmailToVendor(object: any) {
    return this.httpService().post([this.name, 'email', 'vendor', 'send'], object);
  }

  submitAPIRequest(quote_id: number, quote_service_id: number, vendor_id: number) {
    return this.httpService().post([this.name, quote_id, 'service', quote_service_id, 'api'], {
      vendor_id
    });
  }

  submitContractRequest(quote_id: number, quote_service_id: number, vendor_id: number) {
    return this.httpService().post([this.name, quote_id, 'service', quote_service_id, 'api'])
  }

  fetchQuoteServiceCount(quoteId: number) {
    return this.httpService().get([this.name, quoteId, 'service', 'count'])
  }

  csvMap(columns: any) {
    return columns
      .filter((column) => {
        return !!(column.dataField && column.caption);
      })
      .map((column) => {
        if (column.caption.indexOf('\u2191') !== -1) {
          column.caption = column.caption.replace('\u2191', '')
        }
        if (column.caption.indexOf('\u2193') !== -1) {
          column.caption = column.caption.replace('\u2193', '')
        }

        return {
          value: column.dataField,
          label: column.caption,
          type: column.dataType
        };
      });
  }


  sendBulk(quoteId: number, quoteRequests: Array<any>, email: any) {
    return this.httpService().post([this.name, quoteId, 'bulk'], {
      quote_requests: quoteRequests,
      email: email
    });
  }
}
