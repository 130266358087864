import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BandwidthService } from 'app/bandwidth/core/bandwidth.service';
import { Contact } from 'app/contact/core/contact';
import { ContactService } from 'app/contact/core/contact.service';
import Query from 'app/core/query/query';
import { CustomerService } from 'app/customer/core/customer.service';
import { DictionaryService } from 'app/dictionary/core/dictionary.service';
import { LOOKUP_MODELS_ENUM } from 'app/dictionary/core/lookup-models.enum';
import { InventoryService } from 'app/inventory/core/inventory.service';
import { VendorRemitAddressService } from 'app/vendor-remit-address/core/vendor-remit-address.service';
import { VendorEntityService } from 'app/vendor/core/vendor-entity.service';
import { VendorService } from 'app/vendor/core/vendor.service';

import { ApConfigurationService } from 'app/account/core/ap-configuration.service';
import { LocationService } from 'app/location/core/location.service';
import { SiteService } from 'app/site/core/site.service';
import { ContractService } from '../../../contract/core/contract.service';
import { SvcOrderService } from '../../../contract/core/svc-order.service';
import { UserService } from '../../../user/core/user.service';
import { ICustomValueHistory } from '../custom-value-history.interface';
import { History } from '../history';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { AuditsService } from 'app/audit/core/audits.service';
import { audit } from 'rxjs';

@Component({
	selector: 'app-history-isEqual-dialog',
	templateUrl: './history-compare-dialog.component.html',
	styleUrls: ['./history-compare-dialog.component.css']
})
export class HistoryCompareDialogComponent implements OnInit {
	readonly HISTORY_TYPES = {
		orderService: 'orderservice',
		quoteServiceRequest: 'quoteservicerequest',
		didTfn: 'tntfn',
		invoiceStatusHistory: 'invoicestatushistory',
		accountHistory: 'account',
		disputeHistory: 'dispute',
		order: 'order',
		contract: 'contract',
		contractSchedule: 'contract schedule',
		revenueCommitmentContractSchedule: 'revenue commitment contract schedule',
		contractScheduleTC: 'contract schedule terms & condition',
		contractRates: 'contract rates',
		inventoryGLString: 'inventory-gl-string',
		customerInventory: 'customerinventory',
		glString: 'string',
		invoiceChargeProfile: 'invoicechargeprofile'
	};

	readonly CONTRACT_SCHEDULE_SERIES: string =
		LOOKUP_MODELS_ENUM.CONTRACT_SCHEDULE_SERIES.modelName;
	public CONTRACT_VALUES = {
		CONTRACTS_KEY: 'contract_ids',
		CONTRACTS: 'Loading',
		CONTRACTS_TWO: 'Loading',

		SCHEDULE_SERIES_KEY: 'schedule_series_id',
		SCHEDULE_SERIES: 'Loading',
		SCHEDULE_SERIES_TWO: 'Loading',

		PRIOR_SCHEDULE_KEY: 'prior_schedule_id',
		PRIOR_SCHEDULE: 'Loading',
		PRIOR_SCHEDULE_TWO: 'Loading',

		CONTRACT_SCHEDULE_KEY: 'contract_schedule_id',
		CONTRACT_SCHEDULE: 'Loading',
		CONTRACT_SCHEDULE_TWO: 'Loading',

		COMMITMENT_CURRENCY_KEY: 'commitment_currency_id',
		COMMITMENT_CURRENCY: 'Loading',
		COMMITMENT_CURRENCY_TWO: 'Loading',

		COMMITMENT_TERM_TYPE_KEY: 'commit_term_type_id',
		COMMITMENT_TERM_TYPE: 'Loading',
		COMMITMENT_TERM_TYPE_TWO: 'Loading',

		SCHEDULE_KEY: 'schedule_id',
		SCHEDULE: 'Loading',
		SCHEDULE_TWO: 'Loading'
	};

	public NETWORK_INVENTORY_VALUES = {
		SVC_ORDER_SERVICES_KEY: 'svc_order_service_ids',
		SVC_ORDER_SERVICES: 'Loading',
		SVC_ORDER_SERVICES_TWO: 'Loading',

		VENDOR_KEY: 'vendor_id',
		VENDOR: 'Loading',
		VENDOR_TWO: 'Loading',

		CUSTOMER_KEY: 'customer_id',
		CUSTOMER: 'Loading',
		CUSTOMER_TWO: 'Loading',

		SITE_A_KEY: 'site_a_id',
		SITE_A: 'Loading',
		SITE_A_TWO: 'Loading',

		SITE_Z_KEY: 'site_z_id',
		SITE_Z: 'Loading',
		SITE_Z_TWO: 'Loading',

		VENDOR_ENTITY_KEY: 'vendor_entity',
		VENDOR_ENTITY: 'Loading',
		VENDOR_ENTITY_TWO: 'Loading',

		VENDOR_LOCATION_ID_KEY: 'vendor_location_id',
		VENDOR_LOCATION_ID: 'Loading',
		VENDOR_LOCATION_ID_TWO: 'Loading',

		BW_MAX_KEY: 'bw_max_id',
		BW_MAX: 'Loading',
		BW_MAX_TWO: 'Loading',

		CIRCUIT_BW_KEY: 'cir_bw_id',
		CIRCUIT_BW: 'Loading',
		CIRCUIT_BW_TWO: 'Loading',

		STATUS_KEY: 'status_id',
		STATUS: 'Loading',
		STATUS_TWO: 'Loading',

		CIRCUIT_TYPE_KEY: 'ckt_type_id',
		CIRCUIT_TYPE: 'Loading',
		CIRCUIT_TYPE_TWO: 'Loading',

		TECHNOLOGY_KEY: 'technology_id',
		TECHNOLOGY: 'Loading',
		TECHNOLOGY_TWO: 'Loading',

		BW_MODEL_KEY: 'bw_model_id',
		BW_MODEL: 'Loading',
		BW_MODEL_TWO: 'Loading',

		CKT_USAGE_KEY: 'ckt_usage_id',
		CKT_USAGE: 'Loading',
		CKT_USAGE_TWO: 'Loading',

		PROTECTED_KEY: 'protected_id',
		PROTECTED: 'Loading',
		PROTECTED_TWO: 'Loading',

		PRIM_FAILOVER_KEY: 'prim_failover_id',
		PRIM_FAILOVER: 'Loading',
		PRIM_FAILOVER_TWO: 'Loading',

		TOPOLOGY_KEY: 'topology_id',
		TOPOLOGY: 'Loading',
		TOPOLOGY_TWO: 'Loading',

		HANDOFF_TYPE_KEY: 'handoff_type_id',
		HANDOFF_TYPE: 'Loading',
		HANDOFF_TYPE_TWO: 'Loading',

		SITEA_LOCAL_CONTACT_KEY: 'site_a_lcontact_id',
		SITEA_LOCAL_CONTACT: 'Loading',
		SITEA_LOCAL_CONTACT_TWO: 'Loading',

		SITEZ_LOCAL_CONTACT_KEY: 'site_z_lcontact_id',
		SITEZ_LOCAL_CONTACT: 'Loading',
		SITEZ_LOCAL_CONTACT_TWO: 'Loading',

		USER_ID_KEY: 'user_id',
		USER_ID: 'Loading',
		USER_ID_TWO: 'Loading',

		WITHHELD_STATUS_KEY: 'withheld_status',
		WITHHELD_STATUS: 'Loading',
		WITHHELD_STATUS_TWO: 'Loading',

		CURRENCY_KEY: 'currency_id',
		CURRENCY: 'Loading',
		CURRENCY_TWO: 'Loading',

		COUNTRY_KEY: 'country_id',
		COUNTRY: 'Loading',
		COUNTRY_TWO: 'Loading',

		CONFIGURATION_KEY: 'ap_configuration_id',
		CONFIGURATION: 'Loading',
		CONFIGURATION_TWO: 'Loading',

		SUBCLIENT_KEY: 'subclient_id',
		SUBCLIENT: 'Loading',
		SUBCLIENT_TWO: 'Loading',

		REGION_KEY: 'region_id',
		REGION: 'Loading',
		REGION_TWO: 'Loading',

		BILLING_FREQ_KEY: 'billing_freq_id',
		BILLING_FREQ: 'Loading',
		BILLING_FREQ_TWO: 'Loading',

		PAY_METH_KEY: 'pay_meth',
		PAY_METH: 'Loading',
		PAY_METH_TWO: 'Loading',

		MEDIA_TYP_KEY: 'media_typ',
		MEDIA_TYP: 'Loading',
		MEDIA_TYP_TWO: 'Loading',

		CONTRACT_TYPE_KEY: 'contract_type_id',
		CONTRACT_TYPE: 'Loading',
		CONTRACT_TYPE_TWO: 'Loading'
	};

	public CUSTOMER_INVENTORY_VALUES = {
		PORT_BANDWIDTH_KEY: 'port_bandwidth_id',
		PORT_BANDWIDTH: 'Loading',
		PORT_BANDWIDTH_TWO: 'Loading',

		BW_MODEL_KEY: 'bandwidth_model',
		BW_MODEL: 'Loading',
		BW_MODEL_TWO: 'Loading',

		TOPOLOGY_KEY: 'circuit_type',
		TOPOLOGY: 'Loading',
		TOPOLOGY_TWO: 'Loading',

		STATUS_KEY: 'status',
		STATUS: 'Loading',
		STATUS_TWO: 'Loading',

		TECHNOLOGY_KEY: 'product_id',
		TECHNOLOGY: 'Loading',
		TECHNOLOGY_TWO: 'Loading'
	};

	public GL_RULES = {
		CONTEXT: 'context',
		ACCOUNTS: 'account_changes'
	};

	public QUOTING_VALUES = {
		QUOTE_SERVICE_TYPE_KEY: 'service_type',
		QUOTE_SERVICE_TYPE: 'Loading',
		QUOTE_SERVICE_TYPE_TWO: 'Loading',

		CIRCUIT_BENDWIDTH_KEY: 'circuit_bandwidth_id',
		CIRCUIT_BENDWIDTH: 'Loading',
		CIRCUIT_BENDWIDTH_TWO: 'Loading',

		QUOTE_SERVICE_STATUS_KEY: 'service_status',
		QUOTE_SERVICE_STATUS: 'Loading',
		QUOTE_SERVICE_STATUS_TWO: 'Loading'
	};

	public INVOICE_VALUES = {
		INVOICE_ATTACHMENT_TYPE_KEY: 'attachment_type_id',
		INVOICE_ATTACHMENT_TYPE: 'Loading',
		INVOICE_ATTACHMENT_TYPE_TWO: 'Loading'
	};
	public CHARGE_VALUES = {
		CHARGE_REASON_CODE_KEY: 'gl_rule_type_id',
		CHARGE_REASON_CODE: 'Loading',
		CHARGE_REASON_CODE_TWO: 'Loading'
	};

	public INVOICE_CHARGE_PROFILE_VALUES = {
		CHARGE_PROFILE_AUDIT_RULE_TYPE_KEY: 'audit_rule_type',
		CHARGE_PROFILE_AUDIT_RULE_TYPE: 'Loading',
		CHARGE_PROFILE_AUDIT_RULE_TYPE_TWO: 'Loading'
	};

	public STRING_RULES = {
		CUSTOM_RULE: 'Custom',
		SPID_RULE: 'SPID',
		INVENTORY_RULE: 'Inventory',
		SUBACCOUNT_RULE: 'Subaccount',
		BAN_RULE: 'BAN'
	};

	public historyItem;
	public historyItemTwo;
	public invoice;

	// TODO: filter this so that we only get history of the same type
	public historyAll;

	// TODO: rename this 'historyOneId'...
	public historyOne;
	public historyTwo;

	// REVIEW: probably don't need this
	public historyOneMetaData = [];

	// TODO: rename this
	temp;

	changedBy: string;
	changedByTwo: string;

	status: string;
	statusTwo: string;

	currency: string;

	contactFunctions = [];
	isSecureContext: boolean = window.isSecureContext;

	constructor(
		public dialogRef: MatDialogRef<HistoryCompareDialogComponent>,
		public userService: UserService,
		private vendorService: VendorService,
		private vendorEntityService: VendorEntityService,
		private vendorRemitAddressService: VendorRemitAddressService,
		private customerService: CustomerService,
		private bwService: BandwidthService,
		private disctionaryService: DictionaryService,
		private inventoryService: InventoryService,
		private contactService: ContactService,
		public dictionaryService: DictionaryService,
		public locationService: LocationService,
		private apConfigurationService: ApConfigurationService,
		private siteService: SiteService,
		private contractService: ContractService,
		private svcOrderService: SvcOrderService,
		private auditService: AuditsService
	) {}

	async ngOnInit() {
		this.historyOne = this.historyItem.id;
		this.historyOneMetaData = this.historyItem.meta_data;

		if (this.historyItem.meta_data.changed_by_id) {
			this.getUserFullName(this.historyItem.meta_data.changed_by_id.value, 1);
		}

		if (this.historyItem.meta_data.status) {
			this.getStatus(this.historyItem.meta_data.status.value, 1);
		}

		if (this.historyItem.meta_data.currency_id?.value) {
			this.locationService
				.findCurrencyById(this.historyItem.meta_data.currency_id?.value)
				.subscribe((result) => {
					this.currency = result.currency;
				});
		}

		this.generateValuesFromIds();

		this.temp = Object.keys(this.historyItem.meta_data);

		const cf = await this.dictionaryService
			.getByLookup(LOOKUP_MODELS_ENUM.CONTACT_FUNCTION.modelName)
			.toPromise();
		if (cf.items) {
			this.contactFunctions = [...cf.items];
			this.assignFunctionValues(this.historyItem);
		}
	}

	public assignFunctionValues(historyItem) {
		const contacts = historyItem.meta_data.contacts;

		if (!this.contactFunctions || !contacts) return;

		let contacts_string = '';
		if (contacts.value && Array.isArray(contacts.value)) {
			contacts.value.forEach((contact) => {
				const found = this.contactFunctions.filter(
					(cf) => cf.key == contact.contact_function
				);
				if (found && contact.first_name && contact.last_name) {
					contacts_string += `${contacts_string.length ? '\n' : ''}`;
					contacts_string += `${found[0].value}: ${contact.first_name} ${contact.last_name}`;
				}
			});
		}
		contacts.text = contacts_string;
	}

	public close() {
		this.dialogRef.close();
	}

	public onDateOneChange(value) {
		if (value) {
			this.historyItem = this.getHistoryItemFromId(value.value);
			if (this.historyItem.meta_data.changed_by_id) {
				this.getUserFullName(this.historyItem.meta_data.changed_by_id.value, 2);
			}

			if (this.historyItem.meta_data.status) {
				this.getStatus(this.historyItem.meta_data.status.value, 1);
			}

			this.generateValuesFromIds();
			this.assignFunctionValues(this.historyItem);
		}
	}

	public onDateTwoChange(value) {
		if (value) {
			this.historyItemTwo = this.getHistoryItemFromId(value.value);

			this.generateValuesFromIds(2);
		}
		if (this.historyItemTwo.meta_data.changed_by_id) {
			this.getUserFullName(
				this.historyItemTwo.meta_data.changed_by_id.value,
				2
			);
		}

		if (this.historyItemTwo.meta_data.status) {
			this.getStatus(this.historyItemTwo.meta_data.status.value, 2);
		}

		this.generateValuesFromIds(2);
		this.assignFunctionValues(this.historyItemTwo);
	}

	public getHistoryItemFromId(historyId: number) {
		return this.historyAll.filter((item) => item.id === historyId)[0];
	}

	// TODO: what does this exactly achieve?
	public checkDate = function (f: any) {
		return false;
	};

	public isBool(val) {
		return typeof val === 'boolean';
	}

	public getHistoryTitle(item: History): string | number {
		switch (item.entity_type) {
			case this.HISTORY_TYPES.order:
				return `Order ${(item.meta_data.full_order_id && item.meta_data.full_order_id.value) || this.historyItem.full_order_id || item.entity_id}`;
			case this.HISTORY_TYPES.orderService:
				return `Order Service ${(item.meta_data.full_service_id && item.meta_data.full_service_id.value) || item.entity_id}`;
			case this.HISTORY_TYPES.quoteServiceRequest:
				return 'Quote Service Request';
			case this.HISTORY_TYPES.didTfn:
				return 'DID/TFN';
			case this.HISTORY_TYPES.invoiceStatusHistory:
				return (
					'status of Invoice ' +
					`${this.invoice.sp_inv_num || item.parent_id || item.entity_id}`
				);
			case this.HISTORY_TYPES.accountHistory:
				return `${item.entity_type} ${item.meta_data.account_no.value || item.entity_id}`;
			case this.HISTORY_TYPES.disputeHistory:
				return `${item.entity_type} ${item.meta_data.dispute_id.value || item.entity_id}`;
			case this.HISTORY_TYPES.contract:
				return `Contract ${item.entity_id}`;
			case this.HISTORY_TYPES.contractSchedule:
				return `Contract Schedule ${item.entity_id}`;
			case this.HISTORY_TYPES.revenueCommitmentContractSchedule:
				return `Revenue Commitment Contract Schedule ${item.entity_id}`;
			case this.HISTORY_TYPES.contractScheduleTC:
				return `Contract Schedule T/C ${item.entity_id}`;
			case this.HISTORY_TYPES.contractRates:
				return `Contract Rates, Contract ${item.entity_id}`;
			case this.HISTORY_TYPES.inventoryGLString:
			case this.HISTORY_TYPES.customerInventory:
				return `${item.meta_data.meta_title} ${item.entity_id}`;
			case this.HISTORY_TYPES.glString:
				return item.meta_data.meta_title
					? `${item.meta_data.meta_title} ${item.entity_id}`
					: `GL String ${item.entity_id}`;
			case this.HISTORY_TYPES.invoiceChargeProfile:
				return item.meta_data.meta_title
					? `${item.meta_data.meta_title} ${item.entity_id}`
					: `Invoice Charge Profile ${item.entity_id}`;
			default:
				return `${item.entity_type} ${item.entity_id}`;
		}
	}

	public getCustomRules(rules: Array<any>) {
		let rules_string = '';
		if (rules) {
			rules.forEach((rule) => {
				rules_string += `${rules_string.length ? ', ' : ''}`;
				rules_string += `${this.STRING_RULES[rule]}`;
			});
		} else {
			rules_string += 'N/A';
		}
		return rules_string;
	}

	public getCustomValueText(
		custom_value: Array<ICustomValueHistory> = []
	): string {
		let custom_value_string = '';

		if (custom_value && Array.isArray(custom_value)) {
			custom_value.forEach((val: ICustomValueHistory) => {
				custom_value_string += `${custom_value_string.length ? '\n' : ''}`;
				custom_value_string += `${val.name}: ${val.value || 'N/A'}`;
			});
		} else if (custom_value && typeof custom_value == 'object') {
			const keys = Object.keys(custom_value);
			for (const [key, value] of Object.entries(custom_value)) {
				let arr =
					key.indexOf('_') != -1
						? key
								.split('_')
								.map((elm) => elm.charAt(0).toUpperCase() + elm.slice(1))
						: [key.charAt(0).toUpperCase() + key.slice(1)];
				custom_value_string += `${custom_value_string.length ? '\n' : ''}`;
				custom_value_string += `${arr.join(' ')}: ${value || 'N/A'}`;
			}
		} else {
			custom_value_string = 'N/A';
		}

		return custom_value_string;
	}

	public getContextText(custom_value: Array<any> = []): string {
		return JSON.stringify(custom_value, null, 4);
	}

	public getAccountContextText(custom_values: Array<any> = []): Array<string> {
		return custom_values?.map((value) => Object.values(value).join(', '));
	}

	private copyAccountChangesContent(content: Array<string>) {
		const stringValue = content?.join('\n');
		navigator.clipboard.writeText(stringValue || '');
	}

	public copyContent(key: string, isLeftContentSpace = false) {
		const historyItem = this.getHistoryItem(isLeftContentSpace);
		const value = historyItem.meta_data[key]?.value || [];
		const content = this.getAccountContextText(value);
		this.copyAccountChangesContent(content);
	}

	public isContentPresent(key: string, isLeftContentSpace = false) {
		return !!this.getHistoryItem(isLeftContentSpace).meta_data[key]?.value;
	}

	private getHistoryItem(isLeftContentSpace: boolean) {
		return isLeftContentSpace ? this.historyItem : this.historyItemTwo;
	}

	public getUserFullName(userId: number, index: number) {
		this.userService.findById(userId).subscribe((result) => {
			if (index === 1) {
				this.changedBy = result.full_name;
			} else {
				this.changedByTwo = result.full_name;
			}
		});
	}

	public getStatus(statusId: number, index: number) {
		for (let item in LOOKUP_ENUM.INVOICE_STATUS) {
			if (LOOKUP_ENUM.INVOICE_STATUS[item] === statusId) {
				if (index === 1) {
					this.status = item;
				} else {
					this.statusTwo = item;
				}
			}
		}
	}

	private async getInventoryContractType(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CONTRACT_TYPE_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.CONTRACT_TYPE = 'Loading');

			const contractType = await this.inventoryService
				.findContractTypeById(id)
				.toPromise();
			const value =
				contractType && contractType['value'] ? contractType['value'] : 'N/A';

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CONTRACT_TYPE_TWO = value)
				: (this.NETWORK_INVENTORY_VALUES.CONTRACT_TYPE = value);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CONTRACT_TYPE_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.CONTRACT_TYPE = 'N/A');
		}
	}

	private async getSvcOrderServiceNames(
		ids: string,
		historyItem?: 1 | 2
	): Promise<any> {
		if (ids) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SVC_ORDER_SERVICES_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.SVC_ORDER_SERVICES = 'Loading');

			const svcOrderServiceIds = ids.indexOf(', ') ? ids.split(', ') : [...ids];
			const svcOrderServices = await this.svcOrderService
				.findAllServices(
					null,
					new Query({
						where: { id: { $in: svcOrderServiceIds } }
					})
				)
				.toPromise();

			const value =
				svcOrderServices && svcOrderServices['items']
					? svcOrderServices['items']
							.reduce((acc, item) => [...acc, item.description], [])
							.sort()
							.join(', ')
					: 'N/A';

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SVC_ORDER_SERVICES_TWO = value)
				: (this.NETWORK_INVENTORY_VALUES.SVC_ORDER_SERVICES = value);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SVC_ORDER_SERVICES_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.SVC_ORDER_SERVICES = 'N/A');
		}
	}

	private async getContractNames(
		ids: string,
		historyItem?: 1 | 2
	): Promise<any> {
		if (ids) {
			historyItem === 2
				? (this.CONTRACT_VALUES.CONTRACTS_TWO = 'Loading')
				: (this.CONTRACT_VALUES.CONTRACTS = 'Loading');

			const contract_ids = ids.indexOf(', ') ? ids.split(', ') : [...ids];
			const contracts = await this.contractService
				.findOnlyContracts(
					new Query({
						where: { id: { $in: contract_ids } }
					})
				)
				.toPromise();
			const value =
				contracts && contracts['items']
					? contracts['items']
							.reduce((acc, item) => [...acc, item.name], [])
							.sort()
							.join(', ')
					: 'N/A';

			historyItem === 2
				? (this.CONTRACT_VALUES.CONTRACTS_TWO = value)
				: (this.CONTRACT_VALUES.CONTRACTS = value);
		} else {
			historyItem === 2
				? (this.CONTRACT_VALUES.CONTRACTS_TWO = 'N/A')
				: (this.CONTRACT_VALUES.CONTRACTS = 'N/A');
		}
	}

	private async getScheduleSeriesName(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.CONTRACT_VALUES.SCHEDULE_SERIES_TWO = 'Loading')
				: (this.CONTRACT_VALUES.SCHEDULE_SERIES = 'Loading');

			const scheduleSeries = await this.contractService
				.findScheduleSeriesById(id)
				.toPromise();
			const value =
				scheduleSeries && scheduleSeries['value']
					? scheduleSeries['value']
					: 'N/A';

			historyItem === 2
				? (this.CONTRACT_VALUES.SCHEDULE_SERIES_TWO = value)
				: (this.CONTRACT_VALUES.SCHEDULE_SERIES = value);
		} else {
			historyItem === 2
				? (this.CONTRACT_VALUES.SCHEDULE_SERIES_TWO = 'N/A')
				: (this.CONTRACT_VALUES.SCHEDULE_SERIES = 'N/A');
		}
	}

	private async getScheduleName(
		id: number,
		{ leftEntity, rightEntity },
		historyItem?: 1 | 2
	): Promise<any> {
		if (id && id !== 0) {
			historyItem === 2
				? (this.CONTRACT_VALUES[rightEntity] = 'Loading')
				: (this.CONTRACT_VALUES[leftEntity] = 'Loading');

			const schedule = await this.contractService
				.findScheduleById(id)
				.toPromise();
			const value =
				schedule && schedule['schedule_name']
					? schedule['schedule_name']
					: 'N/A';

			historyItem === 2
				? (this.CONTRACT_VALUES[rightEntity] = value)
				: (this.CONTRACT_VALUES[leftEntity] = value);
		} else {
			historyItem === 2
				? (this.CONTRACT_VALUES[rightEntity] = 'N/A')
				: (this.CONTRACT_VALUES[leftEntity] = 'N/A');
		}
	}

	private async getCommitmentCurrency(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id && id !== 0) {
			historyItem === 2
				? (this.CONTRACT_VALUES.COMMITMENT_CURRENCY_TWO = 'Loading')
				: (this.CONTRACT_VALUES.COMMITMENT_CURRENCY = 'Loading');

			const currency = await this.locationService
				.findCurrencyById(id)
				.toPromise();
			const value =
				currency && currency['currency'] ? currency['currency'] : 'N/A';

			historyItem === 2
				? (this.CONTRACT_VALUES.COMMITMENT_CURRENCY_TWO = value)
				: (this.CONTRACT_VALUES.COMMITMENT_CURRENCY = value);
		} else {
			historyItem === 2
				? (this.CONTRACT_VALUES.COMMITMENT_CURRENCY_TWO = 'N/A')
				: (this.CONTRACT_VALUES.COMMITMENT_CURRENCY = 'N/A');
		}
	}

	private async getCommitmentTermType(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id && id !== 0) {
			historyItem === 2
				? (this.CONTRACT_VALUES.COMMITMENT_TERM_TYPE_TWO = 'Loading')
				: (this.CONTRACT_VALUES.COMMITMENT_TERM_TYPE = 'Loading');

			const commitmentType = await this.contractService
				.findScheduleCommitmentTypeById(id)
				.toPromise();
			const value =
				commitmentType && commitmentType['value']
					? commitmentType['value']
					: 'N/A';

			historyItem === 2
				? (this.CONTRACT_VALUES.COMMITMENT_TERM_TYPE_TWO = value)
				: (this.CONTRACT_VALUES.COMMITMENT_TERM_TYPE = value);
		} else {
			historyItem === 2
				? (this.CONTRACT_VALUES.COMMITMENT_TERM_TYPE_TWO = 'N/A')
				: (this.CONTRACT_VALUES.COMMITMENT_TERM_TYPE = 'N/A');
		}
	}

	private generateValuesFromIds(historyItem?: 1 | 2) {
		const { contract_type_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		if (contract_type_id) {
			const { value } = contract_type_id;
			this.getInventoryContractType(value, historyItem);
		}

		const { svc_order_service_ids } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		const { svc_order_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		if (svc_order_service_ids) {
			const { value } = svc_order_service_ids;
			this.getSvcOrderServiceNames(value, historyItem);
		}

		const { contract_ids } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		if (contract_ids) {
			const { value } = contract_ids;
			this.getContractNames(value, historyItem);
		}

		const { schedule_series_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		if (schedule_series_id) {
			const { value } = schedule_series_id;
			this.getScheduleSeriesName(value, historyItem);
		}

		const { prior_schedule_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		if (prior_schedule_id) {
			const { value } = prior_schedule_id;
			this.getScheduleName(
				value,
				{
					leftEntity: 'PRIOR_SCHEDULE',
					rightEntity: 'PRIOR_SCHEDULE_TWO'
				},
				historyItem
			);
		}

		const { contract_schedule_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		if (contract_schedule_id) {
			const { value } = contract_schedule_id;
			this.getScheduleName(
				value,
				{
					leftEntity: 'CONTRACT_SCHEDULE',
					rightEntity: 'CONTRACT_SCHEDULE_TWO'
				},
				historyItem
			);
		}

		const { commitment_currency_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		if (commitment_currency_id) {
			const { value } = commitment_currency_id;
			this.getCommitmentCurrency(value, historyItem);
		}

		const { commit_term_type_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		if (commit_term_type_id) {
			const { value } = commit_term_type_id;
			this.getCommitmentTermType(value, historyItem);
		}

		// get vendor name
		const { vendor_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (vendor_id) {
			const { value } = vendor_id;
			this.getVendorName(value, historyItem);
		}

		// get vendor entity name
		const { vendor_entity } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (vendor_entity) {
			const { value } = vendor_entity;
			this.getVendorEntityName(value, historyItem);
		}

		// get vendor location id name
		const { vendor_location_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (vendor_location_id) {
			const { value } = vendor_location_id;
			this.getVendorLocationId(value, historyItem);
		}

		// get customer name
		const { customer_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (customer_id) {
			const { value } = customer_id;
			this.getCustomerName(value, historyItem);
		}

		// get site a name
		const { site_a_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (site_a_id) {
			const { value } = site_a_id;
			this.getSiteAName(value, historyItem);
		}

		// get site z name
		const { site_z_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (site_z_id) {
			const { value } = site_z_id;
			this.getSiteZName(value, historyItem);
		}

		// get bw max
		const { bw_max_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (bw_max_id) {
			const { value } = bw_max_id;
			this.getBWValue(value, historyItem);
		}

		// get circuit bw
		const { cir_bw_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (cir_bw_id) {
			const { value } = cir_bw_id;
			this.getCircuitBwValue(value, historyItem);
		}

		// get customer inventory port bw
		const { port_bandwidth_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (port_bandwidth_id) {
			const { value } = port_bandwidth_id;
			this.getPortBandwithValue(value, historyItem);
		}

		// get status
		const { status_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (status_id) {
			const { value } = status_id;
			if (
				this.historyItem.entity_type ===
				LOOKUP_ENUM.HISTORY_ENTITY_TYPE.inventory
			) {
				this.getInventoryStatusValue(value, historyItem);
			}
			if (
				this.historyItem.entity_type === LOOKUP_ENUM.HISTORY_ENTITY_TYPE.account
			) {
				this.getAccountStatusValue(value, historyItem);
			}
			if (
				this.historyItem.entity_type ===
					LOOKUP_ENUM.HISTORY_ENTITY_TYPE.dispute ||
				this.historyItem.entity_type ===
					LOOKUP_ENUM.HISTORY_ENTITY_TYPE.dispute_charge
			) {
				this.getDisputeStatusValue(value, historyItem);
			}
		}
		//SSS
		const { status } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (status) {
			const { value } = status;

			if (
				this.historyItem.entity_type === LOOKUP_ENUM.HISTORY_ENTITY_TYPE.dispute
			) {
				this.getDisputeStatusValue(value, historyItem);
			}
			if (
				this.historyItem.entity_type ===
				LOOKUP_ENUM.HISTORY_ENTITY_TYPE.customer_inventory
			) {
				this.getCustomerInventoryStatusValue(value, historyItem);
			}
		}

		// get user status
		const { user_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;
		if (user_id) {
			const { value } = user_id;
			this.getUserValue(value, historyItem);
		}

		// get ckt_type
		const { ckt_type_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (ckt_type_id) {
			const { value } = ckt_type_id;
			this.getCktTypeValue(value, historyItem);
		}

		// get technology
		const { technology_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (technology_id) {
			const { value } = technology_id;
			this.getTechnologyValue(value, historyItem);
		}

		// get bw_model
		const { bw_model_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (bw_model_id) {
			const { value } = bw_model_id;
			this.getBwModelValue(value, historyItem);
		}

		const { bandwidth_model } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (bandwidth_model) {
			const { value } = bandwidth_model;
			this.getCustomerInventoryBwModelValue(value, historyItem);
		}

		// get ckt_usage
		const { ckt_usage_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (ckt_usage_id) {
			const { value } = ckt_usage_id;
			this.getCktUsageValue(value, historyItem);
		}

		// get protected
		const { protected_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (protected_id) {
			const { value } = protected_id;
			this.getProtectedValue(value, historyItem);
		}

		// get prim_failover
		const { prim_failover_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (prim_failover_id) {
			const { value } = prim_failover_id;
			this.getPrimFailoverValue(value, historyItem);
		}

		// get topology
		const { topology_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (topology_id) {
			const { value } = topology_id;
			this.getTopologyValue(value, historyItem);
		}

		// get topology
		const { circuit_type } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (circuit_type) {
			const { value } = circuit_type;
			this.getCustomerInventoryTopologyValue(value, historyItem);
		}

		// get customer inventory technology
		const { product_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (product_id) {
			const { value } = product_id;
			this.getCustomerInventoryTechnologyValue(value, historyItem);
		}

		// get handoff_type
		const { handoff_type_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (handoff_type_id) {
			const { value } = handoff_type_id;
			this.getHandoffTypeValue(value, historyItem);
		}
		// get attachemnt_type
		const { attachment_type_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (attachment_type_id) {
			const { value } = attachment_type_id;
			this.getInvoiceAttachmentType(value, historyItem);
		}

		// get site_a_local_contact
		const { site_a_lcontact_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (site_a_lcontact_id) {
			const { value } = site_a_lcontact_id;
			this.getSiteALocalContactValue(value, historyItem);
		}

		// get site_z_local_contact
		const { site_z_lcontact_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (site_z_lcontact_id) {
			const { value } = site_z_lcontact_id;
			this.getSiteZLocalContactValue(value, historyItem);
		}

		// get withheldValue
		const { withheld_status } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (withheld_status) {
			const { value } = withheld_status;
			this.getWithheldValue(value, historyItem);
		}

		// get currencyValue
		const { currency_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (currency_id) {
			const { value } = currency_id;
			this.getCurrencyValue(value, historyItem);
		}

		// get country name
		const { country_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (country_id) {
			const { value } = country_id;
			this.getCountryName(value, historyItem);
		}

		// get configuration name
		const { ap_configuration_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (ap_configuration_id) {
			const { value } = ap_configuration_id;
			this.getConfigurationName(value, historyItem);
		}

		// get subclient name
		const { subclient_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (subclient_id) {
			const { value } = subclient_id;
			this.getSubclientName(value, historyItem);
		}

		// get region name
		const { region_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (region_id) {
			const { value } = region_id;
			this.getRegionName(value, historyItem);
		}

		// get media type name
		const { media_typ } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (media_typ) {
			const { value } = media_typ;
			this.getMediaTypeName(value, historyItem);
		}

		// get payment method name
		const { pay_meth } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (pay_meth) {
			const { value } = pay_meth;
			this.getPaymentMethodName(value, historyItem);
		}

		// get payment method name
		const { billing_freq_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (billing_freq_id) {
			const { value } = billing_freq_id;
			this.getBillingFrequencyName(value, historyItem);
		}
		// get charge reason code
		const { gl_rule_type_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (gl_rule_type_id) {
			const { value } = gl_rule_type_id;
			this.getChargeReasonCodeValue(value, historyItem);
		}

		// quoting ------------------------------------------
		// get ServiceTypeValue
		const { service_type } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (service_type) {
			const { value } = service_type;
			this.getServiceTypeValue(value, historyItem);
		}

		// get circuit bw
		const { circuit_bandwidth_id } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (circuit_bandwidth_id) {
			const { value } = circuit_bandwidth_id;
			this.getCircuitBendwithValue(value, historyItem);
		}

		// get service status
		const { service_status } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (service_status) {
			const { value } = service_status;
			this.getQuotingStatusValue(value, historyItem);
		}

		// Get Audit rule type
		const { audit_rule_type } =
			historyItem === 2
				? this.historyItemTwo.meta_data
				: this.historyItem.meta_data;

		if (audit_rule_type) {
			const { value } = audit_rule_type;
			this.getChargeProfileAudtRuleType(value, historyItem);
		}
		// end quoting ------------------------------------------
	}

	// Quoting Values :: start
	private async getServiceTypeValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.QUOTING_VALUES.QUOTE_SERVICE_TYPE_TWO = 'Loading')
				: (this.QUOTING_VALUES.QUOTE_SERVICE_TYPE = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.QUOTING_SERVICE_TYPE.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let quotingServiceTypeValue = results.items[0].value;
					if (quotingServiceTypeValue) {
						historyItem === 2
							? (this.QUOTING_VALUES.QUOTE_SERVICE_TYPE_TWO =
									quotingServiceTypeValue)
							: (this.QUOTING_VALUES.QUOTE_SERVICE_TYPE =
									quotingServiceTypeValue);
					}
				});
		} else {
			historyItem === 2
				? (this.QUOTING_VALUES.QUOTE_SERVICE_TYPE_TWO = 'N/A')
				: (this.QUOTING_VALUES.QUOTE_SERVICE_TYPE = 'N/A');
		}
	}

	private async getCircuitBendwithValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.QUOTING_VALUES.CIRCUIT_BENDWIDTH_TWO = 'Loading')
				: (this.QUOTING_VALUES.CIRCUIT_BENDWIDTH = 'Loading');

			const { value } = await this.bwService.findById(id).toPromise();

			historyItem === 2
				? (this.QUOTING_VALUES.CIRCUIT_BENDWIDTH_TWO = value)
				: (this.QUOTING_VALUES.CIRCUIT_BENDWIDTH = value);
		} else {
			historyItem === 2
				? (this.QUOTING_VALUES.CIRCUIT_BENDWIDTH_TWO = 'N/A')
				: (this.QUOTING_VALUES.CIRCUIT_BENDWIDTH = 'N/A');
		}
	}

	private async getPortBandwithValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH_TWO = 'Loading')
				: (this.CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH = 'Loading');

			const { value } = await this.bwService.findById(id).toPromise();

			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH_TWO = value)
				: (this.CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH = value);
		} else {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH_TWO = 'N/A')
				: (this.CUSTOMER_INVENTORY_VALUES.PORT_BANDWIDTH = 'N/A');
		}
	}

	private async getQuotingStatusValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.QUOTING_VALUES.QUOTE_SERVICE_STATUS_TWO = 'Loading')
				: (this.QUOTING_VALUES.QUOTE_SERVICE_STATUS = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.QUOTE_SERVICE_STATUS.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let quotingServiceStatus = results.items[0].value;
					if (quotingServiceStatus) {
						historyItem === 2
							? (this.QUOTING_VALUES.QUOTE_SERVICE_STATUS_TWO =
									quotingServiceStatus)
							: (this.QUOTING_VALUES.QUOTE_SERVICE_STATUS =
									quotingServiceStatus);
					}
				});
		} else {
			historyItem === 2
				? (this.QUOTING_VALUES.QUOTE_SERVICE_STATUS_TWO = 'N/A')
				: (this.QUOTING_VALUES.QUOTE_SERVICE_STATUS = 'N/A');
		}
	}

	// Quoting Values :: end

	// Contract Values :: start

	// Contract Values :: end

	// Inventory Values :: start
	private async getVendorName(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.VENDOR_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.VENDOR = 'Loading');

			const { name } = await this.vendorService.findById(id).toPromise();

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.VENDOR_TWO = name)
				: (this.NETWORK_INVENTORY_VALUES.VENDOR = name);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.VENDOR_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.VENDOR = 'N/A');
		}
	}

	private async getVendorEntityName(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.VENDOR_ENTITY_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.VENDOR_ENTITY = 'Loading');

			const { name } = await this.vendorEntityService.findById(id).toPromise();
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.VENDOR_ENTITY_TWO = name)
				: (this.NETWORK_INVENTORY_VALUES.VENDOR_ENTITY = name);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.VENDOR_ENTITY_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.VENDOR_ENTITY = 'N/A');
		}
	}

	private async getVendorLocationId(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID = 'Loading');

			const { vendor_location_id: name } = await this.vendorRemitAddressService
				.findById(id)
				.toPromise();
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID_TWO = name)
				: (this.NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID = name);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.VENDOR_LOCATION_ID = 'N/A');
		}
	}

	private async getCustomerName(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CUSTOMER_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.CUSTOMER = 'Loading');

			const { company } = await this.customerService.findById(id).toPromise();

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CUSTOMER_TWO = company)
				: (this.NETWORK_INVENTORY_VALUES.CUSTOMER = company);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CUSTOMER_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.CUSTOMER = 'N/A');
		}
	}

	private async getSiteAName(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITE_A_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.SITE_A = 'Loading');

			const { site_id } = await this.siteService.findById(id).toPromise();

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITE_A_TWO = site_id)
				: (this.NETWORK_INVENTORY_VALUES.SITE_A = site_id);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITE_A_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.SITE_A = 'N/A');
		}
	}

	private async getSiteZName(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITE_Z_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.SITE_Z = 'Loading');

			const { site_id } = await this.siteService.findById(id).toPromise();

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITE_Z_TWO = site_id)
				: (this.NETWORK_INVENTORY_VALUES.SITE_Z = site_id);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITE_Z_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.SITE_Z = 'N/A');
		}
	}

	private async getBWValue(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.BW_MAX_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.BW_MAX = 'Loading');

			const { value } = await this.bwService.findById(id).toPromise();

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.BW_MAX_TWO = value)
				: (this.NETWORK_INVENTORY_VALUES.BW_MAX = value);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.BW_MAX_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.BW_MAX = 'N/A');
		}
	}

	private async getCountryName(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.COUNTRY_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.COUNTRY = 'Loading');

			const { name } = await this.locationService
				.findCountryById(id)
				.toPromise();

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.COUNTRY_TWO = name)
				: (this.NETWORK_INVENTORY_VALUES.COUNTRY = name);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.COUNTRY_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.COUNTRY = 'N/A');
		}
	}

	private async getConfigurationName(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CONFIGURATION_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.CONFIGURATION = 'Loading');

			const { name } = await this.apConfigurationService
				.findById(id)
				.toPromise();

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CONFIGURATION_TWO = name)
				: (this.NETWORK_INVENTORY_VALUES.CONFIGURATION = name);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CONFIGURATION_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.CONFIGURATION = 'N/A');
		}
	}

	private async getSubclientName(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SUBCLIENT_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.SUBCLIENT = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.SUBCLIENT.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let subclient = results.items[0].value;
					if (subclient) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.SUBCLIENT_TWO = subclient)
							: (this.NETWORK_INVENTORY_VALUES.SUBCLIENT = subclient);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SUBCLIENT_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.SUBCLIENT = 'N/A');
		}
	}

	private async getRegionName(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.REGION_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.REGION = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.SYSTEM_REGION.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let region = results.items[0].value;
					if (region) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.REGION_TWO = region)
							: (this.NETWORK_INVENTORY_VALUES.REGION = region);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.REGION_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.REGION = 'N/A');
		}
	}

	private async getPaymentMethodName(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.PAY_METH_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.PAY_METH = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.PAY_METH.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let pay_meth = results.items[0].value;
					if (pay_meth) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.PAY_METH_TWO = pay_meth)
							: (this.NETWORK_INVENTORY_VALUES.PAY_METH = pay_meth);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.PAY_METH_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.PAY_METH = 'N/A');
		}
	}

	private async getMediaTypeName(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.MEDIA_TYP_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.MEDIA_TYP = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.MEDIA_TYPE.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let media_typ = results.items[0].value;
					if (media_typ) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.MEDIA_TYP_TWO = media_typ)
							: (this.NETWORK_INVENTORY_VALUES.MEDIA_TYP = media_typ);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.MEDIA_TYP_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.MEDIA_TYP = 'N/A');
		}
	}

	private async getBillingFrequencyName(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.BILLING_FREQ_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.BILLING_FREQ = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.BILLING_FREQUENCY.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let billing_freq = results.items[0].value;
					if (billing_freq) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.BILLING_FREQ_TWO = billing_freq)
							: (this.NETWORK_INVENTORY_VALUES.BILLING_FREQ = billing_freq);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.BILLING_FREQ_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.BILLING_FREQ = 'N/A');
		}
	}

	private async getCircuitBwValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CIRCUIT_BW_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.CIRCUIT_BW = 'Loading');

			const { value } = await this.bwService.findById(id).toPromise();

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CIRCUIT_BW_TWO = value)
				: (this.NETWORK_INVENTORY_VALUES.CIRCUIT_BW = value);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CIRCUIT_BW_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.CIRCUIT_BW = 'N/A');
		}
	}

	private async getInventoryStatusValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.STATUS_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.STATUS = 'Loading');

			let status1 = this.getInventoryStatus(id, 1);
			let status2 = this.getInventoryStatus(id, 2);

			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.STATUS_TWO = status2)
				: (this.NETWORK_INVENTORY_VALUES.STATUS = status1);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.STATUS_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.STATUS = 'N/A');
		}
	}

	private async getCustomerInventoryStatusValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.STATUS_TWO = 'Loading')
				: (this.CUSTOMER_INVENTORY_VALUES.STATUS = 'Loading');

			let status1 = this.getInventoryStatus(id, 1);
			let status2 = this.getInventoryStatus(id, 2);

			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.STATUS_TWO = status2)
				: (this.CUSTOMER_INVENTORY_VALUES.STATUS = status1);
		} else {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.STATUS_TWO = 'N/A')
				: (this.CUSTOMER_INVENTORY_VALUES.STATUS = 'N/A');
		}
	}

	private async getAccountStatusValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id || id === 0) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.STATUS_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.STATUS = 'Loading');

			let status1 = this.getAccountStatus(id, 1);
			let status2 = this.getAccountStatus(id, 2);
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.STATUS_TWO = status2)
				: (this.NETWORK_INVENTORY_VALUES.STATUS = status1);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.STATUS_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.STATUS = 'N/A');
		}
	}

	private async getDisputeStatusValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id || id === 0) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.STATUS_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.STATUS = 'Loading');

			let status1 = this.getDisputeStatus(id, 1);
			let status2 = this.getDisputeStatus(id, 2);
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.STATUS_TWO = status2)
				: (this.NETWORK_INVENTORY_VALUES.STATUS = status1);
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.STATUS_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.STATUS = 'N/A');
		}
	}

	private async getUserValue(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id || id === 0) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.USER_ID_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.USER_ID = 'Loading');

			this.userService.findById(id).subscribe((result) => {
				let fullNameValue = result.full_name;
				if (fullNameValue) {
					historyItem === 2
						? (this.NETWORK_INVENTORY_VALUES.USER_ID_TWO = fullNameValue)
						: (this.NETWORK_INVENTORY_VALUES.USER_ID = fullNameValue);
				}
			});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.USER_ID_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.USER_ID = 'N/A');
		}
	}

	public getInventoryStatus(statusId: number, index: number) {
		for (let item in LOOKUP_ENUM.INVENTORY_STATUS) {
			if (LOOKUP_ENUM.INVENTORY_STATUS[item] === statusId) {
				if (index === 1) {
					return item;
				} else {
					return item;
				}
			}
		}
	}

	public getAccountStatus(statusId: number, index: number) {
		for (let item in LOOKUP_ENUM.ACCOUNT_STATUS) {
			if (LOOKUP_ENUM.ACCOUNT_STATUS[item] === statusId) {
				if (index === 1) {
					return item;
				} else {
					return item;
				}
			}
		}
	}

	public getDisputeStatus(statusId: number, index: number) {
		for (let item in LOOKUP_ENUM.DISPUTE_STATUS_ID) {
			if (LOOKUP_ENUM.DISPUTE_STATUS_ID[item] === statusId) {
				if (index === 1) {
					return item;
				} else {
					return item;
				}
			}
		}
	}

	private async getCktTypeValue(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.INVENTORY_CKT_TYPE.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let circuitTypeValue = results.items[0].value;
					if (circuitTypeValue) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE_TWO =
									circuitTypeValue)
							: (this.NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE = circuitTypeValue);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.CIRCUIT_TYPE = 'N/A');
		}
	}

	private async getTechnologyValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.TECHNOLOGY_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.TECHNOLOGY = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let technologyValue = results.items[0].value;
					if (technologyValue) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.TECHNOLOGY_TWO = technologyValue)
							: (this.NETWORK_INVENTORY_VALUES.TECHNOLOGY = technologyValue);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.TECHNOLOGY_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.TECHNOLOGY = 'N/A');
		}
	}

	private async getBwModelValue(id: number, historyItem?: 1 | 2): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.BW_MODEL_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.BW_MODEL = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.INVENTORY_BW_MODEL.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let bwModelValue = results.items[0].value;
					if (bwModelValue) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.BW_MODEL_TWO = bwModelValue)
							: (this.NETWORK_INVENTORY_VALUES.BW_MODEL = bwModelValue);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.BW_MODEL_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.BW_MODEL = 'N/A');
		}
	}

	private async getCustomerInventoryBwModelValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.BW_MODEL_TWO = 'Loading')
				: (this.CUSTOMER_INVENTORY_VALUES.BW_MODEL = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.INVENTORY_BW_MODEL.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let bwModelValue = results.items[0].value;
					if (bwModelValue) {
						historyItem === 2
							? (this.CUSTOMER_INVENTORY_VALUES.BW_MODEL_TWO = bwModelValue)
							: (this.CUSTOMER_INVENTORY_VALUES.BW_MODEL = bwModelValue);
					}
				});
		} else {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.BW_MODEL_TWO = 'N/A')
				: (this.CUSTOMER_INVENTORY_VALUES.BW_MODEL = 'N/A');
		}
	}

	private async getCktUsageValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CKT_USAGE_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.CKT_USAGE = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.INVENTORY_CIRCUIT_USAGE.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let cktUsageValue = results.items[0].value;
					if (cktUsageValue) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.CKT_USAGE_TWO = cktUsageValue)
							: (this.NETWORK_INVENTORY_VALUES.CKT_USAGE = cktUsageValue);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CKT_USAGE_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.CKT_USAGE = 'N/A');
		}
	}

	private async getProtectedValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.PROTECTED_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.PROTECTED = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.INVENTORY_PROTECTED.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let protectedValue = results.items[0].value;
					if (protectedValue) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.PROTECTED_TWO = protectedValue)
							: (this.NETWORK_INVENTORY_VALUES.PROTECTED = protectedValue);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.PROTECTED_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.PROTECTED = 'N/A');
		}
	}

	private async getPrimFailoverValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.PRIM_FAILOVER_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.PRIM_FAILOVER = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.INVENTORY_PRIM_FAILOVER.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let primFailoverValue = results.items[0].value;
					if (primFailoverValue) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.PRIM_FAILOVER_TWO =
									primFailoverValue)
							: (this.NETWORK_INVENTORY_VALUES.PRIM_FAILOVER =
									primFailoverValue);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.PRIM_FAILOVER_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.PRIM_FAILOVER = 'N/A');
		}
	}

	private async getTopologyValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.TOPOLOGY_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.TOPOLOGY = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.INVENTORY_TOPOLOGY.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let topologyValue = results.items[0].value;
					if (topologyValue) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.TOPOLOGY_TWO = topologyValue)
							: (this.NETWORK_INVENTORY_VALUES.TOPOLOGY = topologyValue);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.TOPOLOGY_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.TOPOLOGY = 'N/A');
		}
	}

	private async getCustomerInventoryTopologyValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.TOPOLOGY_TWO = 'Loading')
				: (this.CUSTOMER_INVENTORY_VALUES.TOPOLOGY = 'Loading');

			let lookupModel: string = LOOKUP_MODELS_ENUM.INVENTORY_TOPOLOGY.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let topologyValue = results.items[0].value;
					if (topologyValue) {
						historyItem === 2
							? (this.CUSTOMER_INVENTORY_VALUES.TOPOLOGY_TWO = topologyValue)
							: (this.CUSTOMER_INVENTORY_VALUES.TOPOLOGY = topologyValue);
					}
				});
		} else {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.TOPOLOGY_TWO = 'N/A')
				: (this.CUSTOMER_INVENTORY_VALUES.TOPOLOGY = 'N/A');
		}
	}

	private async getCustomerInventoryTechnologyValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.TECHNOLOGY_TWO = 'Loading')
				: (this.CUSTOMER_INVENTORY_VALUES.TECHNOLOGY = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let technologyValue = results.items[0].value;
					if (technologyValue) {
						historyItem === 2
							? (this.CUSTOMER_INVENTORY_VALUES.TECHNOLOGY_TWO =
									technologyValue)
							: (this.CUSTOMER_INVENTORY_VALUES.TECHNOLOGY = technologyValue);
					}
				});
		} else {
			historyItem === 2
				? (this.CUSTOMER_INVENTORY_VALUES.TECHNOLOGY_TWO = 'N/A')
				: (this.CUSTOMER_INVENTORY_VALUES.TECHNOLOGY = 'N/A');
		}
	}

	private async getHandoffTypeValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.HANDOFF_TYPE_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.HANDOFF_TYPE = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.INVENTORY_HANDOFF_TYPE.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let handoffTypeValue = results.items[0].value;
					if (handoffTypeValue) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.HANDOFF_TYPE_TWO =
									handoffTypeValue)
							: (this.NETWORK_INVENTORY_VALUES.HANDOFF_TYPE = handoffTypeValue);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.HANDOFF_TYPE_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.HANDOFF_TYPE = 'N/A');
		}
	}

	//TODO://
	private async getInvoiceAttachmentType(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE_TWO = 'Loading')
				: (this.INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.INVOICE_ATTACHMENT_TYPE.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let attachmentTypeValue = results.items[0].value;
					if (attachmentTypeValue) {
						historyItem === 2
							? (this.INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE_TWO =
									attachmentTypeValue)
							: (this.INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE =
									attachmentTypeValue);
					}
				});
		} else {
			historyItem === 2
				? (this.INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE_TWO = 'N/A')
				: (this.INVOICE_VALUES.INVOICE_ATTACHMENT_TYPE = 'N/A');
		}
	}

	private async getSiteALocalContactValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT = 'Loading');

			this.contactService.findById(id).subscribe((contact: Contact) => {
				historyItem === 2
					? (this.NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT_TWO =
							contact.full_name)
					: (this.NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT =
							contact.full_name);
			});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.SITEA_LOCAL_CONTACT = 'N/A');
		}
	}

	private async getSiteZLocalContactValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT = 'Loading');

			this.contactService.findById(id).subscribe((contact: Contact) => {
				historyItem === 2
					? (this.NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT_TWO =
							contact.full_name)
					: (this.NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT =
							contact.full_name);
			});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.SITEZ_LOCAL_CONTACT = 'N/A');
		}
	}

	private async getWithheldValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.WITHHELD_STATUS_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.WITHHELD_STATUS = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.DISPUTE_WITHHELD_STATUS.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.findLookupById(lookupModel, id)
				.subscribe((results) => {
					let withheldValue = results.value;
					if (withheldValue) {
						historyItem === 2
							? (this.NETWORK_INVENTORY_VALUES.WITHHELD_STATUS_TWO =
									withheldValue)
							: (this.NETWORK_INVENTORY_VALUES.WITHHELD_STATUS = withheldValue);
					}
				});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.WITHHELD_STATUS_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.WITHHELD_STATUS = 'N/A');
		}
	}

	private async getCurrencyValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CURRENCY_TWO = 'Loading')
				: (this.NETWORK_INVENTORY_VALUES.CURRENCY = 'Loading');

			this.locationService.findCurrencyById(id).subscribe((result) => {
				let currencyValue = result.currency;
				if (currencyValue) {
					historyItem === 2
						? (this.NETWORK_INVENTORY_VALUES.CURRENCY_TWO = currencyValue)
						: (this.NETWORK_INVENTORY_VALUES.CURRENCY = currencyValue);
				}
			});
		} else {
			historyItem === 2
				? (this.NETWORK_INVENTORY_VALUES.CURRENCY_TWO = 'N/A')
				: (this.NETWORK_INVENTORY_VALUES.CURRENCY = 'N/A');
		}
	}

	// Inventory Values :: end
	private async getChargeReasonCodeValue(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.CHARGE_VALUES.CHARGE_REASON_CODE_TWO = 'Loading')
				: (this.CHARGE_VALUES.CHARGE_REASON_CODE = 'Loading');

			let lookupModel: string =
				LOOKUP_MODELS_ENUM.MANUAL_ADJUSTMENT_REASON_CODE.modelName;
			let query = new Query();
			query.where['id'] = id;

			this.disctionaryService
				.getByLookup(lookupModel, query)
				.subscribe((results) => {
					let attachmentTypeValue = results.items[0].value;
					if (attachmentTypeValue) {
						historyItem === 2
							? (this.CHARGE_VALUES.CHARGE_REASON_CODE_TWO =
									attachmentTypeValue)
							: (this.CHARGE_VALUES.CHARGE_REASON_CODE = attachmentTypeValue);
					}
				});
		} else {
			historyItem === 2
				? (this.CHARGE_VALUES.CHARGE_REASON_CODE_TWO = 'N/A')
				: (this.CHARGE_VALUES.CHARGE_REASON_CODE = 'N/A');
		}
	}

	private async getChargeProfileAudtRuleType(
		id: number,
		historyItem?: 1 | 2
	): Promise<any> {
		if (id) {
			historyItem === 2
				? (this.INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE_TWO =
						'Loading')
				: (this.INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE =
						'Loading');

			let query = new Query();
			query.where['id'] = id;

			this.auditService.findAllList(query).subscribe((results) => {
				let auditRuleTypeValue = results.items[0].name;
				if (auditRuleTypeValue) {
					historyItem === 2
						? (this.INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE_TWO =
								auditRuleTypeValue)
						: (this.INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE =
								auditRuleTypeValue);
				}
			});
		} else {
			historyItem === 2
				? (this.INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE_TWO =
						'N/A')
				: (this.INVOICE_CHARGE_PROFILE_VALUES.CHARGE_PROFILE_AUDIT_RULE_TYPE =
						'N/A');
		}
	}
}
