import { Injectable } from '@angular/core';

import { FormatService } from '../../shared/format/format.service';
import { GridService } from '../../shared/grid/grid.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class ShortInvoiceChargeProfileOnInventoryGridService extends GridService {
	defaultColumns = [
		{
			caption: 'Charge Type',
			dataField: 'charge_type',
			dataType: 'string',
			alignment: 'right',
			visible: true,
			width: 100
		},
		{
			caption: 'Charge Amount',
			dataField: 'charge_amount',
			dataType: 'number',
			alignment: 'right',
			visible: true,
			width: 120
		},
		{
			caption: 'Charge Description',
			dataField: 'charge_description',
			visible: true,
			width: 260
		},
		{
			caption: 'Chg Profile Status',
			dataField: 'is_active',
			dataType: 'boolean',
			cellTemplate: 'booleanTemplate',
			transformValue: {
				true: 'Active',
				false: 'Inactive'
			},
			width: 160
		},
		{
			caption: 'Audit Rule Type',
			dataField: 'audit_type.name',
			dataType: 'string',
			width: 200,
			allowSorting: true
		},
		{
			caption: 'Expected Min Value',
			dataField: 'audit_rule_expected_min_value',
			dataType: 'currency',
			cellTemplate: 'currencyTemplate',
			width: 180,
			allowSorting: true
		},
		{
			caption: 'Expected Max Value',
			dataField: 'audit_rule_expected_max_value',
			dataType: 'currency',
			cellTemplate: 'currencyTemplate',
			width: 180,
			allowSorting: true
		}
	];

	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super(
			'app.cost.invoice-short-charges-profile-on-inventory-grid',
			userSettings
		);
	}
}
