<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
  <button mat-icon-button matTooltip="Back" (click)="back()">
    <ca-icon [key]="'BACK'"></ca-icon>
  </button>
  <span class="toolbar-details-title">{{ contractVendorName }}</span>
  <span class="example-fill-remaining-space">&nbsp;</span>

  <ng-container *permission="'Create'">
    <button *ngIf="tabGroup.isActive(2)" mat-icon-button matTooltip="New Contract"
            [disabled]="!tabGroup.isActive(2)" (click)="newContract()">
      <ca-icon [key]="'ADD_NEW'"></ca-icon>
    </button>
  </ng-container>

  <ng-container *permission="'Create'">
    <button *ngIf="tabGroup.isActive(3)" mat-icon-button matTooltip="New Order"
            [disabled]="!tabGroup.isActive(3)" (click)="newOrderHeader()">
      <ca-icon [key]="'ADD_NEW'"></ca-icon>
    </button>
  </ng-container>

  <ng-container *permission="'Modify'">
    <button *ngIf="tabGroup.isActive(2)" mat-icon-button matTooltip="Edit Contract" 
            [disabled]="!tabGroup.isActive(2) || !this.selection?.id" (click)="editContract(selection)">
      <ca-icon [key]="'EDIT'"></ca-icon>
    </button>
  </ng-container>

  <ng-container *permission="'Modify'">
    <button *ngIf="tabGroup.isActive(3)" mat-icon-button matTooltip="Edit Order"
            [disabled]="!tabGroup.isActive(3) || !this.selectionOrder" (click)="editOrderHeader(selectionOrder)">
      <ca-icon [key]="'EDIT'"></ca-icon>
    </button>
  </ng-container>

  <button *ngIf="tabGroup.isActive(3)" mat-icon-button 
          (click)="refreshOrders()" matTooltip="Refresh List">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>

  <button *ngIf="tabGroup.isActive(2)" mat-icon-button 
          (click)="refreshContracts()" matTooltip="Refresh List">
    <ca-icon [key]="'REFRESH'"></ca-icon>
  </button>

  <!-- Download -->
  <div class="vertical-divider" *ngIf="tabGroup.isActive(2) || tabGroup.isActive(3)"></div>
  <button mat-icon-button matTooltip="Download CSV" *ngIf="tabGroup.isActive(2)" (click)="contractCsv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>
  <button mat-icon-button matTooltip="Download CSV" *ngIf="tabGroup.isActive(3)" (click)="serviceOrderCsv()">
    <ca-icon [key]="'DOWNLOAD'"></ca-icon>
  </button>

  <div class="vertical-divider"></div>
  <button mat-icon-button matTooltip="Settings"
    (click)="gridSettings()"
    [disabled]="isGridSettingsDisabled">
    <ca-icon [key]="'GRID_SETTINGS'"></ca-icon>
  </button>
  <button
    [disabled]="tabGroup.isActive(1)"
    [ngClass]="{'on': sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && (tabGroup.isActive(2) || tabGroup.isActive(3))}"
    (click)="toggleFilter()" 
    mat-icon-button matTooltip="Filter">
    <ca-icon [key]="'FILTER'"></ca-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="app-content tabbed-content">
  <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="activeTabIndex">
      <mat-tab [label]="tab.title" [disabled]="tab.disabled" *ngFor="let tab of tabGroup.tabs">
      </mat-tab>
  </mat-tab-group>

  <div [ngClass]="{'hidden': !tabGroup.isActive(1)}" class="tabs-body">
    <div class="flex-container ca-card" fxLayout="row" fxLayout.sm="column">
      <mat-card fxFlex="40%" fxFlex.sm="100%" caCard>
        <mat-card-header>
          Vendor Info
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Vendor</label>
              <p>
                {{ contractVendorName || 'N/A' }}
              </p>
            </div>
            <div fxFlex class="entry-list-item">
              <label>Active Schedules</label>
              <p>
                {{activeSchedule}}
              </p>
            </div>
          </div>

          <div fxLayout="row" class="entry-list">
            <div fxFlex class="entry-list-item">
              <label>Total Contract Commitment</label>
              <p>
                {{ (data?.commitment| caCurrency:"USD":"symbol-narrow":"1.2-2") || 'N/A' }}
              </p>
            </div>
            <div fxFlex class="entry-list-item">
              <label>Total Schedules</label>
              <p>
                {{totalSchedule}}
              </p>
            </div>
            <!--<div fxFlex class="entry-list-item">
              <label>Total Contract Attainment</label>
              <p>
                {{ (contractVendor?.attainedSum | caCurrency:"USD":"symbol-narrow":"1.2-2") || 'N/A' }}
              </p>
            </div>-->
          </div>
        </mat-card-content>
      </mat-card>

    </div>
  </div>

  <div [ngClass]="{'hidden': !tabGroup.isActive(2)}" class="tabs-body" style="overflow-y: hidden">

    <div fxLayout="row wrap" class="app-content-list dx-nodata-absolute" style="height: 100%;">
      <dx-data-grid #dataGrid
                    (onSelectionChanged)="onSelectionChanged($event)"
                    (onRowClick)="onRowClick($event)"
                    (onCellClick)="onCellClickContract($event)"
                    [columns]="columns"
                    [dataSource]="contracts"
                    [showColumnLines]="false"
                    [showRowLines]="true"
                    style="height: 100%">

        <dxo-sorting mode="none"></dxo-sorting>

        <div *dxTemplate="let d of 'onHoldCellTemplate'">
          <div caGridTooltip>{{ d.value ? 'Yes' : 'No' }}</div>
        </div>
        <div *dxTemplate="let d of 'currencyCommitmentTemplate'">
          <div caGridTooltip>{{ d.value |  caCurrency:"USD":"symbol-narrow":"1.2-2"}}</div>
        </div>

      </dx-data-grid>
      <ca-pager
        [gridPager]="defaultGridPager"
        [pageContext]="contractShellVendorGridService"
        [query]="query"
        class="app-pager ca-default-border-bottom"
        [ngClass]="{ 'ca-default-border-bottom-with-filter': true }"
        (onChange)="filterData(query)"
      >
      </ca-pager>
    </div>

  </div>

  <div [ngClass]="{'visibility-hidden': !tabGroup.isActive(3)}" class="tabs-body" style="overflow-y: hidden">

    <div fxLayout="row wrap" class="app-content-list dx-nodata-absolute" style="height: 100%;">
      <dx-data-grid #grid
                    (onSelectionChanged)="onSelectionOrderChanged($event)"
                    (onRowClick)="onRowClick($event)"
                    (onCellClick)="onCellClickOrder($event)"
                    [columns]="orderHeaderColumns"
                    [dataSource]="svcOrderHeaders"
                    [allowColumnResizing] = "true"
                    [columnAutoWidth]="true"
                    [columnMinWidth]="40"
                    [showColumnLines]="false"
                    [showRowLines]="true"
                    style="height: 100%">
        <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
        <dxo-sorting mode="none"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-load-panel [enabled]="false"></dxo-load-panel>

        <div *dxTemplate="let d of 'currencyTemplate'">
          <span class="float-right">{{ d.value | caCurrency:'USD' }}</span>
        </div>
        <div *dxTemplate="let d of 'soDateTemplate'">
          <div caGridTooltip>{{ d.value | caDate }}</div>
        </div>
      </dx-data-grid>
      <ca-pager
        [query]="orderQuery"
        class="app-pager ca-default-border-bottom"
        [ngClass]="{ 'ca-default-border-bottom-with-filter': true }"
        (onChange)="filterOrderData(orderQuery)"
        [pageContext]="orderServiceHeaderGridService"
      >
      </ca-pager>

    </div>

  </div>


  <mat-sidenav #panelSide mode="side" position="end" opened="false" style="top: -1px;">
    <new-app-filter *ngIf="isVisibleSideSection('filter') && tabGroup.isActive(2)"
                [query]="query"
                [filterService]="contractFilterService"
                (filterRequested)="filterData($event)"
                (clearFilterRequested)="clearFilter($event)">
    </new-app-filter>
    <new-app-filter *ngIf="isVisibleSideSection('filter') && tabGroup.isActive(3)"
                    [query]="orderQuery"
                    [filterService]="svcOrderHeaderFilterService"
                    (filterRequested)="filterOrderData($event)"
                    (clearFilterRequested)="clearOrderFilter($event)">
    </new-app-filter>
  </mat-sidenav>

</mat-sidenav-container>
