import {Observable} from "rxjs";
import {BuildingService} from "./building.service";
import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import Query from "../../core/query/query";
import {map} from "rxjs";

export class BuildingLookupProvider extends IFilterLookupProvider {
  constructor(public buildingService: BuildingService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery? searchQuery : new Query();
    query.orderBy = [['name', 'ASC']];

    return this.buildingService.findAll(query).pipe(
      map((result: any) => {
        return {items: result.items.map((entry) => {
          return entry.name;
        }), total: result.total};
      }));
  }
}
