import {Component, OnInit} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {
  IMessagesResourceService,
  ResourcesService
} from "app/core/resources/resources.service";

import {PageManageDialogComponent} from "../../../core/page-manage-dialog.component";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {AlertService} from "../../../shared/alert/alert.service";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {LoaderService} from "../../../shared/loader/loader.service";
import {Contract} from "../../core/contract";
import {ContractService} from "../../core/contract.service";

const SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

@Component({
  selector: "app-contract-shell-manage-dialog",
  templateUrl: "./contract-shell-menage-dialog.component.html",
  styleUrls: ["./contract-shell-menage-dialog.component.css"],
})
export class ContractShellMenageDialogComponent extends PageManageDialogComponent implements OnInit {
  contract: any;
  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = "contract";
  public limit: 1000;

  constructor(
    public formBuilder: FormBuilder,
    public alert: AlertService,
    public contractService: ContractService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<ContractShellMenageDialogComponent>,
    public loaderService: LoaderService
  ) {
    super(dialogService, dialogRef);
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.contract ? this.contract.name : "", Validators.required],
      description: [this.contract ? this.contract.description : ""],
      vendor_id: [
        {value: this.contract ? this.contract.vendor_id : "", disabled: this.contract?.disableVendorPicker}
      ],
      company_name: [this.contract ? this.contract.company_name : ""],
    });
  }

  onSubmit({value, valid}: { value: Contract; valid: boolean }) {
    if (!valid) {
      this.alert.error("", this.messages.get("FORM_INVALID"));
    }
    if (this.contract && this.contract.id) {
      this.update(Object.assign({}, this.contract, value));
      return;
    }
    this.create(this.form.getRawValue());
  }

  create(contract: Contract, file?: File) {
    // protection of fast Save button clicking, if contract is already created skip creation of new one
    if (contract["contract_id"]) return;

    this.loaderService.displayLoader();

    this.toggleDialogButtons();

    this.contractService.create(contract).subscribe(
      (result) => {
        this.loaderService.hideLoader();
        this.alert.success("", this.messages.get("CREATE_SUCCESS"));
        this.closeDialog(result, true);
      },
      (err) => {
        this.toggleDialogButtons(false);
        this.loaderService.hideLoader();
        this.alert.success("", this.messages.get("CREATE_ERROR"));
      }
    );
  }

  update(contract: Contract) {
    delete contract.disableVendorPicker;
    delete contract.vendor_id;
    this.toggleDialogButtons();
    this.loaderService.displayLoader();
    this.contractService.update(contract.id, contract).subscribe(
      (result) => {
        this.loaderService.hideLoader();
        this.closeDialog(result, true);
      },
      (err) => {
        this.toggleDialogButtons(false);
        this.alert.error("", this.messages.get("UPDATE_ERROR"));
      }
    );
  }

  cancel() {
    this.contractService.cancelEdit();
    this.closeDialog();
  }
}
