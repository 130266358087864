import {Injectable} from '@angular/core';
import {BaseService} from '../../core/base.service';
import {DictionaryQuery} from "./dictionary.query";
import {LookupModel} from "./lookup.model";
import {LOOKUP_MODELS_ENUM} from "./lookup-models.enum";
import {HttpClient} from "@angular/common/http";
import {EntityLockService} from "../../shared/entity-lock/entity-lock.service";
import Query from 'app/core/query/query';
import {map} from "rxjs";

@Injectable()
export class DictionaryService extends BaseService<LookupModel> {

  constructor(private http: HttpClient,
              private entityLock: EntityLockService) {
    super('dictionary', entityLock);
  }

  getByLookup(lookup: string, query?: DictionaryQuery, disableCache?): any {

    let concreteQuery = (query || new DictionaryQuery());
    let transformedQuery = concreteQuery.transform();
    return this.httpService().get(`dictionary/lookup/${lookup}`, transformedQuery)
  };


  findLookupById(lookup: string, id: number): any {
    return this.httpService().get([this.name, 'lookup', lookup, 'one', id]);

  };

  findLookupByIdForEdit(lookup: string, id: number) {
    return this.httpService().get([this.name, 'lookup', lookup, 'one', id, 'edit']);
  }

  findFromAllLookups(query?: Query) {
    return this.httpService().get([this.name, 'lookups'], this.toFilter(query));
  }


  createLookup(lookup: string, item): any {
    return this.httpService().post([this.name, 'lookup', lookup], item);
  }

  updateLookup(lookup: string, id: number, item): any {
    return this.httpService().put([this.name, id, 'lookup', lookup], item).pipe(map((result) => {
      this.completeEdit();
      return result;
    }));
  }

  removeLookup(lookup: string, id: number) {
    return this.httpService().delete([this.name, id,'lookup', lookup]);
  }


  getLookupModelInfo(modelName: string) {
    let lookup;
    for (let i in LOOKUP_MODELS_ENUM) {
      if (LOOKUP_MODELS_ENUM[i].modelName === modelName) {
        lookup = LOOKUP_MODELS_ENUM[i];
        break;
      }
    }
    return lookup;
  }
}
