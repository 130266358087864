<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Profile</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form" >

    <form class="ca-form" [formGroup]="form" autocomplete="false" fxLayout="row">
      <div fxFlex="58%" class="flex-item caGap caGapRight">
       <div class="flex-container" fxLayout="row wrap">

        <div class="flex-item" class="caGapRight">

          <ca-avatar fxLayout="row" fxLayoutAlign="center center" class="details-item" [uploadEnabled]="true"
                     (onAvatarUpload)="handleAvatarUpload($event)"
                     [text]="getUserInitials()" [size]="'m'" [source]="userCopy?.avatar"></ca-avatar>
        </div>

        <div fxFlex>
          <div class="flex-container" fxLayout="row wrap">
            <div fxFlex class="caGap caGapRight">
              <mat-form-field caInput>
                <mat-label>First Name</mat-label>
                <input matInput required formControlName="first_name" type="text">
                <mat-hint *ngIf="form.hasError('required', ['first_name']) && form.controls.first_name.touched"
                          class="error-class">{{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </mat-form-field>
            </div>
            <div fxFlex class="caGap">
              <mat-form-field caInput>
                <mat-label>Last Name</mat-label>
                <input matInput required formControlName="last_name" type="text">
                <mat-hint *ngIf="form.hasError('required', ['last_name']) && form.controls.last_name.touched"
                          class="error-class">{{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-container" fxLayout="row wrap">
            <div fxFlex class="caGap">
              <mat-form-field caInput>
                <mat-label>Email</mat-label>
                <input matInput required formControlName="email" type="text">
                <mat-hint *ngIf="form.hasError('required', ['email']) && form.controls.email.touched && emailUnique"
                          class="error-class">
                  {{VALIDATION.REQUIRED_ERROR}}
                </mat-hint>
                <mat-hint *ngIf="form.hasError('email', ['email']) && form.controls.email.touched && emailUnique"
                          class="error-class">
                  {{VALIDATION.EMAIL_ERROR}}
                </mat-hint>
                <mat-hint *ngIf="!emailUnique">
                  {{VALIDATION.EMAIL_ALREADY_USED}}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-container" fxLayout="row wrap">
            <div class="flex-item caGap caGapRight entry-list-container" fxFlex="80px">
              <div fxLayout="row" class="entry-list">
                <div fxFlex class="entry-list-item" style="padding: 2px 0;">
                  <label>Country Code</label>
                  <p style="text-align: end">{{ phoneCountryCode || ''}}</p>
                </div>
              </div>
            </div>
            <div fxFlex class="caGap">
              <mat-form-field caInput>
                <mat-label>Phone Number</mat-label>
                <input matInput formControlName="phone_number" type="text"
                       [mask]="phoneRegexMask" [validation]="true">
                <mat-hint *ngIf="(form.hasError('pattern', ['phone_number']) || this.form.get('phone_number').invalid) && form.controls.phone_number.touched"
                          class="error-class">{{phoneRegexMask}}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-container" fxLayout="row wrap">
            <div class="flex-item caGap caGapRight entry-list-container" fxFlex="80px">
            </div>
            <div fxFlex class="caGap">
              <mat-form-field caInput>
                <mat-label>Mobile Number</mat-label>
                <input matInput formControlName="mobile_number" type="text"
                       [mask]="phoneRegexMask" [validation]="true">
                <mat-hint *ngIf="(form.hasError('pattern', ['mobile_number']) || this.form.get('mobile_number').invalid) && form.controls.mobile_number.touched"
                          class="error-class">{{phoneRegexMask}}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div>
            <div class="no-margin-top roles-list-title">Roles</div>

            <div fxLayout="column" style="overflow-y: auto;"
                 class="flex-container"
                 [ngClass]="{'ca-user-create-modal' : !user.id, 'ca-user-edit-modal': user.id}">
              <div fxFlex="100%" *ngFor="let role of roleList" class="roles-list">
                {{role.name}}
              </div>
            </div>
          </div>
        </div>


      </div>
      </div>
        <div fxFlex="42%" class="flex-item caGap">
          <mat-slide-toggle class="caGap"  formControlName="is_active">
            Active
          </mat-slide-toggle>         
          <ca-default-picker caInput>
            <mat-form-field>
              <mat-label>Region</mat-label>
              <mat-select
                matInput
                class="ca-mat-select-inline"
                [required]="true"
                formControlName="country_id"
                (selectionChange)="onRegionChange($event)">
                <mat-option
                  *ngFor="let country of allCountries"
                  [value]="country.id">
                  {{country.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ca-default-picker>

          <mat-list class="profile-format-list">
            <mat-list-item>
              <span class="profile-format-list-label" matLine>Date</span>
              <span matLine>
                <span fxFlex="60%">{{this.selectedCountry?.date_format}}</span>
                <span fxFlex="40%" fxLayoutAlign="end center">{{ todayDate | caDate:this.selectedCountry?.date_format}}</span>
              </span>
            </mat-list-item>
            <mat-list-item>
              <span matLine class="profile-format-list-label">Date/Time</span>
              <span matLine>
                <span  fxFlex="60%">{{this.selectedCountry?.date_time_format}}</span>
                <span fxFlex="40%" fxLayoutAlign="end center">{{ todayDate | caDateTime:this.selectedCountry?.date_time_format}}</span>
              </span>
            </mat-list-item>
            <mat-list-item>
              <span matLine class="profile-format-list-label">Positive Currency</span>
              <span matLine>
                <span  fxFlex="60%">{{ currencyFormatExample }}</span>
                <span fxFlex="40%" fxLayoutAlign="end center">{{ currencyExample }}</span>
              </span>
            </mat-list-item>
            <mat-list-item>
              <span matLine class="profile-format-list-label">Negative Currency</span>
              <span matLine>
                <span  fxFlex="60%">{{ currencyNegativeFormatExample }}</span>
                <span fxFlex="40%" fxLayoutAlign="end center">{{ currencyNegativeExample}}</span>
              </span>
            </mat-list-item>
            <mat-list-item>
              <span matLine class="profile-format-list-label">Positive Number</span>
              <span matLine>
                <span  fxFlex="60%">{{ numberFormatExample }}</span>
                <span fxFlex="40%" fxLayoutAlign="end center">{{ numberExample }}</span>
              </span>
            </mat-list-item>
            <mat-list-item>
              <span matLine class="profile-format-list-label">Negative Number</span>
              <span matLine>
                <span  fxFlex="60%">{{ numberNegativeFormatExample }}</span>
                <span fxFlex="40%" fxLayoutAlign="end center">{{ numberNegativeExample}}</span>
              </span>
            </mat-list-item>
          </mat-list>

        </div>
    </form>


  </div>


<mat-dialog-actions class="ca-dialog-action" *ngIf="!viewOnly">
  <span class="example-fill-remaining-space"></span>
  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>
  <button mat-button #saveButton type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid || !emailUnique">
    Save
  </button>
</mat-dialog-actions>

<mat-dialog-actions class="ca-dialog-action" *ngIf="viewOnly">
  <div class="example-fill-remaining-space"></div>

  <button mat-button type="submit" (click)="closeDialog()">
    Cancel
  </button>

  <button *permission="{'action':'Modify', 'key':'users'}" mat-button type="submit" (click)="editForm()" [disabled]="form?.invalid" >
    Edit
  </button>
</mat-dialog-actions>
