<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Service Order</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">

    <form class="ca-form" [formGroup]="form" fxLayout="column" #f="ngForm">

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <mat-form-field caInput>
          <mat-label>Description</mat-label>
          <input required matInput formControlName="description" type="text">
        </mat-form-field>
        <mat-hint *ngIf="form.hasError('required', ['description']) && form.controls['description'].touched" class="error-class hint-possition-fix">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </div>
      <div class="flex-item caGap" fxFlex>
        <ca-lookup-model-picker
          caInput
          [lookupModel]="INVENTORY_TECHNOLOGY"
          formControlName="svc_type_tech_id"
          [pickerForm]="form"
          [clearEnabled]="true"
          placeholder="Svc Tech Type">
        </ca-lookup-model-picker>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-lookup-model-picker
          caInput
          [lookupModel]="INVENTORY_CKT_TYPE"
          formControlName="ckt_type_id"
          [pickerForm]="form"
          [clearEnabled]="true"
          placeholder="Svc Type">
        </ca-lookup-model-picker>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-date-picker caInput
                        placeholder="Rate Eff Date"
                        required="true"
                        formControlName="rate_eff_date">
        </ca-date-picker>
      </div>
      <div class="flex-item caGap" fxFlex>
        <ca-date-picker caInput
                        placeholder="Rate Term Date"
                        required="true"
                        formControlName="rate_term_date">
        </ca-date-picker>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-lookup-model-picker
          caInput
          [lookupModel]="INVENTORY_BW_MODEL"
          formControlName="int_bandwidth_id"
          [pickerForm]="form"
          [clearEnabled]="true"
          placeholder="Int Bandwidth">
        </ca-lookup-model-picker>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-lookup-model-picker
          caInput
          [lookupModel]="BANDWIDTH"
          formControlName="ckt_bandwidth_id"
          [pickerForm]="form"
          [clearEnabled]="true"
          placeholder="Svc Bandwidth">
        </ca-lookup-model-picker>
      </div>
      <div class="flex-item caGap" fxFlex>
        <ca-lookup-model-picker
          caInput
          [lookupModel]="BANDWIDTH"
          formControlName="port_bandwidth_id"
          [pickerForm]="form"
          [clearEnabled]="true"
          placeholder="Port Bandwidth">
        </ca-lookup-model-picker>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-currency-picker
            #currencyPicker
            clearEnabled="true"
            caInput
            [required]="true"
            [searchEnabled]="true"
            (onSelectionChange)="onCurrencyChange($event)"
            formControlName="currency_id"
            [pickerForm]="form"
            placeholder="Currency"></ca-currency-picker>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex>
        <mat-form-field caInput>
          <mat-label>Term</mat-label>
          <input required matInput formControlName="term" type="number">
        </mat-form-field>
      </div>
      <div class="flex-item caGap" fxFlex>
        <ca-lookup-model-picker
            caInput
            [lookupModel]="TERM_UNIT_OF_MEASURE"
            formControlName="term_qty_uom_id"
            [pickerForm]="form"
            [clearEnabled]="true"
            placeholder="Term Qty UOM">
        </ca-lookup-model-picker>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <mat-form-field caInput>
          <mat-label>Service Qty</mat-label>
          <input matInput formControlName="service_qty" type="number">
        </mat-form-field>
      </div>
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-currency
          caInput
          formControlName="mrc"
          placeholder="MRC"
          [required]="true"
          [currency]="selectedCurrency">
        </ca-currency>
      </div>
      <div class="flex-item caGap" fxFlex>
        <ca-currency
          caInput
          formControlName="nrc"
          placeholder="NRC"
          [required]="true"
          [currency]="selectedCurrency">
        </ca-currency>
      </div>

    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
        <div class="flex-item caGap caGapRight" fxFlex>
          <ca-lookup-model-picker
            caInput
            [lookupModel]="QTY_UNIT_OF_MEASURE"
            formControlName="service_qty_uom_id"
            [pickerForm]="form"
            [clearEnabled]="true"
            placeholder="Service Qty UOM">
          </ca-lookup-model-picker>
        </div>
        <div class="flex-item caGap caGapRight" fxFlex>
          <ca-site-picker
            [disabledSites]="disabledSites"
            caInput
            [pickerForm]="form"
            formControlName="location_a_id"
            required="false"
            placeholder="Site A (Carrier Location)"
            [searchEnabled]="true"
            clearEnabled="true"
            [initialItem]="svcOrderService?.location_a">
          </ca-site-picker>
        </div>
        <div class="flex-item caGap" fxFlex>
          <ca-site-picker
            [disabledSites]="disabledSites"
            caInput
            [pickerForm]="form"
            formControlName="location_z_id"
            required="false"
            placeholder="Site Z (Customer Location)"
            [searchEnabled]="true"
            clearEnabled="true"
            [initialItem]="svcOrderService?.location_z">
          </ca-site-picker>
        </div>
      </div>

      <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
        <div class="flex-item caGap caGapRight" fxFlex>
          <ca-lookup-model-picker
            caInput
            [pickerForm]="form"
            [lookupModel]="AT_EXP_EVENT"
            [clearEnabled]="true"
            formControlName="at_exp_event_id"
            required="false"
            placeholder="At Expiration Event">
          </ca-lookup-model-picker>
        </div>
        <div class="flex-item caGap caGap" fxFlex>
          <mat-form-field caInput>
            <mat-label>Cancel Notice (Days)</mat-label>
            <input
              matInput
              formControlName="cancel_notice_pd"
              type="number">
          </mat-form-field>
        </div>
        <div class="flex-item caGap caGap" fxFlex></div>
      </div>

    <div class="flex-container"  fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap" fxFlex>
        <mat-form-field caInput>
          <mat-label>Service Details</mat-label>
          <textarea matInput
                    formControlName="service_details"
                    rows="2"></textarea>
        </mat-form-field>
      </div>
    </div>

  </form>

</div>

<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button
      *ngIf="isUpdate"
      #genericButton
      (deleteRequested)="deleteOrderService($event)"
      [confirmText]="'Delete?'"></ca-confirm-delete-button>
  </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit(form)"
    [disabled]="form?.invalid">
    Save
  </button>
</mat-dialog-actions>
