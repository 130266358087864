<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Svc Order</h1>
</div>

<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
  <form class="ca-form" [formGroup]="form" fxLayout="column">

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex fxFlex="60%">
        <ca-vendor-picker
          caInput
          formControlName="vendor_id"
          [pickerForm]="form"
          [disabled]="true"
          placeholder="Vendor"
          [initialItem]="svcOrderHeader?.vendor"
          [clearEnabled]="true"
          [required]="true"
          [initialItemId]="svcOrderHeader?.vendor_id">
        </ca-vendor-picker>
      </div>
      <div class="flex-item caGap" fxFlex>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex fxFlex="60%">
        <mat-form-field caInput>
          <mat-label>Name</mat-label>
          <input  matInput formControlName="name" type="text">
        </mat-form-field>
      </div>
        <div class="flex-item" fxFlex>
          <ca-date-picker [required]="true" caInput placeholder="SO Date" formControlName="so_date"></ca-date-picker>
        </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <mat-form-field caInput>
          <mat-label>Vendor SO Number</mat-label>
          <input required matInput formControlName="vendor_so_number" type="text">
        </mat-form-field>
        <mat-hint *ngIf="form.hasError('required', ['vendor_so_number']) && form.controls['vendor_so_number'].touched" class="error-class hint-possition-fix">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </div>
      <div class="flex-item caGap" fxFlex>
        <mat-form-field caInput>
          <mat-label>Internal SO Number</mat-label>
          <input required matInput formControlName="internal_so_number" type="text">
        </mat-form-field>
        <mat-hint *ngIf="form.hasError('required', ['internal_so_number']) && form.controls['internal_so_number'].touched" class="error-class hint-possition-fix">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </div>
    </div>


    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap" fxFlex>
        <mat-form-field caInput>
          <mat-label>Description</mat-label>
          <textarea required matInput formControlName="description" matInput rows="2"></textarea>
        </mat-form-field>
        <mat-hint *ngIf="form.hasError('required', ['description']) && form.controls['description'].touched" class="error-class hint-possition-fix">
          {{VALIDATION.REQUIRED_ERROR}}
        </mat-hint>
      </div>
    </div>
  </form>
</div>

<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
  <div class="example-fill-remaining-space">
    <ca-confirm-delete-button
      *ngIf="isUpdate"
      #genericButton
      (deleteRequested)="deleteOrderHeader($event)"
      [confirmText]="'Delete?'"></ca-confirm-delete-button>
  </div>

  <button mat-button #cancelButton type="submit" (click)="cancel()">
    Cancel
  </button>

  <button mat-button #saveButton type="submit" (click)="onSubmit(form)"
    [disabled]="form?.invalid">
    Save
  </button>
</mat-dialog-actions>
