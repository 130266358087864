import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Demarc} from "./demarc.model";

@Injectable()
export class DemarcService extends BaseService<Demarc> {

  constructor() {
    super('demarc');
  }

  getDemarcs(siteId: number, networkCircuitId?: number): any {
    return this.httpService().get([this.name, 'site', siteId, 'circuit', networkCircuitId]);
  }

}
