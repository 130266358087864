import {IFilterLookupProvider} from "../../core/filter/filter-lookup.provider";
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {Observable} from "rxjs";
import {LOOKUP_MODELS_ENUM} from "../../dictionary/core/lookup-models.enum";
import {map} from "rxjs";
export class ChargeTypeLookupProvider extends IFilterLookupProvider{

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  readonly lookupModel: string = LOOKUP_MODELS_ENUM.CHARGE_TYPE.modelName;
  public entries : any;

  findAll(searchQuery?): Observable<any> {
    // We found issue on task GPX-7371
    // We took the same query object for filters and grid. Sometimes happen that we have in where or order_by attribute
    // which we don't have in ChargeType Sequlize schema.
    //Example:
    // 1) Print searchQuery
    // 2) Set  ChargeType filter
    // 3) Reload page
    // You will see in where or order_by attribute: info_only_ind or acct_level_1. This is a problem, we don't need these params
    // When we don't send query object app creates new query instance and everything works fine
    return this.dictionaryService.getByLookup(this.lookupModel).pipe(
      map((result: any) => {
        this.entries = result.items;
        this.entries.map(entry => {
          return {
            key: entry.id,
            value: entry.value
          };
        });
        this.entries.total = result.total;
        return this.entries;
      }));
  }

}
