import {of as observableOf,  Observable } from 'rxjs';
import {Component, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { MatDialogRef } from '@angular/material/dialog';
import {
  IMessagesResourceService,
  ResourcesService
} from 'app/core/resources/resources.service';

import { Dispute } from '../../core/dispute';
import { DisputeService } from '../../core/dispute.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { DisputeChargeService } from '../../../charge/core/dispute-charge.service';
import { DisputeChargesGridService } from '../../core/dispute-charges-grid.service';
import { EntityEditContext } from '../../../shared/entity-lock/entity-edit-context';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { UserService } from "../../../user/core/user.service";
import { flatMap } from "rxjs";
import set = Reflect.set;
import { RandomService } from "app/shared/random/random.service";
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { LookupModel } from '../../../dictionary/core/lookup.model';
import { DisputeWithheldStatusLookup } from '../../core/dispute-witheld-status.lookup';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { DictionaryService } from '../../../dictionary/core/dictionary.service';

@Component({
  selector: 'app-revert-withheld-manage-dialog',
  templateUrl: './revert-withheld-manage-dialog.component.html',
  styleUrls: ['./revert-withheld-manage-dialog.component.scss']
})
export class RevertWithheldManageDialogComponent extends PageManageDialogComponent
  implements OnInit, AfterViewInit {
  @ViewChild(DxDataGridComponent) chargesGrid: DxDataGridComponent;
  @ViewChild('dialogButtons') dialogButtons: ElementRef<HTMLElement>;
  formTitle: string = 'Revert Payment Withheld';
  form: FormGroup;

  dispute: Dispute;
  withheldCharges: any;
  formCharges: any;

  randomString = this.randomService.getRandomCheckString();
  confirmPlaceholder = 'Type in generated string shown below';
  confirmButtonLabel = 'Revert';

  columns: Array<any>;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'dispute';
  disputeWithheldStatusLookup: DisputeWithheldStatusLookup = new DisputeWithheldStatusLookup();
  readonly DISPUTE_WITHHELD_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS;
  readonly DISPUTE_WITHHELD_STATUS_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.DISPUTE_WITHHELD_STATUS.modelName;
  me: any;

  constructor(
    public alertService: AlertService,
    public dialogService: DialogService,
    public disputeService: DisputeService,
    public disputeChargeService: DisputeChargeService,
    public disputeChargesGridService: DisputeChargesGridService,
    public dialogRef: MatDialogRef<RevertWithheldManageDialogComponent>,
    public dictionaryService: DictionaryService,
    public userService: UserService,
    public randomService: RandomService,
    public formBuild: FormBuilder
  ) {
    super(dialogService, dialogRef);

    this.dispute = this.dispute || new Dispute();
    this.dispute.status_obj = this.dispute.status_obj || new LookupModel();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
  }

  ngOnInit() {
    this.userService.me().subscribe(user => {
      this.me = user;
      
      this.dictionaryService
        .getByLookup(this.DISPUTE_WITHHELD_STATUS_LOOKUP_MODEL)
        .subscribe(async (results) => {
          this.populateDisputeWithheldStatusLookup(results.items);
        })
      
      let dateFormat = this.me && this.me.country && this.me.country.date_format;
      this.columns = this.disputeChargesGridService.getChargeColumns(dateFormat);

      this.form = this.formBuild.group({
        reason: new FormControl(this.formCharges ? this.formCharges.reason : null, Validators.required),
      });
    })
  }

  ngAfterViewInit(): void {
    /*this.disputeChargesGridService.create(this.chargesGrid.instance, {});*/
  }

  close(result?: any) {
    this.toggleDialogButtons(false);
    this.dialogRef.close(result);
  }

  onSubmit({value, valid}: { value: any, valid: boolean }): void {
    if (valid) {      
      let disputeForSave = Object.assign({}, this.dispute);
      disputeForSave.dispute_charges.forEach(charge => {
        if(this.withheldCharges.some(e => e.id === charge.id)) {
          charge.dispute_withheld = false;
          charge.reason = value.reason;
        }
      });
      const checkWithheldForDispute = this.checkWithheldStatus(disputeForSave);
      disputeForSave.withheld_status = checkWithheldForDispute.withheld_status;
      disputeForSave.withheld_status_obj = checkWithheldForDispute.withheld_status_obj;

      delete disputeForSave.invoice;
      delete disputeForSave.user.avatar;
      this.toggleDialogButtons();
      this.disputeService.update(this.dispute.id, disputeForSave).subscribe(
        result => {
          this.close(result);
        },
        err => {
          this.toggleDialogButtons(false);
          this.alertService.error('', this.messages.get('UPDATE_ERROR'));
        }
      );
    }
    else {
      this.alertService.error('', this.messages.get('FORM_INVALID'));
    }
  }

  checkWithheldStatus(dispute) {
    let withheldCount: number = 0;
    let withheld_status, withheld_status_obj;
    dispute.dispute_charges.forEach((disputeCharge) => {
      if (disputeCharge.dispute_withheld) {
        withheldCount++;
      }
    })

    if(withheldCount === 0) {
      withheld_status = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS.NOT_WITHHELD;
      withheld_status_obj = this.disputeWithheldStatusLookup.NOT_WITHHELD;
    } else if (withheldCount < dispute.dispute_charges.length) {
      withheld_status = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS.PARTIALLY_WITHHELD;
      withheld_status_obj = this.disputeWithheldStatusLookup.PARTIALLY_WITHHELD;
    } else {
      withheld_status = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS.FULL_WITHHELD;
      withheld_status_obj = this.disputeWithheldStatusLookup.FULL_WITHHELD;
    }
    return {withheld_status, withheld_status_obj};
  }

  populateDisputeWithheldStatusLookup(
    disputeWithheldStatuses: Array<LookupModel>
  ): void {
    disputeWithheldStatuses.forEach(item => {
      if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.NOT_WITHHELD) {
        this.disputeWithheldStatusLookup.NOT_WITHHELD = item;
      }
      if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.PARTIALLY_WITHHELD) {
        this.disputeWithheldStatusLookup.PARTIALLY_WITHHELD = item;
      }
      if (item.id === this.DISPUTE_WITHHELD_STATUS_ENUM.FULL_WITHHELD) {
        this.disputeWithheldStatusLookup.FULL_WITHHELD = item;
      }
    });
  }

  cancel() {
    this.disputeService.cancelEdit();
    this.closeDialog();
  }

  
}
