
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SortingBuilder, SortingService} from "../../../shared/sorting/sorting.service";
import {PermissionService} from "../../../permissions/core/permission.service";
import {DisputeService} from "../../../dispute/core/dispute.service";
import {DisputeGridService} from "../../core/dispute-grid.service";
import {InvoiceFacepage} from "../../core/invoice-facepage";
import {DisputeQuery} from "../../../dispute/core/dispute.query";
import {Dispute} from "../../../dispute/core/dispute";
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {AlertService} from "../../../shared/alert/alert.service";
import {IMessagesResourceService} from "../../../core/resources/resources.service";
import {Sider, SiderSection, SiderSettings} from "../../../core/sider/sider";
import {Select, Store} from "@ngxs/store";
import {QueryBuilder} from "../../../common/query/query.builder";
import {Observable, Subject} from "rxjs";
import {InvoiceFacepageService} from "../../core/invoice-facepage.service";
import {PageDetailsComponent} from "../../../core/page-details.component";
import {PageContext} from "../../../core/page.context";
import {UserSettingsService} from "../../../user/core/user-settings.service";
import {GridSettingsComponent} from "../../../core/grid/grid-settings.component";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {HistoryComponent} from "../../../shared/history/history.component";
import {INVOICE_STATUS_ENUM} from "../../core/invoice-status.enum";
import {InvoiceFlowService} from "../../core/invoice-flow.service";
import {HistoryService} from "../../../shared/history/history.service";
import {LOOKUP_ENUM} from "../../../dictionary/core/lookup.enum";
import {UpdateInvoiceDisputesSiderSection} from "./state/invoice-disputes.actions";
import {takeUntil} from "rxjs";

@Component({
  selector: 'app-invoice-disputes',
  templateUrl: './invoice-disputes.component.html',
  styleUrls: ['./invoice-disputes.component.css']
})
export class InvoiceDisputesComponent
  extends PageDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  private gridSettings: any;

  invoice: InvoiceFacepage;
  disputes: Array<Dispute> = [];
  disputesSorting: SortingBuilder;
  disputesColumnsSorting: any[];
  messages: IMessagesResourceService;
  permissionDisputeView: boolean;
  disputeQuery: DisputeQuery = new DisputeQuery({orderBy: [['id', 'ASC']]});
  disputesColumns: any;
  defaultGridPager: number;

  sider: Sider;
  isDoNotProcessDisabled = true;

  dnpInfo: any = null
  onHoldInfo: any = null
  notesCount = 0;
  invoiceStatusEnum = INVOICE_STATUS_ENUM;
  readonly SYSTEM_MODULE = LOOKUP_ENUM.SYSTEM_MODULE;

  @ViewChild('disputesGrid') disputesGrid: DxDataGridComponent;
  @ViewChild('panelSide') panelSide;
  @ViewChild('history') history: HistoryComponent;
  @ViewChild('testNotes') testNotes;

  @Select(state => state.invoice_disputes.activeSiderSection) $activeSiderSection: Observable<any>;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly queryBuilder: QueryBuilder,
    private readonly sortingService: SortingService,
    private readonly invoiceFacepageService: InvoiceFacepageService,
    public disputeService: DisputeService,
    public disputeGridService: DisputeGridService,
    public permissionService: PermissionService,
    public alertService: AlertService,
    public dialogService: DialogService,
    public invoiceFlowService: InvoiceFlowService,
    public historyService: HistoryService,
    public settingsService: UserSettingsService,
  ) {
    super(new PageContext({
      name: 'app.invoice.invoice-details',
      settings: settingsService
    }));

    this.disputesSorting = this.sortingService.builder();

    this.invoiceFacepageService.invoiceChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(invoice => {
      this.invoice = invoice;
      this.history.refreshList();
    })
  }

  ngOnInit(): void {
    this.invoice = this.activatedRoute.parent.snapshot.data.invoice;
    this.disputeGridService.loadSettings()
      .subscribe((settings) => {
        this.initializeGridColumns(settings);
      });
    this.isDoNotProcessDisabled = this.invoiceFacepageService.isDoNotProcessDisabled(this.invoice);

    this.getOnHoldInfo()
    this.testNotes && this.testNotes.emitCount();
    //this.getDNPInfo(this.invoice.notes, false)
  }

  ngAfterViewInit(): void {
    this.sider = this.createSider();

    this.disputeGridService.create(this.disputesGrid.instance, {
      noDataText: this.disputeGridService.noDataMessage
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createSider() {
    const sider = new Sider(new SiderSettings([
      new SiderSection(this.SECTIONS.FILTER_SECTION_NAME),
      new SiderSection(this.SECTIONS.DETAILS_SECTION_NAME),
      new SiderSection(this.SECTIONS.NOTES_SECTION_NAME),
      new SiderSection(this.SECTIONS.HISTORY_SECTION_NAME),
    ], this.panelSide));

    this.$activeSiderSection.subscribe(sectionName => {
      if (sectionName && sectionName !== 'none') {
        setTimeout(() => {
          this.sider.open(sectionName)
        })
      }
    });

    return sider;
  }

  initializeGridColumns(settings) {
    this.gridSettings = settings;
    this.disputesColumnsSorting = settings ? settings.sorting : [];
    this.disputesColumns = this.disputeGridService.getColumns(settings && settings.columns ? settings.columns : []);
    this.defaultGridPager = settings ? settings.gridPager : 20;
    this.disputeQuery.limit = this.defaultGridPager;

    if (this.disputesColumnsSorting && this.disputesColumnsSorting.length) {
      this.disputeQuery.orderBy = this.disputeGridService.getOrderBy(this.disputesColumnsSorting);
    }

    if (this.disputesGrid && this.disputesGrid.instance) {
      this.disputesGrid.instance.option('columns', this.disputesColumns);
    }

    this.loadDisputes()
  }

  loadDisputes(sortingQuery?: any) {
    this.disputeQuery.set('invoice_id', this.invoice.invoice_id);
    this.disputeService.findDisputes(sortingQuery || this.disputeQuery).subscribe(
      result => {
        this.disputes = result.items;
        this.disputeQuery.total = result.total;
        // this.numOfDisputes = result.total;
        // this.loadDisputeSummary(this.invoice_id);
      },
      () => {
        this.alertService.error('', this.messages.get('DISPUTE_LOAD_ERROR'));
      }
    );
  }

  public onDisputesGridRowClick(row) {
    let component = row.component;
    let prevClickTime = component.lastClickTime;

    component.lastClickTime = new Date();

    if (prevClickTime && component.lastClickTime - prevClickTime < 250) {
      if (row.data && row.data.id) {
        this.showDispute(row.data.dispute_id);
      }
    }
  }

  showDispute(id: any) {
    if (id /*&& this.permissionDisputeView*/) {
      this.router.navigate(['/dispute', id, 'show']);
    }
  }

  public onDisputesCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.disputesSorting.apply(event, this.disputeQuery);
      this.loadDisputes();
    }
  }

  onPageDisputeChange() {
    this.loadDisputes();
  }

  toggleSider(sectionName: string) {
    this.sider.toggle(sectionName);
    const activeSection = this.sider.getActiveSection();
    this.store.dispatch([
      new UpdateInvoiceDisputesSiderSection(activeSection.name)
    ]);
  }

  /** Toolbar actions */
  onDownloadCSVRequested() {
    this.disputeGridService.csvMap().subscribe(fieldsDispute => {
      this.disputeService.exportToCSV(`${this.invoice?.sp_inv_num}_disputes`, {
        fields: fieldsDispute,
        query: this.disputeQuery,
        settings: {
          currencyField: 'dispute.currency.currency'
        },
        fetchDataHandler: this.disputeService.findDisputes.bind(
          this.disputeService,
          this.disputeQuery,
        ),
      });
    });
  }

  onOpenGridSettingsRequested() {
    this.dialogService
      .open(GridSettingsComponent, {
        service: this.disputeGridService
      })
      .afterClosed()
      .subscribe(settings => {
        if (settings) {
          this.resetDataPager();
          this.initializeGridColumns(settings);
        }
      });
  }

  async getOnHoldInfo() {
    if (!this.invoice.account.on_hold) {
      this.onHoldInfo = null;
      return;
    }
    let accountHistory = await this.historyService.findAllForEntity('account', this.invoice.account.id).toPromise()
    let onHoldFound = accountHistory.filter(account => (account.meta_data.on_hold && account.meta_data.on_hold.value === true))
    this.onHoldInfo = (onHoldFound && onHoldFound.length > 0) ? onHoldFound[0] : null
  }

  getDNPInfo(notes: any, forceEmpty: boolean = false) {
    const dnpStatus = this.invoiceFlowService.LOCALS.DO_NOT_PROCESS

    if (forceEmpty || this.invoice.header.status_code !== this.invoiceStatusEnum.DO_NOT_PROCESS) {
      this.dnpInfo = null
    } else {
      const dnpNotes = notes.filter(note => note.content.startsWith(dnpStatus));
      this.dnpInfo = (dnpNotes.length) ? dnpNotes[0] : {};
      this.dnpInfo.status = dnpStatus;
      this.dnpInfo.message = this.dnpInfo.content ? this.dnpInfo.content.substring(dnpStatus.length + 2) : this.messages.get('DNP_FALLBACK_MESSAGE');
    }
  }

  /* Notes count changed */
  onNotesCountChanged(count: number) {
    this.notesCount = count;
  }


  onNoteCreated() {
    // this.refresh(this.query)
    // // TODO: was this.loadCharges(this.invoice); now refresh / check if valid
    // this.selectedCharges.length = 0;
  }

  back() {
    if (document.referrer.indexOf(window.location.host) >= 0 || document.referrer === '') {
      history.back();
    } else {
      this.router.navigate(['/invoice']);
    }
  }
}
