import {
  Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges
} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

import {Observable} from "rxjs";
import {CustomerInventoryService} from "../../../customer-inventory/core/customer-inventory.service";
import {PICKER_COMPONENT_TEMPLATE, PickerComponentBase} from "../../../shared/picker/ca-picker-base.component";
import {BuildingService} from "../../core/building.service";
import {BuildingQuery} from "../../core/building.query";


@Component({
  selector: 'ca-building-picker',
  template: PICKER_COMPONENT_TEMPLATE,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaBuildingPickerComponent),
      multi: true
    },
    CustomerInventoryService
  ]
})
export class CaBuildingPickerComponent extends PickerComponentBase implements OnInit, OnChanges {


  readonly TEXT_FIELD = 'name';
  readonly SECOND_LINE_TEXT_FIELD = 'building_address';
  query: BuildingQuery = new BuildingQuery({
    orderBy: [['name', 'ASC']],
    limit: 20
  });

  @Input('formControlName') formControlName;
  @Input("initialItem") initialItem;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Input('searchEnabled') searchEnabled: boolean;
  @Input('placeholder') placeholder: string = 'Select ...';
  @Input('pickerForm') pickerForm;
  @Input('clearEnabled') clearEnabled: boolean;
  public control;

  constructor(public buildingService: BuildingService) {
    super();
  }

  loadEntries(): Observable<any> {
    return this.buildingService.advancedSearch(this.query);
  }

  search(value: string): Observable<any> {
    this.query.where['q'] = value;
    //this.query.where['name'] = {'$like': `${value}%`};

    return this.buildingService.advancedSearch(this.query);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = this.pickerForm.controls[this.formControlName];

    if(changes['initialItem'] && changes['initialItem'].currentValue) {
      let building = changes['initialItem'].currentValue;
      if(building && building.address) {
        let adr = building.address;
        building['building_address'] = [adr.address1, adr.city, adr.subdivision.abbreviation, adr.postal_code].join(', ');
        this.initialItem = building;
      }
    }
  }

}
