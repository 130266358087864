import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeComponent} from './theme.component';
import {ThemeService} from './core/theme.service';
import { MaterialModule } from "app/material-barrel/material-barrel.module";
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {ThemeManageComponent} from './shared/theme-manage/theme-manage.component';
import {ThemeSchemaService} from "./core/theme.scheme";
import { CustomThemeService } from "./core/custom-theme.service";
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatInputModule,
        FlexLayoutModule,
        ColorPickerModule,
        MaterialModule
    ],
    exports: [ThemeComponent],
    declarations: [ThemeComponent, ThemeManageComponent],
    providers: [ThemeService, ThemeSchemaService, CustomThemeService]
})
export class ThemeModule {
}
