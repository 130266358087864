import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { forkJoin } from 'rxjs';

import { InventoryCodingGridService } from '../core/inventory-coding-grid.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { PageListComponent } from '../../core/page-list.component';
import { AlertService } from '../../shared/alert/alert.service';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { AccountService } from '../../account/core/account.service';
import { VendorService } from '../../vendor/core/vendor.service';
import Query from '../../core/query/query';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { PageContext } from '../../core/page.context';
import { SortingBuilder, SortingService } from '../../shared/sorting/sorting.service';
import { AccountQuery } from '../../account/core/account.query';
import { ACCOUNT_STATUS_ENUM } from '../../account/core/account-status.enum';
import { LoaderService } from '../../shared/loader/loader.service';
import { IMessagesResourceService, ResourcesService } from '../../core/resources/resources.service';
import { InventoryCodingFilterService } from '../core/inventory-coding-filter.service';
import { SystemRuleStore } from '../core/system-rule.store';
import { SystemRuleSelectors } from '../core/system-rule.selectors';
import {PermissionService} from '../../permissions/core/permission.service';
import { IActions } from 'app/gl-system-rules/shared/actions.interface';
import {map, takeUntil} from "rxjs";


@Component({
  selector: 'app-inventory-coding',
  templateUrl: './inventory-coding.component.html',
  styleUrls: ['./inventory-coding.component.css']
})
export class InventoryCodingComponent extends PageListComponent implements AfterViewInit, OnDestroy, OnInit {
  public query: AccountQuery = new AccountQuery({
    limit: 100000
  });

  public bans: Array<any> = [];
  public selectedVendorID: any;
  public selectedVendor: any;
  form$: any;

  messages: IMessagesResourceService;
  readonly MESSAGES_MODULE: string = 'gl';

  @ViewChild(DxDataGridComponent) banGrid: DxDataGridComponent;
  public columns: Array<any>;

  public accountSorting: SortingBuilder;

  ACCOUNT_STATUS_ACTIVE = ACCOUNT_STATUS_ENUM.ACTIVE;

  @ViewChild('panelSide') panelSide;
  @Output() public onActionsLoaded = new EventEmitter();

  filterVisible = false;

  permissionModify = true;

  actionSchema: IActions = {
    add: false,
    edit: false,
  };

  constructor(public dialog: DialogService,
              public router: Router,
              public alert: AlertService,
              public dictionaryService: DictionaryService,
              public inventoryCodingGridService: InventoryCodingGridService,
              public accountService: AccountService,
              public vendorService: VendorService,
              public settingsService: UserSettingsService,
              public sortingService: SortingService,
              public loaderService: LoaderService,
              public inventoryCodingFilterService: InventoryCodingFilterService,
              public formBuilder: FormBuilder,
              private systemRuleStore: SystemRuleStore,
              private permissionService: PermissionService,
              private systemRuleSelectors: SystemRuleSelectors) {
    super(new PageContext({
      name: 'app.gl.gl-inventory-coding',
      settings: settingsService
    }));

    this.accountSorting = this.sortingService.builder();
    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);

    this.query = this.setInitialQuery();
  }

  ngOnInit() {
    this.loadPermissions().subscribe(() => {
            })
    this.columns = this.inventoryCodingGridService.columns();
    this.form$ = this.systemRuleStore.select('inventoryForm');

    this.systemRuleSelectors.getInventoryVendor(this.systemRuleStore)
      .pipe(takeUntil(this.destroy$))
      .subscribe((vendor_id) => {
        this.selectedVendorID = vendor_id;

        if (this.selectedVendorID != null) {
          this.query = this.setInitialQuery();

          this.query.where.vendor_id = this.selectedVendorID;

          this.vendorService.findById(this.selectedVendorID)
            .subscribe((vendor) => {
              this.selectedVendor = vendor;
              this.refreshAccounts(this.query);
            });
        } else {
          this.bans = [];
          this.selectedVendor = null;
        }
      });
  }

  ngAfterViewInit(): void {
    this.inventoryCodingGridService.create(this.banGrid.instance, {
      noDataText: this.inventoryCodingGridService.noDataMessage
    });
    super.ngAfterViewInit();
  }

  public onAccountsCellClick(event) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      this.accountSorting.apply(event, this.query);
      this.refreshAccounts(this.query);
    }
  }

  refreshAccounts(query: AccountQuery) {
    this.query.where = this.query.where || {};
    this.query.where.status_id = this.ACCOUNT_STATUS_ACTIVE;

    this.loaderService.displayLoaderAndManageGrid([this.dataGrid]);

    this.accountService.findAll(query)
      .subscribe((results) => {
        this.bans = results.items;
        this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
      },
        () => {
          this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
        });
  }

  changeVendorStatus() {
    this.dialog.confirm({
      titleText: 'Please confirm',
      bodyText: 'Are you sure you want to ' +
        (this.selectedVendor.gl_inv_coding_enabled ? 'disable' : 'enable') +
        ' GL Coding for Vendor ' + this.selectedVendor.name + '?'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.selectedVendor.gl_inv_coding_enabled = !this.selectedVendor.gl_inv_coding_enabled;
          this.vendorService.codingUpdate(this.selectedVendorID, this.selectedVendor)
            .subscribe(() => {
              this.refreshAccounts(this.query);
              this.alert.success('', this.messages.get('INVENTORY_CODING_VENDOR') + this.selectedVendor.name);
            });
        }
      });
  }

  changeBANStatus(account) {
    account.gl_inv_coding_enabled = !account.gl_inv_coding_enabled;
    this.accountService.codingUpdate(account.id, account)
      .subscribe(() => {
        this.alert.success('', this.messages.get('INVENTORY_CODING_BAN') + account.account_no + ' of Vendor ' + account.vendor.name);
      });
  }

  toggleFilter() {
    if (this.selectedVendor) {
      this.filterVisible = !this.filterVisible;
      this.filterVisible ? this.panelSide.open() : this.panelSide.close();
    }
  }

  filterData(query: Query) {
    this.refreshAccounts(query);
  }

  clearFilter() {
    this.query = this.setInitialQuery();
    this.refreshAccounts(this.query);
  }

  setInitialQuery() {
    let query = Object.assign(new AccountQuery({
      orderBy: [['vendor.name', 'ASC'], ['account_no', 'ASC']]
    }), this.query);
    query.clear();
    query['where'] = {
      id: { $ne: new Date().getMilliseconds() * -1 }
    };
    return query;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadPermissions() {
    const glModify$ = this.permissionService.isAllowed("gl", "MODIFY");
    return forkJoin([
      glModify$]
    ).pipe(map(([ glModify]) => {
      this.permissionModify = !glModify;
      this.onActionsLoaded.emit(this.actionSchema)
    }));
  }

}
