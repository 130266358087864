<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
  <h1 mat-dialog-title>Contract Schedule</h1>
</div>

<div class="schedule-forms">
<div mat-dialog-content class="ca-dialog-content" *ngIf="form">
  <form class="ca-form" [formGroup]="form" fxLayout="column">
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-lookup-model-picker
          caInput
          [pickerForm]="form"
          [disabled]="viewOnly"
          [lookupModel]="CONTRACT_SCHEDULE_SERIES"
          [clearEnabled]="true"
          formControlName="schedule_series_id"
          required="true"
          placeholder="Schedule Series">
        </ca-lookup-model-picker>
      </div>
      <div class="flex-item caGap" fxFlex>
        <mat-slide-toggle *ngIf="![1, 2].includes(form.get('schedule_series_id').value)"
                          formControlName="contains_rates"
                          style="align-self: end;"
                          (change)="isScheduleContainsRates($event)">
          Contains Rate
        </mat-slide-toggle>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex="">
        <mat-form-field caInput>
          <mat-label>Schedule Name</mat-label>
          <input
            matInput
            formControlName="schedule_name"
            required="true"
            type="text">
          <mat-hint *ngIf="!scheduleUnique"
                    class="error-class error-font-size">
            {{this.messages.get('SCHEDULE_ALREADY_EXISTS')}}
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="flex-item caGap" fxFlex>
        <ca-contract-schedule-picker
          caInput
          clearEnabled="true"
          #contractSchedulePicker
          formControlName="prior_schedule_id"
          [disabled] = "!form.get('schedule_series_id').value"
          [query]="contractScheduleQuery"
          [pickerForm]="form"
          [contractId]="contract.id"
          [svcOrderServices]="false"
          [scheduleId] = "this.contractSchedule.id"
          [scheduleSeriesId]="this.form.get('schedule_series_id').value"
          placeholder="Prior Schedule">
        </ca-contract-schedule-picker>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-date-picker caInput
                        placeholder="Customer Sign Date"
                        required="true"
                        formControlName="customer_sign_date">
        </ca-date-picker>
      </div>

      <div class="flex-item caGap" fxFlex>
        <ca-date-picker caInput
                        placeholder="Vendor Sign Date"
                        required="true"
                        formControlName="vendor_sign_date">
        </ca-date-picker>
      </div>
    </div>
    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-date-picker caInput
                placeholder="Effective Date"
                required="true"
                formControlName="effective_date">
        </ca-date-picker>
      </div>

      <div class="flex-item caGap" fxFlex>
        <ca-date-picker caInput
                placeholder="Termination Date"
                required="true"
                formControlName="termination_date">
        </ca-date-picker>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
      <div class="flex-item caGap caGapRight" fxFlex>
        <ca-lookup-model-picker
          caInput
          [pickerForm]="form"
          [lookupModel]="AT_EXP_EVENT"
          [clearEnabled]="true"
          formControlName="at_exp_event_id"
          required="false"
          placeholder="At Expiration Event">
        </ca-lookup-model-picker>
      </div>
      <div class="flex-item caGap caGap" fxFlex>
        <mat-form-field caInput>
          <mat-label>Cancel Notice (Days)</mat-label>
          <input
            matInput
            formControlName="cancel_notice_pd"
            type="number">
        </mat-form-field>
      </div>
    </div>

    <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
        <div class="flex-item caGap caGapRight" fxFlex="25%">
            <mat-form-field caInput>
            <mat-label>Term</mat-label>
            <input
              matInput
              formControlName="rate_term"
              type="number">
            </mat-form-field>
        </div>

        <div class="flex-item caGap" fxFlex>
          <mat-form-field caInput>
            <mat-label>Description</mat-label>
            <input
              matInput
              formControlName="schedule_description"
              type="text">
          </mat-form-field>
        </div>
    </div>
  </form>
</div>

  <div class="starting-width " [ngClass]="{'transition-class' : this.showDynamicForm['revenue_commitment']}">
    <div mat-dialog-content class="ca-dialog-content horizontal-line">
      <div *ngIf="this.showDynamicForm['revenue_commitment']">
        <form class="ca-form" [formGroup]="form" fxLayout="column">
          <div formGroupName="commitmentForm">
            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
              <div class="flex-item caGap caGapRight" fxFlex="">
                <mat-form-field caInput>
                  <mat-label>Commitment Name</mat-label>
                  <input
                    matInput
                    formControlName="commitment_name"
                    type="text">
                </mat-form-field>
              </div>

              <div class="flex-item caGap" fxFlex>
                <mat-form-field caInput>
                  <mat-label>Contributory Service</mat-label>
                  <input
                    matInput
                    formControlName="contribute_services"
                    type="text">
                </mat-form-field>
              </div>
            </div>
            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
              <div class="flex-item caGap caGapRight" fxFlex>
                <ca-date-picker caInput
                                placeholder="Effective Date"
                                formControlName="effective_date"
                                [minDate]="minDate"
                                [maxDate]="maxDate">
                </ca-date-picker>
              </div>
              <div class="flex-item caGap caGapRight" fxFlex>
                <ca-date-picker caInput
                                placeholder="Termination Date"
                                formControlName="termination_date"
                                [minDate]="minDate"
                                [maxDate]="maxDate">
                </ca-date-picker>
              </div>
              <div class="flex-item caGap" fxFlex="25%">
                <ca-currency caInput formControlName="commit_total" disabled="true" placeholder="Commit Total" [currency]="selectedCurrency"></ca-currency>
              </div>
            </div>
            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
              <div class="flex-item caGap caGapRight" fxFlex="25%">
                <mat-form-field caInput>
                  <mat-label>Term</mat-label>
                  <input
                    [disabled] = "termDisabled"
                    matInput
                    formControlName="term"
                    (change)="getTerm()"
                    type="number">

                </mat-form-field>
              </div>
              <div class="flex-item caGap caGapRight" fxFlex>
                <ca-lookup-model-picker
                  caInput
                  [pickerForm]="form.get('commitmentForm')"
                  [disabled]="viewOnly"
                  (onSelectionChange)="getTermType()"
                  [lookupModel]="CONTRACT_SCHEDULE_COMMITMENT_TYPE"
                  [clearEnabled]="true"
                  formControlName="commit_term_type_id"
                  placeholder="Term Type">
                </ca-lookup-model-picker>
              </div>
              <div class="flex-item caGap">
                <ca-currency caInput formControlName="commit_per_term" (change)="getCommitPerTerm()" placeholder="Commitment Per Term" [currency]="selectedCurrency"></ca-currency>
              </div>
            </div>
            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
              <div class="flex-item caGap caGapRight" fxFlex="25%">
                <ca-currency-picker
                  clearEnabled="true"
                  caInput
                  #currencyPicker
                  [searchEnabled]="true"
                  name="currency_id"
                  required="true"
                  formControlName="currency_id"
                  [pickerForm]="form.get('commitmentForm')"
                  (onSelectionChange)="onCurrencyChange($event)"
                  placeholder="Currency">
                </ca-currency-picker>
              </div>
              <div class="flex-item caGap" fxFlex>
                <mat-form-field caInput>
                  <mat-label>Exchange Rate</mat-label>
                  <input matInput formControlName="exchange_rate"
                        placeholder="Exchange Rate"
                        type="number"
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="flex-container" fxLayout="row wrap" fxLayout.sm="column">
              <div class="flex-item caGap" fxFlex>
                <mat-form-field caInput>
                  <mat-label>Description</mat-label>
                  <input
                    matInput
                    formControlName="commitment_description"
                    type="text">
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions class="ca-align-baseline ca-dialog-action">
  <div class="example-fill-remaining-space"></div>

  <button
    mat-button
    #cancelButton
    type="submit"
    (click)="cancel()">
    Cancel
  </button>

  <button
    mat-button
    #saveButton
    type="submit"
    (click)="onSubmit(form)"
    [disabled]="form?.invalid || !scheduleUnique ">
    Save
  </button>
</mat-dialog-actions>
