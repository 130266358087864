import { throwError as observableThrowError, catchError, map } from 'rxjs';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import Entity from '../../../core/entity.model';
import Query from '../../../core/query/query';
import { AlertService } from '../../alert/alert.service';
import { DialogService } from '../../dialog/dialog.service';
import { Document } from '../core/document';
import { DocumentGridService } from '../core/document-grid.service';
import { DocumentService } from '../core/document.service';
import { DocumentManageDialogComponent } from '../document-manage-dialog/document-manage-dialog.component';
import { SortingBuilder, SortingService } from 'app/shared/sorting/sorting.service';
import { PageListComponent } from 'app/core/page-list.component';
import { PageContext } from 'app/core/page.context';
import { UserSettingsService } from 'app/user/core/user-settings.service';

@Component({
  selector: 'app-document-manager',
  templateUrl: './document-manager.component.html',
  styleUrls: ['./document-manager.component.css']
})
export class DocumentManagerComponent extends PageListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Output() docAdd = new EventEmitter();
  @Output() docDelete = new EventEmitter();
  @Output() docSelected = new EventEmitter();

  @Input() documentQuery: any;
  @Input() folder: string;
  @Input() subfolder: string;
  @Input() parentType: string;
  @Input() entityType: number;
  @Input() entityId: number;
  @Input() masterType: number;
  @Input() masterId: number;
  @Input() entity: any;
  @Input() master: any;
  @Input() tabChanged: boolean;
  @Input() documentType: number;
  @Input() entities: Array<Entity> = [];
  @Input('mapMeta') mapMeta: Function = () => {
    return false;
  };

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

  public documents: Array<Document> = [];
  public selection: Document;
  public columns: Array<any>;

  messages: IMessagesResourceService;
  sorting: SortingBuilder;
  query: Query = null;

  readonly MESSAGES_MODULE: string = 'shared';

  constructor(public documentService: DocumentService,
    public dialog: DialogService,
    public alert: AlertService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public documentGridService: DocumentGridService,
    public settingsService: UserSettingsService,
    public sortingService: SortingService) {
      super(
        new PageContext({
          name: 'app.order_document.order_document_list',
          settings: settingsService
        })
      );

      this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
      this.sorting = this.sortingService.builder();
      this.query = this.documentQuery ? this.documentQuery : new Query();
  }

  ngOnInit() {
    const route = this.activatedRoute?.snapshot['_urlSegment']?.segments[0]?.path;

    if (route === 'inventory' || route === 'customer-inventory') {
      this.columns = this.documentGridService.columns().filter(el => { return el.caption !== 'Entity/Item' });
    } else {
      this.columns = this.documentGridService.columns()
    }
  }

  ngAfterViewInit(): void {
    this.documentGridService.create(this.dataGrid.instance, {
      noDataText: this.documentGridService.noDataMessage
    });
  }

  onSelectionChanged(row) {
    this.selection = <Document>row.selectedRowsData[0];
    this.docSelected.emit(this.selection);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.documentGridService.loadSettings()
      .subscribe(settings => {
        let defaultGridPager = settings ? settings.gridPager : 20;
        if (this.query) {
          this.query.limit = defaultGridPager;
        }

        this.loadData();  

        if (changes['tabChanged']) {
          if (this.dataGrid && this.dataGrid.instance) {
            this.dataGrid.instance.refresh();
            this.dataGrid.instance.repaint();
          }
        }
      });
  }

  addEntityDocument(query?: Query): void {
    this.entityId = this.entityId || (this.entity ? this.entity.id : null);
    this.masterId = this.masterId || this.entityId;
    this.masterType = this.masterType || this.entityType;

    this.dialog.open(DocumentManageDialogComponent, {
      folder: this.folder,
      subfolder: this.subfolder || null,
      masterType: this.masterType,
      master: this.master,
      masterId: this.masterId,
      entityType: this.entityType,
      entityId: this.entityId,
      mapMeta: this.mapMeta,
      documentType: this.documentType,
      entities: this.entities
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.alert.success('', this.messages.get('DOC_UPLOAD_SUCCESS'));
            this.docAdd.emit();

            this.loadData();
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('DOC_UPLOAD_FAIL'));
        }
      );
  }

  downloadDocument(doc: Document, folder: string, subfolder?: string) {
    this.documentService.downloadDocument(doc.id, folder, subfolder).pipe(
      map((res: any) => new Blob([res.arrayBuffer()], { type: doc.type }) || {}),
      catchError(error => observableThrowError(error)),)
      .subscribe((response: Blob) => {
        let objectUrl = URL.createObjectURL(response);

        let save = document.createElement('a');
        save.href = objectUrl;
        save.target = '_blank';
        save.download = doc.name;
        let event = document.createEvent('MouseEvents');
        event.initMouseEvent(
          'click', true, false, window, 0, 0, 0, 0, 0
          , false, false, false, false, 0, null
        );
        save.dispatchEvent(event);
      });
  }

  deleteEntityDocument(doc: Document, query?: Query) {
    this.dialog.confirm({
      bodyText: `Delete document ${doc.name || doc.file_name}?`
    })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.documentService.deleteEntity(doc.id, doc.entity_id, doc.entity_type)
              .subscribe(
                (result) => {
                  this.docDelete.emit();
                  this.alert.success('', this.messages.get('DOC_DELETE_SUCCESS'));

                  this.loadData();
                }
              );
          }
        },
        (error) => {
          this.alert.error('', this.messages.get('DOC_DELETE_FAIL'));
        }
      );
  }

  loadData(query?: Query) {
    const docQuery = query ?? this.query;
    docQuery.where =  { master_type: this.masterType, master_id: this.masterId }

    this.documentService.findAllForMaster(docQuery)
      .subscribe(
        (res) => {
          this.query.total = res.total;
          this.documents = res.items;
        },
        (error) => {
          console.error('Error fetching entity documents', error);
        }
      );
  }

  clearSelection() {
    this.dataGrid.instance.clearSelection();
    this.dataGrid.instance.refresh();
    this.dataGrid.instance.repaint();
  }

  public onCellClick(event: any) {
    if (event.rowType === 'header' && event.column.allowSorting) {
      let query = new Query({
        where: { master_type: this.masterType, master_id: this.masterId }
      });

      this.sorting.apply(event, query);
      this.loadData(query);
    }
  }

  ngOnDestroy() {
    this.query = null
  }
}
