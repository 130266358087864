import {Injectable} from '@angular/core';
import {BaseService} from "../../core/base.service";
import {Brand} from "./brand";

@Injectable()
export class BrandService extends BaseService<Brand>{

  constructor() {
    super('brand', null);
  }

  get(id){
    return this.httpService().get([this.name, id])
  }


}
