<mat-toolbar color="accent" class="toolbar-details" mat-scroll-shrink>
	<button mat-icon-button matTooltip="Back" (click)="back()">
		<ca-icon [key]="'BACK'"></ca-icon>
	</button>

	<span class="toolbar-title">{{ (alert?.type?.value || '') + ' Alert: ' + (alert?.rule_name || 'N/A') }}</span>
	<span class="example-fill-remaining-space">&nbsp;</span>

	<button
		*permission="{ action: 'View', key: 'invoice' }"
		matTooltip="View Invoice Details"
		[disabled]="!(alert && alert.invoice && alert.invoice.invoice_id)"
		(click)="goToInvoiceDetails()"
		mat-icon-button
	>
		<ca-icon [key]="'VIEW_INVOICE_DETAILS'"></ca-icon>
	</button>

	<button *permission="{ action: 'Create', key: 'disputes' }" mat-icon-button matTooltip="Create Dispute" [disabled]="addDisputeDisabled" (click)="addDispute()" [hidden]="!tabGroup.isActive(2)">
		<ca-icon [key]="'ADD_DISPUTE'"></ca-icon>
	</button>

	<button mat-icon-button matTooltip="Refresh" (click)="refresh()">
		<ca-icon [key]="'REFRESH'"></ca-icon>
	</button>
	<div class="vertical-divider"></div>

	<button [disabled]="!tabGroup.isActive(2)" mat-icon-button (click)="csv()" matTooltip="Download CSV">
		<ca-icon [key]="'DOWNLOAD'"></ca-icon>
	</button>

	<div class="vertical-divider"></div>

	<button
		[disabled]="!tabGroup.isActive(2)"
		matTooltip="Filter"
		[ngClass]="{
			on: sider?.isActive(SECTIONS.FILTER_SECTION_NAME) && tabGroup.isActive(2)
		}"
		(click)="sider?.toggle(SECTIONS.FILTER_SECTION_NAME)"
		mat-icon-button
	>
		<ca-icon [key]="'FILTER'"></ca-icon>
	</button>
</mat-toolbar>

<mat-sidenav-container class="app-content tabbed-content">
	<mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="currentTabIndex">
		<mat-tab [label]="tab.title" *ngFor="let tab of tabGroup.tabs" [disabled]="tab.disabled"> </mat-tab>
	</mat-tab-group>

	<div [ngClass]="{ 'visibility-hidden': !tabGroup.isActive(1) }" class="tabs-body" fxLayout="column">
		<div class="flex-container ca-card" fxLayout="row">
			<mat-card fxFlex="60%" fxFlexFill caCard>
				<mat-card-header> Audit Info </mat-card-header>

				<mat-card-content>
					<div fxLayout="row" class="entry-list">
						<div fxFlex class="entry-list-item">
							<label>Category</label>
							<p>
								{{ alert?.category?.value || 'N/A' }}
							</p>
						</div>

						<div fxFlex class="entry-list-item">
							<label>Created At</label>
							<p>
								{{ alert?.created_at | date: 'M/D/YYYY h:mm:ss a' }}
							</p>
						</div>
						<div fxFlex class="entry-list-item">
							<label>Created By</label>
							<p>
								{{ alert?.creator?.full_name || 'System' }}
							</p>
						</div>
					</div>

					<div fxLayout="row" class="entry-list">
						<div fxFlex class="entry-list-item">
							<label>Owner</label>
							<p>
								{{ alert?.owner?.full_name || PLACEHOLDERS.OWNER_UNASSIGNED }}
							</p>
						</div>
						<div fxFlex class="entry-list-item">
							<label>Priority</label>
							<p>
								{{ alert?.priority.value || 'N/A' }}
							</p>
						</div>

						<div fxFlex class="entry-list-item">
							<label>Status</label>
							<p>
								{{ alert?.status.value || 'N/A' }}
							</p>
						</div>
					</div>

					<div fxLayout="row" class="entry-list">
						<div fxFlex class="entry-list-item">
							<label>Last Modified At</label>
							<p>
								{{ (alert?.last_modified_at | date) || 'N/A' }}
							</p>
						</div>

						<div fxFlex class="entry-list-item">
							<label>Closed At</label>
							<p>
								{{ (alert?.closed_at | date) || 'N/A' }}
							</p>
						</div>

						<div fxFlex class="entry-list-item">
							<label>Message</label>
							<p>
								{{ alert?.message || 'N/A' }}
							</p>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card fxFlex fxFlexFill caCard>
				<mat-card-header> Invoice Info </mat-card-header>

				<mat-card-content>
					<div fxLayout="row" class="entry-list">
						<div fxFlex class="entry-list-item">
							<label>BAN</label>
							<p>
								{{ alert?.account_no || 'N/A' }}
							</p>
						</div>

						<div fxFlex class="entry-list-item">
							<label>Vendor</label>
							<p>
								{{ alert?.vendor?.name || 'N/A' }}
							</p>
						</div>
					</div>

					<div fxLayout="row" class="entry-list">
						<div fxFlex class="entry-list-item">
							<label>Invoice Number</label>
							<p>
								{{ alert?.invoice?.sp_inv_num || 'N/A' }}
							</p>
						</div>

						<div fxFlex class="entry-list-item">
							<label>Invoice ID</label>
							<p>
								{{ alert?.invoice?.invoice_id || 'N/A' }}
							</p>
						</div>
					</div>

					<div fxLayout="row" class="entry-list">
						<div fxFlex class="entry-list-item">
							<label>Invoice Date</label>
							<p>
								{{ (alert?.invoice?.inv_date | date) || 'N/A' }}
							</p>
						</div>

						<div fxFlex class="entry-list-item"></div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<!----------------------------- DETAILS TAB ------------------------------------>

	<mat-sidenav-container [ngClass]="{ 'visibility-hidden': !tabGroup.isActive(2) }" class="app-content app-content-list dx-nodata-absolute">
		<dx-data-grid
			#dataGrid
			class="app-grid-with-pager app-content-list"
			keyExpr="id"
			[columns]="columns"
			[dataSource]="charges"
			(onCellClick)="onCellClick($event)"
			(onSelectionChanged)="onChargesSelectionChanged($event)"
			(click)="onChargesDataGridClick($event)"
		>
			<dxo-selection mode="multiple"></dxo-selection>

			<div *dxTemplate="let d of 'selectedChargesHeaderTemplate'" class="grid-selection-badge-container">
				<a class="selection-badge grid-badge" matTooltip="Selection" (click)="loadSelectedAlerts()" [ngClass]="{ active: chargesSelectionActive }">
					{{ selectedCharges.length }}
				</a>
			</div>

			<div *dxTemplate="let d of 'chargeDisputeNoteTemplate'" style="position: relative" class="icon-td">
				<a [ngClass]="d.data.charge?.charge_dispute ? 'icon-danger' : 'disabled'" (click)="showDispute(d.data.charge?.charge_dispute?.dispute_id)" class="charge-grid-icons">
					<ca-icon [key]="'DISPUTE'" [innerClass]="'charge-grid-icon'" [tooltip]="'Dispute'"></ca-icon>
				</a>
			</div>

			<div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
				<div caGridTooltip class="right">
					{{ d.value | caCurrency: 'USD' : 'symbol-narrow' : '1.2-2' }}
				</div>
			</div>

			<div *dxTemplate="let d of 'numTemplate'">
				<div caGridTooltip class="right">{{ d.value | number: '1.2-2' }}</div>
			</div>
		</dx-data-grid>

		<ca-pager
			[gridPager]="defaultGridPager"
			[pageContext]="alertMissingInventoryDetailsGridService"
			[query]="query"
			class="app-pager ca-default-border-top"
			(onChange)="onPageChange(query)"
		></ca-pager>

		<mat-sidenav #panelSide mode="side" position="end" opened="false" [ngClass]="{ 'visibility-hidden': !tabGroup.isActive(2) }">
			<new-app-filter
				[ngClass]="{
					hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) || !tabGroup.isActive(2)
				}"
				[query]="query"
				[filterService]="alertMissingInventoryFilterService"
				(filterRequested)="filterData($event)"
				(clearFilterRequested)="clearFilter()"
			>
			</new-app-filter>
		</mat-sidenav>
	</mat-sidenav-container>
</mat-sidenav-container>
