import { Injectable } from '@angular/core';

import { StoreSelectors } from '../../core/store';
import { SystemRuleStore } from './system-rule.store';
import {map, startWith, switchMap,Observable} from "rxjs";

@Injectable()
export class SystemRuleSelectors extends StoreSelectors {
  getSpidVendor: Function;
  getSpidBan: Function;
  getSubAccountVendor: Function;
  getSubAccountBan: Function;
  getInventoryVendor: Function;
  getBanFormVendor: Function;
  constructor() {
    super();
    Object.assign(this, {
      getSpidVendor: this.getFormControlSelector('spidForm', 'vendor_id'),
      getSpidBan: this.getFormControlSelector('spidForm', 'ban'),
      getSubAccountVendor: this.getFormControlSelector('subAccountForm', 'vendor_id'),
      getSubAccountBan: this.getFormControlSelector('subAccountForm', 'ban'),
      getInventoryVendor: this.getFormControlSelector('inventoryForm', 'vendor_id'),
      getBanFormVendor: this.getFormControlSelector('banForm', 'vendor_id')
    });
  }

  private getFormControlSelector = (formName, controlName) => systemRuleStore => {
    return systemRuleStore.select(formName).pipe(switchMap((form: any) => {
      return form.controls[controlName].valueChanges.pipe(startWith(form.controls[controlName].value))
    }));
  }

  private getBanDisabledSelector = (vendorSelector) => systemRuleStore => {
    let previousVendor = null;
    return vendorSelector(systemRuleStore).pipe(map((vendor:any) => {
      let disabled = true;
      if (vendor == null) {
        disabled = true;
      } else if (vendor !== previousVendor) {
        disabled = false;
      }

      previousVendor = vendor;
      return disabled;
    }));
  };

  isSubAccountBanDisabled = (systemRuleStore: SystemRuleStore) => {
    return this.getBanDisabledSelector(this.getSubAccountVendor)(systemRuleStore);
  }

  isSpidBanDisabled = (systemRuleStore: SystemRuleStore) => {
    return this.getBanDisabledSelector(this.getSpidVendor)(systemRuleStore);
  }
}
