import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { EntityLockService } from "../../shared/entity-lock/entity-lock.service";
import {of} from "rxjs";
import Query from "../../core/query/query";

@Injectable()
export class AuditResultService extends BaseService<any> {

  constructor(private entityLock: EntityLockService) {
    super('audit-result', entityLock);
  }

  getAuditResult(query) {
    let concreteQuery = (query || new Query());
    let transformedQuery = concreteQuery.transform();

    return this.httpService().get('audit-process/result', {
      ...this.toFilter(transformedQuery)
    });
  }

  getTestData() {
    return of([
      {
        id: 1,
        run_date: new Date(),
        user: 'Chris Lee',
        vendor: 'AT&T',
        ban: '343564687888415',
        invoice_number: '454465687867845',
        invoice_id: 3662,
        invoice_date: new Date(),
        spid: 'DHEC23434343434',
        related_spid: '',
        charge_code_1: 'TX22E',
        charge_description_1: '',
        charge_code_2: 'TX22E',
        charge_description_2: '',
        facility_bandwidth: '',
        install_date: new Date(),
        charge_qty_type: '',
        charge_qty_used: '',
        charge_qty_allowed: '',
        charge_qty_billed: '',
        billed_charge_rate: '',
        billed_charge_amt: '',
        calc_charge_amt: '',
        calc_var: ''
      }
    ]);
  }

}
