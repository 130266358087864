import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from "rxjs";
import {Quote} from "./quote";
import {QuotingService} from "./quoting.service";

@Injectable()
export class QuoteResolver  {
  constructor(private quoteService: QuotingService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Observable<Quote>> | Promise<Observable<Quote>> | Observable<Quote> {
    return this.quoteService.findById(parseInt(route.paramMap.get('id')));
  }


}
