import { Observable, of, throwError as observableThrowError, map, tap, first, switchMap } from 'rxjs';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { InvoiceFacepage } from './invoice-facepage';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import Query from '../../core/query/query';
import Auth from '../../shared/user-session/auth';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';
import { LOOKUP_ENUM } from '../../dictionary/core/lookup.enum';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { ManageInvoiceDialogComponent } from '../shared/invoice-manage-dialog/invoice-manage-dialog.component';
import { INVOICE_STATUS_ENUM } from './invoice-status.enum';
import { IMessagesResourceService, ResourcesService } from 'app/core/resources/resources.service';
import { AlertService } from 'app/shared/alert/alert.service';
import * as io from 'socket.io-client';
import { Store } from '@ngxs/store';
import { PatchInvoiceStatuses } from '../state/invoice.actions';

@Injectable()
export class InvoiceFacepageService extends BaseService<InvoiceFacepage> {
	messages: IMessagesResourceService;
	readonly MESSAGES_MODULE: string = 'invoice';
	invoiceStatusEnum = INVOICE_STATUS_ENUM;

	@Output() invoiceChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() toolbarAction: EventEmitter<any> = new EventEmitter<{
		action: any;
		params: any;
	}>();
	private url = environment.BASE_API_URL;
	private socket;

	constructor(
		private dialogService: DialogService,
		public http: HttpClient,
		private entityLock: EntityLockService,
		private alertService: AlertService,
		private store: Store
	) {
		super('invoice', entityLock);
		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
	}

	update(id: number, item: any): any {
		return this.httpService().put([this.name, id], item);
	}

	updateApprove(id: number, item: any): any {
		return this.httpService().put([this.name, 'approve', id], item);
	}

	updateStatus(id: number, item: any): any {
		return this.httpService().put([this.name, id, 'status'], item);
	}

	updateStatusApproved(id: number, item: any): any {
		return this.httpService().put([this.name, id, 'statusApproved'], item);
	}

	approveMultiple(ids: any): any {
		return this.httpService().post('invoice/approve/multiple', ids);
	}

	updateDocumentTypeStatus(id: number, item: any): any {
		return this.httpService().put([this.name, id, 'updateDocumentTypeStatus'], item);
	}

	findByIdForApprovedEdit(id: number, config?: any) {
		return this.httpService().get([this.name, id, 'editApproved']);
	}

	loadInvoiceNotesAndContacts(id: number) {
		return this.httpService().get([this.name, id.toString(), 'notesContacts']);
	}

	codableAdjustments = ['adjad', 'adjbf'];

	findAllUncodedCharges(invoiceId: number) {
		let query = {
			scope: 'count',
			where: {
				invoice_id: invoiceId,
				chg_amt: { $ne: 0 },
				$or: [
					{
						charge_coded: false,
						chg_class: {
							$notIn: this.codableAdjustments
						}
					},
					{
						charge_coded: false,
						chg_class: {
							$in: this.codableAdjustments
						},
						include_in_amount_due_status: {
							$eq: LOOKUP_ENUM.UNSET_ADJUSTMENTS.INCLUDED
						}
					}
				]
			}
		};

		return this.httpService().get(['charge', 'coded'], query);
	}

	findAllInvoices(query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().get([this.name, 'all'], { filter: transformedQuery });
	}

	checkInvoiceStatus(query) {
		return this.httpService().post([this.name, 'checkInvoiceStatus'], query);
	}

	isInvoiceOnHold(query?) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().post([this.name, 'isInvoiceOnHold'], transformedQuery);
	}

	getGLChgAmt(query?) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().post([this.name, 'getGLChgAmt'], transformedQuery);
	}

	findAllSimple(query) {
		return this.httpService().get([this.name, 'simple'], query);
	}

	facepagePdf(id: number): Observable<any> {
		const endPoint: string = [this.name, '/', id.toString(), '/facepagePdf'].join('');
		let cookie = Auth.getSession();

		try {
			return this.http
				.get(`${environment.API_URL}${endPoint}`, {
					responseType: 'blob',
					headers: {
						authorization: `Bearer ${cookie.token}`
					},
					params: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }
				})
				.pipe(map((response) => this.extractPdfBlob(response)));
		} catch (error) {
			this.handleError(error);
		}
	}

	public extractPdfBlob(data) {
		let blob = new Blob([data], { type: 'application/pdf' });
		return blob || {};
	}

	public handleError(error: HttpResponse<any> | any) {
		let errMsg: string;
		if (error instanceof HttpResponse) {
			const body = error || '';
			const err = body['error'] || JSON.stringify(body);
			errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
		} else {
			errMsg = error.message ? error.message : error.toString();
		}
		console.error(errMsg);
		return observableThrowError(errMsg);
	}

	findDistinctSubAccounts(query?: Query): any {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().get([this.name, 'subaccounts', 'distinct'], this.toFilter(transformedQuery));
	}

	public downloadContainer(id: number) {
		let cookie = Auth.getSession();
		let params: HttpParams = new HttpParams();
		params.set('authorization', cookie.token);

		let endPoint: string = [environment.API_URL, this.name, '/', id.toString(), '/download'].join('');

		try {
			return this.http
				.get(endPoint, {
					responseType: 'blob',
					headers: {
						authorization: `Bearer ${cookie.token}`
					}
				})
				.pipe(map(this.extractPdfBlob));
		} catch (error) {
			this.handleError(error);
		}
	}

	public downloadSource(id: number) {
		let cookie = Auth.getSession();
		let params: HttpParams = new HttpParams();
		params.set('authorization', cookie.token);

		let endPoint: string = [environment.API_URL, this.name, '/', id.toString(), '/download/source'].join('');

		try {
			return this.http
				.get(endPoint, {
					responseType: 'blob',
					headers: {
						authorization: `Bearer ${cookie.token}`
					}
				})
				.pipe(map(this.extractPdfBlob));
		} catch (error) {
			this.handleError(error);
		}
	}

	downloadSingularFileSourceName(id: number): any {
		let cookie = Auth.getSession();
		let params: HttpParams = new HttpParams();
		params.set('authorization', cookie.token);

		let endPoint: string = [environment.API_URL, this.name, '/', id.toString(), '/download/singularSource'].join('');
		try {
			return this.http.get(endPoint, {
				headers: {
					authorization: `Bearer ${cookie.token}`
				}
			});
		} catch (error) {
			this.handleError(error);
		}
	}

	invoiceDatesDistinct(): any {
		return this.httpService().get([this.name, 'dates']);
	}

	invoiceReceivedDatesDistinct(): any {
		return this.httpService().get([this.name, 'dates']);
	}

	findChargesWithoutAdj(id: number): any {
		return this.httpService().get([this.name, id, 'chargesWithoutAdj']);
	}

	processMultipleInvoices(invoice_ids: any, proceed_to_gl?) {
		proceed_to_gl = proceed_to_gl || false;
		return this.httpService().post(['gl_rules/processMultipleInvoices'], { invoice_ids, proceed_to_gl });
	}

	getNonBalancedInvoices(query: any) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();

		return this.httpService().post([this.name, 'getNonBalancedInvoices'], transformedQuery);
	}

	reload(id: number): any {
		return this.httpService().get([this.name, id, 'reload']);
	}

	destroyMany(ids, note) {
		return this.httpService().delete([this.name], {
			ids,
			note
		});
	}

	openConfirmationDialog({ invoices, onDeleteConfirmed, confirmText, actionText, title, config }) {
		invoices = Array.isArray(invoices) ? invoices : [invoices];
		return this.dialogService.open(
			ManageInvoiceDialogComponent,
			{
				invoices,
				actionText,
				title,
				confirmText,
				onDeleteConfirmed,
				config
			},
			{
				width: !config ? '850px' : '600px'
			}
		);
	}

	deleteWithConfirmation(invoices: Array<InvoiceFacepage> | InvoiceFacepage) {
		invoices = Array.isArray(invoices) ? invoices : [invoices];
		return new Observable((subscriber) => {
			const dialogRef = this.openConfirmationDialog({
				invoices,
				actionText: 'delete',
				title: 'Delete invoices',
				confirmText: 'Are you sure you want to delete this invoice?',
				config: null,
				onDeleteConfirmed: (input) => {
					this.destroyMany(
						(invoices as Array<any>).map((invoice: any) => invoice.invoice_id),
						input.note
					)
						.pipe(
							tap(() => {
								this.alertService.success('', this.messages.get('DELETE_SUCCESS'));
								dialogRef.close();
							})
						)
						.subscribe(() => {
							subscriber.next();
							subscriber.complete();
						});
				}
			});
		});
	}

	isDeleteEligible(invoices: Array<InvoiceFacepage> | InvoiceFacepage) {
		if (invoices != null) {
			invoices = Array.isArray(invoices) ? invoices : [invoices];

			let res = invoices.length && invoices.every((invoice) => invoice && invoice.header && invoice.header.status_code && invoice.header.status_code < INVOICE_STATUS_ENUM.RFA);
			return res;
		}

		return false;
	}

	invoicesByAuditId(query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().get([this.name, 'findAllInvoicesByAuditId'], this.toFilter(transformedQuery));
	}

	invoicesForRateAudit(query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().get([this.name, 'findAllInvoicesForRateAudit'], this.toFilter(transformedQuery));
	}

	findLatestInvoicesByAccount(accountId, accountNo) {
		let concreteQuery = new Query();
		concreteQuery.where.accountId = accountId;
		concreteQuery.where.accountNo = accountNo;
		let transformedQuery = concreteQuery.transform();
		return this.httpService().get([this.name, 'latest-by-account'], this.toFilter(transformedQuery));
	}

	isDoNotProcessDisabled(invoice: InvoiceFacepage) {
		if (invoice && invoice.header && invoice.header.status_code) {
			return invoice.header.status_code > this.invoiceStatusEnum.APPROVED;
		} else {
			return true;
		}
	}

	fetchInvoiceStatuses(): any {
		return this.store
			.select((state) => state.invoices)
			.pipe(
				first(),
				switchMap((state) => {
					if (Object.keys(state?.statuses || {}).length) {
						// Found in store, use them directly
						return of(state.statuses);
					} else {
						// Not found in store, fetch from backend
						return this.httpService()
							.get([this.name, 'status'])
							.pipe(
								tap((statuses) => {
									this.store.dispatch(new PatchInvoiceStatuses({ statuses }));
								})
							);
					}
				})
			);
	}

	onChangeInvoiceDocumentTypeRequested({ invoice, params }) {
		let documentInvoiceTypeId;
		for (const property in LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE) {
			if (property === params) {
				documentInvoiceTypeId = LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE[property];
			}
		}
		if (documentInvoiceTypeId === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.CREDIT_MEMO && invoice.header.document_type_id === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.INVOICE) {
			this.updateDocumentTypeStatus(invoice.invoice_id, {
				document_type_id: documentInvoiceTypeId
			}).subscribe(async (res) => {
				const invoice = await this.findById(res.invoice_id).toPromise();
				this.invoiceChange.emit(invoice);
			});
		} else if (documentInvoiceTypeId === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.INVOICE && invoice.header.document_type_id === LOOKUP_ENUM.DOCUMENT_INVOICE_TYPE.CREDIT_MEMO) {
			this.updateDocumentTypeStatus(invoice.invoice_id, {
				document_type_id: documentInvoiceTypeId
			}).subscribe(async (res) => {
				const invoice = await this.findById(res.invoice_id).toPromise();
				this.invoiceChange.emit(invoice);
			});
		}
	}

	listen() {
		return new Observable((observer) => {
			this.socket = io(this.url);
			this.socket.on('invoice_status', (data) => {
				observer.next(data);
			});
			return () => {
				this.socket.disconnect();
			};
		});
	}

	findAllAuditInvoice(query?: Query) {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().get([this.name, 'findAllAuditInvoices'], this.toFilter(transformedQuery));
	}
}
