import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { IFilterLookupProvider } from '../../core/filter/filter-lookup.provider';
import {Injectable} from '@angular/core';
import {FilterService} from '../../core/filter/filter.service';
import {DictionaryService} from "../../dictionary/core/dictionary.service";
import {siteFilterContext} from './site-filter.context';
import {Observable} from "rxjs";
import {VendorService} from "../../vendor/core/vendor.service";
import {BuildingLookupProvider} from "../../building/core/building-lookup.provider";
import {BuildingService} from "../../building/core/building.service";
import {VendorLookupProvider} from "../../vendor/core/vendor-lookup.provider";
import {UserSettingsService} from "../../user/core/user-settings.service";
import {LocationService} from "../../location/core/location.service";
import {SubdivisionLookupProvider} from "../../location/core/subdivision-lookup.provider";
import {ConfigService} from "../../core/config/config.service";
import {StateLookupProvider} from "../../location/core/state-lookup.provider";
import Query from "../../core/query/query";
import {CustomerLookupProvider} from "../../customer/core/customer-lookup.provider";
import {CustomerService} from "../../customer/core/customer.service";
import { CountryLookupProvider } from 'app/location/core/country-lookup.provider';
import {map} from "rxjs";

export class SiteOwnerCategoryLookupProvider extends IFilterLookupProvider {

  lookupModel: string = LOOKUP_MODELS_ENUM.SITE_OWNER_CATEGORY.modelName;

  constructor(public dictionaryService: DictionaryService) {
    super();
  }

  findAll(searchQuery?): Observable<any> {
    let query = searchQuery? searchQuery : new Query();
    query.orderBy = [['value', 'ASC']];

    return this.dictionaryService.getByLookup(this.lookupModel, query, true).pipe(
      map((result: any) => {
        return {items: result.items.map((entry) => {
          return entry.value;
        }), total: result.total};
      }));
  }
}

@Injectable()
export class SiteFilterService extends FilterService {

  constructor(public dictionaryService: DictionaryService,
              public buildingService: BuildingService,
              public vendorService: VendorService,
              public locationService: LocationService,
              public configService: ConfigService,
              public userSettingsService: UserSettingsService,
              public customerService: CustomerService) {
    super("app.appdata.site-filter", siteFilterContext, userSettingsService);

    this.addLookupProvider('customer', new CustomerLookupProvider(customerService));
    this.addLookupProvider('vendor', new VendorLookupProvider(vendorService, true));
    this.addLookupProvider('building', new BuildingLookupProvider(buildingService));
    this.addLookupProvider('SiteOwnerCategory', new SiteOwnerCategoryLookupProvider(dictionaryService));
    this.addLookupProvider('subdivision', new SubdivisionLookupProvider(locationService));
    this.addLookupProvider('state', new StateLookupProvider(locationService, configService));
    this.addLookupProvider('country', new CountryLookupProvider(locationService, true));
  }
}
