<mat-form-field>
  <mat-label>{{placeholder}}</mat-label>
  <input #curr #currencyInput="ngModel" matInput (blur)="onTouched($event)"
    type="text"
    [ngModel]="value | caCurrency: currency"
    [required]="isRequired"
    [readonly]="isReadonly"
    [disabled]="isDisabled"
    [ngModelOptions]="{updateOn:'blur'}"
    (change)="onChange($event)">
    <mat-hint *ngIf="control && control.touched && control.errors?.required"
            class="error-class">{{ VALIDATION.REQUIRED_ERROR }}
    </mat-hint>
</mat-form-field>
