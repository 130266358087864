import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {RandomService} from "app/shared/random/random.service";
import {forkJoin, of as observableOf} from "rxjs";
import {DataLockService} from "../../../core/data-lock/data-lock.service";
import {PageManageDialogComponent} from "../../../core/page-manage-dialog.component";
import Query from "../../../core/query/query";
import {
  IMessagesResourceService,
  ResourcesService,
} from "../../../core/resources/resources.service";
import {PermissionService} from "../../../permissions/core/permission.service";
import {AlertService} from "../../../shared/alert/alert.service";
import {DialogService} from "../../../shared/dialog/dialog.service";
import {EntityEditContext} from "../../../shared/entity-lock/entity-edit-context";
import {EntityLockData} from "../../../shared/entity-lock/entity-lock-data";
import {Vendor} from "../../core/vendor";
import {VendorEntity} from "../../core/vendor-entity";
import {VendorService} from "../../core/vendor.service";
import {MoveEntityDialogComponent} from "../move-entity-dialog/move-entity-dialog.component";
import {VendorEntityManageDialogComponent} from "../vendor-entity-manage-dialog/vendor-entity-manage-dialog.component";
import {map, takeUntil} from "rxjs";
import {flatMap} from "rxjs";
import {ConfigService} from "../../../core/config/config.service";

@Component({
  selector: "app-vendor-manage-dialog",
  templateUrl: "./vendor-manage-dialog.component.html",
  styleUrls: ["./vendor-manage-dialog.component.css"],
})
export class VendorManageDialogComponent
  extends PageManageDialogComponent
  implements OnInit {
  vendor: any;
  vendorCopy: Vendor;
  entities: any = [];
  entitiesToRemove: any = [];
  deletePlaceholder = "Type in generated string shown below";
  randomString = this.randomService.getRandomCheckString();
  actionText = "delete";

  messages: IMessagesResourceService;
  messagesCore: IMessagesResourceService;

  readonly MESSAGES_MODULE: string = "vendor";
  readonly MESSAGES_MODULE_CORE: string = "core";

  deletePermission = false;

  // Custom fields
  fields: Array<any> = [];
  categories: any = [];
  showCategory: boolean = false;

  readonly custom_values_config_key = 'appdata_vendor';

  constructor(
    public formBuilder: FormBuilder,
    public alert: AlertService,
    public vendorService: VendorService,
    public dialogService: DialogService,
    public randomService: RandomService,
    public dialogRef: MatDialogRef<VendorManageDialogComponent>,
    private permissionService: PermissionService,
    public configService: ConfigService,
    private cd: ChangeDetectorRef,
    public dataLockService?: DataLockService
  ) {
    super(dialogService, dialogRef, dataLockService);

    this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
    this.messagesCore = ResourcesService.messages(this.MESSAGES_MODULE_CORE);
  }

  ngOnInit() {
    this.loadPermissions().subscribe(() => {
    });
    let vendorCopy = (this.vendorCopy = Object.assign({}, this.vendor));
    this.isUpdate = !!(vendorCopy && vendorCopy.id);

    this.form = this.formBuilder.group({
      name: [vendorCopy ? vendorCopy.name : "", Validators.required],
      logo: [vendorCopy ? vendorCopy.logo : ""],
      is_quoting: [
        vendorCopy && vendorCopy.is_quoting ? vendorCopy.is_quoting : false,
      ],
    });

    this.formTitle = this.isUpdate
      ? "Edit Vendor: " + vendorCopy.name
      : "Create Vendor";

    if (this.isUpdate) {
      this.vendorService.getEntities(this.vendor.id).subscribe((data) => {
        this.vendor.entities = data.items ? data.items : data;
      });
    }

    this.initViewOnlyForm(
      this.vendor.id,
      "View Vendor",
      "Edit Vendor",
      this.dataLockService.DATA_LOCK_TYPES.VENDOR
    );

    // Load custom fields
    this.configService.loadCustomFields()
      .pipe(takeUntil(this.destroy$))
      .subscribe(config => {
        this.fields = this.configService.getCustomFieldsFromConfig(config, this.custom_values_config_key);

        let fieldsWithCategory = [];
        let custom_values = this.vendor.custom_values && this.isUpdate ? this.vendor.custom_values : {};
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].category && this.fields[i].category.length > 0)
            fieldsWithCategory = [...fieldsWithCategory, this.fields[i].category];

          if (!this.categories.includes(this.fields[i].category))
            this.categories.push(this.fields[i].category);

          this.fields[i].value = custom_values[this.fields[i].field_name];
          this.form.addControl(this.fields[i].field_name, new FormControl({
            value: this.fields[i].value,
            disabled: this.viewOnly
          }));
        }

        if (fieldsWithCategory.length === this.fields.length)
          this.showCategory = true;

        this.cd.detectChanges();
      });

    this.afterInit();
  }

  init() {
    if (this.vendor && this.vendor.id) {
      return this.vendorService
        .findByIdForEdit(
          this.vendor.id,
          new EntityEditContext({
            dialogRef: this.dialogRef,
          })
        )
        .pipe(flatMap((vendor) => {
          this.vendor = vendor;
          return observableOf(vendor);
        }));
    }
    return observableOf(this.vendor);
  }

  onSubmit({value, valid}: { value: Vendor; valid: boolean }) {
    if (valid) {
      this.manageVendorEntities();

      value.name = value?.name?.trim();
      value.custom_values = this.configService.getCustomFieldValue(this.fields, value);

      value.logo = this.vendorCopy.logo;
      if (this.vendor && this.vendor.id) {
        this.update(Object.assign({}, this.vendor, value));
      } else {
        this.create(value);
      }
    } else {
      this.alert.error("", this.messages.get("FORM_INVALID"));
    }
  }

  manageVendorEntities() {
    if (this.entitiesToRemove.length) {
      this.entitiesToRemove.forEach((el) => {
        this.vendorService.removeEntity(this.vendor.id, el).subscribe();
      });

      this.entitiesToRemove = [];
    }

    if (this.vendor.entities) {
      this.vendor.entities.filter((value) => {
        if (!value.id) {
          this.vendorService.addEntity(this.vendor.id, value.name);
        }
      });
    }
  }

  create(vendor: Vendor) {
    this.toggleDialogButtons();
    this.vendorService.create(vendor).subscribe(
      (result) => {
        this.closeDialog(result, true);
      },
      (error) => {
        this.toggleDialogButtons(false);
        const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_VENDOR' : 'CREATE_ERROR';
        this.alert.error('', this.messages.get(message));
      }
    );
  }

  update(vendor: Vendor) {
    this.toggleDialogButtons();
    this.vendorService.update(vendor.id, vendor).subscribe(
      (result) => {
        this.closeDialog(result, true);
      },
      (error) => {
        this.toggleDialogButtons(false);
        const message = (error.error && error.error['message'] == 'Duplicate error') ? 'DUPLICATE_VENDOR' : 'UPDATE_ERROR';
        this.alert.error('', this.messages.get(message));
      }
    );
  }

  deleteVendor(event) {
    if (this.vendor && this.vendor.id && event) {
      this.toggleDialogButtons();
      this.vendorService.delete(this.vendor.id).subscribe(
        (deleteResult) => {
          if (deleteResult) {
            this.dialogRef.close({deleted: true});
          }
        },
        (err) => {
          this.toggleDialogButtons(false);
          if (err.status === 400) {
            setTimeout(() => {
              this.alert.error(
                "",
                this.messages.get("DELETE_ACTIVE_VENDOR_ERROR")
              );
            }, 600);
          } else {
            this.alert.error("", this.messages.get("DELETE_ERROR"));
          }
        }
      );
    }
  }

  handleLogoUpload(logoDataUrl) {
    this.vendorCopy.logo = logoDataUrl;
  }

  getVendorInitials(): string {
    if (this.vendor && this.vendor.name && this.vendor.name.length > 1) {
      return [this.vendor.name[0], this.vendor.name[1]].join("").toUpperCase();
    } else {
      return "NN";
    }
  }

  newVendorEntity(vendor: Vendor) {
    this.dialogService
      .open(VendorEntityManageDialogComponent, {
        vendor: vendor,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.vendorService
            .findAllinEntityList(new Query({limit: 1000}))
            .subscribe((res) => {
              let allEntitiesNames = res.map((item) => item.name);
              let isExists = allEntitiesNames.includes(result.name);
              if (!isExists) {
                this.vendor.entities.push({
                  id: null,
                  name: result.name,
                  vendor_common_id: vendor.id,
                });
              } else {
                this.alert.error("", this.messages.get("CREATE_ENTITY_ERROR"));
              }
            });
        }
      });
  }

  moveVendorEntity(vendor: Vendor, entity: VendorEntity) {
    this.dialogService
      .open(MoveEntityDialogComponent, {
        vendor: vendor,
        entity: entity,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.vendorService.getEntities(this.vendor.id).subscribe((data) => {
            this.vendor.entities = data.items ? data.items : data;
            this.alert.success("", this.messages.get("MOVE_ENTITY_SUCCESS"));
          });
        }
      });
  }

  deleteVendorEntity(vendor: Vendor, entity: VendorEntity) {
    if (entity.id) {
      this.entitiesToRemove.push(entity.id);
    }

    this.vendor.entities = this.vendor.entities.filter(
      (value) => value.name !== entity.name
    );
  }

  close() {
    if (this.vendor.entities && this.entities) {
      let entetiesCount = this.vendor.entities.length + this.entities.length;
      if (this.vendor.entities.length !== entetiesCount) {
        this.formChanges = true;
      }
    }
    this.closeDialog();
  }

  editForm() {
    this.vendorService
      .findByIdForEdit(this.vendor.id)
      .subscribe((result: EntityLockData) => {
        if (result["$lock"] && result["$lock"]["status"] === 403) {
          this.alert.error(
            "",
            `${this.messagesCore.get("DATA_LOCKED")}${result["$lock"]["user"]}`
          );
        } else {
          this.form.enable();
          this.viewOnly = false;
          this.formTitle = this.editTitle;
        }
      });
  }

  cancel() {
    this.vendorService.cancelEdit();
    this.closeDialog();
  }

  loadPermissions() {
    const deletePermiss$ = this.permissionService.isAllowed(
      "vendors",
      "DELETE"
    );
    return forkJoin(deletePermiss$).pipe(map(([deletePermiss]) => {
      this.deletePermission = deletePermiss;
    }));
  }
}
