import { of as observableOf, Subscription } from 'rxjs';

import { mergeMap } from 'rxjs';
import { GridSettingsComponent } from '../../core/grid/grid-settings.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DisputeService } from '../core/dispute.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { Dispute } from '../core/dispute';
import { DisputeQuery } from '../core/dispute.query';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import { DisputeManageDialogComponent } from '../shared/dispute-manage-dialog/dispute-manage-dialog.component';
import { InvoiceChargeService } from '../../charge/core/invoice-charge.service';
import { InvoiceFacepageService } from '../../invoice/core/invoice-facepage.service';
import { PageListComponent } from '../../core/page-list.component';
import { DisputeGridService } from '../core/dispute-grid.service';
import { Router } from '@angular/router';
import { AlertService } from '../../shared/alert/alert.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { PageContext } from '../../core/page.context';
import { LoaderService } from '../../shared/loader/loader.service';
import {
	IMessagesResourceService,
	ResourcesService
} from 'app/core/resources/resources.service';
import { DataLockDialogService } from '../../core/data-lock/data-lock-dialog.service';
import { DisputeFilterService } from '../core/dispute-filter.service';
import { GridService } from '../../shared/grid/grid.service';
import { DeleteDisputeManageDialog } from '../shared/dispute-delete-manage-dialog/dispute-delete-manage-dialog.component';

import { LOOKUP_ENUM } from '../../dictionary/core/lookup.enum';
import { INVOICE_STATUS_ENUM } from '../../invoice/core/invoice-status.enum';

@Component({
	selector: 'app-dispute-list',
	templateUrl: './dispute-list.component.html'
})
export class DisputeListComponent
	extends PageListComponent
	implements OnInit, AfterViewInit
{
	@ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

	public disputes: Array<any>;
	public total: number;
	public selection: any;
	public columns: Array<any>;
	public sorting: any[][];
	public disabled: any;

	query: DisputeQuery = new DisputeQuery({
		orderBy: [['created_at', 'DESC']]
	});

	messages: IMessagesResourceService;
	readonly MESSAGES_MODULE: string = 'dispute';

	readonly DISPUTE_STATUS_ENUM = LOOKUP_ENUM.DISPUTE_STATUS_ID;
	readonly DISPUTE_WITHHELD_STATUS = LOOKUP_ENUM.DISPUTE_WITHHELD_STATUS;

	constructor(
		public disputeService: DisputeService,
		public invoiceChargeService: InvoiceChargeService,
		public invoiceFacepageService: InvoiceFacepageService,
		public dialogService: DialogService,
		public disputeGridService: DisputeGridService,
		public router: Router,
		public alertService: AlertService,
		public settingsService: UserSettingsService,
		public loaderService: LoaderService,
		public dataLockDialogService: DataLockDialogService,
		public disputeFilterService: DisputeFilterService
	) {
		super(
			new PageContext({
				name: 'app.dispute.dispute-list',
				settings: settingsService
			})
		);

		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
	}

	loadData(query?: DisputeQuery, selectFirst?: boolean) {
		this.loaderService.displayLoader();

		this.disputeService.findAll(query).subscribe(
			(result) => {
				this.disputes = result.items;
				this.query.total = result.total;
				this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
			},
			(error) => {
				console.log('Error fetching disputes', error);

				this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
			}
		);
	}

	refresh() {
		this.loadData(this.query);
		this.selection = null;
		this.dataGrid.instance.deselectAll();
	}

	filterData(query: DisputeQuery) {
		this.query = query;
		this.loadData(query);
		this.selection = null;
		this.dataGrid.instance.deselectAll();
	}

	clearFilter() {
		this.query.where = {};
		this.loadData(this.query);
		this.selection = null;
		this.dataGrid.instance.deselectAll();
	}

	showDetails() {
		this.router.navigate(['/dispute', this.selection.id, 'show']);
	}

	editDispute(dispute) {
		if (dispute) {
			this.disputeService.findByIdForEdit(dispute.id).subscribe((res) => {
				this.dialogService
					.edit(
						DisputeManageDialogComponent,
						{
							dispute: res
						},
						{ width: '60%' }
					)
					.subscribe((items: any) => {
						if (items) {
							let obs = observableOf(items);
							obs.pipe(mergeMap((x) => x.afterClosed())).subscribe((result) => {
								if (result) {
									this.refresh();
									this.alertService.success(
										'',
										this.messages.get('UPDATE_SUCCESS')
									);
								}
							});
						}
					});
			});
		}
	}

	csv() {
		const { query } = this;
		let fieldsSubscription: Subscription;

		const fieldsObservable = this.disputeGridService.csvMap();
		fieldsSubscription = fieldsObservable.subscribe((fields) => {
			this.disputeService.exportToCSV('disputes', { fields, query });
			if (fieldsSubscription) {
				fieldsSubscription.unsubscribe();
			}
		});
	}

	ngOnInit() {
		this._init();
	}

	ngAfterViewInit(): void {
		this.disputeGridService.create(this.dataGrid.instance, {});
		super.ngAfterViewInit();
	}

	onSelectionChanged(row?) {
		if (!row.selectedRowsData.length) return;

		const selected = <Dispute>row.selectedRowsData[0];
		const deleteDisputeDisabled = this.checkDisableDelete(selected);
		this.selection = { ...selected };
		this.selection.deleteDisabled = deleteDisputeDisabled;
	}

	gridSettings() {
		this.dialogService
			.open(GridSettingsComponent, {
				service: this.disputeGridService
			})
			.afterClosed()
			.subscribe((settings) => {
				if (settings) {
					this.resetDataPager();
					this._init();
				}
			});
	}

	getDefaultSort(): any {
		return [['created_at', 'DESC']];
	}

	getGridService(): GridService {
		return this.disputeGridService;
	}

	deleteDispute(dispute: Dispute) {
		this.dialogService
			.open(
				DeleteDisputeManageDialog,
				{
					dispute,
					deactivationMessage: `Are you sure you want to delete Dispute ${dispute.dispute_id}?`
				},
				{
					width: '600px'
				}
			)
			.afterClosed()
			.subscribe(
				(deletedDispute) => {
					if (deletedDispute) {
						this.loaderService.displayLoader();
						this.disputeService.findById(dispute.id).subscribe((result) => {
							let selected = <Dispute>result;
							const deleteDisputeDisabled = this.checkDisableDelete(selected);
							if (!deleteDisputeDisabled) {
								this.disputeService
									.deleteDispute(dispute.id, deletedDispute['note'])
									.subscribe((result) => {
										this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
										// in case of failure, make sure to warn user and exit
										if (!result.status || result.status !== 200) {
											this.alertService.error(
												'',
												this.messages.get('DELETE_ERROR')
											);
											this.refresh();
											return;
										}

										let invoicesToReset = [];
										selected.dispute_charges.forEach((charge) => {
											if (
												charge.dispute_withheld &&
												charge.charge.invoice.header.status_code >
													INVOICE_STATUS_ENUM.NEW_PENDING &&
												!invoicesToReset.includes(
													charge.charge.invoice.invoice_id
												)
											) {
												invoicesToReset.push(charge.charge.invoice.invoice_id);
											}
										});

										if (invoicesToReset.length) {
											invoicesToReset.forEach((invoice_id) => {
												this.invoiceFacepageService
													.updateStatus(invoice_id, {
														status: INVOICE_STATUS_ENUM.NEW_PENDING,
														statusReset: INVOICE_STATUS_ENUM.NEW_RESET
													})
													.subscribe(() => {
														this.invoiceFacepageService.processMultipleInvoices(
															invoice_id
														);
													});
											});
										}

										this.alertService.success(
											'',
											this.messages.get('DELETE_SUCCESS')
										);
										this.refresh();
									});
							} else {
								this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
								this.alertService.error(
									'',
									this.messages.get('DELETE_WITHHELD_DISPUTE')
								);
								this.refresh();
							}
						});
					}
				},
				(error) => {
					this.loaderService.hideLoaderAndManageGrid([this.dataGrid]);
					console.log('Error removing disputes', error);
				}
			);
	}

	checkDisableDelete(dispute) {
		/**
     * enable delete if all charge lines of a dispute are either:
     Not withheld, or
     The underlying invoice states of Withheld lines are New through GL Coded.
     */
		let deleteDisputeDisabled = false;
		if (dispute.withheld_status !== this.DISPUTE_WITHHELD_STATUS.NOT_WITHHELD) {
			deleteDisputeDisabled = dispute.dispute_charges.some((ds) => {
				return (
					ds.dispute_withheld == true &&
					ds.charge.invoice &&
					ds.charge.invoice.header.status_code >= INVOICE_STATUS_ENUM.RFA
				);
			});
		}
		return deleteDisputeDisabled;
	}
}
