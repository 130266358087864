<app-inventory-details-toolbar
	[inventory]="inventory"
	[isAddGLHidden]="true"
	[isAddGLDisabled]="true"
	[isContractTermsHidden]="true"
	[isAddPricingHidden]="true"
	[isCreateOrderHidden]="true"
	[isEditInventoryHidden]="true"
	[isDocumentAction]="false"
	[isPricingDocumentAction]="false"
	[isChargeProfileTab]="true"
	[isSaveDisabled]="isChargeProfileSavingOn || !isChargeProfileDataValid"
	[isEditChargeProfileHidden]="false"
	[isEditChargeProfileDisabled]="isBulkEditDisabled"
	[isSettingsHidden]="false"
	[isSettingsDisabled]="false"
	[isFilterDisabled]="false"
	[isFilterOn]="
		this.sider.getActiveSection().name === this.SECTIONS.FILTER_SECTION_NAME
	"
	[isNotesDisabled]="!isSingleNoteSelected"
	[notesCount]="notesCount"
	[isNotesOn]="
		this.sider.getActiveSection().name === this.SECTIONS.NOTES_SECTION_NAME
	"
	[isHistoryDisabled]="!isSingleNoteSelected"
	[isHistoryOn]="
		this.sider.getActiveSection().name === this.SECTIONS.HISTORY_SECTION_NAME
	"
	(toggleFilterRequested)="toggleFilter()"
	(toggleNotesRequested)="toggleNotes()"
	(toggleHistoryRequested)="toggleHistory()"
	(editChargeProfileStatusRequested)="editChargeProfileStatus()"
	(gridSettingsRequested)="gridSettings()"
	(chargeProfileSaveRequested)="saveChargeProfile()"
	(backRequested)="back()"
>
</app-inventory-details-toolbar>

<mat-sidenav-container
	class="app-content app-content-list tabbed-content dx-nodata-absolute"
>
	<app-inventory-details-tabs
		#tabs
		[activeTabIndex]="ACTIVE_TAB_INDEX"
		[disabled]="hasUnsavedChanges"
		(displayPreventChangeModal)="displayCheckModal()"
	></app-inventory-details-tabs>

	<div
		class="tabs-body grid-container grid-with-toolbar dx-nodata-absolute"
		style="top: -2px; z-index: -1; overflow: hidden"
	>
		<div class="grid-body app-content-list">
			<dx-data-grid
				#chargeProfileGrid
				[height]="'100%'"
				class="grid-with-selection-badge app-grid-with-pager"
				[disabled]="isChargeProfileSavingOn"
				[columns]="chargeProfileColumns"
				[dataSource]="chargeProfiles"
				[showColumnLines]="false"
				[showRowLines]="true"
				[allowColumnResizing]="true"
				[showBorders]="true"
				[columnAutoWidth]="true"
				(onRowClick)="onRowClick($event)"
				(onRowUpdated)="onRowUpdated($event)"
				(onSelectionChanged)="onSelectionChange($event)"
				(onCellClick)="onCellClick($event)"
				(onCellPrepared)="onCellPrepared($event)"
			>
				<dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
				<dxo-selection mode="multiple"></dxo-selection>
				<dxo-paging [enabled]="false" visible="false"></dxo-paging>
				<dxo-sorting mode="none"></dxo-sorting>
				<dxo-load-panel [enabled]="false"></dxo-load-panel>

				<div
					*dxTemplate="let d of 'selectionBadgeHeaderTemplate'"
					class="grid-selection-badge-container"
				>
					<a
						class="selection-badge grid-badge"
						matTooltip="Selection"
						(click)="loadSelected()"
						[ngClass]="{ active: chargeProfilesSelectionActive }"
					>
						{{ chargeProfilesSelected.length }}
					</a>
				</div>

				<div *dxTemplate="let d of 'selectionBadgeCellTemplate'"></div>

				<div *dxTemplate="let d of 'dateTemplate'">
					<div caGridTooltip>{{ d.value | caDate }}</div>
				</div>

				<div *dxTemplate="let d of 'booleanTemplate'">
					<div caGridTooltip>{{ d.value ? 'Active' : 'Inactive' }}</div>
				</div>

				<div *dxTemplate="let d of 'currencyTemplate'">
					<div caGridTooltip class="right">
						{{ d.value | caCurrency: 'USD' }}
					</div>
				</div>
			</dx-data-grid>
		</div>

		<ca-pager
			[gridPager]="defaultGridPager"
			[pageContext]="invoiceChargeProfileGridService"
			[query]="query"
			class="app-pager ca-default-border-top"
			(onChange)="onPageChange(query)"
		></ca-pager>
	</div>

	<mat-sidenav
		#panelMore
		[mode]="'side'"
		position="end"
		[opened]="false"
		(close-start)="panelClose()"
	>
		<new-app-filter
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.FILTER_SECTION_NAME) }"
			[query]="query"
			[filterService]="invoiceChargeProfileFilterService"
			(filterRequested)="filterData($event)"
			(clearFilterRequested)="clearFilter()"
		>
		</new-app-filter>

		<ca-notes
			[entityId]="selectedSingleChargeProfile?.id"
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.NOTES_SECTION_NAME) }"
			[entityType]="SYSTEM_MODULE.INVOICE"
			(countChanged)="onNotesCountChanged($event)"
		></ca-notes>

		<ca-history
			#history
			[ngClass]="{ hidden: !sider?.isActive(SECTIONS.HISTORY_SECTION_NAME) }"
			[entityId]="selectedSingleChargeProfile?.id || 1"
			[entityType]="'invoicechargeprofile'"
		></ca-history>
	</mat-sidenav>
</mat-sidenav-container>
