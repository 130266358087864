<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title">
	<h1 mat-dialog-title>{{ title }}</h1>
</div>

<div mat-dialog-content class="ca-dialog-content">
	<div class="grid-container" *ngIf="!config">
		<div
			style="height: 220px"
			class="grid-body app-content-list app-content-list-no-pager dx-nodata-absolute"
		>
			<dx-data-grid
				#grid
				class="grid-with-checkboxes"
				[columns]="columns"
				[dataSource]="invoices"
			>
				<div *dxTemplate="let d of 'invNumTemplate'">
					<div caGridTooltip>{{ d.column.caption }}</div>
				</div>
				<div *dxTemplate="let d of ''">
					<div caGridTooltip>{{ d.column.caption }}</div>
				</div>
				<div *dxTemplate="let d of 'invoiceCurrencyTemplate'">
					<div caGridTooltip class="right">
						{{ d.value | caCurrency: d.data?.currency }}
					</div>
				</div>
				<div *dxTemplate="let d of 'dateTemplate'">
					<div caGridTooltip>{{ d.value | caDate }}</div>
				</div>
			</dx-data-grid>
		</div>
	</div>
	<form class="ca-form" style="padding-top: 8px" [formGroup]="form">
		<div fxLayout="row wrap">
			<div *ngIf="config" class="flex-item caGap" fxFlex="50%">
				<ca-lookup-model-picker
					fxFlex
					caInput
					[lookupModel]="DNP_REASON_CODE_MODEL"
					[pickerForm]="form"
					formControlName="dnp_reason_code_id"
					placeholder="Reason"
					[required]="'true'"
				>
				</ca-lookup-model-picker>
			</div>
			<mat-form-field caInput class="caGap">
				<mat-label>{{ placeHolder }}</mat-label>
				<textarea
					matInput
					formControlName="note"
					fxFlex="100%"
					rows="2"
					[required]="'true'"
				></textarea>
			</mat-form-field>
		</div>
	</form>
</div>

<mat-dialog-actions class="ca-dialog-action">
	<div class="example-fill-remaining-space"></div>
	<button
		[disabled]="form.invalid"
		#cancelButton
		mat-button
		type="submit"
		(click)="delete()"
	>
		{{ actionText }}
	</button>
	<button #cancelButton mat-button type="submit" (click)="close()">
		Cancel
	</button>
</mat-dialog-actions>
