import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";

import { AlertService } from "../../../shared/alert/alert.service";
import { DialogService } from "../../../shared/dialog/dialog.service";
import { RandomService } from "../../../shared/random/random.service";
import { Audit } from "../../../audit/core/audit";
import Query from "../../../core/query/query";
import { AuditsService } from "../../../audit/core/audits.service";
import { AuditGridService } from "../../../audit/core/audit-grid.service";
import { AuditConfigService } from "../../../audit/core/audit-config.service";
import { AUDIT_TYPE_ENUM } from '../../../audit/core/audit-type.enum';
import { AuditRunGridService } from 'app/invoice/core/audit-run-grid.service';
import { LoaderService } from "app/shared/loader/loader.service";


@Component({
  selector: 'app-audit-run',
  templateUrl: './audit-run.component.html',
  styleUrls: ['./audit-run.component.scss']
})
export class AuditRunComponent implements OnInit, AfterViewInit {

  audits: Array<Audit>;
  query: Query = new Query({
    'order': [['name', 'ASC']]
  });
  selection: Audit;
  selectedAudits: Audit[];
  isRunButtonDisabled = false;

  @ViewChild('auditGrid', {static: false}) dataGrid: DxDataGridComponent;

  public sorting: any[][];

  // Grid settings
  public columns: Array<any>;
  selectionActive: any;
  selectedRows = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<AuditRunComponent>,
    public auditsService: AuditsService,
    public auditConfigService: AuditConfigService,
    public auditGridService: AuditGridService,
    public auditRunGridService: AuditRunGridService,
    public randomService: RandomService,
    public loaderService: LoaderService) {}

  ngOnInit(): void {
    this.selectedAudits = [];
    this.loadData(this.query);
    this.columns = this.auditRunGridService.columns()
  }

  ngAfterViewInit(): void {
    this.auditRunGridService.create(this.dataGrid.instance, {
      selection: {
        mode: 'multiple',
        selectAllMode: 'page'
      }
    });
  }

  loadData(query?: Query, selectFirst?: boolean) {
    const vendorId = this.data['selectedInvoices'][0].vendor_id;
    query.where = {
      $or: {
        type_id: {'$in': [AUDIT_TYPE_ENUM.SYSTEM, AUDIT_TYPE_ENUM.CHARGE_PROFILE] },
        vendor_id: vendorId
      }
    }
    query.limit = 1000;
    this.auditsService.findAll(query)
      .subscribe(
        (result: any) => {
          this.audits = result.items.sort((a,b)=>{
            //console.log(`audit-run.component->loadData(): sorting. A = ${JSON.stringify(a)} vs B ${JSON.stringify(b)} `);
            if(a.type.id === 10 && b.type.id !== 10) return -1;
            if(a.type.id !== 10 && b.type.id === 10) return 1;
            if(a.type.value != b.type.value) return a.type.value > b.type.value ? 1 : -1;
            return a.name > b.name ? 1 : -1;
          });
          this.query.total = result.total;
        },
        (error) => {
          console.log('Error getting audits', error);
        });
  }

  close() {
    this.dialogRef.close(null);
  }

  run() {
    // Prepare data
    this.isRunButtonDisabled = true;
    this.loaderService.displayLoader();
    const auditIds = this.selectedRows.map(a => a.id);
    const invoiceIds = this.data['selectedInvoices'].map(i => i.invoice_id);
    this.auditConfigService.runFromInvoices(auditIds, invoiceIds)
      .subscribe(result => {
        if (result) {
          this.dialogRef.close({isSuccess: true});
        } else {
          this.dialogRef.close({isSuccess:false});
        }
      },(error) => {
        const {error: {messages}} = error;
        this.dialogRef.close({isSuccess: false, messages});
      });
  }

  onSelectionChanged(event: any) {
    this.selection = <Audit>event.selectedRowsData[0];
    // ** Deselected rows */
    if (event.currentDeselectedRowKeys.length) {
      event.currentDeselectedRowKeys.forEach((item) => {
        let index = this.findIndexInSelectedRows(item.id);
        if (index > -1) {
          this.selectedRows.splice(index, 1);
        }
      });
    }

    // ** Selected rows */
    if (event.currentSelectedRowKeys.length) {
      event.currentSelectedRowKeys.forEach((item) => {
        let index = this.findIndexInSelectedRows(item.id);
        if (index === -1) {
          this.selectedRows.push(item);
        }
      });
    }
  }

  private findIndexInSelectedRows(id): number {
    for (let i = 0, l = this.selectedRows.length; i < l; i++) {
      if (this.selectedRows[i].id === id) {
        return i;
      }
    }
    return -1;
  }
}
