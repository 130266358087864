<ng-container *ngIf="contact">

  <mat-toolbar color="accent" mat-scroll-shrink>

    <span class="sneak-peek-title">{{ contact?.title }} {{ contact?.first_name }} {{ contact?.last_name }}</span>

    <span class="example-fill-remaining-space"></span>

    <button mat-icon-button (click)="closeSide()">
      <ca-icon [key]="'CLOSE'"></ca-icon>
    </button>
  </mat-toolbar>
  <div class="ca-card ca-card-sneak-peek">
    <mat-card caCard>
      <mat-card-content>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Full Name</label>
            <p>
              {{ contact?.title }} {{ contact?.first_name }} {{ contact?.last_name }}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Type</label>
            <p>
              {{ contact?.contact_type?.value || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Company</label>
            <p *ngIf="contact && contact.contact_type_id == CONTACT_TYPE_ENUM.VENDOR">
              {{ contact?.vendor?.name || 'N/A'}}
            </p>
            <p *ngIf="contact && contact.contact_type_id == CONTACT_TYPE_ENUM.CUSTOMER">
              {{ contact?.customer?.company || 'N/A'}}
            </p>
            <p *ngIf="!contact || (contact && contact.contact_type_id == CONTACT_TYPE_ENUM.INTERNAL)">
              N/A
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxLayout="column" class="entry-list-item">
            <label>Functions</label>
            <div fxFlex>
              <p>{{ contactFunctions }}</p>
            </div>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Email</label>
            <p>
              {{ contact?.email || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Office Phone</label>
            <p>
              {{ (contact?.office_phone | caPhone) || 'N/A'}}
            </p>
          </div>
          <div fxFlex class="entry-list-item">
            <label>Extension</label>
            <p>
              {{ contact?.office_phone_extension || 'N/A'}}
            </p>
          </div>

        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Mobile Phone</label>
            <p>
              {{ (contact?.mobile_number | caPhone) || 'N/A'}}
            </p>
          </div>
        </div>

        <div fxLayout="row" class="entry-list">
          <div fxFlex class="entry-list-item">
            <label>Address</label>
            <div>
              <p *ngIf="contact?.address?.address1">
                {{ contact?.address?.address1}}
              </p>
              <p *ngIf="contact?.address2">
                {{ contact?.address2 }}
              </p>
              <p *ngIf="contact?.address3">
                {{ contact?.address3 }}
              </p>
              <p>
                {{ contact?.address?.city }}&nbsp; {{ contact?.address?.subdivision?.abbreviation }}&nbsp;
                {{ contact?.address?.postal_code }}
              </p>
              <p *ngIf="contact?.address?.country">
                {{ contact?.address?.country?.name }}
              </p>
            </div>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="!contact">
  <div class="ca-empty-state-container">
    <p class="card-message">
      No contact selected
    </p>
  </div>
</ng-container>